import React, { useState, useEffect, useMemo, Fragment, useCallback} from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Input from "components/app/school/SmallerComponents/input";

import Loader from "components/app/school/SmallerComponents/loader";
import Spinner from "components/app/school/SmallerComponents/spinner";
import BlankLoader from "components/blankLoader";
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { addItemToArray } from "components/app/school/actions/array-utils/Add";
import { updateArray } from 'components/app/school/actions/array-utils/Update';
import { convertNumber, convertToNumber, seperatePoint } from 'components/app/school/actions/money';

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';


//create a button to activate and deactivate student payment

const SchoolFee = ({school_id, currency, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const [dataTable, setDataTable] = useState({sbid:0});
    const [menuDropData, setMenuDropData] = useState({id:'', num:0});

    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allSchoolFees, setAllSchoolFees] = useState([]);
    const [allGeneralSchoolFees, setAllGeneralSchoolFees] = useState([]);
    const [allOptionalSchoolFees, setAllOptionalSchoolFees] = useState([]);

    const [dataTableChange, setDataTableChange] = useState(false);
    const [reRenderSchoolFees, setReRenderSchoolFees] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [loading, setLoading] = useState(false)
    const [menuLoading, setMenuLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);

    const handleDataTableChange = (e) => {
        setDataTableChange(false);
        setDataTable({...dataTable, [e.target.name]:e.target.value});
        setTimeout(() => {
            setDataTableChange(true);
        }, 200)
    }

    const handleEditChange = (e) => {
        if(e.target.name === "fee_point"){
            if(menuDropData.fee === "0" || !menuDropData.fee){
                return;
            }
        }
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value});
    }

    const handleStatCheck = (num=0, id=0, is_active=false, fee=0, is_list_price=false) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(!parseInt(fee) && !is_list_price){
            setTimeout(() => {
                setLoading(false);
                setError({data:["Fee Amount Must Be Updated"], title:"Check Box Error"});
            }, )
        } else {
            appServices.updateSchoolFees(id, {is_active:is_active, fee:fee}).then(res => {
                setSuccess({text: "Status Changed Successfully", title:"success"});
                const arr = updateArray(allSchoolFees, num, res.data);
                setAllSchoolFees(arr);
                itemizeAllFeeArray(arr);
            }).catch(e => {
                setLoading(false);
                setError({data:[e.response.data.detail], title:'Check Box Change Error'});
                console.log(e.response);
            })
        }

    }

    const fetchAllClassSection = (sid) => {
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSectionData(res.data);
        }).catch(e => {
            setAllClassSectionData([]);
            setError({data:["Internal Server Error"], title:"Class Section Data Error"});
        })
    }

    const fetchAllSchoolBranch = (sid=0) => {
        if(sid){
            setPreloading(true);
            setDataTableChange(false);
            appServices.getAllSchoolBranch(sid).then(res => {
                
                setAllSchoolBranchData([...res.data]);
                setDataTable({sbid:res.data[0].id});
                setDataTableChange(true);
            }).catch(e => {
                setAllSchoolBranchData([]);
                setError({data:['Internal Server Error'], title:'School Branch Data Error'});
            })
        }
    } 

    console.log(currency);

    const fetchAllSchoolFees = useCallback((sbid=0) => {
        if(sbid){
            setPreloading(true);
            setReRenderSchoolFees(false);
            appServices.getAllSchoolFees(sbid).then(res => {
                if(!res.data.length){
                    setReRenderSchoolFees(true);
                    setDataTableChange(false);
                    return;
                }
                const arr = addItemToArray(res.data, {})
                setAllSchoolFees(arr);
                itemizeAllFeeArray(arr);
                setTimeout(() => {
                    setPreloading(false);
                }, 200);
            }).catch(e => {
                setError({data:['Internal Server Error'], title:'School Fee Data Error'});
                setPreloading(false);
            })
        } else {
            setPreloading(false);
        }
    }, [])


    const itemizeAllFeeArray = (arr=[]) => {
        if(arr.length > 0){
            const filterGeneralFee = arr.filter((item) => {
                return item.is_general === true;
            })
            const filterOptionalFee = arr.filter((item) => {
                return item.is_optional === true;
            })
            setAllGeneralSchoolFees(filterGeneralFee);
            setAllOptionalSchoolFees(filterOptionalFee);
        }
    }
    
    const onClickEditFee = (data) => {
        setMenuDrop(true);
        setMenuDropData(data);
    }
    const onUpdateSchoolFee = (e) => {
        e.preventDefault();
        setMenuLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            description:menuDropData.description,
            fee: `${convertToNumber(menuDropData.fee)}.${menuDropData.fee_point ? menuDropData.fee_point : '00'}`,
            is_active: menuDropData.is_active,
        }
        appServices.updateSchoolFees(menuDropData.id, data).then(res => {
            setSuccess({text: "Data Updated Successfully", title:"success"});
            setMenuLoading(false);
            setLoading(true);
            setMenuDrop(false);
            const arr = updateArray(allSchoolFees, menuDropData.num, res.data);
            setAllSchoolFees(arr);
            itemizeAllFeeArray(arr);
            setTimeout(() => {
                setLoading(false);
            }, 100)
        }).catch(e => {
            setMenuLoading(false);
            setError({data:[e.response.data.detail], title:'Update Data Error'});
        })
    }
    
    useEffect(() => {
        fetchAllSchoolBranch(school_id);
        fetchAllClassSection(school_id);
    }, [school_id]);

    useEffect(() => {
        if(dataTableChange){
            fetchAllSchoolFees(dataTable.sbid);
        }    
        if(reRenderSchoolFees){
            fetchAllSchoolFees(dataTable.sbid);
        }
    }, [dataTable, fetchAllSchoolFees, dataTableChange, reRenderSchoolFees])

    console.log(menuDropData);

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSchoolBranchData = allSchoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const insertGeneralNewStudentFee = (arr=[], type, class_section, class_section_name="", others={}) => {
        const filterItem = arr.filter((item) => {
            return item[type] === true && item.class_section===class_section;
        })
        return filterItem.map((item) => {
            const data = {name:item.name, num:item.num, is_active:item.is_active, id:item.id, is_list_price:item.is_list_price, description:item.description, fee:seperatePoint(item.fee), fee_point:seperatePoint(item.fee, true), class_section_name:class_section_name, fee_section:others.fee_section, student_type:others.student_type}
            return(
                <Fragment key={item.num}>
                    <Tr  sn={false} edit_btn={true} dlt_btn={false} handleEditClick={() => onClickEditFee(data)}>
                        <div className="c-data"><span className="font-very-small text-capitalize">{item.name} Fee</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize">{!item.is_list_price ? `${currency.symbol}${convertNumber(item.fee)}` : '-'}</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize"><input type="checkbox" checked={item.is_active} value={item.is_active} onChange={() => handleStatCheck(item.num, item.id, !item.is_active, item.fee, item.is_list_price)}  /></span></div>
                        <div className="c-data hide" style={{width:'60%'}}><span className="font-very-small">{item.description.length > 45 ? item.description.slice(0, 45)+'...' : item.description} </span></div>

                        <TdWrapper center header_text={`${item.name.toUpperCase()} FEE - ${type === "is_new" ? 'New' : 'Returning'}`}>
                            <Td heading="Fee Type" text={`${item.name} Fee`} text_class='app-text-color font-very-small text-capitalize' />
                            {!item.is_list_price && <Td heading="Fee Amount" text={`${currency.symbol}${convertNumber(item.fee)}`} text_class='app-text-color font-very-small' />}
                            <Td heading="Status" text_class={item.is_active ? 'app-text-color green' : 'app-text-color red'} text={item.is_active ? 'Active' : 'Inactive'} />
                            <Td heading="Description" text={item.description} />
                        </TdWrapper>
                    </Tr>
                </Fragment>
            )
        })
    }

    return(
        <>
            <HeadComponent title='Manage School Fees' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>  
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage School Fees`} header_icon="fas fa-tasks">
                        {loading && <Loader />}
                        <Section>
                        <Note>Taxes will be added, unless the school plan is a premium plan, then the app tax will be 0% </Note>
                        <Note>The description is to give details about the payment been made to the payer</Note>
                        <Note>The general fee checked out is compulsory for the student in respective classes and the optional stands by it name </Note>
                            <Mallet >
                                <div className="flex-100"><label className='font-super-small'>School Location: 
                                    <select name="sbid" value={dataTable.sbid} onChange={e => handleDataTableChange(e)} className='form-control-theme font-slightly-small' >
                                        {insertSchoolBranchData}
                                    </select></label>
                                </div>
                            </Mallet>
                            {preloading ? (<ul>
                                <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                            <Section header="General Fee">
                                <div style={{paddingBottom:'15px'}}>
                                    <span className="font-med fw-700 disp-block padd-4px app-text-color">New Student</span>
                                    <div className='padd-4px' style={{paddingLeft:'7px'}}>
                                        <Table>
                                            <Tr btn={false} header={true} text={[{text:"Fee Type"}, {text:"Fee Amt"}, {text:'Stat'}, {text:'Description', class:'hide', style:{width:'60%'}}]} />
                                            {!allGeneralSchoolFees.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                (<>
                                                {!allClassSectionData.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                allClassSectionData.map((item, i) => {
                                                    return(
                                                        <Fragment key={i}>
                                                            <Tr btn={false} text={[{text:`${item.name.toUpperCase()} SECTION`, style:{width:'100%'}, class:"font-slightly-small padd-4px app-text-color"}]} style={{paddingLeft:'7px', textAlign:'center'}}  styleText={{fontWeight:700}}/>
                                                            {insertGeneralNewStudentFee(allGeneralSchoolFees, "is_new", item.id, item.name, {fee_section:'General', student_type:'New'})}
                                                        </Fragment>
                                                    )
                                                })}
                                                </>)
                                            }
                                        </Table>
                                    </div>
                                </div>
                                <div style={{paddingBottom:'15px'}}>
                                    <span className="font-med fw-700 disp-block padd-4px app-text-color">Returning Student</span>
                                    <div className='padd-4px' style={{paddingLeft:'7px'}}>
                                        <Table>
                                            <Tr btn={false} header={true} text={[{text:"Fee Type"}, {text:"Fee Amt"}, {text:'Stat'}, {text:'Description', class:'hide', style:{width:'60%'}}]} />
                                            {!allGeneralSchoolFees.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                (<>
                                                {!allClassSectionData.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                allClassSectionData.map((item, i) => {
                                                    return(
                                                        <Fragment key={i}>
                                                            <Tr btn={false} text={[{text:`${item.name.toUpperCase()} SECTION`, style:{width:'100%'}, class:"font-slightly-small padd-4px app-text-color"}]} style={{paddingLeft:'7px', textAlign:'center'}}  styleText={{fontWeight:700}}/>
                                                            {insertGeneralNewStudentFee(allGeneralSchoolFees, "is_returning", item.id, item.name, {fee_section:'General', student_type:'Returning'})}
                                                        </Fragment>
                                                    )
                                                })}
                                                </>)
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </Section>
                            <Section header="Optional Fee">
                                <div style={{paddingBottom:'15px'}}>
                                    <span className="font-med fw-700 disp-block padd-4px app-text-color">New Student</span>
                                    <div className='padd-4px' style={{paddingLeft:'7px'}}>
                                        <Table>
                                            <Tr btn={false} header={true} text={[{text:"Fee Type"}, {text:"Fee Amt"}, {text:'Stat'}, {text:'Description', class:'hide', style:{width:'60%'}}]} />
                                            {!allGeneralSchoolFees.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                (<>
                                                {!allClassSectionData.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                allClassSectionData.map((item, i) => {
                                                    return(
                                                        <Fragment key={i}>
                                                            <Tr btn={false} text={[{text:`${item.name.toUpperCase()} SECTION`, style:{width:'100%'}, class:"font-slightly-small padd-4px app-text-color"}]} style={{paddingLeft:'7px', textAlign:'center'}}  styleText={{fontWeight:700}}/>
                                                            {insertGeneralNewStudentFee(allOptionalSchoolFees, "is_new", item.id,  item.name, {fee_section:'Optional', student_type:'New'})}
                                                        </Fragment>
                                                    )
                                                })}
                                                </>)
                                            }
                                        </Table>
                                    </div>
                                </div>
                                <div style={{paddingBottom:'15px'}}>
                                    <span className="font-med fw-700 disp-block padd-4px app-text-color">Returning Student</span>
                                    <div className='padd-4px' style={{paddingLeft:'7px'}}>
                                        <Table>
                                            <Tr btn={false} header={true} text={[{text:"Fee Type"}, {text:"Fee Amt"}, {text:'Stat'}, {text:'Description', class:'hide', style:{width:'60%'}}]} />
                                            {!allGeneralSchoolFees.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                (<>
                                                {!allClassSectionData.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : 
                                                allClassSectionData.map((item, i) => {
                                                    return(
                                                        <Fragment key={i}>
                                                            <Tr btn={false} text={[{text:`${item.name.toUpperCase()} SECTION`, style:{width:'100%'}, class:"font-slightly-small padd-4px app-text-color"}]} style={{paddingLeft:'7px', textAlign:'center'}}  styleText={{fontWeight:700}}/>
                                                            {insertGeneralNewStudentFee(allOptionalSchoolFees, "is_returning", item.id,  item.name, {fee_section:'Optional', student_type:'Returning'})}
                                                        </Fragment>
                                                    )
                                                })}
                                                </>)
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </Section>
                            </>
                            )}
                        </Section> 
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                        { menuDrop && 
                        <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                            {menuLoading && <Loader />}
                            {menuDropData.id && (
                                <form onSubmit={e => onUpdateSchoolFee(e)}>
                                    <div className='row'>
                                        <div className="fg">
                                            <label className="font-super-small">Fee Section </label>
                                            <input disabled type="text" defaultValue={menuDropData.fee_section + ' Fee'} className="font-very-small app-theme text-capitalize form-control app-text-color disabled" />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Intake </label>
                                            <input disabled type="text" defaultValue={menuDropData.student_type + ' Student'} className="font-very-small app-theme text-capitalize form-control app-text-color disabled" />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Class Section </label>
                                            <input disabled type="text" defaultValue={menuDropData.class_section_name} className="font-very-small app-theme text-capitalize form-control app-text-color disabled" />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Fee Type </label>
                                            <input disabled type="text" defaultValue={menuDropData.name + ' Fee'} className="font-very-small app-theme text-capitalize form-control app-text-color disabled" placeholder="Fee Type" />
                                        </div>
                                        {!menuDropData.is_list_price && (
                                            <div className="fg">
                                                <label className="font-super-small">Fee Amount </label>
                                                <Input type="money" required currency={currency.symbol} currency_value={currency.id} name={"fee"} value={menuDropData.fee} point_name='fee_point' point_value={menuDropData.fee_point}
                                                point_display={menuDropData.fee ? true : false} handleChange={e => handleEditChange(e)} handlePointChange={e => handleEditChange(e)} classname='font-very-small app-theme form-control-theme app-text-color' placeholder='Amount' />
                                            </div>
                                        )}
                                        <div className="fg">
                                            <label className="font-super-small">Description </label>
                                            <textarea value={menuDropData.description} className="font-very-small app-theme form-control-theme app-text-color" placeholder="Description" name="description" onChange={e => handleEditChange(e)} />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Status </label>
                                            <select onChange={e => handleEditChange(e)}  className={`font-very-small app-theme form-control-theme app-text-color`} name="is_active" value={menuDropData.is_active}>
                                                <option value={true} style={{color:'green'}}>Active</option>
                                                <option value={false} style={{color:'red'}}>Inactive</option>
                                            </select>
                                        </div>
                                        <div className="fg-all btn-g align-center">
                                            <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                Update <i className="fas fa-paper-plane"></i> </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </MenuDrop>
                        }
                        </AnimatePresence>

                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolFee;