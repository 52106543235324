import React from 'react';
import { Content } from './style';

const MoneyBox = ({classname_wrapper="", style_wrapper, rise=false, enable_rise=true, title='', amt='', debit=false}) => {
    return(
        <div className={classname_wrapper} style={style_wrapper}>
            <Content>
                <div className='header'>
                    <span className='font-very-small'>{title}</span>
                </div>
                <div className='cont'>
                    <span className={`font-big fw-600 ${debit ? 'red' : 'app-text-color'}`}>{amt} {enable_rise && <i className={`fas fa-angle-${rise ? 'up green' : 'down red'}`}></i>}</span>
                </div>
            </Content>
        </div>
    )
}

export default MoneyBox;