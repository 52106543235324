import styled from "styled-components";

export const Content = styled.div`
    display: inline-block;
    width: 100%;
    color: blue;
    padding: 5px;

    .head{
        font-weight: 600;

    }
    .text{
        font-weight: 500;
        padding-left: 5px;
    }
`;