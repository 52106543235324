import React from 'react';
import { Wrapper } from './style';

const Mallet = ({children, style, classname="flex-33" }) => {
    return(
        <Wrapper style={style}>
            {/* <Content className={classname}> */}
                {children}
            {/* </Content> */}
        </Wrapper>
    )
}

export default Mallet;