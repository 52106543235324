import styled from "styled-components";

export const Content = styled.div`
    label{
        color: var(--appTextColor);
    }
    span.text{
        color: var(--appTextColor);
        display: block;
        font-weight: 500;
        padding: 3px 5px;
    }
    button.text{
        color: #00057a;
        display: block;
        font-weight: 600;
        padding: 3px 5px;
        border: none;
        cursor: pointer;

    }
    img.profile{
        width: 170px;
        height: 170px;
        border-radius: 10px;
        @media(max-width: 540px){
            width: 120px;
            height: 120px;
        }
    }
`;