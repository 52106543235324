import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from './style';

import { joinArray } from "components/app/school/actions/array-utils/Add";

const BreadCrumb = ({removeUrl, appType = "https://voskool.com", arr=[]}) => {
    const rem_http = window.location.href.split('//');

    const url = [appType, ...rem_http[1].split('/').filter((it, i) => i !== 0)];

    console.log(rem_http, window.location.href);
    
    const dt = ["app", "", "http:", "https:", removeUrl];
    const new_url = url.filter(item => !dt.includes(item));
    
    
    const to_map = (arr=[], curr_num=0, first_num=0) => {
        let data = [];
        if(curr_num > first_num){
            for(let i=first_num; i <= curr_num; i++){
                data.push(arr[i]);
            }
        }
        if(curr_num <= first_num){
            data.push(arr[curr_num]);
        }
        return data;
    }
    const crumbs2 = (arr=[]) => {
        const data = arr.map((val, i, array) => {
            const check_http = val.split('//');
            
            if( array.length-1=== i){
                return(
                    <div key={i}><span className="font-small" title={val}> {`${val}`} </span></div>
                )
            }
            if(check_http.length > 1){
                return(
                    <div key={i}><Link className="font-small" to={`${val}`} title={'Voskool'}>{`Voskool`}</Link><i className='fas fa-angle-right font-super-small'></i></div>
                )
            }
            const toMap = to_map(array, i, i > 0 ? 1 : 0);
            return(
                <div key={i}><Link className="font-small" to={`${i=== 0 ? toMap[0] : joinArray(toMap, '/', false)}`} title={val}>{val}</Link><i className='fas fa-angle-right font-super-small'></i></div>
            )
        })
        return data;
    }
    const crumbs = (item = []) => {
        let rows = [];
        const item_len = item.length - 1;
        for(let i = 0; i < item.length; i++){
            if(i === item_len){
                rows.push(<div key={i}><span className="font-small" title={item[i]}> {`${item[i]}`} </span></div>)
            }
            else if(i === 0){
                rows.push(<div key={i}><Link className="font-small" to={`/`} title={item[i]}>{`Voskool`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
            }
            else if(i === 1){
                rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}`} title={item[i]}>{item[i]}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
            }  
            else if(i === 2){
                if(appType){
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
                else{
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
            }  
            else if(i === 3){
                if(appType){
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
                else{
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
            }  
            else if(i === 4){
                if(appType){
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
                else{
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
            } 
            else if(i === 5){
                if(appType){
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[4]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
                else{
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[4]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
            }
            else if(i === 6){
                if(appType){
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[4]}/${item[5]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
                else{
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[4]}/${item[5]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
            }
            else if(i === 7){
                if(appType){
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[4]}/${item[5]}/${item[6]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
                else{
                    rows.push(<div key={i}><Link className="font-small" to={`/${item[1]}/${item[2]}/${item[3]}/${item[4]}/${item[5]}/${item[6]}/${item[i]}`} title={item[i]}>{`${item[i]}`}</Link><i className='fas fa-angle-right font-super-small'></i></div>)
                }
            }
            else{
                rows.push(<div key={i}><span className="font-small" title={item[i]}> {`${item[i]}`} </span><i className='fas fa-angle-right font-super-small'></i></div>)
            }
            
        }
        return rows;
    }
    return(
        <Content>
            {/* {crumbs(arr.length > 0 ? arr : new_url)} */}
            {arr.length > 0 ? crumbs(arr) : crumbs2(new_url)}
        </Content>
    )
}

export default BreadCrumb;