import styled from "styled-components";

export const Wrapper = styled.div`
    margin: 10px;
    width: 100%;
    position: relative;
    
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    .success{
        border: 4px solid rgb(40, 255, 40);
        .text{
            color: rgb(40, 255, 40);
        }
    }
    .red{
        border: 4px solid rgb(255, 60, 40);
        .text{
            color: rgb(255, 60, 40);
        }
    }
    .warning{
        border: 4px solid rgb(255, 255, 40);
        .text{
            color: rgb(255, 255, 40);
        }
    }
    .item-cont{
        padding: 5px;
        margin-right: 5px;
        border-radius: 5px;
        box-sizing: border-box;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.5);
        transition: .3s ease;

        :hover{
            transform: scale(1.1);
        }
        
        .item-number{
            text-align: center;
        }
        .item{
            .text{
                color: #777;
                text-transform:capitalize;
                font-weight: 500;
            }
        }
    }
`;