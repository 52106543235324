import React from 'react';

import BreadCrumb from "components/breadCrumb";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import UnderConstruction from 'components/app/school/SmallerComponents/underConstruction';

import { ComponentWrapper } from "components/app/school/wrapper";

import { HeadComponent } from "components/head";

const SchoolAttendanceStaff = ({user_id}) => {
    return(
        <>
            <HeadComponent title='Staff attendance' />
            <BreadCrumb />
            <ComponentWrapper>
                {/* <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> */}
                <Grid layout='lauto'>
                    <GridContent>
                        <UnderConstruction />
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolAttendanceStaff;
