import React from "react";
import { Content } from "./style";

const TR = ({children, style}) => {
    return(
        <Content style={style}>
            {children}
        </Content>
    )
}

export default TR;