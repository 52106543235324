import styled from "styled-components";

export const Content = styled.div`
    padding: 3px;
    input[type=checkbox]{
        outline: none;
    }
    .s{
        padding-left: 3px;
        font-weight: 500;
    }
`;