import styled from "styled-components";


export const OuterWrapper = styled.div`
	margin-left: 175px;
	@media (max-width: 991px){
		margin-left: 150px;
	}
	@media (max-width: 780px){
		margin-left: 50px;
	}
	@media (max-width: 280px){
		margin-left: 40px;
	}
`;
export const Wrapper = styled.div`
	position: fixed;
	transition: 0.3s ease;
	-webkit-transition: 0.3s ease;
	z-index: 10;
	overflow: auto;
	height: 100vh;
	width: 175px;
	border-bottom-right-radius: 25px;
	
	
    @media (max-width: 991px){
        
            width: 150px;
        
        div ul.navbar_ul li{
            font-size: 12px;
        }
        div ul.navbar_ul li a{
            font-size: 12px;
        }
    }
    @media (max-width: 780px){
		width: 50px;  
    }
	@media (max-width: 280px){
		width: 40px;  
    }
`;

export const Content = styled.div`
     .smv{
	position: fixed;
	left: 50px;
	top: 40px;
	@media (max-width: 280px){
		left: 35px;  
    }
}
 .smv i{
	font-size: 15px;
	color: var(--appBgTextColor);
	padding: 5px;
	cursor: pointer;
	border-radius: 4px;
	@media (max-width: 280px){
		font-size: 11px;
		padding: 3px;
    }

}
 .hmv{
	position: fixed;
	left: 170px;
	top: 40px;
	color: var(--appBgTextColor);
}
 .hmv i{
	font-size: 15px;
	padding: 5px;
	cursor: pointer;
	border-radius: 4px;
	@media (max-width: 280px){
		font-size: 11px;
		padding: 3px;
    }
}
 .user_profile{
	width: 100%;
	padding: 14px 14px 0px 14px;
}
 .user_profile .text_name{
	color: var(--appBgTextColor);
	font-size: 11px;
	text-align: right;
	padding-bottom: 5px;
}
 .user_profile .profile_icon div{
	position: relative;
	padding: 8px;
}
 .user_profile .profile_icon i{
	font-size: 50px;
	padding: 8px;
	color: var(--appBgTextColor);
	position: relative;
}
 .user_profile .profile_icon div{
	width: 85px;
	height: 85px;
}
 .user_profile .profile_icon div img{
	width: 80px;
	height: 77px;
	border-radius: 50%;
}
 .user_profile .profile_icon div span{
	position: absolute;
	width: 10px;
	height: 10px;
	background: green;
	border-radius: 50%;
	bottom: 4px;
	right: 10px;
}
 .user_profile .profile_icon i span{
	position: absolute;
	width: 10px;
	height: 10px;
	background: green;
	border-radius: 50%;
	bottom: 8px;
	right: 7px;
}
 .user_profile .profile_text{
	padding-top: 4px;
}
 .user_profile .profile_text span{
	padding: 4px;
	margin-left: 4px;
	border: 1px solid var(--appTextLightGrey);
	color: var(--appTextLighterGrey);
	font-size: 11px;
	cursor: pointer;
	border-radius: 5px;
	transition: 0.3s ease;
	-webkit-transition: 0.3s ease;
}
 .user_profile .profile_text span:hover{
	border: 1px solid #173f5f;
}
 .user_profile .profile_text span i{
	padding-left: 2px;
	color: var(--appBgTextColor);
}
 .user_profile .name{
	padding-top: 17px;
	
}
 .user_profile .name span{
	color: var(--appBgTextColor);
	font-size: 7px;
	font-weight: 600;
}

 .user_profile2{
	width: 100%;
	background: var(--appBgColor);
	padding: 5px;
	margin-bottom: 2px;
}

 .user_profile2 .profile_icon i{
	font-size: 28px;
	padding: 8px;
	color: var(--appBgTextColor);
	position: relative;
	cursor: pointer;
}
 .user_profile2 .profile_icon div{
	position: relative;
	cursor: pointer;
}
 .user_profile2 .profile_icon div img{
	width: 37px;
	height: 37px;
	border-radius: 25px;
}
 .user_profile2 .profile_icon div span{
	position: absolute;
	width: 6px;
	height: 6px;
	background: green;
	border-radius: 50%;
	bottom: 8px;
	right: 7px;
}
 .user_profile2 .profile_icon i span{
	position: absolute;
	width: 6px;
	height: 6px;
	background: green;
	border-radius: 50%;
	bottom: 8px;
	right: 7px;
}
 .user_profile2 .aside_cont{
	position: fixed;
	transform: translate(45px, -50px);
	z-index: 2;
	padding: 10px;
	display: none;
}
 .user_profile2 .text_name{
	color: var(--appBgTextColor);
	font-size: 11px;
	text-align: center;
}
 .user_profile2 .profile_text{
	padding-top: 4px;
}
 .user_profile2 .profile_text span{
	padding: 4px;
	margin-left: 4px;
	border: 1px solid var(--appTextLightGrey);
	color: var(--appTextLighterGrey);
	font-size: 11px;
	cursor: pointer;
	border-radius: 5px;
	transition: 0.3s ease;
	-webkit-transition: 0.3s ease;
}
 .user_profile2 .profile_text span:hover{
	border: 1px solid var(--appNavTextColor);
}
 .user_profile2 .profile_text span i{
	padding-left: 2px;
	color: #eee;
}
 ul.navbar_ul{
	list-style-type: none;
	padding: 0;
	margin: 0;
	margin-bottom: 20px;
}
 ul.navbar_ul li{
	margin-bottom: 3px;
	font-weight: 500;
	cursor: pointer;
	display: block;
	letter-spacing: .5px;
	-webkit-transition: .4s ease-in-out;
	transition: .4s ease-in-out;
	/*background: #48ACF0;*/
}
 ul.navbar_ul li:hover{
	background: var(--appBgColor);
	color: var(--appBgTextColor); 
}
 ul.navbar_ul .active_nav{
	background: var(--appBgColor);
	color: var(--appBgTextColor); 
}
 ul.navbar_ul .active_nav a,  ul.navbar_ul .active_nav span.link{
	background: var(--appBgColor);
	color: var(--appBgTextColor); 
}
 ul.navbar_ul li a,  ul.navbar_ul li span.link{
	font-size: 13px;
	display: block;
	font-weight: 500;
	text-decoration: none;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
 ul.navbar_ul li:hover a, ul.navbar_ul li:hover span.link{
	color: var(--appBgTextColor);
}
 ul.navbar_ul li .arrows{
position: relative;
}
 ul.navbar_ul li .arrows i{
	position: absolute;
	right: 0;
	bottom: 100%;
}
 ul.navbar_ul li .icon{
	position: relative;
}
 ul.navbar_ul li .icon i{
	position: absolute;
}
 ul.navbar_ul li .text{
	padding-left: 25px;
}
 ul.navbar_ul li .inner_nav{
	list-style-type: none;
	padding: 0;
}
 ul.navbar_ul li .inner_nav{
	padding-left: 3px;
}
 ul.navbar_ul li .inner_nav li{
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	padding: 4px 0;
}
ul.navbar_ul .active_nav .inner_nav li a{
	color: var(--appBgTextColor);
	padding-left: 5px;

}
ul.navbar_ul li:hover .inner_nav li a{
	color: var(--appBgTextColor);
}
 ul.navbar_ul li .inner_nav li:hover a{
	color: var(--appBgTextColor);
}
 ul.navbar_ul li .inner_nav li a{
	display: block;
	font-size: 10px;
	color: var(--appNavTextColor);
}
    @media(max-width:780px){
        ul.navbar_ul li span.text{
			margin-left: 5px;
        }
        
        ul.navbar_ul li:hover{
            background: none;
            color: var(--appBgSecColor);
        }
        ul.navbar_ul li a:hover, ul.navbar_ul li span.link:hover{
			background: none;
            color: var(--appBgSecColor);
        }
		ul.navbar_ul li:hover .inner_nav li a{
			color: var(--appBgSecColor);
		}
        ul.navbar_ul li .icon{
            padding-left: 9px;
            
            padding-top: 15px;
        }
        ul.navbar_ul li .icon i{
            font-size: 16px;
        }
        ul.navbar_ul li .icon i:hover{
            background: none;
            color: var(--appBgSecColor);
        }
		ul.navbar_ul .active_nav .inner_nav li a{
			color: var(--appBgSecColor);
		}
        ul.navbar_ul .active_nav{
			background: none;
            color: var(--appBgSecColor);
        }
        ul.navbar_ul .active_nav a, ul.navbar_ul .active_nav span.link{
			background: none;
            color: var(--appBgSecColor); 
        }
        ul.navbar_ul li .inner_nav{
            width: 100%;
        }
        
        ul.navbar_ul li .inner_nav li{
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        }
        ul.navbar_ul li .inner_nav li:hover a{
            color: var(--appBgSecColor);
        }
        ul.navbar_ul li .inner_nav li a{
            display: block;
            font-size: 10px;
            color: var(--appNavTextColor);
        }
    }
`;