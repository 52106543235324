import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    /* display: block; */
    tr th{
        border-collapse: collapse;
        border: 1px solid var(--appTextLighterGrey);
    }
    table, tr, td{
        border: 1px solid var(--appTextLighterGrey);
        border-radius: 5px;
        /* border-collapse: collapse; */
    }
`;
export const Content = styled.table`
    margin-bottom:1rem;
    background-color:transparent;
    display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;
`;