import React, { useState, useEffect, useMemo, Fragment, useCallback} from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import View from 'components/app/school/SmallerComponents/mallet/view';
import Mash from 'components/app/school/SmallerComponents/mash';
import Btn from 'components/app/school/SmallerComponents/mash/btn';
import ImgViewer from 'components/app/school/SmallerComponents/imgViewer';
import Img from 'components/app/school/SmallerComponents/imgViewer/img';
import Input from 'components/app/school/SmallerComponents/input';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { seperatePoint } from 'components/app/school/actions/money';


const ViewApplicantStaff = ({school_id, user_id, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialApplicantState = useMemo(() => ({staff:{id:null, country_origin:{id:null}, nok:{id:null}, user:{id:null, profile_pic:''}}, school:{id:null}, school_branch:{id:null}, staff_type:{id:null, name:''}, id:null, }), []);
    const initialAcceptFormState = useMemo(() => ({i:'', id:0, cert_type:"", salary:'0', salary_point:'', curr_id:0, curr_symbol:'', school:0, school_branch:0, staff_type:0}), []);

    const [applicantData, setApplicantData] = useState(initialApplicantState)
    const [imgViewer, setImgViewer] = useState({src:'', alt:"", title:''});
    const [imgState, setImgState] = useState({num:0, next:0, prev:0});
    const [acceptForm, setAcceptForm] = useState(initialAcceptFormState)

    const [allImg, setAllImg] = useState([]);
    const [allSalaryData, setAllSalaryData] = useState([]);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [notify, setNotify] = useState(initialSuccessState);

    const [imgType, setImgType] = useState('');
    const [navigate, setNavigate] = useState('');

    const [preloading, setPreloading] = useState(false);
    const [openImgViewer, setOpenImgViewer] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [exist, setExist] = useState(true);
    const [loading, setLoading] = useState(false);

    const nav = useNavigate();

    const { id } = useParams();

    
    const fetchApplicant = useCallback((sid, id) => {
        setPreloading(true);
        setExist(false);
        setError(initialErrorState);
        setApplicantData(initialApplicantState);
        if(sid){
            appServices.getSchoolStaffSchoolApplication(sid, id).then(res => {
                setPreloading(false);
                setExist(true);
                setApplicantData({...res.data});
            }).catch(e => {
                setPreloading(false);
                setExist(false);
                setApplicantData(initialApplicantState);
                setError({title:'Data Error', data:['Something Went Wrong']})
            })
        }
    }, [initialApplicantState, initialErrorState]
    );

    const fetchAllSalary = (sid=0, sbid=0, stft=0) => {
        appServices.getAllStaffSchoolSalary(sid, sbid, stft).then(res => {
            setAllSalaryData([...res.data]);
        }).catch(() => {
            setError({data:["Internal Server Error"], title:"Salary Data Error"});
            setAllSalaryData([]);
        })
    }
    const getSalaryData = (num=0) => {
        num = parseInt(num)
        const filterItem = allSalaryData.filter((item, i) => {
            const n = i + 1;
            return n === num;
        })
        setAcceptForm({...acceptForm, i:num, salary:seperatePoint(filterItem[0].salary), salary_point:seperatePoint(filterItem[0].salary, true), id:filterItem[0].id, curr_symbol:filterItem[0].curr_symbol,
            curr_id:filterItem[0].currency, cert_type:filterItem[0].cert_type, school:school_id, school_branch:filterItem[0].school_branch, staff_type:filterItem[0].staff_type})
    }
    const handleAcceptFormChange = (e) => {
        if(e.target.name === "i" && e.target.value){
            setAcceptForm({...acceptForm, [e.target.name]:e.target.value})
            getSalaryData(e.target.value);
        }
        else if(e.target.name === "i" && e.target.value === ""){
            setAcceptForm({...acceptForm, ...initialAcceptFormState})
        }
        else{
            setAcceptForm({...acceptForm, [e.target.name]:e.target.value})
        }
    }
    console.log(applicantData);
    const handleAllImgClick = (alt, title) => {
        setOpenImgViewer(true);
        setImgViewer({src:'', alt:alt, title:title})
        setImgType('')
        appServices.getAllStaffSchoolCVData(applicantData.staff.user.id).then(res => {
            setImgViewer({src:`${BACKEND_URL}${res.data[0].cv}`, alt:alt, title:title});
            setImgState({num:0, prev:0, next:res.data.length > 0 ? 1 : 0})
            const result = res.data;
            setAllImg([...result]);
            setImgType('cv')
        }).catch(e => {
            setAllImg([]);
        })
    }
    const handleClickBtn = (type) => {
        if(type === "interview"){
            setSuccess(initialSuccessState);
            setError(initialErrorState);
            const data = {
                is_interviewed:true
            }
            appServices.updateStaffSchoolApplication(applicantData.id, data).then(() => {
                fetchApplicant(school_id, id);
                setSuccess({title: 'Interview Sent', text:'You have successfully started an interview with this applicant'})
            }).catch(e => {
                setError({title:'Interview Error', data:['Something Went Wrong']});
            })
        }
    }
    const handleImgClick = (imgSrc, alt="blank", title="") => {
        setOpenImgViewer(true);
        setImgType('');
        setImgState({next:0, prev:0, num:0})
        setImgViewer({src:imgSrc, alt:alt, title:title});
    }
    const handleClickedImgInner = (num) => {
        const filterItem = allImg.filter((item, i) => {
            return i === num
        })
        const next = num + 1;
        const prev = num - 1;
        setImgViewer({...imgViewer, src:`${BACKEND_URL}${filterItem[0].cv}`, alt:'CV'});
        setImgState({num:num, next:allImg.length > next ? next : 0, prev:num > 0 ? prev : 0 })
        setOpenImgViewer(true);
        
    }
    const handleUpdateSalary = (e) => {
        e.preventDefault();  
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setDisableBtn(false);
        const sal_point = acceptForm.salary_point ? acceptForm.salary_point : '00'
        const sal = acceptForm.salary.replace(/,|\./g, '') + "." + sal_point;

        const data = {
            salary: sal,
            staff_cert_type: acceptForm.id,
            created_by: user_id
        }
        const app_data = {
            is_accepted: true
        }
        appServices.updateStaffSchoolApplicationSalary(applicantData.id, acceptForm.id, data).then(res => {
            appServices.updateStaffSchoolApplication(applicantData.id, app_data).then(()=> {
                setDisableBtn(true);
                setSuccess({text:"This Applicant Has Been Accepted, Redirecting....", title:"Success"});
                setNotify(initialSuccessState);
                setAcceptForm(initialAcceptFormState);
                setTimeout(() => {
                    setNavigate(`/${school_title}/staff/applied`);
                }, 1500);
            }).catch(e => {
                setError({data:[e.response.data.detail], title:"Salary Error"});
                setLoading(false);
            })
            
        }).catch(e => {
            setError({data:[e.response.data.detail], title:"Salary Error"});
            setLoading(false);
        })
    }
    const onClickAccept = (id) => {
        setNotify({text:'', timer:0, id:0})
        setTimeout(() => {
            setNotify({text:'Fill In The Form Below To Accept Applicant', timer:300000, id:id})
        }, 100);
    } 
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    // const firstImg = (img=[]) => {
    //     img.shift()
    //     return img;
    // }
    const imgOpt = (type) => {
        if(type === "cv"){
            return (allImg.map((item, i) => {
                const n = i+1;
                return(
                    <Fragment key={i}>
                        <Img selected={imgState.num === i ? true : false} src={`${BACKEND_URL}${item.cv}`} alt={'CV'} text={`Page ${n}`} onClick={() => handleClickedImgInner(i)} />
                    </Fragment>
                )
            }))
        }
        else{
            return(<></>)
        }
        
    }
    const acceptBtn = () => {
        if(applicantData.is_interviewed && !applicantData.is_accepted){
            return (
                <Btn color='green' align='center' onClick={() => onClickAccept(id)}>Accept <i className='fas fa-user-plus'></i></Btn>
            )
        }   
    }

    useEffect(() => {
        fetchApplicant(school_id, id);
    }, [school_id, id, fetchApplicant]);
    useEffect(() => {
        if(applicantData.id){
            fetchAllSalary(school_id, applicantData.school_branch.id, applicantData.staff_type.id);
        }
    }, [exist, school_id, applicantData])
    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    const insertSalaryOption = allSalaryData.map((item, i) => {
        const n = i + 1;
        return(
            <option value={n} key={i}>{`${item.cert_type} -`} <span className='font-super-small'>{item.staff_type_name.toUpperCase()}</span></option>
        )
    })

    return(
        <>
            <HeadComponent title='View Staff Applicant' />
            <BreadCrumb arr={['Voskool', school_title, 'staff', 'applied', 'View Applicant']} />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {notify.id && (<Notification timer={notify.timer} text={notify.text}>
                        <form onSubmit={e => handleUpdateSalary(e)}>
                            <div className='disp-flex wrap padd-4px'>
                                <div className='flex-100'>
                                    <label className='font-super-small'>Select Cert Type: </label>
                                    <select name="i" value={acceptForm.i} onChange={e => handleAcceptFormChange(e)} className='app-theme form-control-theme font-slightly-small'>
                                        <option value="">Select Certificate Type</option>
                                        {insertSalaryOption}
                                    </select>
                                </div>
                            </div>
                            <div className='disp-flex wrap padd-4px'>
                                <div className='flex-100'>
                                    <label className='font-super-small'>Salary: </label>
                                    <Input required type="money" handleChange={e => handleAcceptFormChange(e)} name="salary" point_display={acceptForm.salary ? true : false} point_name='salary_point' point_value={acceptForm.salary_point} currency={acceptForm.curr_symbol} 
                                                currency_value={acceptForm.curr_id} handlePointChange={e => handleAcceptFormChange(e)} value={acceptForm.salary} placeholder='Salary' classname="font-very-small app-theme form-control-theme" />
                                </div>
                            </div>
                            <div className='disp-flex wrap padd-4px'>
                                <div className='flex-100 align-center'>
                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Accept <i className={"fas fa-paper-plane"}></i> </button>
                                </div>
                            </div>
                        </form>
                    </Notification>)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`View Applicant's Data`} header_icon="fas fa-eye">
                    {preloading && (<ul>
                        <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) }
                    {exist && (
                    <>
                    {loading && (<Loader />)}
                        <Section icon="fas fa-info-circle" header="Personal Information">
                            <Mallet>
                                <View type='pp' label='Profile Picture' src={`${BACKEND_URL}${applicantData.staff.user.profile_pic}` } alt="Profile" />
                                <View type="arr" arr={[{label:'Fullname', text:`${applicantData.staff.user.last_name} ${applicantData.staff.user.first_name}`},
                                                        {label:'Email', text:`${applicantData.staff.user.email}`}, {label:'Phone Number', text:`+${applicantData.staff.user.phone_number}`},
                                                        {label:'Gender', text:applicantData.staff.gender === "F" ? 'Female' : 'Male'}]} />
                                <View type="arr" arr={[{label:'Type', text:`${applicantData.staff_type.name}`, classname:'text-capitalize'}, 
                                                    {label:'Certificate Name', text:`${applicantData.staff.cert_name}`, classname:'text-capitalize'},
                                                    {label:'Certificate Type', text:`${applicantData.staff.cert_type}`, classname:'text-capitalize'},
                                                    {label:'Subject Capability', text:`${applicantData.staff.subject_handle}`, classname:'text-capitalize'},
                                                ]} 
                                />
                            </Mallet>
                        </Section>
                        <Section icon="fas fa-info-circle" header="Other Information">
                            <Mallet>
                                <View type='text' label='Qualification'>{applicantData.staff.qualification}</View>
                                <View type='img' label='Certificate' onClickImg={() => handleImgClick(`${BACKEND_URL}${applicantData.staff.cert}`, 'Certificate', 'Certificate')}>Click to view image</View>
                                <View type='img' label='All Curriculum Vitae (CV)' onClickImg={() => handleAllImgClick("CV", "Curriculum Vitae (CV)")}>Click to view all images</View>
                                <View type='img' label='National Id' onClickImg={() => handleImgClick(`${BACKEND_URL}${applicantData.staff.national_id}`, 'National Id', 'National ID')}>Click to view image</View>
                                <View type='text' label='Date Of Birth'>{applicantData.staff.dob}</View>
                                <View type='text' label='Marital Status' classname='text-capitalize'>{applicantData.staff.marital_status}</View>
                                <View type='text' label='Residential Address'>{`${applicantData.staff.address} - ${applicantData.staff.country_origin.name}`}</View>
                                <View type='text' label='Work Experience'>{applicantData.staff.experience}</View>
                                <View type='text' label='Religion' classname='text-capitalize'>{applicantData.staff.rel}</View>
                                <View type='text' label='Languages Spoken'>{applicantData.staff.lang_spoken}</View>
                                <View type='text' label='School Branch Applied' classname='text-capitalize'>{applicantData.school_branch.name}</View>
                                <View type='text' label='Other Phone Number'>{applicantData.staff.work_phone}</View>
                                <View type='text' label='Date Applied'>{applicantData.created}</View>
                            </Mallet>
                        </Section>
                        <Section icon="fas fa-info-circle" header="Kin's Information">
                            <Mallet>
                                <View type='text' label='Next Of Kin'>{applicantData.staff.nok.name}</View>
                                <View type='text' label="Kin's Fullname" classname='text-capitalize'>{applicantData.staff.nok_name}</View>
                                <View type='text' label="Kin's Email">{applicantData.staff.nok_email}</View>
                                <View type='text' label="Kin's Phone Number">{applicantData.staff.nok_phone}</View>
                                <View type='text' label="Kin's Other Phone Number">{applicantData.staff.nok_work_phone}</View>
                                <View type='text' label="Kin's Address">{applicantData.staff.nok_address}</View>
                            </Mallet>
                        </Section>
                    </>
                    )}
                    </GridContent>
                </Grid>
                {exist && (
                    <Mash>
                        {acceptBtn()}
                        {!applicantData.is_interviewed && (<Btn disabled={disableBtn} color='blue' align='center' onClick={() => handleClickBtn('interview')}>Start Interview <i className='fas fa-question-circle'></i></Btn>)}
                        <Btn disabled={disableBtn} color='red'>Reject <i className='fas fa-running'></i></Btn>
                    </Mash>
                )}
                {openImgViewer && (
                    <ImgViewer onClickNextBtn={() => handleClickedImgInner(imgState.next)} 
                        onClickPrevBtn={() => handleClickedImgInner(imgState.prev)} next={imgState.next ? true : false} prev={imgState.num > 0 ? true : false}
                        src={imgViewer.src} alt={imgViewer.alt} title={imgViewer.title} handleClose={() => setOpenImgViewer(false)}>
                        {imgType ?
                            imgOpt(imgType) :
                            (
                                <Img src={imgViewer.src} alt={imgViewer.alt} selected />
                            )
                        }
                    </ImgViewer>
                )}
            </ComponentWrapper>
        </>
    )
}

export default ViewApplicantStaff;