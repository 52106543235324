import React, {useState, useEffect,  Fragment} from 'react';

import { Wrapper, Content } from './style';

import { timifyInterval } from 'components/app/school/actions/timify';


const CountDownTimer = ({type="date", start, end, setCurrentState, title="", style, currentTimer=0}) => {
    const [state, setState] = useState(0);

    useEffect(() => {
        if(type === "date" && !currentTimer){
            const end_time = end ? new Date(end).getTime() : 0;
            const timer = setInterval(() => {     
                const diff = end_time - new Date().getTime();
                if(end_time > new Date().getTime()){
                    setState(diff);
                } else {
                    setState(0);
                }
            }, 1000);
            return () => clearInterval(timer);
        }
        setState(currentTimer);
        return;
    }, [type, end, currentTimer]);
    const timify = timifyInterval(state/1000);
    return(
        <>
            <Wrapper>
                {title && (
                    <div className='align-center padd-5px' style={{margin:'10px 0'}}>
                        <span className='fw-600 font-med text-capitalize'>{title}</span>
                    </div>
                )}
                <Content>
                    {Object.keys(timify).map((item, i) => {
                        const ins_type = () => {
                            if(timify['hour'] < 2 && timify['week'] < 1 && timify['day'] < 1 && timify['year'] < 1){
                                return 'red';
                            } else if(timify['week'] < 1 && timify['day'] < 4 && timify['year'] < 1 && timify['day'] > 1){
                                return 'warning';
                            }
                            else if(timify['week'] < 1 && timify['day'] < 1 && timify['year'] < 1 && timify['hour'] < 6){
                                return 'warning';
                            } else {
                                return 'success';
                            }
                        }
                        const ins = () => {
                            if((item === "year" && timify[item] < 1) || (item === "week" && timify[item] < 1) ||  (item === "day" && timify[item] < 1) || (item === "month")){
                                return(
                                    <></>
                                )
                            } else {
                                return(
                                    <div className={`item-cont app-opp-theme ${ins_type()}`} style={style}>
                                        <div className='item-number'>
                                            <span className="text-number app-opp-text-color fw-700 font-med">{timify[item].length > 0 ? `0${timify[item]}` : timify[item]}</span>
                                        </div>
                                        <div className='item'>
                                            <span className='text app-opp-text-color font-super-small'>{item}</span>
                                        </div>
                                    </div>
                                )
                            }
                        }
                        return(
                            <Fragment key={i}>
                                {ins()}
                            </Fragment>
                        )
                    })}
                    
                </Content>
            </Wrapper>
        </>
    )
}

export default CountDownTimer;