import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    background: var(--appTheme);
`;

export const Content = styled.div`
    img.bg-img{
        width: 100%;
        height: 40vh;

        @media(max-width:1280px){
            height: 30vh;
        }
        @media(max-width:920px){
            height:20vh;
        }
        @media(max-width:420px){
            height:15vh;
        }
    }
    .inner{
        padding-bottom: 20px;
        .profile{
            position: absolute;
            width: 12rem;
            height: 14rem;
            transform: translate(20%, -70%);
            @media(max-width:1280px){
                width: 10rem;
                height: 12rem;
            }
            /* @media(max-width:1024px){
                width: 8rem;
                height: 10rem;
            } */
            @media(max-width:540px){
                transform: translate(100%, -80%);
                width: 9rem;
                height: 10rem;
            }
            @media(max-width:420px){
                transform: translate(50%, -80%);
                width: 9rem;
                height: 10rem;
            }
            @media(max-width:390px){
                width: 8rem;
                height: 9rem;
            }
            @media(max-width:320px){
                width: 7rem;
                height: 8rem;
            }
            img{
                width: 100%;
                height: 100%;
                border-radius: 10px;
                /* border: 2px solid var(--appBgColor); */
            }
        }
        .profile_text{
            display: block;
           h1{
               margin-left: 10rem;
               color: var(--appTextColor);
               @media(max-width: 540px){
                   text-align: center;
                   padding-top: 2rem;
                   margin-left: 0rem;
               }
           }
        }
    }
`;