import React from "react";
import { Wrapper } from "./style"

const MoneyBoxWrapper = ({children, classname="", style}) => {
    return(
        <Wrapper className={classname} style={style}>
            {children}
        </Wrapper>
    )
}

export default MoneyBoxWrapper;