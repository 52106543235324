import React, { useState, useEffect, useMemo, useRef, Fragment} from 'react';
import { Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import SchoolTimeTablePdf from 'components/app/school/attendance/pdf';

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";
import {attendanceIcon} from 'components/app/school/SmallerComponents/attendanceIcon';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
// import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { spread_attendance_days } from 'components/app/school/actions/array-utils/Attendance';
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { filterInnerArray } from 'components/app/school/actions/array-utils/Filter';


const SchoolAttendanceStudent = ({user_id, school, school_id, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    // const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [malletState, setMalletState] = useState({session:'', pre:false, from_date:'', to_date:'', school_branch:'', classes:'', curr_session:'', is_res_date_editable:false, is_start_student_attendance:false, active_session:false});

    const [allSessionData, setAllSessionData] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allStudAttend, setAllStudAttend] = useState([]);

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [reload, setReload] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);

    const [exportType, setExportType] = useState('');

    const initialChangedRecordState = useRef(true);

    const handleMalletChange = e => {
        setDataTableLoading(true);
        setMalletState({...malletState, [e.target.name]:e.target.value, pre:false});
    }
    const onInitTimetable = ()  => {
        setLoading(true);
        appServices.initSchoolStudentAttendance(school_id).then(res => {
            setLoading(false);
            setSuccess({text:"Student's Attendance Auogenerated Successfully", title:"Success"});
            setDataTableLoading(true);
        }).catch(e => {
            setLoading(false);
        })
    }

    const fetchAllSchoolSession = (sid=0) => {
        if(sid){
            appServices.getCurrentSession(sid).then(sess_res => {
                if(sess_res.data.id){
                    setMalletState((prev) => !prev.session && {...prev, session:sess_res.data.id, curr_session:sess_res.data.id, is_res_date_editable:sess_res.data.is_res_date_editable, active_session:sess_res.data.active_session, is_start_student_attendance:sess_res.data.is_start_student_attendance, pre:true})
                }
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", handleClick: () => setReload(true)});
            })
            appServices.getAllSession(sid).then(res => {
                setAllSessionData(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    //  const fetchSchoolWeekDays = (sid) => {
    //     if(sid){
    //         setError({data:[], title:''});
    //         appServices.getAllSchoolWeekDayData(sid).then(res => {
    //             setAllDays(res.data);
    //         }).catch(e => {
    //             setError({data:["Internal Server Error"], title:"Week Days Data Fetch Error",click_text:'Reload', handleClick: () => setReload(true)});
    //         })
    //     }
    // }
    const fetchAllSchoolBranch = (sid) => {
        if(sid){
        setError({data:[], title:''});
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData(res.data);
            if(res.data.length){
                setMalletState((prev) => !prev.school_branch && {...prev, school_branch:res.data[0].id} )
            }
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
        }
    }
    const fetchAllClasses = (sid) => {
        if(sid){
        setError({data:[], title:''});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClassesData(res.data);
        }).catch(e => {
            setAllClassesData([]);
            setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
        }
    }
    const fetchAllStudAttend = (pre=true, sid, ssid, sbid, cid, from_date, to_date) => {
        if(sid && ssid){
            setError({data:[], title:''});
            setPreloading(pre);
            setSpinLoading(!pre);
            appServices.getAllSchoolStudentAttendance(sid, ssid, sbid, cid, from_date, to_date).then(res => {
                setAllStudAttend(res.data);
                setPreloading(false);
                setSpinLoading(false);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Attendance Data Fetch Error'});
                setAllStudAttend([]);
                setPreloading(false);
                setSpinLoading(false);
            })
        }
    }
    // const fetchAllStudents = (pre=true, sid, sbid, cid) => {
    //     setError({data:[], title:''});
    //     setPreloading(pre);
    //     setSpinLoading(!pre);
    //     appServices.getAllStudent_Admin(sid, sbid, '', cid, 1, 500).then(res => {
    //         setAllStudentData({...res.data, results:addItemToArray(res.data.results, {is_attended:false, is_added:false})});
    //         setPreloading(false);
    //         setSpinLoading(false);
    //     }).catch(e => {
    //         setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Student Data Fetch Error'});
    //         setPreloading(false);
    //         setSpinLoading(false);
    //         setAllStudentData({count:0, previous:undefined, next:undefined, results:[]});
    //     })
    // }

    const onClickStartAttendance = (sid=0) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        setPreloading(true);
        appServices.startStudentSchoolAttendanceSession(malletState.session).then(res => {
            setReload(true);
            setLoading(false);
            setPreloading(false);
            setSuccess({text:"Student's Attendance Started Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Attendance Error'});
            setLoading(false);
            setPreloading(false);
        })
    }
    

    useEffect(() => {
        fetchAllSchoolSession(school_id);
        fetchAllSchoolBranch(school_id);
        fetchAllClasses(school_id);
        // fetchSchoolWeekDays(school_id);
    }, [school_id]);

    useEffect(() => {
        if((dataTableLoading && !malletState.session) || (dataTableLoading && !malletState.school_branch) || (dataTableLoading && !malletState.classes)){
            setDataTableLoading(false);
            setAllStudAttend([]);
            setExportType('');
            return;
        }
        if((dataTableLoading && malletState.from_date && malletState.classes) || (dataTableLoading && malletState.to_date && malletState.classes)){
            if(initialChangedRecordState.current){
                initialChangedRecordState.current = false;
                return;
            }
            setSpinLoading(true);
            const timeout = setTimeout(() => {
                setDataTableLoading(false);
                // fetchAllStudents(malletState.pre, school_id, malletState.school_branch, malletState.classes);
                setExportType('');
                fetchAllStudAttend(malletState.pre, school_id, malletState.session, malletState.school_branch, malletState.classes, malletState.from_date, malletState.to_date);
            }, 5200)
            return () => clearTimeout(timeout);
        }
        if(dataTableLoading){
            setDataTableLoading(false);
            setExportType('');
            fetchAllStudAttend(malletState.pre, school_id, malletState.session, malletState.school_branch, malletState.classes);
            return;
        }
    }, [school_id, dataTableLoading, malletState])

    useEffect(() => {
        if(reload){
            setReload(false);
            fetchAllSchoolSession(school_id);
            fetchAllSchoolBranch(school_id);
            fetchAllClasses(school_id);
            // fetchSchoolWeekDays(school_id);
            fetchAllStudAttend(malletState.pre, school_id, malletState.session, malletState.school_branch, malletState.classes);
        }
    }, [reload, malletState, school_id])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={() => error.handleClick()}  />)
    })
    // const styleAttend = {margin:'3px 5px', border:'1px solid #888', padding:'3px',display:'inline-block'}
    const insertSessionOption = allSessionData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.session} - ${item.term_name} term`}</option>
        )
    })
    const insertClassesOption = allClassesData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.name}`}</option>
        )
    })
    const insertSchoolBranchOption = allSchoolBranchData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.name}`}</option>
        )
    })
    const getClass = malletState.classes ? allClassesData.filter((item) => item.id === parseInt(malletState.classes))[0] : {id:0, name:''};
    const insertBtnStartAttendance = () => {
        if(parseInt(malletState.session) === parseInt(malletState.curr_session) && malletState.active_session && malletState.is_res_date_editable && !malletState.is_start_student_attendance){
            return(
                <div className="flex-100 padd-5px align-center">
                    <button type="button" onClick={() => onClickStartAttendance(school_id, parseInt(malletState.session))} className='mybtn font-slightly-small bg-green app-bg-text-color fw-700'>Start Student Attendance</button>
                </div>
            )
        }
        else if(parseInt(malletState.session) === parseInt(malletState.curr_session) && malletState.active_session && !malletState.is_res_date_editable && !malletState.is_start_student_attendance){
            return(
                <div className="flex-100 padd-5px align-center">
                    <Link to={`/${school_title}/attendance`}  className='btn font-slightly-small bg-blue app-bg-text-color fw-700'> <i className='fas fa-arrow-left'></i> Start School Attendance</Link>
                </div>
            )
        }
        else{
            return(
                <></>
            )
        }
    }
    const insertSchoolWeekDayTd = () => {
        const ins = spread_attendance_days(allStudAttend, 5).map((item, i) => {
            const item_len = item.length;
            return(
                <Fragment key={i}>
                    <Tr sn={true} btn={false}>
                        <div className="c-data " style={{width:'100%'}}><span className="font-small disp-block fw-700 align-center app-text-color">FROM {convertDate(item[0].attend_day, 'ddMMyyyy')} TO {convertDate(item[item_len-1].attend_day, 'ddMMyyyy')}</span></div>
                    </Tr>
                    {item[0].students.map((stud_item, j) => {
                        const filter_stud = filterInnerArray(item, 'students', 'student', stud_item.student);
                        return(
                            <Fragment key={j}>
                                <Tr btn={false}>
                                    <div className="c-data"><span className="font-very-small disp-block fw-600  text-capitalize app-text-color">{stud_item.sur_name ? stud_item.sur_name : stud_item.user_sur_name} {stud_item.first_name ? stud_item.first_name : stud_item.user_first_name}</span></div>
                                    <div className="c-data" style={{width:'90%', display:'flex', flexWrap:'wrap', justifyContent:'stretch'}}>
                                        {filter_stud.map((inner_item, k) => {
                                            return(
                                                <Fragment key={k}>
                                                    <span  className="font-super-small disp-inline align-center app-text-color" style={{padding:'5px', paddingRight:'10px'}}>{convertDate(inner_item.attend_day, 'ddMM')} <br /> {inner_item.day_name.toString().slice(0, 3)} <br /> {attendanceIcon('', inner_item.students.length === 1 ? inner_item.students[0].is_present : false, inner_item.students.length === 1 ? inner_item.students[0].is_absent : false)} </span>
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </Tr>
                            </Fragment>
                        )
                    })}
                </Fragment>
            )
        })
        return(
            <>
            {!loading && (
                <>
                {exportType !== "pdf" ? (
                    <Tr sn={true} btn={false}>
                        <div className="c-data align-center" style={{width:'100%', textAlign:'center'}}><button type="button" onClick={() => setExportType('pdf')} className="font-very-small btn disp-block fw-600 bg-blue br align-center" style={{color:'#ccc'}}>Export as PDF <i className='fas fa-file'></i></button></div>
                    </Tr>
                ) : (
                    <Tr sn={true} btn={false}>
                        <div className="c-data" style={{width:'100%', color:'#555'}}><SchoolTimeTablePdf type="student" school={school} school_branch_id={malletState.school_branch} sess_id={malletState.session} classes={getClass} user_id={user_id} from_date={malletState.from_date} to_date={malletState.to_date} is_download /></div>
                    </Tr>
                )}
                </>
            )}
            {ins}
            </>
        )
    } 

    return(
        <>
            <HeadComponent title='Manage Student Attendance' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage Student Attendance`} header_icon="fas fa-tasks">
                        {loading && (<Loader />)}
                        {allStudAttend.length >0 && (
                             <div className='disp-block align-right padd-4px'>
                                <button type="button" className='btn app-bg-color padd-4px app-bg-text-color font-super-small br hover-opacity' onClick={() => onInitTimetable()}>Autogenerate Timetable <i className='fas fa-auto'></i></button>
                            </div>
                        )}
                        <Section>
                            <Mallet>
                                <div className="flex-50-line"><label className='font-super-small'>Select Term/Session: 
                                    <select name="session" onChange={e => handleMalletChange(e)} value={malletState.session} className='form-control-theme font-slightly-small  text-capitalize' >
                                        <option value="">Select Term / Session</option>
                                        {insertSessionOption}
                                    </select></label>
                                </div>
                                <div className="flex-50-line"><label className='font-super-small'>Select School Branch: 
                                    <select name="school_branch" onChange={e => handleMalletChange(e)} value={malletState.school_branch} className='form-control-theme font-slightly-small  text-capitalize' >
                                        <option value="">Select School Branch</option>
                                        {insertSchoolBranchOption}
                                    </select></label>
                                </div>
                                <div className="flex-100"><label className='font-super-small'>Select Class: 
                                    <select name="classes" onChange={e => handleMalletChange(e)} value={malletState.classes} className='form-control-theme font-slightly-small  text-capitalize' >
                                        <option value="">Select Class</option>
                                        {insertClassesOption}
                                    </select></label>
                                </div>
                                <div className="flex-50-line"><label className='font-super-small'>FROM:
                                    <input type="date" onChange={e => handleMalletChange(e)} name="from_date" value={malletState.from_date} className='form-control-theme font-slightly-small' />
                                </label></div>
                                <div className="flex-50-line"><label className='font-super-small'>TO:
                                    <input type="date" onChange={e => handleMalletChange(e)} name="to_date" value={malletState.to_date} className='form-control-theme font-slightly-small' />
                                </label></div>
                                {insertBtnStartAttendance()}
                            </Mallet>
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                <Table>
                                    {spinLoading && <Spinner />}
                                    <Tr btn={false}  header={true} text_style='c-data' text={[{text:"Student"}, {text:'Attendance', style:{width:'90%'}}]} />
                                    {allStudAttend.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    (<>
                                        {insertSchoolWeekDayTd()}
                                    </>)                         
                                    }
                                </Table>
                            </>
                            )}
                        </Section>
                        
                        
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolAttendanceStudent;