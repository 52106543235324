import React, { Fragment } from "react";
import { Content } from './style';

const Option = ({children, type="checkbox", name='', value, checked=false, disabled=false, required=false, classname="font-very-small", contentStyle, onChange}) => {
    const func = () => {
        if(type === "checkbox"){
            return(
                <Fragment>
                    <input type="checkbox" name={name} disabled={disabled} required={required} value={value} checked={checked} onChange={onChange} />
                    <span className={`s ${classname}`}>
                        {children}
                    </span>
                </Fragment>
            )
        }
        else if(type === "textarea"){
            return(
                <Fragment>
                    <textarea onChange={onChange} className={classname} name={name} value={value} checked={checked} disabled={disabled} required={required}>
                        { children }
                    </textarea>
                </Fragment>
            )
        }
        else{
            return(
                <>{children}</>
            )
        }
    }
    return(
        <>
            <Content style={contentStyle}>
                {func()}
            </Content>
        </>
    )
}

export default Option;

