import { sortArrayByNumber } from "./Sort";

export const deleteItemFromInnerArray = (arr=[], arr_num=0, inner_array_name='', num=0) => {
    const check = arr.find((it) => {
        return it.num === arr_num
    })
    if(check){
        const check = arr[arr_num][inner_array_name].find((it) => {
            return it.num === num
        })
        if(check){
            const filterArray = arr.filter((it) => {
                return it.num !== arr_num;
            })
            const filterInnerArray = arr[arr_num][inner_array_name].filter((it) => {
                return it.num !== num;
            })
            const filterArray2 = arr.filter((it) => {
                return it.num === arr_num;
            })
            const all_item_array = {...filterArray2[0], [inner_array_name]:sortArrayByNumber(filterInnerArray)};
            return sortArrayByNumber([...filterArray, {...all_item_array}]);
        } else {
            return arr;
        }
    } else{
        return arr;
    }
}

export const deleteItemFromInnerSecondArray = (arr=[], first_num=-1, second_num=-1, num=-1, first_arr_name="", second_arr_name="") => {
    const check = arr.find((it) => {
        return it.num === first_num
    })
    if(check){
        const check = arr[first_num][first_arr_name].find((it) => {
            return it.num === second_num;
        })
        if(check){
            const check = arr[first_num][first_arr_name][second_num][second_arr_name].find((it) => {
                return it.num === num;
            })
            if(check){
                let filterArray = arr.filter((it) => {
                    return it.num !== first_num;
                })
                let filterInnerArray = arr[first_num][first_arr_name].filter((it) => {
                    return it.num !== second_num;
                })
                let filterInnerArray2 = arr[first_num][first_arr_name].filter((it) => {
                    return it.num === second_num;
                })
                let filterInnerInnerArray = arr[first_num][first_arr_name][second_num][second_arr_name].filter((it) => {
                    return it.num !== num;
                })
                let filterArray2 = arr.filter((it) => {
                    return it.num === first_num;
                })
                const inner_item_array = {...filterInnerArray2[0], [second_arr_name]:sortArrayByNumber(filterInnerInnerArray)};
                const sort_inner_item_array = sortArrayByNumber([...filterInnerArray, {...inner_item_array}])
                const all_item_array = {...filterArray2[0], [first_arr_name]:sort_inner_item_array};
                return sortArrayByNumber([...filterArray, {...all_item_array}]);
            }
            else{
                return arr;
            }
            
        } else {
            return arr;
        }
    } else{
        return arr;
    }
}

export const deleteItemFromArrayByNumKey = (arr=[], n=[]) => {
    if(Array.isArray(arr)){
        let number = Array.isArray(n) ? n : [n]
        let data = [];
        for(let i = 0; i < arr.length; i++){
            if(number.includes(i)){
                continue;
            }
            data.push(arr[i]);
        }
        return data;
    }
    return arr;
}