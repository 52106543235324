import React, { useState, useEffect, useMemo } from "react";
import { AnimatePresence } from 'framer-motion';

import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Table from "components/app/school/SmallerComponents/tableUl";
import Section from "components/app/school/SmallerComponents/Section";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Mallet from "components/app/school/SmallerComponents/mallet";
import Notification from "components/app/school/SmallerComponents/notification";
import {default as SelectBox} from "components/app/school/SmallerComponents/selectBox";
import Option from "components/app/school/SmallerComponents/selectBox/MultCheckbox";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from "components/app/school/SmallerComponents/spinner";

import { HeadComponent } from "components/head";
import { appServices } from "api/schoolApp/services";
import BreadCrumb from "components/breadCrumb";
import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import {default as success_icon} from 'images/success_icon.svg';


const Subjects = ({user_type, school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    
    const [accordion, setAccordion] = useState(0);
    const [submitted, setSubmitted] = useState(null);

    const [loading, setLoading] = useState("");
    const [preloading, setPreloading] = useState("");
    const [preloading2, setPreloading2] = useState(false);
    const [loading2, setLoading2] = useState(false)
    const [dataTableChanged, setDataTableChanged] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);

    const [allSubjectGroupData, setAllSubjectGroupData] = useState([]);
    const [allSubjectGenreData, setAllSubjectGenreData] = useState([]);
    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    const [menuDropData, setMenuDropData] = useState({});

    const [formAccordion, setFormAccordion] = useState({name:'', type:''});
    const [formCheckBoxData, setFormCheckBoxData] = useState([]);
    const [formDataError, setFormDataError] = useState({type:''})

    const [checkBoxOptions, setCheckBoxOptions] = useState([{num:0, checked:false, content:'', name:0}]);
    const [dataTable, setDataTable] = useState({class_section:'', q:''})

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState)
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const handleAccordionFormChange = (e, type) => {
        setFormAccordion({...formAccordion, [e.target.name]:e.target.value, type:type})
    }
    const handleEdit = (data) => {
        setMenuDrop(true);
        setMenuDropData(data);
    }
    const handleEditChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }
    const handleAccordionFormClick = (name, value, type) => {
        const filterCheckBox = checkBoxOptions.filter((item) => {
            return item.checked === true
        })
        setFormAccordion({...formAccordion, [name]:value, type:type, class_section_num:filterCheckBox.length})
        setFormCheckBoxData(filterCheckBox);
    }
    const handleCheckBoxOptionChange = (v=0, vn, num=0, checked=false) => {
        const value_name = vn;
        const value = v;
        const check = checkBoxOptions.find((item) => {
            return item.num === num  
        })
        if(check){    
            const filterItem = checkBoxOptions.filter((item) => {
                return item.num !== num
            })
            setCheckBoxOptions([...filterItem, {num:num, checked:checked, content:value_name, name:value}])
        }
        else{
            setCheckBoxOptions([...checkBoxOptions, {num:num, checked:checked, content:value_name, name:value}])
        }
        
    }
    const handleDataTableChange = (e) => {
        setDataTableChanged(false)
        setDataTable({...dataTable, [e.target.name]:e.target.value});
        setDataTableChanged(true)
    }
    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
        setFormAccordion({name:'', type:''})
    }

    const fetchAllSubjectGroup = (sid) => {
        appServices.getAllSubjectGroup(sid).then(res => {
            setAllSubjectGroupData(res.data)
        }).catch(e => {
            setAllSubjectGroupData([]);
            setError({data:["Internal Server Error"], title:"Subject Group Data Error"});
        })
    }
    const fetchAllSubjectGenre = () => {
        appServices.getAllSubjectGenre().then(res => {
            setAllSubjectGenreData(res.data)
        }).catch(e => {
            setAllSubjectGenreData([]);
            setError({data:["Internal Server Error"], title:"Subject Group Data Error"});
        })
    }
    const fetchAllSubjectPre = (sid) => {
        setPreloading2(true);
        appServices.getAllSubjectSchool(sid).then(res => {
            setAllSubjectData(res.data)
            setPreloading2(false);
        }).catch(e => {
            setAllSubjectData([]);
            setError({data:["Internal Server Error"], title:"Subject Data Error"});
            setPreloading2(false);
        })
    }
    const fetchAllSubject = (sid, csid='', q='') => {
        setLoading2(true);
        appServices.getAllSubjectSchool(sid, csid, q).then(res => {
            setAllSubjectData(res.data)
            setLoading2(false);
        }).catch(e => {
            setAllSubjectData([]);
            setError({data:["Internal Server Error"], title:"Subject Data Error"});
            setLoading2(false);
        })
    }
    const fetchAllClassSection = (sid) => {
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSectionData(res.data);
            
        }).catch(e => {
            setAllClassSectionData([]);
            setError({data:["Internal Server Error"], title:"Class Section Data Error"});
        })
    }

    const CheckBoxDataArray = (dt=[]) => { // This is the initial data of the checkbox array in the useEffect
        var data = [];
        for(let i = 0; i < dt.length; i++){
            data.push({num:i,  checked:false, content:dt[i].name, name:dt[i].id})
        }
        console.log(data)
        return data
    }

    const validateSubjectData = (data) => {
        const errors = {};
        if(!data.name){
            errors.name = "Subject Name is Required";
        }
        if(!data.code){
            errors.code = "Subject Code Is Required"
        }
        if(!data.class_section_num){
            errors.class_section = "One Or More Data Must Be Selected";
        }
        if(!data.genre){
            errors.genre = "Subject Genre Is Required";
        }
        return errors;
    }

    const handleAccordionSubmitData = (data={name:'', type:''}, type) => {
        if(type === "subject_group"){
            const dt = {
                name:data.name,
                school:school_id,
                created_by:user_id
            }
            return appServices.insertSubjectgroup(dt)
        }
        else if(type === "subject"){
            const dt = {
                name:data.name,
                code:data.code,
                group:data.group,
                genre:data.genre,
                school:school_id,
                class_section: formCheckBoxData[0].name,
                user: user_id
            }
            return appServices.insertSubjectSchool(dt)
        }
        else if(type === "multiple_subject"){
            const filterItem = formCheckBoxData;
            filterItem.shift();
            return(
                filterItem.forEach((item) => {
                    const dt = {
                        name:data.name,
                        code:data.code,
                        group:data.group,
                        genre:data.genre,
                        school:school_id,
                        class_section: item.name,
                        user: user_id
                    }
                    return appServices.insertSubjectSchool(dt)
                })
                
            )
        
        }
    }
    const handleAccordionSubmit = (e) => {
        e.preventDefault();
        setFormDataError(validateSubjectData(formAccordion))
        setSubmitted("insert");
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});  
        
        handleAccordionSubmitData(formAccordion, formAccordion.type).then(res => {
            setFormAccordion({name:'', type:''});
            setSuccess({text:"Data Saved Successfully", title:"Success"})
            fetchAllSubjectGroup(school_id)
            setLoading("");
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setError({data:['Something Went Wrong'], title:"Subject Group Error"})
            setLoading("");
            console.log(e.response);
        })
        
    }
    const handleAccordionDataSubmit = (e) => {
        e.preventDefault();
        setFormDataError(validateSubjectData(formAccordion))
        setSubmitted("insert");
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});  
        if(submitted === "inserted"){
            
            handleAccordionSubmitData(formAccordion, formAccordion.type).then(res => {
                handleAccordionSubmitData(formAccordion, "multiple_subject")
                setTimeout(() => {
                    setFormAccordion({name:'', type:''});
                    setFormCheckBoxData([]);
                    setSuccess({text:"Data Saved Successfully", title:"Success"})
                    fetchAllSubject(school_id, dataTable.class_section, dataTable.q)
                    setLoading("");
                    setSubmitted("");
                }, 500)
            }).catch(e => {
                setSuccess({...initialSuccessState});
                setError({data:['Something Went Wrong'], title:"Subject Group Error"})
                setLoading("");
                setSubmitted("");
                fetchAllSubject(school_id)
            })
            
        }
        else{
            setLoading("");
            setSubmitted("insert");
        }
    }
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmitted("none");
        }, 1000 * 15)
        return(
            <div className="nav-dd-anim" style={{display:submitted}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }
    const handleUpdateSubject = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("menuDrop");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            classes: menuDropData.class,
            school: school_id,
            class_section: menuDropData.class_section,
            name: menuDropData.name,
            code: menuDropData.code, 
            genre: menuDropData.genre,
            group: menuDropData.group,
        }
        appServices.updateSubjectSchool(menuDropData.id, data).then(res => {
            setError({...initialErrorState});
            setMenuDrop(false)
            setMenuDropData({...menuDropData, type_update:'class'})
            setSuccess({text: "Data Updated Successfully", title:"success"})
            setSubmitted(menuDropData.id);
            fetchAllSubject(school_id, dataTable.class_section, dataTable.q);
            setLoading("");
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.detail], title:"Subject Update Error"})
            setLoading("");
        })
        
    }

    const handleDeleteSubject = (e, id) => {
        e.preventDefault();
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        appServices.deleteSubjectSchool(id).then(res => {
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
            id:id })
            setLoading("");
            setTimeout(() => {
                fetchAllSubject(school_id, dataTable.class_section, dataTable.q);
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:''},
                    id:id })
                setSuccess({text:"Data Deleted Successfully", title:"Success", click_text:"Undo Delete", handleClick:() => handleUndoDeleteSubject(id)});
            }, 500)
        }).catch(e => {
            console.log(e.response);
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Delete Subject Error"})
        })
    }
    const handleUndoDeleteSubject = (id) => {
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        appServices.deleteSubjectSchool(id, "undo").then(res => {
            setAnim({initial:{opacity: 1, duration:0.4},
                animate:{opacity:1, duration:0.4, background:""},
                id:id })
            setSuccess({text:"Undo Delete Successfull", title:"Success"}) 
            setLoading("");
            fetchAllSubject(school_id, dataTable.class_section, dataTable.q);
        })
        .catch(e => {
            console.log(e.response);
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Undo Delete Subject Error"})
        })
    }

    useEffect(() => {
        fetchAllSubjectGroup(school_id);
        fetchAllSubjectGenre();
        fetchAllClassSection(school_id);
        if(dataTableChanged){
            fetchAllSubject(school_id, dataTable.class_section, dataTable.q);
        }
        else{
            fetchAllSubjectPre(school_id);
        }
    }, [school_id, dataTableChanged, dataTable])
    useEffect(() => {
       setCheckBoxOptions(CheckBoxDataArray(allClassSectionData)) 
       if(Object.keys(formDataError).length === 0 && submitted === "insert"){
           setSubmitted("inserted")
           setCheckBoxOptions(CheckBoxDataArray(allClassSectionData)) 
       }
       
    }, [allClassSectionData, formDataError, submitted])


    console.log(dataTableChanged)

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSubjectGroupOption = allSubjectGroupData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.name}`}</option>
        )
    })
    const insertSubjectGenreOption = allSubjectGenreData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.name}`}</option>
        )
    })
    const insertClassSectionOption = allClassSectionData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.name} Section`}</option>
        )
    })
    const insertClassSectionIdOption = allClassSectionData.map((item) => {
        return(
            <option value={item.class_section} key={item.id}>{`${item.name} Section`}</option>
        )
    })
    const insertClassSectionOptions = checkBoxOptions.map((item, i) => {
        return(
            <Option key={i} classname="text-capitalize font-very-small" name="name" checked={item.checked} value={item.value} onChange={() => handleCheckBoxOptionChange(item.name, item.content, item.num, !item.checked)}>{`${item.content} Section`}</Option>
        )
    })
    const insertSubjects = allSubjectData.map((item, i) => {
        i++;
        // const type = "class_section_form";
        // const checkUpdate = () => {
        //     if(item.id===submitted && menuDropData.type_update==="class_section") return updateSuccessIcon()
        // }
        /* {(() => {if(type==="class_section_form") return {updateSuccessIcon()} })()} */
        const data = {
            i: i,
            id: item.id,
            class_section: item.class_section,
            name: item.name,
            code:  item.code,
            group: item.group,
            genre: item.genre,
            type:"subject_form",
        }
        return(
            <Tr handleEditClick={() => handleEdit(data)}  sn={true} key={i} style={item.id === anim.id && {display:anim.display}}
            animation={(item.id === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}
            handleDeleteClick={e => handleDeleteSubject(e, item.id)}>
                
                <div className="c-data sn"> {item.id===submitted && updateSuccessIcon()}
                    <span className="font-very-small">{i}</span>
                </div>
                <div className="c-data"><span className="font-very-small text-capitalize">{item.name.length > 20 ? `${item.name.slice(0, 20)}...`:item.name}</span></div> 
                <div className="c-data"><span className="font-very-small text-capitalize">{item.code}</span></div>   
                <div className="c-data"><span className="font-very-small text-capitalize">{item.class_section_name}</span></div>
                <div className="c-data"><span className="font-very-small text-capitalize">{item.group_name}</span></div>  
                <div className="c-data"><span className="font-very-small text-capitalize">{item.genre_name}</span></div>    

                <TdWrapper header_text={item.name} header_num={i}>
                    <Td heading="Subject Name" text={item.name} />
                    <Td heading="Subject Code" text={item.code} />
                    <Td heading="Class Section" text={item.class_section_name} />
                    <Td heading="Subject Group" text={item.group_name} />
                    <Td heading="Subject Genre" text={item.genre_name} />
                    <Td heading="Created By" text={`${item.last_name} ${item.first_name}`} />
                </TdWrapper>
            </Tr>
        )
    })
    const insertCheckedBoxValue = formCheckBoxData.map((item) => {
        return ' ' + item.content.trimEnd(',');
    })

    return(
        <>
            <HeadComponent title="Manage Subjects" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> 
                <Grid>
                    <GridContent header_text="Set Subject" header_icon="fas fa-align-left">
                        {loading==="left" && (<Loader />)}
                        {preloading ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (<>
                        <Accordion handleSubmit={e => handleAccordionSubmit(e)} index={1} text="Add Subject Group" handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && ( <>
                                <AccordionFormData required={true} handleChange={(e) => handleAccordionFormChange(e, "subject_group")} name="name" value={formAccordion.name} tag="input" type="text" classname="form-control-theme font-slightly-small" placeholder="Enter Subject Group" />
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion>
                        <Accordion handleSubmit={e => handleAccordionDataSubmit(e)} index={2} text="Add Subject" handleClick={() => handleAccordionClick(2) } currentAccordion={accordion}>
                            {accordion === 2 && ( <>
                                <AccordionFormData error={formDataError.name} handleChange={(e) => handleAccordionFormChange(e, "subject")} name="name" value={formAccordion.name} tag="input" type="text" classname="form-control-theme font-slightly-small" placeholder="Enter Subject Name" />
                                <AccordionFormData error={formDataError.code} handleChange={(e) => handleAccordionFormChange(e, "subject")} name="code" value={formAccordion.code} tag="input" type="text" classname="form-control-theme font-slightly-small" placeholder="Enter Subject Code" />
                                
                                    <SelectBox error={formDataError.class_section} placeholder="Select Class Section..." value={insertCheckedBoxValue} onClick={() => handleAccordionFormClick("class_section", !formAccordion.class_section, "subject")} clicked={formAccordion.class_section}>
                                        {insertClassSectionOptions}
                                    </SelectBox>
                                
                                {/* <AccordionFormData required={true} handleChange={(e) => handleAccordionFormChange(e, "subject")}  tag="select" name="class_section" value={formAccordion.class_section} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Class Section</option>
                                    {insertClassSectionOption}
                                </AccordionFormData> */}
                                <AccordionFormData error={formDataError.genre} handleChange={(e) => handleAccordionFormChange(e, "subject")}  tag="select" name="genre" value={formAccordion.genre} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Subject Genre</option>
                                    {insertSubjectGenreOption}
                                </AccordionFormData>
                                <AccordionFormData handleChange={(e) => handleAccordionFormChange(e, "subject")}  tag="select" name="group" value={formAccordion.group} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Subject Group*</option>
                                    {insertSubjectGroupOption}
                                </AccordionFormData>
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion>
                        </>)}
                    </GridContent>
                    <GridContent header_text="Manage Subjects" header_icon="fas fa-tasks">
                    
                        <Section icon="fas fa-eye" header="View Subjects">
                            <Mallet>
                                <div className="flex-50"><label className='font-super-small'>Select Section: 
                                    <select onChange={e => handleDataTableChange(e)} name="class_section" value={dataTable.class_section} className='form-control-theme font-slightly-small  text-capitalize' >
                                        <option value="">Select Class Section</option>
                                        {insertClassSectionIdOption}
                                    </select></label>
                                </div>
                                <div className="flex-50"><label className='font-super-small'>Filter By: 
                                    <input onChange={e => handleDataTableChange(e)} name="q" value={dataTable.q} className='form-control-theme font-slightly-small' type="text" placeholder='Search...' /></label>
                                </div>
                            </Mallet>
                            {loading2 && <Spinner />}
                            {preloading2 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                            <Table>
                                <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Sub Name"}, {text:"Sub Code"}, {text: 'Class Section'}, {text:"Sub Group"}, {text:"Sub Genre"}]} />
                                {allSubjectData.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    insertSubjects                       
                                }
                            </Table>
                            </>
                            )}
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                                {loading === "menuDrop" && <Loader />}
                                {menuDropData.type === "subject_form" && (
                                    <form onSubmit={e => handleUpdateSubject(e)}>
                                        <div className='row'>
                                            <div className="fg">
                                                <label className="font-super-small">Subject Name </label><input required name="name" onChange={e => handleEditChange(e)} 
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Subject Name" value={menuDropData.name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Subject Code </label><input required name="code" onChange={e => handleEditChange(e)} 
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Subject Code" value={menuDropData.code} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Class Section </label>
                                                <select name="class_section" className="font-very-small form-control-theme text-capitalize" 
                                                placeholder="Class Section" onChange={e => handleEditChange(e)} value={menuDropData.class_section}>
                                                    <option value="">Select Class Section</option>
                                                    {insertClassSectionOption}
                                                </select>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Subject Genre </label>
                                                <select name="genre" className="font-very-small form-control-theme text-capitalize" 
                                                onChange={e => handleEditChange(e)} value={menuDropData.genre}>
                                                    <option value="">Select Subject Genre</option>
                                                    {insertSubjectGenreOption}
                                                </select>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Subject Group </label>
                                                <select name="group" className="font-very-small form-control-theme text-capitalize" 
                                                onChange={e => handleEditChange(e)} value={menuDropData.group}>
                                                    <option value="">Select Subject Group</option>
                                                    {insertSubjectGroupOption}
                                                </select>
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent>
                </Grid>  
            </ComponentWrapper>
            
                
        </>
    )

}

export default Subjects;