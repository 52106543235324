import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { ageCalc } from 'components/app/school/actions/ageCalc';
import { getPageSize } from 'components/app/school/actions/pageSize';


const ApplicantStaff = ({school_id, user_id, school_title=""}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    const [userTable, setUserTable] = useState({filter_by:"", sbid:0, q:"", size:20, p:1})

    const [allData, setAllData] = useState({results:[], count:0});
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);

    const [navigate, setNavigate] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [userTableChanged, setUserTableChanged] = useState(false);

    const nav = useNavigate();

    const fetchAllStaffApption = (loader=true, sid, p=1, size=20, q, sbid, filter_by) => {
        setPreloading(loader);
        setTableLoading(!loader);
        appServices.getAllStaffSchoolApplication(sid, p, size, q, sbid, filter_by).then(res => {
            setPreloading(false);
            setTableLoading(false);
            console.log(res);
            setAllData({...res.data});
        }).catch(e => {
            setPreloading(false);
            setTableLoading(false);
            setAllData({results:[], count:0});
            setError({data:['Internal Server Error'], title:'Application Data Error'});
        })
    }
    const fetchAllSchoolBranch = (sid) => {
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData([...res.data]);
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:['Internal Server Error'], title:'School Branch Data Error'});
        })
    }
    const handleUserTable = (e) => {
        setUserTableChanged(false);
        setUserTable({...userTable, [e.target.name]:e.target.value})
        setUserTableChanged(true)
    }
    const handleUserTablePagination = (e, value) => {
        setUserTableChanged(false);
        const name = "p";
        setUserTable({...userTable, [name]:value})
        setUserTableChanged(true)
    }
    const onClickViewBtn = (data) => {
        if(userTable.q === "accepted"){
            setNavigate(`/${school_title}/staff/user/${data.id}`);
        }
        else if(userTable.q === ""){
            setNavigate(`/${school_title}/staff/applied/${data.id}`);
        }
        else{

        }
    }
    const rejectJob = (id) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setWarning(initialSuccessState);
        appServices.rejectStaffSchoolApplication(id).then(() => {
            setLoading(false);
            setSuccess({title:'Success', text:'Applicant Rejected Successfully'})
            fetchAllStaffApption(false, school_id, userTable.p, userTable.size, userTable.q, userTable.sbid, userTable.filter_by);
        }).catch(e => {
            setError({title:'Error', data:[e.response.data.detail]});
            setLoading(false);
        })  
    }
    const onClickRejectBtn = (id, i) => {
        setWarning({id:0, ...initialSuccessState});
        setTimeout(() => {
            setWarning({id:i, title:'Are you sure?', text:'Are you sure you want to reject this applicant?', click_text:'Yes', handleClick:() => rejectJob(id)})
        }, 200);
    }
    
    
    useEffect(() => {
        fetchAllSchoolBranch(school_id);
    }, [school_id])
    useEffect(() => {
        if(userTableChanged){
            fetchAllStaffApption(false, school_id, userTable.p, userTable.size, userTable.q, userTable.sbid, userTable.filter_by);
        }
        else{
            fetchAllStaffApption(true, school_id);
        }
    }, [school_id, userTable, userTableChanged]);
    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSchoolBranchData = allSchoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const penFunc = (interviewed=false, accepted=false) => {
        if(userTable.q==="" && interviewed && !accepted ){
            return ' (Interview Started) ';
        }
        else if(userTable.q==="" && interviewed && accepted){
            return '(Awaiting Response)';
        }
        else{
            return '';
        }
    }
    const btnFunc = (interviewed=false, accepted=false) => {
        if(userTable.q==="" && interviewed && accepted){
            return false;
        }
        else if(userTable.q==="accepted"){
            return false
        }
        else if(userTable.q==="rejected"){
            return false
        }
        else{
            return true
        }
    }
    const insertAppcantData = () => {
        var i = 0;
        if(userTable.p > 1){
            const page = parseInt(userTable.p) - 1
            const size = parseInt(userTable.size) * page;
            i = size;
        }
        return(allData.results.map((item) => { 
            i++;
            const full_name = item.last_name + ' ' + item.first_name;
            const data = {
                id: item.id,
            }
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} view_btn={btnFunc(item.is_interviewed, item.is_accepted)} handleViewClick={() => onClickViewBtn(data)} handleDeleteClick={() => onClickRejectBtn(item.id, i)} dlt_btn={btnFunc(item.is_interviewed, item.is_accepted)} dlt_text='Reject'>
                    <div className="c-data sn"><span className="font-very-small">{i}</span></div>
                    <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic}`} alt="Profile" /></div> <span className="font-very-small">{full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.subject_handle.length > 25 ? `${item.subject_handle.slice(0, 25)}...` : item.subject_handle}</span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{userTable.q === "" && (<div className='profile-cont'><span className={'circle yellow'}></span></div>)} {userTable.q === "accepted" && (<div className='profile-cont'><span className={'circle green'}></span></div>)} {userTable.q === "rejected" && (<div className='profile-cont'><span className={'circle red'}></span></div>)} <b>{userTable.q ==="" ? `Pending ${penFunc(item.is_interviewed, item.is_accepted)}` : userTable.q}</b></span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.applicant_type}</span></div>
                    <div className="c-data">{userTable.q !== "rejected" && (<span className="font-very-small">{item.phone_number && `+${item.phone_number}`}</span>)}</div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.cert_type.length > 25 ? `${item.cert_type.slice(0, 25)}...` : item.cert_type}</span></div>
                    {userTable.q !== "rejected" &&(
                        <TdWrapper center header_text={`${item.last_name} ${item.first_name}`} header_num={i}>
                            <Td profile_pic={`${BACKEND_URL}${item.profile_pic}`} />
                            <Td heading="Full Name" text_class='text-capitalize' text={`${item.last_name} ${item.first_name}`} />
                            <Td heading="Phone Number" text={item.phone_number} />
                            <Td heading="Email Address" text={item.email} />
                            <Td heading="Type" text_class='text-capitalize' text={item.applicant_type} />
                            <Td heading="Subject Handle" text={item.subject_handle.length > 50 ? `${item.subject_handle.slice(0, 25)}...` : item.subject_handle} />
                            <Td heading="Cert Type" text={item.cert_type} />
                            <Td heading="Age" text={`${ageCalc(item.dob)} years old`} />
                            <Td heading="Address" text={`${item.country_origin_name} - ${item.address}`} />
                            <Td heading="School Branch" text={item.school_branch_name} />
                            <Td heading="Date Applied" text={item.created} />
                            <Td heading="Status" text_class='text-capitalize'  text={userTable.q === "" ? `Pending ${penFunc(item.is_interviewed, item.is_accepted)}` : userTable.q} />
                            {btnFunc(item.is_interviewed, item.is_accepted) && <Td link={userTable.q === "" ? `/${school_title}/staff/applied/${item.id}` : `/${school_title}/staff/applied`} text_link='View More' /> }
                        </TdWrapper>
                    )}
                    </Tr>
                </Fragment>
            )
        }))
    }
    return(
        <>
            <HeadComponent title='Manage Staff Applicants' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage Applied Staff`} header_icon="fas fa-tasks">
                    {preloading ? (<ul>
                        <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <Section>
                            {loading && (<Loader />)}
                            <Mallet >
                                <div className="flex-50"><label className='font-super-small'>Select School Location: 
                                    <select name="sbid" value={userTable.sbid} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small' >
                                        <option value={0}>All</option>
                                        {insertSchoolBranchData}
                                    </select></label>
                                </div>
                                <div className="flex-50"><label className='font-super-small'>Select Status 
                                    <select name="q" value={userTable.q} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small'>
                                        <option value="">Pending</option>
                                        <option value="accepted">Accepted</option>
                                        <option value="rejected">Rejected</option>
                                    </select></label>
                                </div>
                                <div className="flex-70-line"><label className='font-super-small'>Filter By: 
                                    <input name="filter_by" value={userTable.filter_by} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small' type="text" placeholder='Search By... Subject, Name, Type' /></label>
                                </div>
                                <div className="flex-30-line align-right"><label className='font-super-small'>No. Of Users: <br />
                                    <select  name="size" value={userTable.size} onChange={e => handleUserTable(e)} className='font-slightly-small form-control-theme' >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                    </label>
                                </div>
                                
                            </Mallet>
                            {tableLoading && <Spinner />}
                            <Table>
                                <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"S/N"}, {text:"Full Name"}, {text:'Subject Handle'},  {text:'Status'}, {text:'Type'}, {text:'Phone'},  {text:'Cert Type'},]} />
                                {allData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    (<>
                                        {insertAppcantData()}
                                    </>)                         
                                }
                                <div  style={{marginTop:'40px', display:'flex', justifyContent:'center'}}>
                                    <Stack spacing={2}>
                                        <Pagination count={getPageSize(allData.count, userTable.size)} page={userTable.p} onChange={handleUserTablePagination} size="small" color="primary" variant="outlined" shape="rounded" />
                                    </Stack>
                                </div>
                            </Table>
                        </Section>
                    </>)}
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default ApplicantStaff;