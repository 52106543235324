import React, { useState, useEffect, useMemo, Fragment } from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
// import Td from "components/app/school/SmallerComponents/tableInnerLi";

import Loader from "components/app/school/SmallerComponents/loader";
import PreloaderLine from "components/preloaderLine";
import BlankLoader from "components/blankLoader";
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices, appServices5 } from 'api/schoolApp/services';

const Bank = ({school_id, school_title, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialBankState = useMemo(() => ({name:"",}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [accordionLoading, setAccordionLoading] = useState(false);
    const [reload, setReload] = useState(true);

    const [accordion, setAccordion] = useState(1);

    const [state, setState] = useState(initialBankState);

    const [banks, setBanks] = useState([]);
    const [allSchoolBank, setAllSchoolBank] = useState([]);

    const fetchAllBanks = () => {
        setPreloading(true);
        setAccordionLoading(true);
        appServices5.getAllBanks('nigeria').then(res => {
            setBanks(res.data.data);
            setAccordionLoading(false);
            setPreloading(false);
        }).catch(e => {
            setPreloading(false);
            setAccordionLoading(false);
            setError({data:["Internal Server Error"], title:"Banks Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    
    const fetchAllSchoolBank = (sid) => {
        setError({data:[], title:''});
        appServices.getAllSchoolBankAccount(sid).then(res => {
            setPreloading2(false);
            setAllSchoolBank(res.data);
        }).catch(e => {
            setPreloading2(false);
            setAllSchoolBank([]);
            setError({data:["Internal Server Error"], title:"School Account Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
    }

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
    }
    const handleBankStateChange = e => {
        setState({...state, [e.target.name]:e.target.value});
    }
    const onActBankAccount = (id, is_active=false) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setPreloading(true);
        appServices.actSchoolBankAccount(id).then(res => {
            setSuccess({title:'Success', text:is_active ? 'Data Inactivated Successfuly' : 'Data Activated Successfully'});
            setPreloading(false);
            fetchAllSchoolBank(school_id);
        }).catch(e => {
            setPreloading(false);
            setError({title:"Error", data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
        })
    }
    const onSubmitSchoolBankAccount = (e) => {
        e.preventDefault();
        setAccordionLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const filter_bank = banks.filter((it) => it.id === parseInt(state.bank));
        const data = {
            school: school_id,
            p_bank_id: state.bank,
            acct: state.acct,
            bank_name: filter_bank[0].name,
            bank_code: filter_bank[0].code
        }
        appServices.insertSchoolBankAccount(data).then(res => {
            setAccordionLoading(false);
            setState(initialBankState);
            setSuccess({title:'Success', text:'Data Submitted Successfuly'});
            fetchAllSchoolBank(school_id);
        }).catch(e => {
            setAccordionLoading(false);
            setError({title:"Data Insert Error", data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
        })
    }
   
    

    useEffect(() => {
        if(reload && school_id){
            setReload(false);
            fetchAllSchoolBank(school_id);
            fetchAllBanks();
        }
    }, [reload, school_id])
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertBanks = banks.filter((it) => it.slug !== "zenith-bank-usd").map((item, i) => (
        <Fragment key={i}>
            <option value={item.id}>{item.name}</option>
        </Fragment>
    ))
    const insertBanksData = allSchoolBank.map((item, i) => {
        i++;
        return(
            <Fragment key={i}>
                <Tr sn={true} dlt_btn={false} edit_btn={false} active_btn={item.is_active} inactive_btn={!item.is_active} handleActivateClick={() => onActBankAccount(item.uid, item.is_active)} handleInactivateClick={() => onActBankAccount(item.uid, item.is_active)}>
                    <div className="c-data sn"><span className="font-very-small">{i}</span></div>
                    <div className="c-data"> <span className='font-very-small'>{item.bank_name}</span></div>
                    <div className="c-data"> <span className='font-very-small'>{item.acct}</span></div>
                    <div className="c-data"> <span className='font-very-small'>{item.acct_name}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.qty_stock}</span></div>
                </Tr>
            </Fragment>
        )
    })

    return(
        <>
        <HeadComponent title='Manage School Books' />
            <BreadCrumb />
            {preloading && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>  
                <Grid>
                    <GridContent header_text={`Create School Bank Account`} header_icon="fas fa-plus">
                        {accordionLoading && <Loader />}
                        <Accordion index={1} text={`Create School Bank Account`} handleSubmit={e => onSubmitSchoolBankAccount(e)}  handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && (
                                <>
                                    <AccordionFormData required handleChange={(e) => handleBankStateChange(e)} value={state.bank} tag="select" name="bank"  classname="form-control-theme font-slightly-small" >
                                        <option value={""}>Select Bank</option>
                                        {insertBanks}
                                    </AccordionFormData>
                                    <AccordionFormData required  handleChange={(e) => handleBankStateChange(e)} value={state.acct} tag="input" type="text" name="acct"  classname="form-control-theme font-slightly-small" placeholder="Enter Account Number" />
                                    <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
    
                                </>
                            )}
                        </Accordion>
                    </GridContent>
                    <GridContent>
                        <Note>Only one account can be active</Note>
                        <Section icon="fas fa-tasks" header={`Manage School Bank Account`}>
                        {preloading2 ? (<ul>
                                <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                <Table>
                                    <Tr btn={false} header={true} sn={true} text={[{text:"S/N"},  {text:"Acct Name"}, {text:"Bank Acct"}, {text:'Bank Name'}]} />
                                    {allSchoolBank.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :
                                    insertBanksData
                                    }
                                </Table>
                            </>
                            )
                        }
                        </Section>
                        
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default Bank;