import {StyleSheet} from "@react-pdf/renderer";

export const pdfStyle = (schoolColor) => {return StyleSheet.create({
    page:{
        display: 'block',
        width: '100%',
        fontFamily: 'Raleway'
    },
    w10:{
        width:'10%'
    },
    w15:{
        width:'15%'
    },
    w20:{
        width:'20%'
    },
    w30:{
        width:'30%'
    },
    w40:{
        width:'40%'
    },
    w50:{
        width:'50%'
    },
    w60:{
        width:'60%'
    },
    w70:{
        width:'70%'
    },
    w90:{
        width:'90%'
    },
    w100:{
        width:'100%'
    },
    numbering:{
        width:'4%',
        fontWeight:600,
    },
    fw500:{
        fontWeight:500,
    },
    fw600:{
        fontWeight:600,
    },
    fs8px: {
        fontSize:'8px',
    },
    cont_voskool_logo:{
        position: 'absolute',
        top:'50%',
        left:'35%',
        transform: 'rotate(315deg)',
        zIndex:0,
    },
    voskool_logo:{
        width:'170px',
        height: '50px',
        opacity: .22,
    },
    heading_container: {
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'space-between',
        marginTop: '2px',
        marginBottom: '2px',
    },
    heading_container2: {
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'flex-start',
    },
    heading_container_wrapper:{
        position: 'absolute',
        bottom: '-20%',
    },
    mini_heading_text: {
        fontWeight:600,
        fontSize:'12px',
        paddingRight:'5px',
        marginBottom:'4px'
    },
    section_header: {
        marginTop:'6px',
        marginBottom:'6px',
        textAlign:'center',
    },
    section_header_text:{
        fontWeight:600,
        fontSize:'11px'
    },
    section_obj: {
        marginLeft:'8px',
        marginRight:'8px',
        marginTop:'7px',
        marginBottom:'7px',
        width:'100%',
    },
    section_obj_cont:{
        display: 'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        fontSize:'10px',
        marginBottom:'3px'
    },
    section_obj_cont2:{
        display: 'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        fontSize:'9px',
        marginBottom:'8px'
    },
    section_obj_cont_text:{
        fontWeight:'500',
        width:'82%'
    },
    obj_cont_mark_text:{
        fontWeight:600,
        width:'9%'
    },
    heading_logo:{
        width:'50px',

        height: '30px'
    },
    heading_text:{
        width: '90%',
        fontWeight:700,
        fontSize:'18px',
        paddingRight:'10px',
    }, 
    pagination:{
        position:'absolute',
        bottom:0,
        left: 0,
        right:0,
        textAlign:'center',
        fontSize:'8px',
        fontWeight:600
    }
})}