export const runOut = (curr_num=0) => {
    if(curr_num < 1){
        return(
            <div className='disp-block align-center' style={{marginTop:'15px'}}>
                <span className='font-small disp-block align-center red'><b>ERROR: Your VoSpace Has Ranout, Please Get More By Clicking The Button Below</b></span>
                <span className='font-small disp-block align-center red'><b>New Students cannot be added</b></span>
            </div>
        )
    }
    if(curr_num < 10){
        return(
            <div className='disp-block align-center' style={{marginTop:'15px'}}>
                <span className='font-slightly-small red'><b>NOTE:</b> Your VoSpace Is Running Out, Click on the Button below to get more</span>
            </div>
        )
    }
    if(curr_num < 30){
        return(
            <div className='disp-block align-center' style={{marginTop:'15px'}}>
                <span className='font-small' style={{color:'rgb(255, 198, 14)'}}><b>WARNING: Your VoSpace Is Running Out, Click on the Button below to get more</b> </span>
            </div>
        )
    }
}