import React from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import DropDownBox from 'components/app/school/SmallerComponents/dropDownBox';

import { Content, ListContent } from './style';
import { formatNumber } from "../../actions/money";

export const ListOption = ({value, contentClassName, contentStyle, checked=false, flex="100", classname="font-slightly-small", onClick, img="", children}) => {
    return(
        <ListContent className={`flex-${flex} ${contentClassName}`} style={contentStyle}>
            <div className={`disp-flex wrap jc-stretch content ${checked && 'opacity'}`} defaultValue={value} onClick={onClick}>
                <div className='cont'>{!checked && <i className='fas fa-circle font-super-small circle_'></i>}</div>
                {checked && <div className='cont'> <i className='fas fa-check-circle font-very-small app-bg-color-text'></i></div>} 
                <div className='cont'><span className={`${classname} ${checked ?  'fw-700' : 'fw-600'}`}>{children}</span></div>
                {img && <div className="cont"><img alt="Icon" className='sm-icon br-10' src={img}  /></div>}
            </div>
        </ListContent>
    )
}
const Input = ({type, children, styleContent, value, classname="", placeholder="", required=false, disabled=false, currency=null, currency_value="", point_display=false, point_name="", point_value, handleClick, handleChange, handlePointChange, name, setCloseListBox, closeListBox=true, listItemLength=0, error}) => {
    const handleOutsideClick = (bool=false) => {
        setCloseListBox(bool);
    }
    if(type === "money"){
        return(
            <>
            <Content style={styleContent}>
                <select defaultValue={currency_value} className={`disabled flex-15 font-very-small`} disabled={disabled}  ><option defaultValue={currency_value}>{currency}</option></select>
                <input onChange={handleChange} name={name} type={'text'} value={formatNumber(value)} className={point_display ? `flex-60 ${classname}` : `flex-85 ${classname}`} placeholder={placeholder} required={required} disabled={disabled}  />
                {point_display && <div className={`flex-5 align-center`}><span className="font-big">.</span></div>}
                {point_display && <input maxLength={2} min={0} onChange={handlePointChange} name={point_name} type={'number'} value={point_value} className={`flex-20 ${classname}`} placeholder={'00'}  /> }
            </Content>
            </>
        )
    }
    if(type === "list"){
        return(
            <>
            <ClickAwayListener onClickAway={() => handleOutsideClick(true)}>
                <Content style={styleContent}>
                    <input className={`flex-100 ${classname}`} onClick={handleClick} onChange={handleChange} type="text" name={name} value={value} required={required} disabled={disabled} placeholder={placeholder} autoComplete={"on"} />
                    {error && (<span className="font-super-small flex-100" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                    {!closeListBox && (
                        <>
                        {listItemLength > 0 && (
                            <DropDownBox theme="app-theme">
                                {children}
                            </DropDownBox>
                        )}
                        </>
                    )} 
                </Content>
            </ClickAwayListener>
            </>
        )
    }

}

export default Input;