import React from "react";
import { Wrapper } from "./style";

const MenuBackDrop = ({theme, size, children, close, handleClose}) => {
    return(
        <Wrapper initial={{opacity: 0}} animate={{opacity: 1}}>
            <div className={`back-drop ${theme} ${size}`}>
                {close && (<i className=" fas fa-times close-icon font-small app-text-color" onClick={handleClose}></i>)}
                {children}
            </div>
        </Wrapper>
    )
};

export default MenuBackDrop;