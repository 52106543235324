import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { toBoolean } from 'components/app/school/actions/toBoolean';
import { updateArray } from 'components/app/school/actions/array-utils/Update';

const SportHouse = ({school_id, user_id, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    // const nav = useNavigate();

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const [menuDropData, setMenuDropData] = useState({id:'', num:0});

    const [allSportHouseData, setAllSportHouseData] = useState([]);

    // const [navigate, setNavigate] = useState('');
    const [preloading, setPreloading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleMenuChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]: e.target.name === "is_active" ? toBoolean(e.target.value) : e.target.value});
    }

    const fetchAllSportHouse = (school_id) => {
        if(school_id){
            setPreloading(true);
            appServices.getAllSchoolSportHouse(school_id).then(res => {
                setPreloading(false);
                setAllSportHouseData(addItemToArray(res.data));
            }).catch(e => {
                setPreloading(false);
                setAllSportHouseData((prev) => prev.length ? [...prev] : []);
                setError({data:['Internal Server Error'], title:'House Data Fetch Error'});
            })
        }
    }

    const handleMenuUpdate = (e, data) => {
        e.preventDefault();
        setMenuLoading(true);
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices.updateSchoolSportHouse(data.id, data).then(res => {
            setSuccess({title:'Success', text:'Data Updated Successfully'});
            setMenuLoading(false);
            setMenuDrop(false);
            setLoading(false);
            setAllSportHouseData(updateArray(allSportHouseData, data.num, {...res.data}))
        }).catch(e => {
            setMenuLoading(false);
            setLoading(false);
            setError({title:'Data Update Error', data:[e.response.data.detail]});
        })
    }

    const onClickEdit = (data) => {
        setMenuDrop(true);
        setMenuDropData(data)
    }

    // useEffect(() => {
    //     if(navigate){
    //         nav(navigate);
    //     }
    // }, [navigate, nav]);
    useEffect(() => {
        fetchAllSportHouse(school_id);
    }, [school_id])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const insertSchoolSportHouse = allSportHouseData.map((item, i) => {
        const data = {id:item.id, name:item.name, color_name:item.color_name, is_active:item.is_active, num:item.num}
        return(
            <Fragment key={i}>
                <Tr sn={false} dlt_btn={false} handleEditClick={() => onClickEdit(data)}>
                    <div className='c-data' style={{width:'70%'}}><span className="font-very-small text-capitalize">{item.name}</span></div>
                    <div className='c-data'><span className="font-very-small text-capitalize">{item.color_name}</span></div>
                    <div className="c-data"><input type="checkbox" name="name" value={item.is_active} checked={item.is_active} onChange={e => handleMenuUpdate(e, {id:item.id, num:item.num, is_active:!item.is_active})} /> </div>
                </Tr>
            </Fragment>
        )
    })

    return(
        <>
        <HeadComponent title='Manage Sport House' />
        <BreadCrumb />
        <ComponentWrapper>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper>  
            <Grid layout='lauto'>
                <GridContent header_text={`Manage Sport Houses`} header_icon="fas fa-tasks">
                    {loading && <Loader />}
                    <Section>
                        <Note>Check the box to select house to be used</Note>
                        <Note>To Add Sport To List Of Payment For Student School Fees Click <Link to={`/${school_title}/app/school_fee`}>HERE</Link></Note>
                        {preloading ? 
                        (<ul>
                            <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                            <Table>
                                <Tr sn={false} btn={false} header={true} text={[{text:"House Name", style:{width:'70%'}}, {text:"House Color"}, {text:'Stat'}]} />
                                {!allSportHouseData.length ? <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/> : insertSchoolSportHouse}
                            </Table>
                        </>)}
                    </Section>
                    <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                        { menuDrop && 
                        <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                            {menuLoading && <Loader />}
                            {menuDropData.id && (
                                <form onSubmit={e => handleMenuUpdate(e, {
                                    id:menuDropData.id,
                                    num:menuDropData.num,
                                    name:menuDropData.name,
                                    is_active:menuDropData.is_active
                                })}>
                                    <div className='row'>
                                        <div className="fg">
                                            <label className="font-super-small">House Name </label>
                                            <input onChange={e => handleMenuChange(e)} type="text" name="name" className="font-very-small app-theme form-control-theme" placeholder="Enter House Name" value={menuDropData.name} />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">House Color Name </label>
                                            <input disabled type="text" defaultValue={menuDropData.color_name} className="font-very-small app-theme text-capitalize form-control app-text-color disabled" />
                                        </div>
                                        <div className='fg'>
                                            <label className="font-super-small">Status</label>
                                            <input onChange={e => handleMenuChange(e)} type="checkbox" name="is_active" checked={menuDropData.is_active} value={!menuDropData.is_active} />
                                        </div>
                                        <div className="fg-all btn-g align-center">
                                            <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                Update <i className="fas fa-paper-plane"></i> </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </MenuDrop>
                        }
                    </AnimatePresence>
                </GridContent>
            </Grid>
        </ComponentWrapper>
        </>
    )
}

export default SportHouse;