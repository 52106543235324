import React, { useState, useEffect, useCallback, useMemo, Fragment, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
// import { Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import PicPlaceHolder, {ImagePic} from 'components/app/school/SmallerComponents/picPlaceholder';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from 'components/app/school/SmallerComponents/spinner';


import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices, appServices4, appServices5 } from 'api/schoolApp/services';
import { toBoolean } from 'components/app/school/actions/toBoolean';
import { groupArray } from 'components/app/school/actions/array-utils/Group';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import { getFormData } from 'components/app/school/actions/array-utils/Form';
import { validateExamQuestionForm, validateExamMultiQuestionFigForm } from 'components/app/school/actions/validation';
import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { updateArray } from 'components/app/school/actions/array-utils/Update';
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { BACKEND_URL } from 'actions/url';

// import {PDFViewer, Document, pdf, PDFDownloadLink} from "@react-pdf/renderer";

import {default as success_icon} from 'images/success_icon.svg';



const StaffSchoolSetExamQuestion = ({school_id, school, school_branch, access, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialAccordState = useMemo(() => ({exam_question:"", obj_a:'', obj_b:'', obj_c:'', obj_d:'', obj_e:'',  quest_pic:null, quest_pic_value:'', is_pic_bottom:false, is_pic_multi_question:false, is_preview:false, is_compulsory:false, mark:'', mcq:'', school_branch:''}), []);
    const initialAccordState2 = useMemo(() => ({name:"", quest_pic:null, mcq:'', quest_pic_value:''}), []);
    const initialExamRecordState = useMemo(() => ({error:false, server_error:false, id:0, is_uploaded:false, is_staff_uploaded:false, session_active:false}), []);
    const initialMalletState = useMemo(() => ({session:'', type:'', class_section:'', classes:'', subject:'', pre:false, curr_session:'', active_session:false}), []);
    const initialMalletState2 = useMemo(() => ({mcq:'', school_branch:'', pre:true}), []);
    const initialEqChangeState = useMemo(() => ({one:{id:0, uid:''}, two:{id:0, uid:''}, type:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);


    const [malletState, setMalletState] = useState(initialMalletState);
    const [malletState2, setMalletState2] = useState(initialMalletState2);
    const [accordState, setAccordState] = useState(initialAccordState);
    const [accordState2, setAccordState2] = useState(initialAccordState2)
    const [recordState, setRecordState] = useState(initialExamRecordState);
    const [accordFormErrorState, setAccordFormErrorState] = useState({});
    const [accordFormErrorState2, setAccordFormErrorState2] = useState({});
    const [changedState, setChangedState] = useState({});
    const [menuDropData, setMenuDropData] = useState({id:''});
    const [menuDropDataError, setMenuDropDataError] = useState({});
    const [eqChangeState, setEqChangeState] = useState(initialEqChangeState)

    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allEQData, setAllEQData] = useState([]);
    const [allEQFData, setAllEQFData] = useState([]);


    const [preloading, setPreloading] = useState(true);
    const [submit, setSubmit] = useState('');
    const [spinLoading, setSpinLoading] = useState(false);
    const [accordionLoading, setAccordionLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hoverLoading, setHoverLoading] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [malletTableLoading, setMalletTableLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [menuDrop, setMenuDrop] = useState(false);
    const [menuDropLoading, setMenuDropLoading] = useState(false);

    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:-1})

    const [accordion, setAccordion] = useState(0);

    const initialChangedRecordState = useRef(true);

    const handleAccordionClick = (i) => {
        setError(initialErrorState);
        setAccordFormErrorState({});
        setAccordFormErrorState2({});
        setSubmit('');
        setAccordState({...accordState, quest_pic:null, quest_pic_value:'', is_pic_bottom:false, is_pic_multi_question:false})
        setAccordState2({...accordState2})
        if(malletState.classes && malletState.subject && malletState.session && parseInt(malletState.session) === malletState.curr_session){
            if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
            return;
        }
        setAccordion(0);
        setError({title:"Data Error, Requirements not met", data:['Session Must Be An Active Session', 'Class Required', 'Subject Required']});
    }
    const handleAccord1Change = (e) => {
        if(e.target.name === "quest_pic"){
            setAccordState({...accordState, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value})
            return;
        }
        if(e.target.value === "true" || e.target.value === "false"){
            setAccordState({...accordState, [e.target.name]:toBoolean(e.target.value)});
            return;
        }
        setAccordState({...accordState, [e.target.name]:e.target.value});
    }
    const handleAccord2Change = (e) => {
        if(e.target.name === "quest_pic"){
            setAccordState2({...accordState2, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value})
            return;
        }
        setAccordState2({...accordState2, [e.target.name]:e.target.value});
    }
    const handleMalletChange = (e) => {
        setRecordState(initialExamRecordState);
        if(e.target.name === "classes" && e.target.value){
            const class_item = allClassesData.filter((item) => item.classes === parseInt(e.target.value));
            const cit = class_item.length > 0 ? class_item[0] : {classes__class_section_school:0}
            setMalletTableLoading(true);
            setMalletState({...malletState, [e.target.name]:e.target.value, subject:'', class_section:cit.classes__class_section_school, type:'classes'});
            return;
        }
        if((e.target.name === "subject" && e.target.value) || (e.target.name === "session" && malletState.subject && e.target.value)){
            setMalletTableLoading(true);
        }
        setMalletState({...malletState, [e.target.name]:e.target.value, type:''});
    }
    const handleMallet2Change = (e) => {
        setMalletState2({...malletState2, [e.target.name]:e.target.value, pre:false});
        setDataTableLoading(true);
    }
    const handleEditEQChange = e => {
        if(e.target.value === "true" || e.target.value === "false" || e.target.value === true || e.target.value === false){
            setMenuDropData({...menuDropData, [e.target.name]:toBoolean(e.target.value)});
            return;
        }
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value});
    }
    const handleEditImageEQChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value, [e.target.name+'_img']:URL.createObjectURL(e.target.files[0])})
    }

    const fetchAllSchoolSession = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices5.getCurrentSession(sid).then(sess_res => {
                if(sess_res.data.id){
                    setMalletState((prev) => typeof prev === "object" && {...prev, session:sess_res.data.id, curr_session:sess_res.data.id, session_num:sess_res.data.session, term_name:sess_res.data.term_name, pre:true});
                }
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllSubjectETT = (stid, csid) => {
        if(stid && csid){
            setError({data:[], title:''});
            appServices4.getAllSubjectThought(stid, csid).then(res => {
                setAllSubjectData(res.data);
            }).catch(e => {
                setAllSubjectData((prev) => prev.length ? [...prev] : []);
                setError({data:["Internal Server Error"], title:"Subject Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllSchoolBranch = (sid) => {
        if(sid){
        setError({data:[], title:''});
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData(res.data);
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
        }
    }
    const fetchAllClasses = (stid, sid) => {
        if(stid){
        setError({data:[], title:''});
        appServices4.getAllClassesThought(stid, sid).then(res => {
            setAllClassesData(groupArray(res.data, 'classes'));
        }).catch(e => {
            setAllClassesData([]);
            setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
        }
    }
    const fetchAllEQ = (pre=true, esid, mcq, stid, subid) => {
        setPreloading(pre);
        setSpinLoading(!pre);
        setMalletState((prev) => typeof prev === "object" ? {...prev, pre:true} : {...prev});
        console.log(subid, 'Sub')
        appServices4.getAllSchoolExamQuestion(esid, stid, mcq, subid).then(res => {
            setPreloading(false);
            setSpinLoading(false);
            setAllEQData(addItemToArray(res.data, {is_changeable:false} ));
            console.log(res.data);
        }).catch(e => {
            setPreloading(false);
            setSpinLoading(false);
            setAllEQData([]);
            setError({data:["Internal Server Error"], title:"Exam Data Fetch Error"});
        })
    }
    const fetchAllEQF = (esid, stid, subid) => {
        setSpinLoading(true);
        appServices4.getAllSchoolExamMultiQuestionFig(esid, stid, subid).then(res => {
            setSpinLoading(false);
            setAllEQFData(addItemToArray(res.data, {is_clicked:false} ));
        }).catch(e => {
            setSpinLoading(false);
            setAllEQFData([]);
            setError({data:["Internal Server Error"], title:"Exam Fig Data Fetch Error"});
        })
    }
    const fetchQuestRecord = (stid, subid, cid, init={}) => {
        if(stid && subid && cid){
            setHoverLoading(true);
            appServices4.getSchoolExamQuestionSubject1(subid, cid, stid).then(res => {
                setRecordState(res.data);
                setHoverLoading(false);
                setDataTableLoading(true);
            }).catch(e => {
                setHoverLoading(false);
                if(typeof e.response === "object" && e.response.status === 404){
                    setRecordState({...init, error:true});
                    return;
                }
                setRecordState({...init, server_error:true});
            })
            return;
        }
        setRecordState({...init});
    }

    const handleClickStartEQ = () => {
        setError(initialErrorState);
        setLoading(true);
        if(malletState.session && malletState.subject && malletState.session && malletState.classes && parseInt(malletState.session) === malletState.curr_session){
            const data = {
                stid: access.uid,
                subject: malletState.subject,
                session: malletState.session,
                classes: malletState.classes,
                created_by: user_id
            }
            appServices4.insertSchoolExamQuestionSubject(data, malletState.subject).then(res => {
                setRecordState({...initialErrorState, ...res.data});
                setLoading(false);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Error'});
                setLoading(false);
            })
            return;
        }
        setError({data:['Data Error'], title:'Error'});
        setLoading(false);
    }
    const handleSubmitExamQuestion = e => {
        e.preventDefault();
        setError(initialErrorState)
        setSuccess(initialSuccessState);
        setAccordionLoading(true);
        setSpinLoading(true);
        setSubmit("insert_exam_question");
        setAccordFormErrorState(validateExamQuestionForm({...accordState, school_branch:-10000000000000}));
        if(submit === "inserted_exam_question"){
            let data;
            if(accordState.mcq === "obj"){
                data = getFormData({exam_subject:recordState.id, exam_type:accordState.mcq, quest:accordState.exam_question, quest_pic:accordState.quest_pic_value ? accordState.quest_pic : '', school_branch:access.school_branch, created_by:user_id, mark:accordState.mark, obj_a:accordState.obj_a, obj_b:accordState.obj_b, obj_c:accordState.obj_c, obj_d:accordState.obj_d, obj_e:accordState.obj_e, obj_ans:accordState.exam_question_answer, is_pic_bottom:accordState.quest_pic_value ? accordState.is_pic_bottom : false, stid:access.uid})
            } else {
                data = getFormData({exam_subject:recordState.id, exam_type:accordState.mcq, quest:accordState.exam_question, quest_pic:accordState.quest_pic_value ? accordState.quest_pic : '', school_branch:access.school_branch, created_by:user_id, mark:accordState.mark, is_pic_bottom:accordState.quest_pic_value ? accordState.is_pic_bottom : false,  is_quest_compulsory:accordState.is_compulsory, stid:access.uid});
            }
            setAccordState({...accordState, is_preview:!accordState.is_preview});
            appServices4.insertSchoolExamQuestion(data, malletState.subject).then(res => {
                setAccordionLoading(false);
                setSpinLoading(false);
                accordState.mcq === "fill in gap" ? setRecordState({...recordState, tot_fill_gap_quest:recordState.tot_fill_gap_quest + 1}) : setRecordState({...recordState, [`tot_${accordState.mcq}_quest`]:recordState[`tot_${accordState.mcq}_quest`]})
                setAccordState({...initialAccordState});
                setSuccess({text:"Data Submitted Successfully", title:"Success"});
                setAllEQData(updateArray(allEQData, allEQData.length, res.data));
                setAccordFormErrorState({});
                setSubmit('');
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Data Insert Error'});
                setAccordionLoading(false);
                setSpinLoading(false);
                setSubmit('');
            })
            return;
        }
        setAccordionLoading(false);
        setSpinLoading(false);
        // if(accordState.is_preview){
        //     setError({title:'Data Insert Error', data:['Something went wrong']});
             // setAccordState({...accordState, is_preview:false});
        // }

    }
    const handleSubmitExamMultiQuestionPic = e => {
        e.preventDefault();
        setError(initialErrorState)
        setSuccess(initialSuccessState);
        setAccordionLoading(true);
        setSpinLoading(true);
        setSubmit("insert_exam_multi_question_pic");
        setAccordFormErrorState2(validateExamMultiQuestionFigForm(accordState2));
        if(submit === "inserted_exam_multi_question_pic"){
            let data = getFormData({exam_subject:recordState.id, name:accordState2.name,  quest_pic:accordState2.quest_pic, exam_type:accordState2.mcq, created_by:user_id, stid:access.uid})
            appServices4.insertSchoolExamMultiQuestionFig(data, malletState.subject).then(res => {
                setAccordionLoading(false);
                setSpinLoading(false);
                setAccordState2({...initialAccordState2});
                setSuccess({text:"Data Submitted Successfully", title:"Success"});
                setAllEQFData(updateArray(allEQFData, allEQFData.length, res.data));
                setAccordFormErrorState2({});
                setSubmit('');
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Data Insert Error'});
                setAccordionLoading(false);
                setSpinLoading(false);
                setSubmit('');
                console.log(e.response);
            })
            return;
        }
        setAccordionLoading(false);
        setSpinLoading(false);

    }
    const handleSubmitUpdateExamQuestion = e => {
        e.preventDefault();
        setError(initialErrorState)
        setSuccess(initialSuccessState);
        setSubmit("update_exam_question");
        setMenuDropDataError(validateExamQuestionForm({...menuDropData, school_branch:-10000000000000}));
        if(submit === "updated_exam_question"){
            let data;
            if(menuDropData.mcq === "obj"){
                data = getFormData({quest:menuDropData.exam_question, quest_pic:menuDropData.quest_pic_value ? menuDropData.quest_pic : '',  mark:menuDropData.mark, obj_a:menuDropData.obj_a, obj_b:menuDropData.obj_b, obj_c:menuDropData.obj_c, obj_d:menuDropData.obj_d, obj_e:menuDropData.obj_e, obj_ans:menuDropData.exam_question_answer, is_pic_bottom:menuDropData.is_pic_bottom})
            } else {
                data = getFormData({quest:menuDropData.exam_question, quest_pic:menuDropData.quest_pic_value ? menuDropData.quest_pic : '', mark:menuDropData.mark, is_pic_bottom: menuDropData.is_pic_bottom, is_quest_compulsory:menuDropData.is_compulsory});
            }
            setMenuDropLoading(true);
            appServices4.updateSchoolExamQuestion(menuDropData.uid, data, malletState.subject, access.uid).then(res => {
                setMenuDropLoading(false);
                setSuccess({text:"Data Updated Successfully", title:"Success"});
                setMenuDropDataError({});
                setAllEQData(updateArray(allEQData, menuDropData.i, res.data));
                setMenuDrop(false);
                setSubmit(menuDropData.i);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Data Update Error'});
                setSubmit('');
                setMenuDropLoading(false);
            })
            return;
        }
        setMenuDropLoading(false);

    }
    const handleSubmitRecord = useCallback((name="", value, uid) => {
        setSpinLoading(true);
        setError({title:'', data:[]})
        setSuccess({title:'', text:''});
        appServices4.updateSchoolExamQuestionSubject(uid, access.uid, {[name]:value, scores_mal:true}, malletState.subject).then(res => {
            setSpinLoading(false);
            setSuccess({text:"Data Changed Successfully", title:"Success"});
            setRecordState(res.data);
        }).catch(e => {
            setSpinLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Error'});
        })
    }, [malletState, access])
    
    const handleChangeSubmitRecord = e => {
        let value = 0;
        if(e.target.value === ""){
            value = 0;
        } else {
            value = parseInt(e.target.value)
        }
        setRecordState({...recordState, [e.target.name]:value});
        setChangedState({name:e.target.name, value:value});
        setSubmit('update_record_state');
    }
    const onClickPreview = (e, prev=false) => {
        e.preventDefault();
        setAccordState({...accordState, is_preview:prev});
    }
    const handleEditRecord = (data) => {
        setMenuDrop(true);
        setMenuDropDataError({});
        setMenuDropData(data);
    }
    const handleChangeEqListLoc = (type="", num=-1, item={}, cmd="") => {
        if(cmd === 'click'){
            const item_one = {...item, num:num};
            const tot_item = {...eqChangeState, one:item_one, two:{id:0, uid:''}, type:type};
            setEqChangeState(tot_item);
            return;
        }
        if(cmd === 'cancel'){
            setEqChangeState(initialEqChangeState);
            return;
        }
        if(cmd === "change"){
            setError(initialErrorState);
            const item_one = {...eqChangeState.one};
            if(eqChangeState.type === type && item_one.id){
                const item_two = {...item, num:num}
                setLoading(true);
                setSpinLoading(true);
                appServices4.updateSchoolExamQuestionOrder(item_two.uid, access.uid, {order:item_one.order, order_a:item_one.order}, malletState.subject).then(res => {
                    setAllEQData(updateArray(allEQData, item_two.num, {...item_two, order:item_one.order, is_changeable:true}));
                    appServices4.updateSchoolExamQuestionOrder(item_one.uid, access.uid, {order:item_two.order, order_a:item_two.order}, malletState.subject).then(res => {
                        setLoading(false);
                        setMalletState2({...malletState2, pre:false});
                        setEqChangeState({...eqChangeState, one:item_one, two:item_two});
                        setSuccess({text:"Data Interchanged Successfully", title:"Success"});
                        // setDataTableLoading(true);
                        setTimeout(() => {
                            setSpinLoading(false);
                        }, 300)
                    }).catch(e => {
                        setSpinLoading(false);
                        setLoading(false);
                        setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:"Data interchange Error"})
                    })
                }).catch(e => {
                    setSpinLoading(false);
                    setLoading(false);
                    console.log(e.response);
                    setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:"Data interchange Error"})
                })
                return;
            }
            setError({data:['Something went wrong'], title:"Data interchange Error"})
        }
        return;
    }
    const handleEQUpload = (e) => {
        e.preventDefault();
        setWarning({id:0, title:'', text:''});
        setTimeout(() => {
            setWarning({id:5, click_text:`Upload`, 
             title:"Warning", text:"Once uploaded, access to this subject will be revoked!",
             handleClick: () =>{
                setLoading(true);
                setError(initialErrorState);
                setSuccess(initialSuccessState);
                appServices4.updateSchoolExamQuestionSubject(recordState.uid, access.uid, {is_staff_uploaded:true}, malletState.subject).then(res => {
                    setRecordState(res.data);
                    setTimeout(() => {
                        setLoading(false);
                        setWarning({id:0, title:'', text:''});
                        setSuccess({text:"All Question(s) Uploaded Successfully :)", title:"Success"});
                    }, 800)
                }).catch(e => {
                    setLoading(false);
                    setWarning({id:0, title:'', text:''});
                    setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Data Upload Error'});
                })
             }
            })
        })
    }
    const handleDeleteQuestFig = (uid="", num=-1) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices4.deleteSchoolExamMultiQuestionFig(uid, access.uid, malletState.subject).then(res => {
            const filter = addItemToArray(allEQFData.filter((item) => item.uid !== uid));
            setAllEQFData(filter);
            setSuccess({text:"Data Deleted Successfully", title:"Success"});
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:"Delete Error"})
        })
    }
    const handleDeleteRecord = (uid="", num=-1) => {
        setSpinLoading(true);
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices4.deleteSchoolExamQuestion(uid, access.uid, malletState.subject).then(() => {
            setAnim({initial:{opacity: 1, duration:0.4},
                animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
                id:num });
            const filter = addItemToArray(allEQData.filter((item) => item.uid !== uid));
            setLoading(false);
            setTimeout(() => {
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:num });
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
                setAllEQData(filter);
                setSpinLoading(false);
            }, 400)
        }).catch(e => {
            setSpinLoading(false);
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:"Delete Error"})
        })
    }
    console.log(recordState)

    // useEffect(() => {
    //     setRecordState(initialExamRecordState);
    //     setAccordState(initialAccordState);
    //     fetchAllSchoolSession(school_id);
    //     fetchAllClasses(school_id);
    //     fetchAllSubjectETT(school_id);
    //     fetchAllSchoolBranch(school_id);
    // }, [school_id, initialAccordState, initialExamRecordState])
    useEffect(() => {
        if(malletTableLoading && malletState.type === "classes"){
            setMalletTableLoading(false);
            fetchAllSubjectETT(access.uid, malletState.class_section);
            return;
        }
        if(malletTableLoading){
            setMalletTableLoading(false);
            fetchQuestRecord(access.uid, malletState.subject, malletState.classes, initialExamRecordState);
            return;
        }
        if(dataTableLoading){
            setDataTableLoading(false);
            // const sb = malletState2.school_branch ? allSchoolBranchData.filter((item) => item.id === parseInt(malletState2.school_branch))[0] : {uid:''};
            fetchAllEQ(malletState2.pre, recordState.uid, malletState2.mcq, access.uid, malletState.subject);
            fetchAllEQF(recordState.uid, access.uid, malletState.subject);
        }
    }, [malletTableLoading, access.uid, school_id, malletState, malletState2, initialExamRecordState, dataTableLoading, allSchoolBranchData, recordState]);

    useEffect(() => {
        if(eqChangeState.one.uid && eqChangeState.two.uid){
            setAllEQData((prev) => prev.length > 0 ? addItemToArray(updateArray(prev, eqChangeState.one.num, {...eqChangeState.one, order:eqChangeState.two.order}), {is_changeable:false}).sort((a,b) => a.order - b.order) : []);
            setEqChangeState(initialEqChangeState);
            return;
        }
    }, [eqChangeState, initialEqChangeState])

    useEffect(() => {
        if(Object.keys(accordFormErrorState).length === 0 && submit === "insert_exam_question"){
            setSubmit('inserted_exam_question');
            return;
        }
        if(Object.keys(accordFormErrorState2).length === 0 && submit === "insert_exam_multi_question_pic"){
            setSubmit('inserted_exam_multi_question_pic');
            return;
        }
        if(Object.keys(menuDropDataError).length === 0 && submit === "update_exam_question"){
            setSubmit('updated_exam_question');
            return;
        }
    }, [submit, accordFormErrorState, accordFormErrorState2, menuDropDataError])
    useEffect(() => {
        if(submit === "update_record_state" && changedState.name){
            if(initialChangedRecordState.current){
                initialChangedRecordState.current = false;
                return;
            }
            const timeout = setTimeout(() => {
                handleSubmitRecord(changedState.name, changedState.value, recordState.uid);
                setSubmit('');
                setChangedState({});
            }, 800);
            return () => clearTimeout(timeout);
        }
    }, [submit, recordState, changedState, handleSubmitRecord]);

    useEffect(() => {
        if(reload && access.uid){
            setReload(false);
            fetchAllSchoolSession(school_id);
            fetchAllClasses(access.uid, school_id);
            fetchAllSchoolBranch(school_id);
            setRecordState(initialExamRecordState);
            setAccordState(initialAccordState);
            setMenuDropData({});
        }
    }, [access.uid, reload, school_id, initialExamRecordState, initialAccordState])

    console.log(allEQFData)

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertClassesOption = allClassesData.map((item, i) => {
        return(
            <option value={item.classes} key={i}>{item.classes__name}</option>
        )
    })
    // const insertSchoolBranchOption = allSchoolBranchData.map((item, i) => {
    //     return(
    //         <option value={item.id} key={i}>{item.name}</option>
    //     )
    // })
    const objLetter = (num=0) => {
        if(parseInt(num) === 1){
            return 'a';
        }
        if(parseInt(num) === 2){
            return 'b';
        }
        if(parseInt(num) === 3){
            return 'c';
        }
        if(parseInt(num) === 4){
            return 'd';
        }
        if(parseInt(num) === 5){
            return 'e';
        }
        return '';
    }
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmit("");
        }, 1000 * 15)
        
        return(
            <div className="nav-dd-anim" style={{display:submit}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }
    const insertSubjectMalletOption = () => {
        if(malletState.classes){
            const class_item = allClassesData.filter((item) => item.classes===parseInt(malletState.classes))[0]
            return allSubjectData.filter((item) => item.class_section === class_item.classes__class_section_school).map((item, i) => {
                return(
                    <option value={item.subject} key={i}>{`${item.subject_name}`}</option>
                )
            })
        } else {
            return (<option value={''}>{`No data found`}</option>);
        }
    }

    const insertCreateExamQuestionGridContent = () => {
        if(!recordState.is_staff_uploaded && recordState.id && recordState.session_active){
            return(
                <GridContent header_text={`Create ${recordState.subject_name.length > 15 ? recordState.subject_name.slice(0, 15)+'...' : recordState.subject_name} Exam Question`} header_icon="fas fa-plus">
                    {accordionLoading && <Loader />}
                    <Accordion index={1} text={`Add Exam Question`} handleSubmit={e => handleSubmitExamQuestion(e)} handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                        {accordion === 1 && (
                            <>
                            {!accordState.is_preview ? (
                                <>
                                    <div className='disp-flex wrap'>
                                    <div className={accordState.quest_pic_value ? 'flex-50' : 'flex-100'}>
                                        <AccordionFormData tag="input" handleChange={e => handleAccord1Change(e)} type="file" placeholder='Choose Question Image' name="quest_pic" error={accordFormErrorState.quest_pic} value={accordState.quest_pic_value} />
                                    </div>
                                    {accordState.quest_pic_value && (
                                        <div className={'flex-50'}>
                                            <div className='disp-flex wrap jc-stretch'>
                                                <div className='padd-4px' style={{marginTop:'2px'}}>
                                                    <input type="checkbox" name="is_pic_bottom" disabled={accordState.is_pic_multi_question} value={!toBoolean(accordState.is_pic_bottom)} onChange={e => handleAccord1Change(e)} checked={accordState.is_pic_bottom} /> <label className='font-super-small app-text-color'>Insert at bottom?*</label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div style={{width:'calc(100% - 10px)', marginBottom:'4px'}}>
                                    <label className='font-super-small'>Enter Exam Question</label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={accordState.exam_question}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setAccordState({...accordState, exam_question:data});
                                        } }
                                        
                                    />
                                    {accordFormErrorState.exam_question && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{accordFormErrorState.exam_question }</span>)  }
                                </div>
                                <AccordionFormData error={accordFormErrorState.mcq} handleChange={e => handleAccord1Change(e)}  tag="select" name="mcq" value={accordState.mcq} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Question Type</option>
                                    <option value='obj'>Objective</option>
                                    <option value='theory'>Theory</option>
                                    <option value='fill in gap'>Fill in the gap</option>
                                </AccordionFormData>
                                {accordState.mcq === "obj" && (
                                    <>
                                        <div className='disp-flex wrap'>
                                    <span className='flex-100 disp-block align-center fw-700 font-very-small app-text-sec-color'>OBJ ANSWERS</span>
                                    <div className='flex-50-line'>
                                        <AccordionFormData error={accordFormErrorState.obj_a} handleChange={e => handleAccord1Change(e)} styleInp={{height:'60px'}} tag={'input'} type={'textarea'} value={accordState.obj_a} name="obj_a" classname="form-control-theme font-slightly-small" placeholder="Enter OBJ A" />
                                    </div>
                                    <div className='flex-50-line'>
                                        <AccordionFormData error={accordFormErrorState.obj_b} handleChange={e => handleAccord1Change(e)} styleInp={{height:'60px'}} tag={'input'} type={'textarea'} value={accordState.obj_b} name="obj_b" classname="form-control-theme font-slightly-small" placeholder="Enter OBJ B" />
                                    </div>
                                    <div className='flex-50-line'>
                                        <AccordionFormData error={accordFormErrorState.obj_c} handleChange={e => handleAccord1Change(e)} styleInp={{height:'60px'}} tag={'input'} type={'textarea'} value={accordState.obj_c} name="obj_c" classname="form-control-theme font-slightly-small" placeholder="Enter OBJ C*" />
                                    </div>
                                    <div className='flex-50-line'>
                                        <AccordionFormData error={accordFormErrorState.obj_d} handleChange={e => handleAccord1Change(e)} styleInp={{height:'60px'}} tag={'input'} type={'textarea'} value={accordState.obj_d} name="obj_d" classname="form-control-theme font-slightly-small" placeholder="Enter OBJ D*" />
                                    </div>
                                    <div className='flex-50-line'>
                                        <AccordionFormData error={accordFormErrorState.obj_e} handleChange={e => handleAccord1Change(e)} styleInp={{height:'60px'}} tag={'input'} type={'textarea'} value={accordState.obj_e} name="obj_e" classname="form-control-theme font-slightly-small" placeholder="Enter OBJ E*" />
                                    </div>
                                </div>
                                <AccordionFormData error={accordFormErrorState.exam_question_answer} handleChange={e => handleAccord1Change(e)}  tag="select" name="exam_question_answer" value={accordState.exam_question_answer} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Choose Question Answer</option>
                                    <option value={1}>A</option>
                                    <option value={2}>B</option>
                                    <option value={3}>C</option>
                                    <option value={4}>D</option>
                                    <option value={5}>E</option>
                                </AccordionFormData>
                                <AccordionFormData error={accordFormErrorState.mark} handleChange={e => handleAccord1Change(e)}  tag={'input'} type={'number'} value={accordState.mark} name="mark" classname="form-control-theme font-slightly-small" placeholder="Enter Mark" />
                                </>
                                )}
                                {accordState.mcq === "theory" && (
                                    <>
                                        <AccordionFormData error={accordFormErrorState.mark} handleChange={e => handleAccord1Change(e)}  tag={'input'} type={'number'} value={accordState.mark} name="mark" classname="form-control-theme font-slightly-small" placeholder="Enter Mark" />
                                    <div className='flex-100'>
                                        <input type="checkbox" name="is_compulsory" checked={accordState.is_compulsory} value={!toBoolean(accordState.is_compulsory)} onChange={e => handleAccord1Change(e)} /><label className='font-very-small app-text-color'>Make question compulsory?*</label>
                                    </div>
                                    </>
                                )}
                                {accordState.mcq === "fill in gap" && (
                                    <>
                                        <AccordionFormData error={accordFormErrorState.mark} handleChange={e => handleAccord1Change(e)}  tag={'input'} type={'number'} value={accordState.mark} name="mark" classname="form-control-theme font-slightly-small" placeholder="Enter Mark" />
                                        <div className='flex-100'>
                                            <input type="checkbox" name="is_compulsory" checked={accordState.is_compulsory} value={!toBoolean(accordState.is_compulsory)} onChange={e => handleAccord1Change(e)} /><label className='font-very-small app-text-color'>Make question compulsory?*</label>
                                        </div>
                                    </>
                                )}
                                </>
                            ) : (
                                <>
                                
                                </>
                            )}
                                
                                {accordState.mcq  && (
                                    <>
                                    <div className="disp-flex wrap">
                                        <div className='flex-50-line'>
                                            <AccordionFormData handleClick={e => onClickPreview(e, !accordState.is_preview)} contentClass="align-center" btn_text={ accordState.is_preview ? 'Back' : "Preview"} btn_icon={accordState.is_preview ? "fas fa-arrow-left" : "fas fa-play"} tag="btn" type="button" 
                                            classname={`font-slightly-small app-bg-text-color mybtn ${accordState.is_preview ? 'bg-black' : 'bg-green'}`}/>
                                        </div>
                                        <div className={'flex-50-line'}>
                                            <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                            classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                        </div>
                                    </div>
                                    </>
                                )}
                            
                            </>
                        )}
                    </Accordion>
                    <Accordion index={2} text={`Create Figure For Multi-Question`} handleSubmit={e => handleSubmitExamMultiQuestionPic(e)} handleClick={() => handleAccordionClick(2) } currentAccordion={accordion}>
                        {accordion === 2 && (
                            <>
                                <AccordionFormData tag="input" handleChange={e => handleAccord2Change(e)} type="file" placeholder='Choose Questions Image' name="quest_pic" error={accordFormErrorState2.quest_pic} value={accordState2.quest_pic_value} />
                                <AccordionFormData error={accordFormErrorState2.name} handleChange={(e) => handleAccord2Change(e)} value={accordState2.name} tag="input" type="text" name="name"  classname="form-control-theme font-slightly-small" placeholder="Enter Figure's Name" />
                                <AccordionFormData error={accordFormErrorState2.mcq} handleChange={e => handleAccord2Change(e)}  tag="select" name="mcq" value={accordState2.mcq} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Question Type</option>
                                    <option value='obj'>Objective</option>
                                    <option value='theory'>Theory</option>
                                    <option value='fill in gap'>Fill in the gap</option>
                                </AccordionFormData>
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>
                        )}
                    </Accordion>
                </GridContent>
            )
        } else {
            return <></>
        }
    }
    const insertExamQuestionGridContentManager = () => {
        if(recordState.id){
            const filterObj = allEQData.filter((item) => item.exam_type === "obj").sort((a, b) => a.order - b.order);
            const filterTheory = allEQData.filter((item) => item.exam_type === "theory");
            const filterFillGap = allEQData.filter((item) => item.exam_type === "fill in gap");
            console.log(filterTheory)

            const insertObjTable = () => {
                if(filterObj.length > 0){
                    return(
                        <>
                        {!recordState.is_staff_uploaded && (
                            <div className='disp-block align-center w-100'>
                                <button type="button" className='mybtn bg-blue hover-opacity br app-bg-text-color font-slightly-small' onClick={(e) => handleEQUpload(e)}>Upload <i className='fas fa-upload'></i></button>
                            </div>
                        )}
                        <span className='app-text-color fw-700 font-slightly-small disp-block padd-5px'>Objective Question(s)</span>
                        
                            <div className='disp-flex jc-sb wrap'>
                                <div className='form-group padd-5px'>
                                    <label className='font-super-small app-text-color fw-500'>Total Obj Questions Required*</label>
                                    {!recordState.is_staff_uploaded ? (<input type="number" disabled={recordState.is_staff_uploaded} className={`font-very-small app-text-color form-control-theme ${recordState.is_staff_uploaded && 'disabled'}`} name="obj_quest" onChange={e => handleChangeSubmitRecord(e)} value={parseInt(recordState.obj_quest) ? recordState.obj_quest : ''} placeholder='Enter Total Obj Question' />): ( <span className="font-very-small app-text-color fw-600">{recordState.obj_quest}</span>)}
                                </div>
                                <div className='form-group padd-5px'>
                                    <label className='font-super-small app-text-color fw-500'>Total Obj Questions To Answer*</label>
                                    {!recordState.is_staff_uploaded ? (<input type="number" disabled={recordState.is_staff_uploaded} className={`font-very-small app-text-color form-control-theme ${recordState.is_staff_uploaded && 'disabled'}`} onChange={e => handleChangeSubmitRecord(e)} name="obj_quest_ans" value={parseInt(recordState.obj_quest_ans) ? recordState.obj_quest_ans : ''} placeholder='Enter Total Obj To Answer' />): ( <span className="font-very-small app-text-color fw-600">{recordState.obj_quest_ans}</span>)}
                                </div>
                            </div>
                        
                        <div className="w100 align-right">
                            <div className='form-group' style={{marginTop:'5px'}}>
                                <label className='font-super-small app-text-sec-color fw-700'>Total Obj Questions</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" className='font-very-small form-control disabled' style={{width:'70px'}} disabled defaultValue={filterObj.length} />) : (<span className="font-very-small app-text-color fw-600">{filterObj.length}</span>)}
                            </div>
                        </div>
                        <PicPlaceHolder>
                            {allEQFData.filter((item) => item.exam_type === "obj").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <ImagePic src={`${BACKEND_URL}${item.quest_pic}`} alt={item.name} name={item.name} btn={!recordState.is_staff_uploaded} dlt_btn={true} onClickDelete={() => handleDeleteQuestFig(item.uid, item.num)} />
                                    </Fragment>
                                )
                            })}
                        </PicPlaceHolder>
                        <Table>
                            <Tr btn={false} header={true} sn={true} text={[{text:"S/N"},  {text:"Question", style:{width:'100%'}}]} />
                            {filterObj.map((item, i) => {
                                const j = i +1;
                                const data = {
                                    ...initialAccordState,
                                    i: item.num,
                                    id: item.id,
                                    uid: item.uid,
                                    school_branch: item.school_branch,
                                    exam_question: item.quest,
                                    view_quest_pic: item.quest_pic,
                                    mcq:item.exam_type,
                                    obj_a:item.obj_a,
                                    obj_b:item.obj_b,
                                    obj_c:item.obj_c,
                                    obj_d:item.obj_d,
                                    obj_e:item.obj_e,
                                    exam_question_answer:item.obj_ans,
                                    is_pic_bottom: item.is_pic_bottom,
                                    is_pic_multi_question:item.is_pic_multi_question,
                                    mark: item.mark
                                }
                                const created_by_name = `${item.created_by_last_name} ${item.created_by_first_name}`;
                                return(
                                    <Fragment key={i}>
                                        <Tr sn={true} btn={item.created_by !== user_id ? false : !recordState.is_staff_uploaded} style={eqChangeState.type ==="obj" ? (eqChangeState.one.id === item.id ? {backgroundColor:'#00ff90'} : (item.is_changeable ? {backgroundColor:'#00ff90'} : {}) ) : {}} edit_btn={eqChangeState.type ==="obj" ? (eqChangeState.one.id ? false : true) : true} upgrade_btn={eqChangeState.type ==="obj" ? (eqChangeState.one.id ? false : true) : true} check_btn={eqChangeState.type ==="obj" ? (eqChangeState.one.id === item.id ? false : true) : false} times_btn={eqChangeState.type ==="obj" ? (eqChangeState.one.id === item.id ? true : false) : false} upgrade_text='Change' dlt_btn={eqChangeState.type ==="obj" ? (eqChangeState.one.id ? false : true) : true} handleUpgradeClick={() => handleChangeEqListLoc("obj", item.num, item, "click")} handleTimesClick={() => handleChangeEqListLoc("obj", 0, {}, 'cancel')} handleCheckClick={() => handleChangeEqListLoc("obj", item.num, item, "change")} 
                                          handleEditClick={() => handleEditRecord(data)} handleDeleteClick={() => handleDeleteRecord(item.uid, item.num)}
                                            animation={(item.num === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                                            <div className="c-data sn">{item.num === submit ? updateSuccessIcon() : (<span className="font-very-small">{j}</span>)}</div>
                                            <div className='c-data' style={{width:'100%'}}>
                                                {item.quest_pic && <> {!item.is_pic_bottom && <img width="50" height="50" alt="Question" src={`${BACKEND_URL}${item.quest_pic}`} />}</>}
                                                <span className="font-very-small disp-block app-text-color">{parse(item.quest)}</span>
                                                {item.quest_pic && <> {item.is_pic_bottom && (<><img width="50" height="50" alt="Question" src={`${BACKEND_URL}${item.quest_pic}`} /><br /></>)}</>}
                                                <span className='font-super-small disp-inline app-text-color' style={{paddingRight:'3px'}}><b>(a)</b>{item.obj_a} </span>
                                                <span className='font-super-small disp-inline app-text-color'> <b>(b)</b>{item.obj_b} </span>
                                                {item.obj_c && (<span className='font-super-small disp-inline app-text-color' style={{paddingRight:'2px'}}> <b>(c)</b>{item.obj_c} </span>)}
                                                {item.obj_d && (<span className='font-super-small disp-inline app-text-color' style={{paddingRight:'2px'}}> <b>(d)</b>{item.obj_d} </span>)}
                                                {item.obj_e && (<span className='font-super-small disp-inline app-text-color'> <b>(e)</b>{item.obj_e} </span>)}
                                                <div className='disp-flex jc-sb wrap' style={{marginTop:'2px'}}>
                                                    <div>
                                                        <span className='font-super-small app-text-color fw-600'>{item.mark} mark</span>
                                                    </div>
                                                    <div>
                                                        <span className='font-very-small app-text-color fw-700'>({objLetter(item.obj_ans)})</span>
                                                    </div>
                                                    <div>
                                                        <span className='font-super-small app-text-color fw-500'>{item.school_branch_name.length > 8 ? item.school_branch_name.slice(0, 8)+'...' : item.school_branch_name}</span>
                                                    </div>
                                                </div>
                                                <div className='disp-block align-right'>
                                                    <span className="disp-inline"><span className='fw-600 font-super-small app-text-color'>Created By: </span><span className='app-text-color fw-500 font-super-small'>{created_by_name.length > 15 ? `${created_by_name.slice(0, 15)}...` : created_by_name} </span> <br /> <span className="font-super-small" style={{color:'#777'}}>{convertDate(new Date(item.created), 'ddMMyyyy')}</span></span>
                                                </div>
                                            </div>
                                        </Tr>
                                    </Fragment>
                                )
                            })}
                        </Table>
                        </>
                    )
                }
            }
            const insertTheoryTable = () => {
                if(filterTheory.length > 0){
                    return(
                        <>
                        <br />
                        <span className='app-text-color fw-700 font-slightly-small disp-block'>Theory Question(s)</span>
                        <div className='disp-flex jc-sb wrap'>
                            <div className='form-group padd-5px'>
                                <label className='font-super-small app-text-color fw-500'>Total Theory Questions Required*</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" disabled={recordState.is_staff_uploaded} className={`font-very-small app-text-color form-control-theme ${recordState.is_staff_uploaded && 'disabled'}`} name="theory_quest" onChange={e => handleChangeSubmitRecord(e)} value={parseInt(recordState.theory_quest) ? recordState.theory_quest : ''} placeholder='Enter Total Theory Question' />): ( <span className="font-very-small app-text-color fw-600">{recordState.theory_quest}</span>)}
                            </div>
                            <div className='form-group padd-5px'>
                                <label className='font-super-small app-text-color fw-500'>Total Theory Questions To Answer*</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" disabled={recordState.is_staff_uploaded} className={`font-very-small app-text-color form-control-theme ${recordState.is_staff_uploaded && 'disabled'}`} name="theory_quest_ans" onChange={e => handleChangeSubmitRecord(e)} value={parseInt(recordState.theory_quest_ans) ? recordState.theory_quest_ans : ''} placeholder='Enter Total Theory To Answer' />): ( <span className="font-very-small app-text-color fw-600">{recordState.theory_quest_ans}</span>)}
                            </div>
                        </div>
                        <div className="w100 align-right">
                            <div className='form-group' style={{marginTop:'5px'}}>
                                <label className='font-super-small app-text-sec-color fw-700'>Total Theory Questions</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" className='font-very-small form-control disabled' style={{width:'70px'}} disabled defaultValue={filterTheory.length} />) : (<span className="font-very-small app-text-color fw-600">{filterTheory.length}</span>)}
                            </div>
                        </div>
                        <PicPlaceHolder>
                            {allEQFData.filter((item) => item.exam_type === "theory").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <ImagePic src={`${BACKEND_URL}${item.quest_pic}`} alt={item.name} name={item.name} btn={!recordState.is_staff_uploaded} dlt_btn={true} onClickDelete={() => handleDeleteQuestFig(item.uid, item.num)} />
                                    </Fragment>
                                )
                            })}
                        </PicPlaceHolder>
                        <Table>
                            <Tr btn={false} header={true} sn={true} text={[{text:"S/N"},  {text:"Question", style:{width:'100%'}}]} />
                            {filterTheory.map((item, i) => {
                                const j = i +1;
                                const data = {
                                    ...initialAccordState,
                                    i: item.num,
                                    id: item.id,
                                    uid: item.uid,
                                    exam_question: item.quest,
                                    view_quest_pic: item.quest_pic,
                                    school_branch: item.school_branch,
                                    mcq:item.exam_type,
                                    is_pic_bottom: item.is_pic_bottom,
                                    is_compulsory:item.is_quest_compulsory,
                                    is_pic_multi_question:item.is_pic_multi_question,
                                    mark: item.mark
                                }
                                const created_by_name = `${item.created_by_last_name} ${item.created_by_first_name}`;
                                return(
                                    <Fragment key={i}>
                                        <Tr btn={item.created_by !== user_id ? false : !recordState.is_staff_uploaded} style={eqChangeState.type ==="theory" ? (eqChangeState.one.id === item.id ? {backgroundColor:'#00ff90'} : (item.is_changeable ? {backgroundColor:'#00ff90'} : {}) ) : {}} edit_btn={eqChangeState.type ==="theory" ? (eqChangeState.one.id ? false : true) : true} upgrade_btn={eqChangeState.type ==="theory" ? (eqChangeState.one.id ? false : true) : true} check_btn={eqChangeState.type ==="theory" ? (eqChangeState.one.id === item.id ? false : true) : false} times_btn={eqChangeState.type ==="theory" ? (eqChangeState.one.id === item.id ? true : false) : false} upgrade_text='Change' dlt_btn={eqChangeState.type ==="theory" ? (eqChangeState.one.id ? false : true) : true} handleUpgradeClick={() => handleChangeEqListLoc("theory", item.num, item, "click")} handleTimesClick={() => handleChangeEqListLoc("theory", 0, {}, 'cancel')} handleCheckClick={() => handleChangeEqListLoc("theory", item.num, item, "change")}
                                        sn={true} handleEditClick={() => handleEditRecord(data)} handleDeleteClick={() => handleDeleteRecord(item.uid, item.num)}
                                            animation={(item.num === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                                            <div className="c-data sn">{item.num === submit ? updateSuccessIcon() : (<span className="font-very-small">{j}</span>)}</div>
                                            <div className='c-data' style={{width:'100%'}}>
                                                {item.quest_pic && <> {!item.is_pic_bottom && <img width="50" height="50" alt="Question" src={`${BACKEND_URL}${item.quest_pic}`} />}</>}
                                                <span className="font-very-small disp-block app-text-color">{parse(item.quest)}</span>
                                                {item.quest_pic && <> {item.is_pic_bottom && <><img width="50" height="50" alt="Question" src={`${BACKEND_URL}${item.quest_pic}`} /><br /></>}</>}
                                                <div className='disp-flex jc-sb wrap' style={{marginTop:'2px'}}>
                                                    <div>
                                                        <span className='font-super-small app-text-color fw-600'>{item.mark} mark</span>
                                                    </div>
                                                    <div>
                                                        <span className='font-super-small app-text-color fw-500'>{item.school_branch_name.length > 8 ? item.school_branch_name.slice(0, 8)+'...' : item.school_branch_name}</span>
                                                    </div>
                                                </div>
                                                <div className='disp-block align-right'>
                                                    <span className="disp-inline"><span className='fw-600 font-super-small app-text-color'>Created By: </span><span className='app-text-color fw-500 font-super-small'>{created_by_name.length > 15 ? `${created_by_name.slice(0, 15)}...` : created_by_name} </span> <br /> <span className="font-super-small" style={{color:'#777'}}>{convertDate(new Date(item.created), 'ddMMyyyy')}</span></span>
                                                </div>
                                            </div>
                                        </Tr>
                                    </Fragment>
                                )
                            })}
                        </Table>
                        </>
                    )
                }
            }
            const insertFillGapTable = () => {
                if(filterFillGap.length > 0){
                    return(
                        <>
                        <br />
                        <span className='app-text-color fw-700 font-slightly-small disp-block'>Fill In The Gap Question(s)</span>
                        <div className='disp-flex jc-sb wrap'>
                            <div className='form-group padd-5px'>
                                <label className='font-super-small app-text-color fw-500'>Total Fill In Gap Questions Required*:</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" disabled={recordState.is_staff_uploaded} className={`font-very-small app-text-color form-control-theme ${recordState.is_staff_uploaded && 'disabled'}`} name="fill_gap_quest" onChange={e => handleChangeSubmitRecord(e)} value={parseInt(recordState.fill_gap_quest) ? recordState.fill_gap_quest : ''} placeholder='Enter Total Fill In Gap Question' />): ( <span className="font-very-small app-text-color fw-600">{recordState.fill_gap_quest}</span>)}
                            </div>
                            <div className='form-group padd-5px'>
                                <label className='font-super-small app-text-color fw-500'>Total Fill In Gap Questions To Answer*:</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" disabled={recordState.is_staff_uploaded} className={`font-very-small app-text-color form-control-theme ${recordState.is_staff_uploaded && 'disabled'}`} name="fill_gap_quest_ans" onChange={e => handleChangeSubmitRecord(e)} value={parseInt(recordState.fill_gap_quest_ans) ? recordState.fill_gap_quest_ans : ''} placeholder='Enter Total Fill In Gap To Answer' />): ( <span className="font-very-small app-text-color fw-600">{recordState.fill_gap_quest_ans}</span>)}
                            </div>
                        </div>
                        <div className="w100 align-right">
                            <div className='form-group' style={{marginTop:'5px'}}>
                                <label className='font-super-small app-text-sec-color fw-700'>Total Fill In Gap Questions:</label>
                                {!recordState.is_staff_uploaded ? (<input type="number" className='font-very-small form-control disabled' style={{width:'70px'}} disabled defaultValue={filterFillGap.length} />) : (<span className="font-very-small app-text-color fw-600">{filterFillGap.length}</span>)}
                            </div>
                        </div>
                        <PicPlaceHolder>
                            {allEQFData.filter((item) => item.exam_type === "fill in gap").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <ImagePic src={`${BACKEND_URL}${item.quest_pic}`} alt={item.name} name={item.name} btn={!recordState.is_staff_uploaded} dlt_btn={true} onClickDelete={() => handleDeleteQuestFig(item.uid, item.num)} />
                                    </Fragment>
                                )
                            })}
                        </PicPlaceHolder>
                        <Table>
                            <Tr btn={false} header={true} sn={true} text={[{text:"S/N"},  {text:"Question", style:{width:'100%'}}]} />
                            {filterFillGap.map((item, i) => {
                                const j = i +1;
                                const data = {
                                    ...initialAccordState,
                                    i: item.num,
                                    id: item.id,
                                    uid: item.uid,
                                    exam_question: item.quest,
                                    view_quest_pic: item.quest_pic,
                                    school_branch: item.school_branch,
                                    is_pic_bottom: item.is_pic_bottom,
                                    is_compulsory:item.is_quest_compulsory,
                                    is_pic_multi_question:item.is_pic_multi_question,
                                    mcq:item.exam_type,
                                    mark: item.mark
                                }
                                const created_by_name = `${item.created_by_last_name} ${item.created_by_first_name}`;
                                return(
                                    <Fragment key={i}>
                                        <Tr btn={item.created_by !== user_id ? false : !recordState.is_staff_uploaded} style={eqChangeState.type ==="fill in gap" ? (eqChangeState.one.id === item.id ? {backgroundColor:'#00ff90'} : (item.is_changeable ? {backgroundColor:'#00ff90'} : {}) ) : {}} edit_btn={eqChangeState.type ==="fill in gap" ? (eqChangeState.one.id ? false : true) : true} upgrade_btn={eqChangeState.type ==="fill in gap" ? (eqChangeState.one.id ? false : true) : true} check_btn={eqChangeState.type ==="fill in gap" ? (eqChangeState.one.id === item.id ? false : true) : false} times_btn={eqChangeState.type ==="fill in gap" ? (eqChangeState.one.id === item.id ? true : false) : false} upgrade_text='Change' dlt_btn={eqChangeState.type ==="fill in gap" ? (eqChangeState.one.id ? false : true) : true} handleUpgradeClick={() => handleChangeEqListLoc("fill in gap", item.num, item, "click")} handleTimesClick={() => handleChangeEqListLoc("fill in gap", 0, {}, 'cancel')} handleCheckClick={() => handleChangeEqListLoc("fill in gap", item.num, item, "change")}
                                        sn={true} handleEditClick={() => handleEditRecord(data)} handleDeleteClick={() => handleDeleteRecord(item.uid, item.num)}
                                            animation={(item.num === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                                            <div className="c-data sn">{item.num === submit ? updateSuccessIcon() : (<span className="font-very-small">{j}</span>)}</div>
                                            <div className='c-data' style={{width:'100%'}}>
                                                {item.quest_pic && <> {!item.is_pic_bottom && <><img width="50" height="50" alt="Question" src={`${BACKEND_URL}${item.quest_pic}`} /></>}</>}
                                                <span className="font-very-small disp-block app-text-color">{parse(item.quest)}</span>
                                                {item.quest_pic && <> {item.is_pic_bottom && <><img width="50" height="50" alt="Question" src={`${BACKEND_URL}${item.quest_pic}`} /><br /></>}</>}
                                                <div className='disp-flex jc-sb wrap' style={{marginTop:'2px'}}>
                                                    <div>
                                                        <span className='font-super-small app-text-color fw-600'>{item.mark} mark</span>
                                                    </div>
                                                    <div>
                                                        <span className='font-super-small app-text-color fw-500'>{item.school_branch_name.length > 8 ? item.school_branch_name.slice(0, 8)+'...' : item.school_branch_name}</span>
                                                    </div>
                                                </div>
                                                <div className='disp-block align-right'>
                                                    <span className="disp-inline"><span className='fw-600 font-super-small app-text-color'>Created By: </span><span className='app-text-color fw-500 font-super-small'>{created_by_name.length > 15 ? `${created_by_name.slice(0, 15)}...` : created_by_name} </span> <br /> <span className="font-super-small" style={{color:'#777'}}>{convertDate(new Date(item.created), 'ddMMyyyy')}</span></span>
                                                </div>
                                            </div>
                                        </Tr>
                                    </Fragment>
                                )
                            })}
                            {!recordState.is_staff_uploaded && (
                                <div className='disp-block align-center w-100'>
                                    <button type="button" className='mybtn bg-blue hover-opacity br app-bg-text-color font-slightly-small' onClick={(e) => handleEQUpload(e)}>Upload <i className='fas fa-upload'></i></button>
                                </div>
                            )}
                        </Table>
                        </>
                    )
                }
            }
            return(
                <GridContent header_text={`Manage ${recordState.subject_name.length > 15 ? recordState.subject_name.slice(0, 15)+'...' : recordState.subject_name} Exam Question`} header_icon="fas fa-align-left">
                    {loading && <Loader />}
                    <Section>
                        <Mallet>
                            <div className="flex-100"><label className='font-super-small'>Choose Question Type: <br />
                                <select name="mcq" onChange={e => handleMallet2Change(e)} value={malletState2.mcq} className='font-slightly-small text-capitalize form-control-theme'>
                                    <option value={''}>All</option>
                                    <option value={"theory"}>Theory</option>
                                    <option value="obj">Objective</option>
                                    <option value="fill in gap">Fill in the gap</option>
                                </select>
                                </label>
                            </div>

                        </Mallet>
                        {preloading ? (<ul>
                                <BlankLoader num={20} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                            <div className='disp-flex align-center w100'>
                                {spinLoading && <Spinner />}
                            </div>
                            {allEQData.length === 0 && (
                                <Table>
                                    <Tr btn={false} header={true} sn={true} text={[{text:"S/N"},  {text:"Question"}]} />
                                    <Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>
                                </Table>
                            )}
                            {insertObjTable()}
                            {insertTheoryTable()}
                            {insertFillGapTable()}
                            </>
                            )
                        }
                    </Section>
                    <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                        { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                            {menuDropLoading && <Loader />}
                            <form onSubmit={e => handleSubmitUpdateExamQuestion(e)}>
                                <div className='row'>
                                    
                                    {menuDropData.quest_pic_value ? (
                                        <div className="fg profile-cont">
                                            <img style={{borderRadius:'5px'}} src={menuDropData.quest_pic_img} className="" alt="Question" /><br />
                                            <div className='disp-flex wrap jc-stretch'>
                                                <div className='padd-4px' style={{marginTop:'2px'}}>
                                                    <input type="checkbox" name="is_pic_bottom" disabled={menuDropData.is_pic_multi_question} value={!toBoolean(menuDropData.is_pic_bottom)} onChange={e => handleEditEQChange(e)} checked={menuDropData.is_pic_bottom} /> <label className='font-very-small app-text-color'>Insert at bottom?*</label>
                                                </div>
                                                <div className='padd-4px' style={{marginTop:'2px'}}>
                                                    <input type="checkbox" name="is_pic_multi_question" disabled={menuDropData.is_pic_bottom} value={!toBoolean(menuDropData.is_pic_multi_question)} onChange={e => handleEditEQChange(e)} checked={menuDropData.is_pic_multi_question} /> <label className='font-very-small app-text-color'>For multiple questions?*</label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ( <>{menuDropData.view_quest_pic && (
                                        <div className="fg profile-cont">
                                            <img style={{borderRadius:'5px'}} src={`${BACKEND_URL}${menuDropData.view_quest_pic}`} className="" alt="Question" /><br />
                                            <div className='disp-flex wrap jc-stretch'>
                                                <div className='padd-4px' style={{marginTop:'2px'}}>
                                                    <input type="checkbox" name="is_pic_bottom" disabled={menuDropData.is_pic_multi_question} value={!toBoolean(menuDropData.is_pic_bottom)} onChange={e => handleEditEQChange(e)} checked={menuDropData.is_pic_bottom} /> <label className='font-very-small app-text-color'>Insert at bottom?*</label>
                                                </div>
                                            </div>
                                        </div>)}</> )}
                                    <div className="fg">
                                    {menuDropData.quest_pic_value ? (
                                       <>
                                        <button type="button" className='btn bg-red br app-bg-text-color hover-opacity padd-5px font-small' onClick={() => setMenuDropData({...menuDropData, quest_pic:null, quest_pic_value:"", quest_pic_img:''})}>Cancel</button><br />
                                        <span className='font-very-small app-text-color'>{menuDropData.quest_pic.name}</span>
                                       </>
                                    ) : (
                                        <>
                                            <label className='font-super-small'>Choose Question Image</label>
                                            <input type="file" name="quest_pic" onChange={e => handleEditImageEQChange(e)} value={menuDropData.quest_pic_value} />
                                        </>
                                    )}
                                    </div>
                                    <div className="fg-all">
                                        <label className='font-super-small'>Enter Exam Question</label>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={menuDropData.exam_question}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setMenuDropData({...menuDropData, exam_question:data});
                                            } }
                                            
                                        />
                                        {menuDropDataError.exam_question && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.exam_question}</span>}
                                    </div>
                                    <div className="fg">
                                        <label className='font-super-small'>Question Type</label>
                                        <select name="mcq" className='font-very-small form-control disabled' disabled defaultValue={menuDropData.mcq}>
                                            <option value="">Select Question Type</option>
                                            <option value='obj'>Objective</option>
                                            <option value='theory'>Theory</option>
                                            <option value='fill in gap'>Fill in the gap</option>
                                        </select>
                                        {menuDropDataError.mcq && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.exam_type}</span>}
                                    </div>
                                    {menuDropData.mcq === "obj" && (
                                        <>
                                            <div className="fg">
                                                <label className='font-super-small'>OBJ A</label>
                                                <textarea name="obj_a" onChange={e => handleEditEQChange(e)} value={menuDropData.obj_a} className='font-very-small form-control'>{menuDropData.obj_a}</textarea>
                                                {menuDropDataError.obj_a && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.obj_a}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className='font-super-small'>OBJ B</label>
                                                <textarea name="obj_b" onChange={e => handleEditEQChange(e)} value={menuDropData.obj_b} className='font-very-small form-control'>{menuDropData.obj_b}</textarea>
                                                {menuDropDataError.obj_b && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.obj_b}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className='font-super-small'> OBJ C*</label>
                                                <textarea name="obj_c" onChange={e => handleEditEQChange(e)} value={menuDropData.obj_c} className='font-very-small form-control'>{menuDropData.obj_c}</textarea>
                                                {menuDropDataError.obj_c && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.obj_c}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className='font-super-small'> OBJ D*</label>
                                                <textarea name="obj_d" onChange={e => handleEditEQChange(e)}  value={menuDropData.obj_d} className='font-very-small form-control'>{menuDropData.obj_d}</textarea>
                                                {menuDropDataError.obj_d && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.obj_d}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className='font-super-small'>OBJ E*</label>
                                                <textarea name="obj_e" onChange={e => handleEditEQChange(e)} value={menuDropData.obj_e} className='font-very-small form-control'>{menuDropData.obj_e}</textarea>
                                                {menuDropDataError.obj_e && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.obj_e}</span>}
                                            </div>
                                            <div className='fg'>
                                                <label className='font-super-small'>Question Answer</label>
                                                <select name="exam_question_answer"  onChange={e => handleEditEQChange(e)} className='font-very-small form-control' required value={menuDropData.exam_question_answer}>
                                                    <option value="">Choose Question Answer</option>
                                                    <option value={1}>A</option>
                                                    <option value={2}>B</option>
                                                    <option value={3}>C</option>
                                                    <option value={4}>D</option>
                                                    <option value={5}>E</option>
                                                </select>
                                                {menuDropDataError.exam_question_answer && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.exam_question_answer}</span>}
                                            </div>
                                        </>
                                    )}
                                    <div className='fg'>
                                        <label className="font-super-small">Mark </label><input required name="mark" 
                                        type="number" onChange={e => handleEditEQChange(e)} className="font-very-small app-theme form-control-theme" placeholder="Enter Mark" value={menuDropData.mark} />
                                        {menuDropDataError.mark && <span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{menuDropDataError.mark}</span>}
                                    </div>
                                    {menuDropData.mcq === "theory" && (
                                    <>
                                        <div className='fg-all'>
                                            <input type="checkbox" name="is_compulsory" checked={menuDropData.is_compulsory} value={!toBoolean(menuDropData.is_compulsory)} onChange={e => handleEditEQChange(e)} /><label className='font-very-small app-text-color'>Make question compulsory?*</label>
                                        </div>
                                    </>
                                    )}
                                    {menuDropData.mcq === "fill in gap" && (
                                        <>
                                            <div className='fg-all'>
                                                <input type="checkbox" name="is_compulsory" checked={menuDropData.is_compulsory} value={!toBoolean(menuDropData.is_compulsory)} onChange={e => handleEditEQChange(e)} /><label className='font-very-small app-text-color'>Make question compulsory?*</label>
                                            </div>
                                        </>
                                    )}
                                    <div className="fg-all btn-g align-center">
                                        <button type="submit" disabled={menuDropLoading} className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                            Update <i className="fas fa-paper-plane"></i> </button>
                                    </div>
                                </div>
                            </form>
                        </MenuDrop>
                    }
                </AnimatePresence>
                        
                </GridContent>
            )
        } else {
            return(<></>)
        }
    }
    console.log(malletState)
    return(
        <>
            <HeadComponent title='Set Exam Question' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                {hoverLoading && <Loader />}
                <Section>
                    <Mallet>
                        <div className="flex-50-line"><label className='font-super-small'>Current Session/Term: <br />
                            <select name="session" disabled onChange={e => handleMalletChange(e)} value={malletState.session} className='font-slightly-small text-capitalize form-control-theme'>
                                <option value={malletState.session}>{malletState.session_num} {malletState.term_name} term</option>
                            </select>
                            </label>
                        </div>
                        <div className="flex-50-line"><label className='font-super-small'>Select Class: <br />
                            <select name="classes" onChange={e => handleMalletChange(e)} value={malletState.classes} className='font-slightly-small text-capitalize form-control-theme'>
                                <option value={''}>Select Class</option>
                                {insertClassesOption}
                            </select>
                            </label>
                        </div>
                        <div className="flex-100"><label className='font-super-small'>Select Subject: <br />
                            <select name="subject" onChange={e => handleMalletChange(e)} value={malletState.subject} className='font-slightly-small text-capitalize form-control-theme'>
                                <option value={''}>Select Subject</option>
                                {insertSubjectMalletOption()}
                            </select>
                            </label>
                        </div>
                        
                    </Mallet>
                </Section>
                <Grid layout='lauto'>
                    {!recordState.is_staff_uploaded && <>{!recordState.is_uploaded && insertCreateExamQuestionGridContent()}</> }
                    <GridContent>
                        {recordState.error && (
                            <div className='disp-block align-center w-100'>
                                <button type="button" onClick={() => handleClickStartEQ()} className='mybtn bg-green hover-opacity br app-bg-text-color font-very-small'>Begin Subject Exam Question</button>
                            </div>
                        )}
                        {recordState.server_error && (
                            <div className='disp-block align-center w-100'>
                                <button type="button" className='btn bg-red br app-bg-text-color font-very-small' onClick={() => setReload(true)}>Reload... Something went wrong</button>
                            </div>
                        )}
                    </GridContent>
                    {insertExamQuestionGridContentManager()}
                </Grid>
            </ComponentWrapper>
        </>
    )

}

export default StaffSchoolSetExamQuestion;