import React from "react";
import { Wrapper } from "./style";

const InlineBtnWrapper = ({children, classname="jc-c"}) => {
    return(
        <Wrapper className={classname}>
            { children }
        </Wrapper>
    )
}

export default InlineBtnWrapper;