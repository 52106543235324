import React from 'react';
import parse from 'html-react-parser';

// import  Header  from "components/app/school/embeddedApps/web/component/header";
import {Container, HomeContent} from "components/app/school/embeddedApps/web/component/style";

const PageByPage = ({content_type1="", preface1="", text1="", img_src1="", img_alt1="", arr1=[], is_loading=false, preface_underbelly='', preface_underbelly2='', content_type2="", preface2="", text2="", img_src2="", img_alt2="", arr2=[], bg_color=''}) => {
    return(
        <>
            <Container>
                <HomeContent style={{background:bg_color}}>
                    {content_type1 === "img" ? (
                        <div className="flex-50">
                            <div className="align-center">
                                <img src={img_src1} alt={img_alt1} className="img-header" />
                            </div>
                        </div>
                    ) : (
                        <div className="flex-50">
                            {preface1 && <span className="disp-block font-super-big fw-700 app-text-color">{preface1}</span>}
                            {preface_underbelly && <span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px'}}><b>({preface_underbelly})</b></span>}
                            <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                                <span className="disp-block app-text-color fw-500 font-small" style={{letterSpacing:'.6px'}}>
                                    {text1 && parse(text1)}
                                </span>
                            </div>
                            {arr1.length > 0 && (
                                <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                    <ul>
                                        {arr1.map((text, i) => (
                                            <li key={i} className="font-slightly-small app-text-color fw-500">{text}</li>
                                        ))}
                                    </ul>
                                </HomeContent>
                            )}
                        </div> 
                    )}
                    {content_type2 === "img" ? (
                        <div className="flex-50">
                            <div className="align-center">
                                <img src={img_src2} alt={img_alt2} className="img-header" />
                            </div>
                        </div>
                    ) : (
                        <div className="flex-50">
                            {preface2 && <span className="disp-block font-super-big fw-700 app-text-color">{preface2}</span>}
                            {preface_underbelly2 && <span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px'}}><b>{preface_underbelly2}</b></span>}
                            <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                                <span className="disp-block app-text-color fw-500 font-small" style={{letterSpacing:'.6px'}}>
                                    {text2 && parse(text2)}
                                </span>
                            </div>
                            {arr2.length > 0 && (
                                <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                    <ul>
                                        {arr2.map((text, i) => (
                                            <li key={i} className="font-slightly-small app-text-color fw-500">{text}</li>
                                        ))}
                                    </ul>
                                </HomeContent>
                            )}
                        </div> 
                    )}
                    {/* <div className="flex-50">
                        <span className="disp-block font-super-big fw-700 app-text-color">Welcome To,</span>
                        <span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px'}}>{state.name} <b>({state.title.toUpperCase()})</b> School</span>
                        <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                            <span className="disp-block app-text-color fw-500 font-small" style={{color:'#666'}}>
                                We are a aiaa abab bab aabpfaf papfaufafafafafafafafdaabl a balbs a bdaas a abasd a aslasasblafbalbflfabfakfbhkabfalkfbalfbaa
                                afaifg afligalfaalfgga sasgfsfgsyfs yada f;asodfhspfs789as asiuadfuh;klsahp98 sh9 g9pash8ahgghpoasghpasgs9sg sgp9shg9psha9pahgas
                            </span>
                        </div>
                    </div> */}
                </HomeContent>
            </Container>
        </>
    )
}

export default PageByPage;