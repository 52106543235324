import styled from "styled-components";

export const OuterWrapper = styled.div`
    position: fixed;
    right: 5px;
    bottom: 4px;
    z-index: 1;
`;

export const Wrapper = styled.div`
    display: block;
    position: relative;

    .br-circle{
        border-radius: 50%;
    }
    .br-5px{
        border-radius: 5px;
    }
    .bg-red{
        background: rgb(245, 16, 32);
    }
    .wrap{
        background: rgba(235,255,255,0.9);
        padding: 3px;
        
        i{
            display: block;
            padding: 5px 7px;
            color: #eee;
        }
    }
`;

export const InnerWrapper = styled.div`
    position: fixed;
    padding: 4px;
    bottom: -20px;
    right: 38px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    animation: animBtns 1s ease;
    --webkit-animation: animBtns 1s ease;
    @keyframes animBtns{
        from{
            bottom: -50px;
        }
        to{
            bottom: -20px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    transition: .3s ease;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        background: var(--appBgColor);

        span, i{
            color: #ddd;
        }
    }

    .cont{
        padding-left: 4px;
    }
`;