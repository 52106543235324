import React, {useState, useEffect, useMemo} from "react";

import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from "components/app/school/SmallerComponents/mallet";
import ToggleButton from "components/app/school/SmallerComponents/toggleButton";
import Loader from "components/app/school/SmallerComponents/loader";

import { HeadComponent } from "components/head";
import BreadCrumb from "components/breadCrumb";

import { validateImg } from 'components/app/school/actions/validation';
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";
import { ComponentWrapper } from "../wrapper";
import { appServices } from "api/schoolApp/services";
import { BACKEND_URL } from "actions/url";

const Settings = ({school={id:0}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [schoolState, setSchoolState] = useState({id:0});
    const [file, setFile] = useState({file:null, value:'', img:''});

    const [reload, setReload] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSchoolChange = (e, n="", v="") => {
        const name = n ? n : e.target.name
        const value = n ? v : e.target.value
        setSchoolState({...schoolState, [name]:value});
    }
    const handleFileChange = (e) => {
        if(!e.target.value){
            setFile({file:null, value:''});
            return;
        }
        const f = e.target.files[0];
        const validate = validateImg(f, 100000);
        if(validate.success){
            setFile({...file, file:f, value:e.target.value});
            return;
        }
        setFile({...file, file:null, value:''});
    }

    const onSubmitLogo = e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('icon', file.file, file.file.name)
        appServices.updateSchoolPic(schoolState.id, formData).then(res => {
            setSchoolState((prev) => {return {...prev, ...res.data}});
            setLoading(false);
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
            setLoading(false);
        })
    }

    const onSubmitSchool = e => {
        e.preventDefault();
        setError(initialErrorState);
        setLoading(true);
        const formData = {email:schoolState.email};
        appServices.updateSchoolM(schoolState.id, formData).then(res => {
            setLoading(false);
            setSchoolState((prev) => {return {...prev, ...res.data}});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
            setLoading(false);
        })
    }
    const onSubmitEnroll = (name="", value="", text="") => {
        setWarning(initialSuccessState);
        setError(initialErrorState);
        setTimeout(() => {
            setWarning({id:schoolState.id, title:'Are you sure?', text:text, click_text:'Yes', handleClick:() => {
                setLoading(true);
                setWarning(initialSuccessState);
                const formData = {[name]:value};
                appServices.updateSchoolM(schoolState.id, formData).then(res => {
                    setLoading(false);
                    setSchoolState((prev) => {return {...prev, ...res.data}});
                }).catch(e => {
                    setLoading(false);
                    setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Promulgation Error'});
                })
            }});
        })   
    }

    useEffect(() => {
        if(reload && school.id){
            setSchoolState({...school});
            setReload(false);
        }
    }, [reload, school])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })


    return(
        <>
            <HeadComponent title="Settings" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout="lauto">
                    <GridContent header_text="Setting" header_icon="fas fa-cog">
                        {loading && <Loader />}
                        <Section header="School Profile">
                            <div className="disp-block align-center">
                                {schoolState.icon ? (
                                    <div className="disp-block">
                                        <span className="font-slightly-small disp-block app-text-color">School Logo</span>
                                        <img className="br-circle large-icon" src={`${BACKEND_URL}${schoolState.icon}`} alt="school icon" />
                                    </div>   
                                ) : (
                                    <form onSubmit={e => onSubmitLogo(e)}>
                                        <label className='font-super-small'>Upload School Logo</label>
                                        <input type="file" className="font-very-small" name="logo" onChange={e => handleFileChange(e)} />
                                        <div className="disp-block padd-4px">
                                            <button className="btn app-bg-color app-bg-text-color br font-slightly-small hover-opacity">Upload</button>
                                        </div>
                                    </form>
                                )}
                                
                            </div>
                            <form onSubmit={e => onSubmitSchool(e)}>
                                <Mallet>
                                    <div className="flex-100"><label className='font-super-small'>School Email Address: 
                                        <input type="email" required placeholder="Enter School Email Address" name="email" value={schoolState.email} onChange={e => handleSchoolChange(e)} className='form-control-theme font-slightly-small' /></label>
                                    </div>
                                    <div className="flex-100 align-center">
                                        <button className="btn hover-opacity font-slightly-small bg-blue br app-bg-text-color">Save</button>
                                    </div>
                                </Mallet>
                            </form>
                        </Section>
                        <Section header="Enrollment">
                            <Mallet>
                            <div className="disp-flex wrap jc-stretch">
                                <div className="disp-block mr-t-4px">
                                    <span className="font-slightly-small app-text-color fw-600">Begin student enrollment and tuition payment system </span>
                                </div>
                                <div>
                                    <ToggleButton name="is_start_student_enroll" checked={schoolState.is_start_student_enroll} onChange={() => onSubmitEnroll('is_start_student_enroll', !schoolState.is_start_student_enroll, schoolState.is_start_student_enroll ? 'Student Enrollment Will Be Deactivated' : 'Student Enrollment Will Be Activated')} />
                                </div>
                            </div>
                            <div className="disp-flex wrap jc-stretch">
                                <div className="disp-block mr-t-4px">
                                    <span className="font-slightly-small app-text-color fw-600">Begin staff enrollment system </span>
                                </div>
                                <div>
                                    <ToggleButton name="is_start_staff_enroll" checked={schoolState.is_start_staff_enroll} onChange={() => onSubmitEnroll('is_start_staff_enroll', !schoolState.is_start_staff_enroll, schoolState.is_start_staff_enroll ? 'Staff Enrollment Will Be Deactivated' : 'Staff Enrollment Will Be Activated')} />
                                </div>
                            </div>
                            </Mallet>
                        </Section>
                    </GridContent>
                </Grid>    
            </ComponentWrapper>
        </>
    )
}

export default Settings;