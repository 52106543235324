import styled from "styled-components";
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
    .bottom{
        bottom: 0;
    }
    .flex-50{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .flex-33{
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .align-items-center{
        text-align: center;
    }
    .align-items-left{
        text-align: left;
    }
    .align-items-right{
        text-align: right;
    }
`;

export const InnerWrapper = styled(motion.div)`
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;