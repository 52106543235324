import React from "react";

import { Wrapper, Content } from './style';

const PageDownBtn = ({number=0, onClick}) => {
    return(
        <Wrapper>
            <Content>
                <i onClick={onClick} className="fas fa-angle-down font-med hover-opacity app-bg-color-text app-theme"></i>
                {number > 0 && (
                    <div className="num-cont">
                        <span className="font-super-small num">{number}</span>
                    </div>
                )}
                
            </Content>
        </Wrapper>
    )
}

export default PageDownBtn;