import React from 'react';
import parse from 'html-react-parser';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { colorThemePallete } from 'components/app/school/embeddedApps/web/action/colorManager';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

import {Container, HomeContent} from "components/app/school/embeddedApps/web/component/style";

const OnePageEditor = ({preface="", bg_theme="", text="", img_src="", img_alt="", is_add_image=false, is_delete_image=false, is_preface_change=false, is_preface_delete=false, is_text_change=false, is_text_delete=false, preface_value='',  img_value="", text_value="", text_ph='Write something here....', onChangeImg, onToggleImg, onDeleteImg, onChangePreface, onTogglePreface, onSubmitPreface, onDeletePreface, onChangeEditorText, onSubmitText, onToggleText, onDeleteText,  arr=[], is_loading=false, preface_underbelly=''}) => {
    const color = colorThemePallete(bg_theme);
    return(
        <>
        <Container>
                <HomeContent className={bg_theme}> 
                    <div className="flex-100">
                        {is_preface_change ? (
                            <div className='disp-block'>
                                <form onSubmit={onSubmitPreface}>
                                <input type="text" placeholder='Enter Title' className='form-control font-super-big' name="preface_value" onChange={onChangePreface} value={preface_value} />
                                <div className='disp-flex wrap jc-stretch' >
                                    <div className='disp-block padd-4px'>
                                        <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                    </div>
                                    <div className='disp-block padd-4px'>
                                        <button type="button" className='btn app-text-color' title="Cancel" onClick={onTogglePreface} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                    </div>
                                    {is_preface_delete && (
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeletePreface} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                        </div>
                                    )}
                                </div>
                                </form>
                            </div>
                        ) : (
                            <>
                            {preface ? (<><span className={`disp-block font-super-big fw-700 ${color.app_text_color}`}>{preface}</span> <button type="button" className='btn app-text-color font-super-small hover-br br' style={{border:'1px solid var(--appTextColor)'}} onClick={onTogglePreface}><i className='fas fa-pen'></i> Change</button> </>) : (<><span className="disp-block font-super-big fw-700 hover-opacity" onClick={onTogglePreface} style={{color:'#888'}}>Enter Title Here.... e.g Welcome To|</span></>)}
                            </>
                        )}
                        {preface_underbelly && <span className={`disp-block font-bigger ${color.app_text_color} fw-600`} style={{paddingLeft:'10px'}}><b>({preface_underbelly})</b></span>}
                        {/* {img_src ? (
                            <>
                                <div className="align-center">
                                    <img src={img_src} alt={img_alt} className="img-header" />
                                </div>
                                {!is_add_image && (
                                    <div className='disp-block align-center'>
                                        <button type="button" className='btn hover-br br app-text-color font-super-small' onClick={onToggleImg} style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className='disp-block'>
                                    <div className='align-all-center' style={{border:'1px solid #777', marginTop:'10px', marginBottom:'10px'}}>
                                        <span className='font-small fw-600' style={{color:'#777', cursor:'default'}} onClick={onToggleImg}>Click here to add an image|</span>
                                    </div>
                                </div>
                            
                            </>
                        )}
                        {is_add_image && (
                            <div className='disp-block'>
                                <form>
                                <input type="file" required className='form-control' name="img" onChange={onChangeImg} value={img_value} />
                                <div className='disp-flex wrap jc-stretch' >
                                    <div className='disp-block padd-4px'>
                                        <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity'>Upload</button>
                                    </div>
                                    <div className='disp-block padd-4px'>
                                        <button type="button" className='btn app-text-color' onClick={onToggleImg} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                    </div>
                                    {is_delete_image && (
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-bg-text-color hover-opacity' onClick={onDeleteImg} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                        </div>
                                    )}
                                </div>
                                </form>
                            </div>
                        )} */}
                        
                    

                    
                    <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                        {is_text_change ? (
                            <div className='disp-block'>
                            <form onSubmit={onSubmitText}>
                                <div className={`disp-block ${color.app_theme}`} style={{width:'calc(100% - 10px)'}}>
                        
                                <CKEditor editor={ClassicEditor}
                                    data={text_value} onChange={onChangeEditorText} height='200px' />
                            
                                </div>
                                <div className='disp-flex wrap jc-stretch align-center' >
                                    <div className='disp-block padd-4px'>
                                        <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                    </div>
                                    <div className='disp-block padd-4px'>
                                        <button type="button" className='btn app-text-color' title="Cancel" onClick={onToggleText} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                    </div>
                                    {is_text_delete && (
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeleteText} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                        ) : (
                            <>
                            {text ? (
                                <div className='disp-block'>
                                    <span className={`disp-block fw-500 font-small ${color.app_text_color}`}  style={{letterSpacing:'.6px'}}>
                                        {parse(text)}
                                    </span>
                                    <button type="button" className='btn app-text-color font-super-small hover-br br' onClick={onToggleText} style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button> 
                                </div>
                            ) : (
                                <>
                                <div className='align-all-center' onClick={onToggleText} style={{width:'100%', height:'200px', border:'1px solid #888'}}>
                                    <span className="disp-block fw-500 hover-opacity" style={{letterSpacing:'.6px', color:'#777'}}>
                                        {text_ph} !
                                    </span>
                                </div>
                                </>
                            )}
                            </>
                        )}
                        
                            
                    </div>
                    {arr.length > 0 && (
                        <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                            <ul>
                                {arr.map((text, i) => (
                                    <li key={i} className="font-slightly-small app-text-color fw-500">{text}</li>
                                ))}
                            </ul>
                        </HomeContent>
                    )}
                    </div>
                </HomeContent>
            </Container>
        </>
    )
}

export default OnePageEditor;