import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Input from 'components/app/school/SmallerComponents/input';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from "components/app/school/SmallerComponents/spinner";

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { convertNumber, seperatePoint } from 'components/app/school/actions/money';

import {default as success_icon} from 'images/success_icon.svg';

const SetStaffSalary = ({currency, user_type, school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialFormState = useMemo(() => ({cert_type:'', school_branch:0, staff_type:0, salary:'', salary_point:'00'}), [])

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    // const [warning, setWarning] = useState(initialSuccessState);

    const [formInsert, setFormInsert] = useState(initialFormState)
    
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allSchoolStaffTypeData, setAllSchoolStaffTypeData] = useState([]);
    const [allData, setAllData] = useState([]);

    const [dataTable, setDataTable] = useState({school_branch:0, period: 1});
    const [menuDropData, setMenuDropData] = useState({id:''});
    const [menuDrop, setMenuDrop] = useState(false)

    const [accordion, setAccordion] = useState(0);
    const [dataTableChanged, setDataTableChanged] = useState(false);
    const [loading, setLoading] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [preloading, setPreloading] = useState("");
    const [reloadData, setReloadData] = useState(false);
    const [submitted, setSubmitted] = useState(null);

    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
        setFormInsert(initialFormState);
    }
    const handleFormChange = (e) => {
        setFormInsert({...formInsert, [e.target.name]:e.target.value});
    }
    const handleEditFormChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value});
    }
    const handleDataTableChange = (e) => {
        setDataTableChanged(false);
        setDataTable({...dataTable, [e.target.name]:e.target.value});
        setDataTableChanged(true);
    }
    const handleEditClick = (data) => {
        
        setMenuDrop(true);
        setMenuDropData(data);
        
    }
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmitted("none");
        }, 1000 * 15)
        return(
            <div className="nav-dd-anim" style={{display:submitted}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }


    const fetchAllSchoolBranch = (sid) => {
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData([...res.data]);
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"School Branch Data Error"});
        })
    }
    const fetchAllSchoolStaffType = (pd) => {
        appServices.getAllStaffSchoolTypeData(pd).then(res => {
            setAllSchoolStaffTypeData([...res.data]);
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"Staff Type Data Error"});
        })
    }

    const fetchAllSalary = (sid=0, sbid=0, preload=true) => {
        if(preload){
            setPreloading(true);
            appServices.getAllStaffSchoolSalary(sid, sbid).then(res => {
                setPreloading(false);
                setAllData([...res.data]);
            }).catch(() => {
                setError({data:["Internal Server Error"], title:"Salary Data Error"});
                setAllData([]);
                setPreloading(false);
            })
        }
        else{
            setTableLoading(true);
            appServices.getAllStaffSchoolSalary(sid, sbid).then(res => {
                setAllData([...res.data]);
                setTableLoading(false);
            }).catch(() => {
                setError({data:["Internal Server Error"], title:"Salary Data Error"});
                setTableLoading(false);
                setAllData([]);
            })
        }
    }
    const handleFormInsert = (e) => {
        e.preventDefault();
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const sal_point = formInsert.salary_point ? formInsert.salary_point : '00'
        const sal = formInsert.salary.replace(/,|\./g, '') + "." + sal_point;
        const data = {
            salary: sal,
            currency:currency.id,
            school: school_id,
            school_branch: formInsert.school_branch,
            staff_type: formInsert.staff_type,
            cert_type: formInsert.cert_type
        }
        appServices.insertStaffSchoolSalary(data).then(res => {
            setSuccess({text:"Data Saved Successfully", title:"Success"});
            setLoading("");
            setFormInsert(initialFormState);
            fetchAllSalary(school_id, dataTable.school_branch, false)
        }).catch(e => {
            console.log(e.response)
            setError({data:[...e.response.data.non_field_errors], title:"Salary Error"})
            setLoading("");
        })
    } 
    const handleFormUpdate = (e) => {
        e.preventDefault();  
        setSubmitted("");
        setLoading("menuDrop");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const sal_point = menuDropData.salary_point ? menuDropData.salary_point : '00'
        const sal = menuDropData.salary.replace(/,|\./g, '') + "." + sal_point;
        const data = {
            currency: menuDropData.curr_id,
            school: school_id,
            school_branch: menuDropData.school_branch,
            staff_type: menuDropData.staff_type,
            cert_type: menuDropData.cert_type,
            salary: sal,
        }
        appServices.updateStaffSchoolSalary(menuDropData.id, data).then(res => {
            setError({...initialErrorState});
            setMenuDrop(false)
            setMenuDropData({...menuDropData, type_update:'salary'})
            setSuccess({text: "Data Updated Successfully", title:"success"})
            setSubmitted(menuDropData.id);
            setLoading('');
            fetchAllSalary(school_id, dataTable.school_branch, false)
        }).catch(e => {
            setError({data:[e.response.data.detail], title:"Salary Update Error"})
            setLoading("");
            console.log(e.response)
        })
    }

    const handleDeleteSalary = (id) => {
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        appServices.deleteStaffSchoolSalary(id).then(res => {
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
            id:id })
            setLoading("");
            setTimeout(() => {
                fetchAllSalary(school_id, dataTable.school_branch, false)
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:id })
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
            }, 500);
        }).catch(e => {
            console.log(e.response);
            setLoading("");
            setError({data:[e.response.data.detail], title:"Delete Salary Error"})
        })
    }
    console.log(reloadData);
    useEffect(() => {
        fetchAllSchoolBranch(school_id);
        fetchAllSchoolStaffType(4);
        fetchAllSalary(school_id);
    }, [school_id]);
    useEffect(() => {
        setReloadData(false);
        const timer = setInterval(() => {
            setReloadData(true);
        }, 1000 * 60 * 2.5);
        return () => {
            clearInterval(timer)
        }
    },[reloadData, school_id])
    useEffect(() => {
        if(reloadData){
            fetchAllSalary(school_id, dataTable.school_branch, false)
            fetchAllSchoolStaffType(4);
            fetchAllSchoolBranch(school_id);
        }
    }, [school_id, reloadData, dataTable])
    useEffect(() => {
        if(allSchoolBranchData.length > 0){
            setDataTable({school_branch:allSchoolBranchData[0].id, period: 1});
            fetchAllSalary(school_id, allSchoolBranchData[0].id);
        }
    }, [allSchoolBranchData, school_id])
    useEffect(() => {
        if(dataTableChanged){
            fetchAllSalary(school_id, dataTable.school_branch, false)
        }
    }, [dataTable, dataTableChanged, school_id])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSchoolBranchOption = allSchoolBranchData.map((item, i) => {
        return(
            <option value={item.id} key={i}>{`${item.name}`}</option>
        )
    })
    const insertSchoolStaffTypeOption = allSchoolStaffTypeData.map((item, i) => {
        return(
            <option value={item.id} key={i}>{`${item.name}`}</option>
        )
    })
    const insertSalaryData = allData.map((item, i) => {
        i++;
        const sal = item.salary * dataTable.period;
        const edit_data = {
            id: item.id,
            school_branch: item.school_branch,
            cert_type: item.cert_type,
            salary: seperatePoint(item.salary),
            salary_point: seperatePoint(item.salary, true),
            staff_type: item.staff_type,
            curr_symbol: item.curr_symbol,
            curr_id: item.currency,
            is_deletable: item.is_deletable,
            type: 'salary_form'
        }
        return(
            <Fragment key={i}>
                <Tr sn={true} handleEditClick={() => handleEditClick(edit_data)} handleUpgradeClick={() => handleEditClick(edit_data)} handleDeleteClick={() => handleDeleteSalary(item.id)}
                    edit_btn={!item.is_deletable} upgrade_btn={item.is_deletable} dlt_btn={!item.is_deletable} animation={(item.id === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                    <div className="c-data sn">
                        {(() => {if(item.id===submitted) return updateSuccessIcon() })()}
                        <span className="font-very-small">{i}</span>
                    </div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.cert_type}</span></div>
                    <div className="c-data"><span className="font-slightly-small text-capitalize"><span className='font-super-small'>{item.curr_symbol}</span>{convertNumber(sal)}</span></div>
                    <div className="c-data show"><span className="font-very-small text-capitalize">{item.staff_type_name}</span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.school_branch_name}</span></div>
                    <TdWrapper header_text={`${item.cert_type}`} header_num={i}>
                        <Td heading="Certificate Type" text_class='text-capitalize' text={`${item.cert_type}`} />
                        <Td heading="Salary" text={`${item.curr_symbol}${convertNumber(sal)}`} />
                        <Td heading="Staff Type" text_class='text-capitalize' text={item.staff_type_name} />
                        <Td heading="School Branch" text_class='text-capitalize' text={item.school_branch_name} />
                        <Td heading="Period" text={dataTable.period > 1 ? 'Annually' : 'Monthly'} />
                        <Td heading="Created" text_class='text-capitalize' text={item.created} />
                    </TdWrapper>
                </Tr>
            </Fragment>
        )
    })

    return(
        <>
            <HeadComponent title='Salary Setting' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid>
                    <GridContent header_text={`Set Staff Monthly Salary`} header_icon="fas fa-tasks">
                    {loading==="left" && (<Loader />)}
                        <>
                        
                            <Accordion handleSubmit={e => handleFormInsert(e)} index={1} text="Add Salary" handleClick={() => handleAccordionClick(1) } currentAccordion={accordion} >
                                {accordion === 1 && ( <>
                                    <AccordionFormData required={true} handleChange={(e) => handleFormChange(e)} value={formInsert.school_branch} tag="select" name="school_branch" classname="form-control-theme font-slightly-small text-capitalize">
                                        <option value={""}>Select School Branch</option>
                                        {insertSchoolBranchOption}
                                    </AccordionFormData>
                                    <AccordionFormData required={true} handleChange={(e) => handleFormChange(e)} value={formInsert.staff_type} tag="select" name="staff_type" classname="form-control-theme font-slightly-small text-capitalize">
                                        <option value="">Select Staff Type</option>
                                        {insertSchoolStaffTypeOption}
                                    </AccordionFormData>
                                    <AccordionFormData required={true} handleChange={(e) => handleFormChange(e)} value={formInsert.cert_type} tag="input" type="text" name="cert_type" classname="form-control-theme font-slightly-small text-capitalize" placeholder="Enter Cert Type e.g (Graduate, SSCE)" />
                                    <AccordionFormData contentClass='disp-flex' required={true} handleChange={(e) => handleFormChange(e)} value={formInsert.salary} currency={currency.symbol} currency_value={currency.id} point_display={formInsert.salary ? true : false} tag="input" type="money" name="salary"
                                        handlePointChange={(e) => handleFormChange(e)} point_value={formInsert.salary_point} point_name='salary_point' classname="form-control-theme font-slightly-small text-capitalize" placeholder="Monthly Salary..." />
                                    <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                        classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                </>)}
                                
                            </Accordion>
                        </>
                    </GridContent>
                    <GridContent header_text="Manage Salary" header_icon="fas fa-tasks">
                    {preloading ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (
                        <>
                            <Section>
                                <Mallet>
                                    <div className="flex-70"><label className='font-super-small'>Select School Branch: 
                                        <select onChange={e => handleDataTableChange(e)} name="school_branch" value={dataTable.school_branch} className='form-control-theme font-slightly-small  text-capitalize' >
                                            <option value={0}>Select School Branch</option>
                                            {insertSchoolBranchOption}
                                        </select></label>
                                    </div>
                                    <div className="flex-30"><label className='font-super-small'>Select Period: 
                                        <select onChange={e => handleDataTableChange(e)} name="period" value={dataTable.period} className='form-control-theme font-slightly-small  text-capitalize' >
                                            <option value={1}>Monthly</option>
                                            <option value={12}>Annually</option>
                                        </select></label>
                                    </div>
                                </Mallet>
                                {tableLoading && <Spinner />}
                                <Table>
                                <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"S/N"}, {text:"Cert Type"}, {text:'Salary'},  {text:'Staff Type', class:'show'}, {text:'School Branch'},]} />
                                {allData.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    (<>
                                        {insertSalaryData}
                                    </>)                         
                                }
                            </Table>
                            </Section>
                        </>
                        )
                    }
                        <AnimatePresence
                                initial={false}
                                exitBeforeEnter={true}
                                onExitComplete={() => null}
                            >
                            { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                                {loading === "menuDrop" && <Loader />}
                                {menuDropData.type === "salary_form" && (
                                    <form onSubmit={e => handleFormUpdate(e, menuDropData.is_deletable)}>
                                        <div className='row'>
                                            <div className="fg">
                                                <label className="font-super-small">School Branch</label>
                                                <select required disabled={menuDropData.is_deletable} name="school_branch" className="font-very-small form-control-theme text-capitalize" 
                                                placeholder="School Branch" onChange={e => handleEditFormChange(e)} value={menuDropData.school_branch}>
                                                    <option value="">Select School Branch</option>
                                                    {insertSchoolBranchOption}
                                                </select>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Staff Type</label>
                                                <select required disabled={menuDropData.is_deletable} name="staff_type" className="font-very-small form-control-theme text-capitalize" 
                                                placeholder="Staff Type" onChange={e => handleEditFormChange(e)} value={menuDropData.staff_type}>
                                                    <option value="">Select Staff Type</option>
                                                    {insertSchoolStaffTypeOption}
                                                </select>
                                            </div>     
                                            <div className="fg">
                                                <label className="font-super-small">Cert Type </label><input required name="cert_type" onChange={e => handleEditFormChange(e)} 
                                                type="text" className="font-very-small text-capitalize app-theme form-control-theme" placeholder="Certificate Type" value={menuDropData.cert_type} />
                                            </div> 
                                            <div className="fg">
                                                <label className="font-super-small">Salary </label>
                                                <Input required type="money" handleChange={e => handleEditFormChange(e)} name="salary" point_display={menuDropData.salary ? true : false} point_name='salary_point' point_value={menuDropData.salary_point} currency={menuDropData.curr_symbol} 
                                                currency_value={menuDropData.curr_id} handlePointChange={e => handleEditFormChange(e)} value={menuDropData.salary} placeholder='Salary' classname="font-very-small app-theme form-control-theme" />
                                            </div> 
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    {menuDropData.is_deletable ? 'Upgrade' : 'Update'} <i className={!menuDropData.is_deletable ? "fas fa-paper-plane" : "fas fa-level-up-alt"}></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SetStaffSalary;