import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import parse from 'html-react-parser';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Section from 'components/app/school/SmallerComponents/Section';
import PanelDBWrapper from 'components/app/school/SmallerComponents/panelDBWrapper';
import PanelDB, {PanelDBInner} from 'components/app/school/SmallerComponents/panelDB';

// import BlankLoader from "components/blankLoader";
// import Loader from "components/app/school/SmallerComponents/loader";
// import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';
import { convertNumber } from 'components/app/school/actions/money.js';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { BACKEND_URL } from 'actions/url';


const SchoolDashboard = ({school_id, user_id, user={}, currency={}, school={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialState = useMemo(() => ({p:1, size:50, session:'', student_enroll:[], staff_enroll:[], tot_admin:0, tot_student:0, tot_staff:0, id:0}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);
    
    const [state, setState] = useState(initialState);
    const [currentSession, setCurrentSession] = useState({});
    const [schoolVoSpace, setVoSchoolSpace] = useState({id:0});


    const [transaction, setTransaction] = useState(initialPaginationState);
    const [finance, setFinance] = useState([]);
    const [weekDays, setWeekDays] = useState([]);

    const [navigate, setNavigate] = useState('');

    const [reload, setReload] = useState(true);
    const [reload2, setReload2] = useState(true);
    const [preloading2, setPreloading2] = useState(true);

    const nav = useNavigate();

    const fetchDashboard = (sid, init) => {
        setError({data:[], title:''});
        setPreloading2(true);
        appServices.getSchoolDashboard(sid).then(res => {
            setState({...init,  ...res.data});
            setPreloading2(false);
        }).catch(e => {
            setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            setPreloading2(false);
        })
    }
    const fetchAllWeekday = (sid) => {
        appServices.getAllSchoolWeekDayData(sid).then(res => {
            setWeekDays(res.data);
        }).catch(() => {

        })
    }
    const fetchSchoolTransaction = (ssid) => {
        setError({data:[], title:''});
        appServices.getAllPayment(ssid, 1, 3).then(res => {
            setTransaction(res.data);
        }).catch(e => {
            setError({data:["Internal Server Error"], title:"Finance Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    const fetchCurrentSession = (sid) => {
        setError({data:[], title:''});
        appServices.getCurrentSession(sid).then(res => {
            setCurrentSession(res.data);
            setReload2(true);
        }).catch(e => {
            setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    const fetchSchoolFinance = (sid) => {
        setError({data:[], title:''});
        appServices.getAllFinance(sid).then(res => {
            setFinance(res.data);
        }).catch(e => {
            setError({data:["Internal Server Error"], title:"Finance Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    const fetchSchoolVoSpace = (id) => {
        if(id){
            setError({data:[], title:''});
            appServices.getSchoolVoSpace(id).then(res => {
                setVoSchoolSpace(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"VoSpace Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }

    useEffect(() => {
        if(reload && school_id){
            fetchDashboard(school_id, initialState);
            fetchSchoolFinance(school_id);
            fetchCurrentSession(school_id);
            fetchSchoolVoSpace(school_id);
            fetchAllWeekday(school_id);
            setReload(false);
            return;
        }
        if(reload2 && currentSession.id){
            fetchSchoolTransaction(currentSession.id);
            setReload2(false);
            return;
        }
    }, [school_id, currentSession, reload, reload2, initialState]);

    useEffect(() => {
        if(navigate){
            setNavigate('');
            nav(navigate)
        }
    }, [navigate, nav]);

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const currentHour = new Date().getHours();
    const greet = (hr=0, name="") => {
        if(hr > 18){
            return (
                <span className=''>Good night <span className='text-capitalize'>{name}</span>, take a shower and have a good rest!</span>
            );
        }
        if(hr > 15){
            return (
                <span className=''>Good evening <span className='text-capitalize'>{name}</span>, how has your day been?</span>
            );
        }
        if(hr > 11){
            return (
                <span className=''>Good afternoon <span className='text-capitalize'>{name}</span>, how has your day been?</span>
            );
        }
        if(hr > 4){
            return (
                <span className=''>Good morning <span className='text-capitalize'>{name}</span>, hope you have a wonderful day!</span>
            );
        }
        if(hr > 0){
            return (
                <span className=''>Ahh <span className='text-capitalize'>{name}</span>, you should be asleep!</span>
            );
        }
    }
    const runOut = (curr_num=0) => {
        if(curr_num < 1){
            return(
                <div className='disp-block align-center' style={{marginTop:'15px'}}>
                    <span className='font-small disp-block align-center red'><b>ERROR: Your VoSpace Has Ranout, Please Get More NOW!</b></span>
                    <span className='font-small disp-block align-center red'><b>New Students cannot be added</b></span>
                </div>
            )
        }
        if(curr_num < 10){
            return(
                <div className='disp-block align-center' style={{marginTop:'15px'}}>
                    <span className='font-slightly-small red'><b>NOTE:</b> Your VoSpace Is Running Out, view to get more NOW!</span>
                </div>
            )
        }
        if(curr_num < 30){
            return(
                <div className='disp-block align-center' style={{marginTop:'15px'}}>
                    <span className='font-small' style={{color:'rgb(255, 198, 14)'}}><b>WARNING: Your VoSpace Is Running Out, view to get more NOW!</b> </span>
                </div>
            )
        }
    }
    
    const balance = finance.length > 0 ? finance[finance.length - 1] : {id:0, tot_amt:0};
    const notification = {subject: !school.is_setup_subject ? {msg:'<b style="color:red">ATTENTION:</b> Yello, setup subject list now else some technologies might not work, click on the link below to start adding subjects', src:`/${school.title}/subjects`} : null,
        week_days:weekDays.length < 1 ? {msg:'<b style="color:red">ATTENTION:</b> Yello, Setup school week days now else some technologies will not work, click on the link below to setup weekdays', src:`/${school.title}/settings/set/weekdays`} : null, vospace:!school.is_vos ? {msg:'Yello, you are currently not on any <b>VOSPACE</b>, get a voSpace now to make more money, while simultaneously cutting costs for the school, click on the link below to get a vospace',  src:`/${school.title}/student?is_vospace=true`} : null,
        online_payment:!school.is_start_student_payment ? {msg:'Yello,  <b>STUDENT ONLINE PAYMENT</b>, is currently <b style="color:red">inactive</b>, start adding your school fee list to activate it, click on the link below to create list of school fees for student',  src:`/${school.title}/school_fee`} : null,
        student:state.student_enroll.length > 0 ? {msg:`Yello, you have <b>${state.student_enroll.length}</b> new student enrolled and awaiting your response, click the link below to begin processing`, src:`/${school.title}/student/enrolled`} : null, 
        staff:state.staff_enroll.length > 0 ? {msg:`Yello, you have <b>${state.staff_enroll.length}</b> new staff applying for a job and awaiting your response, click the link below to begin processing`, src:`/${school.title}/staff/applied`} : null};

    return(
        <>
            <HeadComponent title='Dashboard' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <div className='disp-block align-right'>
                    <span className='app-text-color font-slightly-small fw-600'>{greet(currentHour, user.first_name)}</span>
                </div>
                {/* <Grid>
                    <GridContent theme='lauto' style={{background:'var(--appBodyColor)'}}> */}
                    {Object.values(notification).filter((it) => it).length > 0 && (
                        <Section header={'Notification'} icon="fas fa-comment-alt">
                            <PanelDBWrapper>
                            {Object.entries(notification).filter((it) => it[1]).map((item, i) => {
                                return (
                                <Fragment key={i}>
                                    <PanelDB  icon="fas fa-comment-alt">
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small fw-500 app-text-color' style={{letterSpacing:'.6px'}}>{parse(item[1].msg)}</span>
                                        </div>
                                        <div className='disp-block align-center'>
                                            <Link to={item[1].src} className='font-very-small fw-600'>View More</Link>
                                        </div>
                                        {/* <div className='disp-block align-center'>
                                            <Note classname='font-very-small'> Begin student enrollment procession</Note>
                                        </div> */}
                                    </PanelDB>
                                </Fragment>
                            )})}
{/*                             
                                <PanelDB  title="Student Enrolled" icon="fas fa-comment-alt" is_view>
                                    <PanelDBInner icon="fas fa-user-circle" text="Admin" is_click_text is_bordered>
                                        <span className='font-med app-text-color fw-700'>400</span>
                                    </PanelDBInner>
                                    <div className='disp-block align-center'>
                                        <Note classname='font-very-small'> Begin student enrollment procession</Note>
                                    </div>
                                </PanelDB>
                                <PanelDB  title="Staff Applied" icon="fas fa-comment-alt" is_view>
                                    <PanelDBInner icon="fas fa-user-circle" text="Admin" is_click_text is_bordered>
                                        <span className='font-med app-text-color fw-700'>400</span>
                                    </PanelDBInner>
                                    <div className='disp-block align-center'>
                                        <Note classname='font-very-small'> Begin staff application procession</Note>
                                    </div>
                                </PanelDB> */}
                            </PanelDBWrapper>
                        </Section>
                    )}
                    <Section header={'Dashboard'} icon="fas fa-chart-pie">
                        <PanelDBWrapper>
                            <PanelDB  title="All Users" icon="fas fa-users">
                                <PanelDBInner icon="fas fa-user-circle" text="Admin" is_click_text is_bordered onClickText={() => setNavigate(`../admin`)}>
                                    <span className='font-med app-text-color fw-700'>{state.tot_admin}</span>
                                </PanelDBInner>
                                <PanelDBInner icon="fas fa-users" text="Students" is_click_text onClickText={() => setNavigate(`../student`)} is_bordered>
                                    <span className='font-med app-text-color fw-700'>{state.tot_student}</span>
                                </PanelDBInner>
                                <PanelDBInner icon="fas fa-user" text="Staff" is_click_text onClickText={() => setNavigate(`../staff`)} is_bordered>
                                    <span className='font-med app-text-color fw-700'>{state.tot_staff}</span>
                                </PanelDBInner>
                                
                                {/* <PanelDBInner icon="fas fa-user" text="Parents">
                                    <span className='font-med app-text-color fw-700'>400</span>
                                </PanelDBInner> */}
                            </PanelDB>
                            {schoolVoSpace.id > 0 && (
                                <PanelDB  title="VoSpace Record" icon="fas fa-hdd" is_view btn_icon="fas fa-eye" btn_title='View' onClickView={() => setNavigate(`../student`)}>
                                    {runOut(schoolVoSpace.vo_space_rem)}
                                    <div className='disp-flex wrap'>
                                        <div className='flex-100 disp-flex jc-stretch'>
                                            <div className='disp-block padd-4px'>
                                                <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-server font-small'></i> VoSpace Remaining:</span>
                                            </div>
                                            <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                                <span className='font-small app-bg-color-text fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_rem}</span>
                                            </div>
                                        </div>
                                        <div className='flex-100 disp-flex jc-stretch'>
                                            <div className='disp-block padd-4px'>
                                                <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-plus font-small'></i> Total Added:</span>
                                            </div>
                                            <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                                <span className='font-small app-text-color fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_added}</span>
                                            </div>
                                        </div>
                                        <div className='flex-100 disp-flex jc-stretch'>
                                            <div className='disp-block padd-4px'>
                                                <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-road font-small'></i> Total Used:</span>
                                            </div>
                                            <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                                <span className='font-small app-text-color fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_used}</span>
                                            </div>
                                        </div>
                                    </div>
                                </PanelDB>
                            )}
                            <PanelDB  title="Transactions" icon="fas fa-coins" is_view onClickView={() => setNavigate(`../finance`)}>
                                <div className='disp-flex wrap jc-sb' style={{marginBottom:'10px'}}>
                                    <div className=''>
                                        <span className='font-big app-text-color fw-600'><span className='green'>+</span>{currency.symbol}{convertNumber(balance.tot_amt)}</span>
                                    </div>
                                    <div>
                                        <span className='font-super-small fw-600 app-text-sec-color'>Balance</span> 
                                    </div>
                                </div>
                                {transaction.results.map((item, i) => {
                                    const full_name = `${item.student_last_name} ${item.student_first_name}`;
                                    const paid_by_full_name = `${item.paid_by_last_name} ${item.paid_by_first_name}`;
                                    return(
                                        <Fragment key={i}>
                                            <PanelDBInner icon={`${BACKEND_URL}${item.student_profile_pic}`} is_img text={full_name.length > 15 ? `${full_name.slice(0, 12)}...` :full_name} desc={`By ${paid_by_full_name.length > 10 ? `${paid_by_full_name.slice(0, 10)}...` : paid_by_full_name} - ${item.paid_by_user_type}`}>
                                                <span className={`font-slightly-small fw-700 ${item.type === "cr" ? 'green' : 'red'}`}>{currency.symbol}{convertNumber(item.tot_amt_paid)}</span><br />
                                                <span className='font-very-super-small disp-block align-center fw-600' style={{color:'#666'}}>{convertDate(item.pay_date, 'yyyy-mm-dd hh:mm:ss')}</span>
                                            </PanelDBInner>
                                        </Fragment>
                                    )
                                })}
                                {/* <PanelDBInner icon="fas fa-user" text="Parents">
                                    <span className='font-med app-text-color fw-700'>400</span>
                                </PanelDBInner> */}
                            </PanelDB>
                            
                        
                            <PanelDB  title="Current Session" icon="fas fa-calendar" is_view btn_icon="fas fa-pen" btn_title='Edit' onClickView={() => setNavigate(`../session`)}>
                                <PanelDBInner icon="fas fa-minus" text={`Session: ${currentSession.session} ${currentSession.term_name} Term`} />
                                <PanelDBInner icon="fas fa-minus" text={`Resumption Date: ${currentSession.res_date}`} />
                                <PanelDBInner icon="fas fa-minus" text={`Vacation Date: ${currentSession.vac_date}`} />
                                <PanelDBInner icon="fas fa-minus" text={`Staff Scores Entry End Date:  ${currentSession.scores_entry_end}`} is_children={false} />
                                <PanelDBInner icon="fas fa-minus" text={`Enrollment Interview Start Date: ${currentSession.interview_start}`} is_children={false} />
                                <PanelDBInner icon="fas fa-minus" text={`Enrollment Interview End Date: ${currentSession.interview_end}`} is_children={false} />
                            </PanelDB>
                            <PanelDB  title="Settings" icon="fas fa-cog" is_view btn_icon="fas fa-pen" btn_title='Edit'>
                                <PanelDBInner icon="fas fa-th" text="Student Enrollment And Tuition System" is_click_text={false}>
                                    <span className={`font-small fw-700 ${school.is_start_student_enroll ? 'green' : 'red'}`}>{school.is_start_student_enroll ? 'Active' : 'Inactive'}</span>
                                </PanelDBInner>
                                <PanelDBInner icon="fas fa-th" text="Student Payment System" is_click_text={false}>
                                    <span className={`font-small fw-700 ${school.is_start_student_payment ? 'green' : 'red'}`}>{school.is_start_student_payment ? 'Active' : 'Inactive'}</span>
                                </PanelDBInner>
                                <PanelDBInner icon="fas fa-th" text="Staff Enrollment System" is_click_text={false}>
                                    <span className={`font-small fw-700 ${school.is_start_staff_enroll ? 'green' : 'red'}`}>{school.is_start_staff_enroll ? 'Active' : 'Inactive'}</span>
                                </PanelDBInner>
                            </PanelDB>
                            <PanelDB  title="Embedded Apps" icon="fas fa-th-large" is_view={true} onClickView={() => setNavigate(`../eapp`)}>
                                <PanelDBInner icon="fas fa-th" text="School Website" is_click_text onClickText={() => setNavigate(`../eapp/web`)}>
                                    <span className={`font-small fw-700 ${school.is_eapp_web_live ? 'green' : 'red'}`}>{school.is_eapp_web_live ? 'Live' : 'Test'}</span>
                                </PanelDBInner>
                                <PanelDBInner icon="fas fa-th" text="Games" is_click_text>
                                    <span className={`font-small fw-600 app-text-sec-color`}>(Coming Soon)</span>
                                </PanelDBInner>
                            </PanelDB>
                            
                            
                            {/* <PanelDB />
                            <PanelDB /> */}
                        </PanelDBWrapper>
                    </Section>
                    {/* </GridContent>
                </Grid> */}
            </ComponentWrapper>
        </>
    )
}

export default SchoolDashboard;