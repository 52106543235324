import React, {useState, Fragment } from "react";
// import { HashLink } from "react-router-hash-link";

import { GridWrapper } from "../../style";
let totTextNum = 150
const ClassPluginEditor = ({img_src="", class_arr=[], img_alt="", title="", from_age='', from_age_value='', to_age="", to_age_value="", text="", is_add_image=false, onClickReadMore, onToggleImg, onChangeImg, onSubmitImg, img_value="", onToggleAge, onChangeAge, is_age_change=false, onSubmitAge, is_text_change=false, text_value='', onSubmitText, onChangeText, onToggleText}) => {
    const [textVal, setTextVal] = useState(text ? (text.length > totTextNum ? `${text.slice(0, totTextNum)}...` : text) : '' );
    return(
        <GridWrapper>
            <div className="cont-school-web">
                {is_add_image ? (
                    <div className='disp-block'>
                        <form onSubmit={onSubmitImg}>
                        <input type="file" required className='form-control' name="img_value" onChange={onChangeImg} value={img_value} />
                        <div className='disp-flex wrap jc-stretch' >
                            <div className='disp-block padd-4px'>
                                <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity'>Upload</button>
                            </div>
                            <div className='disp-block padd-4px'>
                                <button type="button" className='btn app-text-color' onClick={onToggleImg} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                            </div>
                            
                        </div>
                        </form>
                    </div>
                ) : (
                    <div className="img-cont">
                        {img_src ? <img src={img_src} onClick={onToggleImg} alt={img_alt} className="img-cont" /> : <div className="img-cont align-all-center"><span className="fw-500 font-very-small hover-opacity" style={{color:'#ccc', cursor:'default'}} onClick={onToggleImg}>Upload an image showcasing this class <i className="fas fa-image"></i></span></div>}
                        <div className="overlay">
                            <div className="overlay-inner-cont"></div>
                            <div className="text-cont">
                                <span className="disp-block font-small fw-600 text-capitalize">{title}</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="content">
                    {is_age_change ? (
                        <form onSubmit={onSubmitAge}>
                            <div className='disp-flex wrap jc-stretch'>
                                <div className="padd-4px">
                                    <label className="font-super-small">From Age</label>
                                    <input type="number" onChange={onChangeAge} name='from_age_value' required value={from_age_value} style={{width:'70%'}} className="disp-block padd-4px font-very-small" />
                                </div>
                                <div className="padd-4px">
                                    <label className="font-super-small">To Age</label>
                                    <input type="number" onChange={onChangeAge} name='to_age_value' required value={to_age_value} style={{width:'70%'}} className=" disp-block padd-4px font-very-small" />
                                </div>
                            </div>
                            <div className='disp-flex wrap jc-stretch' >
                                <div className='disp-block padd-4px'>
                                    <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity'><i className="fas fa-paper-plane"></i></button>
                                </div>
                                <div className='disp-block padd-4px'>
                                    <button type="button" className='btn app-text-color' onClick={onToggleAge} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                </div>
                                
                            </div>
                        </form>
                    ) : (
                        <span className="disp-block align-center fw-600 font-slightly-small" onClick={onToggleAge}>Age {from_age} - {to_age} <button type="button" className='btn app-text-color align-center font-super-small hover-br br' style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button></span>
                    )}
                    <div className="class-link-cont">
                        {class_arr.map((item, i) => {
                            return(
                                <Fragment key={i}>
                                    <div className="disp-flex link-cont wrap jc-sb padd-4px">
                                        <span className="blue font-very-small fw-600">{item.name}</span>
                                        <button className="btn bg-red br font-super-small fw-600 app-bg-text-color" style={{padding:'2px 5px'}}>Enroll Now <i className="fas fa-arrow-left anim-arrow"></i></button>
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                    {is_text_change ? (
                        <>
                            <div className='disp-block'>
                                    <form onSubmit={onSubmitText}>
                                    <textarea placeholder='Write ...' className='form-control font-very-small' style={{minHeight:'150px'}} name="text_value" onChange={onChangeText} value={text_value} />
                                    <div className='disp-flex wrap jc-stretch' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' onClick={onToggleText} title="Cancel" style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <>
                            {text ? (
                                <div className="disp-block" style={{marginTop:'10px'}}>
                                    <span className="disp-block app-text-color font-slightly-small fw-500">
                                        {textVal} 
                                    </span>
                                    <button type="button" onClick={onToggleText} className='btn app-text-color align-center font-super-small hover-br br' style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button>
                                </div>
                            ) : (
                                <div className="disp-block" style={{marginTop:'10px', marginBottom:'10px'}}>
                                    <span className="font-very-small fw-600" style={{color:'#888'}} onClick={onToggleText}>Write something here to tell the prospective parent or student about this class ... </span>
                                </div>
                            )}
                        </>
                    )}
                    {text && (
                        <>
                        {text.length > textVal.length ? (
                            <div className="disp-block padd-4px align-right">
                                <button type="button" className="btn bg-none app-text-color font-very-small" style={{borderBottom:'1px solid var(--appBgColor)', padding:'0'}} onClick={() => {
                                    setTextVal(text);
                                }}>Read More...</button>
                            </div>
                        ) : (
                            <div className="disp-block padd-4px align-right">
                                <button type="button" className="btn bg-none app-text-color font-very-small" style={{borderBottom:'1px solid var(--appBgColor)', padding:'0'}} onClick={() => {
                                    setTextVal(text.length > totTextNum ? `${text.slice(0, totTextNum)}...` : text);
                                }}>Read Less...</button>
                            </div> 
                        )}
                        </>
                    )}
                    
                </div>
            </div>
        </GridWrapper>
    )
    
}

export default ClassPluginEditor;