import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { AnimatePresence } from 'framer-motion';
import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import MenuDrop from 'components/app/school/SmallerComponents/menuDrop';
import Section from 'components/app/school/SmallerComponents/Section';
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
// import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Input from 'components/app/school/SmallerComponents/input';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { convertNumber, convertToNumber, seperatePoint } from 'components/app/school/actions/money';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { BACKEND_URL } from 'actions/url';

import { loopDate } from 'components/app/school/actions/array-utils/dates';
import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';


const StaffPay = ({school_id, currency, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [state, setState] = useState({year:new Date().getFullYear(), month:new Date().getMonth(), pre:true});
    const [menuDropData, setMenuDropData] = useState({});

    const [payment, setPayment] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [isNotStartPayment, setIsNotStartPayment] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);

    const handleStateChange = e => {
        setState({...state, [e.target.name]:e.target.value});
        setDataTableLoading(true);
    }

    const fetchAllStaffPayment = (sid, y=0, m=0, pre=true) => {
        setError({data:[], title:''});
        setPreloading(pre);
        setPreloading2(pre);
        setSpinLoading(!pre);
        appServices.getAllSchoolStaffPayment(sid, y, m).then(res => {
            setPayment(res.data);
            setPreloading(false);
            setPreloading2(false);
            setSpinLoading(false);
            setIsNotStartPayment(false);
        }).catch(e => {
            if (e.response.status === 400){
                setIsNotStartPayment(true);
                setPreloading(false);
                setSpinLoading(false);
                setPreloading2(false);
                setPayment([]);
                return;
            }
            setPreloading(false);
            setPreloading2(false);
            setSpinLoading(false);
            setPayment([]);
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'Payment Data Fetch Error'], click_text:'Reload', handleClick: () => setReload(true)}});
        })
    }

    const onClickStartPayment = () => {
        setError({data:[], title:''});
        setLoading(true);
        const data = {school:school_id, year:state.year, month:state.month+1};
        appServices.startSchoolStaffPayment(data).then(res => {
            setState({...state, pre:false});
            setLoading(false);
            setReload(true);
            setIsNotStartPayment(false);
        }).catch(e => {
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Payment Insert Error'});
        })
    }
    const onClickPay = (data = {}) => {
        setMenuDrop(true);
        setMenuDropData(data);
    } 

    const handlePaymentClick = e => {
        e.preventDefault();
        setWarning({id:0, initialSuccessState});
        setError(initialErrorState);
        const amt = `${convertToNumber(menuDropData.amt)}.${menuDropData.amt_point}`;
        setTimeout(() => {
            setWarning({id:menuDropData.id, title:'Payment Warning', text:`${currency.symbol}${amt} will be deduced from the school balance remaining, once this staff accepts payment`, click_text:'Pay', handleClick: () => {
                setMenuLoading(true);
                setWarning({id:0, initialSuccessState});
                appServices.paySchoolStaffPayment(menuDropData.uid, {amt_paid:parseFloat(`${convertToNumber(menuDropData.amt)}.${menuDropData.amt_point}`), date_paid:convertDate(new Date(), 'yyyy-mm-dd hh:mm:ss'), paid_by:user_id}).then(res => {
                    setDataTableLoading(true);
                    setMenuLoading(false);
                    setMenuDrop(false);
                    setSuccess({title:'Success', text:'Success... Awaiting Response From Staff'});
                }).catch(e => {
                    setMenuLoading(false);
                    setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Payment Error'});
                })
            } })
        })
    }


    const handleMenuChange = e => {
        if(e.target.name === "amt" && parseInt(convertToNumber(e.target.value)) > parseInt(menuDropData.prev_amt)){
            setMenuDropData({...menuDropData, [e.target.name+'_clean']:menuDropData.prev_amt.toString(), [e.target.name]:menuDropData.prev_amt.toString()});
            return;
        }
        if(e.target.name === "amt"){
            setMenuDropData({...menuDropData, [e.target.name]:e.target.value, [e.target.name+'_clean']:`${convertToNumber(e.target.value)}.${menuDropData.amt_point}`});
            return;
        }
        if(e.target.name === "amt_point"){
            setMenuDropData({...menuDropData, [e.target.name]:e.target.value, amt_clean:`${convertToNumber(menuDropData.amt)}.${e.target.value}`});
            return;
        }
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value});
    }
    useEffect(() => {
        if(reload){
            fetchAllStaffPayment(school_id, parseInt(state.year), parseInt(state.month)+1, state.pre)
            setReload(false);
        }
        if(dataTableLoading){
            fetchAllStaffPayment(school_id, parseInt(state.year), parseInt(state.month)+1, false)
            setDataTableLoading(false)
        }
    }, [school_id, state, dataTableLoading, reload])



    const curr_year = new Date().getFullYear();
    const curr_month = new Date().getMonth();
    const month = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertDateOption = loopDate(curr_year - 2023).map((date, i) => (
        <option value={date} disabled={date > curr_year ? true : false}>{date}</option>
    ))
    const addNumbers = (arr=[]) => {
        const data = arr.reduce((num, currNum) => {
            return parseFloat(num) + parseFloat(currNum);
        }, 0.00);
        return data;
    }
    const isPaid = (is_school_paid=false, is_staff_accept=false) => {
        if(is_school_paid && is_staff_accept){
            return {title:'PAID', text:''};
        }
        if(is_school_paid){
            return {title:'PAID', text:'\n (Awaiting Staff Response)'};
        }
        if(is_staff_accept){
            return {title:'ERROR', text:''};
        }
        return {title:'pending', text:''};
    }
    const insertMonthOption = month.map((mon, i) => {
        if(curr_year === state.year){
            return(
                <option value={i} disabled={i > curr_month ? true : false}>{mon.slice(0, 3)}</option>
            )
        }
        return(
            <option value={i} >{mon.slice(0, 3)}</option>
        )
    })
    return(
        <>
            <HeadComponent title='Staff Payment' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                    <GridContent>
                    {loading && <Loader />}
                    {preloading ? (<ul>
                        <BlankLoader num={20} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <Section>
                            <Mallet>
                                <div className='flex-50-line'>
                                    <label className='font-slightly-small'>Select  Year: 
                                    <select name="year" onChange={e => handleStateChange(e)} value={state.year} className='form-control-theme font-slightly-small' >
                                        {insertDateOption}
                                    </select></label>
                                </div>
                                <div className='flex-50-line'>
                                    <label className='font-slightly-small'>Select  Month: 
                                    <select name="month" onChange={e => handleStateChange(e)} value={state.month} className='form-control-theme font-slightly-small text-capitalize' >
                                        {insertMonthOption}
                                    </select></label>
                                </div>
                            </Mallet>
                            {isNotStartPayment ? (
                                <>
                                {!spinLoading ? (<div className='disp-block w100 align-center' style={{padding:'10px 15px'}}>
                                    <button type="button" onClick={() => onClickStartPayment()} className='btn app-bg-color app-bg-text-color hover-opacity br'>Start Payment</button>
                                </div>) : <Spinner />}
                                </>
                            ): (
                                <>
                                {spinLoading && <Spinner />}
                                <Table>
                                    <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Name - Amt To Pay"}, {text: 'Amt Paid'}, {text:"Status"}]} />
                                    {payment.length === 0 ? (<Tr btn={false} text={[{text:'No Record Found', style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                         (
                                             <>
                                             {payment.map((item, i) => {
                                                 const name = `${item.last_name} ${item.first_name}`;
                                                 const j = i+1;
                                                 const stat = isPaid(item.is_school_paid, item.is_staff_accept);
                                                 const amt = addNumbers([item.curr_salary, item.prev_unpaid_amt]);
                                                 const data = {amt:seperatePoint(amt.toString()), amt_point:seperatePoint(amt.toString(), true), amt_clean:amt.toString(), prev_amt:amt.toString(), name:name, id:item.id, uid:item.uid, pp:item.profile_pic}
                                                 return(
                                                     <Fragment key={i}>
                                                        <Tr sn={true} pay_btn={item.is_school_paid ? false : true} edit_btn={false} dlt_btn={false} handlePayClick={() => onClickPay(data)} >
                                                            <div className="c-data sn">
                                                                <span className="font-very-small app-text-color">{ j }</span>
                                                            </div>
                                                            <div className="c-data">
                                                                <div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic}`} alt="Profile" /></div>
                                                                <span className="font-very-small text-capitalize padd-4px">{name.length > 20 ? `${name.slice(0, 20)}...`:name}</span> <span className='font-very-small fw-600' style={{color:'#777'}}> - {currency.symbol}{convertNumber(addNumbers([item.curr_salary, item.prev_unpaid_amt]))}</span>
                                                                <div className='disp-block'>
                                                                    <code className='font-super-small app-text-color fw-500'> - {item.school_branch_name.length > 8 ? `${item.school_branch_name.slice(0, 8)}...` : item.school_branch_name}</code>
                                                                </div>
                                                            </div> 
                                                            <div className="c-data"><span className="font-slightly-small app-text-color fw-600">{currency.symbol}{convertNumber(item.amt_paid)}</span></div>  
                                                            <div className="c-data"><span className="font-very-small text-capitalize">{stat.title.toLowerCase() === "paid" ? (<><span className='green fw-700'>{stat.title.toUpperCase()}</span>{stat.text && <span className='font-super-small'>{stat.text}</span>} {item.is_incomplete && (<>{!item.is_incomplete && <span className='font-super-small' style={{color:'rgb(205, 220, 15)'}}>(Incomplete)</span>}</>)}</>) : (<>{stat.title.toLowerCase() === "pending" ? <b className='fw-700 ' style={{color:'rgb(205, 220, 15)', fontWeight:700}}>{stat.title}</b> : stat.title}</>)}</span></div>    
                                                        </Tr>
                                                     </Fragment>
                                                 )
                                             })}
                                             </>
                                         )              
                                    }
                                </Table>
                                </>
                            )}
                        </Section>
                        </>
                    )}
                    <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false); setWarning(initialSuccessState); setMenuDropData({});}} header_text={'Pay Staff'} header_icon={"fas fa-coins"}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    <form onSubmit={e => handlePaymentClick(e)}>
                                        <div className='row'>
                                            <div className="fg-all profile-cont">
                                                <img style={{borderRadius:'5px'}} src={`${BACKEND_URL}${menuDropData.pp}`} className="" alt={menuDropData.name} />
                                            </div>
                                            <div className="fg-all">
                                                <label className="font-super-small">Full Name</label>
                                                <input  type="text" className="font-very-small form-control disabled" defaultValue={menuDropData.name} disabled  />
                                            </div>
                                            <div className='fg-all'>
                                                <label className='font-super-small'>Amount</label>
                                                <Input type="money" required currency={currency.symbol} currency_value={currency.id} name={"amt"} value={menuDropData.amt} point_name='amt_point' point_value={menuDropData.amt_point}
                                                point_display={menuDropData.amt ? true : false} handleChange={e => handleMenuChange(e)} handlePointChange={e => handleMenuChange(e)} classname='font-very-small app-theme form-control-theme app-text-color' placeholder='Amount..' />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small fw-700">
                                                    Pay <span className='font-very-small'>{currency.symbol}{convertNumber(menuDropData.amt_clean)}</span> </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                                )}
                            </MenuDrop>
                            }
                    </AnimatePresence>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )

}

export default StaffPay;