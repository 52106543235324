import styled from "styled-components";

export const Content = styled.div`
    padding: 0px 7px 7px;

    label{
        font-weight: 700;
        color: #666;
        display: block;
        padding-bottom: 2px;
    }
    button{
        transition: .4s ease;
        :hover{
            background: var(--appBgSecTextColor);
        }
    }
`;