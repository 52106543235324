import React, { useState, Fragment } from "react";
import { HashLink } from "react-router-hash-link";

import { GridWrapper } from "../style";

let totTextNum = 150
const ClassPlugin = ({img_src="", class_arr=[], img_alt="", title="", from_age='', to_age="", text="", is_add_image=false, is_editor=false, onClickReadMore}) => {
    const [textVal, setTextVal] = useState(text ? (text.length > totTextNum ? `${text.slice(0, totTextNum)}...` : text) : '' );
    return(
        <GridWrapper>
            <div className="cont-school-web">
                <div className="img-cont">
                    {img_src ? <img src={img_src} alt={img_alt} className="img-cont" /> : <div className="img-cont align-all-center">{is_editor && <span className="fw-500 font-very-small hover-opacity" style={{color:'#ccc', cursor:'default'}}>Upload an image showcasing this class <i className="fas fa-image"></i></span>}</div>}
                    <div className="overlay">
                        <div className="overlay-inner-cont"></div>
                        <div className="text-cont">
                            <span className="disp-block font-small fw-600 text-capitalize">{title}</span>
                        </div>
                    </div>
                </div>
                <div className="disp-block">
                    <span className="disp-block align-center fw-600 font-slightly-small" style={{marginTop:'4px'}}>Age {from_age} - {to_age}</span>
                </div>
                <div className="content">
                    <div className="class-link-cont">
                        {class_arr.map((item, i) => {
                            return(
                                <Fragment key={i}>
                                    <div className="disp-flex link-cont wrap jc-sb padd-4px">
                                        <span className="blue font-very-small fw-600">{item.name}</span>
                                        <HashLink to={item.to}className="btn bg-red br font-super-small fw-600" style={{padding:'2px 5px'}}>Enroll Now <i className="fas fa-arrow-left anim-arrow"></i></HashLink>
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                    <div className="disp-block padd-4px">
                        <span className="disp-block app-text-color font-slightly-small fw-500" style={{marginTop:'4px',letterSpacing:'.5px'}}>
                            {textVal} 
                        </span>
                    </div>
                    
                    {text && (
                        <>
                        {text.length > textVal.length ? (
                            <div className="disp-block padd-4px align-right">
                                <button type="button" className="btn bg-none app-text-color font-very-small" style={{borderBottom:'1px solid var(--appBgColor)', padding:'0'}} onClick={() => {
                                    setTextVal(text);
                                }}>Read More...</button>
                            </div>
                        ) : (
                            <div className="disp-block padd-4px align-right">
                                <button type="button" className="btn bg-none app-text-color font-very-small" style={{borderBottom:'1px solid var(--appBgColor)', padding:'0'}} onClick={() => {
                                    setTextVal(text.length > totTextNum ? `${text.slice(0, totTextNum)}...` : text);
                                }}>Read Less...</button>
                            </div> 
                        )}
                        </>
                    )}
                </div>
            </div>
        </GridWrapper>
    )
    
}

export default ClassPlugin;