import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    .l13{
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 2rem;
        max-height: 200vh;
        /* overflow-y: auto; */
        @media(max-width:760px){
            grid-template-columns: auto;
        }
    }
    .l211{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 2rem;
        /* max-height: 200vh; */
        /* overflow-y: auto; */
        @media(max-width:760px){
            grid-template-columns: auto;
        }
    }
    .l31{
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: 2rem;
        /* max-height: 200vh; */
        /* overflow-y: auto; */
        @media(max-width:760px){
            grid-template-columns: auto;
        }
    }
    .lauto{
        display: grid;
        grid-template-columns: auto;
        grid-gap: 2rem;
        max-height: 200vh;
    }
`