import http, { http_file } from "api/http_setup";

const chat_url = 'chat'
export const chatServices = {
    createAccount: (data={}) => {
        return http.post(`${chat_url}/account/main/create`, data);
    },
    createFriendRequest: (data={}) => {
        return http.post(`${chat_url}/account/friend_request/create`, data);
    },
    searchFriends: (name="") => {
        return http.get(`${chat_url}/account/friend_search/all?name=${name}`);
    },
    getAllFriendReqs: () => {
        return http.get(`${chat_url}/account/friends/all_reqs`);
    },
    getAllFriends: () => {
        return http.get(`${chat_url}/account/friends/all`);
    },
    processFriendRequest: (id=0, type=0, data={}) => {
        return http.put(`${chat_url}/account/friend/process_request/update/${id}/${type}`, data);
    },
    updateAccountFriend: (id=0, data={}) => {
        return http.put(`${chat_url}/account/friend/update/${id}`, data);
    },
    createMessage: (data={}) => {
        return http.post(`${chat_url}/account/main/message/create`, data);
    },
    createImageMessage: (data={}) => {
        return http_file.post(`${chat_url}/account/main/message/image/create`, data);
    },
    createMessageVa: (data={}) => {
        return http.post(`${chat_url}/account/main/va/message/create`, data);
    },
    messageByBot: (data={}) => {
        return http.post(`${chat_url}/account/main/va/message_by_bot/create`, data);
    },
    getAllMessagesVa: (id=0, p=1, size=100, start_id='', is_seen='') => {
        return http.get(`${chat_url}/account/main/va/message/all/${id}?p=${p}&size=${size}&start_id=${start_id}&iss=${is_seen}`);
    },
    getAllFriendMessages: (id=0, p=1, size=100, start_id='', is_seen='') => {
        return http.get(`${chat_url}/account/friends/message/all/${id}?p=${p}&size=${size}&start_id=${start_id}&iss=${is_seen}`);
    },
    getAccount: (id=0) => {
        return http.get(`${chat_url}/account/main/get/${id}`);
    },
    updateAccount: (id=0, data={}) => {
        return http.put(`${chat_url}/account/main/update/${id}`, data);
    },
    updateAccountVa: (id=0, data={}) => {
        return http.put(`${chat_url}/account/assistant/update/${id}`, data);
    },

    // getAccount: (id=0) => {
    //     return http.get(`${chat_url}/account/main/get/${id}`);
    // },
}