import React from 'react';
import { Wrapper } from './style';

const InlineList = ({children}) => {
    return(
        <Wrapper>
            <ul>
                {children}
            </ul>
        </Wrapper>
    )
}

export default InlineList;