import React from "react";
import { Wrapper, Content } from "./style";

const Grid = ({header, children, position, grid_width}) => {
    return(
        <Wrapper className={`${position} ${' '} anim`}>
            {header && <h2>{header}</h2>}
            <Content className={`${grid_width} ${' '}`}>
                {children}
            </Content>
        </Wrapper>
    );
};

export default Grid;