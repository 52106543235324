import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";

import { HeadComponent } from "components/head";
import { appServices } from "api/schoolApp/services";

import { ComponentWrapper } from "../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import {default as success_icon} from 'images/success_icon.svg';

const Classes = ({user_type, school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [accordion, setAccordion] = useState(0);
    const [submitted, setSubmitted] = useState(null);
    // const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allClassSectionItem, setAllClassSectionItem] = useState([]);
    const [allClassData, setAllClassData] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allClassItem, setAllClassItem] = useState({result:[]});
    const [allClassesItem, setAllClassesItem] = useState({result:[]});
    const [allClassBlockData, setAllClassBlockData] = useState({result:[]});
    // const [allClassSchoolBranchData, setAllClassSchoolBranchData] = useState({result:[]})

    const [classSectionData, setClassSectionData] = useState({id:0})
    // const [classData, setClassData] = useState({id:0})
    const [menuDropData, setMenuDropData] = useState({id:''});
    const [menuDrop, setMenuDrop] = useState(false)
    const [dataType, setDataType] = useState('')
    
    const [formInsertClassSection, setFormInsertClassSection] = useState({class_section: 0})
    const [formInsertClass, setFormInsertClass] = useState({class_num:0, class_section:0, class:'', cssid:0,})
    const [formInsertClassBlock, setFormInsertClassBlock] = useState({class_section: "", class:'', class_block:'', tot_num:null, class_form:0})

    const [loading, setLoading] = useState("");
    const [preloading] = useState("");
    const [preloading2, setPreloading2] = useState(false);
    const [preloading3, setPreloading3] = useState(false);
    const [preloading4, setPreloading4] = useState(false);
    const [reloadForm, setReloadForm] = useState('');

    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
        setFormInsertClassSection({class_section:''})
        setFormInsertClass({class_section:0, class:'', class_num:0, cssid:0})
        setFormInsertClassBlock({class_section: "", class:'', class_block:'', tot_num:null, class_form:0})
        setClassSectionData({id:0})
        setFormInsertClassSection({class_section:0, school_branch:0});
    }
    const handleClassSectionChange = (e) => {
        setFormInsertClassSection({...formInsertClassSection, [e.target.name]:e.target.value})
    }
    const handleClassChange = (e) => {
        if(e.target.name === "class_section" && e.target.value){ 
            fetchAllClasses(school_id, e.target.value) 
            fetchClassSection(school_id, e.target.value, true)
        }
        setFormInsertClass({...formInsertClass, [e.target.name]:e.target.value})
    }
    const handleClassBlockChange = (e) => {
        if(e.target.name === "class_section") fetchAllClass(school_id, e.target.value)
        else if(e.target.name === "class" && e.target.value) fetchClass(e.target.value, true)
        setFormInsertClassBlock({...formInsertClassBlock, [e.target.name]:e.target.value})
    }
    const handleEdit = (data) => {
        setMenuDrop(true);
        setMenuDropData(data);
    }
    const handleEditChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }

    // const fetchAllSchoolBranch = (sid) => {
    //     appServices.getAllSchoolBranch(sid).then(res => {
    //         setAllSchoolBranchData(res.data)
    //     }).catch(e => {
    //         setAllSchoolBranchData([]);
    //         setError({data:["Internal Server Error"], title:"School Branch Data Error"});
    //     })
    // }

    const fetchAllClassSection = (sid) => {
        setPreloading2(true);
        setReloadForm('');
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSectionData(res.data);
            setPreloading2(false)
        }).catch(e => {
            setAllClassSectionData([]);
            setError({data:["Internal Server Error"], title:"Class Section Data Error"});
            setPreloading2(false)
            setReloadForm('all_class_section_school');
        })
    }
    const fetchAllClassSectionItem = (sid) => {
        setReloadForm('');
        appServices.getAllClassSection(sid).then(res => {
            setAllClassSectionItem(res.data);
        }).catch(e => {
            setAllClassSectionItem([]);
            setReloadForm('all_class_section_item');
            setError({data:["Internal Server Error"], title:"Class Section Data Error"});
        })
    }
    const fetchAllClass = (sid, csid) => {
        if (csid){
            setReloadForm('');
            appServices.getAllClassSchool(sid, csid).then(res => {
                setAllClassData(res.data);
            }).catch(e => {
                setAllClassData([]);
                setError({data:["Internal Server Error"], title:"Class Data Error"});
                setReloadForm('all_class');
            })
        } else {
            setAllClassData([]);
        }
    }
    const fetchAllClasses = (sid, csid) => {
        setAllClassesData([]);
        setReloadForm('');
        appServices.getAllClass(sid, csid).then(res => {
            setAllClassesData(res.data);
        }).catch(e => {
            setAllClassesData([]);
            setReloadForm('all_classes');
            setError({data:["Internal Server Error"], title:"Class Data Error"});
        })
    }
    const fetchAllClassesItem = (sid) => {
        setAllClassesItem({result:[]});
        appServices.getAllClassItem(sid).then(res => {
            setAllClassesItem({result:res.data});
        }).catch(e => {
            setAllClassesData({result:[]});
        })
    }
    const fetchAllClassItem = (sid) => {
        setPreloading3(true)
        setAllClassItem({result:[]});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClassItem({result:res.data});
            setPreloading3(false);
        }).catch(e => {
            setAllClassItem({result:[]});
            setError({data:["Internal Server Error"], title:"Class Data Error"});
            setPreloading3(false)
        })
    }
    const fetchAllClassBlock = (sid, csid, cid) => {
        setPreloading4(true)
        setAllClassBlockData({result:[]});
        appServices.getAllClassBlock(sid, cid, csid).then(res => {
            setAllClassBlockData({result:res.data});
            setPreloading4(false)
        }).catch(e => {
            setAllClassBlockData({result:[]});
            setPreloading4(false)
            setError({data:["Internal Server Error"], title:"Class Block Data Error"});
        })
    }

    const fetchClassSection = (sid, csid, addToForm=false) => {
        setClassSectionData({id:0})
        appServices.getClassSectionSchool2(sid, csid).then(res => {
            if(addToForm) setFormInsertClass({...formInsertClass, cssid:res.data.id, class_section:res.data.class_section})
            setClassSectionData({...classSectionData, ...res.data});
        }).catch(e => {
            setClassSectionData({id:0});
            setError({data:["Internal Server Error"], title:"Class Section Data Error"});
        })
    }
    const fetchClass = (pk, addToForm=false) => {
        // setClassData({id:0})
        setReloadForm('')
        appServices.getClassSchool(pk).then(res => {
            if(addToForm) setFormInsertClassBlock({...formInsertClassBlock, class:res.data.id, class_block:res.data.name})
            // setClassData({...classSectionData, ...res.data});
        }).catch(e => {
            // setClassData({id:0});
            setReloadForm('class');
            setError({data:["Internal Server Error"], title:"Class Data Error"});
        })
    }
    

    const handleClassSectionSubmit = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            class_section: formInsertClassSection.class_section,
            school: school_id,
            user: user_id
        }

        appServices.insertClassSectionSchool(data).then(res => {
            setFormInsertClassSection({class_section:""});
            setSuccess({text:"Data Saved Successfully", title:"Success"})
            setLoading("");
            setSubmitted("");
            fetchAllClassSection(school_id);
        }).catch(e => {
            setError({data:[e.response.data.detail], title:"Class Section Error"})
            setLoading("");
            setSubmitted("");
            fetchAllClassSection(school_id);
        })
    }
    const handleClassSubmit = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            class_section_school: formInsertClass.cssid,
            classes: formInsertClass.class_num,
            name:formInsertClass.class,
            school: school_id,
            user: user_id
        }

        appServices.insertClassSchool(data).then(res => {
            setFormInsertClass({class_section:0, class:'', class_num:0});
            setSuccess({text:"Data Saved Successfully", title:"Success"})
            setLoading("");
            setSubmitted("");
            fetchAllClassItem(school_id)
        }).catch(e => {
            setError({data:[e.response.data.detail], title:"Class Error"})
            setLoading("");
            setSubmitted("");
            fetchAllClassItem(school_id)
        })
    }

    const handleClassBlockSubmit = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            tot_num: formInsertClassBlock.tot_num,
            classes: formInsertClassBlock.class,
            class_block:formInsertClassBlock.class_block,
            school: school_id,
            created_by: user_id
        }
        appServices.insertClassBlock(data).then(res => {
            setFormInsertClassBlock({class_section: 0, class:0, class_block:'', tot_num:''});
            setSuccess({text:"Data Saved Successfully", title:"Success"})
            setLoading("");
            setSubmitted("");
            fetchAllClassBlock(school_id);
        }).catch(e => {
            setError({data:[e.response.data.detail], title:"Class Block Error"})
            setLoading("");
            setSubmitted("");
            fetchAllClassBlock(school_id);
        })
    }
    // const handleUpdateClassSection = (e) => {
    //     e.preventDefault();
    //     setSubmitted("");
    //     setLoading("menuDrop");
    //     setError({...initialErrorState});
    //     setSuccess({...initialSuccessState});
    //     const data = {
    //         school: school_id,
    //         class_section: menuDropData.class_section
    //     }
    //     appServices.updateClassSection(menuDropData.id, data).then(res => {
    //         setError({...initialErrorState});
    //         setMenuDrop(false)
    //         setMenuDropData({...menuDropData, type_update:'class_section'})
    //         setSuccess({text: "Data Updated Successfully", title:"success"})
    //         setSubmitted(menuDropData.id);
    //         fetchAllClassItem(school_id)
    //         fetchAllClassBlock(school_id);
    //         fetchAllClassSection(school_id);
    //         setLoading("");
    //     }).catch(e => {
    //         setSuccess({...initialSuccessState});
    //         setError({data:[e.response.data.detail], title:"Class Section Update Error"})
    //         setLoading("");
    //     })
        
    // }
    const handleUpdateClass = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("menuDrop");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            name: menuDropData.class,
            classes: menuDropData.class_num,
            class_section_school: menuDropData.class_section_school,
        }
        appServices.updateClassSchool(menuDropData.id, data).then(res => {
            setError({...initialErrorState});
            setMenuDrop(false)
            setMenuDropData({...menuDropData, type_update:'class'})
            setSuccess({text: "Data Updated Successfully", title:"success"})
            setSubmitted(menuDropData.id);
            fetchAllClassItem(school_id)
            fetchAllClassBlock(school_id);
            setLoading("");
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.detail], title:"Class Update Error"})
            setLoading("");
        })
        
    }
    const handleUpdateClassBlock = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("menuDrop");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            classes: menuDropData.class,
            school: school_id,
            class_section: menuDropData.class_section,
            class_block: menuDropData.class_block
        }
        appServices.updateClassBlock(menuDropData.id, data).then(res => {
            setError({...initialErrorState});
            setMenuDrop(false)
            setMenuDropData({...menuDropData, type_update:'class_block'})
            setSuccess({text: "Data Updated Successfully", title:"success"})
            setSubmitted(menuDropData.id);
            fetchAllClassItem(school_id)
            fetchAllClassBlock(school_id);
            setLoading("");
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.detail], title:"Class Update Error"})
            setLoading("");
        })
        
    }
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmitted("none");
        }, 1000 * 15)
        return(
            <div className="nav-dd-anim" style={{display:submitted}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }
    const handleDeleteClassSection = (id) => {
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        setWarning(initialSuccessState);
        appServices.deleteClassSectionSchool(id).then(res => {
            setDataType('class_section')
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
            id:id })
            setLoading("");
            setTimeout(() => {
                fetchAllClassItem(school_id)
                fetchAllClassBlock(school_id);
                fetchAllClassSection(school_id);
                setDataType('')
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:id })
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
            }, 500)
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Delete Class Section Error"})
        })
    }
    // const handleUndoDeleteClassSection = (id) => {
    //     setSubmitted(false);
    //     setLoading("right");
    //     setError({...initialErrorState});
    //     setSuccess(initialSuccessState);
    //     appServices.deleteClassSection(id, "undo").then(res => {
    //         setAnim({initial:{opacity: 0, duration:0.4},
    //             animate:{opacity:1, duration:0.4, background:""},
    //             id:id })
    //         setSuccess({text:"Undo Delete Successfull", title:"Success"}) 
    //         setLoading("");
    //         fetchAllClassItem(school_id)
    //         fetchAllClassBlock(school_id);
    //         fetchAllClassSection(school_id);
    //     })
    //     .catch(e => {
    //         console.log(e.response);
    //         setSuccess({...initialSuccessState});
    //         setLoading("");
    //         setError({data:[e.response.data.detail], title:"Undo Delete Class Section Error"})
    //     })
    // }
    const handleDeleteClass = (id) => {
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        setWarning(initialSuccessState);
        appServices.deleteClassSchool(id).then(res => {
            setDataType('class')
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
            id:id })
            setLoading("");
            setTimeout(() => {
                fetchAllClassItem(school_id)
                fetchAllClassBlock(school_id);
                setDataType('')
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:id })
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
            }, 500)
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Delete Class Error"})
        })
    }
    // const handleUndoDeleteClass = (id) => {
    //     setSubmitted(false);
    //     setLoading("right");
    //     setError({...initialErrorState});
    //     setSuccess(initialSuccessState);
    //     appServices.deleteClass(id, "undo").then(res => {
    //         setAnim({initial:{opacity: 0, duration:0.4},
    //             animate:{opacity:1, duration:0.4, background:""},
    //             id:id })
    //         setSuccess({text:"Undo Delete Successfull", title:"Success"}) 
    //         setLoading("");
    //         fetchAllClassItem(school_id)
    //         fetchAllClassBlock(school_id);
    //     })
    //     .catch(e => {
    //         console.log(e.response);
    //         setSuccess({...initialSuccessState});
    //         setLoading("");
    //         setError({data:[e.response.data.detail], title:"Undo Delete Class Error"})
    //     })
    // }
    const handleDeleteClassBlock = (e, id) => {
        e.preventDefault();
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        appServices.deleteClassBlock(id).then(res => {
            setDataType('class_block')
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
            id:id })
            setLoading("");
            setTimeout(() => {
                fetchAllClassBlock(school_id);
                setDataType('')
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:id })
                setSuccess({text:"Data Deleted Successfully", title:"Success", click_text:"Undo Delete", handleClick:() => handleUndoDeleteClassBlock(id)});
            }, 500)
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Delete Class Block Error"})
        })
    }
    const handleUndoDeleteClassBlock = (id) => {
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        appServices.deleteClassBlock(id, "undo").then(res => {
            setAnim({initial:{opacity: 0, duration:0.4},
                animate:{opacity:1, duration:0.4, background:""},
                id:id })
            setSuccess({text:"Undo Delete Successfull", title:"Success"}) 
            setLoading("");
            fetchAllClassBlock(school_id);
        })
        .catch(e => {
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Undo Delete Class Block Error"})
        })
    }
    const deleteWarning = (id=0, title="", text="", type,) => {
        setWarning({id:0, title:'', text:'', click_text:''})
        setTimeout(() => {
            if(type === "class_section") setWarning({id:id, title:title, text:text, handleClick: () => handleDeleteClassSection(id), click_text:'DELETE'})
            else if(type === "class") setWarning({id:id, title:title, text:text, handleClick: () => handleDeleteClass(id), click_text:'DELETE'})
        }, 100)
    }
    // const refreshFunc = () => {
    //     fetchAllClassSection(school_id);
    //     fetchAllClassItem(school_id);
    //     fetchAllClassBlock(school_id);
    //     fetchAllClassSectionItem(school_id);
    //     fetchAllClassesItem(school_id);
    // }
    const onClickReload = (data) => {
        if(reloadForm === "all_class") fetchAllClass(school_id, data.class_section);
        else if(reloadForm === "all_classes") fetchAllClasses(school_id, data.class_section);
        else if(reloadForm === "all_class_section_school") fetchAllClassSection(school_id);
        else if(reloadForm === "all_class_section_item") fetchAllClassSectionItem(school_id);
        else if(reloadForm === "class") fetchClass(data.class, true);
    }
    useEffect(() => {
        fetchAllClassSection(school_id);
        fetchAllClassItem(school_id);
        fetchAllClassBlock(school_id);
        fetchAllClassSectionItem(school_id);
        fetchAllClassesItem(school_id);
    }, [school_id])
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    // const insertSchoolBranchOption = allSchoolBranchData.map((item) => {
    //     return(
    //         <option value={item.id} key={item.id}>{`${item.name}`}</option>
    //     )
    // })
    const insertClassSectionOption = allClassSectionData.map((item) => {
        return(
            <option className='text-capitalize' value={item.class_section} key={item.id}>{`${item.name} Section`}</option>
        )
    })
    // const insertClassSectionIdOption = allClassSectionData.map((item) => {
    //     return(
    //         <option className='text-capitalize' value={item.id} key={item.id}>{`${item.name} Section`}</option>
    //     )
    // })
    const insertClassSectionItemOption = allClassSectionItem.map((item) => {
        return(
            <option className='text-capitalize' value={item.id} key={item.id}>{`${item.class_section} Section`}</option>
        )
    })
    const insertClassOption = allClassData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.name}`}</option>
        )
    })
    const insertClassesOption = allClassesData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.classes}`}</option>
        )
    })
    const insertClassesItemOption = allClassesItem.result.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.classes}`}</option>
        )
    })
    const insertClassItemOption = allClassItem.result.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.classes}`}</option>
        )
    })
    const insertClassSection = allClassSectionData.map((item, i) => {
        i++;
        return(
            <Tr edit_btn={false} key={i}
            animation={(item.id === anim.id & dataType==="class_section") && {initial:{...anim.initial}, animate:{...anim.animate}}}
            handleDeleteClick={e => deleteWarning(item.id, 'Are you sure?', `All Classes In Relation To ${item.name} Section Will Be Deleted`, 'class_section')}>
                
                <div className="c-data sn">
                    <span className="font-very-small">{i}</span>
                </div>
                <div className="c-data" style={{width:'55%'}}><span className="font-very-small text-capitalize">{item.name} Section</span></div>    
            </Tr>
        )
    })
    const insertClass = allClassItem.result.map((item, i) => {
        i++;
        const type = "classes_form";
        const data = {
            i: i,
            id: item.id,
            class_section: item.csid,
            class_section_school: item.class_section_school,
            class_num: item.classes,
            class:item.name,
            type_update:'',
            type:type
        }
        return(
            <Tr key={i} handleEditClick={() => handleEdit(data)}
            animation={(item.id === anim.id & dataType==="class") && {initial:{...anim.initial}, animate:{...anim.animate}}}
            handleDeleteClick={e =>  deleteWarning(item.id, 'Are you sure?', ` ${item.name} Class Will Be Deleted`, 'class')}>
                <div className="c-data sn">{(() => {if(item.id===submitted && menuDropData.type_update==="class") return updateSuccessIcon() })()}
                    <span className="font-very-small">{i}</span></div>
                <div className="c-data"><span className="font-very-small text-capitalize">{item.name}</span></div>    
                <div className="c-data" style={{width:'55%'}}><span className="font-very-small text-capitalize">{item.class_section_name} Section</span></div>    
            </Tr>
        )
    })
    const insertClassBlock = allClassBlockData.result.map((item, i) => {
        i++;
        const data = {
            i: i,
            id: item.id,
            class_section: item.class_section,
            class:item.classes,
            class_block:item.class_block,
            type_update:'',
            type:"class_block_form"
        }
        return(
            <Tr key={i} handleEditClick={() => handleEdit(data)} style={item.id === anim.id && {display:anim.display}}
            animation={(item.id === anim.id & dataType==="class_block") && {initial:{...anim.initial}, animate:{...anim.animate}}}
            handleDeleteClick={e => handleDeleteClassBlock(e, item.id)}>
                <div className="c-data sn">{(() => {if(item.id===submitted && menuDropData.type_update==="class_block") return updateSuccessIcon() })()}
                <span className="font-very-small">{i}</span></div>
                <div className="c-data"><span className="font-very-small text-capitalize">{item.classes_name}</span></div>    
                <div className="c-data"  style={{width:'55%'}}><span className="font-very-small text-capitalize">{item.class_block}</span></div>    
            </Tr>
        )
    })
    return(
        <>
            <HeadComponent title="Manage Classes" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid>
                    <GridContent header_text="Set Class" header_icon="fas fa-align-left">
                        {loading==="left" && (<Loader />)}
                        {preloading ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (<>
                        
                        <Accordion handleSubmit={e => handleClassSectionSubmit(e)} index={2} text="Add Class Section" handleClick={() => handleAccordionClick(2) } currentAccordion={accordion} reload={reloadForm ? true : false} onClickReload={() => onClickReload(formInsertClassSection)}>
                            {accordion === 2 && ( <>
                                <AccordionFormData required={true} handleChange={(e) => handleClassSectionChange(e)} value={formInsertClassSection.class_section} tag="select" name="class_section" classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Class Section</option>
                                    {insertClassSectionItemOption}
                                </AccordionFormData>
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion>
                        <Accordion handleSubmit={e => handleClassSubmit(e)} index={1} text="Add Class" handleClick={() => handleAccordionClick(1) } currentAccordion={accordion} reload={reloadForm ? true : false} onClickReload={() => onClickReload(formInsertClassSection)}>
                            {accordion === 1 && ( <>
                                <AccordionFormData required={true} handleChange={e => handleClassChange(e)}  tag="select" name="class_section" value={formInsertClass.class_section} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Class Section</option>
                                    {insertClassSectionOption}
                                </AccordionFormData>
                                <AccordionFormData required={true} handleChange={e => handleClassChange(e)}  tag="select" name="class_num" value={formInsertClass.class_num} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Class Number</option>
                                    {insertClassesOption}
                                </AccordionFormData>
                                <AccordionFormData required={true} handleChange={(e) => handleClassChange(e)} value={formInsertClass.class} tag="input" type="text" name="class" classname="form-control-theme font-slightly-small text-capitalize" placeholder="Enter Class Name" />
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion>
                        <Accordion handleSubmit={e => handleClassBlockSubmit(e)} index={3} text="Add Class Block (if any)" handleClick={() => handleAccordionClick(3) } currentAccordion={accordion} reload={reloadForm ? true : false} onClickReload={() => onClickReload(formInsertClassBlock)}>
                            {accordion === 3 && ( <>
                                <AccordionFormData required={true} handleChange={e => handleClassBlockChange(e)}  tag="select" name="class_section" value={formInsertClassBlock.class_section} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Class Section</option>
                                    {insertClassSectionOption}
                                </AccordionFormData>
                                <AccordionFormData required={true} handleChange={e => handleClassBlockChange(e)}  tag="select" name="class" value={formInsertClassBlock.class} classname="form-control-theme font-slightly-small text-capitalize">
                                    <option value="">Select Class</option>
                                    {insertClassOption}
                                </AccordionFormData>
                                <AccordionFormData required={true} handleChange={(e) => handleClassBlockChange(e)} value={formInsertClassBlock.class_block} tag="input" type="text" name="class_block" classname="form-control-theme font-slightly-small" placeholder="Enter Class Block Name" />
                                <AccordionFormData required={true} handleChange={(e) => handleClassBlockChange(e)} value={formInsertClassBlock.tot_num} tag="input" type="number" name="tot_num" classname="form-control-theme font-slightly-small" placeholder="Total No. Student In A Class" />
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion>
                        
                        
                        
                        </>)}
                    </GridContent>
                    <GridContent header_text="Manage Classes" header_icon="fas fa-tasks">
                    {preloading2 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section icon="fas fa-eye" header="View Class Section">
                            <Table>
                                <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Class Section", style:{width:'55%'}}]} />
                                {allClassSectionData.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    insertClassSection                         
                                }
                            </Table>
                        </Section>
                        </>
                    )}
                    {preloading3 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section icon="fas fa-eye" header="View Classes">
                            <Table>
                                <Tr btn={false} sn={true} header={true} text={[{text:"S/N"}, {text:"Classes"}, {text:"Class Section", style:{width:'55%'}}]} />
                                {allClassItem.result.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    insertClass                      
                                }
                            </Table>
                        </Section>
                        </>
                    )}
                    {preloading4 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section icon="fas fa-eye" header="View Class Block">
                            <Table>
                                <Tr btn={false} sn={true} header={true} text={[{text:"S/N"}, {text:"Classes"}, {text:"Class Branch", style:{width:'55%'}}]} />
                                {allClassItem.result.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    insertClassBlock                      
                                }
                            </Table>
                        </Section>
                        </>
                    )}
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                                {loading === "menuDrop" && <Loader />}
                                {menuDropData.type === "classes_form" && (
                                    <form onSubmit={e => handleUpdateClass(e)}>
                                        <div className='row'>
                                            <div className="fg">
                                                <label className="font-super-small">Class Section </label>
                                                <select disabled name="class_section" className="font-very-small form-control-theme text-capitalize" 
                                                placeholder="Class Section" onChange={e => handleEditChange(e)} value={menuDropData.class_section}>
                                                    <option value="">Select Class Section</option>
                                                    {insertClassSectionOption}
                                                </select>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Class Number </label>
                                                <select disabled name="class_num" className="font-very-small form-control-theme text-capitalize" 
                                                 onChange={e => handleEditChange(e)} value={menuDropData.class_num}>
                                                    <option value="">Select Class Number</option>
                                                    {insertClassesItemOption}
                                                </select>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Class </label><input required name="class" onChange={e => handleEditChange(e)} 
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Class" value={menuDropData.class} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                                {menuDropData.type === "class_block_form" && (
                                    <form onSubmit={e => handleUpdateClassBlock(e)}>
                                        <div className='row'>
                                            <div className="fg">
                                                <label className="font-super-small">Classes </label>
                                                <select name="class" className="font-very-small form-control-theme text-capitalize" 
                                                placeholder="Class" onChange={e => handleEditChange(e)} value={menuDropData.class}>
                                                    <option value="">Select Class</option>
                                                    {insertClassItemOption}
                                                </select>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Class Block </label><input required name="class_block" onChange={e => handleEditChange(e)} 
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Class Block" value={menuDropData.class_block} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                       
                    </GridContent>
                </Grid>   
            </ComponentWrapper>
        </>
    )
}

export default Classes;