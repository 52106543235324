import React from "react";

import { Wrapper } from './style';

const Box = ({children, outer_classname=""}) => {
    return(
        <Wrapper className={outer_classname}>
            {children}
        </Wrapper>
    )
}

export default Box;