import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
// import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import CountDownTimer from 'components/app/school/SmallerComponents/countDownTimer';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import { HeadComponent } from "components/head";
import { BACKEND_URL } from 'actions/url';
import { appServices5, appServices4 } from 'api/schoolApp/services';

import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';
import { addItemToArray} from 'components/app/school/actions/array-utils/Add';
import { updateArray } from 'components/app/school/actions/array-utils/Update';
import { timify } from 'components/app/school/actions/timify';
import { numberDigit } from 'components/app/school/actions/numberDigit';
import Note from 'components/app/school/SmallerComponents/NOTE';


const StaffSchoolExamSubjectSupervision = ({school_id, school, user_id, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialMalletState = useMemo(() => ({id:0, curr_sess_id:0, curr_sess:'', curr_term:'', session:'', is_upload_exam_timetable:false, term:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    
    const [malletState, setMalletState] = useState(initialMalletState);
    const [examTodayState, setExamTodayState] = useState({id:0});
    const [menuDropData, setMenuDropData] = useState({id:0});

    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allStudReviewData, setAllStudReviewData] = useState([]);
    const [allExamTTReviewData, setAllExamTTReviewData] = useState([]);
    const [allExamTTData, setAllExamTTData] = useState([]);
    const [allExamTTTodayData, setAllExamTTTodayData] = useState([]);
    const [allStudAttend, setAllStudAttend] = useState([]);
    const [allClasses, setAllClasses] = useState([]);

    const [navigate, setNavigate] = useState('');
    const [etcl, setEtcl] = useState('');
    const [currentTime, setCurrentTime] = useState(0);

    const [preloading, setPreloading] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewAllTT, setViewAllTT] = useState(false);
    const [reload, setReload] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);
    const [examTodayStateLoading, setExamTodayStateLoading] = useState(false);
    const [examSupervisionStateLoading, setExamSupervisionStateLoading] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [isStartTimer, setIsStartTimer] = useState(true);

    const [searchParam] = useSearchParams()

    const nav = useNavigate()

    const fetchAllClassSection = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices5.getAllClassSectionSchool(sid).then(res => {
                setAllClassSectionData(res.data);
            }).catch(e => {
                setAllClassSectionData([]);
                setError({data:["Internal Server Error"], title:"Class Section Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchSchoolSession = (sid) => {
        if(sid){
            setError({data:[], title:""});
            appServices5.getCurrentSession(sid).then(res => {
                setMalletState({...res.data, curr_sess:res.data.session, curr_sess_id:res.data.id, curr_term:res.data.term_name, is_upload_exam_timetable:res.data.is_upload_exam_timetable});
                setDataTableLoading(true);
            }).catch(e => {
                setMalletState({id:0, session:'', term:''});
                setError({title:'School Session Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllExamTT = (pre=true, sid, stid) => {
        if(sid && stid){
            setError({data:[], title:''});
            setPreloading(pre);
            setSpinLoading(!pre);
            appServices4.getAllSchoolStaffExamTimeTableSupervision(sid, stid).then(res => {
                setPreloading(false);
                setSpinLoading(false);
                setAllExamTTData(res.data.sort((a, b) => new Date(a.exam_date).getTime() - new Date(b.exam_date).getTime()));
            }).catch(e => {
                setAllExamTTData([]);
                setPreloading(false);
                setSpinLoading(false);
                setError({data:["Internal Server Error"], title:"Exam Timetable Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllExamTTSupervise = (sid, stid) => {
        if(sid && stid){
            appServices4.getAllSchoolStaffExamTimeTableSupervisionRevision(sid, stid).then(res => {
                setAllExamTTReviewData(res.data.sort((a, b) => new Date(a.exam_date).getTime() - new Date(b.exam_date).getTime()));
            }).catch(e => {
                setAllExamTTData([]);
            })
        }
    }
    const fetchAllStudReview = (sid, stid) => {
        if(sid && stid){
            setLoading(true);
            appServices4.getAllSchoolStaffExamTimeTableSupervisionStudentReview(sid, stid).then(res => {
                setAllStudReviewData(res.data);
                setLoading(false);
            }).catch(e => {
                setNavigate('../exam/subject/supervision');
                setAllStudAttend([]);
                setAllStudReviewData([]);
                setLoading(false);
            })
        }
    }
    const fetchAllExamTTToday = (sid, stid) => {
        if(sid && stid){
            setError({data:[], title:''});
            appServices4.getAllSchoolStaffExamTimeTableSupervisionToday(sid, stid).then(res => {
                setAllExamTTTodayData(addItemToArray(res.data));
            }).catch(e => {
                setAllExamTTTodayData([]);
                setViewAllTT(true);
                setError({data:["Something Went Wrong"], title:"Exam Timetable Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchExamAccess = (pk, stid, sid) => {
        if(pk && stid){
            setError({data:[], title:''});
            appServices4.getSchoolStaffExamTimeTableSupervisionToday(pk, stid, sid).then(res => {
                setExamTodayState(res.data);
            }).catch(e => {
                setExamTodayState({id:0});
                setNavigate('../exam/subject/supervision');
                setAllStudAttend([]);
                setAllStudReviewData([]);
                setError({data:["Something Went Wrong"], title:"Exam Access Data Fetch Error"});
            })
        }
    }
    const fetchExamRevisionAccess = (pk, stid, sid) => {
        if(pk && stid){
            setError({data:[], title:''});
            setAllStudReviewData([]);
            setExamSupervisionStateLoading(false);
            setLoading(true);
            appServices4.getSchoolStaffExamTimeTableSupervisionRevision(pk, stid, sid).then(res => {
                console.log(res.data);
                setExamTodayState({...res.data, is_review:true});
                appServices5.initializeStudentExamSitNoMain(sid, res.data.etts_uid).then(res4 => {
                    appServices4.getAllSchoolStaffExamTimeTableSupervisionStudentReview(res.data.uid, stid).then(res2 => {
                        setAllStudReviewData(res2.data);
                        setLoading(false);
                    }).catch(e => {
                        setNavigate('../exam/subject/supervision');
                        setAllStudAttend([]);
                        setAllStudReviewData([]);
                        setLoading(false);
                        setExamSupervisionStateLoading(true);
                    })
                }).catch( e => {
                    setNavigate('../exam/subject/supervision');
                    setAllStudAttend([]);
                    setAllStudReviewData([]);
                    setLoading(false);
                    setExamSupervisionStateLoading(true);
                })
            }).catch(e => {
                setExamTodayState({id:0});
                setLoading(false);
                setError({data:["Something Went Wrong"], title:"Exam Access Data Fetch Error"});
                setExamSupervisionStateLoading(true);
            })
        }
    }
    const fetchAllStud = (sid, stid) => {
        if(sid && stid){
            setError({data:[], title:''});
            setLoading(true);
            appServices4.getAllSchoolStaffExamTimeTableSupervisionStudentToday(sid, stid).then(res => {
                setAllStudAttend(res.data);
                setLoading(false);
            }).catch(e => {
                setAllStudAttend([]);
                setLoading(false);
                setError({data:["Something Went Wrong"], title:"All Student Data Fetch Error"});
            })
        }
    }
    const fetchAllClasses = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices5.getAllClassSchoolItem(sid).then(res => {
                setAllClasses(res.data);
            }).catch(e => {
                setAllClasses([]);
                setError({data:["Internal Server Error"], title:"Classes Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const handleMenuChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }
    const handleClickAct = (type="", uid="") => {
        if(type === "start"){
            setNavigate(`?et=${uid}`);
            setExamTodayStateLoading(true);
            setIsReview(false);
            setIsStartTimer(true);
            return;
        }
        if(type === "review"){
            setNavigate(`?et=${uid}`);
            setExamSupervisionStateLoading(true);
            setIsStartTimer(false);
            setLoading(true);
            setIsReview(true);
            return;
        }
    }
    const handleClickStat = (data={}, id="", notification="", notifyTitle='Is this student not present?', notifyText='Please confirm if the student is not present before you proceed!') => {
        if(notification === "warning"){
            setWarning({id:0, initialSuccessState});
            setTimeout(() => {
                setWarning({id:1000, title:notifyTitle, text:notifyText, click_text:'Yes', handleClick:() => {
                    setWarning(initialSuccessState);
                    setError(initialErrorState);
                    setSuccess(initialSuccessState);
                    setLoading(true);
                    appServices4.updateSchoolStaffExamTimeTableSupervisionStudentStatToday(data, id, searchParam.get('et'), access.uid).then(res => {
                        setLoading(false);
                        setSuccess({title:'Success', text:'Status Saved Successfully'})
                        fetchAllStud(searchParam.get('et'), access.uid)
                        setMenuLoading(false);
                        setMenuDrop(false);
                        setMenuDropData({id:'', type:''});
                    }).catch(e => {
                        setError({title:'Status Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
                        setLoading(false);
                        setMenuLoading(false);
                    })
                }})
            })
            return;
        }
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        appServices4.updateSchoolStaffExamTimeTableSupervisionStudentStatToday(data, id, searchParam.get('et'), access.uid).then(res => {
            setLoading(false);
            setSuccess({title:'Success', text:'Status Saved Successfully'})
            fetchAllStud(searchParam.get('et'), access.uid)
        }).catch(e => {
            setError({title:'Status Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
            setLoading(false);
        })
        return;
    }
    const handleClickStatReview = (data={}, id="", notification="", notifyTitle='Was this student not present?', notifyText='Please confirm if the student was not present before you proceed!') => {
        if(notification === "warning"){
            setWarning({id:0, initialSuccessState});
            setTimeout(() => {
                setWarning({id:1000, title:notifyTitle, text:notifyText, click_text:'Yes', handleClick:() => {
                    setWarning(initialSuccessState);
                    setError(initialErrorState);
                    setSuccess(initialSuccessState);
                    setLoading(true);
                    appServices4.updateSchoolStaffExamTimeTableSupervisionStudentStatReview(data, id, searchParam.get('et'), access.uid).then(res => {
                        setLoading(false);
                        setSuccess({title:'Success', text:'Status Saved Successfully'})
                        fetchAllStudReview(searchParam.get('et'), access.uid)
                        setMenuLoading(false);
                        setMenuDrop(false);
                        setMenuDropData({id:'', type:''});
                    }).catch(e => {
                        setError({title:'Status Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
                        setLoading(false);
                        setMenuLoading(false);
                    })
                }})
            })
            return;
        }
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        appServices4.updateSchoolStaffExamTimeTableSupervisionStudentStatReview(data, id, searchParam.get('et'), access.uid).then(res => {
            setLoading(false);
            setSuccess({title:'Success', text:'Status Saved Successfully'})
            fetchAllStudReview(searchParam.get('et'), access.uid);
        }).catch(e => {
            setError({title:'Status Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
            setLoading(false);
        })
        return;
    }

    const onEndReview = (pk="", stid, data={}) => {
        if(examTodayState.id){
            setError(initialErrorState);
            setSuccess(initialSuccessState);
            setLoading(true);
            appServices4.updateSchoolStaffExamTimeTableSubjectReview(data, pk, stid).then(res => {
                setLoading(false);
                setSuccess({title:'Success', text:'Review Submitted Successfully'});
                setNavigate('../exam/subject/supervision');
                fetchAllExamTTSupervise(school_id, stid);
            }).catch(e => {
                setError({title:'Status Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
                setLoading(false);
                fetchExamRevisionAccess(searchParam.get('et'), stid, school_id);
            })
        }
        
    }

    const handleUpdateSETT = useCallback((pk="", stid, data={}, success_msg="", att_item={}) => {
        if(pk && stid){
            setError({data:[], title:''});
            setLoading(true);
            appServices4.updateSchoolStaffExamTimeTableSubject(data, pk, stid).then(res => {
                setLoading(false);
                setSuccess({title:'Success', text:success_msg})
                setExamTodayState((prev) => prev.id ? {...prev, ...res.data} : res.data);
                setAllExamTTTodayData((prev) => prev.length > 0 ? updateArray(prev, att_item.num, {...att_item, ...res.data}, 'num') : [...prev]);
            }).catch(e => {
                setError({title:'Status Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
                setLoading(false);
                setExamTodayState((prev) => data.is_start_exam ? {...prev, is_start_exam_error:true} : {...prev, is_end_exam_error:true})
                fetchExamAccess(searchParam.get('et'), stid, school_id);
            })
        }
    }, [searchParam, school_id])
    const onSubmitMisconduct = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        setWarning({id:0, initialSuccessState});
        handleClickStat({is_misconducted:true, is_done:true, misconduct:menuDropData.misconduct, mark_pun:menuDropData.mark_pun, misconducted_by:access.id}, menuDropData.id, "warning", 'Are You Sure?', 'This action cannot be reverted!')
    }

    useEffect(() => {
        fetchAllClassSection(school_id);
        fetchSchoolSession(school_id);
        fetchAllClasses(school_id);
    }, [school_id]);

    useEffect(() => {
        if(reload){
            fetchAllClassSection(school_id);
            fetchSchoolSession(school_id);
            fetchAllClasses(school_id);
            setReload(false);
        }
        if(dataTableLoading && access.uid){
            setDataTableLoading(false);
            fetchAllExamTT(malletState.pre, school_id, access.uid);
            fetchAllExamTTToday(school_id, access.uid);
            fetchAllExamTTSupervise(school_id, access.uid);
            // fetchAllStudReview(school_id, access.uid);
        }
    }, [dataTableLoading, reload,  school_id, access.uid, malletState])
    useEffect(() => {  
        if(searchParam.get('et') && access.uid && !examTodayState.id && !examTodayStateLoading && !isReview){
            fetchExamAccess(searchParam.get('et'), access.uid, school_id);
            fetchAllStud(searchParam.get('et'), access.uid);
        }
        if(searchParam.get('et') && access.uid && examTodayStateLoading){
            fetchExamAccess(searchParam.get('et'), access.uid, school_id);
            fetchAllStud(searchParam.get('et'), access.uid);
            setExamTodayState({id:0});
            setExamTodayStateLoading(false);
        }
        if(searchParam.get('et') && access.uid && examSupervisionStateLoading){
            fetchExamRevisionAccess(searchParam.get('et'), access.uid, school_id);
            // fetchAllStudReview(searchParam.get('et'), access.uid);
            setExamTodayState({id:0});
            setExamSupervisionStateLoading(false);
        }
        // if(searchParam.get('et') && access.uid && !examTodayState.id && !examSupervisionStateLoading && isReview){
        //     fetchExamRevisionAccess(searchParam.get('et'), access.uid, school_id);
        //     // fetchAllStudReview(searchParam.get('et'), access.uid);
        // }
        if(!searchParam.get('et') && examTodayState.id){
            setExamTodayState({id:0});
            setExamTodayStateLoading(false);
        }

    }, [school_id, access.uid, searchParam, examTodayState, examTodayStateLoading, examSupervisionStateLoading, isReview]);

    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [navigate, nav])
    
    useEffect(() => {
        if(isStartTimer){
            const timer = setInterval(() => {
                setCurrentTime(new Date().getTime());
            }, 1000)
            return () => clearInterval(timer);
        }
    }, [isStartTimer])
    
    useEffect(() => {
        if(examTodayState.id && !isReview){
            const t_exam_start_time = new Date(examTodayState.exam_date).setHours(parseInt(examTodayState.start_time.slice(0, 2)), examTodayState.start_time.slice(3, 5));
            const t_exam_end_time = new Date(examTodayState.exam_date).setHours(parseInt(examTodayState.end_time.slice(0, 2)), examTodayState.end_time.slice(3, 5));
            if(currentTime > t_exam_start_time && !examTodayState.is_start_exam && !examTodayState.is_start_exam_error){
                handleUpdateSETT(examTodayState.etts_uid, access.uid, {is_start_exam:true, started_by:access.id}, `Currently Undergoing ${examTodayState.subject_name} Exam`, allExamTTTodayData.filter((it) => it.id === examTodayState.id)[0])
            }
            if(currentTime > t_exam_end_time && !examTodayState.is_end_exam && !examTodayState.is_end_exam_error){
                handleUpdateSETT(examTodayState.etts_uid, access.uid, {is_end_exam:true, ended_by:access.id}, `${examTodayState.subject_name} exam session has been ended successfully`, allExamTTTodayData.filter((it) => it.id === examTodayState.id)[0])
            }
            return
        }
    }, [examTodayState, currentTime, access, allExamTTTodayData, handleUpdateSETT, isReview])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const t60 = examTodayState.id ? new Date(examTodayState.exam_date).setHours(parseInt(examTodayState.start_time.slice(0, 2))-1, examTodayState.start_time.slice(3, 5)) : 0;
    const t_exam_start_time = examTodayState.id ? new Date(examTodayState.exam_date).setHours(parseInt(examTodayState.start_time.slice(0, 2)), examTodayState.start_time.slice(3, 5)) : 0;
    const t_exam_end_time = examTodayState.id ? new Date(examTodayState.exam_date).setHours(parseInt(examTodayState.end_time.slice(0, 2)), examTodayState.end_time.slice(3, 5)) : 0;
    const t_exam_start_time_ptih =  examTodayState.id ? t_exam_start_time + ((examTodayState.time_interval / 2) * 60 * 60 * 1000) : 0

    const countdown_time = t_exam_end_time > currentTime ? t_exam_end_time - currentTime : 0;

    console.log(allExamTTReviewData, allStudReviewData);

    const insertTimeTableTodayData = () => {
        return (
            <>
            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med '> SUBJECT(S) TO SUPERVISE TODAY</span></div>
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH>DATE</TH>
                            <TH><b>Time &</b> <br /> Subject</TH>
                            <TH>Act</TH>
                        </TR>
                    </thead>
                    <tbody>
                    {allExamTTTodayData.map((item_j, j) => {
                        const stat = () => {
                            if(item_j.is_start_exam && item_j.is_end_exam){
                                return(
                                    <button type="button" style={{padding:'3px', cursor:'not-allowed', opacity:.4}} className='btn bg-green disabled br font-super-small app-bg-text-color' disabled>Done</button>
                                )
                            }
                            else if(item_j.is_start_exam && !item_j.is_end_exam){
                                return(
                                    <button type="button" style={{padding:'3px'}} className='btn bg-yellow hover-opacity br font-super-small app-bg-text-color' onClick={() => handleClickAct("start", item_j.uid)}>Continue</button>
                                )
                            }
                            else{
                                return(
                                    <button type="button" style={{padding:'3px'}} className='btn bg-blue hover-opacity br font-super-small app-bg-text-color' onClick={() => handleClickAct("start", item_j.uid)}>Check</button>
                                )
                            }
                        }
                        return(
                            <Fragment key={j}>
                                <TR>
                                    <TD>
                                        <span className="font-very-small fw-700 app-text-color">Today</span>
                                    </TD>
                                    <TD>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-600 app-text-color'>{item_j.class_section_name.toUpperCase()}</span>
                                        </div>
                                        <div className='disp-block' style={{cursor:'default'}} >
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.start_time)}</code>
                                            <span className='font-small disp-inline'>-</span>
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.end_time)}</code>
                                        </div>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-500 app-text-color'>{item_j.subject_name}</span>
                                        </div>
                                    </TD>
                                    <TD>
                                        {stat()}
                                    </TD>
                                </TR>
                            </Fragment>
                        )
                    })}
                    </tbody>
                </TABLE>
            </div>
                
            </>
        )
    }
    const insertTimeTableToSuperviseData = () => {
        return (
            <>
            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med '> REVIEW EXAM</span></div>
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH>DATE</TH>
                            <TH><b>Time &</b> <br /> Subject</TH>
                            <TH>Act</TH>
                        </TR>
                    </thead>
                    <tbody>
                    {allExamTTReviewData.map((item_j, j) => {
                        return(
                            <Fragment key={j}>
                                <TR>
                                    <TD>
                                        <span className="font-super-small fw-700 disp-block" >{item_j.day_name && `${item_j.day_name.slice(0, 3)},` }</span><span className="font-very-small fw-700 app-text-color">{convertDate(new Date(item_j.exam_date), 'ddMMyyyy') }</span>
                                    </TD>
                                    <TD>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-600 app-text-color'>{item_j.class_section_name.toUpperCase()}</span>
                                        </div>
                                        <div className='disp-block' style={{cursor:'default'}} >
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.start_time)}</code>
                                            <span className='font-small disp-inline'>-</span>
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.end_time)}</code>
                                        </div>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-500 app-text-color'>{item_j.subject_name}</span>
                                        </div>
                                    </TD>
                                    <TD>
                                    <button type="button" style={{padding:'3px'}} className='btn bg-blue hover-opacity br font-super-small app-bg-text-color' onClick={() => handleClickAct("review", item_j.uid)}>Review</button>
                                    </TD>
                                </TR>
                            </Fragment>
                        )
                    })}
                    </tbody>
                </TABLE>
            </div>
                
            </>
        )
    }
    const insertMinorTimeTableTodayData = (filt_id=0) => {
        return (
            <>
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH style={{width:'90%'}}><b>Time &</b> <br /> Subject</TH>
                            <TH>Act</TH>
                        </TR>
                    </thead>
                    <tbody>
                    {allExamTTTodayData.filter((it) => it.id !== filt_id).map((item_j, j) => {
                        const stat = () => {
                            if(item_j.is_start_exam && item_j.is_end_exam){
                                return(
                                    <button type="button" style={{padding:'3px', cursor:'not-allowed', opacity:.4}} className='btn bg-green disabled br font-super-small app-bg-text-color' disabled>Done</button>
                                )
                            }
                            else if(item_j.is_start_exam && !item_j.is_end_exam){
                                return(
                                    <button type="button" style={{padding:'3px'}} className='btn bg-yellow hover-opacity br font-super-small app-bg-text-color' onClick={() => handleClickAct("start", item_j.uid)}>Continue</button>
                                )
                            }
                            else{
                                return(
                                    <button type="button" style={{padding:'3px'}} className='btn bg-blue hover-opacity br font-super-small app-bg-text-color' onClick={() => handleClickAct("start", item_j.uid)}>Check</button>
                                )
                            }
                        }
                        return(
                            <Fragment key={j}>
                                <TR>
                                    <TD>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-600 app-text-color'>{item_j.class_section_name.toUpperCase()}</span>
                                        </div>
                                        <div className='disp-block' style={{cursor:'default'}} >
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.start_time)}</code>
                                            <span className='font-small disp-inline'>-</span>
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.end_time)}</code>
                                        </div>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-500 app-text-color'>{item_j.subject_name}</span>
                                        </div>
                                    </TD>
                                    <TD>
                                        {stat()}
                                    </TD>
                                </TR>
                            </Fragment>
                        )
                    })}
                    {allExamTTReviewData.filter((it) => it.id !== filt_id).map((item_j, j) => {
                        return(
                            <Fragment key={j}>
                                <TR>
                                    <TD>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-600 app-text-color'>{item_j.class_section_name.toUpperCase()}</span>
                                        </div>
                                        <div className='disp-block' style={{cursor:'default'}} >
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.start_time)}</code>
                                            <span className='font-small disp-inline'>-</span>
                                            <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.end_time)}</code>
                                        </div>
                                        <div className='disp-block'>
                                            <span className='font-super-small fw-500 app-text-color'>{item_j.subject_name}</span>
                                        </div>
                                    </TD>
                                    <TD>
                                        <button type="button" style={{padding:'3px'}} className='btn bg-blue hover-opacity br font-super-small app-bg-text-color' onClick={() => handleClickAct("review", item_j.uid)}>Review</button>
                                    </TD>
                                </TR>
                            </Fragment>
                        )
                    })}
                    </tbody>
                </TABLE>
            </div>
                
            </>
        )
    }
    const insertTimeTableData = () => {
        return (
            <>
            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med '> ALL SUBJECT(S) SUPERVISION</span></div>
            {allClassSectionData.map((class_item, i) => {
                const filterExamTimeItem = allExamTTData.filter((item) => {
                    return item.class_section === class_item.id;
                })
                return(
                    <Fragment key={i}>
                        <div className='flex-100 align-center padd-4px'><span className='app-text-color fw-700 font-small '>{class_item.name.toUpperCase() } SECTION</span></div>
                        <div className='flex-100' style={{width:'0'}}>
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH>DATE</TH>
                                    <TH><b>Time &</b> <br /> Subject</TH>
                                    <TH>Stat</TH>
                                </TR>
                            </thead>
                            <tbody>
                            {filterExamTimeItem.map((item_j, j) => {
                                const stat = () => {
                                    if(item_j.is_start_exam && item_j.is_end_exam) {
                                        return(
                                            <i style={{borderRadius:'10px', padding:'3px', color:'#eee', cursor:'text'}} className='fas fa-check bg-green font-very-small'></i>
                                        )
                                    } else {
                                        return(
                                            <i style={{borderRadius:'10px', padding:'3px', color:'#eee', cursor:'text'}} className='fas fa-minus bg-yellow font-very-small'></i>
                                        )
                                    }
                                }
                                return(
                                    <Fragment key={j}>
                                        <TR>
                                            <TD>
                                                <span className="font-super-small fw-700 disp-block" >{item_j.day_name && `${item_j.day_name.slice(0, 3)},` }</span><span className="font-very-small fw-700 app-text-color">{convertDate(new Date(item_j.exam_date), 'ddMMyyyy') }</span>
                                            </TD>
                                            <TD>
                                                <div className='disp-block' style={{cursor:'default'}} >
                                                    <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.start_time)}</code>
                                                    <span className='font-small disp-inline'>-</span>
                                                    <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_j.end_time)}</code>
                                                </div>
                                                <div className='disp-block'>
                                                    <span className='font-super-small fw-500 app-text-color'>{item_j.subject_name}</span>
                                                </div>
                                            </TD>
                                            <TD>
                                                {stat()}
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                            </tbody>
                        </TABLE>
                        </div>
                    </Fragment>
                )
            })}
            </>
        )
    }

    // const insertTimeTableData = () => {
    //     return (
    //         allClassSectionData.map((class_item, i) => {
    //             const filterExamTimeItem = allExamTTData.filter((item) => {
    //                 return item.class_section === class_item.id;
    //             })
    //             return(
    //                 <Fragment key={i}>
    //                     <div className='flex-100 align-center padd-4px'><span className='app-text-color fw-700 font-small '>{class_item.name.toUpperCase() } SECTION</span></div>
    //                     <div className='flex-100' style={{width:'0'}}>
    //                     <TABLE>
    //                         <thead>
    //                             <TR>
    //                                 <TH>DATE</TH>
    //                                 <TH><b>Time &</b> <br /> Subject</TH>
    //                             </TR>
    //                         </thead>
    //                         <tbody>
    //                         {filterExamTimeItem.map((item_j, j) => {
    //                             return(
    //                                 <Fragment key={j}>
    //                                     <TR>
    //                                         <TD>
    //                                             <span className="font-super-small fw-700 disp-block" >{item_j.day_name && `${item_j.day_name.slice(0, 3)},` }</span><span className="font-very-small fw-700 blue"  style={{cursor:'pointer'}}>{convertDate(new Date(item_j.exam_date), 'ddMMyyyy') }</span>
    //                                         </TD>
    //                                         <TD>
    //                                             <TABLE>
    //                                                 <tbody>
    //                                                     <TR>
    //                                                     {item_j.time.length > 0 && (
    //                                                         <>
    //                                                             {item_j.time.map((item_k, k) => {
    //                                                                 return(
    //                                                                     <Fragment key={k}>                               
    //                                                                         <TD>
                                                                            
    //                                                                             <div className='disp-block' style={{cursor:'default'}} >
    //                                                                                 <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_k.start_time)}</code>
    //                                                                                 <span className='font-small disp-inline'>-</span>
    //                                                                                 <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_k.end_time)}</code>
    //                                                                             </div>
    //                                                                             <div className='disp-block'>
    //                                                                                 <span className='font-super-small fw-500 app-text-color'>{item_k.subject_name}</span>
    //                                                                             </div>
                                                                            
    //                                                                             <table>
    //                                                                                 <tbody>
    //                                                                                     <TR>
                                                                                                
    //                                                                                         {item_k.subjects.map((item_l, l) => {
    //                                                                                             return(
    //                                                                                                 <Fragment key={l}>
    //                                                                                                     <TD>
    //                                                                                                         <div className='disp-block'>
    //                                                                                                             <span className='font-super-small app-text-color'>{item_l.name}</span>
    //                                                                                                         </div>
    //                                                                                                         <div className='disp-block' style={{marginTop:'4px'}}>                     
    //                                                                                                             <span className='fw-700 disp-block font-super-small app-text-sec-color'>Supervisor(s)</span>
    //                                                                                                             {item_l.supervisor.length > 0 && (
    //                                                                                                                 <>
    //                                                                                                                     {item_l.supervisor.map((item_m, m) => {
    //                                                                                                                         return(
    //                                                                                                                             <Fragment key={m}>
    //                                                                                                                                 <span className='font-super-small app-text-color'>{item_m.staff_last_name} {item_m.staff_first_name}</span>    
    //                                                                                                                             </Fragment>
    //                                                                                                                         )
    //                                                                                                                     })}
                                                                                                                            
    //                                                                                                                 </>
    //                                                                                                             )}
                                                                                        
    //                                                                                                         </div>
    //                                                                                                     </TD>
    //                                                                                                     {item_k.subjects.length > 0 && (<>{item_k.subjects.length - 1 !== l && (<TD><span className='font-very-small fw-600'>/</span></TD>)}</>) }
    //                                                                                                 </Fragment>
    //                                                                                             )
    //                                                                                         })}
                                                                                                        
    //                                                                                     </TR>
    //                                                                                 </tbody>
    //                                                                             </table>
    //                                                                         </TD>
    //                                                                     </Fragment>
    //                                                                 )
    //                                                             })}
    //                                                         </>
    //                                                     )}
                                                    
    //                                                     </TR>
    //                                                 </tbody>
    //                                             </TABLE>
    //                                         </TD>
    //                                     </TR>
    //                                 </Fragment>
    //                             )
    //                         })}
    //                         </tbody>
    //                     </TABLE>
    //                     </div>
    //                 </Fragment>
    //             )
    //         })
    //     )
    // }
    const insertMainStudData = () => {
        const class_id = etcl ? etcl : allClasses.filter((item) => item.class_section_school === examTodayState.class_section)[0].id;
        const stud_filter_present = allStudAttend.filter((it) => it.classes === class_id && it.is_present === true);
        const stud_filter = allStudAttend.filter((it) => it.classes === class_id && it.is_present === false );
        // const class_item = etcl ? allClasses.filter((item) => item.id === etcl)[0] : allClasses.filter((item) => item.id === class_id)[0];
        const class_filter = allClasses.filter((it) => it.class_section_school === examTodayState.class_section)
        const ins = stud_filter_present.map((item, i) => {
            i++;
            const full_name = `${item.last_name ? item.last_name : item.user_last_name} ${item.first_name ? item.first_name : item.user_first_name}`;
            const stat = () => {
                if(item.is_present){
                    return(
                        <>
                            {item.is_done && <><span className="fw-600 green font-very-small align-center"><i className="fas fa-check"></i><i className="fas fa-check"></i></span><br /></>}
                            <span className="font-very-small text-uppercase align-center green fw-600">PRESENT</span>
                            <br /><span className="fw-600 app-text-sec-color font-very-small"><i>{item.is_present_before_start ? 'Came Early' : 'Came Late'}</i></span>
                            {item.is_misconducted && <><br /><span className="fw-700 red font-very-small">This Student Has Been Caught On Misconduct (-{parseInt(item.mark_pun)} mark reduced)</span></>}
                        </>
                    )
                }
                return(
                    <span className="font-very-small text-uppercase align-center fw-700" style={{color:'rgb(255, 255, 40)'}}>PENDING</span>
                )
            }
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} dlt_btn={false} done_btn={currentTime > t_exam_start_time_ptih ? true : false} btn={!item.is_done} check_btn={!item.is_present} check_text="Tick" handleDoneClick={() =>  handleClickStat({is_done:true, time_done: new Date()}, item.uid, 'warning', 'Is this student done with this exam?', 'Action cannot be reverted, so please make sure the student is done with this exam before proceeding!')} handleCheckClick={() => handleClickStat({is_present:true, is_late:true, time_present:new Date()}, item.uid)} handleTimesClick={() => handleClickStat({is_present:false, is_late:false, is_present_before_start:false, time_present:null}, item.uid, 'warning')} times_btn={item.is_present} upgrade_btn={!item.is_misconducted} upgrade_text='Misconduct' handleUpgradeClick={() => {setMenuDrop(true); setMenuDropData({type:'misconduct', id:item.uid, name:full_name, adm_no:item.adm_no, misconduct:'', mark_pun:''})}}>
                        <div className="c-data sn"><span className="font-very-small">{numberDigit(item.sit_no, 3)}</span></div>
                        <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic ? item.profile_pic : item.user_profile_pic}`} style={{width:'50px', height:'45px'}} alt="Profile" /></div> <span className="font-very-small text-capitalize">{numberDigit(item.adm_no, 4)} <br /> {full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                        <div className="c-data">{stat()}</div>
                    </Tr>
                </Fragment>
            )
        }) 
        const inss = stud_filter.map((item, i) => {
            i++;
            const full_name = `${item.last_name ? item.last_name : item.user_last_name} ${item.first_name ? item.first_name : item.user_first_name}`;
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} dlt_btn={false} check_btn={!item.is_present} check_text="Tick" handleCheckClick={() => handleClickStat({is_present:true, is_late:true, time_present:new Date()}, item.uid)}>
                        <div className="c-data sn"><span className="font-very-small">{numberDigit(item.sit_no, 3)}</span></div>
                        <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic ? item.profile_pic : item.user_profile_pic}`} style={{width:'50px', height:'45px'}} alt="Profile" /></div> <span className="font-very-small text-capitalize">{numberDigit(item.adm_no, 4)} <br /> {full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                        <div className="c-data"><span className="font-very-small text-uppercase align-center fw-700" style={{color:'rgb(255, 255, 40)'}}>PENDING</span></div>
                    </Tr>
                </Fragment>
            )
        })
        return(
            <>
            <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
            {class_filter.map((item_k, k) => {
                const class_nme = etcl ? (item_k.id === etcl ? 'fw-600 font-med blue padd-5px blue btn bordered-grey  hover-opacity br' : 'fw-600 app-text-color font-med padd-5px btn bordered-grey  hover-opacity br') : (class_filter[0].id === item_k.id ? 'fw-600 font-med blue padd-5px btn  bordered-grey  hover-opacity br' : 'fw-600 app-text-color font-med padd-5px btn  bordered-grey hover-opacity br')
                return(
                    <Fragment key={k}>
                        <button type="button" className={class_nme} onClick={() => setEtcl(item_k.id)}>{item_k.name}</button>
                    </Fragment>
                )
            })}
            </div>
            <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                <span className='fw-600 font-med app-text-color'>All Present Student(s)</span>
            </div>
            <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"Sit No"}, {text:"Adm No / Full Name"}, {text:"Status"}]} />
            {stud_filter_present.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                ins      
            }
            {stud_filter.length > 0 && (
                <>
                    <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                        <span className='fw-600 font-med app-text-color'>All Pending Student(s)</span>
                    </div>
                    <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"Sit No"}, {text:"Adm No / Full Name"}, {text:"Status"}]} />
                        {stud_filter.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                            inss      
                        }
                </>
            )}
            
            </>
        )

    }

    const insertMainStudReviewData = () => {
        const class_id = etcl ? etcl : allClasses.filter((item) => item.class_section_school === examTodayState.class_section)[0].id;
        const stud_filter_present = allStudReviewData.filter((it) => it.classes === class_id && it.is_present === true);
        const stud_filter = allStudReviewData.filter((it) => it.classes === class_id && it.is_present === false );
        // const class_item = etcl ? allClasses.filter((item) => item.id === etcl)[0] : allClasses.filter((item) => item.id === class_id)[0];
        const class_filter = allClasses.filter((it) => it.class_section_school === examTodayState.class_section)
        const ins = stud_filter_present.map((item, i) => {
            i++;
            const full_name = `${item.last_name ? item.last_name : item.user_last_name} ${item.first_name ? item.first_name : item.user_first_name}`;
            const stat = () => {
                if(item.is_present){
                    return(
                        <>
                            {item.is_done && <><span className="fw-600 green font-very-small align-center"><i className="fas fa-check"></i><i className="fas fa-check"></i></span><br /></>}
                            <span className="font-very-small text-uppercase align-center green fw-600">PRESENT</span>
                            <br /><span className="fw-600 app-text-sec-color font-very-small"><i>{item.is_present_before_start ? 'Came Early' : 'Came Late'}</i></span>
                            {item.is_misconducted && <><br /><span className="fw-700 red font-very-small">This Student Has Been Caught On Misconduct (-{parseInt(item.mark_pun)} mark reduced)</span></>}
                        </>
                    )
                }
                return(
                    <span className="font-very-small text-uppercase align-center fw-700" style={{color:'rgb(255, 255, 40)'}}>PENDING</span>
                )
            }
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} dlt_btn={false} btn={true} check_btn={!item.is_present} check_text="Tick" handleCheckClick={() => handleClickStatReview({is_present:true, is_late:false, time_present:null}, item.uid)} handleTimesClick={() => handleClickStatReview({is_present:false, is_late:false, is_present_before_start:false, time_present:null}, item.uid, 'warning')} times_btn={item.is_present}>
                        <div className="c-data sn"><span className="font-very-small">{numberDigit(item.sit_no, 3)}</span></div>
                        <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic ? item.profile_pic : item.user_profile_pic}`} style={{width:'50px', height:'45px'}} alt="Profile" /></div> <span className="font-very-small text-capitalize">{numberDigit(item.adm_no, 4)} <br /> {full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                        <div className="c-data">{stat()}</div>
                    </Tr>
                </Fragment>
            )
        }) 
        const inss = stud_filter.map((item, i) => {
            i++;
            const full_name = `${item.last_name ? item.last_name : item.user_last_name} ${item.first_name ? item.first_name : item.user_first_name}`;
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} dlt_btn={false} check_btn={!item.is_present} check_text="Tick" handleCheckClick={() => handleClickStatReview({is_present:true, is_done:true, is_late:false, time_present:null}, item.uid)}>
                        <div className="c-data sn"><span className="font-very-small">{numberDigit(item.sit_no, 3)}</span></div>
                        <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic ? item.profile_pic : item.user_profile_pic}`} style={{width:'50px', height:'45px'}} alt="Profile" /></div> <span className="font-very-small text-capitalize">{numberDigit(item.adm_no, 4)} <br /> {full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                        <div className="c-data"><span className="font-very-small text-uppercase align-center fw-700" style={{color:'rgb(255, 255, 40)'}}>PENDING</span></div>
                    </Tr>
                </Fragment>
            )
        })
        return(
            <>
            <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
            {class_filter.map((item_k, k) => {
                const class_nme = etcl ? (item_k.id === etcl ? 'fw-600 font-med blue padd-5px blue btn bordered-grey  hover-opacity br' : 'fw-600 app-text-color font-med padd-5px btn bordered-grey  hover-opacity br') : (class_filter[0].id === item_k.id ? 'fw-600 font-med blue padd-5px btn  bordered-grey  hover-opacity br' : 'fw-600 app-text-color font-med padd-5px btn  bordered-grey hover-opacity br')
                return(
                    <Fragment key={k}>
                        <button type="button" className={class_nme} onClick={() => setEtcl(item_k.id)}>{item_k.name}</button>
                    </Fragment>
                )
            })}
            </div>
            <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                <span className='fw-600 font-med app-text-color'>All Present Student(s)</span>
            </div>
            <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"Sit No"}, {text:"Adm No / Full Name"}, {text:"Status"}]} />
            {stud_filter_present.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                ins      
            }
            {stud_filter.length > 0 && (
                <>
                    <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                        <span className='fw-600 font-med app-text-color'>All Pending Student(s)</span>
                    </div>
                    <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"Sit No"}, {text:"Adm No / Full Name"}, {text:"Status"}]} />
                        {stud_filter.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                            inss      
                        }
                </>
            )}
            <div className='padd-5px align-center' style={{margin:'10px 0', width:'100%'}}>
                <Note>Once submitted, current student exam cannot be reviewed, so tread with caution</Note>
                <button type="button" className='btn bg-black app-bg-text-color font-slightly-small hover-opacity br' onClick={() => onEndReview(examTodayState.etts_uid, access.uid, {is_end_exam:true, reviewed_by:access.id, is_review_exam:true, is_start_exam:true})} style={{padding:'10px'}}> Submit Review <i className='fas fa-paper-plane font-small'></i></button>
            </div>
            </>
        )

    }

    const insertStudData = () => {
        const class_filter = allClasses.filter((iitem) => iitem.class_section_school === examTodayState.class_section);
        return(
            <>
            {class_filter.map((itemm, j) => {
                const stud_filter = allStudAttend.filter((it) => it.classes === itemm.id)
                const ins = stud_filter.map((item, i) => {
                    i++;
                    const full_name = `${item.last_name ? item.last_name : item.user_last_name} ${item.first_name ? item.first_name : item.user_first_name}`;
                    const stat = (st=false) => {
                        if(st){
                            return(
                                <span className="font-very-small text-uppercase align-center green fw-600">PRESENT</span>
                            )
                        }
                        return(
                            <span className="font-very-small text-uppercase align-center fw-700" style={{color:'rgb(255, 255, 40)'}}>PENDING</span>
                        )
                    }
                    return(
                        <Fragment key={i}>
                            <Tr sn={true} edit_btn={false} dlt_btn={false} check_btn={!item.is_present} check_text="Tick" handleCheckClick={() => handleClickStat({is_present:true, is_present_before_start:true, time_present:new Date()}, item.uid)}  times_btn={item.is_present} handleTimesClick={() => handleClickStat({is_present:false, is_late:false, is_present_before_start:false, time_present:null}, item.uid, 'warning')}>
                                <div className="c-data sn"><span className="font-very-small">{numberDigit(item.sit_no, 3)}</span></div>
                                <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic ? item.profile_pic : item.user_profile_pic}`} style={{width:'50px', height:'45px'}} alt="Profile" /></div> <span className="font-very-small text-capitalize">{numberDigit(item.adm_no, 4)} <br /> {full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                                <div className="c-data"><span className="font-very-small">{stat(item.is_present)}</span></div>
                            </Tr>
                        </Fragment>
                    )
                }) 
                return(
                    <Fragment key={j}>
                        
                        <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                            <span className='fw-600 font-slightly-small app-text-color'>All Student(s) In {itemm.name}</span>
                        </div>
                        <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"Sit No"}, {text:"Adm No / Full Name"}, {text:"Status"}]} />
                        {stud_filter.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                            ins      
                        }
                    </Fragment>
                )
            })}
            </>
        )
    }
    return(
        <>
            <HeadComponent title="Manage Exam Subject Supervision" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Section>
                    <Mallet>
                        <div className='flex-100'>
                            <label className='font-slightly-small'>Current Session: 
                            <select style={{cursor:'not-allowed'}} name="session" value={malletState.curr_sess_id} disabled className='form-control-theme font-slightly-small' >
                                <option value={malletState.curr_sess_id}>{malletState.curr_sess} - {malletState.curr_term.toUpperCase()} TERM</option>
                            </select></label>
                        </div>
                    </Mallet>
                </Section>
                
                {examTodayState.id ? (
                    <Grid layout='l31'> 
                    <GridContent header_text={`${examTodayState.subject_name} Exam Student Supervision`} header_icon="fas fa-eye">
                        <Section>
                            {loading && (<Loader />)}
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                    <div className='padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                        <button type="button" className='btn app-bg-color app-bg-text-color font-very-small hover-opacity br' onClick={() => setNavigate('../exam/subject/supervision')}><i className='fas fa-arrow-left'></i> Back</button>
                                    </div>
                                    {t_exam_start_time > currentTime && <Note>All student(s) should be in the exam hall 30 minutes before the starting time of the exam</Note>}
                                    {/* <CountDownTimer type="date" end={new Date().setHours(23)} title="Countdown Timer Before Exam Starts" /> */}
                                    {t60 < currentTime ? (
                                        <>
                                        {t_exam_start_time < currentTime ? (
                                            <>
                                            <div className='align-right padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                                <span className='fw-600 font-very-small app-text-color'>Exam Supervision Started By: </span> <span className="fw-500 app-text-color font-very-small">{examTodayState.started_by_gender === "F" ? "Miss. " : 'Mr. '}{examTodayState.started_by_last_name} {examTodayState.started_by_first_name}</span>
                                            </div>
                                            {isReview ? <div className='disp-block align-center'><span className='font-big fw-700'>Revisit {examTodayState.subject_name.length > 10 ? `${examTodayState.subject_name.slice(0, 10)}...` : examTodayState.subject_name} Exam</span></div> : <CountDownTimer type="date" currentTimer={countdown_time} title={`Exam Countdown Timer (${examTodayState.subject_name.length > 10 ? `${examTodayState.subject_name.slice(0, 10)}...` : examTodayState.subject_name})`} />}
                                            {countdown_time > 0 ? (
                                                <>  
                                                    {countdown_time <= 600000 && (
                                                        <>
                                                        {countdown_time <= 180000 ? (
                                                            <>
                                                                <Note>The exam will end automatically when the countdown timer stops and all students will be done automatically</Note>
                                                                <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                                                    <span className='fw-600 red font-med'>All students must put utencils down and submit their answer sheets NOW!</span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                                                <span className='fw-600 font-slightly-small' style={{color:'yellow'}}>All students should prepare to submit their answer sheet(s) to their supervisor</span>
                                                            </div>
                                                        )}
                                                        </>
                                                    )}
                                                    <Table>
                                                    {insertMainStudData()}
                                                    </Table>
                                                </>
                                            ) : (
                                                <>
                                                {!isReview ? (
                                                     <>

                                                        <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                                            <span className='fw-600 green font-med'>Exam session has been ended </span>
                                                        </div>
                                                        <div className='padd-5px align-center' style={{margin:'10px 0', width:'100%'}}>
                                                            <button type="button" className='btn app-bg-color app-bg-text-color font-super-small hover-opacity br' onClick={() => setNavigate('../exam/subject/supervision')}><i className='fas fa-arrow-left'></i> Go Back</button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    <Table>
                                                    {insertMainStudReviewData()}
                                                    </Table>
                                                    </>
                                                )}
                                                </>
                                               
                                            )}
                                       
                                            </>
                                        ) : (
                                            <>
                                                <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                                    <span className='fw-500 app-text-color font-med'>EXAM STARTS FROM </span><span className='fw-600 font-med app-text-color'>{timify(examTodayState.start_time)} - {timify(examTodayState.end_time)}</span>
                                                </div>
                                                <Note>To mark student as present, click on the <i className="fas fa-check"></i>/Tick icon </Note>
                                                <Table>
                                                    {insertStudData()}
                                                </Table>
                                            </>
                                        )}
                                        </>
                                    ) : (
                                        <>
                                        <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                            <span className='fw-500 app-text-color font-med'>EXAM STARTS FROM </span><span className='fw-600 font-med app-text-color'>{timify(examTodayState.start_time)} - {timify(examTodayState.end_time)}</span>
                                        </div>
                                        <div className='align-center padd-5px' style={{margin:'10px 0', width:'100%'}}>
                                            <span className='fw-500 app-text-sec-color font-slightly-small'><i>Please Wait.... Students Data Will Be Populated An Hour Before The Exam</i></span>
                                        </div>
                                        </>
                                    )}
                                </>
                                )
                            }
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false)}} header_text={"Student Misconduct Form"} header_icon={"fas fa-align-left"}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    {menuDropData.type === "misconduct" && (
                                        <>
                                        <form className='row' onSubmit={(e) => onSubmitMisconduct(e)}>
                                            <div className="fg">
                                                <label className="font-super-small">Adm No. </label>
                                                <input onChange={e => handleMenuChange(e)} disabled type="text" name="adm_no" className="font-very-small app-theme form-control disabled" placeholder="Student Adm No" value={numberDigit(menuDropData.adm_no, 4)} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Name </label>
                                                <input onChange={e => handleMenuChange(e)} disabled type="text" name="name" className="font-very-small app-theme form-control disabled text-capitalize" placeholder="Student Name" value={menuDropData.name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Misconduct </label>
                                                <textarea onChange={e => handleMenuChange(e)} type="text" required name="misconduct" className="font-very-small app-theme form-control-theme" placeholder="What Misconduct Occured?.." value={menuDropData.misconduct} style={{height:'80px'}} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Mark To Reduce As Punishment</label>
                                                <input onChange={e => handleMenuChange(e)} type="number" required name="mark_pun" className="font-very-small app-theme form-control-theme" placeholder="Mark Punishment" value={menuDropData.mark_pun} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Save <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </form>
                                        </>
                                    )}
                                </>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent> 
                    <GridContent header_text={`Other Exams For Today`} header_icon="fas fa-eye">
                        <Section>
                            <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                {insertMinorTimeTableTodayData(examTodayState.id)}
                            </div>
                        </Section>
                    </GridContent>
                    </Grid>
                ) : (
                    <Grid layout='lauto'>
                    <GridContent header_text={`Exam Timetable`} header_icon="fas fa-align-right">
                        <Section>
                         {loading && (<Loader />)}
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                    <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                        {spinLoading && <Spinner />}
                                        {malletState.is_upload_exam_timetable && (
                                            <>
                                                {allExamTTTodayData.length > 0 &&  insertTimeTableTodayData()}
                                                {allExamTTReviewData.length > 0 && insertTimeTableToSuperviseData()}
                                                <div className='flex-100 align-center padd-4px'><button type="button" className='btn bg-none font-slightly-small blue app-text-color' style={{fontStyle:'italic'}} onClick={() => setViewAllTT(!viewAllTT)}>View all exam subject supervision</button></div>
                                                {viewAllTT && <>{allExamTTData.length > 0 ? insertTimeTableData() : <div className='flex-100 align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}</>}
                                            </>
                                        )}
                                    </div>
                                </>
                                )
                            }
                        </Section>
                    </GridContent>  
                    </Grid>
                )}
                
            </ComponentWrapper>
        </>
    )
}

export default StaffSchoolExamSubjectSupervision;