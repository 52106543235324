export const BgColor = (color="") => {
    if(color === "blue"){
        return '#00057a';
    }
    if(color === "red"){
        return '#570a00';
    }
    if(color === "green"){
        return 'rgb(40, 100, 50)';
    }
    if(color){
        return color;
    }
    return '#26223a';
}