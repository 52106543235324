import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Wrapper, OuterWrapper, Content } from "./style";
import BlankLoader from "components/blankLoader";
// import { appServices } from "api/schoolApp/services";

const initialState = {
    id: 0,
    link_dd: "",
    link_icon: "",
    link_name: "",
    link_path: "",
    link_title: "",
}

export const VerticalNav = ({name="", honor="", img_src="", data=[], school={}, loader=true, children}) => {
    const locc = window.location.href.split('//')[1].split('/');
    const [state, setState] = useState([initialState]);
    const [dataState, setDataState] = useState(data);
    const [loading, setLoading] = useState(true);
    const [expandNav, setExpandNav] = useState(false);
    const [activeNav, setActiveNav] = useState(locc[2]);
    const [navDropDown, setNavDropDown] = useState(0);

    // const loc = window.location.href;
    const currentLoc = locc[2];
    // const fetchNavData = (pk) => {
    //     setLoading(true);
    //     appServices.getNavData(pk).then(res => {
    //         setState([...res.data]);
    //         setLoading(false);
    //     }).catch(error => {
    //         setState([initialState])
    //         setLoading(false);
    //     });
            
    // }
    useEffect(() => {
        if(data.length){
            setDataState(data);
        }
        else{
            setDataState([])
        }
    }, [data]);
    useEffect(() => {
        // fetchNavData(4);
        if(!loader){
            setLoading(false);
            setState(dataState);  
            return;
        }
        setLoading(true);
        setState([initialState]);
    }, [dataState, loader]);

    useEffect(() => {
        const intval = setInterval(() => {
            const locc = window.location.href.split('//')[1].split('/');
            setActiveNav(locc[2]);
        }, 1000);
        return () => clearInterval(intval);
    }, [])
    
    const clickExpandNav = () =>{
        setExpandNav(true);
    }
    const clickShrinkNav = () =>{
        setExpandNav(false);
    }
    
    const activeLinkHandle = (location = currentLoc) => {
        
        if(location === currentLoc){
            setActiveNav(currentLoc);
        }
        else{
            setActiveNav(location);
        }
    }

   
    const mapLink = state.map((item, index) => {
        if (item.id === 0){
            return <BlankLoader num={8} hide_text={true} /> 
        }
        else if (item.link_dd){
            const mapLinkDd = item.link_dd.split("*").map((dd, i)=>{
                if(dd){
                    return(     
                        <li className="nav-dd-anim" key={i}><Link  onClick={() => {activeLinkHandle(item.link_path); setExpandNav(false);}} to={dd.split('|')[1]} className="app-nav-text-color">{dd.split('|')[0]}</Link></li>
                    )
                }
                else{
                    return(
                        <Fragment key={i}><></></Fragment>
                    )
                }
            })
            if(item.link_path === activeNav){
                return(
                    <li key={item.id} className={expandNav ? `active_nav nav-icon-padd` : `padd-4px active_nav`}>   
                        <span className="app-nav-text-color link" onClick={() => {
                            if(navDropDown){
                                setNavDropDown(0);
                            }
                            else{
                                setNavDropDown(item.id);
                            }
                        }}>
                            <div className="icon"><i className={item.link_icon}></i></div>
                            <span className={expandNav ? "text disp-nav-none-block nav-icon-padd" : "text disp-nav-block-none "} title={item.link_title}>{item.link_name}</span>
                            <div className={expandNav ? "arrows disp-nav-none-block" : "arrows disp-nav-block-none "}><i className={item.id === navDropDown ? `dis fas fa-arrow-circle-down nav-dd-anim` : `dis fas fa-arrow-circle-right`}></i></div>
                            {item.id === navDropDown && (<ul className={expandNav ? "inner_nav nav-mrt-20-780 nav-dd-anim" : "inner_nav nav-mrt-20 nav-dd-anim"}>
                                {item.id === navDropDown && mapLinkDd}
                            </ul>)}
                            
                        </span>
                    </li>
                );
                
            }
            return(
                <li key={item.id} className={expandNav ? `nav-icon-padd` : `padd-4px`}>   
                    <span className="app-nav-text-color link" onClick={() => {
                            if(navDropDown){
                                setNavDropDown(0);
                            }
                            else{
                                setNavDropDown(item.id);
                            }
                        }}>
                        <div className="icon"><i className={item.link_icon}></i></div>
                        <span className={expandNav ? "text disp-nav-none-block nav-icon-padd" : "text disp-nav-block-none "} title={item.link_title}>{item.link_name}</span>
                        <div className={expandNav ? "arrows disp-nav-none-block" : "arrows disp-nav-block-none "}><i className={item.id === navDropDown ? `dis fas fa-arrow-circle-down nav-dd-anim` : `dis fas fa-arrow-circle-right`}></i></div>
                        {item.id === navDropDown && (<ul className={expandNav ? "inner_nav nav-mrt-20-780 nav-dd-anim" : "inner_nav nav-mrt-20 nav-dd-anim"}>
                            {item.id === navDropDown && mapLinkDd}
                        </ul>)}
                    </span>
                </li>
            );
        }
        else{      
            if(item.link_path === activeNav){
                return (
                    <li  key={item.id} className={expandNav ? `active_nav nav-icon-padd` : `padd-4px active_nav`}><Link onClick={() => {
                        activeLinkHandle(item.link_path);
                        setNavDropDown(0);
                        setExpandNav(false);
                    }} to={item.link_path} className="app-nav-text-color"><div className="icon"><i className={item.link_icon}></i></div><span className={expandNav ? "text disp-nav-none-block nav-icon-padd" : "text disp-nav-block-none "} title={item.link_title}>{item.link_name}</span></Link></li> 
                    )
            }
            return (
            <li  key={item.id} className={expandNav ? "nav-icon-padd" : "padd-4px"}><Link onClick={() => {
                activeLinkHandle(item.link_path);
                setNavDropDown(0);
                setExpandNav(false);
            }} to={item.link_path} className="app-nav-text-color"><div className="icon"><i className={item.link_icon}></i></div><span className={expandNav ? "text disp-nav-none-block nav-icon-padd" : "text disp-nav-block-none "} title={item.link_title}>{item.link_name}</span></Link></li> 
            )
        }
    })
    
    
    return(
        <>
        <Wrapper  className={expandNav ? "app-theme nav-wrapper-width" : "app-theme"}>
            <Content>
            
                <div className={expandNav ? "smv disp-all-nav-none" : "smv disp-nav-none-block"}>
                    <i className="fas fa-arrow-right app-bg-color" onClick={() => clickExpandNav()}></i>
                </div>
                <div className={expandNav ? "hmv disp-nav-none-block" : "smv disp-all-nav-none"}>
                    <i className="fas fa-arrow-left app-bg-color" onClick={() => clickShrinkNav()}></i>
                </div>
                <div className={expandNav ? "user_profile app-bg-color disp-nav-none-block" : "user_profile app-bg-color disp-nav-block-none"}>  
                    <div className="profile_icon" align="center"> 
                        {img_src ? <div><img src={img_src} alt='Me' className="" /><span className="online"></span></div> : <i className="fas fa-user"><span className="online"></span></i> }   
                    </div>
                    <div className="text_name">
                        <span className="text-upper"><span className="text-capitalize">{honor} {name}</span></span>
                    </div>
                    {/* <div className="profile_text" align="center">
                        <span>View  <i className="fas fa-reply"></i></span>
                        <span>Edit  <i className="fas fa-edit"></i></span>
                    </div> */}
                    <div className="name" align="right">
                        <span className="text-upper">-Profile Panel-</span>
                    </div>
                </div>
                <div className={expandNav ? "user_profile2 app-bg-color disp-all-nav-none" : "user_profile2 app-bg-color disp-nav-none-block"}>
			        <div className="profile_icon" align="center">
                    {img_src ? <div><img src={img_src} alt='Me' className="" /><span className="online"></span></div> : <i className="fas fa-user"><span className="online"></span></i> }   
                    </div>
                    <div className="aside_cont profile_texts2 app-bg-color">
				        <div className="text_name">
                            <span className="text-upper"><span className="text-upper">{honor} {name}</span></span>
                        </div>
                        {/* <div className="profile_text" align="center">
                            <span>View  <i className="fas fa-reply"></i></span>
                            <span>Edit  <i className="fas fa-edit"></i></span>
                        </div> */}
                    </div>    
                </div>
                <ul className="navbar_ul">
                    { loading ? (
                        <BlankLoader num={4} hide_text={true} />
                    ) :  mapLink}
                </ul>    
                
            </Content>
        </Wrapper>
        <OuterWrapper>
            {children} 
            
        </OuterWrapper>
        </>
    )
}

export default VerticalNav;