import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import CountDownTimer from 'components/app/school/SmallerComponents/countDownTimer';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import { HeadComponent } from "components/head";
import { appServices5, appServices4 } from 'api/schoolApp/services';

import { addItemToArray} from 'components/app/school/actions/array-utils/Add';
import { filterObjectByKey} from 'components/app/school/actions/array-utils/Filter';
//import { updateArray, updateArrayWithArray, updateinnerArray } from 'components/app/school/actions/array-utils/Update';
//import { moveKeyLocInObject } from 'components/app/school/actions/array-utils/Move';
//import { groupArray } from 'components/app/school/actions/array-utils/Group';
import { numberDigit } from 'components/app/school/actions/numberDigit';
import Note from 'components/app/school/SmallerComponents/NOTE';
import { sortByName } from 'components/app/school/actions/array-utils/Sort';


const StaffFormTeacherScoresEntry = ({school_id, school, school_branch, user_id, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialMalletState = useMemo(() => ({curr_sess_id:0, curr_sess:'', scores_entry_end:'', curr_term:'', session:'', is_upload_exam_timetable:false, term:'', classes:'', student:''}), []);
    const initialTraitState = useMemo(() => ({id:0, uid:'', commendation:"", manners:"", attentiveness:'', honesty:'', neatness:'', politeness:'', punctuality:''}), []);
    const initialTraitPsychState = useMemo(() => ({id:0, uid:'', handwriting:'', sports:'', drawing_and_painting:'', art_and_crafts:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    
    const [malletState, setMalletState] = useState(initialMalletState);
    const [traitScoreState, setTraitScoreState] = useState(initialTraitState);
    const [traitPsychScoreState, setTraitPsychScoreState] = useState(initialTraitPsychState);

    const [allScoresEtable, setAllScoresEtable] = useState([]);
    const [allStudent, setAllStudent] = useState([]);
    const [allStudentScores, setAllStudentScores] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [malletTableLoading, setMalletTableLoading] = useState(false);
    const [isStaffUploaded, setIsStaffUploaded] = useState(false);

    const [remark, setRemark] = useState('');
    const [autogenerateRemark, setAutogenerateRemark] = useState(-1)

    const fetchAllClasses = (id) => {
        if(id){
            setError({data:[], title:''});
            setPreloading(true);
            appServices4.getAllFormMasterClasses(id).then(res => {
                setAllClassesData(res.data);
                setPreloading(false);
            }).catch(e => {
                setAllClassesData([]);
                setPreloading(false);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllScores = (sid) => {
        if(sid){
            setError({data:[], title:""});
            appServices5.getAllSchoolScores(sid).then(res => {
                setAllScoresEtable(addItemToArray(res.data, {is_clicked:false}))
            }).catch(e => {
                setError({title:'School Scores Data Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllStudent = (sid, sbid, cid) => {
        if(sid && sbid && cid){
            setError({data:[], title:''});
            appServices4.getAllSchoolStudent(sid, sbid, cid).then(res => {
                setAllStudent(addItemToArray(res.data));            
            }).catch(e => {
                setAllStudent([]);
                setError({data:["Internal Server Error"], title:"Student Data Fetch Error"});
            })
        }
    }
    // const autoGenerateRemark = (sid, mark) => {
    //     if(sid && mark){
    //         setError({data:[], title:''});
    //         appServices4.initRemark(sid, mark).then(res => {
    //             console.log(res.data)
    //         }).catch(e => {
                
    //             setError({data:["Internal Server Error"], title:"Autogeneration Error"});
    //         })
    //     }
    // }
    
    const fetchAllStudentScores = useCallback((stid, sid, sbid, cid, stuid, initTrait={}, initTraitPsych={}) => {
        if(stid && sid && sbid && cid && stuid){
            setSpinLoading(true);
            appServices4.getAllSchoolMainStudentScoresEntryFormMaster(stid, sid, sbid, cid, stuid).then(res => {
                setAllStudentScores(addItemToArray(sortByName(res.data, 'subject_name')));
                appServices4.getSchoolStudentTraitScoreEntryFM(stid, sid, sbid, cid, stuid).then(res2 => {
                    setTraitScoreState(filterObjectByKey(res2.data, initTrait));
                    setTraitPsychScoreState(filterObjectByKey(res2.data, initTraitPsych));
                    setIsStaffUploaded(res2.data.is_uploaded);
                    setRemark(res2.data.remark);
                    setSpinLoading(false);
                    setLoading(false);
                }).catch(e => {
                    setTraitScoreState(initTrait);
                    setIsStaffUploaded(false);
                    setTraitPsychScoreState(initTraitPsych);
                    setSpinLoading(false);
                    setRemark('');
                    setLoading(false);
                })
            }).catch(e => {
                setAllStudentScores([]);
                setSpinLoading(false);
                setLoading(false);
                setTraitScoreState(initTrait);
                setTraitPsychScoreState(initTraitPsych);
                setError({title:'Student Scores Data Fetch Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            return;
        }
        setAllStudentScores([]);
        setTraitScoreState(initTrait);
        setTraitPsychScoreState(initTraitPsych);
    }, [])
    const fetchCurrentSession = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices5.getCurrentSession(sid).then(res => {
                setMalletState({curr_sess:res.data.session, curr_sess_id:res.data.id, curr_term:res.data.term_name, scores_entry_end:res.data.scores_entry_end, is_upload_exam_timetable:res.data.is_upload_exam_timetable, classes:'', student:''});
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const handleRemarkChange = e => {
        setRemark(e.target.value);
    }
    const handleScoreTraitChange = e => {
        if(e.target.value && parseInt(e.target.value) < 6 && parseInt(e.target.value) > -1 ){
            setError(initialErrorState);
            setSuccess(initialSuccessState);
            setLoading(true);
            if(!traitScoreState.id){
                const fm_class = allClassesData.filter((it) => it.classes === parseInt(malletState.classes))[0]
                const data = {sid:school_id, cid:malletState.classes, sbid:school_branch.id, stid:access.uid, [e.target.name]:e.target.value, access_exam:allStudentScores[0].student_access, created_by:fm_class.id};
                appServices4.insertSchoolStudentTraiScoretEntryFM(data).then(res => {
                    let result_data = res.data
                    setTraitScoreState(filterObjectByKey(result_data, initialTraitState));
                    setTraitPsychScoreState(filterObjectByKey(result_data, initialTraitPsychState));
                    setLoading(false);
                    setSuccess({title:'Success', text:'Data Saved Successfully'});
                }).catch(e => {
                    setLoading(false);
                    setError({title:'Insert Data Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
                })
                return;
            }
            const fm_class = allClassesData.filter((it) => it.classes === parseInt(malletState.classes))[0]
            const data = {[e.target.name]:e.target.value, updated_by:fm_class.id};
            appServices4.updateSchoolStudentTraitScoreEntryFM(access.uid, school_id, malletState.classes, traitScoreState.uid, data).then(res => {
                let result_data = res.data
                setTraitScoreState(filterObjectByKey(result_data, initialTraitState));
                setTraitPsychScoreState(filterObjectByKey(result_data, initialTraitPsychState));
                setLoading(false);
                setSuccess({title:'Success', text:'Data Updated Successfully'});
            }).catch(e => {
                setLoading(false);
                setError({title:'Insert Data Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            return;
        }
        if(Object.keys(initialTraitState).includes(e.target.name)){
            setTraitScoreState({...traitScoreState, [e.target.name]:null});
            return;
        }
        if(Object.keys(initialTraitPsychState).includes(e.target.name)){
            setTraitPsychScoreState({...traitPsychScoreState, [e.target.name]:null});
            return;
        }
    }
    const handleArrowClick = (num=-1) => {
        const stud_item = allStudent.filter((it) => it.num === num)[0];
        setMalletState({...malletState, student:stud_item.uid});
        setDataTableLoading(true);
        setLoading(true);
        setRemark('');
        setAutogenerateRemark(-1);
    }
    const handleUpload = (e) => {
        e.preventDefault();
        if(!Object.values(traitScoreState).find((it) => !it) && !Object.values(traitPsychScoreState).find((it) => !it)){
        setWarning({id:0, title:'', text:''});
        setTimeout(() => {
            setWarning({id:5, click_text:`Upload`, 
             title:"Warning", text:"Data Cannot Be Altered Once Uploaded, Are You Sure?",
             handleClick: () =>{
                setError(initialErrorState);
                setSuccess(initialSuccessState);
                setLoading(true);
                const fm_class = allClassesData.filter((it) => it.classes === parseInt(malletState.classes))[0]
                const data = {is_uploaded:true, remark:remark, updated_by:fm_class.id}
                appServices4.updateSchoolStudentTraitScoreEntryFM(access.uid, school_id, malletState.classes, traitScoreState.uid, data).then(res => {
                    setLoading(false);
                    setSuccess({title:'Success', text:'Student Data Uploaded Successfully'});
                    setIsStaffUploaded(true);
                    setWarning({id:0, title:'', text:''});
                }).catch(e => {
                    setLoading(false);
                    setWarning({id:0, title:'', text:''});
                    setError({title:'Data Upload Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
                })
            }
            })
        })
        }
    }
    const handleMalletChange = e => {
        setDataTableLoading(true);
        if(e.target.name === "classes" && e.target.value){
            setMalletTableLoading(true);
            setDataTableLoading(true);
            setMalletState({...malletState, [e.target.name]:e.target.value, student:'', pre:false});
            return;
        }
        if(e.target.name === "classes" && !e.target.value){
            setAllStudent([]);
            setMalletState({...malletState, [e.target.name]:e.target.value, student:'', pre:false});
            setDataTableLoading(true);
            return
        }
        setDataTableLoading(true);
        setMalletState({...malletState, [e.target.name]:e.target.value, pre:false});
    }
    const handleAutoRemark = () => {
        const check_exam_score_empty = allStudentScores.filter((it) => it.scores.length < 1 && it.is_present === true).length > 3 ? true : false
        const tot_score = allStudentScores.reduce((tot, item) => {
            tot += parseFloat(item.tot_mark ? item.tot_mark : 0)
            return tot;
        }, 0)
        const fin_avr = tot_score / allStudentScores.length;
        if(!check_exam_score_empty){
            setAutogenerateRemark(fin_avr);
        }
    }

    useEffect(() => {
        if(reload && access.uid){
            fetchAllClasses(access.uid);
            fetchCurrentSession(school_id);
            fetchAllScores(school_id);
            setMalletState(initialMalletState);
            setReload(false);
        }
        // fetchSchoolWeekDays(school_id);
    }, [school_id, school_branch.id, access.uid, reload, initialMalletState]);

    useEffect(() => {
        if(malletTableLoading){
            fetchAllStudent(school_id, school_branch.id, malletState.classes);
            setMalletTableLoading(false);
            setAutogenerateRemark(-1);
            setRemark('');
            setIsStaffUploaded(false);
        }
        if(dataTableLoading){
            fetchAllStudentScores(access.uid, school_id, school_branch.id, malletState.classes, malletState.student, initialTraitState, initialTraitPsychState);
            setDataTableLoading(false);
            setAutogenerateRemark(-1);
            setRemark('');
            setIsStaffUploaded(false);
        }
        if(autogenerateRemark > -1){
            setLoading(true);
            appServices4.initRemark(school_id, autogenerateRemark).then(res => {
                setRemark(res.data.remark);
                setAutogenerateRemark(-1);
                setLoading(false);
            }).catch(e => {
                setError({title:'Autogeneration Error', data:['Remark Autogeneration Error']});
                setAutogenerateRemark(-1);
                setLoading(false);
            })
        }
    }, [school_id, access.uid, school_branch.id, malletState, dataTableLoading, autogenerateRemark, malletTableLoading, initialTraitState, initialTraitPsychState, fetchAllStudentScores])


    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertClassesOption = allClassesData.map((item) => {
        return(
            <option value={item.classes} key={item.id}>{`${item.class_name}`}</option>
        )
    })
    const insertStudentOption = allStudent.map((item, i) => {
        const full_name = item.user_last_name ? `${item.user_last_name} ${item.user_first_name}` : `${item.last_name} ${item.first_name}`
        return(
            <option value={item.uid} key={i}>{`${numberDigit(item.adm_no, 4)} - ${full_name}`}</option>
        )
    })

    const insertScoresEntryData = () => {
        const all_scores = allScoresEtable;
        const stud_name_width = (100 - ((all_scores.length) * 5) + 10);
        const split_str = (str="", search="") => {
            const spl = str.split(search)
            if(spl.length > 0){
                return spl.map((item) => (`${item} `));
            }
            return str;
        }
        const check_exam_score_empty = allStudentScores.filter((it) => it.scores.length < 1 && it.is_present === true).length > 3 ? true : false
        const stud_item = malletState.student ? allStudent.filter((it) => it.uid === malletState.student)[0] : {user_last_name:'', user_first_name:''};
        const full_name = stud_item.user_last_name ? `${stud_item.user_last_name} ${stud_item.user_first_name}` : `${stud_item.last_name} ${stud_item.first_name}`;
        const all_trait_score = {...traitScoreState, ...traitPsychScoreState};
        const check_all_null_trait_score = Object.entries(all_trait_score).filter((it) => it[1] === null || it[1] === '').length > 0 ? true : false;
        return (
            <>
            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med text-capitalize'> {full_name} Scores Collation</span></div>
            {isStaffUploaded && <div className='flex-100 padd-4px'><span className='disp-block align-center font-small fw-600 green'><i>**Data Has Been Uploaded Successfully</i></span></div>}
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH style={{padding:'5px', width:`${stud_name_width}%`}}><span className="font-slightly-small">Subject</span></TH>
                            {all_scores.map((item_j, j) => (
                                <Fragment key={j}>
                                    <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">{item_j.short_name}</span></TH>
                                </Fragment>
                            ))}
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Teacher</span></TH>
                            {/* <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">GRD</span></TH> */}
                        </TR>
                    </thead>
                    <tbody>
                        {allStudentScores.map((item, i) => {
                            // const full_name = item.subject_offering.user_last_name ? `${item.subject_offering.user_last_name} ${item.subject_offering.user_first_name}` : `${item.subject_offering.last_name} ${item.subject_offering.first_name}`;
                            const exam_score = item.scores
                            const scores = item.subject_offering.scores_temp;
                            const teacher_name = item.scores.length > 0 ? `${item.scores[0].created_by_last_name} ${item.scores[0].created_by_first_name}`: (item.subject_offering.scores_temp.length > 0 ? `${item.subject_offering.scores_temp[0].created_by_last_name} ${item.subject_offering.scores_temp[0].created_by_first_name}` : '')
                            const insertScores = () => {
                                return allScoresEtable.filter((it) => it.short_name.toLowerCase() !== "exam").map((item_k, j) => {
                                    let item_jj = scores.filter((it) => it.score_type === item_k.id || it.short_name === item_k.short_name)
                                    let item_j = item_jj.length > 0 ? item_jj[0] : {id:0, is_absent:false, mark:''}
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{item_j.is_absent ? 'ABS' : parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            const insertExamScore = () => {
                                return exam_score.map((item_j, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            return(
                                <Fragment key={i}>
                                    <TR>
                                        <TD align='center'><span className="font-slightly-small fw-600 app-text-color text-capitalize">{item.subject_name}</span></TD>
                                        {insertScores()}
                                        {item.is_present ? insertExamScore() : (<TD><span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>ABS</span></TD>)}
                                        <TD>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{teacher_name}</span>}</TD>
                                        {/* <TD>{<span className={`disp-block font-slightly-small fw-700 app-text-color align-center`}>{item.curr_grade}</span>}</TD>           */}
                                    </TR>    
                                </Fragment>
                            )
                        })}
                    </tbody>
                </TABLE>
                {check_exam_score_empty && <Note>Please contact, the respective blank subject exam score teacher and have them input it, to begin your score entry</Note>}
            </div>
            {!isStaffUploaded && <Note>Rating Range Scales From 0 - 5</Note>}
            <div className='disp-flex wrap'>
                <div className='flex-50 padd-4px'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'5px', width:`90%`}}><span className="font-slightly-small">Effective Traits</span></TH>
                                <TH style={{padding:'5px'}}><span className="font-slightly-small">Rating</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(traitScoreState).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD><span className="font-slightly-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD>
                                                {!isStaffUploaded ? <input type="number" name={item} className={`font-slightly-small fw-600 form-control ${check_exam_score_empty && 'disabled'}`} onChange={e => handleScoreTraitChange(e)} required={!check_exam_score_empty} disabled={check_exam_score_empty} maxLength={5} minLength={0} value={traitScoreState[item] === null ? '' : traitScoreState[item]}/> : <span className='font-slightly-small fw-600'>{traitScoreState[item]}</span>}
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                <div className='flex-50 padd-4px'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'5px', width:`90%`}}><span className="font-slightly-small">PSYCHOMOTOR</span></TH>
                                <TH style={{padding:'5px'}}><span className="font-slightly-small">Rating</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(traitPsychScoreState).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD><span className="font-slightly-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD>
                                                
                                                {!isStaffUploaded ?   <input type="number" name={item} className={`font-slightly-small fw-600 form-control ${check_exam_score_empty && 'disabled'}`} onChange={e => handleScoreTraitChange(e)} required={!check_exam_score_empty} disabled={check_exam_score_empty} maxLength={5} minLength={0} max={5} min={0} value={traitPsychScoreState[item] === null ? '' : traitPsychScoreState[item]}/> : <span className='font-slightly-small fw-600'>{traitPsychScoreState[item]}</span>}
                                                
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
            </div>
            {!isStaffUploaded ? (
                <>
                    <div className='flex-50-line padd-4px' style={{marginBottom:'4px'}}><label className="app-text-color fw-700 font-slightly-small">Remark:</label></div>
                    <div className='flex-50-line align-right padd-4px' style={{marginBottom:'4px'}}><button type="button" className="font-very-small btn app-bg-color padd-4px app-bg-text-color hover-opacity br" onClick={() => handleAutoRemark()} style={check_all_null_trait_score ? {cursor:'not-allowed', opacity:.6}: {cursor:'default'}}><i className="fas fa-recycle"></i> Autogenerate Remark</button></div>
                </>
            ) : (
                <div className='flex-100 padd-4px' style={{marginBottom:'4px'}}><label className="app-text-color fw-700 font-slightly-small">Remark:</label> <code className='font-slightly-small fw-500'>{remark}</code></div>
            )}
            
            <div className='flex-100' style={{marginBottom:'4px'}}>
                {!isStaffUploaded ? (
                    <form onSubmit={e => handleUpload(e)}>
                        <textarea type="text" placeholder="Enter Student's Remark" disabled={check_all_null_trait_score} required={!check_all_null_trait_score} className="form-control-theme font-slightly-small " onChange={e => handleRemarkChange(e)} value={remark} />
                        <div className='disp-flex wrap'>
                            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><button type="submit" disabled={check_all_null_trait_score} className='mybtn font-very-small bg-blue app-bg-text-color' style={check_all_null_trait_score ? {cursor:'not-allowed', opacity:.6}: {cursor:'pointer'}}>Upload <i className='fas fa-paper-plane'></i></button></div>
                        </div>
                    </form>
                ) : (
                    <span className='disp-block align-center font-small fw-600 green'><i>**Data Has Been Uploaded Successfully</i></span>
                )}
                
            </div>
            </>
        )
    }
    console.log(allStudentScores)
    const scores_entry_end_time = malletState.scores_entry_end === null ? (new Date().getTime()  + 100000) : (malletState.scores_entry_end ?  new Date(malletState.scores_entry_end).getTime() : 0);
    const next = malletState.student ? allStudent.filter((it) => it.uid === malletState.student)[0].num + 1 : 0;
    const prev = malletState.student ? allStudent.filter((it) => it.uid === malletState.student)[0].num - 1 : -1;
    return(
        <>
            <HeadComponent title="Form Teacher Scores Entry For Student" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Section>
                    <Mallet>
                        <div className='flex-100'>
                            <label className='font-slightly-small'>Current Session: 
                            <select style={{cursor:'not-allowed'}} name="session" value={malletState.curr_sess_id} disabled className='form-control font-slightly-small' >
                                <option value={malletState.curr_sess_id}>{malletState.curr_sess} - {malletState.curr_term.toUpperCase()} TERM</option>
                            </select></label>
                        </div>
                    </Mallet>
                </Section>
                <Grid layout='lauto'>   
                    <GridContent header_text={`Student Trait Scores Entry`} header_icon="fas fa-align-left">
                        <Section>
                            {loading && (<Loader />)}
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                {scores_entry_end_time > new Date().getTime() ? (
                                    <>
                                    {malletState.scores_entry_end && <CountDownTimer type="date" end={malletState.scores_entry_end} title={`Scores Entry Countdown Timer`} />}
                                    <Mallet>
                                        <div className="flex-50-line"><label className='font-super-small'>Select Class: 
                                            <select name="classes" onChange={e => handleMalletChange(e)} value={malletState.classes} className='form-control-theme font-slightly-small  text-capitalize' >
                                                <option value="">Select Class</option>
                                                {insertClassesOption}
                                            </select></label>
                                        </div>
                                        <div className="flex-50-line"><label className='font-super-small'>Select Student: 
                                            <select name="student" onChange={e => handleMalletChange(e)} value={malletState.student} className='form-control-theme font-slightly-small  text-capitalize' >
                                                <option value="">Select Student {allStudent.length > 0 && '...'}</option>
                                                {insertStudentOption}
                                            </select></label>
                                        </div>
                                    </Mallet>
                                    <div className='disp-flex wrap'>
                                        <div className="flex-100">
                                            {spinLoading && <Spinner />}
                                        </div>
                                    </div>
                                    
                                    <div className='disp-flex wrap'>
                                        {allStudentScores.length > 0 && (
                                            <>
                                            {prev > -1 && (
                                                <div className="flex-5-line">
                                                    <div className='disp-flex jc-c v-align-middle'>
                                                        <button type="button" className='btn app-bg-color app-bg-text-color br-circle padd-4px hover-opacity font-med' onClick={() => handleArrowClick(prev)} title="Previous" style={{padding:'5px'}}><span className='fas fa-arrow-left'></span></button>
                                                    </div>
                                                </div>
                                            )}
                                            </>
                                        )} 
                                        <div className='flex-90-line'>
                                            <div className='disp-flex wrap'>
                                                {allStudentScores.length > 0 ? insertScoresEntryData() : (<> {!spinLoading && <div className='flex-90-line align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}</>)}
                                            </div>
                                        </div>
                                        {allStudentScores.length > 0 && (
                                            <>
                                            {allStudent.length > next  && (
                                               <div className="flex-5-line">
                                                    <div className='disp-flex v-align-middle jc-c'>
                                                    <button type="button" className='btn app-bg-color app-bg-text-color br-circle padd-4px hover-opacity font-med' onClick={() => handleArrowClick(next)} title="Next" style={{padding:'5px'}}><span className='fas fa-arrow-right'></span></button>
                                                    </div>
                                                </div>
                                            )}
                                            </>
                                        )} 
                                    </div>
                                    </>
                                    ) : (
                                        <div className='flex-100 padd-4px align-center' style={{marginBottom:'4px'}}><span className='red fw-700 font-big'>Scores Entry Session Has Been Ended </span> </div>
                                    )} 
                                </>
                                )
                            }
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default StaffFormTeacherScoresEntry;