import styled  from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    cursor: default;
    .container:hover:not(.active){
        box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
    }
    .active{
        border: 2px solid #2032ff;

        .checked-cont{
            i{
                border: 1px solid #2032ff;
                color: #2032ff;
            }
        }
        .save-cont{
            span{
                background: #2032ff;
                color: #ddd;
            }
        }
    }
`;

export const Content = styled.div`
    min-height: 200px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 15px;
    transition: .4s ease;
    position: relative;
    .disp-flex{
        display: flex;
    }
    .old-price-cont{
        position: absolute;
        left: 35%;
        top: 25%;

        @media(max-width: 540px){
            left: 45%;
        }
        .old-price{
            text-decoration: line-through;
            color: #666;
        }
    }
    .save-cont{
        position: absolute;
        top:-5%;
        left: 30%;
        
        @media(max-width: 540px){
            left: 35%;
        }
        span{
            padding: 10px;
            border-radius: 20px;
            background: #ccc;
        }
    }
    .checked-cont{
        position: absolute;
        left: 5%;
        top: 5%;

        i{
            padding: 5px;
            border-radius: 50%;
            border: 1px solid #bbb;
            color: #555;
        }
    }
`;