import React, {Fragment} from 'react';
import { Content } from './style';

const View = ({children, flex='33-view', type="text", label="", classname="", arr=[], src=null, alt="", onClickImg }) => {
    const func = (typ) => {
        if(typ === "text"){
            return(
                <Fragment>
                    {label && (<label className='font-very-small'>{label}:</label>)}
                    <span className={`font-small text ${classname}`}>{children}</span>
                </Fragment>
                
            )
        }
        else if(typ === "img"){
            return(
                <Fragment>
                    {label && (<label className='font-very-small'>{label}:</label>)}
                    <button onClick={onClickImg} className={`font-slightly-small text ${classname}`}>{children}</button>
                </Fragment>
                
            )
        }
        else if(typ === "pp"){
            return(
                <Fragment>
                    {label && (<><label className='font-very-small'>{label}:</label><br /></>)}
                    <img className={typ === "pp" ? 'profile' : 'picture'} src={src} alt={alt} />
                </Fragment>
                
            )
        }
        else if(type === "arr"){
            const insertArr = arr.map((item, i) => {
                return(
                    <Fragment key={i}>
                        {item.label && (<label className='font-very-small'>{item.label}:</label>)}
                        {item.text && <span className={`font-small text ${item.classname}`}>{item.text}</span>}
                        {item.btn_text && <button onClick={item.onClickImg} className={`font-slightly-small text ${item.classname}`}>{item.btn_text}</button>}
                    </Fragment>
                )
            })
            return insertArr;
        }
        else{
            return children;
        }
    }
    return(
        <Content className={`flex-${flex}`}>
            {func(type)}
        </Content>
    )
}

export default View;