import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    margin:20px 4px;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border-radius: 10px;
    padding: 6px;
    background: #26223a;
`;