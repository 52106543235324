
import axios from "axios";
import { error_messenger } from "./error_handler";
import { BACKEND_URL } from "./url";

export const login =  async (email, password) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({email, password})

    return await axios.post(`${BACKEND_URL}/auth/jwt/create/`, body, config)
}
export const sign_up = async (user_type,first_name,  last_name, email, phone_number, password, re_password, gender) => {
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    }
    const body = JSON.stringify({user_type, first_name, gender, last_name, email, phone_number, password, re_password})

    return await axios.post(`${BACKEND_URL}/auth/users/`, body, config).then(res => {
        return res;
    }).catch(e => {
        console.log(e.response.data)
    })
}
export const verify_user = async () => {
    if(localStorage.getItem("access")){
        const token = JSON.parse(localStorage.getItem("access"));
        const config = {
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        }
        const body = JSON.stringify({token : token.access});
        
        
        return await axios.post(`${BACKEND_URL}/auth/jwt/verify`, body, config).then(res => {
            if(res.data.code !== "token_not_valid"){
                return {
                    type:"success",
                    isAuthenticated: true
                }
            }
            else{
                return {
                    type:"error",
                    isAuthenticated: false
                }
            }
        }).catch(error => {
            if (error.response){
                return{ 
                    "type":"error",
                "code":error.response.status,
                "detail":error_messenger(error.response.status, "load_user", error.response.data.detail),
                "isAuthenticated":false};
            }
            else if(error.request) {
                
                return {"type":"error",
                "code":450,
                "detail":error_messenger(450),
                "isAuthenticated": false};
            } else {
                return {"type":"error",
                "detail":error.message,
                "isAuthenticated": false
                };
            }
        })
        
    }
    else{
        return {
            type:"error",
            isAuthenticated: false,
        }
    }
}
export const load_user = async () => {
    if(localStorage.getItem("access")){
        const access = JSON.parse(localStorage.getItem("access"))
        const config = {
            headers: {
                'Content-Type':'application/json',
                'Authorization':`JWT ${access.access}`,
                'Accept':'application/json'
            }
        }
        return await axios.get(`${BACKEND_URL}/auth/users/me/`, config);
    }
}