

export const LOCATION = () => {
    if(!localStorage.getItem('location')){
        fetch('https://api.ipregistry.co/?key=tryout').then(function (response) {
        return response.json();
        }).then(function (payload) {
            const config = {
                company: payload.company,
                carrier: payload.carrier,
                location: payload.location,
                timezone: payload.timezone,
                currency: payload.currency
            }
            localStorage.setItem('location', JSON.stringify(config));
        });
    }
    
}