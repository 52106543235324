import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    background: var(--appTheme);
    padding: 10px;
    transition: .4s ease;
    border-radius: 7px;
    box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.1);
    overflow-y: auto;

    &:hover{
        background: rgba(215, 234, 35, .1);
        .icon-btn-cont{
            display: block;
        }
    }
`;
export const Content = styled.div`
    .icon-placeholder-cont{
        position: absolute;
        left: 45%;
        top: 30%;

        i{
            font-size: 3rem;
            opacity: .3;
        }
    }
    .icon-btn-cont{
        position: absolute;
        left: 40%;
        top: 0;
        animation: animBtn 1s ease;
        display: none;

        @keyframes animBtn{
            from{
                left: 0;
            }
            to{
                left: 40%;
            }
        }
        button{
            padding: 2px 5px;
            letter-spacing: .4px;
            cursor: pointer;
            background: none;
            border-radius: 10px;
            border: 1px solid var(--appBgColor);
            transition: .3s linear;

            &:hover{
                background: var(--appBgColor);
                color: #eee;
            }
        }
    }
    .header-cont{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .header{
            span{
                color: #777;
            }
        }
        .icon{
            span{
                color: #999;
            }
        }
    }
    .bordered{
        border-bottom: 1px dotted var(--appTextSecColor);
        border-bottom-right-radius: 3px;
    }
`;

export const InnerWrapper = styled.div`
    
`;
export const InnerContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .main-cont{
        display: flex;
        justify-content: stretch;

        .cont-icon{
            img{
                width: 30px;
                height: 30px;
            }
        }

        div{
            padding: 0 5px;
        }
        .hover-text{
            transition: .3s ease;
            cursor: pointer;
            &:hover{
                color: var(--appBgColor);
            }
        }
    }
`;