import styled from "styled-components";
import { motion } from "framer-motion";


export const Wrapper=styled(motion.div)`
    position: absolute;
    left: -40%;
    bottom: 0;
    transform: translate(10%, 99%);
    z-index: 1;
    
    overflow-x: hidden;
    padding-top: 10px;
    display: none;
    @media(max-width:1280px){
        left: -44%;
    }
    @media(max-width:1024px){
        left: 20%;
        padding-top: 15px;
    }
    @media(max-width:430px){
        left: 8%;
    }
    @media(max-width:320px){
        left: 3%;
    }
`;

export const Content = styled(motion.div)`
    width: 300px;
    overflow-y: auto;
    scroll-behavior: auto;
    max-height: 300px;
    background: var(--appBodyColor) !important;
    box-shadow: 5px 0 6px 5px rgba(0,0,0,0.2);
    border: 1px solid var(--appBgColor);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    @media(max-width:1024px){
        width: 250px;
    }
    @media(max-width:430px){
        width: 200px;
        max-height: 250px;
    }
    @media(max-width:320px){
        width: 150px;
        max-height: 200px;
    }
    ::after{
        content: "";
        position: absolute; 
        top:-4%;
        left: 93%;
        transform: rotate(120deg);
        margin-top: -5px;
        border-width: 20px;
        border-style: solid;
        border-color: transparent var(--appBgColor) transparent transparent;
        @media(max-width:1024px){
            top:-3%;
            border-width: 15px;
            left: 45%;
            transform: rotate(90deg);
        }
        @media(max-width:320px){
            top:0%;
        }
        @media(max-width: 280px){
            border-width: 10px;
        }
    }
    div.header{
        width: 100%;
        background: var(--appBgColor);
        border-radius: 10px;
        span{
            font-weight: 600;
            color: var(--appBgTextColor);
            display: inline-block;
        }
        span:last-child{
            padding-left: 7px;
        }
        .heading-left{
            text-align: left;
            padding: 0 5px;
            border-radius: 50%;
            background: var(--appBodyColor);
            color: var(--appTextColor);
        }
    }
    div.content:nth-child(odd){
        background: var(--appTheme);
    }
    
    div.content{
        padding: 6px 3px;
        border-radius: 3px;
        margin-left: 6px;
        .profile-cont{
            position: relative;
            span.circle{
                width: 12px;
                height: 12px;
                position: absolute;
                border-radius: 50%;
                right: 40%;
                bottom: 10%;
                @media(max-width: 780px){
                    width: 8px;
                    height: 8px;
                    
                }
                @media(max-width: 320px){
                    width: 7px;
                    height: 7px;
                    bottom: 14%;
                }
                span{
                    padding-left: 15px;
                    font-weight: 400;
                    @media(max-width: 540px){
                        padding-left: 8px;
                    }
                    @media(max-width: 320px){
                        padding-left: 6px;
                    }
                }
            }
            span.circle.green{
                background: green;
            }
            span.circle.red{
                background: red;
            }
            img{
                width: 100px;
                height: 100px;
                border-radius: 40%;

                @media(max-width: 1024px){
                    width: 70px;
                    height: 70px;
                }
                @media(max-width: 780px){
                    width: 60px;
                    height: 60px;
                }
                @media(max-width: 320px){
                    width: 50px;
                    height: 50px;
                }
            }
        }
        span.heading{
            font-weight: 600;
            display: inline-block;
            padding-right: 5px;
            color: var(--appTextColor);
        }
        span.text{
            font-weight: 500;
            display: inline-block;
        }
        .link{
            font-weight: 700;
            display: inline-block;
            text-decoration: none;
        }
    }
`;