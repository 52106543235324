import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { appServices, appServices2, appServices3, appServices4, appServices5 } from "api/schoolApp/services";
import { AdminRoutes, StudentRoutes, ParentRoutes, StaffRoutes } from "appContainer/schoolAppRoutes";
import VerticalNav from "./verticalNav";
import HorizontalNav from "./horizontalNav";
import { SchoolAppStyle } from "GlobalStyle";
import Redirect from "components/redirectPage";
import PreloaderLine from "components/preloaderLine";
import { BACKEND_URL } from "actions/url";

// const initalLayoutState = {
//     "type":"",
//     "data": {},
//     "isAuthenticated":true,
// };
const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const initialCurrState = {
    name:'', code:'', symbol:'', id:0
}
const Layout = ({children, inner_state}) => {
    const {schoolName} = useParams()
    // const [state, setState] = useState(initalLayoutState);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(initialUserState);
    const [school, setSchool] = useState('');
    const [navState, setNavState] = useState([]);
    const [schoolCurr, setSchoolCurr] = useState(initialCurrState)
    const [schoolId, setSchoolId] = useState(0);
    const [accessData, setAccessData] = useState({id:0, uid:''});
    const [parentAccessData, setParentAccessData] = useState([]);
    const [schoolDetail, setSchoolDetail] = useState({working_time_start:'', working_time_end:'', school_name:'', school_icon:''})

    const navigate = useNavigate();
    const [nav, setNav] = useState('');
    
    const [preloading, setPreloading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [navLoading, setNavLoading] = useState(true);
    const [exist, setExist] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(true);
    const [error404, setError404] = useState(false);

    const fetchStudentNavData = (pk, arr=[]) => {
        setNavLoading(true);
        appServices2.getNavData(pk).then(res => {
            setNavState([...arr, ...res.data]);
            setNavLoading(false);
        }).catch(error => {
            setNavState([])
            setNavLoading(false);
        });
            
    }

    const fetchSchool = useCallback((sn, id) => {
        setLoading2(true);
        setPreloading(true);
        appServices.getUser(0, sn, id).then(res => {
            setLoading2(false);
            setPreloading(false);
            setExist(true);
        }).catch(e => {
            setLoading2(false);
            setExist(false);
            setPreloading(false);
            setError404(true);
        })
    }, [])

    const fetchStudentAccessData = useCallback((id=0, sid=0) => {
        setLoading2(true);
        setPreloading(true);
        appServices2.getAccess(id, sid).then(res => {
            setExist(true);
            setAccessData(res.data);
            setPreloading(false);
            setLoading2(false);
            if(res.data.is_setup_app_account){
                fetchStudentNavData(4, [ {   id: 10001, link_dd: "", link_icon: "fas fa-eye", link_name: "Result Checker Key", link_path: "result_checker_key", link_title: "Manage kid(s) account" },                 {   id: 10002, link_icon: "fas fa-gamepad", link_name: "Games", link_path: "game", link_title: "Games", link_dd:"Go-Quiz|game/go_quiz*" }]);
                return;
            }
            setNavState([
                {   id: 10000,
                    link_dd: "",
                    link_icon: "fas fa-cog",
                    link_name: "App Setup",
                    link_path: "account/setup",
                    link_title: "Manage account setup"
                },
            ])
            setNavLoading(false);
        }).catch(e => {
            setExist(false);
            setAccessData({id:0, uid:''});
            setLoading2(false);
            setError404(true);
            setPreloading(false);
        })
    }, []);

    const fetchParentAccessData = useCallback((id, sid) => {
        setLoading2(true);
        setNavLoading(true);
        setPreloading(true);
        appServices3.getAccess(id, sid).then(res => {
            setExist(true);
            setParentAccessData([...res.data]);
            setLoading2(false);
            setPreloading(false);
            if(res.data.filter((item) => item.is_parent_access_app && item.is_cs_paid && item.is_setup_app_account).length > 0){
                const nav = [{   id: 10000, link_dd: "", link_icon: "fas fa-users", link_name: "All Kid(s) Account", link_path: "account/all_kids", link_title: "Manage kid(s) account" }, 
                {   id: 10001, link_dd: "", link_icon: "fas fa-eye", link_name: "Result Checker Key", link_path: "result_checker_key", link_title: "Manage kid(s) account" },
                {   id: 10002,  link_icon: "fas fa-gamepad", link_name: "Games", link_path: "game", link_title: "Games", link_dd:"Go-Quiz|game/go_quiz*" }]
                fetchStudentNavData(4, nav);
                return;
            }if(res.data.filter((item) =>  item.is_cs_paid && !item.is_setup_app_account && item.is_parent_access_app).length > 0){
                const nav = [{   id: 10000, link_dd: "", link_icon: "fas fa-users", link_name: "All Kid(s) Account", link_path: "account/all_kids", link_title: "Manage kid(s) account" }, {   id: 10001, link_dd: "", link_icon: "fas fa-cog", link_name: "App Setup", link_path: "account/kid/setup", link_title: "Manage account setup" }]
                setNavState(nav);
                setNavLoading(false);
                return;
            }
            
            // if(res.data.filter((item) => item.is_cs_paid).length > 0){
            //     const nav = [{   id: 10000, link_dd: "", link_icon: "fas fa-cog", link_name: "Kid(s) Account", link_path: "account/all_kids", link_title: "Manage kid(s) account" }, {   id: 10001, link_dd: "", link_icon: "fas fa-cog", link_name: "App Setup", link_path: "account/kid/setup", link_title: "Manage account setup" }]
            //     setNavState(nav);
            //     setNavLoading(false);
            //     return;
            // }
            const nav = [{   id: 10000, link_dd: "", link_icon: "fas fa-cog", link_name: "All Kid(s) Account", link_path: "account/all_kids", link_title: "Manage kid(s) account" }]
            setNavLoading(false);
            setNavState(nav);
        }).catch(e => {
            setExist(false);
            setLoading2(false);
            setParentAccessData([]);
            setError404(true);
            setPreloading(false);
        })
    }, []);
    const fetchStaffAccessData = (id, sid) => {
        setLoading2(true);
        setNavLoading(true);
        setPreloading(true);
        appServices4.getAccess(id, sid).then(res => {
            setExist(true);
            setAccessData(res.data);
            setLoading2(false);
            setNavLoading(false);
            setPreloading(false);
            setNavState([
                {   id: 10007,
                    link_dd: "",
                    link_icon: "fas fa-watch",
                    link_name: "Curriculum",
                    link_path: "curriculum",
                    link_title: "Manage Curriculum"
                },
                {   id: 10006,
                    link_dd: "",
                    link_icon: "fas fa-watch",
                    link_name: "Live Timetable",
                    link_path: "timetable",
                    link_title: "manage Live Timetable"
                },
                {   id: 10000,
                    link_dd: "",
                    link_icon: "fas fa-cog",
                    link_name: "Manage Student Attendance",
                    link_path: "attendance/student",
                    link_title: "Manage Student Attendance"
                },
                {   id: 10001,
                    link_dd: "",
                    link_icon: "fas fa-cog",
                    link_name: "Exam Question Entry",
                    link_path: "exam/question_entry",
                    link_title: "Exam Question Entry"
                },
                {   id: 10002,
                    link_dd: "",
                    link_icon: "fas fa-cog",
                    link_name: "Exam Supervision",
                    link_path: "exam/subject/supervision",
                    link_title: "Exam Subjects Supervision"
                },
                {   id: 10003,
                    link_dd: "",
                    link_icon: "fas fa-cog",
                    link_name: "Scores Entry",
                    link_path: "scores/entry",
                    link_title: "Scores Entry"
                },
                {   id: 10004,
                    link_dd: "",
                    link_icon: "fas fa-cog",
                    link_name: "Scores Entry (Form Teacher)",
                    link_path: "scores/form_master_entry",
                    link_title: "Form Teacher Scores Entry"
                },
                {   id: 10005,
                    link_dd: "",
                    link_icon: "fas fa-coins",
                    link_name: "My Payment",
                    link_path: "payment",
                    link_title: "Manage Payment"
                },
            ])
        }).catch(e => {
            setExist(false);
            setLoading2(false);
            setAccessData({id:0, uid:''});
            setError404(true);
            setPreloading(false);
        })
    }
    const fetchNavData = (pk) => {
        setNavLoading(true);
        appServices.getNavData(pk).then(res => {
            setNavState(res.data);
            setNavLoading(false);
        }).catch(error => {
            setNavState([])
            setNavLoading(false);
        });
            
    }

    const initsSESN = (sid) => {
        appServices5.initializeStudentExamSitNo(sid)
    }
    

    const fetchAccess = useCallback((sn, userr={}) => {
        setLoading2(true);
        appServices.getSchool(sn).then(res => {
            setSchool(res.data.title);
            setSchoolId(res.data.id);
            localStorage.removeItem('app_bg_color');
            localStorage.setItem('app_bg_color', res.data.pri_color);
            setSchoolDetail({...res.data});
            setSchoolCurr({...initialCurrState, symbol:res.data.curr_symbol, code:res.data.curr_code, id:res.data.curr_id})
            initsSESN(res.data.id);
        
            if(userr.user_type === "student"){
                fetchStudentAccessData(userr.id, res.data.id); 
            }
            else if(userr.user_type === "school_owner" || userr.user_type === "super_admin" || userr.user_type === "admin"){
                fetchSchool(sn, userr.id);
                fetchNavData(4);
            }
            else if(userr.user_type === "parent"){
                fetchParentAccessData(userr.uid, res.data.id);
            }
            else if(userr.user_type === "staff"){
                fetchStaffAccessData(userr.uid, res.data.id);
            }
            else{
                setExist(false);
                setLoading2(false);
                setError404(true);
            }
        }).catch(e => {
            setSchoolId(0);
            setSchool("");
            setSchoolDetail({working_time_start:'', working_time_end:'', school_name:'', school_icon:''})
            setSchoolCurr(initialCurrState);
            setLoading2(false);
            setExist(false);
        })
    }, [fetchSchool, fetchStudentAccessData, fetchParentAccessData])

    
    
    // const fetchUser =  useCallback(async () => {
    //     setLoading(true);
    //     setError(true);
    //     setSchool(schoolName);
    //     setSchoolId(0);
    //     await authHandler.load_user(load_user()).then(result => {
    //         setState({...result});
    //         setUser({...result.data});
    //         setLoading(false); 
    //         setError(false);
    //         fetchSchool(schoolName, result.data.id);
    //     }).catch(error => {
    //         setState({...error.response});
    //         setLoading(false);
    //         setError(true);
    //     })
    // }, [schoolName, fetchSchool]);
 
    useEffect(() => {
        setLoading(true);
        setLoading2(true);
        if(inner_state.type === "success"){
            setIsAuthenticated(inner_state.isAuthenticated);
            setError(false);
            setLoading(false);
            fetchAccess(schoolName, inner_state.data);
            // fetchSchool(schoolName, inner_state.data.id, inner_state.data);
        }
        else if(inner_state.type === "error"){
            setIsAuthenticated(inner_state.isAuthenticated);
            setLoading(false);
            setError(true);
            appServices.getSchool(schoolName).then(res => {
                setSchool(res.data.title);
                setLoading2(false);
            }).catch(e => {
                setSchool("");
                setLoading2(false);
            })
        }
    }, [ fetchAccess,  schoolName, inner_state])
    useEffect(() => {
        if(isAuthenticated && !loading){
            // setState({...inner_state});
            setUser({...inner_state.data});
        }
    }, [inner_state, loading, isAuthenticated])
    useEffect(() => {
        if(nav){
            return navigate(nav)
        }
    }, [navigate, nav]);

    useEffect(() => {
        if(!loading2){     
            if(school !== schoolName){
                setNav(`/`);
                return;
            }    
            else if(isAuthenticated === false){
                setNav(`/${schoolName}/login`);
                return;
            }
            else if(user.user_type === "pending"){
                setNav("/");
                return;
            }
            return;
        }
    }, [isAuthenticated, school, schoolName, user.id, user.user_type, navigate, loading2])
    const returnData = () => {
        // if(school === ""){
            if(user.user_type === "school_owner" || user.user_type === "super_admin" || user.user_type === "admin"){
                return(
                    <>
                        <VerticalNav name={user.first_name} honor={user.gender === "F" ? 'Mrs.' : 'Mr.'} img_src={user.profile_pic} loader={navLoading} data={[...navState]}>
                            <HorizontalNav user={user} title={school} school_id={schoolId} school={schoolDetail} user_id={user.id} user_type={user.user_type} img_src={schoolDetail.icon ? `${BACKEND_URL}${schoolDetail.icon}` : ""} />
                            <AdminRoutes school={{id:schoolId, name: schoolDetail.name, email: schoolDetail.email, is_start_student_enroll:schoolDetail.is_start_student_enroll, is_start_staff_enroll:schoolDetail.is_start_staff_enroll, icon:schoolDetail.icon, title:school, currency:schoolCurr, is_eapp_web_live:schoolDetail.is_eapp_web_live, is_start_setup_web:schoolDetail.is_start_setup_web, is_web_live:schoolDetail.is_web_live, is_start_student_payment:schoolDetail.is_start_student_payment, is_app_fee_only:schoolDetail.is_app_fee_only, working_time_start:schoolDetail.working_time_start, working_time_end:schoolDetail.working_time_end, curr_sess:schoolDetail.curr_session, is_vos:schoolDetail.is_vos, is_setup_subject:schoolDetail.is_setup_subject, is_trial:schoolDetail.is_trial, vo_space:schoolDetail.vo_space, trial_expiry:schoolDetail.trial_expiry}} user_type={user.user_type} user_id={user.id} user={user} />
                        </VerticalNav>
                        <SchoolAppStyle />
                        
                        
                    </>    
                )
            }
            else if(user.user_type === "student"){
                const sch_data = {id:schoolId, email: schoolDetail.email, name: schoolDetail.name, icon:schoolDetail.icon, is_start_student_enroll:schoolDetail.is_start_student_enroll, is_start_staff_enroll:schoolDetail.is_start_staff_enroll, is_start_setup_web:schoolDetail.is_start_setup_web, is_eapp_web_live:schoolDetail.is_eapp_web_live, is_setup_eapp_web:schoolDetail.is_setup_eapp_web, title:school, currency:schoolCurr, is_start_student_payment:schoolDetail.is_start_student_payment, is_app_fee_only:schoolDetail.is_app_fee_only, working_time_start:schoolDetail.working_time_start, working_time_end:schoolDetail.working_time_end, curr_sess:schoolDetail.curr_session, curr_symbol:schoolCurr.symbol, is_setup_subject:schoolDetail.is_setup_subject, is_trial:schoolDetail.is_trial, is_vos:schoolDetail.is_vos, vo_space:schoolDetail.vo_space, trial_expiry:schoolDetail.trial_expiry}
                return(
                    <>
                        {!error && (<><VerticalNav name={user.first_name} honor={user.gender === "F" ? 'Mrs.' : 'Mr.'} loader={navLoading} data={[...navState]}>
                            <HorizontalNav school_id={schoolId} school={schoolDetail} user={user} user_id={user.id} user_type={user.user_type} title={school} img_src={schoolDetail.icon ? `${BACKEND_URL}${schoolDetail.icon}` : ""} />
                            <StudentRoutes school={sch_data} user_type={user.user_type} user_id={user.id} access={accessData} />
                            {/* <AdminRoutes school={{id:schoolId, name: schoolDetail.school_name, icon:schoolDetail.school_icon, title:school, currency:schoolCurr, working_time_start:schoolDetail.working_time_start, working_time_end:schoolDetail.working_time_end}} user_type={user.user_type} user_id={user.id} user={user} /> */}
                        </VerticalNav>
                        <SchoolAppStyle /></>)
                        }
                    </>    
                )
            }
            else if(user.user_type === "parent"){
                const sch_data = {id:schoolId, email: schoolDetail.email, name: schoolDetail.name, is_start_student_enroll:schoolDetail.is_start_student_enroll, is_start_staff_enroll:schoolDetail.is_start_staff_enroll, is_start_setup_web:schoolDetail.is_start_setup_web, is_web_live:schoolDetail.is_web_live, is_eapp_web_live:schoolDetail.is_eapp_web_live, icon:schoolDetail.icon, title:school, currency:schoolCurr, working_time_start:schoolDetail.working_time_start, is_start_student_payment:schoolDetail.is_start_student_payment, is_app_fee_only:schoolDetail.is_app_fee_only, working_time_end:schoolDetail.working_time_end, curr_sess:schoolDetail.curr_session, curr_symbol:schoolCurr.symbol, is_vos:schoolDetail.is_vos, is_setup_subject:schoolDetail.is_setup_subject, is_trial:schoolDetail.is_trial, vo_space:schoolDetail.vo_space, trial_expiry:schoolDetail.trial_expiry}
                return(
                    <>
                        {!error && (<><VerticalNav loader={navLoading} data={[...navState]}>
                            <HorizontalNav school_id={schoolId} school={schoolDetail} user_id={user.id} user={user} user_type={user.user_type} title={school} img_src={schoolDetail.icon ? `${BACKEND_URL}${schoolDetail.icon}` : ""} />
                            <ParentRoutes school={sch_data} user_type={user.user_type} user={user} user_id={user.id} access={parentAccessData} />
                        </VerticalNav>
                        <SchoolAppStyle /></>)
                        }
                    </>    
                )
            }
            else if(user.user_type === "staff"){
                const sch_data = {id:schoolId, email: schoolDetail.email, is_start_student_enroll:schoolDetail.is_start_student_enroll, is_start_staff_enroll:schoolDetail.is_start_staff_enroll, name: schoolDetail.name, is_start_setup_web:schoolDetail.is_start_setup_web, is_setup_eapp_web:schoolDetail.is_setup_eapp_web, icon:schoolDetail.icon, title:school, is_start_student_payment:schoolDetail.is_start_student_payment, is_app_fee_only:schoolDetail.is_app_fee_only, currency:schoolCurr, working_time_start:schoolDetail.working_time_start, is_eapp_web_live:schoolDetail.is_eapp_web_live, working_time_end:schoolDetail.working_time_end, curr_sess:schoolDetail.curr_session, curr_symbol:schoolCurr.symbol, is_vos:schoolDetail.is_vos, is_setup_subject:schoolDetail.is_setup_subject, is_trial:schoolDetail.is_trial, vo_space:schoolDetail.vo_space, trial_expiry:schoolDetail.trial_expiry}
                const school_branch = {id:accessData.school_branch, name:accessData.school_branch_name}
                return(
                    <>
                        {!error && (<><VerticalNav loader={navLoading} data={[...navState]}>
                            <HorizontalNav school_id={schoolId} school={schoolDetail} user={user} user_id={user.id} user_type={user.user_type} title={school} img_src={schoolDetail.icon ? `${BACKEND_URL}${schoolDetail.icon}` : ""} />
                            <StaffRoutes school={sch_data} school_branch={school_branch} user_type={user.user_type} user_id={user.id} access={accessData} />
                        </VerticalNav>
                        <SchoolAppStyle /></>)
                        }
                    </>    
                )
            }
            else{
                
                return (
                    <>
                        {!error && (<><VerticalNav>
                            <HorizontalNav />
                        </VerticalNav>
                        <SchoolAppStyle  /></>)
                        }
                    </>    
                );
            }
        // }
        // else{
                
        //     return (
        //         <>

        //         </>    
        //     );
        // }
        
    }
    
    
    
        
        return(
            <>
            {preloading && <PreloaderLine />}
            {!loading2 && (
                <>
                {error404 && (
                    <>
                    <Redirect error404 title='Page Not Found' link1='/' link1_title='Go Back' />
                    </>
                )}
                {exist && (
                    <>
                    {returnData()}
                    
                    </>
                )}
                </>
            )}
            </>
        )
        
   
       
}

export default Layout;