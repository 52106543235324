import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000;
    z-index: 999999990000;
`;