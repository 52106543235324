import React, { useState, useEffect, Fragment } from "react";
import {PDFDownloadLink, PDFViewer, Document, Font, Page, View, Image, Text} from "@react-pdf/renderer";
import Html from 'react-pdf-html';

import raleway_regular from 'font/raleway.regular.ttf';
import raleway_semibold from 'font/raleway.semibold.ttf';
import raleway_bold from 'font/raleway.bold.ttf';
import raleway_extrabold from 'font/raleway.extrabold.ttf';

import voskool_logo from "images/voskool_logo.png";

import { appServices } from 'api/schoolApp/services';
import { BACKEND_URL } from 'actions/url';
import { oneToHundred } from 'components/app/school/actions/1_100';

import {pdfStyle} from './style';

import { timify, timifyInterval } from "components/app/school/actions/timify";
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";

const ExamQuestionPdf = ({id, user_id, sess_id, subject_id, class_id, school, schoolColor, changeState, is_download=true}) => {

    const [allEQData, setAllEQData] = useState({obj:[], theory:[], fill_in_gap:[], is_fetched:false});
    const [allEQFData, setAllEQFData] = useState([]);

    const [state, setState] = useState({id:0});
    const [sessionState, setSessionState] = useState({});
    const [examSubjectState, setExamSubjectState] = useState({id:0, supervisor:[]});

    const [preloading, setPreloading] = useState(true);
    const [loadEqData, setLoadEqData] = useState(false);
    const [err, setErr] = useState(false);
    const [error, setError] = useState([]);
    const [reload, setReload] = useState(false);

    const fetchSession = (sid) => {
        if(sid){
            appServices.getCurrentSession(sid).then(res => {
                setSessionState(res.data);
            }).catch(e => {
                setSessionState({});
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading This Page'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading This Page'}`]);
            })
        }
    }
    const fetchExamSubjectTimeTable = (ssid, subid) => {
        if(ssid && subid){
            appServices.getSchoolExamTimetableSubject(ssid, subid).then(res => {
                setExamSubjectState(res.data);
            }).catch(e => {
                setExamSubjectState({id:0});
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`]);
            })
        }
    }

    const fetchExamRecord = (subid, ssid, cid) => {
        if(subid && ssid && cid){
            setPreloading(true);
            appServices.getSchoolExamQuestionSubject1(subid, ssid, cid).then(res => {
                if(res.data.is_uploaded){
                    setState(res.data);
                    setLoadEqData(true);
                    return;
                }
                setPreloading(false);
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, 'Error..., Exam Question Has Not Been Uploaded'] : ['Error..., Exam Question Has Not Been Uploaded']);
            }).catch(e => {
                setPreloading(false);
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'}`]);
            })
        }
    }
    const fetchAllEQ = (id, ssid, subid, ob="") => {
        if(id && ssid && subid){
            setPreloading(true);
            appServices.getAllSchoolExamQuestionPdfData(id, ssid, subid, ob).then(res => {
                setPreloading(false);
                setAllEQData(res.data);
            }).catch(e => {
                setPreloading(false);
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`]);
            })
        }
    }
    const fetchAllEQF = (id) => {
        appServices.getAllSchoolExamMultiQuestionFig(id).then(res => {
            setAllEQFData(res.data);
        }).catch(e => {
            setErr(true);
            setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`]);
        })
    }
    
    Font.register({family:'Raleway', fonts:[{src:`${raleway_regular}`}, {src:raleway_semibold, fontWeight:600} , {src:raleway_bold, fontWeight:700} , {src:raleway_extrabold, fontWeight:800}]});

    useEffect(() => {
        fetchExamRecord(subject_id, sess_id, class_id);
        fetchSession(school.id);
    }, [sess_id, class_id, subject_id, school])
    useEffect(() => {
        if(loadEqData){
            setLoadEqData(false);
            fetchAllEQ(state.uid, sess_id, subject_id, 'order');
            fetchExamSubjectTimeTable(sess_id, subject_id);
            fetchAllEQF(state.uid);
        }
    }, [loadEqData, state, sess_id, subject_id]);

    useEffect(() => {
        if(reload){
            setPreloading(true);
            setErr(false);
            setError([]);
            fetchExamRecord(subject_id, sess_id, class_id);
            fetchSession(school.id);
            setReload(false);
        }
    }, [sess_id, class_id, subject_id, school, reload])

    const theoryCompQuest = allEQData.theory.filter((item) => item.is_quest_compulsory === true);
    const fillGapCompQuest = allEQData.fill_in_gap.filter((item) => item.is_quest_compulsory === true);
    const objFig = allEQFData.filter((item) => item.exam_type === "obj");
    const theoryFig = allEQFData.filter((item) => item.exam_type === "theory");
    const fillGapFig = allEQFData.filter((item) => item.exam_type === "fill in gap");

    const FigInsert = (arr=[]) => {
        if(arr.length > 4){
            let data = []
            for(let i = 0; i < arr.length; i = i + 4){
                let init_arr = [];
                let begin = i;
                let end = i + 4;
                for(let j = begin; j < arr.length; j++){
                    if(j >= end){
                        break;
                    }
                    init_arr.push({...arr[j]});
                }
                data.push({num:i, arr:init_arr});
            }
            return data;
        }
        return [{num:0, arr:arr}];
    }

    const questImgFunc = (quest_pic='', type="", is_pic_bottom=false) => {
        if(type === "bottom" && quest_pic && is_pic_bottom){
            return(
                <><Image src={`${BACKEND_URL}${quest_pic}`} style={{maxWidth:'40%', maxHeight:'100px'}} /><Text style={{width:'4%'}}></Text></>
            )
        }
        if(type === "normal"){
            return(
                <><Image src={`${BACKEND_URL}${quest_pic}`} style={{maxWidth:'40%', maxHeight:'100px'}} /><Text style={{width:'4%'}}></Text></>
            )
        }
        return (<></>);
    }
    console.log(allEQData)
    const time_allowed = timifyInterval(examSubjectState.time_interval * 60 * 60);

    const insertDocument = () => {
        return(
            <Document>
                <Page size={'A4'} wrap break>
                    <Text style={pdfStyle(schoolColor).pagination} render={({pageNumber, totalPages}) => (
                        `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                    <View style={pdfStyle(schoolColor).page}>
                        <View style={pdfStyle(schoolColor).heading_container}>
                            <Image src={`${BACKEND_URL}${school.icon}`} style={pdfStyle(schoolColor).heading_logo} />
                            <Text style={pdfStyle(schoolColor).heading_text}>{school.name}</Text>
                        </View>
                        <View style={pdfStyle(schoolColor).heading_container2}>
                            <Text style={pdfStyle(schoolColor).w10}></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w50}}>Session: <Text style={{...pdfStyle(schoolColor).fw500, textTransform:'capitalize'}}>{sessionState.session} {sessionState.term_name} Term</Text></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w40}}>Class: <Text style={pdfStyle(schoolColor).fw500}>{state.class_name}</Text></Text>
                        </View>
                        <View style={pdfStyle(schoolColor).heading_container2}>
                            <Text style={pdfStyle(schoolColor).w10}></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w50}}>Subject Name: <Text style={pdfStyle(schoolColor).fw500}>{state.subject_name}</Text></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w40}}>Subject Code: <Text style={pdfStyle(schoolColor).fw500}>{state.subject_code}</Text></Text>
                        </View>
                        <View style={pdfStyle(schoolColor).heading_container2}>
                            <Text style={pdfStyle(schoolColor).w10}></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w50}}>Exam Time: <Text style={pdfStyle(schoolColor).fw500}>{timify(examSubjectState.start_time)} - {timify(examSubjectState.end_time)}</Text></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w40}}>Time Allowed: <Text style={pdfStyle(schoolColor).fw500}>{time_allowed.hour ? `${time_allowed.hour} Hour${time_allowed.hour > 1 ? 's' : ''}` : ''} {time_allowed.minute ? `${time_allowed.minute} Minutes` : ''}</Text></Text>
                        </View>
                        <View style={pdfStyle(schoolColor).heading_container2}>
                            <Text style={pdfStyle(schoolColor).w10}></Text>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w90}}>Date: <Text style={pdfStyle(schoolColor).fw500}>{convertDate(new Date(examSubjectState.exam_date), 'ddMMyyyy')}</Text></Text>
                        </View>
                        <View style={pdfStyle(schoolColor).cont_voskool_logo}>
                            <Image src={voskool_logo} style={pdfStyle(schoolColor).voskool_logo} />
                        </View>
                        <View style={{...pdfStyle(schoolColor).heading_container2, marginTop:'4px', justifyContent:'flex-start'}}>
                            <Text style={{...pdfStyle(schoolColor).mini_heading_text, ...pdfStyle(schoolColor).w20, fontSize:'10px', textAlign:'right'}}>GUIDELINES:</Text>
                            <Text style={{...pdfStyle(schoolColor).fs8px, ...pdfStyle(schoolColor).fw500, ...pdfStyle(schoolColor).w70}}>No gawking or side-seeing, no sidetalking, no mobile phones allowed in the exam hall while exam is going on. Read the instructions carefully before answering any questions</Text>
                        </View>
                        {allEQData.obj.length > 0 && (
                            <>
                                <View style={pdfStyle(schoolColor).section_header}>
                                    <Text style={pdfStyle(schoolColor).section_header_text}>SECTION A: OBJECTIVE</Text>
                                </View>
                                <View style={pdfStyle(schoolColor).heading_container2}>
                                    <Text style={pdfStyle(schoolColor).w10}></Text>
                                    <Text style={{...pdfStyle(schoolColor).mini_heading_text, width:'13%', fontSize:'11px'}}>Instructions: </Text><Text style={{...pdfStyle(schoolColor).fw600, width:'70%', fontSize:'9px'}}>(1) Shade/Tick/Mark all the correct answers. (2). over-writing is not permitted. (3) Answer {state.obj_quest === state.obj_quest_ans ? "all" : `any ${oneToHundred(state.obj_quest_ans)}(${state.obj_quest_ans})`} questions. {objFig.length > 0 && ' (4) Use the figures below to answer these questions'} </Text>
                                </View>
                                {objFig.length > 0 && FigInsert(objFig).map((it, k) => {
                                    return(
                                        <Fragment key={k}>
                                            <View style={{...pdfStyle(schoolColor).heading_container2, marginLeft:'5%', marginRight:'5%'}}>
                                                {it.arr.map((item, i) => (
                                                    <Fragment key={i}>
                                                        <View style={{position:'relative', maxWidth:'33%', maxHeight:'100px', marginBottom:'10px', marginRight:'10px'}}>
                                                            <Image src={`${BACKEND_URL}${item.quest_pic}`} style={{maxWidth:'100%', maxHeight:'100px'}} />
                                                            <View style={pdfStyle(schoolColor).heading_container_wrapper}>
                                                                <View style={pdfStyle(schoolColor).section_obj_cont2}>
                                                                    <Text style={pdfStyle(schoolColor).section_obj_cont_text}>Fig {item.name}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </Fragment>
                                                ))}
                                            </View>
                                        </Fragment>
                                    )
                                })}
                                <View style={pdfStyle(schoolColor).section_obj}>
                                    {allEQData.obj.map((item, i) => {
                                        const j = i + 1;
                                        const quest = `${item.quest}`
                                        return(
                                            <View key={j}>
                                                <View style={pdfStyle(schoolColor).section_obj_cont}>
                                                    <Text style={pdfStyle(schoolColor).numbering}>({j})</Text>
                                                    {item.quest_pic && (<>{questImgFunc(item.quest_pic, "normal")}</>)}
                                                    <Text style={{...pdfStyle(schoolColor).section_obj_cont_text, width: item.quest_pic ? '75%' : '90%'}}><Html style={{fontSize: 10}}>{quest}</Html></Text>
                                                </View>
                                                <View style={pdfStyle(schoolColor).section_obj_cont2}>
                                                    <Text style={pdfStyle(schoolColor).numbering}></Text>
                                                    <Text style={pdfStyle(schoolColor).section_obj_cont_text}><Text style={pdfStyle(schoolColor).numbering}>(a)</Text> {item.obj_a}  <Text style={pdfStyle(schoolColor).numbering}>(b)</Text> {item.obj_b} {item.obj_c && <><Text style={pdfStyle(schoolColor).numbering}>(c)</Text> {item.obj_c}</>} {item.obj_d &&<><Text style={pdfStyle(schoolColor).numbering}>(d)</Text> {item.obj_d}</>}</Text>
                                                    {item.mark && <Text style={pdfStyle(schoolColor).obj_cont_mark_text}> {item.mark} mark </Text>}
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>
                            </>
                        )}
                        {allEQData.theory.length > 0 && (
                            <>
                                <View style={pdfStyle(schoolColor).section_header}>
                                    <Text style={pdfStyle(schoolColor).section_header_text}>{allEQData.obj.length > 0 ? 'SECTION B: THEORY' : 'SECTION A: THEORY'}</Text>
                                </View>
                                <View style={pdfStyle(schoolColor).heading_container2}>
                                    <Text style={pdfStyle(schoolColor).w10}></Text>
                                    <Text style={{...pdfStyle(schoolColor).mini_heading_text, width:'13%', fontSize:'11px'}}>Instructions: </Text><Text style={{...pdfStyle(schoolColor).fw600, width:'70%', fontSize:'9px'}}> {theoryCompQuest.length > 0 ? `(1). Answer all compulsory questions and any other ${oneToHundred(state.theory_quest_ans - theoryCompQuest.length)} (${state.theory_quest_ans - theoryCompQuest.length}) question(s)` : `(1) Answer any ${oneToHundred(state.theory_quest_ans)} (${state.theory_quest_ans}) question(s)`} {theoryFig.length > 0 && '   (2) Use the figures below to answer these question(s)'}</Text>
                                </View>
                                {theoryFig.length > 0 && FigInsert(theoryFig).map((it, k) => {
                                    return(
                                        <Fragment key={k}>
                                            <View style={{...pdfStyle(schoolColor).heading_container2, marginLeft:'5%', marginRight:'5%'}}>
                                                {it.arr.map((item, i) => (
                                                    <Fragment key={i}>
                                                        <View style={{position:'relative', maxWidth:'33%', maxHeight:'100px', marginBottom:'10px', marginRight:'10px'}}>
                                                            <Image src={`${BACKEND_URL}${item.quest_pic}`} style={{maxWidth:'100%', maxHeight:'100px'}} />
                                                            <View style={pdfStyle(schoolColor).heading_container_wrapper}>
                                                                <View style={pdfStyle(schoolColor).section_obj_cont2}>
                                                                    <Text style={pdfStyle(schoolColor).section_obj_cont_text}>Fig {item.name}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </Fragment>
                                                ))}
                                            </View>
                                        </Fragment>
                                    )
                                })}
                                <View style={pdfStyle(schoolColor).section_obj}>
                                    {allEQData.theory.map((item, i) => {
                                        const j = i + 1;
                                        const quest = `${item.quest}`
                                        return(
                                            <View key={j}>
                                                <View style={{...pdfStyle(schoolColor).section_obj_cont, marginBottom:'9px'}}>
                                                    <Text style={pdfStyle(schoolColor).numbering}>({j})</Text>
                                                    {item.quest_pic && <><Image src={`${BACKEND_URL}${item.quest_pic}`} style={{maxWidth:'40%', maxHeight:'100px'}} /><Text style={{width:'4%'}}></Text></>}
                                                    <Text style={{...pdfStyle(schoolColor).section_obj_cont_text, width:item.quest_pic ? '55%' : '82%'}}>{item.is_quest_compulsory && <Text style={{fontWeight:700, fontSize:'9px'}}>(COMPULSORY) </Text>}<Html style={{ fontSize: 10 }}>{quest}</Html></Text>
                                                    {item.mark && <Text style={pdfStyle(schoolColor).obj_cont_mark_text}> {item.mark} mark </Text>}
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>
                            </>
                        )}

                        {allEQData.fill_in_gap.length > 0 && (
                            <>
                                <View style={pdfStyle(schoolColor).section_header}>
                                    {allEQData.obj.length > 0 ? (
                                        <Text style={pdfStyle(schoolColor).section_header_text}>{allEQData.theory.length > 0 ? 'SECTION C: FILL IN THE GAP' : 'SECTION B: FILL IN THE GAP'}</Text>
                                    ) : (<Text style={pdfStyle(schoolColor).section_header_text}>{allEQData.theory.length > 0 ? 'SECTION B: FILL IN THE GAP' : 'SECTION A: FILL IN THE GAP'}</Text>)}      
                                </View>
                                <View style={pdfStyle(schoolColor).heading_container2}>
                                    <Text style={pdfStyle(schoolColor).w10}></Text>
                                    <Text style={{...pdfStyle(schoolColor).mini_heading_text, width:'13%', fontSize:'11px'}}>Instructions: </Text><Text style={{...pdfStyle(schoolColor).fw600, width:'70%', fontSize:'9px'}}> {fillGapCompQuest.length > 0 ? `(1) Answer all compulsory questions and any other ${oneToHundred(state.fill_gap_quest_ans - fillGapCompQuest.length)} (${state.fill_gap_quest_ans - fillGapCompQuest.length}) question(s)` : `(1) Answer any ${oneToHundred(state.fill_gap_quest_ans)} (${state.fill_gap_quest_ans}) question(s)`} {fillGapFig.length > 0 && '   (2) Use the figures below to answer these question(s)'}</Text>
                                </View>
                                {fillGapFig.length > 0 && FigInsert(fillGapFig).map((it, k) => {
                                    return(
                                        <Fragment key={k}>
                                            <View style={{...pdfStyle(schoolColor).heading_container2, marginLeft:'5%', marginRight:'5%'}}>
                                                {it.arr.map((item, i) => (
                                                    <Fragment key={i}>
                                                        <View style={{position:'relative', maxWidth:'33%', maxHeight:'100px', marginBottom:'10px', marginRight:'10px'}}>
                                                            <Image src={`${BACKEND_URL}${item.quest_pic}`} style={{maxWidth:'100%', maxHeight:'100px'}} />
                                                            <View style={pdfStyle(schoolColor).heading_container_wrapper}>
                                                                <View style={pdfStyle(schoolColor).section_obj_cont2}>
                                                                    <Text style={pdfStyle(schoolColor).section_obj_cont_text}>Fig {item.name}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </Fragment>
                                                ))}
                                            </View>
                                        </Fragment>
                                    )
                                })}
                                <View style={pdfStyle(schoolColor).section_obj}>
                                    {allEQData.fill_in_gap.map((item, i) => {
                                        const j = i + 1;
                                        const quest = `${item.quest}`
                                        return(
                                            <View key={j}>
                                                <View style={{...pdfStyle(schoolColor).section_obj_cont, marginBottom:'9px'}}>
                                                    <Text style={pdfStyle(schoolColor).numbering}>({j})</Text>
                                                    {item.quest_pic && <><Image src={`${BACKEND_URL}${item.quest_pic}`} style={{maxWidth:'40%', maxHeight:'100px'}} /><Text style={{width:'4%'}}></Text></>}
                                                    <Text style={{...pdfStyle(schoolColor).section_obj_cont_text, width:item.quest_pic ? '55%' : '82%'}}>{item.is_quest_compulsory && <Text style={{fontWeight:700, fontSize:'9px'}}>(COMPULSORY) </Text>}<Html style={{ fontSize: 10 }}>{quest}</Html></Text>
                                                    {item.mark && <Text style={pdfStyle(schoolColor).obj_cont_mark_text}> {item.mark} mark </Text>}
                                                </View>
                                            </View>
                                        )
                                    })}
                                </View>
                                <View style={{marginTop:'10px', width:'100%', textAlign:'right'}}>
                                    <View>
                                        <Text style={{...pdfStyle(schoolColor).fw600, fontSize:'12px'}}>SUPERVISOR(S)</Text>
                                    </View>
                                    <View>
                                        <Text style={{...pdfStyle(schoolColor).fw500, fontSize:'10px'}}>{examSubjectState.supervisor.map((item, i, arr) => (
                                            <Fragment key={i}>
                                                {item.staff_last_name} {item.staff_first_name}{arr.length > 1 ? ', ' : ''}
                                            </Fragment>
                                        ))}</Text>
                                    </View>
                                </View>
                            </>
                        )}
                        
                    </View>
                </Page>
                </Document>
        )
    }
    console.log(error);
    return(
        <>
        {!preloading && (
            <>
            {!err ? (
                <>
                {is_download ? (
                    <PDFDownloadLink document={insertDocument()} fileName={`${school.title.toUpperCase()} ${sessionState.session} ${sessionState.term_name} Term ${state.class_name} ${state.subject_name} Exam Question`}>
                    {({ loading }) => (loading ? 'Loading document...' : `Download ${state.subject_name} Exam Question PDF!`)}
                    </PDFDownloadLink>
                ) : (
                    <PDFViewer showToolbar={false} style={{width:'100%', height:'70vh'}}> 
                        {insertDocument()}
                    </PDFViewer>
                )}
                </>
            ) : (
                <div style={{display:'block', textAlign:'center', width:'100%'}}>
                    <span className="font-med disp-block" style={{fontWeight:600, color:'red'}}>PDF Proccessed With The Following Errors</span><br />
                    <span className="font-small" style={{fontWeight:600, color:'red'}}>{error.map((item) => item)}</span>
                    <button className="btn bg-blue hover" style={{color:'#eee'}} type="button" onClick={() => setReload(true)}>Reload</button>
                </div>
            )}
            
            </>
        )}     
      </>  
    )
}

export default ExamQuestionPdf;