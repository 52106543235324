import React, { useState, useEffect, useMemo, Fragment, useCallback, useRef } from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from 'components/app/school/SmallerComponents/Section';
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import MoneyBoxWrapper from 'components/app/school/SmallerComponents/moneyBox/wrapper';
import MoneyBox from 'components/app/school/SmallerComponents/moneyBox';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { addItemToArray, addDateArrayToArray } from 'components/app/school/actions/array-utils/Add';
import { groupArray } from 'components/app/school/actions/array-utils/Group';
import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';
import { timify } from 'components/app/school/actions/timify';
import { convertNumber } from 'components/app/school/actions/money.js';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { BACKEND_URL } from 'actions/url';

const SchoolFinance = ({school_id, user_id, user_type, currency={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialState = useMemo(() => ({p:1, size:50, session:''}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);
    
    const [state, setState] = useState(initialState);

    const [allSession, setAllSession] = useState([]);
    const [allClasses, setAllClasses] = useState([]);
    const [allSchoolBranch, setAllSchoolBranch] = useState([]);
    const [financeState, setFinanceState] = useState(initialPaginationState);
    const [finance, setFinance] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [isNewData, setIsNewData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [viewFinance, setViewFinance] = useState(JSON.parse(localStorage.getItem('vf')) === false ? false : true);


    const buttonRef = useRef(null);

    const fetchAllSession = (sid, init={}) => {
        setError((prev) => {return {...prev, data:prev.data}});
        appServices.getAllSession(sid).then(res => {
            setAllSession(res.data);
            setState({...init, session:res.data[0].id});
        }).catch(e => {
            setAllSession([]);
            setReload(false);
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'Session Data Fetch Error'], click_text:'Reload', handleClick: () => setReload(true)}});
        })
    }

    const fetchAllSchoolFinance = (ssid, p=1, size=2, inc=false, next=false, data={school_branch:'', classes:''}) => {
        setError((prev) => {return {...prev, data:prev.data}});
        setSpinLoading(inc);
        if(Object.values(data).length > 0 && p === 1 && !next){
            setLoading(true);
        }
        appServices.getAllPayment(ssid, p, size, data.school_branch, data.classes).then(res => {
            if(inc && next){
                setFinanceState((prev) => prev.results.length > 0 ? {...res.data, results:groupArray([...prev.results, ...res.data.results], 'id')} : {...res.data, results:addItemToArray(res.data.results)});
                setState((prev) => {return {...prev, p:p}});
                setPreloading2(false);
                setSpinLoading(false);
                setPreloading(false);
                setLoading(false);
                return;
            }
            setFinanceState({...res.data, results:addItemToArray(res.data.results)});
            setPreloading2(false);
            setSpinLoading(false);
            setPreloading(false);
            setLoading(false);
        }).catch(e => {
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'School Payment Data Fetch Error'], click_text:'Reload', handleClick: () => setReload(true)}});
            setPreloading2(false);
            setSpinLoading(false);
            setPreloading(false);
        })
    }
    const fetchAllFinance = (sid) => {
        setError({title:'', data:[]});
        appServices.getAllFinance(sid).then(res => {
            setFinance(res.data);
        }).catch(e => {
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'Finance Data Fetch Error']}});
        })
    }
    const fetchAllSchoolBranch = (sid) => {
        setError((prev) => {return {...prev, data:prev.data}});
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranch(res.data);
        }).catch(e => {
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'School Branch Data Fetch Error'], click_text:'Reload', handleClick: () => setReload(true)}});
            setAllSchoolBranch([]);
        })
    }
    const fetchAllClasses = (sid) => {
        setError((prev) => {return {...prev, data:prev.data}});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClasses(res.data);
        }).catch(e => {
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'Class Data Fetch Error'], click_text:'Reload', handleClick: () => setReload(true)}});
            setAllClasses([]);
        })
    }
    const handleStateChange = e => {
        setState((prev) => {return {...prev, [e.target.name]:e.target.value, p:1}})
        setIsNewData(true);
    }
    const onClickEye = () => {
        setViewFinance(!viewFinance);
        localStorage.setItem('vf', !viewFinance);
    }
    const handleScrollLoadMore = useCallback(() => {
        if(financeState.next){
            const ref = buttonRef.current ? buttonRef.current.getBoundingClientRect() : {top:0, bottom:0};
            if(parseInt(ref.bottom) < 750 && parseInt(ref.bottom) > 735 && !spinLoading){
                fetchAllSchoolFinance(state.session, state.p+1, state.size, true, financeState.next ? true : false, {school_branch:state.school_branch, classes:state.classes});
                return;
            }
            if(parseInt(ref.bottom) < 620 && parseInt(ref.bottom) > 602 && !spinLoading){
                fetchAllSchoolFinance(state.session, state.p+1, state.size, true, financeState.next ? true : false, {school_branch:state.school_branch, classes:state.classes});
                return;
            }
        }
    }, [state, financeState.next, spinLoading])

    useEffect(() => {
        if(reload && state.session){
            setFinanceState(initialPaginationState);
            fetchAllSchoolFinance(state.session, 1, state.size, false, false, {school_branch:state.school_branch, classes:state.classes});
            fetchAllFinance(school_id);
            setReload(false);
            return;
        }
        if(reload){
            fetchAllSession(school_id, initialState);
            fetchAllClasses(school_id);
            fetchAllSchoolBranch(school_id);
            fetchAllFinance(school_id);
            return;
        }
        if(isNewData){
            setFinanceState(initialPaginationState);
            fetchAllSchoolFinance(state.session, 1, state.size, false, false, {school_branch:state.school_branch, classes:state.classes})
            setIsNewData(false);
        }
    }, [school_id, state, reload, initialState, initialPaginationState, isNewData])

    useEffect(() => {
        if(financeState.next){
            window.addEventListener('scroll', handleScrollLoadMore);

            return () => {
                window.removeEventListener('scroll', handleScrollLoadMore)
            }
        }
    }, [financeState, handleScrollLoadMore])

   
    
    
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertSessionOption = allSession.map((item, i) => (
        <option key={i} value={item.id}>{item.session} - {item.term_name.toUpperCase()} Term</option>
    ))
    const insertSchoolBranchOption = allSchoolBranch.map((item, i) => (
        <option key={i} value={item.id}>{item.name} </option>
    ))
    const insertClassesOption = allClasses.map((item, i) => (
        <option key={i} value={item.id}>{item.name} </option>
    ))
    const tot_amt_finance = financeState.results.length > 0 ? financeState.results.reduce((val, item) => {
        val +=  parseFloat(item.tot_amt_school)
        return val;
    }, 0) : 0;
    const tot_amt_finance_session = finance.length > 0 ? finance.reduce((val, item) => {
        val = {tot_amt: val.tot_credited + parseFloat(item.tot_credited) - (val.tot_debited + parseFloat(item.tot_debited)), tot_credited: val.tot_credited + parseFloat(item.tot_credited), tot_debited: val.tot_debited + parseFloat(item.tot_debited), prev_amt: val.prev_amt + parseFloat(item.prev_amt)};
        return val;
    }, {tot_amt:0, tot_credited:0, tot_debited:0, prev_amt:0}) : {tot_amt:0, tot_credited:0, tot_debited:0, prev_amt:0};
    return(
        <>
            <HeadComponent title='Manage Finance' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                    <GridContent>
                        {loading && <Loader />}
                        {preloading ? (<ul>
                        <BlankLoader num={20} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                    
                    <Section>
                            <div className='w100' style={{borderBottom:'3px solid blue', borderBottomLeftRadius:'10px'}}>
                                <div className='disp-block'>
                                    <span className='fw-700 font-very-small app-text-sec-color'>Total Balance Remaining::</span>
                                </div>
                                <div className='disp-flex jc-sb wrap' style={{padding:'2px 7px'}}>
                                    <div>
                                        <span className='fw-700 font-super-big' style={{color:'var(--app_opp_theme)'}}>{viewFinance ? `${currency.symbol}${convertNumber(tot_amt_finance_session.tot_amt)}` : `******`}</span>
                                    </div>
                                    <div>
                                        <i className={viewFinance ? 'fas fa-eye' : 'fas fa-eye-slash'} onClick={() => onClickEye()} style={{cursor:'pointer', color:'#777'}}></i>
                                    </div>
                                </div>
                            </div>
                            <MoneyBoxWrapper style={{justifyContent:'space-between'}} >
                                <MoneyBox title={'Total Credited:'} classname_wrapper='app-theme' rise={true} amt={viewFinance ? `${currency.symbol}${convertNumber(tot_amt_finance_session.tot_credited)}` : '****'}  />
                                <MoneyBox title={'Total Debited:'} classname_wrapper='app-theme' rise={true} enable_rise={false} debit amt={viewFinance ? `-${currency.symbol}${convertNumber(tot_amt_finance_session.tot_debited)}` : '****'} />
                            </MoneyBoxWrapper>
                        </Section>
                        <Section>
                            <Mallet>
                                <div className='flex-50'>
                                    <label className='font-slightly-small'>Select Session: 
                                    <select name="session" onChange={e => handleStateChange(e)} value={state.session} className='form-control-theme font-slightly-small' >
                                        <option value={""} disabled>Select Session</option>
                                        {insertSessionOption}
                                    </select></label>
                                </div>
                                <div className='flex-50'>
                                    <label className='font-slightly-small'>Filter By School Branch: 
                                    <select name="school_branch" value={state.school_branch} onChange={e => handleStateChange(e)} className='form-control-theme font-slightly-small' >
                                        <option value={""}>Select School Branch</option>
                                        {insertSchoolBranchOption}
                                    </select></label>
                                </div>
                                <div className='flex'>
                                    <label className='font-slightly-small'>Filter By Class: 
                                    <select name="classes" value={state.classes} onChange={e => handleStateChange(e)} className='form-control-theme font-slightly-small' >
                                        <option value={""}>Select Class</option>
                                        {insertClassesOption}
                                    </select></label>
                                </div>
                            </Mallet>
                            {financeState.results.length > 0 ? (
                                <>
                                    {addDateArrayToArray(financeState.results, 'pay_date', true).map((item, i) => {
                                        return(
                                            <Fragment key={i}>
                                            <div className='disp-block w100' style={{padding:'10px 15px'}}>
                                                <h6 className='fw-700 font-very-small app-text-color' style={{color:'#555'}}>{convertDate(item.date, 'ddMMyyyy')}</h6>
                                                <Table >
                                                        {item.arr.map((item_, j) => {
                                                            const profile_pic = item_.user_student_profile_pic ? item_.user_student_profile_pic : item_.student_profile_pic;
                                                            const student_name = item_.user_student_last_name ? `${item_.user_student_last_name} ${item_.user_student_first_name}` : `${item_.student_last_name} ${item_.student_first_name}`;
                                                            return(
                                                                <Fragment key={j}>
                                                                    <Tr btn={false} sn={true}>
                                                                        <div className="c-data sn"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${profile_pic}`} alt="Profile" style={{width:"30px", height:'30px', borderRadius:'50%'}} /></div></div>
                                                                        <div className="c-data">
                                                                            <div className='disp-block'>
                                                                                <span className="font-slightly-small fw-600 text-capitalize">{student_name}</span>
                                                                            </div>
                                                                            <div className='disp-block padd-4px'>
                                                                                <span className="font-very-small fw-600 text-capitalize" style={{color:'#666'}}><b>By: </b>{item_.paid_by_last_name} {item_.paid_by_first_name} - {item_.paid_by_user_type} <b style={{textTransform:'lowercase'}}> at </b> {timify(item_.pay_date.slice(11, 19))}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="c-data"><span className="font-slightly-small fw-700 app-text-color">{item_.type === "cr" ? (<b style={{color:'rgb(25, 250, 35)'}}>+{currency.symbol}{convertNumber(item_.tot_amt_school)}</b>) : convertNumber(item_.tot_amt_school)}</span></div>
                                                                        <TdWrapper center header_num={j}>
                                                                            <Td profile_pic={`${BACKEND_URL}${profile_pic}`} />
                                                                            <Td heading="Amount"  styleText={item_.type === "cr" ? {color:'rgb(25, 250, 35)', fontWeight:700} : {fontWeight:700}} text={`${currency.symbol}${convertNumber(item_.tot_amt_school)}`} />
                                                                            <Td heading="Invoice"  text_class='text-capitalize' text={item_.invoice} />
                                                                            <Td heading="Payer's Name"  text_class='text-capitalize' text={student_name} />
                                                                            <Td heading="Payee's Name"  text_class='text-capitalize' text={`${item_.paid_by_last_name} ${item_.paid_by_first_name} `} />
                                                                            <Td heading="Total Item Bought"   text={item_.cart.length} />
                                                                            <Td heading="Paid By"  text_class='text-capitalize' text={item_.paid_by_user_type} />
                                                                            <Td heading="Date Paid"  text={`${convertDate(item_.pay_date.slice(0, 10), 'yyyy-mm-dd')} at ${timify(item_.pay_date.slice(11, 19))}`} />
                                                                            {/* <Td link={`${item_.uid}`} text_link='View More'  /> */}
                                                                        </TdWrapper>
                                                                    </Tr>
                                                                </Fragment>
                                                            )
                                                        })}
                                                </Table>
                                            </div>
                                            </Fragment>
                                        )
                                    })}
                                    <div className='disp-block padd-4px align-right'>
                                        <h4 className='app-text-color fw-700 font-med'>Total Amount: {currency.symbol} {convertNumber(tot_amt_finance)}</h4>
                                    </div>
                                    {spinLoading && <Spinner />}
                                    {financeState.next && (
                                        <div className='disp-block align-center' ref={buttonRef}>
                                            <button type="button" className='btn app-bg-color hover-opacity app-bg-text-color br' onClick={() => fetchAllSchoolFinance(state.session, state.p+1, state.size, true, financeState.next ? true : false, {school_branch:state.school_branch, classes:state.classes})} style={{padding:'6px'}}>Load More <i className='fas fa-arrow-down'></i></button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='disp-block w100 align-center'>
                                    {!loading && <span className='red font-small fw-600'>No Record Found</span>}
                                </div>
                            )}
                            
                        </Section>
                    </>
                    )}
                        

                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolFinance;