import { addItemToArray } from "./Add";
export const groupArray = (arr=[], by='') => {
    if(by){
        let data = addItemToArray(arr);
        let new_data = [];
        for(let i = 0; i < arr.length; i++){
            if(data[i] === undefined){
                continue;
            }
            const filter = data.filter((it) => it[by] === data[i][by])
            if(filter.length > 0){
                for(let j=1; j < filter.length; j++){
                    if(filter[j].num){
                        delete data[filter[j].num]
                    }
                }
            }
        }
        for(let i =0; i < data.length; i++){
            if(!data[i]){
                continue;
            }
            new_data.push(data[i])
        }
        return new_data
    }
} 