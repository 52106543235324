import styled from "styled-components";
import { motion } from "framer-motion";

export const Content = styled(motion.div)`
    border-radius: 5px;
    box-shadow: 0 0 15px 3px rgba(0,0,0,0.1);
    padding: 5px 10px 20px 10px;
    position: relative;
    .header{
        display: inline-block;
        width: 100%;
        margin: 10px 0px;
        span.heading{
            color: var(--appTextColor);
            padding-left: 7px;
            text-transform: capitalize;
            font-weight: 600;
        }
        i.heading{
            padding: 5px;
            border-radius: 50%;
            background: var(--appBgColor);
            color: var(--appBgTextColor);
        }
    }
`;