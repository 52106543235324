import React from "react";
import {OuterWrapper, Wrapper} from './style';

const Header = ({children, outer_classname="", is_fixed=false}) => {
    return(
        <OuterWrapper style={{position:'relative'}}>
            <Wrapper className={`${outer_classname} ${is_fixed ? 'pos-fixed' : ''}`}>
                {children}
            </Wrapper>
        </OuterWrapper>
    )
}

export default Header;