import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useParams, Link, Routes, Route } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Profile from 'components/app/school/SmallerComponents/profile';
import InlineBtnWrapper from 'components/app/school/SmallerComponents/btn/inlineBtn';
import InlineBtn from 'components/app/school/SmallerComponents/btn/inlineBtn/btn';
import InlineList from 'components/app/school/SmallerComponents/inlineList';
import Item from 'components/app/school/SmallerComponents/inlineList/item';

import StudentProfileContent from './innerComponents/profile';
import AddSubjectStudent from './innerComponents/addSubject';

import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper, CustomWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

const MANAGEViewStudent = ({school_id, school_title, user_type}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    //const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const intitialStudentState = useMemo(() => ({id:0, adm_no:0, is_self_sponsored:false,  user:{id:0, profile_pic:null}, country_origin:{id:0}, guardian:{id:0}, current_class:{id:0}, fath_occupation:{id:0}, emergency_country_res:{id:0}, emergency_type:{id:0}, guardian_type:{id:0}, state_res:{id:0}, report_card:null, birth_cert:null, profile_pic:null}), []);
    const initialState = useMemo(() => ({id:0, uid:'', email:'', is_alumni:false, is_assigned_subjects:false, is_cs_paid:false, classes:{id:0, class_section_school:{id:0, class_section:{id:0}}}, house:{id:0, color:{id:0}}, sess_added:{id:0, term:{id:0}}, student:{id:0, class_accepted:{id:0}}}), []);

    const {id} = useParams();

    const [state, setState] = useState(initialState);
    const [studentState, setStudentState] = useState(intitialStudentState);

    const [error, setError] = useState(initialErrorState);
    // const [success, setSuccess] = useState(initialSuccessState);
    // const [warning] = useState(initialSuccessState);

    const [isOnline] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [exist, setExist] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchStudent = (pk, sid) => {
        if(pk){
            setPreloading(true);
            setExist(false);
            setLoading(true);
            appServices.getStudentSchoolAccess_Admin(pk, sid).then(res => {
                setPreloading(false);
                setExist(true);
                setState({...res.data});
                setStudentState({adm_no:res.data.adm_no, guardian_first_name:res.data.guardian_first_name, guardian_last_name:res.data.guardian_last_name, guardian_phone_number:res.data.guardian_phone_number, guardian_email:res.data.guardian_email, ...res.data.student.student})
                setLoading(false);
            }).catch(e => {
                setPreloading(false);
                setLoading(false);
                setExist(false);
                setError({data:['Internal Server Error'], title:'Student Data Fetch Error'});
            })
        }
    }

    useEffect(() => {
        fetchStudent(id, school_id);
    }, [id, school_id]);

    console.log(state)

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const LinkView = (type="") => {
        return(
            <Fragment><Item link={true}><Link className={`font-small ${!type  && 'active'}`} to={`/${school_title}/student/m/${id}`}>Profile</Link></Item>
                <Fragment>
                    <Item link={true}><Link className={`font-small ${type === "payment_history" && 'active'}`}  to={`/${school_title}/student/m/${id}/payment_history`}>Payment History</Link></Item>
                    <Item link={true}><Link className={`font-small ${type === "add_subject" && 'active'}`} to={`/${school_title}/student/m/${id}/add_subject`}>Add Subject</Link></Item>
                </Fragment>
            </Fragment>
        )
    }
    const name = studentState.user ? `${studentState.user.last_name} ${studentState.user.first_name}` : `${studentState.sur_name} ${studentState.other_names}`;
    const pp = studentState.user ? studentState.user.profile_pic : studentState.profile_pic;
    const http_url = window.location.href.split('//')[1].split('/');
    return(
        <>
        {loading && (<Loader />)}
        {!preloading && (
        <>
            {exist ? (
                <>
                    <HeadComponent title='View Student Data' />
                    <BreadCrumb arr={[http_url[0], school_title, "student", "View Profile"]} />
                    <ComponentWrapper>
                        <NotificationWrapper>
                            {error.title && (errorHandler)}
                            {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                            {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)} */}
                        </NotificationWrapper> 
                        <Profile status={isOnline} text={`${name} - Student`} pp={`${BACKEND_URL}${pp}`}>  
                            <CustomWrapper>
                                <InlineBtnWrapper>
                                    <InlineBtn label='Chat Up'><i className='far fa-comment'></i></InlineBtn>
                                    <InlineBtn label='Terminate Student' classname='bg-none bordered-red red br font-med'><i className='fas fa-trash'></i></InlineBtn>
                                </InlineBtnWrapper>
                            </CustomWrapper>
                            <InlineList>
                                <Routes>
                                    <Route path="" element = {LinkView()} />
                                    <Route path="payment_history" element = {LinkView('payment_history')} />
                                    <Route path="add_subject" element = {LinkView('add_subject')} />
                                </Routes>
                            </InlineList>  
                        </Profile>
                        <CustomWrapper>
                            <Grid layout='l31'>
                                {preloading ? (<ul>
                                <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                    <GridContent>
                                        <Routes>
                                            <Route path="" element={<StudentProfileContent uid={id} school_id={school_id} school_title={school_title} data={{...studentState, sess_added:state.sess_added, adm_no:state.adm_no, house_id:state.house ? state.house.id : 0, house_name:state.house ? state.house.name : '', house_color_name:state.house ? state.house.color.name : '', is_assigned_subjects:state.is_assigned_subjects, is_setup_app_account:state.is_setup_app_account, is_cs_paid:state.is_cs_paid, prom_class:state.prom_class, class_id:state.classes.id, class_name:state.classes.name, class_section_school_id:state.classes.class_section_school.id, emergency_relate_name:state.emergency_relate_name, emergency_country_origin_name:state.emergency_country_origin_name}} />} />
                                            {/* <Route path="assign_subject" element={<StaffAssignSubject school_id={school_id} user_type={user_type} user_id={user_id} data={state} />} />
                                            <Route path="assign_role"  element={<AssignRoles school_id={school_id} user_type={user_type} user_id={user_id} data={state} />} /> */}
                                            <Route path="add_subject"  element={<AddSubjectStudent uid={id} school_id={school_id} data={state} />} />
                                        </Routes>
                                    </GridContent>
                                </>
                                )}
                            </Grid>
                        </CustomWrapper>
                    </ComponentWrapper>
                </>
            ) : (
                <></>
            )}
        </>
        )}
        </>
    )
}

export default MANAGEViewStudent;