import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    padding-top: 7px;

    ul{
        margin: 0;
        padding: 0;
        overflow-x: auto;
        width: 100%;
        list-style: none;
        display: block;
        padding-top: 15px;
        border-top: 1px solid #999;

        .link{
            cursor: pointer;

            a, button{
                outline: none;
                border: none;
                background: none;
                text-decoration: none;
                font-weight: 600;
                text-transform: capitalize;
                color: var(--appTextDarkGrey);
                padding-bottom: 5px;
                transition: .3s ease;
                border-bottom-left-radius: 10px;
                border-bottom: 3px solid transparent;

                :hover:not(.active){
                    border-bottom: 3px solid var(--appTextDarkGrey);
                }

            }
            a.active, button.active{
                border-bottom:3px solid var(--appBgColor);
            }
        }
    }
`;