import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { style } from "./style";

const NumberShadeBox = ({num, color}) => {
    var new_num;
    if(num > 9 || num < 0){
        new_num = 0;
    }
    else{
        new_num = num;
    }
    const func =() => {
        let data = [];
        let nnm = new_num - 1;
        for(let i =0; i < 9; i++){
            const n = i + 1;
            data.push(<View key={i} style={style.container}>
                <Text style={{...style.ilis, color: color ? color : '#000'}}>[{n}]</Text>
                {i === nnm && <Text style={style.mark} />}
            </View>)
        }
        data.push(<View key={12} style={style.container}>
            <Text style={{...style.ilis, color: color ? color : '#000'}}>[0]</Text>
            {new_num === 0 && <Text style={{...style.mark, transform: 'translate(-0.2px, 2px)',}} />}
        </View>)
        return data;
    }
    return(
        <View>
            <View style={style.container}>
                <Text style={style.num}>{new_num}</Text>
                <Text style={{...style.ilis_box, borderColor:color ? color : '#000'}}></Text>
            </View>
            {func()}
            
            
        </View>
    )
}

export default NumberShadeBox;