import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Pagination from '@mui/material/Pagination';
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";

import { HeadComponent } from "components/head";

import { BACKEND_URL } from 'actions/url';
import { appServices } from "api/schoolApp/services";
import { sign_up } from "actions/auth";

import { validateSignUpForm } from '../actions/validation';
import { getPageSize } from 'components/app/school/actions/pageSize';
import { ComponentWrapper } from "../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import {default as success_icon} from 'images/success_icon.svg';


const UserManager = ({headTitle, user_type, type='admin', school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialUserState = useMemo(() => ({profile_pic:null, profile_pic_value:undefined, user_name:'', first_name:'', last_name:'', email:'', phone_number:undefined, user_type:'', password:'', re_password:''}), [])
    // const initialUserEditState = useMemo(() => ({profile_pic:null, profile_pic_value:undefined, user_name:'', first_name:'', last_name:'', email:'', phone_number:undefined, user_type:''}), [])

    const [userData, setUserData] = useState({results:[]});
    const [schoolBranchData, setSchoolBranchData] = useState([]);
    const [userTable, setUserTable] = useState({order_by:"", status:1, q:"", size:20, p:1})
    const [userTableChanged, setUserTableChanged] = useState(false);
    const [menuDropData, setMenuDropData] = useState({id:''});
    // const [menuDropDataError, setMenuDropDataError] = useState({});
    const [menuDrop, setMenuDrop] = useState(false);

    const [accordion, setAccordion] = useState(0);
    const [submitted, setSubmitted] = useState(null);
    const [formUser, setFormUser] = useState(initialUserState);
    const [formUserError, setFormUserError] = useState({});

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [loading, setLoading] = useState("");
    const [preloading] = useState("");
    const [preloading2, setPreloading2] = useState(false);
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const fetchAllUser = (scid, typ, userType, status, order_by, q, size, p) => {
        appServices.getAllUser(scid, typ, userType, p, size, status, q, order_by).then(res => {
            setPreloading2(false);
            setUserData({...res.data});    
        }).catch(e => {
            setError({data:[e.response.data], title:"Data Error"});
            setUserData({results:[]});
            setPreloading2(false);
        })
    }
    const getAllSchoolBranch = (sid) => {
        appServices.getAllSchoolBranch(sid).then(res => {
            setSchoolBranchData(res.data);
        }).catch(e => {
            setError({data:[e.response.data], title:"Data Error"});
        })
    }
    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
        fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
        setSubmitted("");
        setFormUserError({});
        setFormUser({...initialUserState});
    }
    const handleUserTable = (e) => {
        setUserTableChanged(false);
        setUserTable({...userTable, [e.target.name]:e.target.value})
        setUserTableChanged(true)
    }
    const handleUserTablePagination = (e, value) => {
        setUserTableChanged(false);
        const name = "p";
        setUserTable({...userTable, [name]:value})
        setUserTableChanged(true)
    }
    const handleUserChange = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.value})
    }
    const handleEditUser = (data) => {
        setMenuDrop(true);
        setMenuDropData(data);
        fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
    }
    const handleEditUserChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }
    const handleUserEditImageChange = (e, img_name='') => {
        
        if(img_name){
            const img = img_name;
            setMenuDropData({...menuDropData, [e.target.name]:e.target.files[0], [img]:URL.createObjectURL(e.target.files[0]), [e.target.name + '_value']:e.target.value});
        }
        else{
            setMenuDropData({...menuDropData, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value})
        }
    }
    const handleUserImageChange = (e, img_name='') => {
        
        if(img_name){
            
            setFormUser({...formUser, [e.target.name]:e.target.files[0], img:URL.createObjectURL(e.target.files[0]), [e.target.name + '_value']:e.target.value});
        }
        else{
            setFormUser({...formUser, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value})
        }
    }
    const addUserDetail = (timer=200) => {
        var data = {
            school:school_id,
        }
        if(formUser.school_branch){
            data = {
                school:school_id,
                school_branch: formUser.school_branch
            }
        }
        setTimeout(() => {appServices.insertSchoolUser(formUser.user_name, formUser.user_type, data).then(res => {
            if(formUser.profile_pic){
                const profileForm = new FormData()
                profileForm.append("profile_pic", formUser.profile_pic, formUser.profile_pic.name)
                appServices.updateUserProfile(formUser.user_name, profileForm).then(res => {
                    setFormUser(initialUserState);
                    setSuccess({text:"Data Submitted Successfully", title:"Success"})
                    setLoading("");
                    setSubmitted("");
                    fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
                }).catch(e => {
                    setError({data:[e.response.data.name], title:"User Error"})
                    setLoading("");
                    console.log(e.response);
                    setSubmitted("");
                })
            }
            else{
                 setFormUser(initialUserState);
                setSuccess({text:"Data Submitted Successfully", title:"Success"})
                setLoading("");
                setSubmitted("");
                fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
            }
            
        }).catch(e => {
            setError({data:[e.response.data.name], title:"User Error"})
            setLoading("");
            console.log(e.response);
            setSubmitted("");
        })
        }, timer)
        
    }
    const handleFormUserSubmit = (e) => {
        e.preventDefault();
        setFormUserError(validateSignUpForm(formUser));
        setSubmitted("insert"); 
        if(submitted === "inserted"){
            setLoading("left");
            setError({...initialErrorState});
            setSuccess({...initialSuccessState});
            sign_up(formUser.user_type, formUser.user_name, formUser.first_name,
                formUser.last_name, formUser.email, formUser.phone_number, formUser.password, formUser.re_password).then(res => {
                    addUserDetail();
                }).catch(e => {
                    console.log(e.response)                  
                    addUserDetail();       
                })
        }  
    }
    const handleUserUpdate = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("menuDrop");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            first_name: menuDropData.first_name,
            last_name: menuDropData.last_name,
            email: menuDropData.email,
            phone_number: menuDropData.phone_number,
            user_type: menuDropData.user_type
        }
        var branch_data = {
            school_branch: null
        }
        if((menuDropData.school_branch && menuDropData.user_type === "admin") || (menuDropData.school_branch && menuDropData.user_type === "student") || (menuDropData.school_branch && menuDropData.user_type === "staff") || (menuDropData.school_branch && menuDropData.user_type === "parent")){
            branch_data = {
                school_branch:menuDropData.school_branch
            }
        }
        
        appServices.updateUser(menuDropData.id, data).then(res => {
            appServices.updateSchoolUser(menuDropData.detail_id, branch_data).then(() => {
            
            if(menuDropData.profile_pic_value){
                const profileForm = new FormData()
                profileForm.append("profile_pic", menuDropData.profile_pic, menuDropData.profile_pic.name)
                appServices.updateUserProfile(menuDropData.user_name, profileForm)
            }            
            setTimeout(() => {
                setError({...initialErrorState});
                setSuccess({text: "Data Updated Successfully", title:"success"})
                setSubmitted(menuDropData.detail_id);
                fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
                setLoading("");
                setMenuDrop(false);
            }, 1500);    
            })
        }).catch(e => {
            console.log(e.response);
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.name], title:"User Update Error"})
            setLoading("");
        })
    }
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmitted("none");
        }, 1000 * 15)
        return(
            <div className="nav-dd-anim" style={{display:submitted}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }

    const handleUserInactivate = (id, title) => {
        setWarning({id:0, title:'', text:''})
        setTimeout(() => {
            setWarning({id:id, click_text:`Inactivate User ${title}`, 
             title:"Warning", text:"Are You Sure You Want To Inactivate This User?",
             handleClick: () => {
                setSubmitted(false);
                setLoading("right");
                setError({...initialErrorState});
                setSuccess(initialSuccessState);
                appServices.inactivateSchoolUser(id).then(res => {
                    setAnim({initial:{opacity: 1, duration:0.4},
                        animate:{opacity:0, duration:0.4, background:"#ff0000"},
                        id:id })
                    setLoading("");
                    setWarning({id:0, title:'', text:''})
                    setTimeout(() => {
                        fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
                        setSuccess({text:"User Inactivated Successfully", title:"Success"})
                        setAnim({initial:{opacity: 0, duration:0.4},
                            animate:{opacity:1, duration:0.4, background:"#000000e0"},
                            id:id })
                    }, 500)
                }).catch(e => {
                    setSuccess({...initialSuccessState});
                    setLoading("");
                    setError({data:["Something Went Wrong"], title:"User Error"})
                })
             }
            })
        })
    }
    const handleUserActivate = (id, title) => {
        setWarning({id:0, title:'', text:''})
        setTimeout(() => {
            setWarning({id:id, click_text:`Activate User ${title}`, 
             title:"Warning", text:"Are You Sure You Want To Activate This User?",
             handleClick: () => {
                setSubmitted(false);
                setLoading("right");
                setError({...initialErrorState});
                setSuccess(initialSuccessState);
                appServices.activateSchoolUser(id).then(res => {
                    setAnim({initial:{opacity: 1, duration:0.4},
                        animate:{opacity:0, duration:0.4, background:"#00ff00"},
                        id:id })
                    setLoading("");
                    setWarning({id:0, title:'', text:''})
                    setTimeout(() => {
                        fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
                        setSuccess({text:"User Activated Successfully", title:"Success"})
                        setAnim({initial:{opacity: 0, duration:0.4},
                            animate:{opacity:1, duration:0.4, background:"#000000e0"},
                            id:id })
                    }, 500)
                }).catch(e => {
                    setSuccess({...initialSuccessState});
                    setLoading("");
                    setError({data:["Something Went Wrong"], title:"User Error"})
                })
             }
            })
        })
    }
    console.log(userData);
    
    useEffect(() => {
        if(Object.keys(formUserError).length === 0 && submitted === "insert"){
            setSubmitted("inserted");
        }
    }, [initialErrorState, initialSuccessState, formUser, loading, school_id, initialUserState, formUserError, submitted])
    useEffect(() => {
        if(userTableChanged){
            fetchAllUser(school_id, type, user_type, userTable.status, userTable.order_by, userTable.q, userTable.size, userTable.p)
        }
        else{
            fetchAllUser(school_id, type, user_type)
        }
    }, [school_id, type, user_type, userTable, userTableChanged])
    useEffect(() => {
        getAllSchoolBranch(school_id);
    }, [school_id]);

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const userTypeOption = () => {
        if(user_type === "school_owner" && type === "admin"){
            return(
                <>
                    <option value="school_owner">School Owner</option>
                    <option value="super_admin">Super Admin</option>
                    <option value="admin">Admin</option>
                </>
            )
        }
        else{
            return(
                <></>
            )
        }
    }
    const insertSchoolBranchData = schoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const insertUserData = () => {
        var i = 0;
        if(userTable.p > 1){
            const page = parseInt(userTable.p) - 1
            const size = parseInt(userTable.size) * page;
            i = size;
        }
        return(userData.results.map((item) => {  
        i++;
        const time = new Date().getTime();
        const data = {
            i: i,
            id: item.user,
            profile_pic: item.profile_pic,
            profile_pic_value: undefined,
            user_name: item.user_name,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            phone_number: item.phone_number,
            user_type: item.user_type,
            school_branch: item.school_branch,
            detail_id: item.id,
            type:"user_form"
        }
        return(
            <Fragment key={i}>
            <Tr sn={true} style={item.id === anim.id && {display:anim.display}} animation={item.id === anim.id && {initial:{...anim.initial}, animate:{...anim.animate}}}
             active_btn={item.is_school_active ? true : false} inactive_btn={item.is_school_active ? false : true} dlt_btn={false} handleEditClick={() => handleEditUser(data)}
             handleInactivateClick={() => handleUserInactivate(item.id, item.user_name)} handleActivateClick={() => handleUserActivate(item.id, item.user_name)}>
                <div className="c-data sn">{item.id === submitted ? updateSuccessIcon() : (<span className="font-very-small">{i}</span>)}</div>
                <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic}`} alt="Profile" /><span className={item.is_online > time ? 'circle green' : 'circle red'}></span></div> <span className="font-very-small">{item.user_name}</span></div>
                <div className="c-data"><span className="font-very-small text-capitalize">{item.last_name} {item.first_name}</span></div>
                <div className="c-data"><span className="font-very-small">{item.phone_number}</span></div>
                <div className="c-data"><span className="font-very-small">{item.email.length > 20 ? `${item.email.slice(0, 20)}...` : item.email}</span></div>

                <TdWrapper header_text={item.user_name} header_num={i}>
                    <Td profile_pic={`${BACKEND_URL}${item.profile_pic}`} profile_status={time < item.is_online ? 'online':''} />
                    <Td heading="User Name" text={item.user_name} />
                    <Td heading="Full Name" text_class='text-capitalize' text={`${item.last_name} ${item.first_name}`} />
                    <Td heading="Phone Number" text={item.phone_number} />
                    <Td heading="Email Address" text={item.email} />
                    <Td heading="Branch" text={item.email} />
                    <Td heading="User Type" text={item.user_type} />
                    <Td heading="Status" text_class={item.user_active ? 'green' : 'red'} text={item.user_active ? 'Active' : 'Inactive'} />
                </TdWrapper>
            </Tr>
            </Fragment>
        )
    }))}
    return(
        <>
            <HeadComponent title={headTitle} />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid>
                    <GridContent header_text={`Add ${type}`} header_icon="fas fa-plus">
                    {loading==="left" && (<Loader />)}
                        {preloading ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (<>
                        <Accordion handleSubmit={e => handleFormUserSubmit(e)} index={1} text={`Add ${type}`} handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && ( <>
                                <AccordionFormData handleChange={e => handleUserImageChange(e, true)} error={formUserError.profile_pic}  tag="input" type="file" name="profile_pic" value={formUser.profile_pic_value} fileImg={formUser.img} styleFileImg={{width:'8rem', height:'8rem', borderRadius:'50%'}} classname="form-control-theme font-slightly-small" placeholder="Choose Profile Picture" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.user_name}   tag="input" type="text" name="user_name" value={formUser.user_name} classname="form-control-theme font-slightly-small" placeholder="Enter Username" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.last_name}  tag="input" type="text" name="last_name" value={formUser.last_name} classname="form-control-theme font-slightly-small" placeholder="Enter Surname" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.first_name}  tag="input" type="text" name="first_name" value={formUser.first_name} classname="form-control-theme font-slightly-small" placeholder="Enter Firstname" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.email}  tag="input" type="email" name="email" value={formUser.email} classname="form-control-theme font-slightly-small" placeholder="Enter Email" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.phone_number}  tag="input" type="text" name="phone_number" value={formUser.phone_number} classname="form-control-theme font-slightly-small" placeholder="Enter Phone Number" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.user_type}  tag="select" name="user_type" value={formUser.user_type} classname="form-control-theme font-slightly-small">
                                    <option value="">Select User Type</option>
                                    {userTypeOption()}
                                </AccordionFormData>
                                {formUser.user_type === "admin" && (
                                    <AccordionFormData required={true} handleChange={e => handleUserChange(e)} error={formUserError.school_branch}  tag="select" name="school_branch" value={formUser.school_branch} classname="form-control-theme font-slightly-small">
                                        <option value="">Select School Branch</option>
                                        {insertSchoolBranchData}
                                    </AccordionFormData>
                                )}
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.password}  tag="input" type="password" name="password" value={formUser.password} classname="form-control-theme font-slightly-small" placeholder="Enter Password" />
                                <AccordionFormData handleChange={e => handleUserChange(e)} error={formUserError.re_password}  tag="input" type="password" name="re_password" value={formUser.re_password} classname="form-control-theme font-slightly-small" placeholder="Re-Enter Password" />
                                <AccordionFormData contentClass="align-center" btn_text="Submit" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion></>)}
                    
                    </GridContent>
                    <GridContent header_text={`Manage ${type}`} header_icon="fas fa-tasks">
                    {preloading2 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                            <Section icon="fas fa-tasks" header={`Manage ${type} Users`}>
                                <Mallet >
                                    <div className="flex-50"><label className='font-super-small'>Select Status: 
                                        <select onChange={e => handleUserTable(e)} name="status" value={userTable.status} className='form-control-theme font-slightly-small' >
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select></label>
                                    </div>
                                    <div className="flex-50"><label className='font-super-small'>Order By: 
                                        <select onChange={e => handleUserTable(e)} name="order_by" value={userTable.order_by} className='form-control-theme font-slightly-small'>
                                            <option value="-id">Descending</option>
                                            <option value="">Ascending</option>
                                        </select></label>
                                    </div>
                                    <div className="flex-70-line"><label className='font-super-small'>Filter By: 
                                        <input onChange={e => handleUserTable(e)} name="q" value={userTable.q} className='form-control-theme font-slightly-small' type="text" placeholder='Search...' /></label>
                                    </div>
                                    <div className="flex-30-line align-right"><label className='font-super-small'>No. Of Users: <br />
                                        <select onChange={e => handleUserTable(e)} name="size" className='font-slightly-small form-control-theme' value={userTable.size}>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>
                                        </label>
                                    </div>
                                </Mallet>
                                <Table>
                                    <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"User Name"}, {text:"Full Name"}, {text:'Phone No'}, {text:'Email'}]} />
                                    {userData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                        (<>
                                            {insertUserData()}
                                        </>)                         
                                    }
                                    <div  style={{marginTop:'40px', display:'flex', justifyContent:'center'}}>
                                        <Pagination count={getPageSize(userData.count, userTable.size)} page={userTable.p} onChange={handleUserTablePagination} size="small" color="primary" variant="outlined" shape="rounded" />
                                    </div>
                                </Table>
                            </Section>
                            <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                            >
                            { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                                {loading === "menuDrop" && <Loader />}
                                {menuDropData.type === "user_form" && (
                                    <form onSubmit={e => handleUserUpdate(e)}>
                                        <div className='row'>
                                            <div className="fg profile-cont">
                                                <img src={menuDropData.img ? `${menuDropData.img}` : `${BACKEND_URL}${menuDropData.profile_pic}`} className="" alt="Profile" />
                                                <div className='profile-file'>
                                                    <label className="label font-very-small" htmlFor="profile">Upload Profile Picture</label>
                                                    <input type="file" id="profile"  name="profile_pic" onChange={e => handleUserEditImageChange(e, "img")} value={menuDropData.profile_pic_value}  />
                                                </div>
                                            </div>
                                            <div className="fg">
                                                {/* <label className="font-super-small">Username </label>
                                                <input disabled type="text" className="font-very-small app-theme form-control disabled" value={menuDropData.user_name} /> */}
                                                <div className='inside'>
                                                    <label className="font-super-small">Surname </label>
                                                    <input onChange={e => handleEditUserChange(e)} required type="text" name="last_name" className="font-very-small app-theme form-control-theme" placeholder="Enter Surname" value={menuDropData.last_name} />
                                                </div>
                                                <div className="inside">
                                                    <label className="font-super-small">Firstname </label>
                                                    <input onChange={e => handleEditUserChange(e)} required type="text" name="first_name" className="font-very-small app-theme form-control-theme" placeholder="Enter Firstname" value={menuDropData.first_name} />
                                                </div>
                                                <div className="inside">
                                                    <label className="font-super-small">Phone Number </label>
                                                    <input onChange={e => handleEditUserChange(e)} type="text" name="phone_number" className="font-very-small app-theme form-control-theme" placeholder="Enter Phone Number" value={menuDropData.phone_number} />
                                                </div>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Email Address </label>
                                                <input onChange={e => handleEditUserChange(e)} required type="email" name="email" className="font-very-small app-theme form-control-theme" placeholder="Enter Email" value={menuDropData.email} />
                                            </div>
                                            
                                            {user_type === "school_owner" && (
                                                <>
                                                    <div className="fg">
                                                        <label className="font-super-small">User Type </label>
                                                        <select onChange={e => handleEditUserChange(e)} className='font-very-small app-theme form-control-theme' name="user_type" value={menuDropData.user_type}>
                                                            <option value="">Select User Type</option>
                                                            <option value="school_owner">School Owner</option>
                                                            <option value="super_admin">Super Admin</option>
                                                            <option value="admin">Admin</option>
                                                        </select>
                                                    </div>
                                                </>
                                            )}
                                            {menuDropData.user_type === "admin"  && (
                                                <>
                                                    <div className="fg">
                                                        <label className="font-super-small">School Branch </label>
                                                        <select required name="school_branch" onChange={e => handleEditUserChange(e)} className='font-very-small app-theme form-control-theme' value={menuDropData.school_branch ? menuDropData.school_branch : ""}>
                                                            <option value="">Select Branch</option>
                                                            {insertSchoolBranchData}
                                                        </select>
                                                    </div>
                                                </>
                                            )}
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>

                        </>
                        )
                    }
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default UserManager;