import React from 'react';
import { Wrapper, Content, Img } from './style';

export const ImagePic = ({src, alt="", name="", btn=true, dlt_btn=false, onClickDelete}) => {
    return(
        <Content>
            <Img src={src} alt={alt} />
            {name && (
                <div style={{textAlign:'center'}}>
                    <span className='font-very-small fw600' >Fig {name}</span>
                </div>
            )}
            {btn && (
                <>
                    {dlt_btn && (
                        <div style={{textAlign:'center'}}>
                            <button type="button" onClick={onClickDelete} className='btn font-very-small bg-red hover-opacity br' style={{color:'#eee'}}><i className='fas fa-trash'></i></button>
                        </div>
                    )}
                </>
            )}
        </Content>
    )
}

const PicPlaceHolder = ({children}) => {
    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default PicPlaceHolder;