import React from "react";
import { Wrapper } from "./style";
const Grid = ({children, layout="l13", theme}) => {
    return(
        <Wrapper className={theme}>
            <div className={`${layout}`}>
                {children}
            </div> 
        </Wrapper>
    )
}

export default Grid;