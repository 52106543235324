import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { GlobalStyle } from "GlobalStyle";
import Layout from "components/app/school/layout";
import ResultChecker from "components/app/school/student/admin/resultChecker";
import LoginPage from "components/loginPage";
import CheckSchool from "components/app/school/checkSchool";

import { appServices } from "api/schoolApp/services";
import PreloaderLine from "components/preloaderLine";
import NotFound from "components/notFound";

const IndexComponent = ({user={}}) => {
    const [schoolState, setSchoolState] = useState({id:0});

    const [searchParam] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [navigate, setNavigate] = useState('');

    const nav = useNavigate();

    const fetchSchool = (id, u_type, uid) => {
        if(id){
            setLoading(true);
            appServices.getUserA(id).then(res => {
                const item = res.data[0];
                setSchoolState({...item});
                setNavigate(`/${item.school_title}/dashboard`);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setSchoolState({id:0});
            })
            return;
        }
        setLoading(false);
    }
    useEffect(() => {
        const local_storage = searchParam.get('act') ? {access:searchParam.get('act'), refresh:searchParam.get('rft')} : {access:'', refresh:''}
        if(local_storage.access && !localStorage.getItem('act')){
            localStorage.removeItem('access');
            localStorage.setItem('access', JSON.stringify(local_storage));
            window.location.reload();
        }
      }, [searchParam]);
    useEffect(() => {
        fetchSchool(user.id, user.user_type, user.uid);
    }, [user.id, user.user_type, user.uid]);
    useEffect(() => {
        if(navigate){
            window.location.href = `${schoolState.school_title}/dashboard`;
            setNavigate('');
            return;
        }
    }, [schoolState, navigate, nav])

    return(
        <>
        {loading ? <PreloaderLine /> : <NotFound has_user={user.id ? true : false} />}
        </>
    )
}


const SchoolApp = ({state}) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(state);
    }, [state]);

    return(
        <>
            <Routes>
                <Route path="/" element={<IndexComponent user={user.data} />} />
                <Route path="/result_checker" element={<ResultChecker user={user} />} />
                <Route path="/school" element={<CheckSchool user={user.data} />} />
                <Route path="/:schoolName/login" element={<><LoginPage user={user.data} /> <GlobalStyle /></>} />
                <Route path="/:schoolName/*" element={<Layout inner_state={user} />} />
                <Route element={<NotFound />} />
            </Routes>
        </>
    )
}

export default SchoolApp;