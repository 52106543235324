import React, { useState, useEffect, useMemo, useRef, Fragment} from 'react';
// import { Link } from 'react-router-dom';
// import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
// import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Mallet from 'components/app/school/SmallerComponents/mallet';

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";
import {attendanceIcon} from 'components/app/school/SmallerComponents/attendanceIcon';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices4, appServices5 } from 'api/schoolApp/services';


import { addItemToArrayAndInnerArray } from 'components/app/school/actions/array-utils/Add';
import { updateinnerArray } from 'components/app/school/actions/array-utils/Update';
import { spread_attendance_days } from 'components/app/school/actions/array-utils/Attendance';
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { filterInnerArray } from 'components/app/school/actions/array-utils/Filter';
import Note from 'components/app/school/SmallerComponents/NOTE';


const SchoolAttendanceStudent = ({user_id=0, access, school, school_branch, school_id=0, school_title=""}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [malletState, setMalletState] = useState({session:'', pre:false, from_date:'', to_date:'', school_branch:'', classes:'', curr_session:'', is_res_date_editable:false, is_start_student_attendance:false, active_session:false});
    const [dataTableUpdateData, setDataTableUpdateData] = useState({});

    const [allClassesData, setAllClassesData] = useState([]);
    const [allStudAttend, setAllStudAttend] = useState([]);

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [reload, setReload] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [dataTableUpdateLoading, setDataTableUpdateLoading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);

    const initialChangedRecordState = useRef(true);

    const handleMalletChange = e => {
        setDataTableLoading(true);
        setMalletState({...malletState, [e.target.name]:e.target.value, pre:false});
    }

    const fetchAllClasses = (id) => {
        if(id){
            setError({data:[], title:''});
            appServices4.getAllFormMasterClasses(id).then(res => {
                setAllClassesData(res.data);
            }).catch(e => {
                setAllClassesData([]);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchCurrentSession = (sid, sb) => {
        if(sid && sb){
            setError({data:[], title:''});
            appServices5.getCurrentSession(sid).then(res => {
                setMalletState((prev) => typeof prev === "object" && {...prev, school_branch:sb, curr_session:res.data.id, session:res.data.id});
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllStudAttend = (pre=true, stid, sid, cid, from_date, to_date) => {
        if(sid && stid){
            setError({data:[], title:''});
            setPreloading(pre);
            setSpinLoading(!pre);
            appServices4.getAllSchoolStudentAttendance(stid, sid, cid, from_date, to_date).then(res => {
                setAllStudAttend(addItemToArrayAndInnerArray(res.data, 'students'));
                setPreloading(false);
                setSpinLoading(false);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Attendance Data Fetch Error'});
                setAllStudAttend([]);
                setPreloading(false);
                setSpinLoading(false);
            })
        }
    }

    const onClickAttend = (id, arr_num, num, upd_item={}, item) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        appServices4.updateSchoolStudentAttendance(id, access.uid, {...upd_item, updated_by:access.id}).then(res => {
            setSuccess({text:"Attendance Altered Successfully", title:"Success"});
            setDataTableUpdateData({arr_num:arr_num, num:num, item:{...item, ...upd_item}});
            setDataTableUpdateLoading(true);
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Attendance Alteration Error'});
            setLoading(false);
        })
    }
    useEffect(() => {
        if(reload && access.uid){
            fetchAllClasses(access.uid);
            fetchCurrentSession(school_id, school_branch.id);
            setReload(false);
        }
        // fetchSchoolWeekDays(school_id);
    }, [school_id, school_branch.id, access.uid, reload]);

    useEffect(() => {
        if((dataTableLoading && !malletState.session) || (dataTableLoading && !malletState.classes)){
            setDataTableLoading(false);
            setAllStudAttend([]);
            return;
        }
        if((dataTableLoading && malletState.from_date && malletState.classes) || (dataTableLoading && malletState.to_date && malletState.classes)){
            if(initialChangedRecordState.current){
                initialChangedRecordState.current = false;
                return;
            }
            setSpinLoading(true);
            const timeout = setTimeout(() => {
                setDataTableLoading(false);
                fetchAllStudAttend(malletState.pre, access.uid, school_id, malletState.classes,  malletState.from_date, malletState.to_date);
            }, 5200)
            return () => clearTimeout(timeout);
        }
        if(dataTableLoading){
            setDataTableLoading(false);
            fetchAllStudAttend(malletState.pre, access.uid, school_id, malletState.classes);
            return;
        }
        if(dataTableUpdateLoading){
            setDataTableUpdateLoading(false);
            setAllStudAttend((prev) => prev.length > 0 ? updateinnerArray(prev, dataTableUpdateData.arr_num, 'students', dataTableUpdateData.num, dataTableUpdateData.item) : [...prev]);
            setLoading(false);
            setDataTableUpdateData({});
            return;
        }
    }, [access.uid, dataTableLoading, dataTableUpdateLoading, dataTableUpdateData, malletState, school_id])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={() => error.handleClick()}  />)
    })
    const insertClassesOption = allClassesData.map((item) => {
        return(
            <option value={item.classes} key={item.id}>{`${item.class_name}`}</option>
        )
    })

    const insertSchoolWeekDayTd = () => {
        const ins = spread_attendance_days(allStudAttend, 5).map((item, i) => {
            const item_len = item.length;
            return(
                <Fragment key={i}>
                    <Tr sn={true} btn={false}>
                        <div className="c-data " style={{width:'100%'}}><span className="font-small disp-block fw-700 align-center app-text-color">FROM {convertDate(item[0].attend_day, 'ddMMyyyy')} TO {convertDate(item[item_len-1].attend_day, 'ddMMyyyy')}</span></div>
                    </Tr>
                    {item[0].students.map((stud_item, j) => {
                        const filter_stud = filterInnerArray(item, 'students', 'student', stud_item.student);
                        return(
                            <Fragment key={j}>
                                <Tr btn={false}>
                                    <div className="c-data"><span className="font-very-small disp-block fw-600  text-capitalize app-text-color">{stud_item.sur_name ? stud_item.sur_name : stud_item.user_sur_name} {stud_item.first_name ? stud_item.first_name : stud_item.user_first_name}</span></div>
                                    <div className="c-data" style={{width:'90%', display:'flex', flexWrap:'wrap', justifyContent:'stretch'}}>
                                        {filter_stud.map((inner_item, k) => {
                                            const stud_itemm = inner_item.students.length === 1 ? inner_item.students[0] : {is_present:false, is_absent:false}
                                            return(
                                                <Fragment key={k}>
                                                    <span  className="font-super-small disp-inline align-center app-text-color" style={{padding:'5px', paddingRight:'10px'}}>{convertDate(inner_item.attend_day, 'ddMM')} <br /> {inner_item.day_name.toString().slice(0, 3)} <br /> {attendanceIcon('btn', stud_itemm.is_present, stud_itemm.is_absent, {}, () => onClickAttend(stud_itemm.uid, inner_item.num, stud_itemm.num, {is_present:!stud_itemm.is_present, is_absent:!stud_itemm.is_present ? false : true}, stud_itemm), () => onClickAttend(stud_itemm.uid, inner_item.num, stud_itemm.num, {is_present:!stud_itemm.is_absent ? false : true, is_absent:!stud_itemm.is_absent}, stud_itemm))} </span>
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </Tr>
                            </Fragment>
                        )
                    })}
                </Fragment>
            )
        })
        return(
            <>
            {ins}
            </>
        )
    } 

    return(
        <>
            <HeadComponent title='Manage Student Attendance' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage Student Attendance`} header_icon="fas fa-tasks">
                        {loading && (<Loader />)}
                        
                        <Section>
                            <Mallet>
                                <div className="flex-100"><label className='font-super-small'>Select Class: 
                                    <select name="classes" onChange={e => handleMalletChange(e)} value={malletState.classes} className='form-control-theme font-slightly-small  text-capitalize' >
                                        <option value="">Select Class</option>
                                        {insertClassesOption}
                                    </select></label>
                                </div>
                                <div className="flex-50-line"><label className='font-super-small'>FROM:
                                    <input type="date" onChange={e => handleMalletChange(e)} name="from_date" value={malletState.from_date} className='form-control-theme font-slightly-small' />
                                </label></div>
                                <div className="flex-50-line"><label className='font-super-small'>TO:
                                    <input type="date" onChange={e => handleMalletChange(e)} name="to_date" value={malletState.to_date} className='form-control-theme font-slightly-small' />
                                </label></div>
                            </Mallet>
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                <Table>
                                    {spinLoading && <Spinner />}
                                    <Tr btn={false}  header={true} text_style='c-data' text={[{text:"Student"}, {text:'Attendance', style:{width:'90%'}}]} />
                                    {allStudAttend.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    (<>
                                        <Tr btn={false} ><div className="c-data align-center" style={{width:'100%'}}><Note> <span className="font-very-small align-center">To mark as present click on <i className="fas fa-check"></i>, and absent as <i className="fas fa-times"></i> and vice versa when already clicked</span></Note></div></Tr>
                                        {insertSchoolWeekDayTd()}
                                    </>)                         
                                    }
                                </Table>
                            </>
                            )}
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolAttendanceStudent;