import React from "react";
import { Wrapper, Content } from "./style";

const Loader = () => {
    return(
        <Wrapper>
            <Content>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--text'></div>
            </Content>
        </Wrapper>
    )
}

export default Loader;