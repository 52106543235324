import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Content } from "./style";
import { Content as MoneyContent } from "components/app/school/SmallerComponents/input/style";
import Input from "components/app/school/SmallerComponents/input";
import { formatNumber } from "../../actions/money";

const AccordionFormData = ({children, styleContent, contentClass="", styleInp, showYearDropdown=false, showYearPicker=false, dateFormat=null,  handleChange, handleClick, value="", defaultValue="", handleSubmit, type="", error, name="", fileImg, styleFileImg, fileName, options=[], selected=null, selected_date =null, tag=null, 
    currency=null, currency_value='', point_display=false,  point_value, point_name="", handlePointChange, btn_text="", btn_icon="", placeholder="", classname="", setCloseListBox, closeListBox=true, required=false, disabled=false, readonly=false,
    min, max, maxLength}) => {
    const funcOpt = options.map((item, index) => {
        if(item.value){
            return(
                <option key={index} value={item.value} disabled={item.disabled} selected={item.value===selected ? true : false}>{ item.text }</option>
            )
        }
        else{
            return(
                <option key={index} className="empty-option" value={item.value} disabled={item.disabled} selected={item.value===selected ? true : false}>{ item.text }</option>
            ) 
        }
        
    
    })
    const getData = (data) => {
        setCloseListBox(data);
    }
    
    const func = () => {
        if(tag === "input"){
            if(type === "date"){
                return(     
                    <>
                    <DatePicker dateFormat={dateFormat} showYearDropdown={showYearDropdown} showYearPicker={showYearPicker} name={name}  portalId="root-portal"
                     className={disabled ? `disabled ${classname}` : classname} placeholderText={placeholder}
                      selected={selected_date} onChange={handleChange} required={required} disabled={disabled} />  
                      {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                    </>  
                    // <input onInput={() => {type="date"}} onChange={handleChange} name={name} type="text" value={value} className={disabled ? `disabled ${classname}` : classname} placeholder={placeholder} required={required} disabled={disabled}  />
                )
            }
            else if(type === "textarea"){
                return(
                    <>
                    <label className="font-super-small">{placeholder} </label>
                    <textarea readOnly={readonly} onChange={handleChange} style={styleInp} name={name} type={type} value={value} className={disabled ? `disabled ${classname}` : classname} placeholder={placeholder} required={required} disabled={disabled}>{value}</textarea>
                    {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                    </>
                )
            }
            else if(type==="file"){
                return(
                    <>
                    <label className="font-super-small">{placeholder} </label>
                    <div className="profile-cont align-center">
                        {fileImg && (<img style={styleFileImg} src={fileImg} alt="Profile" />)}
                    </div>
                    <input readOnly={readonly} onChange={handleChange} name={name} type={type} value={value} className={disabled ? `disabled ${classname}` : classname} required={required} disabled={disabled}  />
                    <span style={{color:'cornflowerblue'}} className="font-super-small">{fileName}</span>
                    {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                    </>
                )
            }
            else if(type === "money"){
                return(
                    <MoneyContent>
                        <select defaultValue={currency_value} className={`disabled flex-15 font-very-small`} disabled={disabled}  ><option defaultValue={currency_value}>{currency}</option></select>
                        <input readOnly={readonly} onChange={handleChange} name={name} type={'text'} value={formatNumber(value)} className={point_display ? `flex-60 ${classname}` : `flex-85 ${classname}`} placeholder={placeholder} required={required} disabled={disabled}  />
                        {point_display && <div className={`flex-5 align-center`}><span className="font-big">.</span></div>}
                        {point_display && <input readOnly={readonly} maxLength={2} min={0} max={99} onChange={handlePointChange} name={point_name} type={'number'} value={point_value} className={`flex-20 ${classname}`} placeholder={'00'}  /> }
                        {error && (<span className="font-super-small flex-100" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                    </MoneyContent>
                )
            }
            else if(type === "list"){
                return(
                    <Input classname={classname} handleChange={handleChange} type={"list"} name={name} required={required} disabled={disabled} value={value} placeholder={placeholder} setCloseListBox={getData} closeListBox={closeListBox} />
                )
            }
            else if(type === "number"){
                return(
                    <>
                    <input readOnly={readonly} min={min} max={max} maxLength={maxLength} onChange={handleChange} name={name} type={type} value={value} className={disabled ? `disabled ${classname}` : classname} placeholder={placeholder} required={required} disabled={disabled}  />
                    {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                    </>
                )
            }
            return(
                <>
                <input readOnly={readonly} onChange={handleChange} name={name} type={type} value={value} className={disabled ? `disabled ${classname}` : classname} placeholder={placeholder} required={required} disabled={disabled}  />
                {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                </>
            )
        }
        else if(tag === "select"){
            return(
                <>
                <select value={value} onChange={handleChange} className={disabled ? `disabled ${classname}` : classname} name={name} required={required} disabled={disabled}>
                    {funcOpt}
                    {children}
                </select>
                {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
                </>
                 
            )
        }
        else if(tag === "btn"){
            return(
              
                <button onSubmit={handleSubmit} onClick={handleClick} type={type} className={classname}>{btn_text} {btn_icon && (<i className={btn_icon}></i>)}</button>
                 
            )
        }
        else{
            return children
        }
    }
    return (
        
            <Content style={styleContent} className={`${contentClass}`} initial={{opacity: 0, y:'7vh'}} animate={{opacity:1, transitionDuration: 0.8, y:'0vh'}} exit={{opacity: 0, y:'-10vh'}}>
                {func()}
            </Content> 
        
    )       
}

export default AccordionFormData;