import React, { useState, useEffect, useMemo, useCallback, Fragment} from 'react';
import { useParams, Link, Routes, Route } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Profile from 'components/app/school/SmallerComponents/profile';
import InlineBtnWrapper from 'components/app/school/SmallerComponents/btn/inlineBtn';
import InlineBtn from 'components/app/school/SmallerComponents/btn/inlineBtn/btn';
import InlineList from 'components/app/school/SmallerComponents/inlineList';
import Item from 'components/app/school/SmallerComponents/inlineList/item';
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import View from 'components/app/school/SmallerComponents/mallet/view';
import ImgViewer from 'components/app/school/SmallerComponents/imgViewer';
import Img from 'components/app/school/SmallerComponents/imgViewer/img';
import StaffProfileContent from "components/app/school/staff/innerComponents/profile";
import StaffAssignSubject from 'components/app/school/staff/innerComponents/assignSubject';
import AssignRoles from 'components/app/school/staff/innerComponents/assignRoles';

import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from 'components/preloaderLine';
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper, CustomWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

const StaffProfile = ({school_id, school_title, user_id, user_type}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialState = useMemo(() => ({id:0, uid:'', email:'', school_uid:'', staff:{staff:{id:0, gender:''}, staff_cert_type:{id:0}, staff_type:{id:0}, school:{id:0}, school_branch:{name:'', id:0},}, session_added:{id:0, term:{id:0}}}), []);
    const {id} = useParams()
    const [error, setError] = useState(initialErrorState);

    const [state, setState] = useState(initialState);
    const [imgViewer, setImgViewer] = useState({src:'', alt:"", title:''});

    const [allStaff, setAllStaff] = useState({count:0, results:[], });

    const [openImgViewer, setOpenImgViewer] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [exist, setExist] = useState(false);
    const [preloading2, setPreloading2] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchStaff = (pk) => {
        setPreloading(true);
        setExist(false);
        setLoading(true);
        appServices.getStaffSchoolAccess(pk).then(res => {
            setPreloading(false);
            setExist(true);
            setState({...res.data});
            setLoading(false);
        }).catch(e => {
            setPreloading(false);
            setLoading(false);
            setExist(false);
            setError({data:['Internal Server Error'], title:'Staff Data Error'});
        })
    }

    const fetchAllStaff = (sbid, order_by="-id") => {
        if(sbid){
            setPreloading2(true);
            appServices.getAllStaffSchoolAccess(sbid, 1, 5, '', order_by).then(res => {
                setPreloading2(false);
                setAllStaff({...res.data});
            }).catch(e => {
                setPreloading2(false);
                setAllStaff({count:0, results:[], });
            })
        }
    }

    const getOnline = useCallback((pk, callback=false) => {
        if(callback){
            setIsOnline(false);
            const intertval = setInterval(() => {
                const time = new Date().getTime();
                appServices.getOnlineUser(pk).then(res => {
                    if(res.data.is_online > time){
                        setIsOnline(true);
                    } else {
                        setIsOnline(false);
                    }
                }).catch(() => {
                    setIsOnline(false);
                })
            }, 1000 * 10)
            return () => {
                clearInterval(intertval)
            }
        }
        else{
            setIsOnline(false);
            const time = new Date().getTime();
            appServices.getOnlineUser(pk).then(res => {
                if(res.data.is_online > time){
                    setIsOnline(true);
                } else {
                    setIsOnline(false);
                }
            }).catch(() => {
                setIsOnline(false);
            })
        }
    }, [])
    const handleImgClick = (imgSrc="", alt="blank", title="") => {
        setOpenImgViewer(true);
        setImgViewer({src:imgSrc, alt:alt, title:title});
    }
    console.log(state);

    useEffect(() => {
        fetchStaff(id);
    }, [id]);
    useEffect(() => {
        if(state.id){
            getOnline(state.staff.staff.user, false);
            fetchAllStaff(state.staff.school_branch.id);
        }
    }, [state, getOnline])
    useEffect(() => {
        if(state.id){
            getOnline(state.staff.staff.user, true);
        }
    }, [state, getOnline])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const LinkView = (type="") => {
        return(
            <Fragment><Item link={true}><Link className={`font-small ${!type  && 'active'}`} to={`/${school_title}/staff/user/${id}`}>About</Link></Item>
            {state.staff.staff_type.name === "teacher" && (
                <Fragment>
                    <Item link={true}><Link className={`font-small ${type === "assign_subject" && 'active'}`}  to={`/${school_title}/staff/user/${id}/assign_subject`}>Assign Subject</Link></Item>
                    <Item link={true}><Link className={`font-small ${type === "assign_role" && 'active'}`} to={`/${school_title}/staff/user/${id}/assign_role`}>Assign Role</Link></Item>
                </Fragment>
            )}</Fragment>
        )
    }

    return (<>
        {loading && (<Loader />)}
        {preloading && <PreloaderLine />}
        {!preloading && (
        <>
            {exist ? (
                <>
                    <HeadComponent title='Staff Profile' />
                    <BreadCrumb arr={["Voskool", school_title, "staff", "View Profile"]} />
                    <ComponentWrapper>
                        <NotificationWrapper>
                            {error.title && (errorHandler)}
                            {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                            {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)} */}
                        </NotificationWrapper> 
                        <Profile status={isOnline} text={`${state.last_name} ${state.first_name} - Staff`} pp={`${BACKEND_URL}${state.profile_pic}`}>  
                            <CustomWrapper>
                                <InlineBtnWrapper>
                                    <InlineBtn label='Chat Up'><i className='far fa-comment'></i></InlineBtn>
                                    <InlineBtn label='Upgrade Salary'><i className='fas fa-level-up-alt'></i></InlineBtn>
                                    <InlineBtn label='Terminate Contract' classname='bg-none bordered-red red br font-med'><i className='fas fa-trash'></i></InlineBtn>
                                </InlineBtnWrapper>
                            </CustomWrapper>
                            <InlineList>
                                <Routes>
                                    <Route path="" element = {LinkView()} />
                                    <Route path="assign_subject" element = {LinkView('assign_subject')} />
                                    <Route path="assign_role" element = {LinkView('assign_role')} />
                                </Routes>
                            </InlineList>  
                        </Profile>
                        <CustomWrapper>
                            <Grid layout='l31'>
                                {preloading ? (<ul>
                                <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                    <GridContent>
                                        <Routes>
                                            <Route path="" element={<StaffProfileContent data={state} OnClickCertImg={() => handleImgClick(`${BACKEND_URL}${state.staff.staff.cert}`, 'Certificate', 'Certificate')}  OnClickNationalIdImg={() => handleImgClick(`${BACKEND_URL}${state.staff.staff.national_id}`, 'National ID', 'National ID')} />} />
                                            <Route path="assign_subject" element={<StaffAssignSubject school_title={school_title} school_id={school_id} user_type={user_type} user_id={user_id} data={state} />} />
                                            <Route path="assign_role"  element={<AssignRoles school_id={school_id} user_type={user_type} user_id={user_id} data={state} />} />
                                        </Routes>
                                    </GridContent>
                                </>
                                )}
                                {preloading2 ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                    <GridContent style={{maxHeight:'80vh'}}>
                                    <Section>
                                        <span className="font-med fw-700 disp-block app-text-color">Other Staff</span><br />
                                        <Mallet>
                                            {allStaff.results.map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <View flex='50-line-o' type=""><img src={`${BACKEND_URL}${item.profile_pic}`} alt={`${item.last_name} ${item.first_name}`} style={{maxWidth:'100%', height:'50px', borderRadius:'5px'}} /></View>
                                                        <View flex='50-line-o' type="text"><Link to={`/${school_title}/staff/user/${item.uid}`} className="link font-very-small">{item.last_name} {item.first_name}</Link></View>
                                                    </Fragment>
                                                )
                                            })}
                                        </Mallet>
                                        <div className="content align-center">
                                            <Link to={`/${school_title}/staff`} className="link font-very-small">View More</Link>
                                        </div>   
                                    </Section>
                                    </GridContent>
                                </>
                                )}
                            </Grid>
                        </CustomWrapper>
                        {openImgViewer && (
                            <ImgViewer src={imgViewer.src} alt={imgViewer.alt} title={imgViewer.title} handleClose={() => setOpenImgViewer(false)}>
                                
                                    <Img src={imgViewer.src} alt={imgViewer.alt} selected />
                                
                            </ImgViewer>
                        )}
                    </ComponentWrapper>
                </>
            )  : (<><HeadComponent title='Error 404 - Page Not Found' /> <h1>aadsadala aodhoashdoasdaosdaopdhopahpdasdhpagdgdpsgdasgdp9aguysdfafdafdfayidfifaysdfafdoafuodfuafdfausftaftiafdfai</h1></>)
            }
        </> 
    )}
    </>
    )
}

export default StaffProfile;