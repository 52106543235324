import styled from "styled-components";

export const Content = styled.div`
    
    padding: 5px;
    .green{
        background: #057b00;
        color: #ddd;
    }
    .red{
        background: #570b00;
        color: #ddd;
    }
    .pink{
        background: #ff0090;
        color: #ddd;
    }
    .blue{
        background: #00057a;
        color: #ddd;
    }

    .btn{
        padding: 1rem 2rem;
        border: none;
        font-weight: 600;
        border-radius: 10px;
        box-sizing: border-box;
        box-shadow: 0 0 5px 7px rgba(0,0,0,0.2);
        transition: .3s ease;
        opacity: .9;
        cursor: pointer;
        @media(max-width: 540px){
            padding: .7rem 1.2rem;
        }

        :hover{
            i{
                padding-left: 10px;
            }
        }

        i{
            transform: scale(1.1);
            display: inline-block;
            padding-left: 5px;
            transition: .3s ease;
        }
    }

`;