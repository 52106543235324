import React from "react";
import { Wrapper, Content } from "./style";

const TABLE = ({children, style}) => {
    return(
        <Wrapper>
            <Content style={style}>
                {children}
            </Content>
        </Wrapper>
    )
}

export default TABLE;