import styled from "styled-components";
import { motion } from "framer-motion";

export const Content = styled(motion.div)`
    border: 1px solid var(--appBgColor);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    .disp-flex{
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .flex-5{
            flex: 0 0 5%;
            max-width: 5%;
        }
        .flex-15{
            flex: 0 0 15%;
            max-width: 15%;
        }
        .flex-20{
            flex: 0 0 20%;
            max-width: 20%;
        }
        .flex-30{
            flex: 0 0 30%;
            max-width: 30%;
        }
        .flex-60{
            flex: 0 0 60%;
            max-width: 60%;
        }
        .flex-85{
            flex: 0 0 85%;
            max-width: 85%;
        }
        .flex-100{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .refresh-div{
        padding: 3px;
        color: var(--appTextColor);
        button{
            border-radius: 50%;
            border:none;
            outline: none;
            color: var(--appNavTextColor);
        }
    }
    .main-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px;
        cursor: default;
        transition: .4s ease;
        :hover{
            i.btnn{
                background: var(--appBgColor);
                color: var(--appBgTextColor);
                
            }
        }
        

        span.heading{
            padding-left: 2px;
            text-transform: capitalize;
            font-weight: 600;
        }
        i.btnn{
            padding: 5px;
            border-radius: 50%;
            border: 1px solid var(--appBgColor);
            cursor: pointer;
            transition: .4s ease;
        }
    }
`;