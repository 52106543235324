import React, { useState, useEffect, useMemo } from "react";
import { AnimatePresence } from 'framer-motion';

import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Notification from "components/app/school/SmallerComponents/notification";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";

import { HeadComponent } from "components/head";
import { appServices } from "api/schoolApp/services";
import BreadCrumb from "components/breadCrumb";
import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";

import {default as success_icon} from 'images/success_icon.svg';


const SchoolBranches = ({school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    
    const [accordion, setAccordion] = useState(0);
    const [submitted, setSubmitted] = useState(null);

    const [loading, setLoading] = useState("");
    const [preloading, setPreloading] = useState("");
    const [preloading2, setPreloading2] = useState(false);

    const [schoolBranchData, setSchoolBranchData] = useState({results:[]});
    const [menuDropData, setMenuDropData] = useState({id:''});
    const [menuDrop, setMenuDrop] = useState(false)

    const [formBranch, setFormBranch] = useState({});

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState)
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
    }
    const handleBranchChange = (e) => {
        setFormBranch({...formBranch, [e.target.name]:e.target.value})
    }
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const handleBranchSubmit = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("left");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});   
        
        const data = {
            name: formBranch.branch,
            location: formBranch.location,
            school: school_id,
            created_by:user_id
        }

        appServices.insertSchoolBranch(data).then(res => {
            setFormBranch({name:"", location:''})
            setSuccess({text:"Data Saved Successfully", title:"Success"})
            getAllSchoolBranch(school_id);
            setLoading("");
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.name], title:"Branch Error"})
            setLoading("");
            console.log(e.response);
        })
    }
    const getAllSchoolBranch = (sid) => {
        setPreloading2(false);
        appServices.getAllSchoolBranch(sid).then(res => {
            setSchoolBranchData({results:res.data})
            setPreloading2(false);
        }).catch(e => {
            setError({data:[e.response.data], title:"Data Error"});
            setPreloading2(false);
        })
    }
    const handleEditBranch = (data) => {
        setMenuDrop(true);
        setMenuDropData(data);
    }
    const handleEditBranchChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }
    const handleBranchUpdate = (e) => {
        e.preventDefault();
        setSubmitted("");
        setLoading("menuDrop");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        const data = {
            name:menuDropData.name,
            location: menuDropData.location
        }
        appServices.updateSchoolBranch(menuDropData.id, data).then(res => {
            setError({...initialErrorState});
            setMenuDrop(false)
            setSuccess({text: "Data Updated Successfully", title:"success"})
            setSubmitted(menuDropData.id);
            getAllSchoolBranch(school_id);
            setLoading("");
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.name], title:"Branch Update Error"})
            setLoading("");
        })
        
    }
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmitted("none");
        }, 1000 * 15)
        return(
            <div className="nav-dd-anim" style={{display:submitted}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }
    const handleBranchDeleteCheck = (id, title) => {
        setWarning({id:0, title:'', text:''})
        setTimeout(() => {
            setWarning({id:id, click_text:`Delete ${title} Branch`, 
             title:"Warning", text:"Are You Sure You Want To Delete This Data?",
            handleClick: () => {
                setSubmitted(false);
                setLoading("right");
                setError({...initialErrorState});
                setSuccess(initialSuccessState);
                appServices.deleteSchoolBranch(id).then(res => {
                    setAnim({initial:{opacity: 1, duration:0.4},
                    animate:{opacity:0, duration:0.4, background:"#ff0000"},
                    id:id })
                    setLoading("");
                    setWarning({id:0, title:'', text:''})
                    setTimeout(() => {
                        getAllSchoolBranch(school_id);
                        setSuccess({text:"Data Deleted Successfully", title:"Success"})
                        setAnim({initial:{opacity: 0, duration:0.4},
                            animate:{opacity:1, duration:0.4, background:""},
                            id:id })
                    }, 500)
                }).catch(e => {
                    console.log(e.response);
                    setSuccess({...initialSuccessState});
                    setLoading("");
                    setError({data:["Something Went Wrong"], title:"Delete Branch Error"})
                })
            }
            });
        }, 100);
        
    }
    console.log(schoolBranchData)
    useEffect(() => {
        getAllSchoolBranch(school_id);
    }, [school_id])
    const insertSchoolBranchData = schoolBranchData.results.map((item, i) => {
        i++;
        const data = {
            i: i,
            id: item.id,
            name: item.name,
            location:item.location,
            type:"branch_form"
        }
        return(
            <Tr style={item.id === anim.id && {display:anim.display}} sn={true} animation={item.id === anim.id && {initial:{...anim.initial}, animate:{...anim.animate}}}
             handleDeleteClick={() => handleBranchDeleteCheck(item.id, item.name)} handleEditClick={() => handleEditBranch(data)}>
                <div className="c-data sn">{item.id === submitted ? updateSuccessIcon() : (<span className="font-very-small">{i}</span>)}</div>
                <div className="c-data"><span className="font-very-small">{item.name}</span></div>
                <div style={{width:'55%'}} className="c-data"><span className="font-very-small">{item.location}</span></div>
                {/* <div className="c-data"><span className="font-very-small">{item.created_by_last_name} {item.created_by_first_name}</span></div> */}

                <TdWrapper header_text={item.name} header_num={i}>
                    <Td heading="Name/Place" text={item.name} />
                    <Td heading="Address" text={item.location} />
                    <Td heading="Created By" text={`${item.created_by_last_name} ${item.created_by_first_name}`} />
                    <Td heading="Created" text={convertDate(item.created, "ddMMyyyy")} />
                </TdWrapper>
            </Tr>
        )
    })
    return(
        <>
            <HeadComponent title="Settings - School Branches" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid>
                    <GridContent header_text="Set School Branch" header_icon="fas fa-align-left">
                        {loading==="left" && (<Loader />)}
                        {preloading ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (<>
                        <Accordion handleSubmit={(e) => handleBranchSubmit(e)} index={1} text="Add Branch" handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && ( <>
                                <AccordionFormData required={true} handleChange={(e) => handleBranchChange(e)} value={formBranch.branch} tag="input" type="text" name="branch" classname="form-control-theme font-slightly-small" placeholder="Enter Branch Name/Place" />
                                <AccordionFormData required={true} handleChange={(e) => handleBranchChange(e)} value={formBranch.location} tag="input" type="text" name="location" classname="form-control-theme font-slightly-small" placeholder="Enter Branch Address" />
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>)}
                            
                        </Accordion>
                        </>)}
                    </GridContent>
                    <GridContent header_text="Manage School Branch" header_icon="fas fa-tasks">
                    {preloading2 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section icon="fas fa-eye" header="View School Branches">
                            <Table>
                                <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Name"}, {text:"Address", style:{width:'55%'}}]} />
                                {schoolBranchData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    insertSchoolBranchData                          
                                }
                            </Table>
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                                {loading === "menuDrop" && <Loader />}
                                {menuDropData.type === "branch_form" && (
                                    <form onSubmit={e => handleBranchUpdate(e)}>
                                        <div className='row'>
                                            <div className="fg">
                                                <label className="font-super-small">Name/Place </label><input required name="name" onChange={e => handleEditBranchChange(e)}
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Name/Place" value={menuDropData.name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Address </label><input required name="location" onChange={e => handleEditBranchChange(e)} 
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Address" value={menuDropData.location} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                        </>
                    )}
                    </GridContent>
                </Grid>    
            </ComponentWrapper>
        </>
    )
}

export default SchoolBranches;