import React from 'react';
import { Link } from 'react-router-dom';
import {Wrapper} from './style.js';

const NotFound = ({has_user=false}) => {
  return (
    <Wrapper>
      <h1 className="error-code">404</h1>
      <p className="error-message">Oops! The page you are looking for doesn't exist.</p>
      <div className='error-message-cont'>
        <Link className="home-link" to={`https://voskool.com`}>
            <i className='fas fa-arrow-left'></i> Back To Voskool
        </Link>
      </div> <br />
      <div className='error-message-cont'>
        <Link className="home-link" to={has_user ? "/school" : "/school/login"}>
             {has_user ? 'Dashboard' : 'Go To Login Page'} <i className='fas fa-arrow-right'></i>
        </Link>
      </div>
    </Wrapper>
  );
};

export default NotFound;
