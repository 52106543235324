export const numberDigit = (num=0, places=0) => {
    if (num.toString().length < places){
        var new_num = "";
        let number = places - num.toString().length;
        for(let i =0; i < number; i++){
            new_num += "0";
        }
        new_num += num.toString();
        return new_num;
    }
    else{
        return num.toString();
    }
}