import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    margin: 3px 0;
    flex-wrap: wrap;
    @media(max-width: 540px){
        margin: 3px 0;
    }
    .flex-33{
        flex: 0 0 33.33%;
        max-width: 33.33%;

        @media(max-width: 1280px){
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media(max-width: 380px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .flex-33-view{
        flex: 0 0 33.33%;
        max-width: 33.33%;

        @media(max-width: 490px){
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media(max-width: 320px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .flex-10{
        flex: 0 0 10%;
        max-width: 10%;
    }
    .flex-20{
        flex: 0 0 20%;
        max-width: 20%;

        @media(max-width: 540px){
            flex: 0 0 30%;
            max-width: 30%;
        }
    }
    
    .flex-30-line{
        flex: 0 0 30%;
        max-width: 30%;
        @media(max-width: 540px){
            flex: 0 0 40%;
            max-width: 40%;
        }
    }
    .flex-70-line{
        flex: 0 0 70%;
        max-width: 70%;
        @media(max-width: 540px){
            flex: 0 0 60%;
            max-width: 60%;
        }
    }
    .flex-50-line{
        flex: 0 0 50%;
        max-width: 50%;
        @media(max-width: 360px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .flex-50-line-o{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .flex-50{
        flex: 0 0 50%;
        max-width: 50%;
        @media(max-width: 380px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .flex{
        flex: 0 0 100%;
        max-width: 100%;
    }
    div{
        padding: 6px 10px;
        @media(max-width: 540px){
            padding: 3px 5px;
        }
        label{
            font-weight: 700;
            color: var(--appTextSecColor);
        }
        input, select{
            margin-top: 3px;
            border: 2px solid var(--appBgColor);
            color: var(--appTextColor);
            border-radius: 10px;
            
        }
    }
`;
export const Content = styled.div`
`;