import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    .img-user{
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }
    .stat-cont{
        .online{
            background: green;
        }
        .offline{
            background: red;
        }
        span.circle{
            width: 10px;
            display: block;
            height: 10px;
            border-radius: 50%;
        }
    }
    .border-right{
        border-right: 1px solid #ccc;
    }
    .flex-20{
        flex:  0 0 20%;
        max-width: 20%;
    }
    .flex-25{
        flex:  0 0 25%;
        max-width: 25%;
        @media (max-width: 1280px){
            flex:  0 0 30%;
            max-width: 30%;
        }
        @media (max-width: 780px){
            display: none;
        }
    }
    .flex-25-m-none{
        flex:  0 0 25%;
        max-width: 25%;
        @media (max-width: 1280px){
            display: none;
        }
    }
    
    .flex-50{
        flex:  0 0 50%;
        max-width: 50%;
        @media (max-width: 1280px){
            flex:  0 0 70%;
            max-width: 70%;
        }
        @media (max-width: 780px){
            display: none;
        }
    }
    .flex-mobile{
        display: none;
        @media (max-width: 780px){
            flex: 0 0 100%;
            max-width: 100%;
            display: block;
            z-index: 1000;
        }
    }
    .flex-60{
        flex:  0 0 60%;
        max-width: 60%;
    }
`;

export const Content = styled.div`
    position: relative !important;
    height: 100vh;
`;

export const Container = styled.div`
    width: 100%;
    position: absolute;
    height: 100%;
    overflow-y: auto;
`;