import {StyleSheet} from '@react-pdf/renderer';

export const TableStyle = StyleSheet.create({
    table: {
        width: '100%'
    },
    title_container: {
        display: 'flex',
        width:'100%',
        flexDirection:'column',
        marginTop:'5px',
        marginBottom:'5px'
    },
    title:{
        fontWeight: 600,
        fontSize:'13px'
    },
    bordered:{
        borderTop:'1px solid #000',
        borderBottom:'1px solid #000'
    }
})

export const TRStyle = StyleSheet.create({
    tr:{
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        width:'100%'
    },
    bordered:{
        borderBottom:'1px solid #000',
        borderBottomLeftRadius:'3px',
        borderBottomRightRadius:'3px'
    }
})

export const TDStyle = StyleSheet.create({
    td:{
        padding:'3px'
    },
    bordered:{
        borderRight:'1px solid #000'
    },
    text:{
        fontWeight:500,
        color:'#000'
    }
})

export const THStyle = StyleSheet.create({
    td:{
        padding:'4px'
    },
    bordered:{
        borderRight:'2px solid #000',
        borderBottom:'1px solid #000',
    },
    text:{
        fontWeight:600,
        color:'#000'
    }
})