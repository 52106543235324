import React from "react";
import { Content } from "./style";

const GridContent = ({children, theme="app-theme", header_text, header_icon="fas fa-plus", style}) => {
    return(
        <Content initial={{opacity: 0, duration: 0.5,}} animate={{opacity:1, duration: 1,}} className={theme} style={style}>
            { header_text && (
            <div className="header">
                <i className={`heading font-small ${header_icon}`}></i><span className={`heading font-slightly-small `}>{`${header_text} `}</span>
            </div>
            )}   
            {children}
        </Content>
    )
}

export default GridContent;