import React, { useCallback, useEffect, useState } from "react";

import { Wrapper, InnerWrapper } from "./style";

const Mash = ({pos="bottom", children}) => {
    const [y, setY] = useState(window.scrollY);
    const [yScroll, setYScroll] = useState(false);

    const handleMash = useCallback(e => {
            const window = e.currentTarget;
            if(y > window.scrollY){
                setYScroll(false);
            }
            else if(y < window.scrollY){
                setYScroll(true);
            }
            setY(window.scrollY);
        }, [y]
    )

    useEffect(() => {
        window.addEventListener("scroll", handleMash);

        return () => {
            window.removeEventListener("scroll", handleMash);
        }
    }, [handleMash])


    return(
        <Wrapper >
            <InnerWrapper initial={{y: yScroll ? 0 : 100}} animate={{y: yScroll ? 100 : 0, duration:0.3}} className={`${pos}`}>
                {children}
            </InnerWrapper>
        </Wrapper>
    )
}

export default Mash;