import React, { useState, useEffect, useMemo} from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
// import { saveAs } from 'file-saver';
import {PDFViewer, Document} from "@react-pdf/renderer";
import OMRGenerator from 'components/app/school/SmallerComponents/omrGenerator';


import { ComponentWrapper} from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
// import { appServices } from 'api/schoolApp/services';





const BookletTemplate = ({school_id, school_title, school_name, school_icon, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    // const [state, setState] = useState([])
    const [renderPage, setRenderPage] = useState(false);
    // const [currentPage, setCurrentPage] = useState(0);
    // const [pages, setPages] = useState(0);
    // const [changeIndex, setChangeIndex] = useState(false);
    const [index, setIndex] = useState([]);


    // console.log(index);
    // console.log("state: "+state.length);
    // console.log("page: "+pages);
    // console.log("current page: "+currentPage);
    const arraignData = () => {
        setRenderPage(false); 
        const data = [{stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}]
        
        // const tot_d2 = Math.round(data.length / 2);
        // const tot_d2_d5 = Math.round(tot_d2 / 5);
        // const tot_d2_d5_t10 = tot_d2_d5 * 10;
        // const pages = data.length > tot_d2_d5_t10 ? (tot_d2_d5 + 1) : tot_d2_d5
        // setCurrentPage(1);
        // setPages(pages);
        let dt = [];
        for(let i = 0; i < (data.length > 9 ? 10 : data.length); i++){
            dt.push({...data[i]});
        }
        setIndex(dt);
        // setState(data);
        setRenderPage(true);
    }
    // const arraignIndex = (curr_page=0, dt_len=0, ...dtt) => {
    //     if(curr_page){
    //         const dt_page = dt_len - (curr_page * 10);
    //         const curr_page_t10 = (curr_page - 1) * 10;
    //         setRenderPage(false); 
    //         let dt = [];
    //         for(let i = curr_page_t10; i < (dt_page > 9 ? (curr_page_t10+10) : dt_page); i++){
    //             dt.push({...dtt[i]});
    //         }
    //         setIndex(dt);
    //         setRenderPage(true);
    //     }
    // }

    useEffect(() => {
        arraignData()
    }, [])
    
    // useEffect(() => {
    //     if(currentPage){
    //         arraignIndex(currentPage, 53, []);
    //     }
    // }, [state, currentPage])

    // useEffect(() => {
    //     setIndex([
    //         {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    //         {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    //     ])
    //     setState([
    //         {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, 
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},
    // {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'},{stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}, {stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}
    //     ])
    // },[])

    const schoolColor = "#00057a";
    
    const errorHandler = error.data.map((item, i) => {
        return(<Notification type="error" text={item} key={i} title={error.title} />)
    })

    // const generatePdfDocument = async (fileName) => {
    //     const blob = await pdf((
    //         <OMRGenerator schoolColor={schoolColor} school_id={school_id} school_icon={school_icon} school_name={school_name} school_title={school_title} />
    //     )).toBlob();
    //     saveAs(blob, fileName);
    // };

    

    return(
        <>
            <HeadComponent title='Booklet - Template - Exam' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                    <GridContent header_text={`OMR Template`} header_icon="fas fa-eye">
                        <Section style={{width: '100%'}} >
                            {/* <button type="button" onClick={() => generatePdfDocument("asdadasd")}>Save</button> */}
                            <PDFViewer showToolbar={false} style={{
                                width: '100%',
                                height: '70vh',
                                }}> 
                                <Document>
                                    {renderPage && (
                                        <OMRGenerator renderPage={renderPage} data={index} schoolColor={schoolColor} school_id={school_id} school_icon={school_icon} school_name={school_name} school_title={school_title} />
                                    )}
                                </Document> 
                            </PDFViewer>
                            {/* <PDFDownloadLink
                                document={<OMRGenerator schoolColor={schoolColor} school_id={school_id} school_icon={school_icon} school_name={school_name} school_title={school_title} />}
                                fileName={`${school_title.toUpperCase()} OMR SHEET`}
                            >
                                {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
                            </PDFDownloadLink> */}
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default BookletTemplate;