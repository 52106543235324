import React from "react";
import { Wrapper, Content } from "./style";
import { Link } from "react-router-dom";


const Td = ({heading="", link="", text_link="", text="", profile_pic="", profile_status='', text_class="app-text-color", text_icon="", styleImg,styleText={}, target=""}) => {
    //  : 
                    // (<span className="circle red"><span className="font-super-small">Offline</span></span>) 
    return(
        <>
        {profile_pic && (
            <div className="content align-center">
                <div className="profile-cont">
                    <img src={profile_pic} style={styleImg} alt="Profile" />
                    {profile_status === "online" && (<span className="circle green"><span className="font-super-small">Online</span></span>)}
                    
                </div>
            </div>
        )}   
        {heading && (
            <div className="content">
                {heading && (<span className="heading font-very-small">{heading}:- </span>) }
                {text && (<span style={{paddingLeft:'3px', ...styleText}} className={`text font-very-small ${text_class}`}>{text} <i className={text_icon}></i></span>) }
            </div>   
        )}
        {link && (
            <div className="content align-center">
                {link && (<Link to={link} className="link font-very-small" target={target}>{text_link}</Link>) }
            </div>   
        )}
        
        </>    
    )
}
export const TdWrapper = ({children, header_num, header_text, center=false}) => {
    return(
        <Wrapper className={`inner-content ${ center && 'center-td-wrapper'}`}>
            <Content>
                {header_text && (
                <div className="header">
                    {header_num && (<span className="heading-left font-slightly-small">{header_num}</span>)}
                    <span className=" font-slightly-small">{header_text}</span>
                </div>)}
                {children}
            </Content>
        </Wrapper>
    )
}
export default Td;