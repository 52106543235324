export const timify = (time="") => {
    var tym;
    var hour = parseInt(time.slice(0,2))
    if(time.length > 4){
        const minutes = time.slice(3,5)
        tym = `${hour.toString()}:${minutes ? minutes.toString() : '00'}${hour === 12 ? 'pm' : 'am'}`
        if(hour > 12){
            hour = hour - 12;
            tym = `${hour.toString()}:${minutes ? minutes.toString() : '00'}pm`;
        }
        return tym;
    } else { return time;}
}

export const timifyInterval = (intrval=0) => {
    let interval = parseInt(intrval) * 1000
    let year = Math.floor(parseInt(interval) / (60 * 60 * 24 * 365 * 1000)) ;
    let month =  Math.floor(((parseInt(interval) % (60 * 60 * 24 * 365 * 1000)) / (60 * 60 * 24 * 30* 1000)));
    let week =  Math.floor(((parseInt(interval) % (60 * 60 * 24 * 365 * 1000)) / (60 * 60 * 24 * 7 * 1000)));
    let day = Math.floor((((parseInt(interval) % (60 * 60 * 24 * 365 * 1000)) % (60 * 60 * 24 * 7 * 1000)) / (60 * 60 * 24 * 1000))) ;
    let hour =  Math.floor( ((((parseInt(interval) % (60 * 60 * 24 * 365 * 1000)) % (60 * 60 * 24 * 7 * 1000)) % (60 * 60 * 24 * 1000)) / (60 * 60 * 1000) ))
    let minute = Math.floor( (((((parseInt(interval) % (60 * 60 * 24 * 365 * 1000)) % (60 * 60 * 24 * 7 * 1000)) % (60 * 60 * 24 * 1000)) % (60 * 60 * 1000)) / (60 * 1000) ));
    let second = Math.floor( ((((((parseInt(interval) % (60 * 60 * 24 * 365 * 1000)) % (60 * 60 * 24 * 7 * 1000)) % (60 * 60 * 24 * 1000)) % (60 * 60 * 1000)) % (60 * 1000)) / (1000) ));

    return {year:year, week:week, day:day, hour:hour, minute:minute, second:second, month:month}
}