import React from 'react';

const Img = ({src, alt="", text, onClick, selected=false}) => {
    return(
        <div className={`item ${selected && 'sel'}`} onClick={onClick}>
            <img src={src} alt={alt} />
            <span className='font-super-small'>{text}</span>
        </div>
    )
}

export default Img;