// import Mash from "..";
import { Content } from "./style";

const Btn = ({children, disabled=false, onClick, flex=50, align="", color=""}) => {
    return(
        <Content className={`flex-${flex} align-items-${align}`}>
            <button onClick={onClick} style={{cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? 0.6 : 1}} disabled={disabled} type="button" className={`btn font-small ${color}`}>{children}</button>
        </Content>
    )
}

export default Btn;