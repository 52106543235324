import React from "react";
import { Wrapper } from "./style";

const BackDrop = ({children, theme="", onClick}) => {
    return(
        <Wrapper className="" style={{background:"rgba(0,0,0,0.97)"}} onClick={onClick} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} //transition={{duration: 1}}
        >
            {children}
        </Wrapper>
    )
}

export default BackDrop;