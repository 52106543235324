import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { addItemToArray } from 'components/app/school/actions/array-utils/Add';

const SchoolScoreGradeSystem = ({school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [accordion, setAccordion] = useState(0);

    const [menuDropData, setMenuDropData] = useState({id:'', num:0 });
    const [formAccordion, setFormAccordion] = useState({name:'', fmark:'', tmark:''});
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const [allScoresGradeData, setAllScoresGradeData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
    }
    const handleAccordionFormChange = (e) => {
        setFormAccordion({...formAccordion, [e.target.name]:e.target.value})
    }
    const onChangeMenuGrade = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }

    const fetchAllScoresGrade = (sid="", pre=true) => {
        setError({data:[], title:''});
        setPreloading(pre);
        setSpinLoading(!pre);
        appServices.getAllSchoolScoresGrade(sid).then(res => {
            setAllScoresGradeData(addItemToArray(res.data));
            setPreloading(false);
            setSpinLoading(false);
        }).catch(e => {
            setAllScoresGradeData([]);
            setPreloading(false);
            setSpinLoading(false);
            setError({data:["Internal Server Error"], title:"Scores Grade Data Fetch Error"});
        })
    }

    const handleAccordionSubmit = (e) => {
        e.preventDefault();
        if(parseInt(formAccordion.fmark) < parseInt(formAccordion.tmark)){
            setSuccess(initialSuccessState);
            setError(initialErrorState);
            setLoading(true);
            const data = {
                name: formAccordion.name,
                tmark: parseInt(formAccordion.tmark),
                fmark: parseInt(formAccordion.fmark),
                grade: formAccordion.grade.toUpperCase(),
                short_name: formAccordion.short_name ? formAccordion.short_name.toUpperCase() : '',
                school: school_id
            }
            appServices.insertSchoolScoresGrade(data).then(res => {
                setLoading(false);
                setFormAccordion({name:'', fmark:'', tmark:'', short_name:''})
                fetchAllScoresGrade(school_id, false);
                setSuccess({text:"Data Saved Successfully", title:"Success"});
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Grade Submit Error'});
                setLoading(false);
            })
            return;
        }
        setError({data:['From cannot be greater than to'], title:'Grade Submit Error'});
    }
    const handleEditScoresSubmit = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        const data = {
            name: menuDropData.name,
            tmark: parseInt(menuDropData.tmark),
            fmark: parseInt(menuDropData.fmark),
            grade: menuDropData.grade.toUpperCase(),
            short_name: menuDropData.short_name ? menuDropData.short_name.toUpperCase() : '',
        }
        appServices.updateSchoolScoresGrade(menuDropData.uid, data).then(res => {
            setMenuLoading(false);
            setMenuDrop(false);
            fetchAllScoresGrade(school_id, false);
            setSuccess({text:"Data Updated Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Scores Grade Update Error'});
            setMenuLoading(false);
            console.log(e.response)
        })
    }
    const handleDeleteScoresGrade = (id="", num=0) => {
        setLoading2(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices.deleteSchoolScoresGrade(id).then(res => {
            setLoading2(false);
            setAnim({initial:{opacity: 1, duration:0.4},
                animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
                id:num });
            setTimeout(() => {
                fetchAllScoresGrade(school_id, false);
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:num })
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
            }, 500)
        }).catch(e => {
            setLoading2(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:"Delete Score Grade Error"})
        })
    }

    const onClickEditIcon = (item, i=0) => {
        setMenuDropData({...item, num:i});
        setMenuDrop(true);
    }

    useEffect(() => {
        fetchAllScoresGrade(school_id, true);
    }, [school_id])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const insertData = allScoresGradeData.map((item, i) => {
        return(
            <Fragment key={i}>
                <Tr sn={false} edit_btn={true} add_btn={false} dlt_btn handleEditClick={() => onClickEditIcon({...item}, item.num)} handleDeleteClick={() => handleDeleteScoresGrade(item.uid, item.num)}
                animation={(item.num === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                    <div className="c-data"><span className="font-very-small fw-700">{item.fmark} - {item.tmark}%</span></div>
                    <div className="c-data"><span className="font-very-small">{item.grade}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.name} ({item.short_name})</span></div>
                </Tr>
            </Fragment>
        )
    })

    return(
        <>
            <HeadComponent title='Manage Score Grading System' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='l13'>
                    <GridContent header_text={`Add New Grade`} header_icon="fas fa-plus">
                        {loading && (<Loader />)}
                        <Accordion handleSubmit={e => handleAccordionSubmit(e)} index={1} text="Add Grade" handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && ( 
                            <>  
                                <label className='app-text-color fw-500 font-super-small flex-100 padd-5px'>Enter Score Range</label>
                                <div className='disp-flex wrap'>
                                    <AccordionFormData min={0} max={99} styleContent={{flex:'0 0 40%', maxWidth:'40%'}} required handleChange={(e) => handleAccordionFormChange(e)} name="fmark" value={formAccordion.fmark} tag="input" type="number" classname="form-control-theme font-slightly-small" placeholder="From..." />
                                    <div style={{flex:'0 0 20%', maxWidth:'20%', textAlign:'center'}} className='font-big'> - </div>
                                    <AccordionFormData min={1} max={100} styleContent={{flex:'0 0 40%', maxWidth:'40%'}} required handleChange={(e) => handleAccordionFormChange(e)} name="tmark" value={formAccordion.tmark} tag="input" type="number" classname="form-control-theme font-slightly-small" placeholder="To..." />
                                </div>

                                <AccordionFormData required handleChange={(e) => handleAccordionFormChange(e)} name="grade" value={formAccordion.grade} tag="input" type="text" classname="form-control-theme font-slightly-small" placeholder="Enter Grade" />
                                <AccordionFormData required handleChange={(e) => handleAccordionFormChange(e)} name="name" value={formAccordion.name} tag="input" type="text" classname="form-control-theme font-slightly-small" placeholder="Enter Interpretation" />
                                <AccordionFormData handleChange={(e) => handleAccordionFormChange(e)} name="short_name" value={formAccordion.short_name} tag="input" type="text" classname="form-control-theme font-slightly-small" placeholder="Enter Short Interpretation*" />
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                            </>
                            )}
                        </Accordion>
                    </GridContent>
                    <GridContent header_text={`Manage Score Grading System`} header_icon="fas fa-tasks">
                        {loading2 && (<Loader />)}
                        <Section>
                        {preloading ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                            <Table>
                                {spinLoading && <Spinner />}
                                <Tr btn={false}  header={true} sn={false} text_style='c-data' text={[{text:"Score"}, {text:'Grade'},  {text:'Interpretation (Short)'}]} />
                                {allScoresGradeData.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                (<>
                                    {insertData}
                                </>)                         
                                }
                            </Table>
                        </>
                        )}
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false)}} header_text={"Edit Grade"} header_icon={"fas fa-pen"}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    <form onSubmit={e => handleEditScoresSubmit(e)}>
                                        <div className='row'>
                                            <div className="fg-all">
                                                <label className="font-super-small fw-600">Scores Range</label>
                                                <div className='disp-flex wrap'>
                                                    <div style={{flex:'0 0 40%', maxWidth:'40%'}}>
                                                        <input type="number" min={0} max={99} required onChange={e => onChangeMenuGrade(e)} name="fmark" className="font-very-small app-theme form-control-theme" placeholder={`From...`} value={menuDropData.fmark} />
                                                    </div>
                                                    <div style={{flex:'0 0 20%', maxWidth:'20%', textAlign:'center'}} className='font-big'> - </div>
                                                    <div style={{flex:'0 0 40%', maxWidth:'40%'}}>
                                                        <input type="number" min={1} max={100} required onChange={e => onChangeMenuGrade(e)} name="tmark" className="font-very-small app-theme form-control-theme" placeholder={`To...`} value={menuDropData.tmark} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Grade</label>
                                                <input type="text" required name="grade" onChange={e => onChangeMenuGrade(e)} className="font-very-small app-theme form-control-theme" placeholder={`Enter Grade`} value={menuDropData.grade} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Interpretation</label>
                                                <input type="text" required name="name" onChange={e => onChangeMenuGrade(e)} className="font-very-small app-theme form-control-theme" placeholder={`Enter Interpretation`} value={menuDropData.name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Short Interpretation*</label>
                                                <input type="text" name="short_name" onChange={e => onChangeMenuGrade(e)} className="font-very-small app-theme form-control-theme" placeholder={`Enter Short Interpretation`} value={menuDropData.short_name} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )


}

export default SchoolScoreGradeSystem;