import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 70px;
    transition: .8s ease;

    @media (max-width: 780px){
        padding: 7px;
    }
    @media (max-width: 540px){
        padding: 3px;
    }
    h2{
        font-size: 1.7rem;
        color: #888;

        @media (max-width: 768px){
            font-size: 1.2rem;
        }
    }
    .small-w{
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        @media (max-width: 320px){
            grid-template-columns: auto;
        }
    }
    .big-w{
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 2rem;
        @media (max-width: 1280px){
            grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
            grid-gap: 1.5rem;
        }
        @media (max-width: 620px){
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-gap: 1.5rem;
        }
        /* @media (max-width: 1024px){
            grid-template-columns: auto;
            grid-gap: 1rem;
        } */
        /* @media (max-width: 540px){
            grid-template-columns: auto;
        } */
    }
`;

export const Content = styled.div`
    
    display: grid;
    
    
    
    
`;