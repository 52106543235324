import React, { useState, useEffect, useMemo, useCallback, Fragment} from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper} from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import BlankLoader from "components/blankLoader";
import Loader from 'components/app/school/SmallerComponents/loader';
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { timify } from "components/app/school/actions/timify";

const SetSchoolWeekDays = ({school_id, user_id, user_type, school_title, working_time_start, working_time_end}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const [workingTime, setWorkingTime] = useState({start:'', end:''})

    const [allDays, setAllDays] = useState([{id:0, name:'', short_name:''}]);

    const [preloading, setPreloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);

    const fetchAllWeekday = (sid, loader=true) => {
        setPreloading(loader);
        setSpinLoading(!loader);
        appServices.getAllSchoolWeekDayData(sid, "all").then(res => {
            setPreloading(false);
            setSpinLoading(false);
            setAllDays(res.data);
        }).catch(e => {
            setPreloading(false);
            setSpinLoading(false);
            setAllDays([]);
            setError({title:'Day Fetch Error', data:['Internal Server Error']});
        })
    }

    const fetchSchool = (title) => {

    }

    const insertAllDays = useCallback(() => {
        if (school_id){
            setError(initialErrorState);
            setPreloading(true);
            const data = {school: school_id, day:1}
            appServices.insertSchoolWeekDay(data).then(res=> {
                setTimeout(() => {
                    fetchAllWeekday(school_id);
                    setPreloading(false);
                }, 200);
            }).catch(e => {
                setPreloading(false);
                // setError({title:'Day Insert Error', data:['Something Went Wrong']});
            })
        }
    }, [school_id, initialErrorState])

    const onChangeBox = ( value=false, id=0) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        appServices.updateSchoolWeekDay(id).then(res => {
            setLoading(false);
            fetchAllWeekday(school_id, false);
            setSuccess({title:'Success', text:`Data Sent Successfully`});
        }).catch(e => {
            setError({title:'Checkbox Error', data:[e.response.data.detail]});
            setLoading(false);
            console.log(e.response);
            fetchAllWeekday(school_id, false);
        })
    }

    useEffect(()=> {
        fetchAllWeekday(school_id);
        setWorkingTime({start:working_time_start, end:working_time_end});
    }, [school_id, working_time_end, working_time_start])
    useEffect(() => {
        if(!allDays.length && school_id){insertAllDays()}
    }, [allDays, school_id, insertAllDays])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })


    

    const insertTableData = () => {
        if(allDays.length <= 1){
            return(<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>)   
        } else {
            return (allDays.map((item, i) => {
                return(
                    <Fragment key={i}>
                        <Tr edit_btn={false} dlt_btn={false}>
                            <div className="c-data align-center" style={{width:'90%'}}><span className="font-small">{item.name}</span></div>
                            <div className="c-data"><input type={"checkbox"} checked={item.is_active} value={item.is_active} onChange={() => onChangeBox(!item.is_active, item.uid)} /></div>
                        </Tr>
                    </Fragment>
                )
            }))
        }
    }

    return(
        <>
            <HeadComponent title='School Weekdays - Set' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> 
                <Grid layout='l31'>
                    {preloading ? (<ul>
                    <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <GridContent header_text={`Manage Days`} header_icon="fas fa-align-right">
                            <div className='align-right'><span className='font-small app-text-color fw-600'>SCHOOL WORKING TIME <span>{timify(workingTime.start)} - {timify(workingTime.end)}</span></span></div>
                            <Note>Please select only the school's working days</Note>
                            <Section>
                                {loading && <Loader />}
                                <Table>
                                    <Tr btn={false}  header={true} text={[{text:"Day Name", class:'font-small', style:{width:'90%', textAlign:'center'}}, {text:"Box", class:'font-small'}]} />
                                    {spinLoading && <Spinner />}
                                    {insertTableData()}
                                </Table>    
                            </Section>
                        </GridContent>
                    </>
                    )}
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SetSchoolWeekDays;