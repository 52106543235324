import React from "react";
import { Content } from "./style";

const InlineBtn = ({children, label="", onClick, classname="font-med bg-none bordered-blue br blue", style}) => {
    return(
        <Content className=" align-center">
            {label && <label className="font-super-small">{label}</label>}
            <button type="button" onClick={onClick} className={`btn ${classname}`}>{ children }</button>
        </Content>
    )
}

export default InlineBtn;