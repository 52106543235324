import React from "react";
import { Wrapper } from "./style";

const InlineNotification = ({type, data}) => {
    const sortNot = () => {
        if(type === "success"){
            return(
                <div class="success_cont2"><div class="icon_cont"><span class="fas fa-check icon"></span></div><span class="success_text rem1">{data}</span></div>
            )
        }
        else if(type === "error"){
            return(
                <div class="error_cont error_c_name"><div class="icon_cont"><span class="fas fa-times icon"></span></div> <span class="error_text rem1">{data}</span></div>
            )
        }
    }
    return(

        <Wrapper>
            {sortNot()}
        </Wrapper>
    )
}

export default InlineNotification;