import React from "react";
import { Wrapper, Content, InnerWrapper } from './style';

const Select = ({children, error, onClick, styleInput, required=false, clicked=false, name, value, placeholder="Select...", font_size="font-very-small", pad='3px', type="", styleWrapper={}}) => {
    return(
        <Wrapper style={{padding:pad}}>
            <Content>
                <i onClick={onClick} className={`icon-place fas ${ !clicked ?  'fa-angle-down' : 'fa-angle-left'} ${font_size}`}></i>
                {type === "textarea" ? (<textarea style={styleInput} onClick={onClick} required={required} readOnly placeholder={placeholder} name={name} defaultValue={value} className={`sel-inp text-capitalize ${font_size}`} />)
                : (<input style={styleInput} onClick={onClick} required={required} readOnly placeholder={placeholder} type="text" name={name} defaultValue={value} className={`sel-inp text-capitalize ${font_size}`} />)
                }
                <InnerWrapper style={clicked ? {visibility:'visible', ...styleWrapper} : {visibility:'hidden', ...styleWrapper}}>
                    {children}
                </InnerWrapper>
            </Content>
            {error && (<span className="font-super-small" style={{color:'red', fontWeight:'500'}}>{error}</span>)  }
        </Wrapper>
    )
}

export default Select;