import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { addItemToArray } from 'components/app/school/actions/array-utils/Add';

const SchoolScoringSystem = ({school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [menuDropData, setMenuDropData] = useState({id:'', num:0 });
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const [allScoresData, setAllScoresData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);

    

    const fetchAllScores = (sid="", pre=true) => {
        setError({data:[], title:''});
        setPreloading(pre);
        setSpinLoading(!pre);
        appServices.getAllSchoolScores(sid).then(res => {
            setAllScoresData(addItemToArray(res.data));
            setPreloading(false);
            setLoading(false);
            setSpinLoading(false);
        }).catch(e => {
            setAllScoresData([]);
            setPreloading(false);
            setLoading(false);
            setSpinLoading(false);
            setError({data:["Internal Server Error"], title:"Scores Data Fetch Error"});
        })
    }

    const onClickIcon = (type="", item, i=0, pos="after") => {
        setLoading(true);
        setMenuDropData({...item, type:type, num:i, pos:pos, new_mark:'', new_long_name:'', new_short_name:''});
        setMenuDrop(true);
        setLoading(false);
    }
    const onChangeMenuScores = (e, type="") => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value});
    }

    const handleAddScoresSubmit = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        let order = 0;
        const checkOrder = allScoresData.find((item) => item.order > menuDropData.order);
        if(checkOrder && menuDropData.pos === "after"){
            order = menuDropData.order + 1;
        } else if(checkOrder && menuDropData.pos === "before"){
            order = menuDropData.order - 1;
        }
        else {
            order = menuDropData.order + 500;
        }
        const data = {
            school:school_id,
            short_name: menuDropData.new_short_name,
            long_name: menuDropData.new_long_name,
            mark: parseInt(menuDropData.new_mark),
            order: order
        }
        appServices.insertSchoolScores(data).then(res => {
            setMenuLoading(false);
            setMenuDrop(false);
            setLoading(true);
            fetchAllScores(school_id, false);
            setSuccess({text:"Data Saved Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Scores Submit Error'});
            setLoading(false);
            setMenuLoading(false);
        })
    }
    const handleEditScoresSubmit = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        const data = {
            school:school_id,
            short_name: menuDropData.short_name,
            long_name: menuDropData.long_name,
            mark: parseInt(menuDropData.mark)
        }
        appServices.updateSchoolScores(menuDropData.uid, data).then(res => {
            setMenuLoading(false);
            setMenuDrop(false);
            setLoading(true);
            fetchAllScores(school_id, false);
            setSuccess({text:"Data Updated Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Scores Update Error'});
            setLoading(false);
            setMenuLoading(false);
            console.log(e.response)
        })
    }
    const handleDeleteScores = (id="", num=0) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices.deleteSchoolScores(id).then(res => {
            setLoading(false);
            setAnim({initial:{opacity: 1, duration:0.4},
                animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
                id:num });
            setTimeout(() => {
                fetchAllScores(school_id, false);
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:``},
                    id:num })
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
            }, 500)
        }).catch(e => {
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:"Delete Book Error"})
        })
    }

    console.log(allScoresData)
    useEffect(() => {
        fetchAllScores(school_id, true);
    }, [school_id])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const insertData = allScoresData.map((item, i) => {
        return(
            <Fragment key={i}>
                <Tr sn={false} edit_btn={true} add_btn={true} dlt_btn  handleAddClick={() => onClickIcon("add_scores", {...item}, item.num)} handleEditClick={() => onClickIcon("edit_scores", {...item}, item.num)} handleDeleteClick={() => handleDeleteScores(item.uid, item.num)} 
                animation={(item.num === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                    <div className="c-data"><span className="font-very-small fw-700">{item.short_name}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.long_name}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.mark}%</span></div>
                </Tr>
            </Fragment>
        )
    })
    const g_total_mark = allScoresData.reduce((total, num) => {
        return total + parseInt(num.mark)
    }, 0)

    console.log(g_total_mark)

    return(
        <>
            <HeadComponent title='Manage Scoring System' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage Scoring System`} header_icon="fas fa-tasks">
                        <Section>
                        {loading && (<Loader />)}
                        {preloading ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                            <div className='align-right'><span className='fw-700 btn bg-blue br-circle app-bg-text-color' onClick={() => onClickIcon("add_scores", {short_name:allScoresData.length ? allScoresData[0].short_name : '', long_name:allScoresData.length ? allScoresData[0].long_name : '', order:allScoresData.length ? allScoresData[0].order : 0, id:'as'}, -1, 'before')}><i className='fas fa-plus'></i></span></div>
                            <Table>
                                {spinLoading && <Spinner />}
                                <Tr btn={false}  header={true} sn={false} text_style='c-data' text={[{text:"Short Name"}, {text:'Long Name'},  {text:'Total Score (%)'}]} />
                                {allScoresData.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                (<>
                                    {insertData}
                                </>)                         
                                }
                                <Tr sn={false} btn={false} ><div className='c-data'></div> <div className='c-data'></div> <div className='c-data'><span className="font-slightly-small fw-700">GT = {g_total_mark}%</span></div></Tr>
                            </Table>
                        </>)
                        }
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false);}} header_text={menuDropData.type==="add_scores" ? "Add New Scores" : "Edit Scores"} header_icon={menuDropData.type==="add_scores" ? "fas fa-plus" : "fas fa-pen"}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    {menuDropData.type === "add_scores" && (
                                        <form className='row' onSubmit={e => handleAddScoresSubmit(e)}>
                                            <div className="fg-all">
                                                <label className="font-super-small fw-600">Position to add</label>
                                                <input type="text" name="after_short_name" className="font-very-small app-theme form-control disabled" placeholder={`Position`} disabled defaultValue={`${menuDropData.pos} ${menuDropData.long_name} (${menuDropData.short_name})`} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Enter Short Name</label>
                                                <input type="text" required onChange={e => onChangeMenuScores(e, menuDropData.type)} name="new_short_name" className="font-very-small app-theme form-control-theme" placeholder={`Short Name`} value={menuDropData.new_short_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Enter Long Name</label>
                                                <input type="text" required name="new_long_name" onChange={e => onChangeMenuScores(e, menuDropData.type)} className="font-very-small app-theme form-control-theme" placeholder={`Long Name`} value={menuDropData.new_long_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Enter Total Mark</label>
                                                <input type="number" required name="new_mark" onChange={e => onChangeMenuScores(e, menuDropData.type)} className="font-very-small app-theme form-control-theme" placeholder={`Mark`} value={menuDropData.new_mark} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Save <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </form>
                                    )}
                                    {menuDropData.type === "edit_scores" && (
                                        <form className='row' onSubmit={e => handleEditScoresSubmit(e)}>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Short Name</label>
                                                <input type="text" required onChange={e => onChangeMenuScores(e, menuDropData.type)} name="short_name" className="font-very-small app-theme form-control-theme" placeholder={`Short Name`} value={menuDropData.short_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Long Name</label>
                                                <input type="text" required name="long_name" onChange={e => onChangeMenuScores(e, menuDropData.type)} className="font-very-small app-theme form-control-theme" placeholder={`Long Name`} value={menuDropData.long_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Total Mark</label>
                                                <input type="number" required name="mark" onChange={e => onChangeMenuScores(e, menuDropData.type)} className="font-very-small app-theme form-control-theme" placeholder={`Mark`} value={menuDropData.mark} />
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </form>
                                    )}
                                </>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolScoringSystem;