import React from "react";
import BackDrop from "components/backDrop";
import { Wrapper, Content } from "./style";

const ImgViewer = ({children, zIndex="", src, alt="Blank", zoom=false, next=false, prev=false, handleClose, onClickNextBtn, onClickPrevBtn, title=""}) => {
    return(
        <BackDrop zIndex={zIndex ? zIndex : 'z-9'}>
            <Wrapper>
                <Content>
                    <div className="flex-60">
                        <div className="close-cont">
                            <button type="button" className="font-small" onClick={handleClose}>Close</button>
                        </div>
                        <img src={src} alt={alt} />
                        <div className="btn-cont">
                            {prev && (<button type="button" className="font-small" onClick={onClickPrevBtn}><i className="fas fa-arrow-left"></i></button>)}
                            {zoom && (<button type="button" className="font-small br"><i className="fas fa-image"></i></button>)}
                            {next && (<button type="button" className="font-small" onClick={onClickNextBtn}><i className="fas fa-arrow-right"></i></button>)}
                        </div>
                    </div>
                    <div className="flex-40">
                        <div className="close-cont">
                            <button type="button" className="font-small" onClick={handleClose}>Close</button>
                        </div>
                        <span className="font-slightly-small sp">All {title} Image(s)</span>

                        <div className="inner-content">
                            {children}
                        </div>
                    </div>
                </Content>
            </Wrapper>
        </BackDrop>
    )
}

export default ImgViewer;