import React, { useState, useEffect, Fragment } from "react";
import {PDFDownloadLink, PDFViewer, Document, Font, Page, View, Image, Text} from "@react-pdf/renderer";
import { Table, TR, TD, TH } from 'components/app/school/SmallerComponents/pdf/tableComponent';

import {pdfStyle} from './style';
import {attendanceIconPdf} from 'components/app/school/SmallerComponents/attendanceIcon';

import raleway_regular from 'font/raleway.regular.ttf';
import raleway_semibold from 'font/raleway.semibold.ttf';
import raleway_bold from 'font/raleway.bold.ttf';
import raleway_extrabold from 'font/raleway.extrabold.ttf';

import voskool_logo from "images/voskool_logo.png";

import { spread_attendance_days } from 'components/app/school/actions/array-utils/Attendance';
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { filterInnerArray } from 'components/app/school/actions/array-utils/Filter';

import { appServices } from 'api/schoolApp/services';
import { BACKEND_URL } from 'actions/url';

const SchoolTimeTablePdf = ({type="", user_id, sess_id=0, school, school_branch_id=0, classes, day_len=10, from_date, to_date, is_download=false}) => {

    const [state, setState] = useState({id:0});
    const [sessionState, setSessionState] = useState({});

    const [allAttendance, setAllAttendance] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [loadData, setLoadData] = useState(false);
    const [err, setErr] = useState(false);
    const [error, setError] = useState([]);
    const [reload, setReload] = useState(false);

    const fetchSession = (sid) => {
        if(sid){
            appServices.getCurrentSession(sid).then(res => {
                setSessionState(res.data);
            }).catch(e => {
                setSessionState({});
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading This Page'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading This Page'}`]);
            })
        }
    }
    const fetchSchoolAttendance = (sid, ssid) => {
        if(sid && ssid){
            appServices.getSchoolAttendance(sid, ssid).then(res => {
                setState(res.data);
                setLoadData(true);
            }).catch(e => {
                setState({id:0});
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading This Page'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading This Page'}`]);
            })
        }
    }

    const fetchAllSchoolAttendance = (type="", sid, ssid, sbid, cid, from_date, to_date) => {
        if(type === "student" && sid && ssid && sbid && cid){
            setPreloading(true);
            appServices.getAllSchoolStudentAttendance(sid, ssid, sbid, cid, from_date, to_date).then(res => {
                setPreloading(false);
                setAllAttendance(res.data);
            }).catch(e => {
                setPreloading(false);
                setErr(true);
                setError((prev) => prev.length > 0 ? [...prev, `${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`] : [`${e.response.data.detail ? e.response.data.detail : 'Something Went Wrong, Try Reloading'}`]);
            })
        }
    }
    Font.register({family:'Raleway', fonts:[{src:`${raleway_regular}`}, {src:raleway_semibold, fontWeight:600} , {src:raleway_bold, fontWeight:700} , {src:raleway_extrabold, fontWeight:800}]});
    useEffect(() => {
        fetchSession(school.id);
        fetchSchoolAttendance(school.id, sess_id);
    }, [school, sess_id])

    useEffect(() => {
        if(sessionState.is_start_student_attendance && state.id && loadData){
            setLoadData(false);
            fetchAllSchoolAttendance(type, school.id, sess_id, school_branch_id, classes.id, from_date, to_date);
        }
    }, [type, school, sess_id, school_branch_id, classes, sessionState, state, loadData, from_date, to_date])  

    useEffect(() => {
        if(reload){
            setReload(false);
            fetchSession(school.id);
            fetchSchoolAttendance(school.id, sess_id);
        }
    }, [reload, school, sess_id])

    const insertAttendanceData = () => {
        return(
            <Page size={'A4'} wrap break orientation="landscape">
                <Text style={pdfStyle.pagination} render={({pageNumber, totalPages}) => (
                        `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                <View style={pdfStyle.page}>
                    <View style={pdfStyle.heading_container}>
                        <Image src={`${BACKEND_URL}${school.icon}`} style={pdfStyle.heading_logo} />
                        <Text style={pdfStyle.heading_text}>{`${school.name}, ${sessionState.session} ${sessionState.term_name} Term
                            ${classes.name} ${type} Attendance List ${from_date ? ` FROM ${from_date.toString()}` : ''}${to_date ? ` TO ${to_date.toString()}` : ''}`}</Text>
                    </View>
                </View>
                <View style={pdfStyle.cont_voskool_logo}>
                    <Image src={voskool_logo} style={pdfStyle.voskool_logo} />
                </View>
                <Table wrap bordered>
                    <TR bordered>
                        <TH bordered width="20%" align="center" textOnly> Student </TH>
                        <TH bordered width="80%" align="center" textOnly> Attendance   </TH>
                    </TR>
                    {spread_attendance_days(allAttendance, day_len).map((item, i) => {
                        const item_len = item.length
                        return(
                            <Fragment key={i}>
                                <TR>
                                    <TH bordered width="100%" align="center" fontSize="12px" padding={'6px'} textOnly>FROM {convertDate(item[0].attend_day, 'ddMMyyyy')} TO {convertDate(item[item_len-1].attend_day, 'ddMMyyyy')}</TH>
                                </TR>
                                {item[0].students.map((stud_item, j) => {
                                    const filter_stud = filterInnerArray(item, 'students', 'student', stud_item.student);
                                    return(
                                        <Fragment key={j}>
                                            <TR bordered>
                                                <TD bordered width={'20%'} fontSize="11px" style={{textTransform:'capitalize'}} textOnly>{stud_item.sur_name ? stud_item.sur_name : stud_item.user_sur_name} {stud_item.first_name ? stud_item.first_name : stud_item.user_first_name}</TD>
                                                <TD width={'80%'} padding={'0'}>
                                                    <TR bordered>
                                                    {filter_stud.map((inner_item, k) => {
                                                        const width_len = parseInt(100 / day_len) - 1;
                                                        return(
                                                            <Fragment key={k}>
                                                                <TD width={`${width_len}%`} bordered align="center" fontSize={'8.5px'} textOnly>{inner_item.day_name.toString().slice(0, 3)}, {convertDate(inner_item.attend_day, 'ddMM')} {'\n'} {attendanceIconPdf(inner_item.students.length === 1 ? inner_item.students[0].is_present : false, inner_item.students.length === 1 ? inner_item.students[0].is_absent : false, {width:'10px', height:'10px'})}</TD>
                                                            </Fragment>
                                                        )
                                                    })}
                                                    </TR>
                                                </TD>
                                            </TR>
                                        </Fragment>
                                    )
                                })}
                            </Fragment>
                        )
                    })}
                    
                </Table>
            </Page>
        )
    }

    return(
        <>
        {!preloading ? (
            <>
            {!err ? (
                <>
                {is_download ? (
                    <PDFDownloadLink document={<Document>{insertAttendanceData()}</Document>} fileName={`${school.title.toUpperCase()}_${sessionState.session}_${sessionState.term_name.toUpperCase()}_TERM_${classes.name.toUpperCase()}_${type.toUpperCase()}_ATTENDANCE_LIST${from_date ? `_FROM_${from_date.toString()}` : ''}${to_date ? `_TO_${to_date.toString()}` : ''}`} >
                        {({ loading }) => (loading ? 'Loading document...' : `Download ${sessionState.session} ${sessionState.term_name} Term ${classes.name} Attendance List ${from_date ? ` FROM ${from_date.toString()}` : ''}${to_date ? ` TO ${to_date.toString()}` : ''}!`)}
                    </PDFDownloadLink>
                ) : (
                    <PDFViewer showToolbar={false} style={{width:'100%', height:'70vh'}}> 
                        <Document>
                            {insertAttendanceData()}
                        </Document>
                    </PDFViewer>
                )}
                </>
            ) : (
                <div style={{display:'block', textAlign:'center', width:'100%'}}>
                    <span className="font-med disp-block" style={{fontWeight:600, color:'red'}}>PDF Proccessed With The Following Errors</span><br />
                    <span className="font-small" style={{fontWeight:600, color:'red'}}>{error.map((item) => item)}</span>
                    <button className="btn bg-blue hover" style={{color:'#eee'}} type="button" onClick={() => setReload(true)}>Reload</button>
                </div>
            )}
            </>
        ): (
            <div style={{display:'block', width:'100%'}}>
                <code className="font-small" style={{color:'#666', fontWeight:500, letterSpacing:'.6px'}}>fetching data.... </code>
            </div>
        )}
        </>
    )

}

export default SchoolTimeTablePdf;