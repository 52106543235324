import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { useNavigate } from 'react-router-dom'

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import Spinner from "components/app/school/SmallerComponents/spinner";
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { getPageSize } from 'components/app/school/actions/pageSize';
import { convertNumber} from 'components/app/school/actions/money';

const ManageStaff = ({user_type, school_id, school_title, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    // const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    // const [success, setSuccess] = useState(initialSuccessState);
    // const [warning, setWarning] = useState(initialSuccessState);

    const [allData, setAllData] = useState({results:[], count:0});
    const [userTable, setUserTable] = useState({order_by:"", sbid:0, q:"", size:20, p:1})

    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);

    const [navigate, setNavigate] = useState('');
    const [preloading, setPreloading] = useState(false);
    const [preChange, setPreChange] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [userTableChanged, setUserTableChanged] = useState(false);

    const nav = useNavigate();

    const handleUserTable = (e) => {
        setPreChange(false);
        setUserTableChanged(false);
        setUserTable({...userTable, [e.target.name]:e.target.value})
        setTimeout(() => {
            setUserTableChanged(true)
        }, 500)
    }
    const handleUserTablePagination = (e, value) => {
        setUserTableChanged(false);
        setPreChange(false);
        const name = "p";
        setUserTable({...userTable, [name]:value})
        setUserTableChanged(true)
    }
    const onClickNav = (n) => {
        setNavigate(n);
    }
    const fetchAllSchoolBranch = (sid=0) => {
        if(sid){
            setPreloading(true);
            setPreChange(false)
            appServices.getAllSchoolBranch(sid).then(res => {
                setAllSchoolBranchData([...res.data]);
                setUserTable({sbid:res.data[0].id, order_by:"", q:"", size:20, p:1,});
                setPreloading(false);
                setPreChange(true);
            }).catch(e => {
                setAllSchoolBranchData([]);
                setError({data:['Internal Server Error'], title:'School Branch Data Error'});
            })
        }
    }
    const fetchAllStaff = (loader=true, sbid, p=1, size=20, q, order_by) => {
        setPreloading(loader);
        setTableLoading(!loader);
        appServices.getAllStaffSchoolAccess(sbid, p, size, q, order_by).then(res => {
            setPreloading(false);
            setTableLoading(false);
            console.log(res);
            setAllData({...res.data});
        }).catch(e => {
            setPreloading(false);
            setTableLoading(false);
            setAllData({results:[], count:0});
            setError({data:['Internal Server Error'], title:'Application Data Error'});
        })
    }
    console.log(userTable)
    useEffect(() => {
        fetchAllSchoolBranch(school_id); 
    }, [school_id])

    useEffect(() => {
        if(preChange){
            fetchAllStaff(true, userTable.sbid)
        }
        if(userTableChanged){
            fetchAllStaff(false, userTable.sbid, userTable.p, userTable.size, userTable.q, userTable.order_by);
        }
    }, [userTable, userTableChanged, preChange])

    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSchoolBranchData = allSchoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const insertStaffData = () => {
        var i = 0;
        if(userTable.p > 1){
            const page = parseInt(userTable.p) - 1
            const size = parseInt(userTable.size) * page;
            i = size;
        }
        return(allData.results.map((item) => { 
            i++;
            const full_name = item.last_name + ' ' + item.first_name;
            const data = {
                id: item.id
            }
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} dlt_btn={true} dlt_text="Terminate" view_btn upgrade_btn handleViewClick={() => onClickNav(`/${school_title}/staff/user/${item.uid}`)} >
                    <div className="c-data sn"><span className="font-very-small">{i}</span></div>
                    <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.profile_pic}`} alt="Profile" /></div> <span className="font-very-small">{full_name.length > 25 ? `${full_name.slice(0, 25)}...` : full_name}</span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.staff_type_name}</span></div>
                    <div className="c-data"><span className="font-slightly-small">{item.curr_symbol}{convertNumber(item.staff.salary)}</span></div>
                    <div className="c-data"><span className="font-slightly-small"><b>{item.staff_type_name === "teacher" ? item.subject_assigned : '-'}</b></span></div>
                    <div className="c-data"><span className="font-slightly-small"><b>{item.staff_type_name === "teacher" ? item.class_assigned : '-'}</b></span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.rel}</span></div>
                    <div className="c-data"><span className="font-slightly-small">{item.rel}</span></div>
                    <TdWrapper center header_text={`${item.last_name} ${item.first_name}`} header_num={i}>
                        <Td profile_pic={`${BACKEND_URL}${item.profile_pic}`} />
                        <Td heading="Full Name" text_class='text-capitalize' text={`${item.last_name} ${item.first_name}`} />
                        <Td heading="Staff ID" text={`${item.school_uid}`} />
                        <Td heading="Email" text={`${item.email}`} />
                        <Td heading="Gender" text_class='text-capitalize' text={`${item.gender}`} />
                        <Td heading="Staff Type" text_class='text-capitalize' text={`${item.staff_type_name}`} />
                        <Td heading="Salary/mon" text_class='text-capitalize' text={`${item.curr_symbol}${convertNumber(item.staff.salary)}`} />
                        <Td heading="No. Subject Assigned" text_class='text-capitalize' text={`${item.staff_type_name === "teacher" ? item.subject_assigned : '-'}`} />
                        <Td heading="No. Class Assigned" text_class='text-capitalize' text={`${item.staff_type_name === "teacher" ? item.class_assigned : '-'}`} />
                        <Td heading="Religion" text_class='text-capitalize' text={`${item.rel}`} />
                        <Td heading="Session Joined" text_class='text-capitalize' text={`${item.session_added.session}`} />
                        <Td heading="Joined" text_class='text-capitalize' text={`${item.created}`} />
                        <Td link={`/${school_title}/staff/user/${item.uid}`} text_link='View More' /> 
                    </TdWrapper>
                    
                    </Tr>
                </Fragment>
            )
        }))
    }

    return(
        <>
            <HeadComponent title='Manage Staff' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)} */}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage Staff`} header_icon="fas fa-tasks">
                    {preloading ? (<ul>
                        <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <Section>
                            <Mallet >
                            <div className="flex-50"><label className='font-super-small'>School Location: 
                                    <select name="sbid" value={userTable.sbid} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small' >
                                        {insertSchoolBranchData}
                                    </select></label>
                                </div>
                                <div className="flex-50"><label className='font-super-small'>Order By: 
                                    <select name="order_by" value={userTable.order_by} onChange={e => handleUserTable(e)}  className='form-control-theme font-slightly-small' >
                                        <option value={''}>S/N</option>
                                        <option value={'-id'}>S/N-Descending</option>
                                    </select></label>
                                </div>
                                <div className="flex-70-line"><label className='font-super-small'>Search By: 
                                    <input name="q" value={userTable.q} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small' type="text" placeholder='Name, Type, ID, Email, Phone Number' /></label>
                                </div>
                                <div className="flex-30-line align-right"><label className='font-super-small'>No. Of Users: <br />
                                    <select  name="size" value={userTable.size} onChange={e => handleUserTable(e)} className='font-slightly-small form-control-theme' >
                                        <option value={1}>1</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                    </label>
                                </div>
                            </Mallet>
                            {tableLoading && <Spinner />}
                            <Table>
                                <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"S/N"}, {text:"Full Name"}, {text:'Type'}, {text:'Sal / mon'}, {text:'No. Sub Assigned'},  {text:'No. Class Assigned'},  {text:'Rel'},]} />
                                {allData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    (<>
                                        {insertStaffData()}
                                    </>)                         
                                }
                            </Table>
                            <div  style={{marginTop:'40px', display:'flex', justifyContent:'center'}}>
                                <Stack spacing={2}>
                                    <Pagination count={getPageSize(allData.count, userTable.size)} page={userTable.p} onChange={handleUserTablePagination} size="small" color="primary" variant="outlined" shape="rounded" />
                                </Stack>
                            </div>
                        </Section>
                    </>
                    )}
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default ManageStaff;