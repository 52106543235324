import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
    background: #000;
    width: 100%;
    position: relative;
`;

export const Content = styled(motion.div)`
    position: absolute;
    z-index: 10000 !important;
    width: 100%;
    border-radius: 3px;
    max-height: 200px;
    overflow: auto;
    border-radius: 6px;

    .container{
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }
`;
