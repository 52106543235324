import React, { useState, useEffect, useCallback, useMemo, Fragment} from 'react';
import { Link } from 'react-router-dom';

import Notification from "components/app/school/SmallerComponents/notification";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";

import BlankLoader from "components/blankLoader";
import Loader from 'components/app/school/SmallerComponents/loader';
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { appServices } from "api/schoolApp/services";

const StaffAssignSubject = ({school_id, school_title, user_type, user_id, data}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:"", data:[]}), []);
    const initialWarningState = useMemo(() => ({title:"", text:""}), []);
    const initialState = useMemo(() => ({id:0, email:'', school_uid:'', staff:{staff:{id:0, gender:''}, staff_cert_type:{id:0}, staff_type:{id:0, name:''}, school:{id:0}, school_branch:{name:'', id:0},}, session_added:{id:0, term:{id:0}}}), []);

    const disableBtnStyle = {
        cursor: 'not-allowed', opacity:0.4
    }
    const enableBtnStyle = {
        cursor: 'pointer', opacity:1
    }
    const [state, setState] = useState(initialState);
    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialWarningState);

    const [dataTable, setDataTable] = useState({class:'', class_section:''});
    const [subDataTable, setSubDataTable] = useState({class_section:''});
    const [classSectionData, setClassSectionData] = useState({id:'', name:''});
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allUnassignedClassesSubjectData, setAllUnassignedClassesSubjectData] = useState([]);
    const [allUnassignedStaffSubjectData, setAllUnassignedStaffSubjectData] = useState([]);
    const [allAssignedStaffSubjectData, setAllAssignedStaffSubjectData] = useState([]);
    const [allAssignedClassesSubjectData, setAllAssignedClassesSubjectData] = useState([]);
    const [allSubjectAssigned, setAllSubjectAssigned] = useState([]);

    const [unassignedItemsChecked, setUnassignedItemsChecked] = useState(0);
    const [clickedUnassignedSubject, setClickedUnassignedSubject] = useState(-1);
    const [clickedUnassignedSubjectData, setClickedUnassignedSubjectData] = useState({id:'', sub_name:''});
    const [assignedItemsChecked, setAssignedItemsChecked] = useState(0);
    const [clickedAssignedSubject, setClickedAssignedSubject] = useState(-1);
    const [clickedAssignedSubjectData, setClickedAssignedSubjectData] = useState({id:'', sub_name:''});

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [btnAddClick, setBtnAddClick] = useState(false);
    const [dataTableLoader, setDataTableLoader] = useState(false);
    const [dataTableChanged, setDataTableChanged] = useState(false);
    const [isSetup] = useState(true);

    const [unassignedSpinLoading, setUnassignedSpinLoading] = useState(false);
    const [unassignedCheckAll, setUnassignedCheckAll] = useState(false);
    const [menuDispUnassigned, setMenuDispUnassigned] = useState(false);
    const [btnUnAssign, setBtnUnAssign] = useState(false);

    const [assignedSpinLoading, setAssignedSpinLoading] = useState(false);
    const [assignedCheckAll, setAssignedCheckAll] = useState(false);
    const [menuDispAssigned, setMenuDispAssigned] = useState(false);
    const [btnAssign, setBtnAssign] = useState(false);

    const handleSubChange = (e) => {
        setSubDataTable({...subDataTable, [e.target.name]:e.target.value});
        if(e.target.name === "class_section" && e.target.value) {fetchAllUnassignedStaffSubject(e.target.value); fetchAllAssignedStaffSubject(e.target.value); fetchClassSection(e.target.value); setMenuDispUnassigned(false); setClickedUnassignedSubject(-1);  setMenuDispAssigned(false); setClickedAssignedSubject(-1);}
        if(e.target.name === "class_section" && !e.target.value) {setAllUnassignedClassesSubjectData([]); setAllAssignedClassesSubjectData([]); setAllAssignedStaffSubjectData([]); setClassSectionData({id:'', name:''}); setAllUnassignedStaffSubjectData([]); setMenuDispUnassigned(false); setClickedUnassignedSubject(-1); setMenuDispAssigned(false); setClickedAssignedSubject(-1);}
    }
    const handleDataTableChange = (e) => {
        setDataTable({...dataTable, [e.target.name]:e.target.value});
        setDataTableChanged(false);
        if(e.target.name === "class_section" && e.target.value){ setDataTable({...dataTable, [e.target.name]:e.target.value, class:''}); fetchAllClasses(school_id, e.target.value)}
        if(e.target.name === "class_section" && !e.target.value) {setAllClassesData([])}
        setDataTableChanged(true);
    }
    const fetchClassSection = (id) => {
        appServices.getClassSectionSchool(id).then(res => {
            setClassSectionData({...res.data});
        }).catch(e => {
            setClassSectionData({id:'', name:''});
        })
    }
    const fetchAllClassSection = (sid) => {
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSectionData([...res.data]);
        }).catch(e => {
            setAllClassSectionData([]);
            setError({data:["Internal Server Error"], title:"Class Section Data Error"});
        })
    }
    // const fetchAllUnassignedClassesSubject = (subid) => {
    //     setAllUnassignedClassesSubjectData([]);
    //     appServices.getAllStaffSchoolClassesSubject(subid).then(res => {
    //         setAllUnassignedClassesSubjectData(res.data);
    //         console.log(res);
    //     }).catch(e => {
    //         setAllUnassignedClassesSubjectData([]);
    //         setError({data:["Internal Server Error"], title:"Class Data Error"});
    //     })
        
    // }
    const fetchAllUnassignedStaffSubject = (sbid) => {
        setUnassignedSpinLoading(true);
        appServices.getAllStaffSchoolSubject(sbid, "unassigned").then(res => {
            setAllUnassignedStaffSubjectData([...res.data]);
            setUnassignedSpinLoading(false);
            console.log(res);
        }).catch(e => {
            setAllUnassignedStaffSubjectData([]);
            setUnassignedSpinLoading(false);
            setError({data:["Internal Server Error"], title:"Subject Data Error"});
        })
    }
    const fetchAllAssignedStaffSubject = (sbid) => {
        setAssignedSpinLoading(true);
        appServices.getAllStaffSchoolSubject(sbid, "assigned").then(res => {
            setAllAssignedStaffSubjectData([...res.data]);
            setAssignedSpinLoading(false);
        }).catch(e => {
            setAllAssignedStaffSubjectData([]);
            setAssignedSpinLoading(false);
            setError({data:["Internal Server Error"], title:"Subject Data Error"});
        })
    }

    const fetchAllAssignedSubject = useCallback((loader=false, csid, cid) => {
        if(state.id){
            setPreloading(loader);
            setDataTableLoader(!loader);
            setError({title:'', data:[]});
            appServices.getAllStaffSchoolSubjectAssigned(state.id, csid, cid).then(res => {
                setAllSubjectAssigned(res.data);
                setPreloading(false);
                setDataTableLoader(false)
            }).catch(e => {
                setError({title:'Subject Fetch Error ', data:['Something Went Wrong']});
                setPreloading(false);
                setDataTableLoader(false);
                setAllSubjectAssigned([]);
            })
        } else {
            setAllSubjectAssigned([]);
        }
        
    }, [state.id])
    const fetchAllClasses = (sid, csid) => {
        appServices.getClassSectionSchool(csid).then(result => {
            appServices.getAllClassSchool(sid, result.data.class_section).then(res => {
                setAllClassesData([...res.data]);
                console.log(res);
            }).catch(e => {
                setAllClassesData([]);
                setError({title:'Class Data Error', data:['Internal Server Error']});
            })
        }).catch(e => {
            setAllClassesData([]);
            setError({title:'Class Data Error', data:['Internal Server Error']});
        })
    }

    const handleUnassignedSubjectClick = (id, i=0, sub_name="") => {
        console.log(id)
        setLoading(true);
        setMenuDispUnassigned(false);
        setUnassignedSpinLoading(true);
        setAllUnassignedClassesSubjectData([]);
        setUnassignedCheckAll(false);
        setUnassignedItemsChecked(0);
        setClickedUnassignedSubject(-1);
        appServices.getAllStaffSchoolClassesSubject(id, 'unassigned').then(res => {
            setAllUnassignedClassesSubjectData(checkBoxArray(res.data));
            setClickedUnassignedSubject(i);
            setClickedUnassignedSubjectData({id:id, sub_name:sub_name});
            setMenuDispUnassigned(true);
            console.log(res.data)
            setLoading(false);
            setUnassignedSpinLoading(false);
        }).catch(e => {
            setAllUnassignedClassesSubjectData([]);
            setClickedUnassignedSubjectData({id:'', sub_name:''});
            setMenuDispUnassigned(false);
            setUnassignedSpinLoading(false);
            setLoading(false);
            setError({data:["Internal Server Error"], title:"Class Data Error"});
        })
    }
    const handleAssignedSubjectClick = (id, i=0, sub_name="") => {
        console.log(id)
        setLoading(true);
        setMenuDispAssigned(false);
        setAssignedSpinLoading(true);
        setAllAssignedClassesSubjectData([]);
        setAssignedCheckAll(false);
        setAssignedItemsChecked(0);
        setClickedAssignedSubject(-1);
        appServices.getAllStaffSchoolClassesSubject(id, 'assigned').then(res => {
            setAllAssignedClassesSubjectData(checkBoxArray(res.data));
            setClickedAssignedSubject(i);
            setClickedAssignedSubjectData({id:id, sub_name:sub_name});
            setMenuDispAssigned(true);
            console.log(res.data)
            setLoading(false);
            setAssignedSpinLoading(false);
        }).catch(e => {
            setAllAssignedClassesSubjectData([]);
            setClickedAssignedSubjectData({id:'', sub_name:''});
            setMenuDispAssigned(false);
            setAssignedSpinLoading(false);
            setLoading(false);
            setError({data:["Internal Server Error"], title:"Class Data Error"});
        })
    }
    console.log(clickedUnassignedSubject);
    const handleUnassignedCloseClick = () => {
        setMenuDispUnassigned(false);
        setClickedUnassignedSubject(-1);
        fetchAllUnassignedStaffSubject(subDataTable.class_section);
    }
    const handleAssignedCloseClick = () => {
        setMenuDispAssigned(false);
        setClickedAssignedSubject(-1);
        fetchAllAssignedStaffSubject(subDataTable.class_section);
    }
    const checkBoxArray = (arr=[], checked=false) => {
        let data = [];
        for(let i = 0; i < arr.length; i++){
            data.push({...arr[i], num:i, checked:checked})
        }
        return data;
    }
    const sortArray = (arr=[], type) => {
        return arr.sort((a,b) => a.num - b.num);
    }
    const totCheckedItems = useCallback((arr=[], num=0) => {
        const filterItem = arr.filter((item) => {
            return item.checked === true;
        })
        if(num > 0) return filterItem.length + 1
        return filterItem.length
    }, [])

    const handleUnassignedCheckAll = (checked=false) => {
        const filterItem = allUnassignedClassesSubjectData.filter((it) => {
            return it.checked === true;
        })  
        if(checked){
            if(filterItem.length === allUnassignedClassesSubjectData.length){setUnassignedCheckAll(true);}
            setAllUnassignedClassesSubjectData(checkBoxArray(allUnassignedClassesSubjectData, true));
            setUnassignedCheckAll(true);
        }
        else{
            setAllUnassignedClassesSubjectData(checkBoxArray(allUnassignedClassesSubjectData, false));
            setUnassignedCheckAll(false);
        }
        
    }
    const handleAssignedCheckAll = (checked=false) => {
        const filterItem = allAssignedClassesSubjectData.filter((it) => {
            return it.checked === true;
        })  
        if(checked){
            if(filterItem.length === allAssignedClassesSubjectData.length){setAssignedCheckAll(true);}
            setAllAssignedClassesSubjectData(checkBoxArray(allAssignedClassesSubjectData, true));
            setAssignedCheckAll(true);
        }
        else{
            setAllAssignedClassesSubjectData(checkBoxArray(allAssignedClassesSubjectData, false));
            setAssignedCheckAll(false);
        }
        
    }

    const handleCheckBox = (checked=false, item) => {
        const check = allUnassignedClassesSubjectData.find((it) => {
            return it.num === item.num
        })
        if(check){
            const filterItem = allUnassignedClassesSubjectData.filter((it) => {
                return it.num !== item.num;
            })
            setAllUnassignedClassesSubjectData(sortArray([...filterItem, {...item, checked:checked}]))
        }
        else{
            setAllUnassignedClassesSubjectData(sortArray([...allUnassignedClassesSubjectData, {...item, checked:checked}]));
        }
    }
    const handleAssignedCheckBox = (checked=false, item) => {
        const check = allAssignedClassesSubjectData.find((it) => {
            return it.num === item.num
        })
        if(check){
            const filterItem = allAssignedClassesSubjectData.filter((it) => {
                return it.num !== item.num;
            })
            setAllAssignedClassesSubjectData(sortArray([...filterItem, {...item, checked:checked}]))
        }
        else{
            setAllAssignedClassesSubjectData(sortArray([...allAssignedClassesSubjectData, {...item, checked:checked}]));
        }
    }

    const onClickAdd = (type=false) => {
        setBtnAddClick(type);
        if(!type) fetchAllClassSection(school_id);
    }

    const onSubmitUnassignedSubject = (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(initialSuccessState);
        setError(initialErrorState);
        var promise = Promise.resolve();
        var datum = [];
        const filterItem = allUnassignedClassesSubjectData.filter((item) => {
            return item.checked === true
        });
        if(filterItem.length){
            filterItem.forEach((item, i) => {
                const n = i + 1;
                promise = promise.then(()=> {
                    console.log(item);
                    setLoading(true);
                    const data = {
                        class_handler:item.id,
                        staff: state.id,
                        assigned_by: user_id,
                    }
                    appServices.insertStaffSchoolSubject(data).then(res => {
                        datum.push(`${item.class_name} ${item.subject_name} Added Successfully`)
                        setSuccess({title:`Data ${n} Added Successfully`, data:[...datum] });
                    }).catch(e => {
                        setError({title:"Error", data:[e.response.detail]});
                        console.log(e.response);
                    })
                    return new Promise((resolve) => {
                        setTimeout(resolve, 250)
                    })
                })
            })

            promise.then(() => {
                setLoading(false);
                handleUnassignedSubjectClick(clickedUnassignedSubjectData.id, clickedUnassignedSubject, clickedUnassignedSubjectData.sub_name);
                fetchAllAssignedSubject(false, dataTable.class_section, dataTable.class)
                fetchAllAssignedStaffSubject(subDataTable.class_section);
            })
        } else {
            setError({title:"Check Error", data:["No Item Checked"]});
        }

    }

    const onSubmitAssignedSubject = (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(initialSuccessState);
        setError(initialErrorState);
        var promise = Promise.resolve();
        var datum = [];
        const filterItem = allAssignedClassesSubjectData.filter((item) => {
            return item.checked === true
        });
        if(filterItem.length){
            filterItem.forEach((item, i) => {
                const n = i + 1;
                promise = promise.then(()=> {
                    console.log(item);
                    setLoading(true);
                    const data = {
                        class_handler:item.id,
                        staff: state.id,
                        assigned_by: user_id,
                    }
                    appServices.insertStaffSchoolSubject(data).then(res => {
                        datum.push(`${item.class_name} ${item.subject_name} Added Successfully`)
                        setSuccess({title:`Data ${n} Added Successfully`, data:[...datum] });
                    }).catch(e => {
                        setError({title:"Error", data:[e.response.data.detail]});
                        console.log(e.response);
                    })
                    return new Promise((resolve) => {
                        setTimeout(resolve, 250)
                    })
                })
            })

            promise.then(() => {
                setLoading(false);
                handleAssignedSubjectClick(clickedAssignedSubjectData.id, clickedAssignedSubject, clickedAssignedSubjectData.sub_name);
                fetchAllAssignedSubject(false, dataTable.class_section, dataTable.class)
            })
        } else {
            setError({title:"Check Error", data:["No Item Checked"]});
        }

    }
    const onDeleteSubject = (i, id, class_hand_id) => {   
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setWarning(initialWarningState);
        setAnim({initial:{opacity: 1, duration:0.4, background:'rgba(0,0,0,0)'}, animate:{}, id:i});
        appServices.deleteStaffSchoolSubjectAssigned(id).then(res => {
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
            id:i })
            setLoading(false);
            fetchAllAssignedSubject(false, dataTable.class_section, dataTable.class)
            setTimeout(() => {
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:`rgba(0,0,0,0)`},
                    id:i })
                setSuccess({...initialSuccessState, text:"Data Deleted Successfully", title:"Success", click_text:"Undo Delete", handleClick:() => handleUndoDeleteSubject(i, id, class_hand_id)});
            }, 500);
        }).catch(e => {
            console.log(e.response);
            setLoading(false);
            setError({data:[e.response.data.detail], title:"Delete Subject Error"});
        })
    }
    const handleUndoDeleteSubject = (i, id, class_hand) => {
        setLoading(false);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            class_handler:class_hand,
            staff: state.id,
            assigned_by: user_id,
        }
        appServices.insertStaffSchoolSubject(data).then(res => {
            setSuccess({...initialSuccessState, text:"Undo Delete Successfull", title:"Success"}) 
            setLoading(false);
            fetchAllAssignedSubject(false, dataTable.class_section, dataTable.class)
            setTimeout(() => {
                setAnim({initial:{opacity: 0, duration:0.4},
                animate:{opacity:1, duration:0.4, background:"rgba(0,255,50,1)"},
                id:i })
            }, 300);
            setTimeout(() => {
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:"rgba(0,0,0,0)"},
                    id:i })
            }, 600);
        })
        .catch(e => {
            console.log(e.response);
            setLoading(false);
            setError({data:[e.response.data.detail], title:"Undo Delete Class Block Error"})
        })
    }
    const deleteWarning = (i=0, class_hand, id=0, title="", text="") => {
        setWarning({id:0, title:'', text:'', click_text:''})
        if(id){
            setTimeout(() => {
                setWarning({id:i, title:title, text:text, handleClick: () => onDeleteSubject(i, id, class_hand), click_text:'DELETE'});
            }, 100);
        } else {
            setError({title:'Delete Error', data:['Something Went Wrong']})
        }
    }

    useEffect(() => {
        setState(data);
    }, [data]);

    useEffect(() => {
        fetchAllClassSection(school_id);
        fetchAllAssignedSubject(true)
    }, [school_id, fetchAllAssignedSubject]);

    useEffect(() => {
        if(dataTableChanged){fetchAllAssignedSubject(false, dataTable.class_section, dataTable.class);}
        if(state.staff.staff_type.name !== "teacher" && state.staff.staff_type.name){setError({title:'Error', data:['This Staff Is Not A Teacher']})}
    }, [dataTable, dataTableChanged, state.staff.staff_type.name, fetchAllAssignedSubject])

    useEffect(() => {
        // if(subDataTable.class_section){
            
        // }
        if(allUnassignedClassesSubjectData.length > 0){
            setUnassignedItemsChecked(totCheckedItems(allUnassignedClassesSubjectData));
        }
        if(allUnassignedClassesSubjectData.length === totCheckedItems(allUnassignedClassesSubjectData)){
            setUnassignedCheckAll(true);
        }
        if(allUnassignedClassesSubjectData.length !== totCheckedItems(allUnassignedClassesSubjectData)){
            setUnassignedCheckAll(false);
        }
        if(totCheckedItems(allUnassignedClassesSubjectData) > 0){
            setBtnUnAssign(true)
        }
        if(!totCheckedItems(allUnassignedClassesSubjectData)){
            setBtnUnAssign(false)
        }
        
        if(allAssignedClassesSubjectData.length > 0){
            setAssignedItemsChecked(totCheckedItems(allAssignedClassesSubjectData));
        }
        if(allAssignedClassesSubjectData.length === totCheckedItems(allAssignedClassesSubjectData)){
            setAssignedCheckAll(true);
        }
        if(allAssignedClassesSubjectData.length !== totCheckedItems(allAssignedClassesSubjectData)){
            setAssignedCheckAll(false);
        }
        if(totCheckedItems(allAssignedClassesSubjectData) > 0){
            setBtnAssign(true)
        }
        if(!totCheckedItems(allAssignedClassesSubjectData)){
            setBtnAssign(false)
        }
        
    }, [allUnassignedClassesSubjectData, allAssignedClassesSubjectData, totCheckedItems])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const successHandler = success.data.map((item, index) => {
        return(<Notification type="success" text={item} key={index} title={error.success} />)
    })
    const insertClassSectionOption = allClassSectionData.map((item, i) => {
        return(
            <option className='text-capitalize' value={item.id} key={i}>{`${item.name} Section`}</option>
        )
    })
    const insertSubjectAssigned = allSubjectAssigned.map((item, i) => {
        i++;
        return(
            <Tr animation={(i === anim.id ) && {initial:{...anim.initial}, animate:{...anim.animate}}} 
            edit_btn={false} key={i} dlt_btn handleDeleteClick={() => deleteWarning(i, item.class_handler, item.id, 'Are you sure?', `${item.class_name} - ${item.subject_name} will be deleted`)}>
                <div className="c-data sn">
                    <span className="font-slightly-small">{i}</span>
                </div>  
                <div className="c-data" style={{width:'100%'}}><span className="font-slightly-small text-capitalize">{item.subject_name} - {item.class_name}</span></div>    
            </Tr>
        )
    })
    const insertClassOption = allClassesData.map((item) => {
        return(
            <option className='text-capitalize' value={item.id} key={item.id}>{`${item.name}`}</option>
        )
    })
    const tableOption = () => {
        return(
            allUnassignedStaffSubjectData.map((item, i) => {
                return(
                    <Fragment key={i}>
                        <TR>
                            <TD classname="text-capitalize">{item.name}</TD>
                            <TD><button type="button" className={`btn font-slightly-small fw-500 app-text-lighter-grey hover-opacity ${i === clickedUnassignedSubject ? 'app-bg-color' : 'bg-black'}`} onClick={() => handleUnassignedSubjectClick(item.id, i, item.name)}>+</button></TD>
                            {/* {(() => { return allClassesData.map((inner_item, ii) => {
                                return(
                                    <Fragment key={ii}>
                                        <TD><input type={"checkbox"} /></TD>
                                    </Fragment>
                                )
                            })})()} */}
                        </TR>
                    </Fragment>
                )
            })
        )
    }
    const tableAssignedOption = () => {
        return(
            allAssignedStaffSubjectData.map((item, i) => {
                return(
                    <Fragment key={i}>
                        <TR>
                            <TD classname="text-capitalize">{item.name}</TD>
                            <TD><button type="button" className={`btn font-slightly-small fw-500 app-text-lighter-grey hover-opacity ${i === clickedAssignedSubject ? 'app-bg-color' : 'bg-black'}`} onClick={() => handleAssignedSubjectClick(item.id, i, item.name)}>+</button></TD>
                        </TR>
                    </Fragment>
                )
            })
        )
    }
                  

    return(
        <Fragment>
            <NotificationWrapper>
                {error.title && (errorHandler)}
             {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.data && (successHandler)}
                {success.text && (<Notification type="success" timer={1000 * 15} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            {state.staff.staff_type.name === "teacher" && (
            <Section>
                {loading && <Loader />}
                <span className="font-med fw-700 disp-block app-text-color">Manage Subject - Staff</span>
                <div className='flex-100 align-right'><Link to={`/${school_title}/staff/subject_assign`} className='btn br bg-blue font-small app-bg-color app-text-lighter-grey hover-opacity'><i className='fas fa-plus font-slightly-small'></i> </Link></div>
                {!isSetup && (
                    <>
                        <div className='flex-100 align-right'><button type="button" className='btn br bg-blue font-small app-bg-color app-text-lighter-grey hover-opacity' onClick={() => onClickAdd(!btnAddClick)}>{!btnAddClick ? (<><i className='fas fa-plus font-slightly-small'></i> Add</>) : (<><i className='fas fa-times font-slightly-small'></i> Close</>) } </button></div>
                {btnAddClick && (
                
                <Mallet>
                    <div className="flex-100"><label className='font-super-small'>Select Class Section: 
                        <select name="class_section" value={subDataTable.class_section} onChange={e => handleSubChange(e)}  className='form-control-theme font-slightly-small text-capitalize' >
                            <option value={""}>Select Class Section</option>
                            {insertClassSectionOption}
                        </select></label>
                    </div>
                    {classSectionData.id && <div className='align-center flex-100'><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color text-capitalize">{classSectionData.name} Section</span></div>}
                    <div className='align-center flex-100'><span className="font-slightly-small fw-700 disp-block padd-4px app-text-color">Subject(s) Without Teachers</span></div>
                    <div className={menuDispUnassigned ? 'flex-50-line' : 'flex'} style={{width:'0'}}>
                        {unassignedSpinLoading && <Spinner /> }
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH style={{width:'100%'}}>Subject</TH>
                                    <TH>Action</TH>
                                </TR>
                            </thead>
                            <tbody>
                                {allUnassignedStaffSubjectData.length > 0 ? tableOption() : 
                                (<TR><TD align='center' colspan={2} classname="font-slightly-small"><span className='red'>No Record Found...</span> <span style={{cursor:'default'}} className='app-bg-color-text font-very-small'>Reload<i className='fas fa-reload'></i></span></TD></TR>)}
                            </tbody>
                        </TABLE>
                    </div>
                    {menuDispUnassigned && (
                        <div className='flex-50-line app-body-color' style={{width:'0'}}>
                            <div className='disp-flex jc-sb'>
                                <div></div>
                                <div><button type="button" className="font-very-small btn fw-700 disp-block app-bg-color-text" onClick={() => handleUnassignedCloseClick()}>Close</button></div>
                            </div>
                            <div className='align-center flex-100 app-opp-theme'><span className="font-slightly-small fw-700 disp-block padd-4px app-opp-text-color text-capitalize">{clickedUnassignedSubjectData.sub_name}</span></div>
                            <TABLE>
                                <thead>
                                    <TR>
                                        <TH style={{width: '100%'}}>Class</TH>
                                        <TH>Box</TH>
                                    </TR>
                                </thead>
                                <tbody>
                                    {allUnassignedClassesSubjectData.map((item, i) => {
                                        return(
                                            <Fragment key={i}>
                                                <TR>
                                                    <TD classname="text-capitalize">{item.class_name}</TD>
                                                    <TD><input type={"checkbox"} checked={item.checked} value={item.num} onChange={(e) => handleCheckBox(!item.checked, item)} /></TD>
                                                </TR>
                                            </Fragment>
                                        ) ; 
                                    })}
                                    {allUnassignedClassesSubjectData.length > 0 && (<><TR><TH align='left'>{!unassignedCheckAll ?  'Check' : 'Uncheck' } All</TH> <TH><input type={"checkbox"} value={unassignedCheckAll} checked={unassignedCheckAll} onChange={() => handleUnassignedCheckAll(!unassignedCheckAll)} /></TH></TR><TR><TH align='left'>Total</TH><TH>{unassignedItemsChecked}</TH></TR></>)}
                                    <TR>
                                        <TD colspan={2} align='center'><button style={btnUnAssign ? enableBtnStyle : disableBtnStyle} disabled={btnUnAssign ? false : true} type="button" className='btn app-bg-color font-slightly-small fw-500 app-text-lighter-grey hover-opacity' onClick={(e) => onSubmitUnassignedSubject(e)}>Assign</button></TD>
                                    </TR>
                                </tbody>
                            </TABLE>
                        </div>
                    )}
                    <div className='align-center flex-100'><span className="font-slightly-small fw-700 disp-block padd-4px app-text-color">Subject(s) With Teachers</span></div>
                    <div className={menuDispAssigned ? 'flex-50-line' : 'flex'} style={{width:'0'}}>
                        {assignedSpinLoading && <Spinner /> }
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH style={{width:'100%'}}>Subject</TH>
                                    <TH>Action</TH>
                                </TR>
                            </thead>
                            <tbody>
                                {allAssignedStaffSubjectData.length > 0 ? tableAssignedOption() : 
                                (<TR><TD align='center' colspan={2} classname="font-slightly-small"><span className='red'>No Record Found...</span> <span style={{cursor:'default'}} className='app-bg-color-text font-very-small'>Reload<i className='fas fa-reload'></i></span></TD></TR>)}
                            </tbody>
                        </TABLE>
                    </div>
                    {menuDispAssigned && (
                        <div className='flex-50-line app-body-color' style={{width:'0'}}>
                            <div className='disp-flex jc-sb'>
                                <div></div>
                                <div><button type="button" className="font-very-small btn fw-700 disp-block app-bg-color-text" onClick={() => handleAssignedCloseClick()}>Close</button></div>
                            </div>
                            <div className='align-center flex-100 app-opp-theme'><span className="font-slightly-small fw-700 disp-block padd-4px app-opp-text-color text-capitalize">{clickedAssignedSubjectData.sub_name}</span></div>
                            <TABLE>
                                <thead>
                                    <TR>
                                        <TH style={{width: '100%'}}>Class</TH>
                                        <TH>Box</TH>
                                    </TR>
                                </thead>
                                <tbody>
                                    {allAssignedClassesSubjectData.map((item, i) => {
                                        return(
                                            <Fragment key={i}>
                                                <TR>
                                                    <TD classname="text-capitalize">{item.class_name}</TD>
                                                    <TD><input type={"checkbox"} checked={item.checked} value={item.num} onChange={(e) => handleAssignedCheckBox(!item.checked, item)} /></TD>
                                                </TR>
                                            </Fragment>
                                        ) ; 
                                    })}
                                    {allAssignedClassesSubjectData.length > 0 && (<><TR><TH align='left'>{!assignedCheckAll ?  'Check' : 'Uncheck' } All</TH> <TH><input type={"checkbox"} value={assignedCheckAll} checked={assignedCheckAll} onChange={() => handleAssignedCheckAll(!assignedCheckAll)} /></TH></TR><TR><TH align='left'>Total</TH><TH>{assignedItemsChecked}</TH></TR></>)}
                                    <TR>
                                        <TD colspan={2} align='center'><button style={btnAssign ? enableBtnStyle : disableBtnStyle} disabled={btnAssign ? false : true} type="button" className='btn app-bg-color font-slightly-small fw-500 app-text-lighter-grey hover-opacity' onClick={(e) => onSubmitAssignedSubject(e)}>Assign</button></TD>
                                    </TR>
                                </tbody>
                            </TABLE>
                        </div>
                    )}
                    
                </Mallet>
                )}
                <br />
                <Section>
                    <span className="font-small fw-700 disp-block padd-4px app-text-color">Assigned Subject(s) - Staff</span>
                    <Mallet>
                        <div className="flex-50-line"><label className='font-super-small'>Select Class Section: 
                            <select name="class_section" value={dataTable.class_section} onChange={e => handleDataTableChange(e)}  className='form-control-theme font-slightly-small text-capitalize' >
                                <option value={""}>All</option>
                                {insertClassSectionOption}
                            </select></label>
                        </div>
                        <div className="flex-50-line"><label className='font-super-small'>Select Class: 
                            <select name="class" value={dataTable.class} onChange={e => handleDataTableChange(e)}  className='form-control-theme font-slightly-small text-capitalize' >
                                <option value={""}>All</option>
                                {insertClassOption}
                            </select></label>
                        </div>
                    </Mallet> 
                    {dataTableLoader && <Spinner /> }
                    {preloading ? (<ul style={{width:'100%'}}>
                        <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                            <>
                                <Table>
                                    <Tr btn={false} sn={true} header={true} text={[{text:"S/N"},  {text:"Subject", style:{width:'100%'}}]} />
                                    {allSubjectAssigned.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                        insertSubjectAssigned                    
                                    }
                                </Table>
                            </>
                        )}
                </Section>
                    </>
                )}
                
            </Section>
            )}
        </Fragment>
    )

}

export default StaffAssignSubject;