import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { AnimatePresence } from 'framer-motion';
import { useNavigate, Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from "components/app/school/SmallerComponents/table_html/table";
import TR from "components/app/school/SmallerComponents/table_html/tr";
import TD from "components/app/school/SmallerComponents/table_html/td";
import TH from "components/app/school/SmallerComponents/table_html/th";
import Input, { ListOption } from 'components/app/school/SmallerComponents/input';

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { addItemToArray} from "components/app/school/actions/array-utils/Add";
import { updateArray, updateArrayWithArray } from 'components/app/school/actions/array-utils/Update';


const StaffSubjectAssign = ({school_id, user_id, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [dataTable, setDataTable] = useState({csid:'', pre:false});
    const [menuDropData, setMenuDropData] = useState({id:'', type:'', num:0, staff_id:0, staff_name:'', sort_type:'', staff_assign:[]});

    const [allSubject, setAllSubject] = useState([]);
    const [allStaffData, setAllStaffData] = useState(initialPaginationState);
    const [allStaffData2, setAllStaffData2] = useState(initialPaginationState);
    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [closeListBoxDataLoading, setCloseListBoxDataLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [reload, setReload] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);
    const [closeListBox, setCloseListBox] = useState(true);

    const fetchAllClassSection = (sid) => {
        if(sid){
            setPreloading(true);
            appServices.getAllClassSectionSchool(sid).then(res => {
                setAllClassSectionData(res.data);
                if(res.data.length){
                    setDataTable({csid:res.data[0].class_section, pre:true});
                    setDataTableLoading(true);
                }
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Class Section Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
                setAllClassSectionData([]);
                setPreloading(false);
            })
        } 
    }
    const fetchAllClasses = (sid) => {
        if(sid){
            appServices.getAllClassSchoolItem(sid).then(res => {
                setAllClassesData(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Classes Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
                setAllClassesData([]);
                setPreloading(false);
            })
        }
    }

    const fetchAllStaff = (sid, sbid, q) => {
        setError({data:[], title:''});
        appServices.getAllStaffSchoolAccess(sbid, 1, 250, q, '', sid).then(res => {
            setAllStaffData({...res.data, results:addItemToArray(res.data.results)});
            if(!q){
                setAllStaffData2({...res.data, results:addItemToArray(res.data.results)});
            }
        }).catch(e => {
            setAllStaffData({count:0, previous:'', next:'', results:[]});
            setError({data:["Internal Server Error"], title:"Staff Data Fetch Error"});
        })
    }

    const fetchAllSubject = (pre=true, sid, csid) => {
        if(sid){
            setPreloading(pre);
            setTableLoading(!pre);
            setError({data:[], title:''});
            appServices.getAllSubjectSchool(sid, csid).then(res => {
                setPreloading(false);
                setTableLoading(false);
                setAllSubject(addItemToArray(res.data));
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Subject Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
                setPreloading(false);
                setTableLoading(false);
                setAllSubject([]);
            })
        }
    }
    
    const handleTable = (e) => {
        setDataTable({...dataTable, [e.target.name]:e.target.value, pre:false});
        setDataTableLoading(true);
    }
    const handleMenuChange = (e) => {
        const val_split = e.target.value.split(' ');
        const val = val_split.length > 1 ? val_split[0] : e.target.value
        console.log(val_split);
        setCloseListBox(false);
        setMenuDropData({...menuDropData, [e.target.name]:val, sort_type:'staff', staff_id:0});
        setCloseListBoxDataLoading(true);
    }
    const handleClickedList = (name="", id, item={}) => {
        if(name && id){
            setMenuDropData({...menuDropData, sort_type:'', staff_name:name, staff_id:id});
            setCloseListBoxDataLoading(false);
            setCloseListBox(true);
        }
    }
    const onClickIcon = (id=0, type="", item={}) => {
        if(id && type==="add"){
            setMenuDrop(true);
            setMenuDropData({id:id, type:'add_staff', ...item});
            return;
        }
        if(id && type==="edit"){
            setMenuDrop(true);
            setMenuDropData({id:id, type:'edit_staff', ...item, staff_assign:addItemToArray(item.staff_assign, {is_disabled:true, is_changed:false}, 'original_staff', 'staff')});
            return;
        }
        if(id && type==="delete"){
            setMenuDrop(true);
            setMenuDropData({id:id, type:'delete_staff', ...item, staff_assign:addItemToArray(item.staff_assign, {is_disabled:true})});
            return;
        }
        setMenuDrop(false);
    }
    const onClickEditMenuIcon = (type="", num=0, item={}) => {
        if(type === "edit"){
            setMenuDropData({...menuDropData, staff_assign:updateArray(menuDropData.staff_assign, num, {...item, is_disabled:false})})
        }
        if(type === "cancel"){
            setMenuDropData({...menuDropData, staff_assign:updateArray(menuDropData.staff_assign, num, {...item, is_disabled:true})})
        }
    }

    const handleSubjectAssignSubmit = (e, num=0) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        if(menuDropData.staff_id){
            const data = {
                subject: menuDropData.id,
                staff: menuDropData.staff_id,
                assigned_by:user_id
            }
            appServices.insertSubjectStaffAssignSchool(data).then(res => {
                setSuccess({text:"Data Saved Successfully", title:"Success"});
                setMenuDrop(false);
                setMenuLoading(false);
                setDataTableLoading(true);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Subject Assign Error'});
                setMenuLoading(false);
            })
            return;
        }
        setError({title:'Subject Assign Error', data:['Invalid Staff Assigned']});
        setMenuLoading(false);
    }

    const handleClassAssignSubmit = (e, item={}, type="") => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        if(type === "insert"){
            const data = {
                classes: item.id,
                staff_subject: item.staff_subject
            }
            appServices.insertSubjectStaffClassAssignSchool(data).then(res => {
                setSuccess({text:"Class Assigned Successfully", title:"Success"});
                setLoading(false);
                setDataTableLoading(true);
                setDataTable({...dataTable, pre:false});
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Subject Assign Error'});
                setLoading(false);
            })
            return;
        }
        if(type === "delete"){
            appServices.deleteSubjectStaffClassAssignSchool(item.uid).then(res => {
                setSuccess({text:"Class Removed Successfully", title:"Success"});
                setLoading(false);
                setDataTableLoading(true);
                setDataTable({...dataTable, pre:false});
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Subject Assign Error'});
                setLoading(false);
            })
            return
        }
        setLoading(false);
    }
    const handleStaffAssignDelete = (id="") => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        appServices.deleteSubjectStaffAssignSchool(id).then(res => {
            setSuccess({text:"Staff Deleted Successfully", title:"Success"});
            setMenuLoading(false);
            setDataTableLoading(true);
            setDataTable({...dataTable, pre:false});
            setMenuDropData({...menuDropData, staff_assign:menuDropData.staff_assign.filter((item) => item.uid !== id)})
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Subject Assign Error'});
            setMenuLoading(false);
        })
    }


    const getCloseListBoxData = (data) => {
        setCloseListBox(data);
    }

    useEffect(() => {
        fetchAllClassSection(school_id);
        fetchAllClasses(school_id);
        fetchAllStaff(school_id, 0);
    }, [school_id])
    useEffect(() => {
        if(dataTableLoading){
            fetchAllSubject(dataTable.pre, school_id, dataTable.csid);
            setDataTableLoading(false);
            return;
        }
    }, [school_id, dataTableLoading, dataTable]);

    useEffect(() => {
        if(reload){
            fetchAllClassSection(school_id);
            fetchAllSubject(true, school_id, dataTable.csid);
            fetchAllStaff(school_id, 0)
            setReload(false);
        }
    }, [reload, school_id, dataTable])

    useEffect(() => {
        if(closeListBoxDataLoading && menuDropData.sort_type === "staff"){
            setCloseListBoxDataLoading(false);
            fetchAllStaff(school_id, 0, menuDropData.staff_name);
        }
    }, [school_id, closeListBoxDataLoading, menuDropData])

    console.log(menuDropData)

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertClassSectionData = allClassSectionData.map((item) => {
        return(
            <option key={item.id} value={item.class_section}>{item.name}</option>
        )
    })
    const filterClassBySection = (csid=0, arr=[]) => {
        let filterItem = allClassesData.filter((item) => {
            return item.class_section_school === csid;
        })
        let new_arr = addItemToArray(arr, {is_active:true})
        return updateArrayWithArray(filterItem, new_arr, 'id', 'classes');
    }
    console.log(allClassesData)
    const insertTdSubject = allSubject.map((item, i) => {
        return(
            <Fragment key={i}>
                <TR>
                    <TD classname={'text-capitalize'}>{item.name} - {item.class_section_name} Section</TD>
                    <TD>{item.staff_assign.map((item2, ii, arr) => {
                        const arr_len = arr.length - 1;
                        return(
                            <Link className='text-capitalize link font-super-small' key={ii} to={`/${school_title}/app/staff/user/${item2.staff_uid}`}>{item2.last_name} {item2.first_name}{arr_len !== ii && ', ' }</Link>
                        )
                    })}</TD>
                    <TD>{item.staff_assign.length ? (
                        <>
                        {item.staff_assign.map((inner_item, ii) => {
                            return(
                                <Fragment key={ii}>
                                    <span className='app-text-sec-color font-super-small text-capitalize disp-block fw-700'>-{inner_item.last_name} {inner_item.first_name}</span>
                                    <div style={{paddingLeft:'3px', display:'inline-block'}}>
                                        {filterClassBySection(item.class_section, inner_item.class_handle).map((item3, iii, arr) => {
                                            const arr_len = arr.length - 1;
                                            return(
                                                <Fragment key={iii}>
                                                    {item3.is_active ?  <span style={{cursor:'default', color:'green'}} className='text-capitalize fw-700 font-super-small' onClick={(e) => handleClassAssignSubmit(e, {uid:item3.uid}, "delete")}>{item3.name}</span> : <span  className='text-capitalize fw-700 red font-super-small red' style={{cursor:'default'}}  onClick={(e) => handleClassAssignSubmit(e, {...item3, staff_subject:inner_item.id}, "insert")}>{item3.name}</span>}{arr_len !== iii && ', ' }
                                                </Fragment>
                                                // <span key={iii}  className='text-capitalize fw-600 app-text-color font-super-small'>{item3.name} {item3.is_active ? <span className='btn disp-inline br-circle font-super-small' onClick={(e) => handleClassAssignSubmit(e, {uid:item3.uid}, "delete")} style={{padding:'1px', background:'green', color:'#ddd'}}><i className='fas fa-check'></i></span> : <span className='btn disp-inline br-circle font-super-small' style={{padding:'2px', background:'red', color:'#ddd'}} onClick={(e) => handleClassAssignSubmit(e, {...item3, staff_subject:inner_item.id}, "insert")}><i className='fas fa-times'></i></span>}{arr_len !== iii && ', ' }</span>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            )
                        })}
                        </>
                        ) : ''}
                    </TD>
                    <TD><button type="button" className='btn font-slightly-small bg-blue hover-opacity br' onClick={() => onClickIcon(item.id, 'add', item)} style={{color:'#ddd'}}><i className='fas fa-plus'></i></button> {item.staff_assign.length > 0 && (<button type="button" onClick={() => onClickIcon(item.id, 'delete', item)} className='btn font-slightly-small bg-red hover-opacity fw-700' style={{color:'#ddd'}}><i className='fas fa-trash'></i></button>)}</TD>
                </TR>
            </Fragment>
        )
    })
    const staffDataOptions = allStaffData.results.map((item, i) => {
        const name = item.last_name + ' ' + item.first_name;
        return(
            <Fragment key={i}>
                <ListOption contentClassName={'app-body-color translateX-200-sm'} onClick={() => handleClickedList(name, item.id, item)} img={`${BACKEND_URL}${item.profile_pic}`} contentStyle={{width:'0%'}} flex={'100'} classname='font-very-small'>{name.length > 34 ? name.slice(0, 34)+'...' : name}</ListOption>
            </Fragment>
        )
    })

    const staff2Options = allStaffData2.results.map((item, i) => {
        const name = item.last_name + ' ' + item.first_name;
        return(
            <option value={item.id} key={i}>{name}</option>
        )
    })

    return(
        <>
        <HeadComponent title='Assign Subject To Staff' />
        <BreadCrumb />
        <ComponentWrapper>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper>   
            <Grid layout='lauto'>
                <GridContent header_text={`Assign Subject To Staff`} header_icon="fas fa-pen"> 
                    <Section>
                        {loading && (<Loader />)}
                        {preloading ? (<ul>
                        <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Mallet>
                            <div className="flex-100"><label className='font-super-small'>Select Class Section: 
                                <select name="csid" value={dataTable.csid} onChange={e => handleTable(e)} className='form-control-theme text-capitalize font-slightly-small' >
                                    <option value="">All</option>
                                    {insertClassSectionData}
                                </select></label>
                            </div>
                        </Mallet>
                        <Mallet>
                            <div className='flex-100' style={{width:'0'}}>
                                <TABLE >
                                    <thead>
                                        <TR>
                                            <TH>Subject - Class Section</TH>
                                            <TH>Staff Assigned</TH>
                                            <TH>Class Assigned</TH>
                                            <TH>Act</TH>
                                        </TR>
                                    </thead>
                                    <tbody>
                                        {tableLoading && <TR><TD colspan={4} style={{width:'100%', textAlign:'center'}}><Spinner /></TD></TR>}
                                        {allSubject.length > 0 ? insertTdSubject : (<TR><TD colspan={4} style={{width:'100%', textAlign:'center', color:'red'}}>No Record Found..., <span style={{color:'blue', cursor:'pointer'}} onClick={() => setReload(true)}>Reload</span></TD></TR>)}
                                    </tbody>
                                </TABLE>
                            </div>
                        </Mallet>
                        </>
                        )}
                    </Section>
                    <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false)}} header_text={menuDropData.type==="add_staff" ? "Assign New Staff" : menuDropData.type === "edit_staff" ?  "Edit Assigned Staff" : 'Delete Assigned Staff'} header_icon={menuDropData.type==="add_staff" ? "fas fa-plus" : menuDropData.type==="edit_staff" ?  "fas fa-pen" : 'fas fa-trash'}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    {menuDropData.type === "add_staff" && (
                                        <form className='row' onSubmit={e => handleSubjectAssignSubmit(e, menuDropData.num)}>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Class Section</label>
                                                <input type="text" name="class_section" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Class Section`} disabled value={menuDropData.class_section_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Subject Name</label>
                                                <input type="text" name="sub_name" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Subject Name`} disabled value={menuDropData.name} />
                                            </div>
                                            <div className="fg-all">
                                                <label className="font-super-small fw-600">Select Staff To Assign</label>
                                                <div style={{display:'flex', flexWrap:'wrap'}}>
                                                    <Input required handleChange={(e) => handleMenuChange(e)} value={menuDropData.staff_name} handleClick={() => setCloseListBox(false)} name="staff_name" type="list" classname="form-control-theme font-slightly-small" placeholder='Select Staff' setCloseListBox={getCloseListBoxData} closeListBox={closeListBox} listItemLength={allStaffData.results.length} >
                                                        {allStaffData.results.length > 0  &&  staffDataOptions}
                                                    </Input>
                                                </div>
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Save <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </form>
                                    )}
                                    {menuDropData.type === "edit_staff" && (
                                        <form className='row'>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Class Section</label>
                                                <input type="text" name="class_section" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Class Section`} disabled value={menuDropData.class_section_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Subject Name</label>
                                                <input type="text" name="sub_name" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Subject Name`} disabled value={menuDropData.name} />
                                            </div>
                                            {menuDropData.staff_assign.map((item, i) => {
                                                return(
                                                    <div key={i} className="fg-all">
                                                        <label className="font-super-small fw-600">Edit Assigned Staff</label>
                                                        <div className='disp-flex wrap'>
                                                            <div className='flex-50-line'>
                                                                <select name="edit_staff" value={item.staff} disabled={item.is_disabled} className={`font-very-small text-capitalize  ${item.is_disabled ? 'form-control disabled' : 'form-control-theme app-theme'}`}>
                                                                    <option value="">Select Staff</option>
                                                                    {staff2Options}
                                                                </select>
                                                            </div>
                                                            <div className='flex-50-line' style={{paddingLeft:'3px'}}>
                                                                {item.is_disabled ? (
                                                                    <>
                                                                        <button type="button" onClick={() => onClickEditMenuIcon("edit", item.num, item)} className='btn font-very-small bg-red hover-opacity fw-700 br' style={{color:'#ccc', marginRight:'3px'}}><i className='fas fa-trash'></i></button>
                                                                        <button type="button" className='btn hover-opacity br bg-yellow app-bg-text-color font-very-small' disabled={!item.is_disabled}><i className='fas fa-pen'></i></button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button type="button" className={`btn   br app-bg-color app-bg-text-color font-very-small ${item.is_changed && 'hover-opacity'}`} style={{cursor:item.is_changed ? 'pointer' : 'not-allowed'}} disabled={!item.is_changed}><i className='fas fa-paper-plane'></i></button>
                                                                        <button type="button" onClick={() => onClickEditMenuIcon("cancel", item.num, item)} className='btn font-very-small bg-black hover-opacity fw-700 br' style={{color:'#ccc', marginLeft:'3px'}}><i className='fas fa-times'></i></button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </form>
                                    )}
                                    {menuDropData.type === "delete_staff" && (
                                        <form className='row'>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Class Section</label>
                                                <input type="text" name="class_section" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Class Section`} disabled value={menuDropData.class_section_name} />
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small fw-600">Subject Name</label>
                                                <input type="text" name="sub_name" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Subject Name`} disabled value={menuDropData.name} />
                                            </div>
                                            {menuDropData.staff_assign.map((item, i) => {
                                                const name = item.last_name + ' ' + item.first_name;
                                                const n = i + 1;
                                                return(
                                                    <div key={i} className="fg">
                                                        <label className="font-super-small fw-600">Assigned Staff {n}</label>
                                                        <div className='disp-flex wrap' style={{margin:'0'}}>
                                                            <div className='flex-50-line'>
                                                                <input type="text" name="delete_staff" className="font-very-small text-capitalize app-theme form-control disabled" placeholder={`Staff Name`} disabled value={name} />
                                                            </div>
                                                            <div className='flex-50-line' style={{paddingLeft:'3px'}}>
                                                              
                                                                <button type="button" onClick={() => handleStaffAssignDelete(item.uid)} className='btn hover-opacity br bg-red app-bg-text-color font-very-small'><i className='fas fa-trash'></i></button>                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </form>
                                    )}
                                </>
                                )}
                            </MenuDrop>
                            }
                    </AnimatePresence>       
                
                </GridContent>
            </Grid>
        </ComponentWrapper>
        </>
    )
}

export default StaffSubjectAssign;