import styled from 'styled-components';

export const Content = styled.div`
    padding: 6px 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 15px 10px rgba(0,0,0,0.2);
    margin: 4px;

    @media(max-width: 540px){
        padding: 2px 5px;
        padding-bottom: 10px;
    }

    .header{
        
        border-bottom: 1px dotted #aaa;
        @media(max-width: 540px){
            padding-bottom: 0px;
        }
        span{
            font-weight: 700;
            color: #666;
        }
    }
    .cont{
        .green{
            color: rgb(40, 245, 67);
        }
        .red{
            color: rgb(255, 87, 67);
        }
    }
`;