export const getBlob = (url) => 
    fetch(url).then(resp => {
        return resp.blob();
    }).then(resp => {
        return resp;
    })

const blobToFile = (blob=Blob, url) => {
    const urlName = url.split('/').pop();
    const file = new File([blob], urlName, {type:blob.type, size:blob.size, lastModified: new Date().getTime()})
    return file;
}

export default blobToFile;