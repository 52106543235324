import React from "react";
import { Wrapper, Content } from "./style";

import { convertNumber } from "components/app/school/actions/money";

const CartCard = ({is_active=false, styleContainer, base_period=0, save_base_price=0, discount=0, name='', name_under="", price='', base_price, curr="", prev_price="", renewal_price="", renewal_date="", onClick, is_free=false}) => {
    const calcPerStud = (mon=0, num=0) => {
        const val = Math.ceil(parseInt(mon) / parseInt(num));
        return val;
    }
    const calcAmtSaved = (amt=0, num=0, save_bp=0) => {
        const first_save_base_price = Math.ceil(parseInt(save_bp) * parseInt(num));
        
        const amount = first_save_base_price - parseInt(amt) ;

        return amount;
    }
    return(
        <Wrapper onClick={onClick}>
            <Content style={styleContainer} className={`container ${is_active ? 'active' : ''}`}>
            <div className="checked-cont">
                <i className="fas fa-check"></i>
            </div>
            {parseInt(price) > 0 && (
                <>
                {calcAmtSaved(price, name_under, save_base_price) !== 0 && (
                    <div className="save-cont">
                        <span className="font-very-small fw-600">SAVE {curr}{convertNumber(calcAmtSaved(price, name_under, save_base_price))}</span>
                    </div>
                )}
                </>
            )}
            <div className="disp-flex wrap" style={{marginTop:'20px'}}>
                <div className="flex-100 disp-block align-center">
                    <span className="font-small fw-600 text-upper app-text-color"> {name}</span> 
                </div>
            </div>
            <div className="disp-flex" style={{marginTop:'5px'}}>
                {name_under && (
                    <div className="flex-100 disp-block align-center" >
                        <span className="font-med fw-700 text-upper app-bg-color-text" style={{ letterSpacing:'.6px'}}> {name_under}</span> <span className="font-super-small fw-600 app-text-color">students</span>
                    </div>
                )}
            </div>   
            <div className="disp-flex" style={{marginTop:'25px'}}> 
                {prev_price && (
                    <div className="flex-100 disp-block align-center old-price-cont" style={{marginTop:'15px'}}>
                        <span className="font-very-small old-price">{curr}{convertNumber(prev_price)}</span>
                    </div>
                )}
                <div className="flex-100 disp-block align-center" style={{marginTop:'0px'}}>
                    <span className="font-bigger fw-700" style={{color:'var(--voskoolBagColor)'}}>{parseInt(price) === 0 ? 'FREE' : `${curr}${convertNumber(price)}`}</span>
                    {parseInt(price) > 0 && (
                        <div className="disp-block" style={{marginTop:'3px'}}>
                            <span className="font-slightly-small fw-600" style={{color:'#777'}}>NAIRA <span className="font-super-small fw-500">for re-integration</span></span>
                        </div>
                    )}
                    
                    {/* <div className="disp-block" style={{marginTop:'3px'}}>
                        <span className="font-very-small fw-500" style={{color:'#777'}}>Plan renews at {curr}{renewal_price}/month on</span>
                        <div className="disp-block">
                            <span className="font-slightly-small fw-500"  style={{color:'#000'}}>{renewal_date}</span>
                        </div>
                    </div> */}
                </div>
                
            </div>
            {calcPerStud(price, name_under) > 0 && (
                <div className="disp-flex" style={{marginTop:'20px'}}> 
                    <div className="flex-100 disp-block align-center" style={{marginTop:'0px'}}>
                    <span className="font-slightly-small fw-600" style={{color:'#777'}}>{curr}{calcPerStud(price, name_under)}  <span className="font-super-small fw-500"> per student</span></span>
                    </div>
                </div>
            )}
            
            {!is_active && (
                <>
                {!is_free && (
                    <div className="disp-flex" style={{marginTop:'20px'}}> 
                        <div className="flex-100 disp-block align-center" style={{marginTop:'0px'}}>
                            <button className="padd-4px btn br hover-opacity app-bg-color app-bg-text-color" >Select</button>
                        </div>
                    </div>
                )}
                </>
            )}
            {is_free && (
                // <div className="disp-flex" style={{marginTop:'20px'}}>
                     <div className="disp-block align-center" style={{marginTop:'20px'}}>
                        <button type="button" className="mybtn font-very-small fw-600"  style={{background:'#3225ff', color:'#ccc', padding:'3px'}}>Try FREE {name_under}</button>
                    </div> 
                // </div>
            )}
            
            </Content>
        </Wrapper>
    )
}

export default CartCard;