import React, { useState, useEffect, useMemo, Fragment} from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Mallet from 'components/app/school/SmallerComponents/mallet';

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { Link } from 'react-router-dom';


const SchoolAttendance = ({school_id, user_id, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    // const [menuDropData, setMenuDropData] = useState({id:'', num:0 });
    // const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})
    const [malletState, setMalletState] = useState({session:''});
    const [attendState, setAttendState] = useState({id:0, days:[]})

    const [allSessionData, setAllSessionData] = useState([]);
    const [allDays, setAllDays] = useState([]);

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    // const [menuLoading, setMenuLoading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    // const [menuDrop, setMenuDrop] = useState(false);

    const fetchAllSchoolSession = (sid=0) => {
        if(sid){
            appServices.getCurrentSession(sid).then(sess_res => {
                if(sess_res.data.id){
                    setMalletState({session:sess_res.data.id, curr_session:sess_res.data.id, is_res_date_editable:sess_res.data.is_res_date_editable, active_session:sess_res.data.active_session})
                }
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error"});
            })
            appServices.getAllSession(sid).then(res => {
                setDataTableLoading(true);
                setAllSessionData(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error"});
            })
        }
    }
    const fetchSchoolWeekDays = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices.getAllSchoolWeekDayData(sid).then(res => {
                setAllDays(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Week Days Data Fetch Error"});
            })
        }
    }
    const fetchSchoolAttendance = (sid, ssid, pre=true) => {
        setError({data:[], title:''});
        setPreloading(pre);
        setSpinLoading(!pre)
        appServices.getSchoolAttendance(sid, ssid).then(result => {
            setPreloading(false);
            setSpinLoading(false);
            setAttendState({...result.data});
        }).catch(e => {
            setError({data:["Internal Server Error"], title:"Attendance Data Fetch Error"});
            setPreloading(false);
            setSpinLoading(false);
        })
    }

    const spread_attendance_days = (arr=[], tot_arr=[], day_len=0) => {
        let array = [];
        const handler = (a=[], num=0, tot_len=0) => {
            let data = []
            const tot = tot_len + num - 1;
            const arr_len = a.length;
            if(arr_len >= tot){
                for(let j = num; j <  tot; j++){
                    data.push({...a[j]});
                }
                return data;
            }
            return data;
        }
        for(let i = 0; i < arr.length; i++){
            let j = arr[i].i + 1;
            array.push({...arr[i], item:handler(tot_arr, j, day_len)})
        }
        return array;
    }
    const spread_attendance = (array=[], days_len=0) => {
        let arr= [];
        for(let i = 0; i < array.length; i = i + days_len){
            arr.push({...array[i], i:i});
        }
        return spread_attendance_days(arr, array, days_len)
    }



    

    const handleMalletChange = e => {
        setMalletState({...malletState, [e.target.name]:e.target.value});
    }
    const onClickStartAttendance = (sid=0, ssid=0) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        setPreloading(true);
        appServices.createSchoolStudentAttendance({school:sid, session:ssid, created_by:user_id}).then(res => {
            appServices.getSchoolAttendance(res.data.school, res.data.session).then(result => {
                setAttendState({...result.data});
                setLoading(false);
                setPreloading(false);
                setSuccess({text:"Attendance Started Successfully", title:"Success"});
                fetchAllSchoolSession(school_id);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Attendance Error'});
                setLoading(false);
                setPreloading(false);
            })
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Attendance Error'});
            setLoading(false);
            setPreloading(false);
        })
    }

    useEffect(() => {
        fetchSchoolWeekDays(school_id);
        fetchAllSchoolSession(school_id);
    }, [school_id])
    useEffect(() => {
        if(dataTableLoading && malletState.session){
            setDataTableLoading(false);
            fetchSchoolAttendance(school_id, parseInt(malletState.session))
        }
    }, [malletState, dataTableLoading, school_id]);

    console.log(attendState)

    const styleAttend = {margin:'3px 5px', border:'1px solid #888', padding:'3px',display:'inline-block'}

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSessionOption = allSessionData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.session} - ${item.term_name} term`}</option>
        )
    })
    const insertBtnStartAttendance = () => {
        if(parseInt(malletState.session) === parseInt(malletState.curr_session) && malletState.active_session && !malletState.is_res_date_editable){
            return(
                <div className="flex-100 padd-5px align-center">
                    <button type="button" onClick={() => onClickStartAttendance(school_id, parseInt(malletState.session))} className='mybtn font-slightly-small bg-green app-bg-text-color fw-700'>Start Attendance</button>
                </div>
            )
        }
        return(
            <></>
        )
    }
    // const insertSchoolWeekDayTd = () => {
    //     const a = spread_attendance(attendState.days, allDays.length).map((item, i) => {
    //         const ii = i + 1;
    //         return(
    //             <Fragment key={i}>
    //                 <Tr sn={true} btn={false}>
    //                     <div className="c-data sn"><span className="font-very-small">{ii}</span></div>
    //                     <div className="c-data" style={{width:'90%'}}><span className="font-very-small" style={styleAttend}><span className='font-super-small disp-block'>{item.day_name.toString().slice(0, 3)},</span>{convertDate(item.attend_day, 'ddMMyyyy') }</span> {item.item.map((inner_item, iii) => {
    //                         return(
    //                             <span key={iii} className="font-very-small"  style={styleAttend}><span className='font-super-small disp-block'>{inner_item.day_name.toString().slice(0, 3)},</span>{convertDate(inner_item.attend_day, 'ddMMyyyy')}</span>
    //                         )
    //                     })}</div>
    //                 </Tr>
    //             </Fragment>
    //         )
    //     })
    //     return(
    //         <>
    //         {a}
    //         </>
    //     )
    // } 

    return(
        <>
            <HeadComponent title='Manage School Attendance' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage School Attendance`} header_icon="fas fa-tasks">
                        {loading && (<Loader />)}
                        <Section>
                            <Mallet>
                                <div className="flex-100"><label className='font-super-small'>Select Term/Session: 
                                    <select name="session" onChange={e => handleMalletChange(e)} value={malletState.session} className='form-control-theme font-slightly-small  text-capitalize' >
                                        <option value="">Select Term / Session</option>
                                        {insertSessionOption}
                                    </select></label>
                                </div>
                                {insertBtnStartAttendance()}
                                
                            </Mallet>
                        {preloading ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        {attendState.id > 0 && (
                            <>
                            <Table>
                                {spinLoading && <Spinner />}
                                <Tr btn={false}  header={true} sn={true} text_style='c-data' text={[{text:"S/N"}, {text:'Start Date'}, {text:'Curr Date'}, {text:'End Date'}]} />
                                {/* {insertSchoolWeekDayTd()} */}
                                <Tr sn={true} btn={false}>
                                    <div className="c-data sn"><span className="font-very-small">1</span></div>
                                    <div className="c-data"><span className="font-very-small" style={styleAttend}>{convertDate(attendState.start_date, 'ddMMyyyy') }</span></div>
                                    <div className="c-data"><span className="font-very-small" style={styleAttend}>{convertDate(attendState.curr_date, 'ddMMyyyy') }</span></div>
                                    <div className="c-data"><span className="font-very-small" style={styleAttend}>{convertDate(attendState.end_date, 'ddMMyyyy') }</span></div>
                                </Tr>
                            </Table>

                            <div className='disp-block align-center padd-4px'>
                                <Link to={`student`} className='font-slightly-small'> View Student Attendance List</Link>
                            </div>
                            </>
                        )}
                            
                        </>
                        )}
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolAttendance;