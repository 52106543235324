export const addItemToArray = (arr=[], item, key, value) => {
    let data = [];
    for(let i =0; i < arr.length; i++){
        if(key){
            data.push({...arr[i],  ...item, [key]:arr[i][value], num:i});
        }
        else {
            data.push({...arr[i],  ...item, num:i});
        }
    }
    return data;
}
export const addParItemToArray = (arr=[], obj, obj_name="", item={}) => {
    let data = [];
    for(let i = 0; i < arr.length; i++){
        data.push({[obj_name]:{...arr[i], ...obj}, ...item})
    }
    return data;

}
export const addItemKeyToArrayOnly = (arr=[], item, key1='key', value1='id', key2='', value2='', key3='', value3='', key4='', value4='', key5='', value5='') => {
    let data = [];
    for(let i =0; i < arr.length; i++){
        if(key2 && key3 && key4 && key5){
            data.push({...item, [key1]:arr[i][value1], [key2]:arr[i][value2], [key3]:arr[i][value3], [key4]:arr[i][value4], [key5]:arr[i][value5], num:i});
        }
        else if(key2 && key3 && key4){
            data.push({...item, [key1]:arr[i][value1], [key2]:arr[i][value2], [key3]:arr[i][value3], [key4]:arr[i][value4], num:i});
        }
        else if(key2 && key3){
            data.push({...item, [key1]:arr[i][value1], [key2]:arr[i][value2], [key3]:arr[i][value3], num:i});
        }
        else if(key2){
            data.push({...item, [key1]:arr[i][value1], [key2]:arr[i][value2], num:i});
        }
        else{
            data.push({...item, [key1]:arr[i][value1], num:i});
        }
    }
    return data;
}
export const addItemToArrayAndInnerArrays = (arr=[], inner_arr_name=[], inner_arr_item=[], item) => {
    const addItemToArray = (arr=[], item) => {
        let data = [];
        for(let i =0; i < arr.length; i++){
            data.push({...arr[i],  ...item, num:i});
        }
        return data;
    }
    let data = addItemToArray(arr, item);
    if(inner_arr_name.length > inner_arr_item.length || inner_arr_name.length === inner_arr_item.length){
        for(let i = 0; i < arr.length; i++){
            for(let j = 0; j < inner_arr_name.length; j++){
                console.log(data[i])
                data.splice(i, 1, {...data[i], [inner_arr_name[j]]:addItemToArray(data[i][inner_arr_name[j]], {...inner_arr_item[j]})})
            }
        }
    }
    return data;
    

}
export const addItemToArrayAndInnerArray = (arr=[], inner_arr_name="", inner_arr_item, item, third_arr_name="", third_arr_item) => {
    let data = [];
    const addItemToArray = (arr=[], item) => {
        let data = [];
        for(let i =0; i < arr.length; i++){
            data.push({...arr[i],  ...item, num:i});
        }
        return data;
    }
    if(third_arr_name){
        const addItemToArrayAndInnerArray = (arr=[], item, inner_arr_name="", inner_arr_item={}) => {
            let dt = [];
            for(let i =0; i < arr.length; i++){
                dt.push({...arr[i], [inner_arr_name]:addItemToArray(arr[i][inner_arr_name], inner_arr_item),  ...item, num:i});
            }
            return dt;
        }
        for(let i =0; i < arr.length; i++){
            data.push({...arr[i], [inner_arr_name]:addItemToArrayAndInnerArray(arr[i][inner_arr_name], inner_arr_item, third_arr_name, third_arr_item),  ...item, num:i});
        }
        return data;
    }
    for(let i =0; i < arr.length; i++){
        data.push({...arr[i], [inner_arr_name]:addItemToArray(arr[i][inner_arr_name], inner_arr_item),  ...item, num:i});
    }
    return data;
}
export const addItemToArrayOnly = (arr=[], item) => {
    let data = [];
    for(let i =0; i < arr.length; i++){
        data.push({...item, num:i});
    }
    return data;
}

export const addArrayWithArray = (arr=[], list=[], num_name='num_name') => {
    let data = [];
    for(let i =0; i < arr.length; i++){
        for(let j =0; j < list.length; j++){
            data.push({ ...arr[i], ...list[j], [num_name]:i, num:j});
        }
    }
    return data;

}
export const joinArray = (arr=[], jo="", is_end=true) => {
    let data = '';
    for(let i = 0; i < arr.length; i++){
        data = `${data}${is_end ? `${arr[i]}${jo}` : `${jo}${arr[i]}`}`;
    }
    return data;
}
export const addArrayWithNumber = (len=0, list=[], num_name='num') => {
    let data = [];
    for(let i =0; i < len; i++){
        for(let j =0; j < list.length; j++){
            data.push({...list[j], [num_name]:i, num:j});
        }
    }
    return data;

}

export const addDateArrayToArray = (arr=[], key="", desc_order=false) => {
    if(arr.length > 0 && key){
        let data = [];
        let lastDateObj = arr[arr.length - 1];
        let lastDate = lastDateObj[key].slice(0, 10);
        let lastDateTime = new Date(lastDate).getTime();
        let firstDateObj = arr[0];
        let firstDate =  firstDateObj[key].slice(0, 10);
        let firstDateTime = new Date(firstDate).getTime();
        let loopDate = 1000 * 60 * 60 * 24;
        if(desc_order){
            lastDateObj = arr[0];
            lastDate = lastDateObj[key].slice(0, 10);
            lastDateTime = new Date(lastDate).getTime();
            firstDateObj = arr[arr.length - 1];
            firstDate =  firstDateObj[key].slice(0, 10);
            firstDateTime = new Date(firstDate).getTime();
            while(lastDateTime >= firstDateTime){
                const time = lastDateTime
                let dt = [];
                for(let i = 0; i < arr.length; i++){
                    if(new Date(arr[i][key].slice(0, 10)).getTime() === time){
                        dt.push(arr[i]);
                    }
                }
                if(dt.length > 0){
                    data.push({date:lastDateTime, arr:dt});
                }
                lastDateTime-=loopDate;
            }
        }
        while(firstDateTime <= lastDateTime){
            const time = firstDateTime
            let dt = [];
            for(let i = 0; i < arr.length; i++){
                if(new Date(arr[i][key].slice(0, 10)).getTime() === time){
                    dt.push(arr[i]);
                }
            }
            if(dt.length > 0){
                data.push({date:firstDateTime, arr:dt})
            }
            firstDateTime+=loopDate;
        }
        return data;
    }
    return [];
}
