import React from "react";
import { Wrapper } from "./style";

const THDrop= ({children, style, bg="app-opp-theme"}) => {
    return(
        <Wrapper style={style}>
            {children}
        </Wrapper>
    )
}

export default THDrop;