import styled from "styled-components";
import { motion } from "framer-motion";

export const Content = styled(motion.li)`
    display: inline-flex;
    width: 100%;
    transition: .2s ease;
    border-radius: 5px;
    position: relative;
    .sn:first-child{
        width: 8%;
        @media(max-width:540px){
            width: 11%;
        }
        @media(max-width:420px){
            width: 14%;
        }
    }
    :hover:not(.th){
        border-bottom: 1px solid var(--appBgColor);
        .c-data{
            opacity: .6;
        }
        .inner-content{
            display: block;
        }
        .btn-wrapper{
            display: inline-flex;

            button{
                opacity: 1;
            }
        }
    }
    .c-data{
        display: table-cell;
        width: 25%;
        padding: 10px;
        border-right: 2px solid var(--appTheme);
        @media(max-width: 1280px){
            width: 33.33%;
        }
        @media(max-width:540px){
            width: 33.33%;
            padding: 8px;
        }
        @media(max-width:420px){
            width: 45%;
            padding: 8px;
        }
        .profile-cont{
            display: inline-block;
            position: relative;
            span.circle{
                width: 7px;
                height: 6px;
                position: absolute;
                border-radius: 50%;
                bottom: 5%;
                right: 5%;
            }
            span.circle.green{
                background: green;
            }
            span.circle.red{
                background: red;
            }
            span.circle.yellow{
                background: #ffff15;
            }
            img.profile{
                width: 20px;
                height: 20px;

                @media(max-width: 320px){
                    width: 15px;
                    height: 15px;
                }
            }
        }
        
        span{
            font-weight: 500;
            letter-spacing: .4px;
            color: var(--appTextColor);
        }
    }
    
    .btn-wrapper{
        position: absolute;
        bottom: 5px;
        right: 0%;
        display: none;
        transition: .3s ease;

        button{
            transition: .5s ease;
            margin-right: 7px;
            background: var(--appTheme);
            border: none;
            outline: none;
            cursor: pointer;
            border-radius: 10px;
            box-shadow: 0 0 10px 3px rgba(0,0,0,0.1);
            font-weight: 600;
            :hover{
                box-shadow: 0 0 10px 3px rgba(0,0,0,0.3);
            }
            span{
                padding: 2px 3px;
                @media(max-width:540px){
                    padding: 2px;
                }
            }
        }
        button.b-green{
            border: 1px solid #44ff00;
            color: #44ff00;
        }
        button.br-red{
            border: 1px solid #ff0090;
            color: #00057a;
        }
        button.edit_btn{
            border: 1px solid #00057a;
            color: #00057a;
        }
        button.view_btn{
            border: 1px solid #a05700;
            color: #a05700;
        }
        button.dlt_btn{
            border: 1px solid #ff0010;
            color: #ff0010;
        }
    }
    
    
    div.c-data:nth-child(8){
        display: none;
    }
     div.c-data:nth-child(9){
        display: none;
    }
    div.c-data:nth-child(10){
        display: none;
    }

    div.c-data:nth-child(11){
        display: none;
    }
    div.c-data:nth-child(12){
        display: none;
    }
    div.c-data:nth-child(13){
        display: none;
    }
    div.c-data:nth-child(14){
        display: none;
    }
    div.c-data:nth-child(15){
        display: none;
    }
    div.c-data:nth-child(16){
        display: none;
    }

    div.c-data:nth-child(17){
        display: none;
    }
    div.c-data:nth-child(18){
        display: none;
    }
    div.c-data:nth-child(19){
        display: none;
    }
    div.c-data:nth-child(20){
        display: none;
    }
    @media(max-width:1280px){
        div.c-data:nth-child(6){
            display: none;
        }

    }
    @media(max-width:540px){
        div.c-data:nth-child(7){
            display: none;
        }
    }
    @media(max-width:420px){
        div.c-data:nth-child(5){
            display: none;
        }
        div.hide.c-data:nth-child(4){
            display: none;
        }
    }
    @media(max-width:280px){
        div.c-data:nth-child(4){
            display: none;
        }
        div.c-data.show:nth-child(4){
            display: table-cell !important;
        }
    }
`;