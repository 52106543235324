import React from "react";

import { Wrapper } from "./style";

const Background = ({children}) => {
    return(
        <Wrapper className="app-body-color">
            {children}
        </Wrapper>
    )
}

export default Background;