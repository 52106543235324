import styled from 'styled-components';

export const OuterWrapper = styled.div`
    margin-top: 40px;
    padding: 20px;
    margin-bottom: 20px;
`;

export const ImgWrapper = styled.div`
    img{
        width: 130px;
        height: 130px;
        border-radius: 50%;
    }
    .icon-wrapper{
        margin-top: 20px;
        align-items: center;
        justify-content: center;

        .icon-cont{
            padding: 5px 10px;
            border: 1px solid rgba(0,0,0,0.1);
            border-radius: 10px;
            transition: .3s ease;
            cursor: pointer;

            &:hover{
                background: rgba(0,0,0,0.1);
            }

            .text{
                margin-top: 5px;
            }
        }
    }
`;

export const Wrapper = styled.div`
    margin-top: 30px;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    transition: .3s ease;
    margin-bottom: 8px;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 3px;
    cursor: default;

    &:hover{
        box-shadow: 0 0 3px 4px rgba(0,0,0,0.08);
    }

    .padd-r-10px{
        padding-right: 10px;
    }
`;