import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    padding: 10px 0;
    .error_cont{
    background: #ff0000;
    padding: 8px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
    border-radius: 1%;
    display: block;
    text-align: left;
    /* border: 2px solid #ff0000; */
  }
  .error_cont .icon_cont{
    color: #8f0000;
    position: absolute;
    font-size: 14px;
  }
 .error_cont .error_text{
    color: #eee;
    letter-spacing: 1px;
    margin-left: 20px;
    text-transform: capitalize;
  }
  .success_cont2{
    background: #68bb59;
    padding: 4px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
    border-radius: 1%;
    text-align: left;
  }
  .success_cont2 .icon_cont{
    color: #4c9a2a;
    position: absolute;
    font-size: 14px;
  }
  .success_cont2 .success_text{
    color: #fff;
    letter-spacing: 1px;
    margin-left: 30px;
    font-weight: 500;
    text-transform: capitalize;
  }
`;