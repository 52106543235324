import React, {useState, useEffect} from "react";
import { OuterWrapper, Wrapper, InnerWrapper, Content } from "./style";

export const BtnAbsUIWrapper = ({children, is_absolute=true, style={}}) =>{
    return(
        <OuterWrapper style={{...style}}>   
            {children}
        </OuterWrapper>
    )
}

const BtnAbsUI = ({is_clickable=false, is_click=false, onClick, children}) => {
    const [isClick, setIsClick] = useState(false);

    useEffect(() => {
        setIsClick(is_click);
    }, [is_click])
    return(
        <Wrapper>
            <div className={`wrap ${isClick ? 'br-5px' : 'br-circle'}`}>{is_clickable ? <i className={` font-med hover-opacity ${!is_click ? (isClick ? 'br-5px fas fa-times bg-red' : 'br-circle fas fa-plus app-bg-color') : 'br-circle fas fa-plus app-bg-color'}`} onClick={() => setIsClick(!isClick)}></i> : <i className="fas fa-plus font-med hover-opacity app-bg-color" onClick={onClick}></i>}</div>
            {is_clickable && (
                <InnerWrapper className="app-theme" style={{display: !is_click ? (isClick ? 'block' : 'none') : 'none'}}>
                    {children}
                </InnerWrapper>
            )}
            
        </Wrapper>
    )
}

export const BtnAbsUIBtn = ({title="", icon="", onClick}) => {
    return(
        <Content onClick={onClick}>
            <div className="disp-block cont">
                <i className={`${icon} font-super-small`}></i>
            </div>
            <div className="disp-block cont">
                <span className="font-super-small">{title}</span>
            </div>
        </Content>
    )
}

export default BtnAbsUI;