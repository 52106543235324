import React, { useState, useEffect, useMemo, Fragment } from 'react';
import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from 'components/app/school/SmallerComponents/Section';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import Mallet from 'components/app/school/SmallerComponents/mallet';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { convertNumber } from 'components/app/school/actions/money';
import { HeadComponent } from "components/head";
import { appServices4 } from 'api/schoolApp/services';
// import { BACKEND_URL } from 'actions/url';

import { loopDate } from 'components/app/school/actions/array-utils/dates';
import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';

let  month = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
const StaffPayment = ({school_id=0, school={}, user_id=0, access={}, currency={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [state, setState] = useState({year:new Date().getFullYear(), month:'', pre:true});

    const [payment, setPayment] = useState([]);
    const [currentPayment, setCurrentPayment] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);

    const handleStateChange = e => {
        setState({...state, [e.target.name]:e.target.value, pre:false});
        setDataTableLoading(true);
    }

    const fetchAllCurrentPayment = (sid, stid) => {
        setCurrentPayment([]);
        appServices4.getAllCurrentSchoolPayment(sid, stid).then(res => {
            setCurrentPayment(res.data);
        }).catch(e => {
            setCurrentPayment([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Payment Error'});
        })
    }
    const fetchAllStaffPayment = (sid, stid, y=0, m=0, pre=true) => {
        setError({data:[], title:''});
        setPreloading(pre);
        setPreloading2(pre);
        setSpinLoading(!pre);
        appServices4.getAllSchoolPayment(sid, stid, y, m).then(res => {
            setPayment(res.data);
            setPreloading(false);
            setPreloading2(false);
            setSpinLoading(false);
        }).catch(e => {
            setPreloading(false);
            setPreloading2(false);
            setSpinLoading(false);
            setPayment([]);
            setError((prev) => {return {...prev, title:"Error", data:[...prev.data, 'Payment Data Fetch Error']}});
        })
    }
    const onClickFinalize = (id, dt={}) => {
        setWarning({id:0, initialSuccessState});
        setTimeout(() => {
            setError(initialErrorState);
            setSuccess(initialSuccessState);
            setWarning({id:dt.id, title:'Would You Like To Proceed ...', text:`Would you like to accept payment for ${month[dt.month-1].toUpperCase()} ${dt.year}`, click_text:'Yes', handleClick: () => {
                setLoading(true);
                setWarning({id:0, initialSuccessState});
                appServices4.finalizeSchoolPayment(id, {pay_date:convertDate(new Date(), 'yyyy-mm-dd hh:mm:ss')}).then(res => {
                    setLoading(false);
                    setReload(true);
                    setSuccess({title:'Success', text:'Payment Completed Successfully'});
                }).catch(e => {
                    setLoading(false);
                    setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Payment Error'});
                })
            } })
        })
    }

    useEffect(() => {
        if(access.uid && reload){
            fetchAllCurrentPayment(school_id, access.uid);
            fetchAllStaffPayment(school_id, access.uid, parseInt(state.year), state.month ? parseInt(state.month)+1 : '', state.pre);
            setReload(false);
            return;
        }
        if(dataTableLoading && state.year){
            fetchAllCurrentPayment(school_id, access.uid);
            fetchAllStaffPayment(school_id, access.uid, parseInt(state.year), state.month ? parseInt(state.month)+1 : '', state.pre);
            setDataTableLoading(false);
            return;
        }
    }, [school_id, access, state, dataTableLoading, reload])

    const curr_year = new Date().getFullYear();
    const curr_month = new Date().getMonth();
    

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertDateOption = loopDate(curr_year - 2023).map((date, i) => (
        <option value={date} disabled={date > curr_year ? true : false}>{date}</option>
    ))
    const insertMonthOption = month.map((mon, i) => {
        if(curr_year === state.year){
            return(
                <option value={i} disabled={i > curr_month ? true : false}>{mon.slice(0, 3)}</option>
            )
        }
        return(
            <option value={i} >{mon.slice(0, 3)}</option>
        )
    })
    const addNumbers = (arr=[]) => {
        const data = arr.reduce((num, currNum) => {
            return parseFloat(num) + parseFloat(currNum);
        }, 0.00);
        return data;
    }
    const isPaid = (is_school_paid=false, is_staff_accept=false) => {
        if(is_school_paid && is_staff_accept){
            return {title:'PAID', text:''};
        }
        if(is_school_paid){
            return {title:'PAID', text:'\n (Awaiting Your Response)'};
        }
        if(is_staff_accept){
            return {title:'ERROR', text:''};
        }
        return {title:'pending', text:''};
    }
    return(
        <>
            <HeadComponent title='Staff Payment' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                <GridContent>
                {preloading ? (<ul>
                        <BlankLoader num={20} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <Section>
                            {loading && <Loader />}
                            {currentPayment.length > 0 && (
                                <div className='disp-block' style={{padding:'5px', background:'var(--appTheme)', boxShadow:'0 0 3px 5px rgba(0,0,0,0.2)', borderRadius:'5px'}}>
                                    <div className='disp-block align-center padd-4px'>
                                        <span className='app-text-color fw-700 font-small'>Accept Payment (If recieved)</span>
                                    </div>
                                    <TABLE>
                                        <thead>
                                            <TR>
                                                <TH>S/N</TH>
                                                <TH style={{width:'60%'}}>Sal Mon</TH>
                                                <TH style={{width:'20%'}}>Amt Paid</TH>
                                                <TH>Act</TH>
                                                <TH>Paid By (Admin)</TH>
                                            </TR>
                                        </thead>
                                        <tbody>
                                            {currentPayment.map((item, i) => {
                                                i++;
                                                return(
                                                    <Fragment key={i}>
                                                        <TR>
                                                            <TD><span className='font-very-small'>{i}</span></TD>
                                                            <TD><span className='font-very-small text-upper'>{month[item.month-1].slice(0, 3)} - {item.year} </span></TD>
                                                            <TD><span className='font-slightly-small fw-600'>{currency.symbol}{convertNumber(item.amt_paid)}</span></TD>
                                                            <TD><button type="button" className='font-slightly-small fw-600 btn br hover-opacity app-bg-text-color'  onClick={() => onClickFinalize(item.uid, {year:item.year, month:item.month, id:item.id})} style={{background:'rgb(40, 245, 50)'}}>Accept</button></TD>
                                                            <TD><span className='font-very-small'>{item.paid_by_last_name} {item.paid_by_first_name}</span></TD>
                                                        </TR>
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </TABLE>
                                </div>
                            )}
                            
                            <Mallet>
                                <div className='flex-50-line'>
                                    <label className='font-slightly-small'>Select  Year: 
                                    <select name="year" onChange={e => handleStateChange(e)} value={state.year} className='form-control-theme font-slightly-small' >
                                        {insertDateOption}
                                    </select></label>
                                </div>
                                <div className='flex-50-line'>
                                    <label className='font-slightly-small'>Select  Month: 
                                    <select name="month" onChange={e => handleStateChange(e)} value={state.month} className='form-control-theme font-slightly-small text-capitalize' >
                                        <option value={''}>Select Month</option>
                                        {insertMonthOption}
                                    </select></label>
                                </div>
                            </Mallet>
                            {spinLoading && <Spinner />}
                            <Table>
                                <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Amt To Pay"}, {text: 'Amt Paid'}, {text:"Status"}]} />
                                {payment.length === 0 ? (<Tr btn={false} text={[{text:'No Record Found', style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                        (
                                            <>
                                            {payment.map((item, i) => {
                                                // const name = `${item.last_name} ${item.first_name}`;
                                                const j = i+1;
                                                const stat = isPaid(item.is_school_paid, item.is_staff_accept);
                                                const prev_amt = item.cumm_prev_unpaid_amt;
                                                
                                                return(
                                                    <Fragment key={i}>
                                                    <Tr edit_btn={false} dlt_btn={false}>
                                                        <div className="c-data sn">
                                                            <span className='font-super-small fw-600' style={{color:'#777'}}>{j}</span>  
                                                        </div> 
                                                        <div className="c-data"><span className="font-slightly-small app-text-color fw-600">{currency.symbol}{convertNumber(addNumbers([item.curr_salary, parseFloat(prev_amt)]))} </span> {!state.month && <span className='font-super-small app-text-color'>({item.year}/{month[item.month-1].slice(0, 3)})</span>}</div>  
                                                        <div className="c-data"><span className="font-slightly-small app-text-color fw-600">{currency.symbol}{convertNumber(item.amt_paid)}</span></div>  
                                                        <div className="c-data">
                                                            <span className="font-very-small text-capitalize">{stat.title.toLowerCase() === "paid" ? (<><span className='green fw-700'>{stat.title.toUpperCase()}</span>{stat.text && <span className='font-super-small'>{stat.text}</span>} {item.is_incomplete && (<>{!item.is_incomplete && <span className='font-super-small' style={{color:'rgb(205, 220, 15)'}}>(Incomplete)</span>}</>)}</>) : (<>{stat.title.toLowerCase() === "pending" ? <b className='fw-700 ' style={{color:'rgb(205, 220, 15)', fontWeight:700}}>{stat.title}</b> : stat.title}</>)}</span>
                                                            {item.is_school_paid && (
                                                                <span className='font-super-small app-text-color'><b>By:</b> {item.paid_by_last_name} {item.paid_by_first_name}</span>
                                                            )}
                                                            {item.is_staff_accept && (
                                                                <span className='font-very-small app-text-color '><br /> {item.pay_date && convertDate(item.pay_date, 'ddMMyyyy')}</span>
                                                            )}
                                                        </div>    
                                                    </Tr>
                                                    </Fragment>
                                                )
                                            })}
                                            </>
                                        )              
                                }
                            </Table>
                        </Section>
                    </>
                )}
                </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default StaffPayment;