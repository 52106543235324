import React, { useState, useEffect, useMemo } from 'react';

import BreadCrumb from "components/breadCrumb";
import Note from 'components/app/school/SmallerComponents/NOTE';
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";


import Loader from "components/app/school/SmallerComponents/loader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { validateParentDataForm2, validateStudentDataForm2 } from 'components/app/school/actions/validation';
import { runOut } from 'components/app/school/actions/utils/runOutVospace';
import { groupArray } from 'components/app/school/actions/array-utils/Group';
import { HashLink } from 'react-router-hash-link';
import { sortByName } from 'components/app/school/actions/array-utils/Sort';
import { Link } from 'react-router-dom';


const AddStudent = ({school_id, school_title, school={}, user_type, user={email:''}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialParentState = useMemo(() => ({surname:'', othername:'', email:'', gender:'', title:'', phone_number:'', addr:'', password:'', re_password:''}), []);
    const initialStudentState = useMemo(() => ({profile_pic:File, enroll_type:'', curr_class:'', school_branch:'', guardian:'', guardian_type:'', sponsored_by:'', school_fee_payment:'yes', surname:'', othername:'', email:'', gender:'', title:'', phone_number:'', addr:'', password:'', re_password:'', rel:''}), [])

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [allGuardians, setAllGuardians] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allParents, setAllParents] = useState([]);

    const [studentStateError, setStudentStateError] = useState({});
    const [studentState, setStudentState] = useState(initialStudentState);
    const [parentStateError, setParentStateError] = useState({});
    const [parentState, setParentState] = useState(initialParentState);
    const [schoolVoSpace, setVoSchoolSpace] = useState({id:0});

    const [submit, setSubmit] = useState('');

    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(true);

    const fetchSchoolVoSpace = (id) => {
        if(id){
            setError({data:[], title:''});
            appServices.getSchoolVoSpace(id).then(res => {
                setVoSchoolSpace(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"VoSpace Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchAllSchoolBranch = (sid, pre=true) => {
        if(sid){
            appServices.getAllSchoolBranch(sid).then(res => {
                setAllSchoolBranchData([...res.data]);
            }).catch(e => {
                setAllSchoolBranchData([]);
                setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchAllParents = (sid) => {
        if(sid){
            appServices.getAllParentByADMIN(sid).then(res => {
               setAllParents(groupArray(res.data.parents, 'id'));
               appServices.getAllParentADMIN(sid).then(res => {
                setAllParents((prev) => prev.length > 0 ? sortByName(groupArray([...prev, ...res.data.parents], 'id'), 'full_name') : sortByName(prev, 'full_name'));
               })
            }).catch(e => {
                setAllSchoolBranchData([]);
                setError({data:["Internal Server Error"], title:"Parents Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchAllGuardians = () => {
        appServices.getAllGuardians().then(res => {
            setAllGuardians([...res.data])
        }).catch(e => {
            setAllGuardians([]);
        })
    }
    const fetchAllClasses = (school_id) => {
        if(school_id){
            setError({data:[], title:''});
            appServices.getAllClassSchoolItem(school_id).then(res => {
                setAllClassesData(addItemToArray(res.data));
            }).catch(e => {
                setAllClassesData([]);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }

    const handleSubmitParent = (e) => {
        e.preventDefault();
        setSubmit('insert_parent');
        setParentStateError(validateParentDataForm2(parentState));
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "inserted_parent"){
            setLoading(true);
            const data = {
                title:parentState.title,
                gender:parentState.gender,
                address:parentState.addr,
                last_name:parentState.surname,
                first_name:parentState.othername,
                email:parentState.email,
                phone_number:`234${parentState.phone_number}`,
                school: school.id,
                password:parentState.password
            }
            appServices.insertParentByAdmin(data).then(res => {
                setLoading(false);
                setParentState(initialParentState);
                setSubmit('');
                setSuccess({title:'Success', text:'Parent Added Successfully'});
                fetchAllParents(school_id);
            }).catch(e => {
                setLoading(false);
                setSubmit('');
                setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error'})
            });
        }
    }

    const handleSubmitStudent = (e) => {
        e.preventDefault();
        setSubmit('insert_student');
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setStudentStateError(validateStudentDataForm2(studentState));
        if(submit === "inserted_student"){
            setLoading(true);
            const class_enrolled = allClassesData.filter((it) => it.id === parseInt(studentState.curr_class))[0];
            let formData = new FormData();
            studentState.profile_pic_value && formData.append('profile_pic', studentState.profile_pic, studentState.profile_pic.name);
            formData.append('last_name', studentState.surname);
            formData.append('first_name', studentState.othername);
            formData.append('gender', studentState.gender);
            formData.append('rel', studentState.rel);
            formData.append('enroll_type', studentState.enroll_type);
            formData.append('class_accepted', studentState.curr_class);
            formData.append('class_enrolled', class_enrolled.o_class_id);
            formData.append('school_branch', studentState.school_branch);
            formData.append('sponsored_by', studentState.sponsored_by);
            formData.append('school', school_id);
            formData.append('user', user.id);
            formData.append('school_fee_payment', studentState.school_fee_payment);
            if(studentState.sponsored_by === "self"){
                formData.append('email', studentState.email);
                formData.append('phone_number', `234${studentState.phone_number}`);
                formData.append('address', studentState.addr);
                formData.append('password', studentState.password);
            }
            if(studentState.sponsored_by === "guardian"){
                formData.append('guardian', studentState.guardian);
                formData.append('guardian_type', studentState.guardian_type);
            }

            appServices.insertStudentByAdmin(formData).then(res => {
                setLoading(false);
                setStudentState(initialStudentState);
                setSuccess({title:'Success', text:studentState.school_fee_payment === 'yes' ? 'Student Enrolled Successfully' : 'Student Accepted Successfully'});
                setSubmit('');
            }).catch(e => {
                setLoading(false);
                setSubmit('');
                setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error'})
            });
        }
    }

    useEffect(() => {
        if(reloadData && school.id){
            setReloadData(false);
            fetchAllSchoolBranch(school_id);
            fetchAllClasses(school_id);
            fetchSchoolVoSpace(school_id);
            fetchAllParents(school_id);
            fetchAllGuardians();
        }
    }, [reloadData, school_id, school])

    const handleStudentChange = e => {
        setStudentState((prev) => {return {...prev, [e.target.name]:e.target.value}});
    }
    const handleParentChange = e => {
        setParentState((prev) => {return {...prev, [e.target.name]:e.target.value}});
    }
    const handleUserChangeFile = (e) => {
        setStudentState({...studentState, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value});
    }

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    });
    const insertClassesOption = allClassesData.map((item, i) => (
        <option value={item.id} key={i}>{item.name}</option>
    ))
    const insertSchoolBranchData = allSchoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const guardianTypeOptions = allGuardians.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const parentOptions = allParents.map((item, i) => {
        return(
            <option key={i} value={item.id}>{item.full_name}</option>
        )
    })

    useEffect(() => {
        if(Object.keys(parentStateError).length === 0 && submit === "insert_parent"){
            setSubmit('inserted_parent');
        }
        if(Object.keys(studentStateError).length === 0 && submit === "insert_student"){
            setSubmit('inserted_student');
        }
    }, [parentStateError, studentStateError, submit]);
    return(
        <>
            <HeadComponent title='Manage all students' />
            <BreadCrumb />
            
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                    {schoolVoSpace.id > 0 && (
                    <>
                        <GridContent header_text={`VoSpace Record`} header_icon="fas fa-hdd">
                            <Section>
                            
                                <div className='disp-flex wrap'>
                                    <div className='flex-30 disp-flex jc-stretch'>
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-server font-small'></i> VoSpace Remaining:</span>
                                        </div>
                                        <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                            <span className='font-med app-bg-color-text fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_rem}</span>
                                        </div>
                                    </div>
                                    <div className='flex-30 disp-flex jc-stretch'>
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-plus font-small'></i> Total Added:</span>
                                        </div>
                                        <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                            <span className='font-small app-text-color fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_added}</span>
                                        </div>
                                    </div>
                                    <div className='flex-30 disp-flex jc-stretch'>
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-road font-small'></i> Total Used:</span>
                                        </div>
                                        <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                            <span className='font-small app-text-color fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_used}</span>
                                        </div>
                                    </div>
                                </div>
                                {runOut(schoolVoSpace.vo_space_rem)}
                            </Section>
                        </GridContent>

                    </>
                    )}
                    <GridContent header_text={`Add Student`} header_icon="fas fa-plus">
                        {loading && <Loader />}
                        <Section>
                        <form onSubmit={e => handleSubmitStudent(e)}>
                        <div className='disp-flex wrap'>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student's Passport Picture</label>
                                <input type='file' name="profile_pic" onChange={e => handleUserChangeFile(e)} value={studentState.profile_pic_value} className='form-control-theme app-text-color font-slightly-small' />
                                {studentStateError.profile_pic && (<span className="red font-super-small fw-600">{studentStateError.profile_pic}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student's Surname</label>
                                <input type="text" name="surname" onChange={e => handleStudentChange(e)} value={studentState.surname} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Surname' />
                                {studentStateError.surname && (<span className="red font-super-small fw-600">{studentStateError.surname}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student's Othernames</label>
                                <input type="text" name="othername" onChange={e => handleStudentChange(e)} value={studentState.othername}  className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Othernames' />
                                {studentStateError.othername && (<span className="red font-super-small fw-600">{studentStateError.othername}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student's Gender</label>
                                <select name="gender" onChange={e => handleStudentChange(e)} value={studentState.gender}  className='font-slightly-small form-control-theme app-text-color'>
                                    <option value={""}>Select Gender</option>
                                    <option value={"M"}>Male</option>
                                    <option value={"F"}>Female</option>
                                </select>
                                {studentStateError.gender && (<span className="red font-super-small fw-600">{studentStateError.gender}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student's Religion</label>
                                <select name="rel" onChange={e => handleStudentChange(e)} className='font-slightly-small form-control-theme app-text-color' value={studentState.rel}>
                                    <option value={""}>Select Religion</option>
                                    <option value={"islam"}>Islam</option>
                                    <option value={"christian"}>Christianity</option>
                                </select>
                                {studentStateError.rel && (<span className="red font-super-small fw-600">{studentStateError.rel}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student Type</label>
                                <select name="enroll_type" onChange={e => handleStudentChange(e)} value={studentState.enroll_type}  className='font-slightly-small form-control-theme app-text-color'>
                                    <option value={""}>Select Student Enroll Type</option>
                                    <option value={"returning"}>Returning</option>
                                    <option value={"new"}>New</option>
                                </select>
                                {studentStateError.enroll_type && (<span className="red font-super-small fw-600">{studentStateError.enroll_type}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student Current Class</label>
                                <select name="curr_class" onChange={e => handleStudentChange(e)} value={studentState.curr_class}  className='font-slightly-small form-control-theme app-text-color'>
                                    <option value={""}>Select Class</option>
                                    {insertClassesOption}
                                </select>
                                {studentStateError.curr_class && (<span className="red font-super-small fw-600">{studentStateError.curr_class}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student School Location</label>
                                <select name="school_branch" onChange={e => handleStudentChange(e)} value={studentState.school_branch}  className='font-slightly-small form-control-theme app-text-color'>
                                    <option value={""}>Select School Branch</option>
                                    {insertSchoolBranchData}
                                </select>
                                {studentStateError.school_branch && (<span className="red font-super-small fw-600">{studentStateError.school_branch}</span>)}
                            </div>
                            <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Student's Sponsorship</label>
                                <select name="sponsored_by" onChange={e => handleStudentChange(e)} value={studentState.sponsored_by}  className='font-slightly-small form-control-theme app-text-color'>
                                    <option value={""}>Select Sponsorer</option>
                                    <option value={"guardian"}>Guardian / Parent</option>
                                    <option value={"self"}>Self Sponsored</option>
                                </select>
                                {studentStateError.sponsored_by && (<span className="red font-super-small fw-600">{studentStateError.sponsored_by}</span>)}
                            </div>
                            {studentState.sponsored_by === "guardian" && (
                                <>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Choose Student Guardian/Parent</label>
                                    <select name="guardian" onChange={e => handleStudentChange(e)} className='font-slightly-small text-capitalize form-control-theme app-text-color' value={studentState.guardian}>
                                        <option value={""}>Select Parent / Guardian</option>
                                        {parentOptions}
                                    </select>
                                    {studentStateError.guardian && (<span className="red font-super-small fw-600">{studentStateError.guardian}</span>)}
                                    <span className='font-very-small'>Can't find guardian's name? <HashLink to="#parent_form" className='blue fw-600'>Add New Parent</HashLink></span>
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Student Guardian Type</label>
                                    <select name="guardian_type" onChange={e => handleStudentChange(e)} className='font-slightly-small form-control-theme app-text-color' value={studentState.guardian_type}>
                                        <option value={""}>Select Guardian Type</option>
                                        {guardianTypeOptions}
                                    </select>
                                    {studentStateError.guardian_type && (<span className="red font-super-small fw-600">{studentStateError.guardian_type}</span>)}
                                </div>
                                </>
                            )}
                            {studentState.sponsored_by === "self" && (
                                <>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Student's Email Address</label>
                                    <input type="text" name="email" onChange={e => handleStudentChange(e)} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Email' value={studentState.email}  />
                                    {studentStateError.email && (<span className="red font-super-small fw-600">{studentStateError.email}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Student's Phone Number</label>
                                    <div className='disp-flex jc-stretch'>
                                        <input type="text" className='form-control disabled app-text-color font-slightly-small' readOnly style={{width:'20%'}} value={"+234"} />
                                        <input type="text" name="phone_number" onChange={e => handleStudentChange(e)} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Phone' value={studentState.phone_number}  />
                                    </div>
                                    {studentStateError.phone_number && (<span className="red font-super-small fw-600">{studentStateError.phone_number}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Student's Address</label>
                                    <textarea name="addr" onChange={e => handleStudentChange(e)} placeholder='Address..' className='font-slightly-small form-control-theme app-text-color' value={studentState.addr}  />
                                    {studentStateError.addr && (<span className="red font-super-small fw-600">{studentStateError.addr}</span>)}
                                </div>
                                    <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                        <label className='font-super-small app-text-color'>Password</label>
                                        <input type="password" onChange={e => handleStudentChange(e)} name="password" className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Password' value={studentState.password}  />
                                        {studentStateError.password && (<span className="red font-super-small fw-600">{studentStateError.password}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Re-Enter Password</label>
                                    <input type="password" name="re_password" onChange={e => handleStudentChange(e)}  className='form-control-theme app-text-color font-slightly-small' placeholder='Re-Enter Password' value={studentState.re_password}  />
                                    {studentStateError.re_password && (<span className="red font-super-small fw-600">{studentStateError.re_password}</span>)}
                                </div>
                                <div className='flex-100 disp-block align-center'>
                                        <Note>Please write down the password somewhere, incase you forget and you try to login later</Note>
                                </div>
                                </>
                            )}
                            
                            <div className='flex-100 disp-block' style={{paddingRight:'6px'}}>
                                <label className='font-super-small app-text-color'>Do you want this student to pay for the current term school fees?</label>
                                <select name="school_fee_payment" onChange={e => handleStudentChange(e)} className='font-slightly-small form-control disabled app-text-color' disabled={true} value={studentState.school_fee_payment} >
                                    <option value={""}>Select...</option>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"no"}>No</option>
                                </select>
                                {studentStateError.school_fee_payment && (<span className="red font-super-small fw-600">{studentStateError.school_fee_payment}</span>)}
                                {/* <Note><span className='font-very-small'>Choosing "No": the guardian/student will not pay for the school fees and be automatically added as paid student, choosing "Yes": the student will be enrolled and payment portal will be prepared for school fees</span></Note> */}
                            </div>
                            
                            <div className='flex-100 disp-block align-center' style={{marginTop:'15px'}}>
                                <button className='mybtn font-slightly-small app-bg-color app-bg-text-color'>Save <i className='fas fa-paper-plane'></i></button>
                            </div>
                        </div>
                        </form>
                        <div className='disp-flex jc-sb' style={{marginTop:'10px'}}>
                            <Link to={`/${school.title}/student`} className='font-very-small'>View All Current Students</Link> 
                            <Link to={`/${school.title}/student/enrolled`} className='font-very-small'>View All Enrolled Students</Link>
                        </div>
                        </Section>
                   </GridContent> 
                    <GridContent header_text={`Add New Parent`} header_icon="fas fa-plus">
                        {loading && <Loader />}
                        <Section id="parent_form">
                            <form onSubmit={e => handleSubmitParent(e)}>
                            <div className='disp-flex wrap'>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Email Address</label>
                                    <input type="email" name="email" onChange={e => handleParentChange(e)} value={parentState.email} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Email' />
                                    {parentStateError.email && (<span className="red font-super-small fw-600">{parentStateError.email}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Title</label>
                                    <input type="text" name="title" onChange={e => handleParentChange(e)} value={parentState.title} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Title e.g Alhaji' />
                                    {parentStateError.title && (<span className="red font-super-small fw-600">{parentStateError.title}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Surname</label>
                                    <input type="text" name="surname" onChange={e => handleParentChange(e)} value={parentState.surname} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Surname' />
                                    {parentStateError.surname && (<span className="red font-super-small fw-600">{parentStateError.surname}</span>)}
                                </div>
                                <div className='flex-30 disp-block'  style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Othernames</label>
                                    <input type="text" name="othername" onChange={e => handleParentChange(e)} value={parentState.othername} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Othernames' />
                                    {parentStateError.othername && (<span className="red font-super-small fw-600">{parentStateError.othername}</span>)}
                                </div>
                                
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Phone Number</label>
                                    <div className='disp-flex jc-stretch'>
                                        <input type="text" className='form-control disabled app-text-color font-slightly-small' disabled style={{width:'20%'}} value={"+234"} />
                                        <input type="text" name="phone_number" onChange={e => handleParentChange(e)} value={parentState.phone_number} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Phone' />
                                    </div>
                                    {parentStateError.phone_number && (<span className="red font-super-small fw-600">{parentStateError.phone_number}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Gender</label>
                                    <select name="gender" className='font-slightly-small form-control-theme app-text-color' onChange={e => handleParentChange(e)} value={parentState.gender}>
                                        <option value={""}>Select Gender</option>
                                        <option value={"M"}>Male</option>
                                        <option value={"F"}>Female</option>
                                    </select>
                                    {parentStateError.gender && (<span className="red font-super-small fw-600">{parentStateError.gender}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Address</label>
                                    <textarea name="addr" className='font-slightly-small form-control-theme app-text-color' placeholder='Address..' onChange={e => handleParentChange(e)} value={parentState.address} />
                                    {parentStateError.addr && (<span className="red font-super-small fw-600">{parentStateError.addr}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Password</label>
                                    <input type="password" name="password" className='form-control-theme app-text-color font-slightly-small' onChange={e => handleParentChange(e)} value={parentState.password} placeholder='Enter Password' />
                                    {parentStateError.password && (<span className="red font-super-small fw-600">{parentStateError.password}</span>)}
                                </div>
                                <div className='flex-30 disp-block' style={{paddingRight:'6px'}}>
                                    <label className='font-super-small app-text-color'>Re-Enter Password</label>
                                    <input type="password" name="re_password" className='form-control-theme app-text-color font-slightly-small' onChange={e => handleParentChange(e)} value={parentState.re_password} placeholder='Re-Enter Password' />
                                    {parentStateError.re_password && (<span className="red font-super-small fw-600">{parentStateError.re_password}</span>)}
                                </div>
                                <div className='flex-100 disp-block align-center'>
                                    <Note>Please write down the password somewhere, incase you forget and you try to login later</Note>
                                </div>
                                <div className='flex-100 disp-block align-center'>
                                    <button className='mybtn font-slightly-small app-bg-color app-bg-text-color'>Save <i className='fas fa-paper-plane'></i></button>
                                </div>
                            </div>
                            </form>
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default AddStudent;