import styled from "styled-components";

export const ComponentWrapper = styled.div`
    width: 100%;
    padding: 15px 12px; 
`;

export const CustomWrapper = styled.div`
    margin: 1rem 6rem;
    @media(max-width: 1280px){
        margin: 1rem 5rem;
    }
    @media(max-width: 1024px){
        margin: 1rem 3.5rem;
    }
    @media(max-width: 780px){
        margin: 1rem 3rem;
    }
    @media(max-width: 540px){
        margin: 1rem 1.5rem;
    }
    @media(max-width: 320px){
        margin: 1rem 1rem;
    }
`;