export const cutItem = (obj={}, location='', is_delete=false) => {
    const lc = location.split('/');
    const loc = lc.length > 0 ? lc : [location]
    const item = obj;
    let ret_item = {}
    if(location){
        if(loc.length){
            switch(loc.length){
                case 1:
                    ret_item = item[loc[0]];
                    is_delete && delete item[loc[0]]
                    break;
                case 2:
                    ret_item = item[loc[0]][loc[1]];
                    is_delete && delete item[loc[0]][loc[1]]
                    break
                case 3:
                    ret_item = [loc[0]][loc[1]][loc[2]];
                    is_delete && delete item[loc[0]][loc[1]][loc[2]]
                    break;
                case 4:
                    ret_item = [loc[0]][loc[1]][loc[2]][loc[3]];
                    is_delete && delete item[loc[0]][loc[1]][loc[2]][loc[3]]
                    break;
                case 5:
                    ret_item = [loc[0]][loc[1]][loc[2]][loc[3]][loc[4]];
                    is_delete && delete item[loc[0]][loc[1]][loc[2]][loc[3]][loc[4]]
                    break;
                default:
                 ret_item = {};
            }
            console.log(ret_item);
            return ret_item;
        }
        return item
    }
    return undefined;
}

export const moveItem = (obj={}, it={}, location="") => {
    const lc = location.split('/');
    const loc = lc.length > 0 ? lc : [location]
    let item = obj;
    if(location){
        if(loc.length){
            switch(loc.length){
                case 1:
                    item[loc[0]] = it 
                    break;
                case 2:
                    item[loc[0]][loc[1]] = it
                    break;
                case 3:
                    item[loc[0]][loc[1]][loc[2]] = it
                    break;
                case 4:
                    item[loc[0]][loc[1]][loc[2]][loc[3]] = it
                    break;
                case 5:
                    item[loc[0]][loc[1]][loc[2]][loc[3]][loc[4]] = it
                    break;
                default:
                    break;
            }
            console.log(item);
            return item
        }
        return item
    }
    return undefined;
}
export const moveKeyLocInObject= (it, curr_loc='', new_loc='', is_delete_existing=false, new_key="") => {
    var datum = it;
    if(typeof it === "object" && Array.isArray(it)){
        for(let i = 0; i < it.length; i++){
            const item = it[i];
            const get_item = cutItem(item, curr_loc, is_delete_existing);
            const move_item = moveItem(item, get_item, new_loc);
            datum.splice(i, 1, {...it[i], ...move_item});
        }
        return datum
    }
}