import styled from "styled-components";

export const Content = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
        padding-bottom: 8px;
        margin-left: 3px;
        float: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        .circle{
            padding: 11px;
            background: #ccc;
            border-radius: 50%;
            display: inline-block;
            animation: circle_anim 1s ease infinite;
            @media(max-width:780px){
                margin-left: 8px;
            }
            ::after{
                width: 100%;
                @keyframes circle_anim{
                    from{
                        background: #bbb;
                    }
                    to{
                        background: #ccc;
                    }
                }
            }
            
        }
        .text{
            background: #ccc;
            display: inline-block;
            margin-left: 5px;
            padding: 1px;
            width: 100%;
            height: 15px;
            border-radius: 2px;
            animation: circle_anim 2s ease infinite;
            ::after{
                width: 100%;
                @keyframes circle_anim{
                    from{
                        background: #bbb;
                    }
                    to{
                        background: #ccc;
                    }
                }
            }
        }
        .h-led{
            @media(max-width:780px){
                display: none;
            }
        }
    }
`;