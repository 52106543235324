import styled from "styled-components";

export const Wrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 10vh;
    width: 100%;
    z-index: 100000000;

    
`;

export const Container = styled.div`
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;
`;

export const Content = styled.div`
    padding: 5px;
    position: relative;

    img{
        width: 80px;
        height: 90px;
        background: var(--appTheme);
        border: none;
        border-radius: 10px;
        animation: imgFade 500ms ease;

        @keyframes imgFade {
            from{
                transform: scale(0);
            }
            to{
                transform: scale(1);
            }
        }
    }

    .close-cont{
        position: absolute;
        right: 10px;
        top: 10px;

        span{
            color: #000000;
            cursor: pointer;
            font-weight: 700;
        }
    }

`;