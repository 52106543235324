import {Image} from "@react-pdf/renderer";

import square_check from "images/square_check.png";
import square_times from "images/square_times.png";
import square from "images/square.png";


export const attendanceIcon = (type="", is_attended=false, is_absent=false, style={}, onClickPresent, onClickAbsent) => {
    if(type === "btn" && is_attended){
        return(
            <button type="button" className="hover-opacity" style={{padding:0, cursor:'pointer'}} onClick={onClickPresent}><img src={square_check} alt="checked" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /></button>
        )
    }
    else if(type === "btn" && is_absent){
        return(
            <button type="button" className="hover-opacity" style={{padding:0, cursor:'pointer'}} onClick={onClickAbsent}><img src={square_times} alt="close" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /></button>
        )
    }
    else if(type === "btn" && !is_absent && !is_attended){
        return(
            <div style={{display:'flex', flexWrap:'wrap', justifyContent:"stretch"}}>
                <button type="button" className="btn hover-opacity" style={{padding:0, marginRight:'4px', cursor:'pointer'}} onClick={onClickPresent}><img src={square_check} alt="checked" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /></button>
                <button type="button" className="btn hover-opacity" style={{padding:0, cursor:'pointer'}} onClick={onClickAbsent}><img src={square_times} alt="close" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /></button>
            </div>
        )
    }
    else if(is_absent){
        return(
            <img src={square_times} alt="close" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /> 
        )
    }
    else if(is_attended){
        return(
            <img src={square_check} alt="checked" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /> 
        )
    }
    else{
        return(
            <img src={square} alt="square" style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /> 
        )
    }
}

export const attendanceIconPdf = (is_attended=false, is_absent=false, style={}) => {
    if(is_absent){
        return(
            <Image src={square_times}  style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /> 
        )
    }
    else if(is_attended){
        return(
            <Image src={square_check}  style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /> 
        )
    }
    else{
        return(
            <Image src={square} style={style ? {width:'13px', height:'13px', ...style} : {width:'13px', height:'13px'}} /> 
        )
    }
}