import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    padding: 2rem;
    width: 100%;
    @media(max-width: 780px){
        padding: 1rem;
    }
`;

export const Content = styled.div`
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @media(max-width: 1024px){
        width: 80%;
    }
    @media(max-width: 780px){
        width: 85%;
    }
    @media(max-width: 320px){
        width: 80%;
    }
    .close-cont{
        position: absolute;
        top: 0;
        right: 0;

        button{
            border: none;
            background: none;
            font-weight: 700;
            letter-spacing: .4px;
            color: #777;
            cursor: pointer;
        }
    }
    div.flex-60{
        flex: 0 0 60%;
        max-width: 59%;
        max-height: 80vh;
        border-radius: 5px;
        background-color: #eee;
        position: relative;
        @media(max-width: 780px){
            flex: 0 0 100%;
            max-height: 65vh;
            max-width: 99%;
        }
        .btn-cont{
            display: flex;
            justify-content: center;
            align-items: center;

            button.br-c{
                border-radius: 50%;
            }
            button.br{
                border: 1px solid var(--appBgColor);
                border-radius: 10px;
            }
            button{
                border: none;
                background: none;
                outline: none;
                padding: 5px 12px;
                transition: .3s ease;
                :hover{
                    color: var(--appBgColor);
                }

                @media(max-width: 1024px){
                    padding: 3px 10px;
                }
            }
        }
        img{
            width: 100%;
            height: 90%;
            max-height: 90%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    div.flex-40{
        flex: 0 0 40%;
        max-width: 39%;
        background-color: #eee;
        border-radius: 5px;
        overflow-y: auto;
        position: relative;
        .sp{
            font-weight: 600;
            padding: 4px;
            display: block;
            color: #111;
        }
        .inner-content{
            padding: 10px 7px;
            display: flex;
            justify-content: stretch;
            flex-wrap: wrap;
            width: 100%;
            .item.sel{
                border: 2px solid var(--appBgColor);
            }
            .item{
                width: 80px;
                height: 80px;
                margin-bottom: 17px;
                margin-right: 10px;
                border: 2px solid #aaa;
                border-radius: 10px;
                transition: .3s ease;
                :hover{
                    border: 2px solid #111;
                }
                @media(max-width: 1280px){
                    width: 70px;
                    height: 70px;
                }
                @media(max-width: 540px){
                    width: 50px;
                    height: 50px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
                span{
                    font-weight: 600;
                    text-align: center;
                    display: block;
                    color: #444;
                }
            }
        }

        @media(max-width: 780px){
            flex: 0 0 100%;
            max-width: 99%;
            margin-top: 10px;
        }
    }
`;