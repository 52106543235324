const arrUnit = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
const arrTens = [{digit:1, name:'teen', is_spaced:false, rtl:true, filter:[{num:10, name:'ten'}, {num:11, name:'eleven'}, {num:12, name:'twelve'}, {num:13, name:'thirteen'}]}, 
    {digit:2, name:'twenty', is_spaced:true, rtl:false, filter:[]}, {digit:3, name:'thirty', rtl:false, is_spaced:true, filter:[]}, {digit:4, rtl:false, name:'forty', is_spaced:true, filter:[]},
    {digit:5, name:'fifty', is_spaced:true,  rtl:false,filter:[]},   {digit:6, name:'sixty', rtl:false, is_spaced:true, filter:[]},  {digit:7, name:'seventy', rtl:false, is_spaced:true, filter:[]},
    {digit:8, name:'eighty', is_spaced:true, rtl:false, filter:[]},  {digit:9, name:'ninety', rtl:false, is_spaced:true, filter:[]}, 
]
export const oneToHundred = (num=0) => {
    if(num){
        if(typeof(parseInt(num)) === "number"){
            let num_normal = parseInt(num);
            if(num.toString().length === 2){
                let unit_num = parseInt(num_normal.toString()[1])
                const filter_item = arrTens.filter((item) => {
                    return item.digit.toString()[0] === num_normal.toString()[0]
                })[0]
                const filter_item_filter = filter_item.filter.filter((item) => {
                    return item.num === num_normal;
                })
                if(filter_item_filter.length > 0){
                    return filter_item_filter[0].name;
                }
                if(filter_item.rtl){
                    return filter_item.is_spaced ? `${arrUnit[unit_num]} ${filter_item.name}` : `${arrUnit[unit_num]}${filter_item.name}`;
                }
                return filter_item.is_spaced ? `${filter_item.name} ${arrUnit[unit_num]}` : `${filter_item.name}${arrUnit[unit_num]}`;
            }
            return arrUnit[num_normal];
        }
        return undefined;
    }
} 