export const getPageSize = (n=0, tot_n=0) => {
    const num = n/tot_n
    const numl = num + 1;
    const numf = parseInt(num.toFixed())
    if(numf < num && numl > num ){
        return numf+1;
    }
    else{
        return numf;
    }
}