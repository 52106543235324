import React from "react";
import { Content } from "./style";

const TD = ({children, style, align="", colspan, font_size='slightly-small', classname, onMouseEnter,onMouseLeave}) => {
    return(
        <Content style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} colSpan={colspan} className={`align-${align} font-${font_size} ${classname}`}>
            {children}
        </Content>
    )
}

export default TD;