import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

const Notification = ({type=null, text="", title="", timer=1000*60 * 2, handleClick, click_text="", link_text="", link, children}) => {
    const [notification, setNotification] = useState(1);
    var class_name;
    var icon;
    var btn_color;
    if(type === "success"){
        class_name = "border-green";
        icon = "fas fa-check-double green font-med";
        btn_color = "btn-br-green font-super-small";
    }
    else if(type === "error"){
        class_name = "border-red";
        icon = "fas fa-times red font-med";
        btn_color = "btn-br-red font-super-small";
    }
    else if(type === "warning"){
        class_name = "border-yellow";
        icon = "fas fa-exclamation-triangle yellow font-med";
        btn_color = "btn-br-yellow font-super-small";
    }
    else{
        class_name = "border-blue";
        icon = "far fa-comment-alt blue font-med";
        btn_color = "btn-br-blue font-super-small";
    }
    
    setTimeout(() => {
        setNotification(0);
    }, timer)
    return(
        
            <AnimatePresence initial={false}>
                {notification && <motion.li className={class_name} 
                positionTransition
                initial={{ opacity: 0, y: 50, scale: 0.3 }}
                animate={{ opacity: 1, y: 0, scale: 1}}
                exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <div className='close-cont'>
                        <i onClick={() => setNotification(0)} className='fas fa-angle-down font-med'></i>
                    </div>
                    {text && (<span className='text font-slightly-small'><i className={icon}></i> {text}</span>)}
                    {title && (<span className='heading font-slightly-small'>{title}</span>)}
                    {children}
                    {click_text && (<div className='btn-cont'><button type="button" className={btn_color} onClick={handleClick}>{click_text}</button></div>)}
                    {link_text && (<div className='btn-cont'><Link className={btn_color} to={link}>{link_text}</Link></div>)}
                </motion.li>}
            </AnimatePresence>
           
    )
}

export default Notification;