import React from 'react';
import BackDrop from 'components/backDrop';
import { Content } from './style';
import voskoolLogo from 'images/voskool_logo.png';


const MenuDrop = ({theme="white", header_text="", header_icon="", handleClose, children}) => {
    const dropIn = {
        hidden: {
            y:'100vh',
            opacity: 0,
            duration: 0.3,
        },
        visible: {
            y:'20vh',
            x:'0vh',
            opacity: 1,
            transition: {
                duration: 0.4,
                
            },
        },

        exit: {
            y:'100vh',
            opacity: 0,
        },
    }
    return(
        <BackDrop>
            <Content variants={dropIn} initial="hidden" animate="visible" exit="exit">
                <div className='container'>
                    <div className='header'>
                        <span className='font-small'>{header_text} <i className={header_icon}></i></span>
                        <button className='btn font-small' onClick={handleClose}>Close</button>
                    </div>
                    <div className='underlay-cont'>
                        <img src={voskoolLogo} alt="Voskool" />
                    </div>
                    {children}
                    
                </div>
            </Content>
        </BackDrop>
    )
}

export default MenuDrop;