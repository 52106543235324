// import axios from "axios";
import { error_messenger } from "./error_handler";
import { verify_user } from "./auth";
// import { BACKEND_URL } from "./url";
const authHandler = {
    login: (login) => {
        return login.then(response => {
            // const config = {
            //     headers:{
            //         'Content-Type':'application/json'
            //     }
            // }
            // const data = { token:response.data.access, refresh_token:response.data.refresh, user:response.data.id}
            // return axios.post(`${BACKEND_URL}/account/auth/user`, JSON.stringify(data), config).then(()=> {
            localStorage.setItem("access", JSON.stringify(response.data));
            return {
                "type":"success",
            "code":response.status,
            "data":response.data,
            "detail":""
            }
            // }).catch(error => {
            //     return { 
            //         "type":"error",
            //         "code":error.response.status,
            //         "detail":error_messenger(error.response.status, "login", error.response.data.detail)
            //     };
            // })
            
        }).catch((error) => {
            if (error.response){
                return { 
                    "type":"error",
                "code":error.response.status,
                "detail":error_messenger(error.response.status, "login", error.response.data.detail)};
            }
            else if(error.request) {
              
               return {"type":"error",
               "code":450,
               "detail":error_messenger(450)};
            } else {
                return {"type":"error",
                "detail":error.message};
            }
            
        })
    },
    load_user: async(load_user) => {
        return await verify_user().then(res => {
            if(res.isAuthenticated){
                return load_user.then(response => {
                    return {
                        "type":"success",
                        "data": response.data,
                        "isAuthenticated":true
                    };
                }).catch(error => {
                    if (error.response){
                        return{ 
                            "type":"error",
                        "code":error.response.status,
                        "detail":error_messenger(error.response.status, "load_user", error.response.data.detail),
                        "isAuthenticated":false};
                    }
                    else if(error.request) {
                        
                        return {"type":"error",
                        "code":450,
                        "detail":error_messenger(450),
                        "isAuthenticated": false};
                    } else {
                        return {"type":"error",
                        "detail":error.message,
                        "isAuthenticated": false
                        };
                    }
                })
            }
            else{
                return {"type":"error",
                    "isAuthenticated": false
                };
            }
        })
       
    }
}

export default authHandler;