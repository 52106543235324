import { loopDateTime } from "./dates";
export const spreadArrayByDatetimeDay = (arr=[], date_arr=[], key='', start=new Date().getTime(), day_count=10) => {
    let loop_date = date_arr.length > 0 ? date_arr : loopDateTime(start, 'day', day_count);
    let data = [];
    for(let i = 0; i < loop_date.length; i++){
        const filter = arr.filter((it) => {
            const time = Math.floor(new Date(it[key]).getTime() / (1000 * 60 * 60 * 24))
            const date_time = Math.floor(new Date(loop_date[i]).getTime() / (1000 * 60 * 60 * 24))
            if(time === date_time){
                return it;
            }
        })
        if(filter.length > 0){
            data.push({time:loop_date[i], arr:filter});
        }
    }
    return data;
}