import React from 'react';
import { Content } from './style';

const Item = ({children, classname, link=false}) => {
    return(
        <Content className={`${link && 'link'} ${classname}`}>
           {children}
        </Content>
    )
}

export default Item;