import React from "react";
import { motion } from "framer-motion";

import voskool_background from "images/voskool_background.png";
import user_icon from "images/user-icon.png";

import { Wrapper, Content } from "./style";
import { CustomWrapper } from "components/app/school/wrapper";

const Profile = ({children, text="", status=false, pp=""}) => {
    return(
        <Wrapper>
            <Content>
                <img className="bg-img" src={voskool_background} alt="Voskool" />
                <div className="inner">
                    <div className="profile">
                        <motion.img initial={{opacity:0}} animate={{opacity:1, transition:{duration: .5}}} className="profile_img" src={pp ? pp : user_icon} alt="User Profile" />
                    </div>
                    <CustomWrapper>
                        <div className="profile_text">
                            <motion.h1 className="font-bigger text-capitalize" initial={{opacity:0}} animate={{opacity:1, transition:{duration: 1.2}}}>{text} <span className={status ? "font-small green" : "font-small red"}><i className="fas fa-circle"></i> {status && (<span className="font-very-small green">(Currently Online)</span>)}</span></motion.h1>
                            {children}
                        </div>
                    </CustomWrapper>
                </div>
            </Content>
        </Wrapper>
    )
}

export default Profile;