import React from 'react';
import { View, Text } from "@react-pdf/renderer";


import { TableStyle, TRStyle, TDStyle, THStyle } from './style';

export const Table = ({children, bordered=false, wrap=true}) => {
    return(
        <View style={bordered ? {...TableStyle.table, ...TableStyle.bordered} : {...TableStyle.table}} wrap={wrap}>
            {children}
        </View>
    )
}

export const TR = ({children, bordered=false, wrap=false}) => {
    return(
        <View style={bordered ? {...TRStyle.tr, ...TRStyle.bordered} : TRStyle.tr} wrap={wrap}>
            {children}
        </View>
    )
}

export const TD = ({children, bordered=false, width="20%", fontSize="11px", padding="3px", align="left", textOnly=false, wrap=false, style}) => {
    return(
        <View wrap={wrap} style={bordered ? {...TDStyle.td, ...TDStyle.bordered, ...style, width:width, padding:padding,  textAlign:align, } : {...TDStyle.td, ...style, width:width, padding:padding, textAlign:align}}>
            {textOnly ? <Text style={{...TDStyle.text, fontSize:fontSize}}>{children}</Text> : children }
        </View>
    )
}

export const TH = ({children, bordered=false, wrap=false, width="20%", fontSize="13px", padding="4px", align="left", textOnly=false, style}) => {
    return(
        <View wrap={wrap} style={bordered ? {...THStyle.th,  ...THStyle.bordered, ...style, width:width, padding:padding, textAlign:align} : {...THStyle.th, ...style, width:width, padding:padding, textAlign:align}}>
            {textOnly ? <Text style={{...THStyle.text, fontSize:fontSize}}>{children}</Text> : children }
        </View>
    )
}