import styled from "styled-components";

export const OuterWrapper = styled.div`
    width:100%;
    position: relative;
    .min-h-50px{
        min-height: 50px;
    }
    .img-cont{
        img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }
    .min-h-30px{
        min-height: 25px;
    }
    .min-h-10vh{
        min-height: 10vh;
    }
    .min-h-15vh{
        min-height: 14.6vh;
    }
    .min-h-16vh{
        min-height: 16vh;
    }
    .min-h-20vh{
        min-height: 20vh;
    }
    .min-h-100px{
        min-height: 100px;
    }
    .min-h-120px{
        min-height: 125px;
    }
    .min-h-150px{
        min-height: 150px;
    }
    .y-50px{
        transform: translateY(-50px);
    }
    .y-10px{
        transform: translateY(-10px);
    }
    .b-r-b-l-20px{
        border-bottom-left-radius: 30px;
    }
    .b-r-b-r-20px{
        border-bottom-right-radius: 30px;
    }
    .shadow{
        box-shadow: 0 0 3px 4px rgba(0,0,0,0.02);
    }
`;
export const Wrapper = styled.div`
    padding: 5px 10px;
    width: 100%;
`;