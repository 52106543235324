import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    position: relative;

        .flex-5{
            flex: 0 0 5%;
            max-width: 5%;
        }
        .flex-15{
            flex: 0 0 15%;
            max-width: 15%;
        }
        .flex-20{
            flex: 0 0 20%;
            max-width: 20%;
        }
        .flex-30{
            flex: 0 0 30%;
            max-width: 30%;
        }
        .flex-50{
            flex: 0 0 50%;
            max-width: 50%;
            @media(max-width:320px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-60{
            flex: 0 0 60%;
            max-width: 60%;
        }
        .flex-85{
            flex: 0 0 85%;
            max-width: 85%;
        }
        .flex-100{
            flex: 0 0 100%;
            max-width: 100%;
        }
`;

export const ListContent = styled.div`
    padding: 0 2px 0 2px;
    transition: 0.3s ease;
    border-bottom: 1px solid #999;
    
    :hover{
        opacity: .8;
        .circle_{
            display: inline-block;
        }
    }
    .opacity{
        opacity: .8;
    }
    .content{
        margin-bottom: 2px;
        cursor: default;
    }
    .cont{
        padding-right: 5px;
    }
    .circle_{
        color: #777;
        display: none;
        transition: 0.2s ease;
    }
`;