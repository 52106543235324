import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useNavigate, Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import TD from 'components/app/school/SmallerComponents/table_html/td';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { gameServices } from 'api/schoolApp/services';

import { addItemToArray} from "components/app/school/actions/array-utils/Add";  
import { updateArray } from "components/app/school/actions/array-utils/Update";
import Mallet from "components/app/school/SmallerComponents/mallet";

const GameGoQuiz = ({user_id, user_uid, user_type, user, school, school_id, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [playerModeState, setPlayerModeState] = useState({});

    const nav = useNavigate();

    const [allSubject, setAllSubject] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);

    const fetchAllSubject = (auth_id) => {
        gameServices.getAllSubject(auth_id).then(res => {
            setAllSubject(addItemToArray(res.data, {is_click_go:false}));
        }).catch(e => {
            setError({title:'Data Error', data:['Internal Server Error']})
        })
    }

    const onClickGo = (num=-1, is_clicked=false) => {
        setAllSubject((prev) => { return updateArray(prev, num, {...prev[num], is_click_go:is_clicked})});
    }
    const underConstruction = () => {
        setError({title:'Error', data:['Currently under construction']});
    }

    useEffect(() => {
        if(reload && access.id){
            fetchAllSubject(access.uid);
            setReload(false);
        }
    }, [access, reload]);

    console.log(allSubject)

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    return(
        <>
        <HeadComponent title="Go-Quiz Game" />
        <BreadCrumb />
        {preloading && <PreloaderLine />}
        <ComponentWrapper>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
                <Grid layout="lauto">
                    <GridContent header_text={`Ranking`} header_icon="fas fa-one">
                    </GridContent>
                </Grid>
                <Grid layout='l31'>
                    <GridContent header_text={`Choose a subject and start quiz`} header_icon="fas fa-one">
                    {loading && <Loader />}
                    {preloading && (
                        <Section>
                            <Mallet>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Go-Grade 1 (Primary 1 - 3)</span>
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <TABLE>
                                        <tbody>
                                            {allSubject.filter((it) => it.grade === 1).map((item, i) => (
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD style={{width:'80%'}}><span className="font-slightly-small fw-500 disp-block app-text-color">{item.name} </span></TD>
                                                        <TD><span className="font-med fw-600 disp-block" style={{color:'rgba(25, 214, 40, 0.8)'}}> +{item.xp}xp </span></TD>
                                                        <TD>
                                                            {item.is_click_go ? (
                                                                <>
                                                                <div className="disp-block padd-4px align-center">
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> <i className="fas fa-arrow-left"></i> Back </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Singleplayer <i className="fas fa-user"></i> </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small  app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Multiplayer <i className="fas fa-users"></i> </button>
                                                                </div>
                                                                
                                                                </>
                                                            ) : (
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> Go </button>
                                                            )}
                                                            
                                                        </TD>
                                                    </TR>
                                                </Fragment>
                                            ))}
                                            
                                        </tbody>
                                    </TABLE>              
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Go-Grade 2 (Primary 4 - 6)</span>
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <TABLE>
                                        <tbody>
                                            {allSubject.filter((it) => it.grade === 2).map((item, i) => (
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD style={{width:'80%'}}><span className="font-slightly-small fw-500 disp-block app-text-color">{item.name} </span></TD>
                                                        <TD><span className="font-med fw-600 disp-block" style={{color:'rgba(25, 214, 40, 0.8)'}}> +{item.xp}xp </span></TD>
                                                        <TD>{item.is_click_go ? (
                                                                <>
                                                                <div className="disp-block padd-4px align-center">
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> <i className="fas fa-arrow-left"></i> Back </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Singleplayer <i className="fas fa-user"></i> </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small  app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Multiplayer <i className="fas fa-users"></i> </button>
                                                                </div>
                                                                
                                                                </>
                                                            ) : (
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> Go </button>
                                                            )}</TD>
                                                    </TR>
                                                </Fragment>
                                            ))}
                                            
                                        </tbody>
                                    </TABLE>              
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Go-Grade 3 (JSS 1 - 3)</span>
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <TABLE>
                                        <tbody>
                                            {allSubject.filter((it) => it.grade === 3).map((item, i) => (
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD style={{width:'80%'}}><span className="font-slightly-small fw-500 disp-block app-text-color">{item.name} </span></TD>
                                                        <TD><span className="font-med fw-600 disp-block" style={{color:'rgba(25, 214, 40, 0.8)'}}> +{item.xp}xp </span></TD>
                                                        <TD>{item.is_click_go ? (
                                                                <>
                                                                <div className="disp-block padd-4px align-center">
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> <i className="fas fa-arrow-left"></i> Back </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Singleplayer <i className="fas fa-user"></i> </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small  app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Multiplayer <i className="fas fa-users"></i> </button>
                                                                </div>
                                                                
                                                                </>
                                                            ) : (
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> Go </button>
                                                            )}</TD>
                                                    </TR>
                                                </Fragment>
                                            ))}
                                            
                                        </tbody>
                                    </TABLE>              
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Go-Grade 4 (SS 1 - 3)</span>
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <TABLE>
                                        <tbody>
                                            {allSubject.filter((it) => it.grade === 4).map((item, i) => (
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD style={{width:'80%'}}><span className="font-slightly-small fw-500 disp-block app-text-color">{item.name} </span></TD>
                                                        <TD><span className="font-med fw-600 disp-block" style={{color:'rgba(25, 214, 40, 0.8)'}}> +{item.xp}xp </span></TD>
                                                        <TD>{item.is_click_go ? (
                                                                <>
                                                                <div className="disp-block padd-4px align-center">
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> <i className="fas fa-arrow-left"></i> Back </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Singleplayer <i className="fas fa-user"></i> </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small  app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Multiplayer <i className="fas fa-users"></i> </button>
                                                                </div>
                                                                
                                                                </>
                                                            ) : (
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> Go </button>
                                                            )}</TD>
                                                    </TR>
                                                </Fragment>
                                            ))}
                                            
                                        </tbody>
                                    </TABLE>              
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Go-Grade 5 (Master)</span>
                                </div>
                                <div className='flex' style={{paddingBottom:'2px'}}>
                                    <TABLE>
                                        <tbody>
                                            {allSubject.filter((it) => it.grade === 5).map((item, i) => (
                                                <Fragment key={i}>
                                                    <TR>
                                                        <TD style={{width:'80%'}}><span className="font-slightly-small fw-500 disp-block app-text-color">{item.name} </span></TD>
                                                        <TD><span className="font-med fw-600 disp-block" style={{color:'rgba(25, 214, 40, 0.8)'}}> +{item.xp}xp </span></TD>
                                                        <TD>{item.is_click_go ? (
                                                                <>
                                                                <div className="disp-block padd-4px align-center">
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> <i className="fas fa-arrow-left"></i> Back </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Singleplayer <i className="fas fa-user"></i> </button>
                                                                </div>
                                                                <div className="disp-block padd-4px">
                                                                    <button className="font-super-small  app-opp-text-color app-opp-theme br btn hover-opacity" onClick={underConstruction}> Multiplayer <i className="fas fa-users"></i> </button>
                                                                </div>
                                                                
                                                                </>
                                                            ) : (
                                                                <button className="font-very-small app-bg-color app-bg-text-color br btn hover-opacity" onClick={() => onClickGo(item.num, !item.is_click_go)}> Go </button>
                                                            )}</TD>
                                                    </TR>
                                                </Fragment>
                                            ))}
                                            
                                        </tbody>
                                    </TABLE>              
                                </div>
                            </Mallet>
                        </Section>
                    )}
                    </GridContent>
                    <GridContent header_text={`Game Level Ranking`} header_icon="fas fa-one">
                        {/* <Mallet> */}
                            <div className='flex' style={{paddingBottom:'2px'}}>
                                <div className="disp-block">
                                    <TABLE>
                                        <thead>
                                            <TR>
                                                <TH>Rank</TH>
                                                <TH>Xp Points</TH>
                                            </TR>
                                        </thead>
                                        <tbody>
                                            <TR>
                                                <TD><span className="font-slightly-small fw-500 disp-block padd-4px app-text-color">Rookie </span></TD>
                                                <TD><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color"> 1 - 1,000 xp </span></TD>
                                            </TR>
                                            <TR>
                                                <TD><span className="font-slightly-small fw-500 disp-block padd-4px app-text-color">Elite </span></TD>
                                                <TD><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color">1001 - 2000 xp </span></TD>
                                            </TR>
                                            <TR>
                                                <TD><span className="font-slightly-small fw-500 disp-block padd-4px app-text-color">Veteran </span></TD>
                                                <TD><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color">2001 - 3500xp </span></TD>
                                            </TR>
                                            <TR>
                                                <TD><span className="font-slightly-small fw-500 disp-block padd-4px app-text-color">Pro </span></TD>
                                                <TD><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color">3501 - 6500xp </span></TD>
                                            </TR>
                                            <TR>
                                                <TD><span className="font-slightly-small fw-500 disp-block padd-4px app-text-color">Master </span></TD>
                                                <TD><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color">6501 - 10500xp </span></TD>
                                            </TR>
                                            <TR>
                                                <TD><span className="font-slightly-small fw-500 disp-block padd-4px app-text-color">Legendary </span></TD>
                                                <TD><span className="font-slightly-small fw-600 disp-block padd-4px app-text-color">10500xp+ </span></TD>
                                            </TR>
                                        </tbody>
                                    </TABLE>
                                    
                                </div>
                                
                            </div>
                        {/* </Mallet> */}
                    </GridContent>
                </Grid>
        </ComponentWrapper>
        </>
    )
}

export default GameGoQuiz;