import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border: 1px solid #eee;
`;

export const Content = styled.div`
    margin: 5px 10px;
    
`;

export const Img = styled.img`
    max-width:100px;
    max-height: 100px;
    
`;