import React, { useState, useEffect, useMemo} from 'react';

import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import View from 'components/app/school/SmallerComponents/mallet/view';

import { convertNumber } from 'components/app/school/actions/money';


const StaffProfileContent = ({data, OnClickNationalIdImg, OnClickCertImg}) => {
    const initialState = useMemo(() => ({id:0, email:'', school_uid:'', staff:{staff:{id:0, gender:''}, staff_cert_type:{id:0}, staff_type:{id:0}, school:{id:0}, school_branch:{name:'', id:0},}, session_added:{id:0, term:{id:0}}}), []);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState(data);
    }, [data])

    return(
        <Section>
            <span className="font-med fw-700 disp-block app-text-color">About Staff</span><br />
            <span className="font-small fw-700 disp-block padd-4px app-text-color">Staff Information</span>
            <Mallet>
                <View flex='50' type="text" label="School ID">{state.school_uid}</View>
                <View flex='50' type="text" label="Staff Type" classname='text-capitalize'>{state.staff.staff_type.name}</View>
                {state.staff.staff_type.name === "teacher" && (
                    <>
                    <View flex='50' type="text" label="Class Assigned">{state.class_assigned}</View>
                    <View flex='50' type="text" label="Subject Assigned">{state.subject_assigned}</View>
                    </>
                )}
                <View flex='50' type="text" label="Certificate Type">{state.staff.staff_cert_type.cert_type}</View>
                <View flex='50' type="text" label="Salary">{state.curr_symbol}{convertNumber(state.staff.salary)}</View>
                <View flex='50' type="text" label="Branch Applied">{state.staff.school_branch.name}</View>
                <View flex='50' type="text" label="Session Joined" classname='text-capitalize'>{state.session_added.session} - {state.session_added.term.term} Term</View>
                <View flex='100' type="text" label="Date Joined">{state.created}</View>
            </Mallet><br />

            <span className="font-small fw-700 disp-block padd-4px app-text-color">Personal Information</span>
            <Mallet>
                <View flex='50' type="text" label="Gender">{state.staff.staff.gender}</View>
                <View flex='50' type="text" label="Telephone No.">+{state.phone}</View>
                <View flex='50' type="text" label="Email">{state.email.length > 15 ? `${state.email.slice(0, 15)}..` : state.email}</View>
                <View flex='50' type="text" label="D.O.B">{state.staff.staff.dob}</View>
                <View flex='50' type="text" label="Marital Status"classname='text-capitalize'>{state.staff.staff.marital_status}</View>
                <View flex='50' type="text" label="Language Spoken"classname='text-capitalize'>{state.staff.staff.lang_spoken}</View>
                <View flex='50' type="text" label="Religion"classname='text-capitalize'>{state.staff.staff.rel}</View>
                <View flex='50' type="text" label="Address">{state.staff.staff.address}</View>
                <View flex='50' type="text" label="Experience">{state.staff.staff.experience}</View>
                <View flex='50' type="text" label="Certificate"classname='text-capitalize'>{state.staff.staff.cert_type}</View>
                <View flex='50' type="img" label="National ID" onClickImg={OnClickNationalIdImg}>Click to view this image</View>
                <View flex='50' type="img" label="Certificate" onClickImg={OnClickCertImg}>Click to view this image</View>
            </Mallet>
            <br />

            <span className="font-small fw-700 disp-block padd-4px app-text-color">Next Of Kin's Information</span>
            <Mallet>
                <View flex='50' type="text" label="Next Of Kin">{state.kin_type}</View>
                <View flex='50' type="text" label="Fullname">{state.staff.staff.nok_name}</View>
                <View flex='50' type="text" label="Email">{state.staff.staff.nok_email}</View>
                <View flex='50' type="text" label="Telephone No.">{state.staff.staff.nok_phone}</View>
                <View flex='50' type="text" label="Address">{state.staff.staff.nok_address}</View>
            </Mallet>
        </Section>
    )
}

export default StaffProfileContent;