import React from "react";

import { OuterWrapper, ImgWrapper, Wrapper, Content } from "./style";

export const ProfileUIWrapper = ({children}) => {
    return(
        <OuterWrapper>
            {children}
        </OuterWrapper>
    )
} 

export const ProfileUIWrapper2 = ({children}) => {
    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
} 

export const ProfileUIImage = ({src="", title="", title2="", desc="", is_chat=false, onClickChat}) => {
    return(
        <ImgWrapper className="align-center">
            <img src={src} alt={title} />
            <div className="disp-block padd-4px">
                <span className="font-slightly-small app-text-color fw-600">{title}</span>
                {title2 && (
                    <div className="disp-inline padd-4px">
                        <span className="font-very-small app-text-color fw-600" style={{color:'#222'}}>{title2}</span>
                    </div>
                )}
                
                <div className="disp-inline">
                    <span className="font-very-small app-text-color fw-500" style={{color:'#777'}}>{desc}</span>
                </div>
            </div>
            <div className="disp-flex  wrap icon-wrapper">
                {is_chat && (
                    <div className="disp-block">
                        <div className="disp-flex wrap jc-stretch">
                            <div className="icon-cont disp-block" onClick={onClickChat}>
                                <div className="icon disp-block">
                                    <i className="fas fa-comment font-big" style={{color:'var(--appBgColor)'}}></i>
                                </div>
                                <div className="text disp-block">
                                    <span className="font-super-small fw-600 app-text-color">Chat</span>
                                </div>
                            </div>
                        </div>
                    </div>     
                )}
                
            </div>
        </ImgWrapper>
    )
}

const ProfileUI = ({classname="app-text-color", onClick, icon='', title=""}) => {
    return(
        <Content>
            <div className="disp-block icon-cont padd-r-10px">
                <span className={`${icon} font-slightly-small ${classname}`}></span>
            </div>
            <div className="disp-block text-cont padd-r-10px">
                <span className={`font-very-small fw-600 ${classname}`}>{title}</span>
            </div>
        </Content>
    )
}

export default ProfileUI;