export const spread_attendance_days = (arr=[], day_len=1) => {
    let array = [];
    const arr_handler = (num=0, a=[], day_len=1) => {
        let dt = [];
        let num_day_len = num + day_len;
        for(let j=parseInt(num); j < a.length; j++){
            if(j >= num_day_len){
                break;
            }
            dt.push({...a[j]});
        }
        return dt;
    }
    for(let i = 0; i < arr.length; i = i + day_len){
        array.push(arr_handler(i, arr, day_len));
    }
    return array;
}
export const spread_attendance = (array=[], days_len=0) => {
    let arr= [];
    for(let i = 0; i < array.length; i = i + days_len){
        arr.push({...array[i], i:i});
    }
    return spread_attendance_days(arr, array, days_len)
}