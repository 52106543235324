import React from "react";
import { Link } from 'react-router-dom';
import { Content } from "./style";

const Menu = ({bgColor, text, position, tooltip, i, size="small-size", customStyle, a, children, contData, onClick}) => {
    return(
        <Content>
            {i && (<i onClick={onClick} className={`${i}`}>{contData}</i>)}
            {a && (<Link to={`${a}`}><i className={`${i}`}>{contData}</i></Link>)}         
            <div className={`menu ${bgColor} ${position} ${tooltip} ${size}`} style={customStyle}>

                <p>{text}</p>
            </div>
            {children}
        </Content>
    )
}

export default Menu;