import React, { useState, useEffect, Fragment } from "react";
import { Page, View, Image, Text, Font } from "@react-pdf/renderer";
import TextInput from 'components/app/school/SmallerComponents/pdf/textInput';
import NumberShadeBox from 'components/app/school/SmallerComponents/pdf/numberShadeBox';

import { pdfStyle } from "./style";

import voskool_logo from "images/voskool_logo.png";

import { numberDigit } from 'components/app/school/actions/numberDigit';

import raleway_regular from 'font/raleway.regular.ttf';
import raleway_semibold from 'font/raleway.semibold.ttf';
import raleway_bold from 'font/raleway.bold.ttf';
import raleway_extrabold from 'font/raleway.extrabold.ttf';

import { BACKEND_URL } from 'actions/url';

const OMRGenerator = ({school_id, schoolColor, school_title, school_icon, school_name, data=[] }) => {
    const [state, setState] = useState([{stud_name:'John Doe', subj_name:'Christian Religious Studies', subj_code:'CRK', paper_code:'P-10', subj_id:0, exam_no:1, class:'JSS3', school_branch_name:'Alafin, Royal Street Command'}
    ]);
    
    useEffect(() => {
        if(data.length){
            setState(data);
        }
    }, [data]);
    Font.register({family:'Raleway', fonts:[{src:`${raleway_regular}`}, {src:raleway_semibold, fontWeight:600} , {src:raleway_bold, fontWeight:700} , {src:raleway_extrabold, fontWeight:800}]});
    const sch_num = numberDigit(school_id, 4);
    const reg_no = numberDigit(0, 4);
    const class_no = numberDigit(0, 4);
    
    const exam_num = sch_num+reg_no+class_no;
    const subject_code = numberDigit(1, 3);

    const examNumberBoxData = [...exam_num].map((item, i) => {
        return(
            <View  key={i}>
                <NumberShadeBox key={i} color={schoolColor} num={parseFloat(item)} />
            </View>
        )
    })
    const subjectCodeData = [...subject_code].map((item, i) => {
        return(
            <View  key={i}>
                <NumberShadeBox key={i} color={schoolColor} num={parseFloat(item)} />
            </View>
        )
    })
    const insertObjBgData = () => {
        let data = [];
        for(let i = 0; i < 135; i++){
            data.push(
                <View key={i} style={pdfStyle(schoolColor).obj_bg}>
                    <Text style={pdfStyle(schoolColor).obj}>LOOSKOOVVOSKOOLLOOSKOOVVOSKOOLLOOSKOOVVOSKOOLLOOSKOOVVOSKOOLVOSKOOLLOOSKOOVVOSKOOL</Text>
                </View>
            )
        }
        return data;
    }
    const insertLetterBoxData = (start_num=0, end_num=100) => {
        let data = [];
        for(let i = start_num; i < end_num; i++){
            const n = i + 1;
            data.push(
                <View key={i} style={{...pdfStyle(schoolColor).inner_cont_sb, marginBottom:'2px'}}>
                    <Text style={pdfStyle(schoolColor).box_letter_header}>{n}.</Text>
                    <Text style={pdfStyle(schoolColor).box_letter_text}>[A]</Text>
                    <Text style={pdfStyle(schoolColor).box_letter_text}>[B]</Text>
                    <Text style={pdfStyle(schoolColor).box_letter_text}>[C]</Text>
                    <Text style={pdfStyle(schoolColor).box_letter_text}>[D]</Text>
                    <Text style={pdfStyle(schoolColor).box_letter_text}>[E]</Text>
                </View>
            )
        }
        return data;
    }
    const part = (part=0, num=0) => {
        var i = num;
        const tot = state.length-1;
        console.log(i+1)
        if(i <= tot){
            return(
                <View key={i} style={pdfStyle(schoolColor).section_half}>
                    <View style={pdfStyle(schoolColor).cont_obj_bg}>
                        {insertObjBgData()}
                    </View>
                    <View style={pdfStyle(schoolColor).heading_container}>
                        <Image src={`${BACKEND_URL}${school_icon}`} style={pdfStyle(schoolColor).heading_logo} />
                        <Text style={pdfStyle(schoolColor).heading_text}>{school_name} ({school_title.toUpperCase()})
                            <Text style={pdfStyle(schoolColor).heading_text_}> OMR Sheet</Text>
                        </Text>
                    </View>
                    <View style={pdfStyle(schoolColor).cont_voskool_logo}>
                        <Image src={voskool_logo} style={pdfStyle(schoolColor).voskool_logo} />
                    </View>
                    <View style={pdfStyle(schoolColor).cont}>
                        <View style={pdfStyle(schoolColor).inner_cont_form}>    
                            <TextInput borderColor={schoolColor} label="Student's Name">{state[i].stud_name}</TextInput>
                            <TextInput borderColor={schoolColor} label="Subject Name">{state[i].subj_name} ({state[i].subj_code})</TextInput>
                            <View style={{display:'flex', flexDirection:'row'}}>
                                <View style={{width:'49%'}}>
                                    <TextInput borderColor={schoolColor} label="Class">{state[i].class}</TextInput>
                                </View>
                                <View style={{width:'50%'}}>
                                    <TextInput borderColor={schoolColor} label="Paper Code">{state[i].paper_code}</TextInput>
                                </View>
                            </View>
                            <TextInput borderColor={schoolColor} label="School Branch">{state[i].school_branch_name}</TextInput>
                        </View>
                        <View style={pdfStyle(schoolColor).inner_cont_other}>
                            <Image src={`${BACKEND_URL}${school_icon}`} style={{...pdfStyle(schoolColor).heading_logo, alignSelf:'center'}} />
                            <Image src={`${BACKEND_URL}${school_icon}`} style={{...pdfStyle(schoolColor).heading_logo, alignSelf:'center'}} />
                        </View>
                    </View>
                    <View style={pdfStyle(schoolColor).inner_cont_sb}>
                        <View style={{...pdfStyle(schoolColor).box_cont}}>
                            <Text style={pdfStyle(schoolColor).box_label}>Exam Number</Text>
                            {examNumberBoxData}
                        </View>
                        <View style={{...pdfStyle(schoolColor).box_cont, paddingLeft:'15px',  paddingRight:'15px'}}>
                            <Text style={{...pdfStyle(schoolColor).box_label, width:'100px'}}>Subject Code</Text>
                            {subjectCodeData}
                        </View>
                    </View>
                    <View style={pdfStyle(schoolColor).cont}>
                        <Text style={pdfStyle(schoolColor).cont_header_center}>Shade With A 2B Pencil, Using The Shaded Options Above As Reference </Text>
                    </View>
                    <View style={pdfStyle(schoolColor).cont}>
                        <View style={{...pdfStyle(schoolColor).inner_cont_25, borderWidth:'1px', borderColor:schoolColor}}>
                            {insertLetterBoxData(0, 30)}
                        </View>
                        <View style={{...pdfStyle(schoolColor).inner_cont_25, borderWidth:'1px', borderColor:schoolColor}}>
                            {insertLetterBoxData(30, 60)}
                        </View>
                        <View style={{...pdfStyle(schoolColor).inner_cont_25, borderWidth:'1px', borderColor:schoolColor}}>
                            {insertLetterBoxData(60, 90)}
                        </View>
                        <View style={{...pdfStyle(schoolColor).inner_cont_25, borderWidth:'1px', borderColor:schoolColor}}>
                            {insertLetterBoxData(90, 100)}
                        </View>
                    </View>
                </View>
            )
        }
        else{
            return(
                <></>
            )
        }
        
  
    }
    
    const func = () => {
        let data = [];
        const stateD2 = Math.round(state.length / 2);
        for(let i =0; i < stateD2; i++){       
            data.push(
                <Fragment key={i}>
                    <View key={i}>
                        <View break={i > 0 ? true : false} key={i}  wrap  style={pdfStyle(schoolColor).page}>
                            {part(0, i > 0 ? i+1 : i)}
                            <View style={pdfStyle(schoolColor).section_6}>
                        
                            </View>
                            {part(1, i > 0 ? i + 2 : i+1)}
                        </View>
                    </View>
                </Fragment>
                
            )
            
        }
        return data;
    }
    return (
        <>
            <Page size={"A4"} wrap>
                {func()}
            </Page>
        
        </>
    )
}

export default OMRGenerator;