export const getFormData = (data = {}) => Object.keys(data).reduce((formData, key) => {
    if(typeof data[key] === "object" && data[key] !== null && !Array.isArray(data[key]) && data[key].name){
        formData.append(key, data[key], data[key].name)
    }
    else if(Array.isArray(data[key])){
        data[key].forEach((value) => {
            formData.append(`${key}[]`, typeof value === "object" ? getFormData(value) : value);
        })
    } else if(data[key]) {
        formData.append(key, data[key])
    }
    return formData;
}, new FormData());