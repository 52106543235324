import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    width: 100%;

    .focus{
            box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
    }
`;

export const Content = styled.div`
    border: 1px solid #aaa;
    padding: 5px;
    width: 100%;
    position: relative;
    transition: .3s ease;
    border-radius: 5px;
    background: var(--appTheme);

    .icon-place{
        position: absolute;
        right: 3px;
        bottom: 20%;
        font-size: 1rem;
        
    }
    .sel-inp{
        width: 90%;
        border: none;
        outline: none;
        background: none;
    }
`;

export const InnerWrapper = styled.div`
    width: 100%;
    max-height: 150px;
    position: absolute;
    left: 0;
    margin-top: 5px;
    z-index: 10;
    background: var(--appTheme);
    border-radius: 5px;
    overflow-y: auto;
    padding: 5px;
    @media(max-width:540px){}
`;