import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
    .small-size{
        width: 150px;
        height: 35vh;
        @media(max-width: 780px){
            width: 120px;
            height: 30vh;
        }
        @media(max-width: 375px){
            width: 110px;
        }
        @media(max-width: 285px){
            width: 100px;
        }
    }
    .med-size{
        width: 250px;
        height: 55vh;
        @media(max-width: 780px){
            width: 200px;
        }
        @media(max-width: 320px){
            width: 150px;
        }
    }
    .big-size{
        width: 400px;
        height: 90vh;
        @media(max-width: 780px){
            width: 300px;
        }
        @media(max-width: 320px){
            width: 250px;
        }
        @media(max-width: 280px){
            width: 230px;
        }
    }
    .big-size.end{
        right: 0%;
    }
    .med-size.end{
        right: 0%;
    }
    
    .small-size.end{
        right: 0%;
    }
    .back-drop{
        position: absolute;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        border-radius: 6px;
        box-shadow: 1px 1px 3px 3px rgba(0,0,0,0.1);
        .close-icon{
            position: absolute;
            top: -3%;
            right: 0%; 

            @media(max-width: 1280px){
                top: -1%;
            } 
        }
    }
`;