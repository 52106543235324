import React from "react";
import { Content } from "./style";
import Menu from "components/menu";

const Tr = ({handleClick, text_style="c-data", style, styleText, animation={initial:{opacity:0}, animate:{opacity:1}, exit:null},  sn=false, header=false, striped="striped", handleCheckClick, handleTimesClick, handleAddClick, handleEditClick, handlePayClick, handleViewClick, handleActivateClick, handleDoneClick, handleInactivateClick, handleDeleteClick, handleUpgradeClick, dlt_text="Delete", upgrade_text="Upgrade", check_text="Ok", text=[], btn=true, check_btn=false, pay_btn=false, times_btn=false, add_btn=false, edit_btn=true, view_btn=false, chat_btn=false, active_btn=false, inactive_btn=false, dlt_btn=true, upgrade_btn=false, done_btn=false, children}) => {
    const textData = text.map((item, index) => {
        return(
            <div style={item.style} key={index} className={sn && index===0 ? `${text_style} font-very-small sn ${item.class}`: `${text_style} ${item.class} font-very-small`}><span style={styleText}>{item.text}</span></div>
        )
    })
    return(
        <>
           
           
            <Content style={style} initial={animation.initial} animate={animation.animate} exit={animation.exit} onClick={handleClick} className={header ? `th ${striped}` : `${striped}`}>
                
                {textData}
                {children}
                {btn && (<div  className="btn-wrapper">
                    {check_btn && (<Menu text={check_text} tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleCheckClick} className="b-green"><span className="fas fa-check font-very-small "></span></button></Menu>)}
                    {times_btn && (<Menu text='Cancel' tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleTimesClick} className="br-red"><span className="fas fa-times font-very-small "></span></button></Menu>)}
                    {add_btn && (<Menu text='Add' tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleAddClick} className="edit_btn"><span className="fas fa-plus font-super-small "></span></button></Menu>)}
                    {pay_btn && (<Menu text='Pay' tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handlePayClick} className="edit_btn"><span className="fas fa-coins font-super-small "></span></button></Menu>)}
                    {edit_btn && (<Menu text='Edit' tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleEditClick} className="edit_btn"><span className="fas fa-pen font-super-small "></span></button></Menu>)}
                    {upgrade_btn && (<Menu text={upgrade_text} tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleUpgradeClick} className="edit_btn"><span className="fas fa-level-up-alt font-very-small "></span></button></Menu>)}
                    {done_btn && (<Menu text='Done' tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleDoneClick} className="b-green"><span className="font-super-small "><i className="fas fa-check"></i><i className="fas fa-check font-very-super-small"></i></span></button></Menu>)}
                    {view_btn && (<Menu text='View' tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleViewClick} className="view_btn"><span className="fas fa-eye font-super-small "></span></button></Menu>)}
                    {active_btn && (<button type="button" onClick={handleInactivateClick} className="b-green font-super-small">Active</button>)}
                    {inactive_btn && (<button type="button" onClick={handleActivateClick} className="dlt_btn font-super-small">Inactive</button>)}
                    {dlt_btn && (<Menu text={dlt_text} tooltip="bottom-tooltip" position="top-menu" bgColor="app-bg-color" size="very-small-size"><button type="button" onClick={handleDeleteClick} className="dlt_btn"><span className="fas fa-trash font-super-small "></span></button></Menu>)}
                </div>) }
                
            
            </Content>
            

        </>    
    )
}

export default Tr;