export const formatNumber = (mon='0', type='en') => {
    const formatter = Intl.NumberFormat(type, {

    })
    const money = formatter.format(mon.toString().replace(/,|\./g, ""))
    if(money === "NaN" || money === '0'){
        return '';
    }
    return money;
}

export const convertToNumber = (mon="0") => {
    return mon.toString().replace(/,|\./g, "");
}

export const convertNumber = (mon='0', type='en') => {
    const formatter = Intl.NumberFormat(type, {
        
    })
    const money = formatter.format(mon);
    if(money === "NaN"){
        return 0;
    }
    return money;
}

export const seperatePoint = (money="0", view_point=false) => {
    let mon = money.toString();
    let mon_split = mon.split('.');
    let point = mon_split[1];
    console.log(mon_split)
    if(mon_split.length === 2){    
        if(view_point){
            return point;
        }
        return mon_split[0];
    }
    if(view_point){
        return '00';
    }
    return mon;
}