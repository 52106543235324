import React from 'react';

import { Wrapper, Container, Content } from './style';

export const ImgViewerWrapper = ({children}) => {
    return(
        <Wrapper>
            <Container>
                {children}
            </Container>  
        </Wrapper>
    )
}

const ImgViewer = ({alt="", src="", onClickClose}) => {
    return(
        <Content>
            <img className="img_" alt={alt} src={src}  />
            <div className='close-cont'>
                <span className='fas fa-times font-slightly-small close' onClick={onClickClose}></span>
            </div>
        </Content>
    )
}

export default ImgViewer;