import React from "react";
import { Wrapper, Content } from "./style";

const PreloaderLine = () => {
    return(
        <Wrapper>
            <Content>
                
            </Content>
        </Wrapper>
    )
}

export default PreloaderLine;