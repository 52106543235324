import React, { useState, useEffect, useMemo, useCallback, Fragment} from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import THDrop from 'components/app/school/SmallerComponents/table_html/th/menuDrop';
// import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper} from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import BlankLoader from "components/blankLoader";
import Loader from 'components/app/school/SmallerComponents/loader';
// import Spinner from 'components/app/school/SmallerComponents/spinner';

// import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { timify } from 'components/app/school/actions/timify';
import { Link } from 'react-router-dom';

import { addItemToArray, addItemToArrayAndInnerArray, addItemToArrayOnly, addArrayWithArray } from 'components/app/school/actions/array-utils/Add';
import { updateArray, updateinnerArray } from 'components/app/school/actions/array-utils/Update';
import { deleteItemFromInnerArray } from 'components/app/school/actions/array-utils/Delete';

const TimeTable = ({school_id, user_id, user_type}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialTHDropState = useMemo(() => ({start_time:'', end_time:'', class_section:''}), []);
    const initialTTTState = useMemo(() => ({start_time:'', end_time:'', uid:'', id:0, start_time_disp:false, end_time_disp:false, num:-1, class_section:''}), []);
    const initialTimeTableState = useMemo(() => ({id:0, uid:"", add_btn_disp:false, subject_name:''}), []);
    const initialTimeTableSubjectState = useMemo(() => ({subject_disp:false, curr_subject_value:''}), []);
    const initialSessionState = useMemo(() => ({id:0, curr_sess_id:0, curr_sess:'', curr_term:'', session:'', term:''}), []);

    const [allDays, setAllDays] = useState([]);
    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allTimeTTData, setAllTimeTTData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    // const [allSession, setAllSession] = useState([])
    const [allTimeTableData, setAllTimeTableData] = useState([{subject:[]}]);

    const [menuDisp, setMenuDisp] = useState([{clicked:false, num:0}]);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const [THDropState, setTHDropState] = useState([initialTHDropState]);
    const [TTTState, setTTTState] = useState([initialTTTState]);
    const [TimeTableState, setTimeTableState] = useState([initialTimeTableState]);
    const [sessionState, setSessionState] = useState(initialSessionState);

    const [preloading, setPreloading] = useState(false);
    // const [preloading2, setPreloading2] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchAllWeekday = useCallback(() => {
        if(school_id && sessionState.curr_sess_id){
            setPreloading(true);
            appServices.getAllSchoolWeekDayData(school_id).then(day_res => {
                appServices.getAllClassSectionSchool(school_id).then(res => {
                    setAllDays(addArrayWithArray(res.data, day_res.data,  "class_num"));
                    setMenuDisp(addItemToArrayOnly(res.data, {clicked:false}));
                    setTHDropState(addItemToArrayOnly(res.data, initialTHDropState))
                    setTimeout(() => {
                        setAllClassSectionData(res.data);
                    }, 200)
                }).catch(e => {
                    setMenuDisp({clicked:false, num:0})
                    setAllClassSectionData([]);
                    setError({data:["Internal Server Error"], title:"Class Section Fetch Error"});
                    setPreloading(false);
                })
                // 
            }).catch(e => {
                setAllDays([]);
                setError({title:'Day Fetch Error', data:['Internal Server Error']});
            })
        }
    }, [school_id, sessionState.curr_sess_id, initialTHDropState])

    const fetchAllTimeTableTime = useCallback(() => {
        if(school_id && sessionState.curr_sess_id){
            appServices.getAllSchoolTimeTableTimeData(school_id, sessionState.curr_sess_id).then(res => {
                setAllTimeTTData(addItemToArray(res.data, {}));
                setTTTState(addItemToArray(res.data, {start_time_disp:initialTTTState.start_time_disp, end_time_disp:initialTTTState.end_time_disp,}))
            }).catch(e => {
                setPreloading(false);
                setAllTimeTTData([]);
                setError({title:'Timetable Time Fetch Error', data:['Internal Server Error']});
            })
        }
    }, [school_id, sessionState.curr_sess_id, initialTTTState])

    const fetchAllTimeTable = useCallback(() => {
        if(school_id && sessionState.curr_sess_id){
            appServices.getAllSchoolTimeTableData(school_id, sessionState.curr_sess_id).then(res => {
                setTimeTableState(addItemToArrayAndInnerArray(res.data, "subject", {subject_disp:initialTimeTableSubjectState.subject_disp, curr_subject_value:initialTimeTableSubjectState.curr_subject_value}, {subject_name:initialTimeTableState.subject_name, add_btn_disp:initialTimeTableState.add_btn_disp, on_hover:false}))
                setAllTimeTableData(addItemToArrayAndInnerArray(res.data, "subject", {subject_disp:initialTimeTableSubjectState.subject_disp, curr_subject_value:initialTimeTableSubjectState.curr_subject_value}, {}));
                setTimeout(() => {
                    setPreloading(false)
                }, 300)
            }).catch(e => {
                setPreloading(false);
                setAllTimeTableData([]);
                setError({title:'Timetable Fetch Error', data:['Internal Server Error']});
            })
        }
    }, [school_id, sessionState.curr_sess_id, initialTimeTableState, initialTimeTableSubjectState]);

    const fetchAllSubject = (sid) => {
        if(sid){
            appServices.getAllSubjectSchool(sid, "", "", "name").then(res => {
                setAllSubjectData([...res.data]);
            }).catch(e => {
                setAllSubjectData([]);
                setError({title:'Subject Fetch Error', data:['Internal Server Error']});
            })
        }
    }

    const fetchSchoolSession = (sid) => {
        if(sid){
            appServices.getCurrentSession(sid).then(res => {
                setSessionState({...res.data, curr_sess:res.data.session, curr_sess_id:res.data.id, curr_term:res.data.term_name});
            }).catch(e => {
                setSessionState({id:0, session:'', term:''});
                setError({title:'School Session Fetch Error', data:['Internal Server Error']});
            })
        }
    }

    // const fetchAllSchoolSession = useCallback(() => {
    //     if(school_id){
    //         setPreloading2(true);
    //         appServices.getAllSession(school_id).then(res => {
    //             setAllSession(res.data);
    //             console.log(res);
    //             setPreloading2(false);
    //         }).catch(e => {
    //             console.log(e);
    //             setAllSubjectData([]);
    //             setError({title:'School Session Fetch Error', data:['Internal Server Error']});
    //             setPreloading2(false);
    //         })
    //     }
    // }, [school_id]);

    // const fetchAllClassSection = useCallback(() => {
    //     if(school_id ){
    //         setPreloading(true);
    //         appServices.getAllClassSectionSchool(school_id).then(res => {
    //             setMenuDisp(addItemToArrayOnly(res.data, {clicked:false}));
    //             setTHDropState(addItemToArrayOnly(res.data, initialTHDropState))
    //             setTimeout(() => {
    //                 setAllClassSectionData(res.data);
    //                 setPreloading(false)
    //             }, 600)
    //             console.log(res.data);
    //         }).catch(e => {
    //             setMenuDisp({clicked:false, num:0})
    //             setAllClassSectionData([]);
    //             setError({data:["Internal Server Error"], title:"Class Section Fetch Error"});
    //             setPreloading(false);
    //         })
    //     }
    // }, [school_id, initialTHDropState]);


    const handleTHDropChange = (e, i=0, csid=0) => {
        setTHDropState(updateArray(THDropState, i, {...THDropState[i], [e.target.name]:e.target.value, class_section:csid}))
    }

    const handleTTTChange = (e, i=0) => {
        setTTTState(updateArray(TTTState, i, {...TTTState[i], [e.target.name]:e.target.value}));
    }

    const handleTimeTableChange = (e, i=0) => {
        setTimeTableState(updateArray(TimeTableState, i, {...TimeTableState[i], [e.target.name]:e.target.value}));
    }

    const handleTimeTableSubjectChange = (e, arr_num=0, num=0) => {
        setTimeTableState(updateinnerArray(TimeTableState, arr_num, "subject", num, {...TimeTableState[arr_num].subject[num], [e.target.name]:e.target.value}))
    }

    const onClickTTT = (i=0, item, initial={start_time:'', end_time:''}) => {
        setTTTState(updateArray(TTTState, i, item))
        if(item.start_time_disp){
            setTTTState(updateArray(TTTState, i, {...item, start_time:initial.start_time, start_time_disp:true}));
        }
        if(item.end_time_disp){
            setTTTState(updateArray(TTTState, i, {...item, end_time:initial.end_time, end_time_disp:true}));
        }
        
    } 
    const onClickEditTimeTableSubject = (state_num=0, i=0, item) => {
        if(TimeTableState[state_num].subject.length){
            setTimeTableState(updateinnerArray(TimeTableState, state_num, "subject", i, item ));
        }
    }

    const onClickAddTimeTable = (i=0, item) => {
        setTimeTableState(updateArray(TimeTableState, i, item));
    }  
    const onSubmitTHDrop = (e, i) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        const data = {
            school:school_id,
            created_by:user_id,
            order:0,
            session: sessionState.curr_sess_id,
            class_section:THDropState[i].class_section,
            start_time: THDropState[i].start_time,
            end_time: THDropState[i].end_time
        }
        appServices.insertSchoolTimeTableTime(data).then(res => {
            const tot_ttt_num = allTimeTTData.length
            setSuccess({title:'Success', text:'Data Saved Successfully'});
            setTHDropState(addItemToArrayOnly(THDropState, initialTHDropState));
            setTTTState(updateArray(TTTState, tot_ttt_num, {...initialTTTState, ...res.data}))
            fetchAllTimeTable();
            setTimeout(() => {
                setAllTimeTTData(updateArray(allTimeTTData, tot_ttt_num, {...res.data}))
                setLoading(false);
            }, 250)
        }).catch(e => {
            setLoading(false);
            setError({title:'Subject Save Error', data:[e.response.data.detail]});
        })
    }

    const onSubmitTTSubject = (e, i) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        const data = {
            time_table: TimeTableState[i].id,
            subject: TimeTableState[i].subject_name,
            created_by: user_id
        }
        appServices.insertSchoolTimeTableSubject(data).then(res => {
            setTimeTableState(updateArray(TimeTableState, i, {...initialTimeTableState, ...res.data, subject:addItemToArray(res.data.subject, initialTimeTableSubjectState)}))
            setTimeout(() => {
                setAllTimeTableData(updateArray(allTimeTableData, i, {...res.data, subject:addItemToArray(res.data.subject, initialTimeTableSubjectState)}))
                setSuccess({title:'Success', text:'Data Saved Successfully'});
                setLoading(false);
            }, 100)
        }).catch(e => {
            setLoading(false);
            setError({title:'Time Save Error', data:[e.response.data.detail]});
        })
    }

    const handleUpdateTTT = (e, i) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        const data = {
            start_time:TTTState[i].start_time,
            end_time: TTTState[i].end_time,
            updated_by: user_id,
        }
        appServices.updateSchoolTimeTableTime(TTTState[i].id, data).then(res => {
            setTTTState(updateArray(TTTState, i, {...initialTTTState, ...res.data}));
            setAllTimeTTData(updateArray(allTimeTTData, i, res.data));
            setTimeout(() => {
                setLoading(false);
                setSuccess({title:'Update Success', text:'Data Updated Successfully'});
            }, 100);
        }).catch(e => {
            setLoading(false);
            setError({title:'Time Update Error', data:[e.response.data.detail, "No Active Session Found"]});
        })
    }

    const handleUpdateTTS = (e, ii=0, iii=0) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        const data = {
            subject:TimeTableState[ii].subject[iii].curr_subject_value,
            time_table:TimeTableState[ii].subject[iii].time_table,
            updated_by: user_id,
        }
        appServices.updateSchoolTimeTableSubject(TimeTableState[ii].subject[iii].id, data).then(res => {
            setTimeTableState(updateinnerArray(TimeTableState, ii, "subject", iii, {...initialTimeTableSubjectState, ...res.data}));
            setAllTimeTableData(updateinnerArray(allTimeTableData, ii, "subject", iii, res.data));
            setTimeout(() => {
                setLoading(false);
                setSuccess({title:'Update Success', text:'Data Updated Successfully'});
            }, 100);
        }).catch(e => {
            setLoading(false);
            setError({title:'Subject Update Error', data:[e.response.data.detail]});
        })
    }

    const handleDeleteTimeTableSubject = (ii=0, iii=0) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        appServices.deleteSchoolTimeTableSubject(TimeTableState[ii].subject[iii].id).then(res => {   
            setAllTimeTableData(deleteItemFromInnerArray(allTimeTableData, ii, "subject", iii));
            setTimeout(() => {
                setTimeTableState(deleteItemFromInnerArray(TimeTableState, ii, "subject", iii));
                setLoading(false);
                setSuccess({title:'Delete Success', text:'Data Deleted Successfully'});
            }, 100);
        }).catch(e => {
            setLoading(false);
            setError({title:'Subject Delete Error', data:[e.response.data.detail]});
        })
    }

    useEffect(() => {
        fetchAllWeekday();
        fetchSchoolSession(school_id);
        fetchAllTimeTableTime();
        fetchAllTimeTable();
        // fetchAllSchoolSession();
        fetchAllSubject(school_id);
    }, [school_id,  fetchAllWeekday, fetchAllTimeTableTime, fetchAllTimeTable]);

    useEffect(() => {
       
    }, [])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    // const insertSessionOption = allSession.map((item, i) => {
    //     return(
    //         <option key={i} value={item.id}>{item.session} - {item.term_name.toUpperCase()} TERM</option>
    //     )
    // })

    

    const insertTableTDData = (class_num=0, class_id=0) => {
        if(!allDays.length){
            return(<TR><TD classname={"align-center red"}>No Record Found</TD></TR>)   
        } else {
            const filterDay = allDays.filter((item) => {
                return item.class_num === class_num;
            })
            const filterSubject = allSubjectData.filter((item) => {
                return item.class_section === class_id;
            })
            const insertSubjectData = filterSubject.map((item, i) => {
                return(
                    <option value={item.id} key={i}>{item.name}</option>
                )
            })
            const currentDay = new Date().getDay()
            return (filterDay.map((item, i) => {
                const filterTimeTable = allTimeTableData.filter((item2) => {
                    return item2.week_day===item.id && item2.class_section_id===class_id;
                })
                const filterTimeTableState = TimeTableState.filter((item2) => {
                    return item2.week_day===item.id && item2.class_section_id===class_id;
                })
                return(
                    <Fragment key={i}>
                        <TR>
                            <TD><span className='fw-700'>{item.name.slice(0, 4)}</span></TD>
                            {filterTimeTable.map((item_tt, ii) => {
                                const tot_sub_len_min = item_tt.subject.length - 1;
                                const styleTT = () => {
                                    var return_value;
                                    const currentTime = new Date().getTime();
                                    const start_time = new Date(new Date().setHours(parseInt(item_tt.start_time.slice(0,2)), parseInt(item_tt.start_time.slice(3,5)), parseInt(item_tt.start_time.slice(6,8)))).getTime();
                                    const end_time = new Date(new Date().setHours(parseInt(item_tt.end_time.slice(0,2)), parseInt(item_tt.end_time.slice(3,5)), parseInt(item_tt.end_time.slice(6,8)))).getTime();
                                    if(currentDay === item_tt.day_order ){
                                        return_value = {background:'red', color:'#eee'};
                                        if(start_time > currentTime){
                                            return_value = undefined;
                                        }
                                        else if(start_time < currentTime && end_time > currentTime){
                                            return_value = {background:'green', color:'#eee'}
                                        }
                                    }
                                    return return_value;
                                    
                                }  
                                const dotFunc = () => {
                                    if(item_tt.subject.length){
                                        return(<button type='button' style={{padding:'0'}} className="app-text-sec-color btn font-slightly-small bg-none fw-700" onClick={() => onClickAddTimeTable(filterTimeTableState[ii].num, {...filterTimeTableState[ii], on_hover:!filterTimeTableState[ii].on_hover})} ><i className='fas fa-ellipsis-v'></i></button>)
                                    }
                                }
                                const hoverBtnFunc = () => {
                                    if(filterTimeTableState[ii].on_hover && item_tt.subject.length){
                                        return(<><div style={{marginTop:'3px'}} className='disp-flex jc-sb '><Link to="" className='br-circle bg-pink-red' title='View More'><i className='fas fa-eye'></i></Link>{!filterTimeTableState[ii].add_btn_disp && <button type="button" title="Add Subject" onClick={() => onClickAddTimeTable(filterTimeTableState[ii].num, {...filterTimeTableState[ii], add_btn_disp:!filterTimeTableState[ii].add_btn_disp})} style={{padding:'3px', marginLeft:'3px'}} className='btn br-circle app-bg-color app-bg-text-color hover-opacity font-super-small' ><i className='fas fa-plus'></i></button>}</div></>)
                                    }
                                } 
                                
                                return(
                                    <Fragment key={ii}>
                                        <TD style={styleTT()}>
                                            <div className='form-group'>
                                                {item_tt.subject.map((item_sub, iii) => { 
                                                    return(
                                                        <Fragment key={iii}>{!filterTimeTableState[ii].subject[iii].subject_disp ? (
                                                        <span  onClick={() => onClickEditTimeTableSubject(filterTimeTableState[ii].num, filterTimeTableState[ii].subject[iii].num, {...filterTimeTableState[ii].subject[iii], subject_disp:!filterTimeTableState[ii].subject[iii].subject_disp, curr_subject_value:item_sub.subject})} className='font-super-small app-text-color' style={styleTT()}>{item_sub.sub_name}</span>) :  
                                                            (
                                                                <div className='form-group'>
                                                                    <form onSubmit={e => handleUpdateTTS(e, filterTimeTableState[ii].num, filterTimeTableState[ii].subject[iii].num)}>
                                                                        <button type="button" onClick={() => onClickEditTimeTableSubject(filterTimeTableState[ii].num, filterTimeTableState[ii].subject[iii].num, {...filterTimeTableState[ii].subject[iii], subject_disp:!filterTimeTableState[ii].subject[iii].subject_disp})} className='btn hover-opacity app-bg-text-color bg-red br font-super-small'><i className='fas fa-times'></i></button>
                                                                        <select style={{width:'70px'}} className='font-super-small fw-600 b-1-app-bg br-10' name="curr_subject_value" value={filterTimeTableState[ii].subject[iii].curr_subject_value} onChange={e => handleTimeTableSubjectChange(e, filterTimeTableState[ii].num, filterTimeTableState[ii].subject[iii].num)}  required>
                                                                            <option value={""}>Select Subject</option>
                                                                            {filterSubject.length > 0 ? insertSubjectData : (<option value={""}>No Record Found</option>)}
                                                                        </select>
                                                                        <button type="submit"  className={`btn hover-opacity app-bg-text-color app-bg-color br font-super-small`}><i className='fas fa-paper-plane'></i></button>
                                                                        <button type='button' onClick={() => handleDeleteTimeTableSubject(filterTimeTableState[ii].num, filterTimeTableState[ii].subject[iii].num)} title="Delete Subject" style={{background:'red', color:'#eee'}} className={`btn hover-opacity br font-super-small`}><i className='fas fa-trash'></i></button> 
                                                                    </form>
                                                                </div> 
                                                            )
                                                        }       
                                                        <span className='font-slightly-small fw-700'>{tot_sub_len_min === iii ? '' : '/'}</span>
                                                    </Fragment>)
                                                })}
                                            </div>
                                            <div className='disp-block'>
                                                {dotFunc()}
                                                {hoverBtnFunc()}
                                                {!filterTimeTableState[ii].add_btn_disp ? (
                                                    <>{!item_tt.subject.length && <button type="button"  title="Add Subject" onClick={() => onClickAddTimeTable(filterTimeTableState[ii].num, {...filterTimeTableState[ii], add_btn_disp:!filterTimeTableState[ii].add_btn_disp})} style={{padding:'3px'}} className='btn br-circle app-bg-color app-bg-text-color hover-opacity font-super-small' ><i className='fas fa-plus'></i></button>}</>) :
                                                    (
                                                        <form onSubmit={e => onSubmitTTSubject(e, filterTimeTableState[ii].num)}>
                                                            <button type="button" onClick={() => onClickAddTimeTable(filterTimeTableState[ii].num, {...filterTimeTableState[ii], add_btn_disp:!filterTimeTableState[ii].add_btn_disp, subject_name:''})} className='btn hover-opacity app-bg-text-color bg-red br font-super-small'><i className='fas fa-times'></i></button>
                                                            <select style={{width:'70px'}} className='font-super-small fw-600 b-1-app-bg br-10' name="subject_name" value={filterTimeTableState[ii].subject_name} onChange={e => handleTimeTableChange(e, filterTimeTableState[ii].num)}  required>
                                                                <option value={""}>Select Subject</option>
                                                                {filterSubject.length > 0 ? insertSubjectData : (<option value={""}>No Record Found</option>)}
                                                            </select>
                                                            <button type="submit"  className={`btn hover-opacity app-bg-text-color app-bg-color br font-super-small`}>Save</button>
                                                        </form>
                                                    )
                                                }
                                                
                                            </div>
                                        </TD>
                                    </Fragment>
                                )
                            })}
                        </TR>
                    </Fragment>
                )
            }))
        }
    }

    const insertTimeTableData = () => {
        return (allClassSectionData.map((class_item, i) => {
            const filterTimeItem = allTimeTTData.filter((item) => {
                return item.class_section === class_item.id;
            })
            const filterTimeItemState = TTTState.filter((item) => {
                return item.class_section === class_item.id;
            })
            return(
                <Fragment key={i}>
                    <div className='flex-100 align-center padd-4px'><span className='app-text-color fw-700 font-small '>{class_item.name.toUpperCase() } SECTION</span></div>
                    <div className='flex-100' style={{width:'0'}}>
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH>Day / Time</TH>      
                                    {filterTimeItem.map((item, j) => {
                                        return(
                                            <Fragment key={j}>
                                                <TH>
                                                    <div className='form-group'>
                                                        {!filterTimeItemState[j].start_time_disp && <code className='font-very-small fw-700 app-bg-color-text' onClick={() => onClickTTT(filterTimeItemState[j].num, {...filterTimeItemState[j], start_time_disp:!filterTimeItemState[j].start_time_disp}, {start_time:item.start_time}) }>{timify(item.start_time)}</code>}
                                                        {filterTimeItemState[j].start_time_disp &&(
                                                            <div className='form-group'>
                                                                <form onSubmit={e => handleUpdateTTT(e, filterTimeItemState[j].num)}>
                                                                    <button type="button" onClick={() => onClickTTT(filterTimeItemState[j].num, {...filterTimeItemState[j], start_time_disp:!filterTimeItemState[j].start_time_disp}, {start_time:item.start_time})} className='btn hover-opacity app-bg-text-color bg-red br font-super-small'><i className='fas fa-times'></i></button>
                                                                    <input type="time" className='font-super-small fw-600 b-1-app-bg br-10' name="start_time" value={filterTimeItemState[j].start_time} onChange={e => {handleTTTChange(e, filterTimeItemState[j].num)}} required/>
                                                                    <button type="submit"  className={`btn hover-opacity app-bg-text-color app-bg-color br font-super-small`}><i className='fas fa-paper-plane'></i></button>
                                                                </form>
                                                            </div>
                                                        ) }
                                                        - 
                                                        {!filterTimeItemState[j].end_time_disp && <code className='font-very-small fw-700 app-bg-color-text' onClick={() => onClickTTT(filterTimeItemState[j].num, {...filterTimeItemState[j], end_time_disp:!filterTimeItemState[j].end_time_disp}, {end_time:item.end_time})}>{timify(item.end_time)}</code>}
                                                        {filterTimeItemState[j].end_time_disp &&(
                                                            <div className='form-group'>
                                                                <form onSubmit={e => handleUpdateTTT(e, filterTimeItemState[j].num)}>
                                                                    <button type="button" onClick={() => onClickTTT(filterTimeItemState[j].num, {...filterTimeItemState[j], end_time_disp:!filterTimeItemState[j].end_time_disp}, {end_time:item.end_time})} className='btn hover-opacity app-bg-text-color bg-red br font-super-small'><i className='fas fa-times'></i></button>
                                                                    <input type="time" className='font-super-small fw-600 b-1-app-bg br-10' name="end_time" value={filterTimeItemState[j].end_time} onChange={e => {handleTTTChange(e, filterTimeItemState[j].num)}}  required/>
                                                                    <button type="submit"  className={`btn hover-opacity app-bg-text-color app-bg-color br font-super-small`}><i className='fas fa-paper-plane'></i></button>
                                                                </form>
                                                            </div>
                                                        ) }
                                                    </div>
                                                </TH>
                                            </Fragment>
                                        )
                                    })}
                                        

                                    <TH><button type='button' title='Add Time' onClick={() => setMenuDisp(updateArray(menuDisp, menuDisp[i].num, {clicked:!menuDisp[i].clicked}))} className={`btn bordered-grey hover-opacity app-opp-text-color br font-very-small ${menuDisp[i].clicked ? 'bg-red ' : 'app-opp-theme'}`}><i className={menuDisp[i].clicked ? 'fas fa-times' : 'fas fa-plus '}></i></button>
                                        {menuDisp[i].clicked && (
                                            <THDrop>
                                                <form onSubmit={e => {onSubmitTHDrop(e, THDropState[i].num)}}>
                                                    <div className='form-group'>
                                                        <label className='font-very-small app-text-sec-color'>From:</label><br />
                                                        <input type="time" name="start_time" className='font-super-small fw-500 b-1-app-bg br-10' value={THDropState[i].start_time} onChange={(e) => handleTHDropChange(e, THDropState[i].num, class_item.id)} required/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label className='font-very-small app-text-sec-color'>To:</label><br />
                                                        <input type="time" name="end_time" className='font-super-small fw-500 b-1-app-bg br-10' value={THDropState[i].end_time} onChange={(e) => handleTHDropChange(e, THDropState[i].num, class_item.id)}  required/>
                                                    </div>
                                                    <button type='submit' className='app-bg-color btn bordered-grey hover-opacity app-bg-text-color br font-very-small mr-top-2'>Save</button>
                                                </form>
                                            </THDrop>
                                        )}
                                    </TH>
                                </TR>
                            </thead>
                            <tbody>
                                {insertTableTDData(i, class_item.id)}
                            </tbody>
                        </TABLE>
                    </div>
                </Fragment>
            )
        }))
    }

   
    

    return(
        <>
            <HeadComponent title='School Weekdays - Set' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> 
                <Grid layout='lauto'>
                    
                        <GridContent header_text={`Manage School Timetable`} header_icon="fas fa-align-right">
                        <Section>
                            {/* <Mallet> */}
                            {loading && <Loader />}
                            <Mallet>
                                <div className='flex'>
                                    <label className='font-slightly-small'>Current Session: 
                                    <select style={{cursor:'not-allowed'}} name="session" value={sessionState.curr_sess_id} disabled className='form-control-theme font-slightly-small' >
                                        <option value={sessionState.curr_sess_id}>{sessionState.curr_sess} - {sessionState.curr_term.toUpperCase()} TERM</option>
                                    </select></label>
                                </div>
                            </Mallet>
                            {preloading ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                            <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                {allClassSectionData.length > 0 ? insertTimeTableData() : <div className='flex-100 align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}
                                
                            </div>
                            </>)}
                            {/* </Mallet> */}
                        </Section>
                        </GridContent>
                    
                    
                </Grid>
            </ComponentWrapper>
        </>
    )

}

export default TimeTable;