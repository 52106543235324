import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import Spinner from "components/app/school/SmallerComponents/spinner";
import Loader from 'components/app/school/SmallerComponents/loader';
import BlankLoader from "components/blankLoader";
import PreloaderLine from 'components/preloaderLine';

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { ageCalc } from 'components/app/school/actions/ageCalc';
import { getPageSize } from 'components/app/school/actions/pageSize';
import { addItemToArray } from 'components/app/school/actions/array-utils/Add';


const EnrolledStudent = ({school_id, user_id, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    const [userTable, setUserTable] = useState({sbid:'', q:"", size:100, p:1})

    const [allData, setAllData] = useState(initialPaginationState);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);

    const [navigate, setNavigate] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [userTableChanged, setUserTableChanged] = useState(false);
    const [preloading2, setPreloading2] = useState(true);

    const nav = useNavigate();

    const fetchAllEnrolledStudent = (loader=true, sid, p=1, size=100, q, sbid) => {
        setPreloading(loader);
        setTableLoading(!loader);
        setPreloading2(true);
        appServices.getAllStudentEnrolled_Admin(sid, p, size, sbid, q).then(res => {
            setPreloading(false);
            setTableLoading(false);
            setPreloading2(false);
            setAllData(() => {return {...res.data, results:res.data.results}});
        }).catch(e => {
            setPreloading(false);
            setTableLoading(false);
            setAllData({count:0, previous:undefined, next:undefined, results:[]});
            setPreloading2(false);
            setError({data:['Internal Server Error'], title:'Enroll Data Fetch Error'});
        })
    }
    const fetchAllClasses = (school_id) => {
        if(school_id){
            setError({data:[], title:''});
            appServices.getAllClassSchoolItem(school_id).then(res => {
                setAllClassesData(addItemToArray(res.data));
            }).catch(e => {
                setAllClassesData([]);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchAllSchoolBranch = (sid) => {
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData([...res.data]);
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        })
    }

    const handleUserTable = (e) => {
        setUserTableChanged(false);
        setUserTable({...userTable, [e.target.name]:e.target.value})
        setUserTableChanged(true)
    }
    const handleUserTablePagination = (e, value) => {
        setUserTableChanged(false);
        const name = "p";
        setUserTable({...userTable, [name]:value})
        setUserTableChanged(true)
    }

    const getClassName = (id=0) => {
        const filterItem = allClassesData.filter((item) => item.classes === id);
        if(filterItem.length > 0){
            return filterItem[0].name
        }
        return '';
    }
    const rejectJob = (id) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState)
        setWarning(initialSuccessState);
        appServices.terminateStudentEnrolled_Admin(id).then(() => {
            setLoading(false);
            setSuccess({title:'Success', text:'Enrollment Rejected Successfully'})
            fetchAllEnrolledStudent(false, school_id, userTable.p, userTable.size, userTable.q, userTable.sbid);
        }).catch(e => {
            setError({title:'Error', data:[e.response.data.detail]});
            setLoading(false);
        })  
    }
    const onClickRejectBtn = (id, i, name="") => {
        setWarning({id:0, ...initialSuccessState});
        setTimeout(() => {
            setWarning({id:i, title:'Are you sure?', text:`Reject ${name.length > 15 ? name.slice(0,15)+"..." : name+"'s"} enrollement?`, click_text:'Yes', handleClick:() => rejectJob(id)})
        }, 100);
    }
    const onClickViewBtn = (data={}) => {
        setNavigate(`/${school_title}/student/enrolled/${data.id}`);
    }

    useEffect(() => {
        fetchAllSchoolBranch(school_id);
        fetchAllClasses(school_id);
    }, [school_id])
    useEffect(() => {
        if(userTableChanged){
            fetchAllEnrolledStudent(false, school_id, userTable.p, userTable.size, userTable.q, userTable.sbid);
        }
        else{
            fetchAllEnrolledStudent(true, school_id);
        }
    }, [school_id, userTable, userTableChanged]);
    useEffect(() => {
        if(reloadData){
            fetchAllEnrolledStudent(false, school_id, userTable.p, userTable.size, userTable.q, userTable.sbid);
            fetchAllClasses(school_id);
            fetchAllSchoolBranch(school_id);
            setReloadData(false);
        }
    }, [reloadData, school_id, userTable]);
    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSchoolBranchData = allSchoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const insertEnrollData = () => {
        var i = 0;
        if(userTable.p > 1){
            const page = parseInt(userTable.p) - 1
            const size = parseInt(userTable.size) * page;
            i = size;
        }
        const stat = (data={}) => {
            if(data.is_accepted){
                return '(Undergoing payment)'
            }
            if(data.is_interviewer_accepted){
                return '(Interviewer accepted this student)'
            }
            if(data.is_finish_interview){
                return '(Interview finished)'
            }
            if(data.is_start_interview){
                return '(Interview startted)'
            }
            
            return 'PENDING';
        }
        return(allData.results.map((item) => { 
            i++;
            const full_name = item.last_name ? item.last_name + ' ' + item.first_name : item.enroll_sur_name + ' ' + item.enroll_other_name;
            const email = item.is_self_sponsored ? ''+item.student_email : ''+item.parent_email;
            const data = {
                id: item.uid,
            }
            return(
                <Fragment key={i}>
                    <Tr sn={true} edit_btn={false} view_btn={true} handleViewClick={() => onClickViewBtn(data)} dlt_btn={!item.is_accepted} handleDeleteClick={() => onClickRejectBtn(item.uid, i, full_name)} dlt_text='Reject'>
                        <div className="c-data sn"><span className="font-very-small">{i}</span></div>
                        <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.is_self_sponsored ? item.profile_pic : item.enroll_profile_pic}`} alt="Profile" /></div> <span className="font-very-small">{full_name.length > 20 ? `${full_name.slice(0, 20)}...` : full_name}</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize">{getClassName(item.class_enrolled)}</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize">{item.enroll_type}</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize fw-600">{stat({is_accepted:item.is_accepted, is_finish_interview:item.is_finish_interview, is_interviewer_accepted:item.is_interviewer_accepted, is_start_interview:item.is_start_interview})}</span></div>
                        <div className="c-data"><span className="font-very-small">{email.length > 15 ? `${email}...` : email}</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize">{item.student_rel}</span></div>
                        <TdWrapper center header_text={full_name.length > 25 ? full_name.slice(0, 25)+'...' : full_name } header_num={i}>
                            <Td profile_pic={`${BACKEND_URL}${item.is_self_sponsored ? item.profile_pic : item.enroll_profile_pic}`} />
                            <Td heading="Full Name" text_class='text-capitalize' text={`${full_name}`} />
                            <Td heading="Class Enrolled" text_class='text-capitalize' text={`${getClassName(item.class_enrolled)}`} />
                            <Td heading="Status" text_class='text-capitalize fw-700' text={`${stat({is_accepted:item.is_accepted, is_finish_interview:item.is_finish_interview, is_interviewer_accepted:item.is_interviewer_accepted, is_start_interview:item.is_start_interview})}`} />
                            <Td heading="School Branch Applied" text_class='text-capitalize' text={`${item.school_branch_name}`} />
                            <Td heading="Religion" text_class='text-capitalize' text={`${item.student_rel}`} />
                            <Td heading="Age" text={`${ageCalc(item.student_dob)} years old`} />
                            <Td heading="Phone Number" text={item.is_self_sponsored ? '+'+item.student_phone : '+'+item.parent_phone} />
                            <Td heading="Email" text={`${email}`} />
                            <Td heading="Sponsored By" text_class='text-capitalize' text={`${item.is_self_sponsored ? 'Self' : 'Guardian'}`} />
                            <Td heading="Date Enrolled" text_class='text-capitalize' text={`${item.created}`} />
                            <Td link={`/${school_title}/student/enrolled/${item.uid}`} text_link='View More'  />
                        </TdWrapper>
                    </Tr>
                </Fragment>
            )
        }))
    }
    return(
        <>
            <HeadComponent title='Manage Enrolled Students' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>   
                <Grid layout='lauto'>
                    <GridContent header_text={`Manage Enrolled Student`} header_icon="fas fa-tasks">
                    {preloading ? (<ul>
                        <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <Section>
                            {loading && (<Loader />)}
                            <Mallet >
                                <div className="flex-100"><label className='font-super-small'>Select School Location: 
                                    <select name="sbid" value={userTable.sbid} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small' >
                                        <option value={''}>All</option>
                                        {insertSchoolBranchData}
                                    </select></label>
                                </div>
                                <div className="flex-70-line"><label className='font-super-small'>Select Enroll Type 
                                    <select name="q" value={userTable.q} onChange={e => handleUserTable(e)} className='form-control-theme font-slightly-small'>
                                        <option value="">All</option>
                                        <option value="returning">Returning Student</option>
                                        <option value="new">New Student</option>
                                    </select></label>
                                </div>
                                <div className="flex-30-line align-right"><label className='font-super-small'>No. Of Users: <br />
                                    <select  name="size" value={userTable.size} onChange={e => handleUserTable(e)} className='font-slightly-small form-control-theme' >
                                        <option value={100}>100</option>
                                        <option value={200}>200</option>
                                        <option value={300}>300</option>
                                        <option value={400}>400</option>
                                        <option value={500}>500</option>
                                    </select>
                                    </label>
                                </div>
                                
                            </Mallet>
                            {tableLoading && <Spinner />}
                            <Table>
                                <Tr btn={false} header={true} sn={true} text_style='c-data' text={[{text:"S/N"}, {text:"Full Name"}, {text:'Class Enrolled'},  {text:'Type'}, {text:'Status'}, {text:'Email'},  {text:'Religion'},]} />
                                {allData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                    (<>
                                        {insertEnrollData()}
                                    </>)                         
                                }
                                <div  style={{marginTop:'40px', display:'flex', justifyContent:'center'}}>
                                    <Stack spacing={2}>
                                        <Pagination count={getPageSize(allData.count, userTable.size)} page={userTable.p} onChange={handleUserTablePagination} size="small" color="primary" variant="outlined" shape="rounded" />
                                    </Stack>
                                </div>
                            </Table>
                        </Section>
                    </>
                    )}
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default EnrolledStudent;