import React from "react";

import { Wrapper, Content } from "./style";

const Header = ({title="", classname="", is_placeholder=false, onClick}) => {
    
    return(
        <Wrapper>
            <Content>
                <span onClick={onClick} className={`font-super-big ${classname} ${!is_placeholder ? 'text-capitalize' : ''}`} style={is_placeholder ? {color:'#999'} : {}}>{title}</span>
            </Content>
        </Wrapper>
    )
}
export const HeaderDesc = ({text=''}) => {
    return(
        <div className="flex-100" style={{padding:'0 30px', margin:'0'}}>
            <span className="disp-block font-small app-text-color">{text}</span>
        </div>
    )
}

export default Header;