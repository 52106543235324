export const error_messenger = ( code, type="", data="") => {
    if(code === 450){
        return "no response from server";
    }
    else if(code === 500){
        return "Internal Server Error";
    }
    else if(type === "login" && code===401){
        return "Incorrect Username or Password";
    }
    else if(code===401){
        return "Error Encountered While Fetching Data";
    }
    else{
        return data;
    }
}