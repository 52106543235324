import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    padding: 10px;
    display: grid;
    position: relative;
    transition: .8s ease;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    @media (max-width: 375px){
        grid-template-columns: auto;
    }
`;