import React from "react";
import { Content } from "./style";

const Note = ({children, classname="font-slightly-small"}) => {
    return(
        <Content>
            <span className={`head ${classname}`}>NOTE:</span><span className={`${classname} text`}>{children}</span>
        </Content>
    )
}

export default Note;