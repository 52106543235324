import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from 'components/app/school/SmallerComponents/Section';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";
import { HeadComponent } from "components/head";

const SchoolEmbeddedApp = ({school_id, user_id, user_type, school={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    // const initialState = useMemo(() => ({p:1, size:50, session:''}), []);

    const [error] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [navigate, setNavigate] = useState('');

    const [preloading2] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [nav, navigate])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    return(
        <>
            <HeadComponent title='All Embedded Applications' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                    <GridContent>
                        <Section>
                            <Table>
                                <Tr sn btn={false} header={true} text={[{text:"S/N"}, {text:"App Name"},  {text:"Status"}]} />
                                <Tr  sn edit_btn={false} dlt_btn={false} view_btn handleViewClick={() => setNavigate(`web`)} >
                                    <div className="c-data sn">
                                        <span className="font-very-small app-text-color">1</span>
                                    </div>
                                    <div className="c-data">
                                        <span className="font-slightly-small app-text-color fw-600">School Website</span>
                                    </div> 
                                    <div className="c-data"><span className="font-very-small">{school.is_web_live ? <span className='green'>Live</span> : <span className='red'>Test</span>}</span></div>    
                                </Tr>
                                <Tr  sn edit_btn={false} dlt_btn={false} view_btn={false} >
                                    <div className="c-data sn">
                                        <span className="font-very-small app-text-color">2</span>
                                    </div>
                                    <div className="c-data">
                                        <span className="font-slightly-small app-text-color fw-600">Game</span>
                                    </div> 
                                    <div className="c-data"><span className="font-very-small">Coming Soon</span></div>    
                                </Tr>
                            </Table>
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolEmbeddedApp;