import React, { useState, useEffect, Fragment } from "react";
import {Document, PDFDownloadLink, Font, Page, View, Image, Text} from "@react-pdf/renderer";
import { Table, TR, TD, TH } from 'components/app/school/SmallerComponents/pdf/tableComponent';

import {pdfStyle} from './style';

import raleway_regular from 'font/raleway.regular.ttf';
import raleway_semibold from 'font/raleway.semibold.ttf';
import raleway_bold from 'font/raleway.bold.ttf';
import raleway_extrabold from 'font/raleway.extrabold.ttf';

import voskool_logo from "images/voskool_logo.png";

import { appServices } from "api/schoolApp/services";
import { BACKEND_URL } from 'actions/url';

import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';
import { timify } from 'components/app/school/actions/timify';

const ExamTimetablePdf = ({user_id, user_type, session, school}) => {
    
    // const [sessionState, setSessionState] = useState({});
    const [allExamTTData, setAllExamTTData] = useState([]);
    const [allClassSectionData, setAllClassSectionData] = useState([]);

    const [preloading, setPreloading] = useState(true);
    // const [err, setErr] = useState(false);
    
    const fetchAllExamTT = (sid, sess={}) => {
        if(sid && sess.id && sess.is_upload_exam_timetable){
            setPreloading(true);
            appServices.getAllSchoolExamTimetable(sid, sess.id).then(res => {
                setAllExamTTData(res.data);
                setPreloading(false);
            }).catch(e => {
                setPreloading(false);
                // setErr(true);
            })
            return;
        }
        // setErr(true);
    }
    const fetchAllClassSection = (sid) => {
        if(sid){
            appServices.getAllClassSectionSchool(sid).then(res => {
                setAllClassSectionData(res.data);
            }).catch(e => {
                setAllClassSectionData([]);
                // setErr(true);
            })
        }
    }

    Font.register({family:'Raleway', fonts:[{src:`${raleway_regular}`}, {src:raleway_semibold, fontWeight:600} , {src:raleway_bold, fontWeight:700} , {src:raleway_extrabold, fontWeight:800}]});

    useEffect(() => {
        fetchAllClassSection(school.id);
        fetchAllExamTT(school.id, session);
    }, [school, session]);

    const insertTimeTableData = () => {
        return (
            allClassSectionData.map((class_item, i) => {
                const filterExamTimeItem = allExamTTData.filter((item) => {
                    return item.class_section === class_item.id;
                })
                return(
                    <Fragment key={i}>
                        <Page size={'A4'} wrap break orientation="landscape">
                            <Text style={pdfStyle.pagination} render={({pageNumber, totalPages}) => (
                            `Page ${pageNumber} / ${totalPages}`
                            )} fixed />
                            <View style={pdfStyle.page}>
                                <View style={pdfStyle.heading_container}>
                                    <Image src={`${BACKEND_URL}${school.icon}`} style={pdfStyle.heading_logo} />
                                    <Text style={pdfStyle.heading_text}>{`${school.name}, ${session.session} ${session.term_name} Term
                                         ${class_item.name} Section Examination Timetable`}</Text>
                                </View>
                            </View>
                            <View style={pdfStyle.cont_voskool_logo}>
                                <Image src={voskool_logo} style={pdfStyle.voskool_logo} />
                            </View>
                            <Table bordered>
                                <TR bordered>
                                    <TH bordered width="20%" align="center" textOnly> Date </TH>
                                    <TH bordered width="80%" align="center" textOnly> Exam (Time - Subject(s) - Supervisor(s))   </TH>
                                </TR>
                                {filterExamTimeItem.map((item_j, j) => (
                                    <Fragment key={j}>
                                        <TR bordered>
                                            <TD bordered width="20%" textOnly fontSize="13px"><Text style={{fontWeight:600}}>{item_j.day_name}, {convertDate(new Date(item_j.exam_date), 'ddMMyyyy') }</Text></TD>
                                            <TD bordered width="80%" padding="0">
                                                <TR width="100%">
                                                    {item_j.time.map((item_k, k, arr_k) => {
                                                        const arr_len = arr_k.length;
                                                        const w = 100 / arr_len;
                                                        return(
                                                            <Fragment key={k}>
                                                                <TD bordered width={`${parseInt(w)}%`} padding="0">
                                                                    <Text style={{fontWeight:600, fontSize:'13px', color:'#000',paddingBottom:'3px', paddingLeft:'3px', paddingTop:'2px', borderBottom:'1px solid #555'}}>{timify(item_k.start_time)} - {timify(item_k.end_time)}</Text>
                                                                    <TR width="100%">
                                                                        <TD width={`100%`}>
                                                                        {item_k.subjects.map((item_l, l) => {
                                                                            return(
                                                                                <Fragment key={l}>
                                                                                    <Text style={{fontWeight:500, fontSize:'12px', color:'#000', textTransform:'capitalize'}}>{item_l.name}</Text>
                                                                                    <TR width="100%">
                                                                                        <TD width={`100%`}>
                                                                                            <Text style={{fontWeight:600, fontSize:'9px', color:'#555',paddingTop:'2px'}}>SUPERVISOR(S)</Text>
                                                                                            <Text style={{fontWeight:500, fontSize:'10px', color:'#000', paddingBottom:'7px', paddingLeft:'5px', textTransform:'capitalize'}}>
                                                                                            {item_l.supervisor.map((item_m, m) => {
                                                                                                return(
                                                                                                    <Fragment key={m}>
                                                                                                        {item_m.staff_last_name} {item_m.staff_first_name}, 
                                                                                                    </Fragment>
                                                                                                )
                                                                                            })}
                                                                                            </Text>
                                                                                        </TD>
                                                                                    </TR>
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                        </TD>
                                                                    </TR>
                                                                </TD>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </TR>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                ))}
                            </Table>
                            {session.active_session && (
                                <View style={{width:'100%', textAlign:'right', marginTop:'10px'}}>
                                    <Text style={{fontWeight:700, fontSize:'16px'}}>Voskool wishes you the very best on your examinations, KEEP GRINDING!.</Text>
                                </View>
                            )}
                            
                        </Page>
                    </Fragment>
                )
            })
        )
    }

    return(
        <>
        {!preloading && (
            // <PDFViewer showToolbar={false} style={{width:'100%', height:'70vh'}}> 
            //     <Document>
            //         {insertTimeTableData()}
            //     </Document>
            // </PDFViewer>
            <PDFDownloadLink document={<Document>{insertTimeTableData()}</Document>} fileName={`${school.title.toUpperCase()}_${session.session}_${session.term_name.toUpperCase()}_TERM_ALL_CLASS_SECTION_EXAMINATION_TIMETABLE`} >
                {({ loading }) => (loading ? 'Loading document...' : `Download ${session.session} ${session.term_name} Term Exam Timetable!`)}
            </PDFDownloadLink>
        )}
        </>
    )
    
}

export default ExamTimetablePdf;