import React from 'react';
import { Wrapper } from './style';

const ToggleButton = ({name="", value='', checked=false, onChange}) => {
    return(
        <Wrapper>
            <input type="checkbox" name={name} value={value} checked={checked} onChange={onChange} />
            <label for="check" class="button" onClick={onChange}></label>
        </Wrapper>
    )
}

export default ToggleButton;