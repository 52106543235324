import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import Note from 'components/app/school/SmallerComponents/NOTE';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices3 } from 'api/schoolApp/services';

import { addItemToArray} from "components/app/school/actions/array-utils/Add";

const KidsAccount = ({user_id, user_uid, user_type, user, school, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const nav = useNavigate();

    const [accessState, setAccessState] = useState([]);

    const [navigate, setNavigate] = useState('');

    const [preloading, setPreloading] = useState(true);
    const [loading, setLoading] = useState(false);
    // const [reload, setReload] = useState(false);

    const onClickParentAccess = (id) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices3.updateParentAccessToApp(id, school.id).then(res => {
            setSuccess({title:'Success', text:"Access to this kid's app has been acquired successfully.... reloading"});
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }).catch(e => {
            setError({title:'Access Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']})
            setLoading(false);
        })
    }

    useEffect(() => {
        setPreloading(true);
        if(access.length){
            setAccessState(addItemToArray(access));
            setPreloading(false);
            return;
        }
        setPreloading(false);
    }, [access]);

    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [nav, navigate])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    const statBtn = (stat={is_parent_access_app:false, is_setup_app_account:false, is_assigned_subjects:false}, type="", id="") => {
        if(type === "btn"){
            return(
                <>
                {!stat.is_parent_access_app && (
                    <button type="button" onClick={() => onClickParentAccess(id)} className='padd-4px mybtn text-capitalize fw-600 app-text-sec-color font-very-small bg-blue br disp-block'>Aquire app access</button>
                )}
                {!stat.is_setup_app_account && (
                    <>{stat.is_parent_access_app && (<><br /><button onClick={() => setNavigate(`/${school.title}/account/kid/setup`)} type="button" className='padd-4px mybtn text-capitalize fw-600 app-text-sec-color font-very-small bg-black disp-block br'>Start app setup</button></>)}</>
                )} 
                </>
            )
        }
        if(type === "text"){
            return(
                <>
                    <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'> Acquired parent access to kid's app <span className={`fw-700 ${stat.is_parent_access_app ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span> <br />
                    <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'> kid's app setup <span className={`fw-700 ${stat.is_setup_app_account ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span> <br />
                </>
            )
        }
    }

    return(
        <>
        <HeadComponent title='Manage All Your Kids Account' />
        <BreadCrumb />
        <ComponentWrapper>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper>  
            <Grid layout='lauto'>
                <GridContent header_text={`Manage All your kids' account....`} header_icon="fas fa-align-right">
                    {loading && <Loader />}
                    <Section>
                        {preloading ? 
                        (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (
                            <>
                            <Mallet>
                                <span className='font-med disp-block fw-700 app-text-color text-capitalize'>All {school.title} Approved Students</span><br/>
                                <Note>Aquire kid's app access to be able to view the app contents like, report cart, payment and e.t.c</Note>
                                <Note>App access can only be given one kid at a time</Note>
                                <div className='flex-100' style={{width:'0'}}>
                                    <TABLE>
                                        <thead>
                                            <TR>
                                                <TH style={{width:'60%'}}>Kid's Name</TH>
                                                <TH>School - Current Class</TH>
                                                <TH>Action</TH>
                                                <TH>Status</TH>
                                            </TR>
                                        </thead>
                                        <tbody>
                                            {accessState.filter((item) => item.school_id === school.id).map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <TR>
                                                            <TD classname={"text-capitalize align-center"}>{item.last_name} {item.first_name}</TD>
                                                            <TD classname={"text-capitalize"}>{item.school_title} - {item.class_name}</TD>
                                                            <TD>{statBtn({is_parent_access_app:item.is_parent_access_app, is_setup_app_account:item.is_setup_app_account, is_assigned_subjects:item.is_assigned_subjects}, "btn", item.uid)}</TD>
                                                            <TD>{statBtn({is_parent_access_app:item.is_parent_access_app, is_setup_app_account:item.is_setup_app_account, is_assigned_subjects:item.is_assigned_subjects}, "text")}</TD>
                                                        </TR>
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </TABLE>
                                </div>
                            </Mallet>
                            </>
                        )}
                    </Section>
                </GridContent>
            </Grid>
        </ComponentWrapper>
        </>
    )

}

export default KidsAccount;