import styled  from "styled-components";

export const Wrapper = styled.div`
    margin: 10px 0;  
    padding: 10px 15px;
`;

export const Content = styled.div`
    position: relative;
    display: block;
    padding: 10px 5px;
    span::after{
       content : '';
       position: absolute;
       bottom: 0;
       left: 2%;
       width: 15%;
       height: 5px;
       background: var(--appBgColor);
       border-radius: 10px;
       @media (max-width:540px){
        width: 30%;
       }
    }
`;