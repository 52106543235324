import React from "react";
import { Content } from "./style";
import { motion } from "framer-motion";

const Accordion = ({bgColor="app-body-color", reload=false, onClickReload, formStyle, text="Loading", animation = -6, handleClick, handleSubmit, currentAccordion = -1, index = 0, children, style}) => {
    const num =  animation + 'vh';
    var clickEvt;
    if(index === currentAccordion){
        clickEvt = true;
    }
    else{
        clickEvt = false;
    }
    return(
        <>
            
            <Content  style={style} className={bgColor} initial={{marginTop: 5, y:num, opacity:0, duration: 1.2,}} animate={{opacity: 1, marginTop: 10, y:'0vh',  duration: 1.7,}}>
                <div onClick={handleClick} className={clickEvt ? "app-bg-color main-content" : `${bgColor} main-content`}>
                    <div>
                        <span className={clickEvt ? "heading font-super-small app-bg-text-color" : "heading font-slightly-small app-text-color"}> {text} </span>
                    </div>
                    <div>
                        <i className={clickEvt ? "fas fa-times font-super-small btnn app-bg-text-color" : "fas fa-plus font-super-small btnn app-text-color"}></i>
                    </div>
                </div>    
                {clickEvt && reload && <motion.div initial={{opacity: 0, y:'-10vh'}} animate={{opacity: 1, duration:1, y:'0vh'}} className="align-center refresh-div"> <button onClick={onClickReload} className=" font-small"><i className="fas fa-redo"></i></button> <span className="font-super-small">Reload</span></motion.div>}
                <form style={formStyle} onSubmit={handleSubmit}> 
                    
                    {children}
                </form>
            </Content>
                
        </>
    )
}

export default Accordion;