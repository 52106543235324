import styled from "styled-components";

export const Content = styled.div`

        display: inline-block;
        position: relative;
        :hover {
            .menu{
                visibility: visible;
                opacity: 1;
            }
        }  
            .top-menu{
                top: -170%;
                left: -30%;
                @media(max-width: 800px){
                    top: -170%;
                    left: -20%;
                }
                @media(max-width: 540px){
                    top: -145%;
                    left: -30%;
                }
                @media(max-width: 395px){
                    top: -143%;
                    left: -30%;
                }
               
            }
            .left-menu{
                right: 63%;
                top: 10%;
                @media(max-width: 800px){
                    top: 15%;
                    right: 78%;
                }
                @media(max-width: 540px){
                    right: 89%;
                }
                @media(max-width: 395px){
                    right:100%;
                }
               
            }
            .right-menu{
                left: 120%;
                top: -130%;
                @media(max-width: 800px){
                    top: -150%;
                }
            }
            .very-small-size{
                width: 50px;
                max-height: 50px;
                @media(max-width: 800px){
                    width: 40px;
                }
                @media(max-width: 320px){
                    width: 30px;
                }
            }
            .small-size{
                padding: 3px;
                width: 100px;
                @media(max-width: 800px){
                    width: 70px;
                }
                @media(max-width: 320px){
                    width: 50px;
                }
            }
            .med-size{
                padding: 5px 6px;
                width: 150px;
                @media(max-width: 800px){
                    width: 100px;
                }
                @media(max-width: 320px){
                    width: 80px;
                }
            }
            .big-size{
                padding: 5px 10px;
                width: 200px;
                @media(max-width: 800px){
                    width: 150px;
                }
                @media(max-width: 320px){
                    width: 100px;
                }
            }
            .bottom-tooltip{
                ::after{
                    bottom: -17%;
                    left: 45%;
                    transform: rotate(90deg);
                }
            }
            .left-tooltip{
                ::after{
                    top: 50%;
                    right: 100%;
                }
            }
            .right-tooltip{
                ::after{
                    top: 50%;
                    left:92%;
                    transform: rotate(360deg);
                }
            }
            .right-tooltip-o{
                ::after{
                    top: 50%;
                    left:96%;
                    transform: rotate(360deg);
                }
            }
            .menu{
                position: absolute;
                visibility: hidden;
                opacity: 0;
                text-align: center;
                z-index: 9;
                border-radius: 7px;
                transition: .2s ease-in-out;
                
                ::after{
                    content: "";
                    position: absolute; 
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent var(--appBgColor) transparent transparent;
                    @media(max-width: 280px){
                        border-width: 3px;
                    }
                }
                p{
                    color: #ddd;
                    font-size: .6rem;
                    font-weight: 500;
                    text-transform: initial;

                    @media(max-width: 540px){
                        font-size: .5rem;
                    }
                    @media(max-width: 320px){
                        font-size: .4rem;
                    }
                }
            }
            
        
`;