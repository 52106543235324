import React, { useState, useEffect, useMemo, Fragment, useCallback} from 'react';
import { AnimatePresence } from 'framer-motion';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import {default as SelectBox} from "components/app/school/SmallerComponents/selectBox";
import Option from "components/app/school/SmallerComponents/selectBox/MultCheckbox";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";

import Loader from "components/app/school/SmallerComponents/loader";
import Spinner from 'components/app/school/SmallerComponents/spinner';
import BlankLoader from "components/blankLoader";
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices, appServices4 } from 'api/schoolApp/services';

import { groupArray } from "components/app/school/actions/array-utils/Group";
import { addItemToArray, addItemKeyToArrayOnly, addItemToArrayAndInnerArray } from "components/app/school/actions/array-utils/Add";
import { updateArray } from 'components/app/school/actions/array-utils/Update';
import { sortArrayByNumber } from 'components/app/school/actions/array-utils/Sort';
import { deleteItemFromInnerArray } from 'components/app/school/actions/array-utils/Delete';

import {default as success_icon} from 'images/success_icon.svg';

const SchoolCurriculum = ({school_id, school_type, user_id, access={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const initialTopicState = useMemo(() => ({theme:"", name:"", content:[{num:0, value:''}], content_clicked:false}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [state, setState] = useState({subject:'', classes:'', class_id:'', fetch_theme:false})
    const [tableState, setTableState] = useState({theme:''})
    const [themeState, setThemeState] = useState({name:''});
    const [topicState, setTopicState] = useState(initialTopicState);

    const [allClassesData, setAllClassesData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    const [allCurriculumTheme, setAllCurriculumTheme] = useState([]);
    const [allCurriculumTopicData, setAllCurriculumTopicData] = useState(initialPaginationState);
    const [menuDropData, setMenuDropData] = useState({id:'', num:-1, content:[],});
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const [accordion, setAccordion] = useState(-1);

    const [preloading, setPreloading] = useState(false);
    // const [reload, setReload] = useState(true);
    const [tableStateChanged, setTableStateChanged] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [accordionLoading, setAccordionLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [menuLoading, setMenuLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);

    const [submit, setSubmit] = useState('');

    const fetchAllClasses = (stid, sid) => {
        if(stid && sid){
            setError({data:[], title:''});
            appServices4.getAllClassesThought(stid, sid).then(res => {
                setAllClassesData(addItemToArray(groupArray(res.data, 'classes')));
            }).catch(e => {
                setAllClassesData([]);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }

    const fetchAllSubject = (stid, csid) => {
        if(stid && csid){
            setError({data:[], title:''});
            appServices4.getAllSubjectThought(stid, csid).then(res => {
                setAllSubjectData(res.data);
            }).catch(e => {
                setAllSubjectData([]);
                setError({data:["Internal Server Error"], title:"Subject Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }

    const fetchAllCurriculumTheme = (school_id, cid, sub_id) => {
        if(school_id && cid && sub_id){
            setError({data:[], title:''});
            appServices4.getAllSchoolCurriculumTheme(school_id, cid, sub_id).then(res => {
                setAllCurriculumTheme(res.data);
            }).catch(e => {
                setAllCurriculumTheme([]);
                setError({data:["Internal Server Error"], title:"Theme Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }

    const fetchAllCurriculumTopic = useCallback((loader=true) => {
        if(tableState.theme){
            setError({data:[], title:''});
            setPreloading(loader);
            setSpinLoading(!loader);
            appServices4.getAllSchoolCurriculumTopic(tableState.theme).then(res => {
                setPreloading(false);
                setSpinLoading(false);
                setAllCurriculumTopicData({...initialPaginationState, results:addItemToArrayAndInnerArray(res.data, 'content', {changed:false, added:true}, {})});
            }).catch(e => {
                setPreloading(false);
                setSpinLoading(false);
                setAllCurriculumTopicData(initialPaginationState);
                setError({data:[e.response.data.detail], title:"Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
            return;
        }
        setAllCurriculumTopicData(initialPaginationState);
    }, [tableState, initialPaginationState])

    const handleAccordionClick = (i) => {
        setError(initialErrorState);
        if(state.subject && state.classes){
            if(i === accordion){setAccordion(0)} else {setAccordion(i)};
            setThemeState({name:''});
            setTopicState(initialTopicState);
            return;
        }
        setAccordion(-1)
        setError({data:['Class and subject must be selected first'], title:'Error'});
    }

    const handleStateChange = (e) => {
        !e.target.value && setAccordion(-1);
        setAllCurriculumTopicData(initialPaginationState);
        setTableState({...tableState, theme:''})
        if(e.target.name === "classes"){
            if(e.target.value){
                const filterItem =allClassesData.filter((item) => {
                    return item.num === parseInt(e.target.value)
                })
                setState({...state, [e.target.name]:e.target.value, class_id:filterItem[0].classes, subject:''});
                fetchAllSubject(access.uid, filterItem[0].classes__class_section_school);
                return;
            }
            setAllSubjectData([]);
            setState({...state, [e.target.name]:e.target.value, class_id:'', subject:''});
            return;
        }
        setState({...state, [e.target.name]:e.target.value, fetch_theme:true});
    }
    const handleThemeChange = (e) => {
        setThemeState({...themeState, [e.target.name]:e.target.value});
    }
    const handleTableChange = (e) => {
        setTableState({...tableState, [e.target.name]:e.target.value});
        setTableStateChanged(true);
    }
    const handleTopicChange = (e) => {
        setTopicState({...topicState, [e.target.name]:e.target.value});
    }
    const handleTopicContentChange = (e, i=0) => {
        setTopicState({...topicState, content:updateArray(topicState.content, i, {name:'content', value:e.target.value})});
    }
    const handleMenuTopicContentChange = (e, i=0, item={}) => {
        setMenuDropData({...menuDropData, content:updateArray(menuDropData.content, i, {...item, name:e.target.value, changed:true})})
    }
    const handleAccordionSelectBoxClick = (name="", value=false) => {
        setTopicState({...topicState, [name]:value});
    }
    const handleMenuSelectBoxClick = (name="", value=false) => {
        setMenuDropData({...menuDropData, [name]:value, content:addItemToArray(menuDropData.original_content)});
    }
    const handleMenuChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value});
    }
    console.log(menuDropData)
    const onClickContentControl = (i=-1, type="") => {
        let num = i;
        if(type === "add"){
            num++;
            setTopicState({...topicState, content:addItemToArray([...topicState.content, {num:num, name:'', value:''}], {})})
            return;
        }
        const filterItem = topicState.content.filter((item) => {
            return item.num !== i;
        })
        setTopicState({...topicState, content:addItemToArray(filterItem, {})});
    }
    const onClickMenuContentControl = (i=-1, type="") => {
        let num = i;
        if(type === "add"){
            num++;
            setMenuDropData({...menuDropData, content:addItemToArray([...menuDropData.content, {num:num, name:'', added:false, changed:false}], {})})
            return;
        }
        const filterItem = menuDropData.content.filter((item) => {
            return item.num !== i;
        })
        setMenuDropData({...menuDropData, content:addItemToArray(filterItem, {})});
    }
    const onClickEditTopic = (data={id:0}) => {
        setMenuDrop(true);
        setMenuDropData(data);
    }


    const handleThemeSubmit = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setAccordionLoading(true);
        if(state.classes && state.subject){
            const data = {
                classes:state.class_id,
                subject:state.subject,
                name: themeState.name,
                created_by: user_id,
                school: school_id
            }
            appServices4.insertSchoolCurriculumTheme(state.subject, data).then(() => {
                setAccordionLoading(false);
                setSuccess({text:"Data Submitted Successfully", title:"Success"});
                fetchAllCurriculumTheme(school_id, state.class_id, state.subject);
                setThemeState({name:''});
            }).catch((e) => {
                setError({data:[e.response.data.detail], title:'Theme Submit Error'});
                setAccordionLoading(false);
            })
            return;
        }
        setAccordionLoading(false);
        setError({data:['Class and subject must be selected first'], title:'Theme Submit Error'});
    }
    const handleTopicSubmit = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setAccordionLoading(true);
        const filterContent = topicState.content.filter((item) => item.value !== "");
        if(state.classes && state.subject && filterContent.length > 0){
            const content = addItemKeyToArrayOnly(filterContent, {}, 'name', 'value')
            const data = {
                theme: topicState.theme,
                name: topicState.name,
                created_by: user_id,
                content:content
            }
            appServices4.insertSchoolCurriculumTopic(state.subject, data).then(res => {
                setAccordionLoading(false);
                setSuccess({text:"Data Submitted Successfully", title:"Success"});
                setTopicState(initialTopicState);
                fetchAllCurriculumTopic(false);
            }).catch((e) => {
                setError({data:[e.response.data.detail], title:'Topic Submit Error'});
                setAccordionLoading(false);
            })
            return;
        }
        setAccordionLoading(false);
        setError({data:['Class, subject and topic content must be selected first'], title:'Topic Submit Error'});
    }
    const handleThemeUpdate = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        appServices4.updateSchoolCurriculumTheme(state.subject, menuDropData.uid, {name:menuDropData.name}).then(() => {
            setMenuLoading(false);
            setLoading(true);
            setMenuDrop(false);
            fetchAllCurriculumTheme(school_id, state.class_id, state.subject);
            setSuccess({text:"Data Updated Successfully", title:"Success"});
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }).catch((e) => {
            setError({data:[e.response.data.detail], title:'Theme Update Error'});
            setMenuLoading(false);
        })
    }
    const handleTopicUpdate = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        setSubmit('');
        const data = {
            theme: menuDropData.theme,
            name: menuDropData.name,
            content: menuDropData.content,
        }
        appServices4.updateSchoolCurriculumTopic(state.subject, menuDropData.uid, data).then(res => {
            const data = {...allCurriculumTopicData, results:updateArray(allCurriculumTopicData.results, menuDropData.num, {...res.data, content:addItemToArray(res.data.content, {added:true, changed:false})})}
            setSubmit(menuDropData.id);
            setMenuLoading(false);
            setMenuDrop(false);
            setAllCurriculumTopicData(data);
            setSuccess({text:"Data Updated Successfully", title:"Success"});
        }).catch((e) => {
            setError({data:[e.response.data.detail], title:'Topic Update Error'});
            setMenuLoading(false);
        })
    }
    const onClickDeleteTheme = (id="") => {
        setWarning({id:0, title:'', text:''});
        if(id){
            setTimeout(() => {
                setWarning({id:id, click_text:`Delete`, 
                    title:"Warning", text:"All Data In Relation To This Theme Will Be Deleted?",
                    handleClick: () =>{
                        setLoading(true);
                        setError(initialErrorState);
                        setSuccess(initialSuccessState);
                        appServices4.deleteSchoolCurriculumTheme(state.subject, id).then(res => {
                            setLoading(false);
                            setAllCurriculumTopicData(initialPaginationState);
                            setAllCurriculumTheme(allCurriculumTheme.filter((item) => item.uid !== id));
                            setTableState({...tableState, theme:''});
                            setSuccess({text:"All Data Deleted Successfully", title:"Success", click_text:'Undo Delete', handleClick: () => {
                                setLoading(true);
                                appServices.deleteSchoolCurriculumTheme(id).then(() => {
                                    setLoading(false); 
                                    setSuccess({text:"Undo Delete Successfully", title:"Success"})
                                    setTableState({...tableState, theme:id});
                                    fetchAllCurriculumTheme(school_id, state.class_id, state.subject);
                                    fetchAllCurriculumTopic();
                                }).catch((e) => {
                                    setError({data:[e.response.data.detail], title:'Undo Delete Theme Error'});
                                    setLoading(false);
                                })
                            }});
                            setWarning({id:0, title:'', text:''});
                        }).catch((e) => {
                            setError({data:[e.response.data.detail], title:'Theme Delete Error'});
                            setLoading(false);
                            setWarning({id:0, title:'', text:''});
                        })
                    }
                })
            })
        }
        
    }
    const onClickDeleteTopic = (id="", i=-1, item={}) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        setSpinLoading(true);
        const undoDeleteClick = () => {
            setLoading(true);
            appServices4.deleteSchoolCurriculumTopic(state.subject, id).then(() => {
                setLoading(false);
                setAnim({initial:{opacity: 0, duration:0.4},
                    animate:{opacity:1, duration:0.4, background:"rgba(0,255,50,1)"},
                    id:id });
                setAllCurriculumTopicData({...allCurriculumTopicData, results:sortArrayByNumber([...allCurriculumTopicData.results])})
                setSuccess({text:"Undo Delete Successfully", title:"Success"})
                setTimeout(() => {
                    setAnim({initial:{opacity: 0, duration:0.4},
                        animate:{opacity:1, duration:0.4, background:``},
                        id:id });
                }, 200);
            }).catch((e) => {
                setError({data:[e.response.data.detail], title:'Undo Topic Delete Error'});
                setLoading(false);
            })
        };
        appServices4.deleteSchoolCurriculumTopic(state.subject, id).then(() => {
            setLoading(false);
            setSpinLoading(false);
            const filterItem = allCurriculumTopicData.results.filter((item) => item.num !== i);
            setAnim({initial:{opacity: 1, duration:0.4},
                animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
                id:id });
            setTimeout(() => {
                setAllCurriculumTopicData({...allCurriculumTopicData, results:[...filterItem]});
                setTimeout(() => {
                    setAnim({initial:{opacity: 0, duration:0.4},
                        animate:{opacity:1, duration:0.4, background:''},
                        id:id })
                    setSuccess({text:"Data Deleted Successfully", title:"Success", click_text:'Undo Delete', handleClick: () => undoDeleteClick()})
                }, 200);
            }, 300)
        }).catch((e) => {
            setError({data:[e.response.data.detail], title:'Topic Delete Error'});
            setLoading(false);
        })
    }
    const onClickDeleteContent = (id, i=-1, content_num=-1) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        appServices4.deleteSchoolCurriculumContent(state.subject, id).then((res) => {
            setMenuLoading(false);
            setAllCurriculumTopicData({...allCurriculumTopicData, results:deleteItemFromInnerArray(allCurriculumTopicData.results, i, "content", content_num)});
            setMenuDropData({...menuDropData, content:menuDropData.content.filter((item) => item.num !== content_num), original_content:menuDropData.original_content.filter((item) => item.num !== content_num)});
            setSuccess({text:"Data Deleted Successfully", title:"Success"});
        }).catch((e) => {
            setError({data:[e.response.data.detail], title:'Content Delete Error'});
            setMenuLoading(false);
        })
    }
    const onClickSaveContent = (id="", i=-1, item={}) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(item.name && item.added && id){
            setMenuLoading(true)
            const data ={
                name:item.name,
                topic: item.topic,
            }
            appServices4.updateSchoolCurriculumContent(state.subject, id, data).then((res) => {
                setMenuLoading(false);
                fetchAllCurriculumTopic(false);
                setMenuDropData({...menuDropData, content:updateArray(menuDropData.content, i, {...item, name:item.name, changed:false}), original_content:addItemToArray(res.data.content, {changed:false, added:true})});
                setSuccess({text:"Data Updated Successfully", title:"Success"});
            }).catch((e) => {
                setError({data:[e.response.data.detail], title:'Content Save Error'});
                setMenuLoading(false);
            })
            return;
        }
        if(item.name && !item.added && !id){
            setMenuLoading(true);
            console.log('Topic' +item.topic)
            const data ={
                name:item.name,
                topic: menuDropData.id,
            }
            appServices4.insertSchoolCurriculumContent(state.subject, data).then((res) => {
                setMenuLoading(false);
                fetchAllCurriculumTopic(false);
                setMenuDropData({...menuDropData, content:updateArray(menuDropData.content, i, {...item, name:item.name, changed:false, added:true}), original_content:addItemToArray(res.data.content, {changed:false, added:true})});
                setSuccess({text:"Data Saved Successfully", title:"Success"});
            }).catch((e) => {
                setError({data:[e.response.data.detail], title:'Content Save Error'});
                setMenuLoading(false);
            })
            return;
        }
    }
    // useEffect(() => {
    //     if(access.id && reload){
    //         setReload(false);
    //         fetchAllClasses(access.uid, school_id);
    //     }
    // }, [school_id, access, reload]);

    useEffect(() => {
        if(state.classes && state.subject && state.fetch_theme){
            fetchAllCurriculumTheme(school_id, state.class_id, state.subject);
            setState((prev) => prev.classes && {...prev, fetch_theme:false});
        }
        if(tableStateChanged){
            fetchAllCurriculumTopic(false);
            setTableStateChanged(false);
        }
    }, [state, school_id, fetchAllCurriculumTopic, tableStateChanged])
    useEffect(() => {
        if(reloadData && access.id){
            fetchAllClasses(access.uid, school_id);
            setState({subject:'', classes:'', class_id:''});
            setAllCurriculumTopicData(initialPaginationState);
            setReloadData(false);
        }
    }, [reloadData, school_id, access, initialPaginationState])

    console.log(allClassesData, allSubjectData)

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmit("none");
        }, 1000 * 15)
        
        return(
            <div className="nav-dd-anim" style={{display:submit}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }

    const insertClassOption = allClassesData.map((item, i) => {
        return(
            <option key={i} value={item.num}>{item.classes__name}</option>
        )
    })
    const insertSubjectOption = allSubjectData.map((item, i) => {
        return(
            <option key={i} value={item.subject}>{item.subject_name} ({item.subject_code})</option>
        )
    })
    const insertThemeOption = allCurriculumTheme.length ? allCurriculumTheme.map((item, i) => {
        return(
            <option key={i} value={item.id}>{item.name}</option>
        )
    }) : (
        <option value={""}>No Record Found</option>
    );
    const insertThemeOption2 = allCurriculumTheme.length ? allCurriculumTheme.map((item, i) => {
        return(
            <option key={i} value={item.uid}>{item.name}</option>
        )
    }) : (
        <option value={""}>No Record Found</option>
    );

    const insertContentOptions = topicState.content.map((item, i) => {
        return(
            <div key={i} className='disp-flex wrap'>
                <Option required type="textarea" name='content' onChange={e => handleTopicContentChange(e, item.num)} value={item.value} classname='font-very-small form-control-theme app-text-color' contentStyle={{flex:'0 0 70%', maxWidth:'70%'}}>
                    {item.value}
                </Option>
                <div className='flex-30'>
                    <button type="button" style={{padding:'5px', marginRight:'3px'}} onClick={() => onClickContentControl(item.num, 'add')} className="btn br hover-opacity app-bg-color font-super-small app-bg-text-color"><i className='fas fa-plus'></i></button>
                    {item.num > 0 && <button type="button" style={{padding:'3px'}} onClick={() => onClickContentControl(item.num, 'remove')} className="btn br hover-opacity bg-red font-super-small app-bg-text-color"><i className='fas fa-minus'></i></button>}
                </div>
            </div>
        )
    })
    const insertMenuContentOptions = menuDropData.content.map((item, i) => {
        const last_obj = menuDropData.content.length - 1;
        return(
            <div key={i} className='disp-flex wrap' style={{width:'100%'}}>
                <Option required type="textarea" name='content' onChange={e => handleMenuTopicContentChange(e, item.num, item)} value={item.name} classname='font-very-small form-control-theme app-text-color' contentStyle={{flex:'0 0 70%', maxWidth:'70%'}}>
                    {item.name}
                </Option>
                <div className='flex-30'>
                    {item.changed && <button type="button" style={{padding:'5px', marginRight:'3px'}} onClick={() => onClickSaveContent(item.id ? item.id : '', item.num, item)} className="btn br hover-opacity app-bg-color font-super-small app-bg-text-color">Save</button>}
                    {last_obj === i && <button type="button" style={{padding:'5px', marginRight:'3px'}} onClick={() => onClickMenuContentControl(item.num, 'add')} className="btn br hover-opacity app-bg-color font-super-small app-bg-text-color"><i className='fas fa-plus'></i></button>}
                    {item.added && <button type="button" style={{padding:'5px', background:'red'}} onClick={() => onClickDeleteContent(item.uid, menuDropData.num, item.num)} className="btn br hover-opacity font-super-small app-bg-text-color">Delete</button>}
                    {!item.added && <button type="button" style={{padding:'3px'}} onClick={() => onClickMenuContentControl(item.num, 'remove')} className="btn br hover-opacity bg-red font-super-small app-bg-text-color"><i className='fas fa-minus'></i></button>}
                </div>
            </div>
        )
    })
    // Energy transfer when work is done
    const insertContentValue = topicState.content.map((item) => item.value ? item.value+ '\n' : '');
    const insertMenuContentValue = menuDropData.content.map((item) => item.name ? item.name+ '\n' : '');

    const viewStringFromArray = (arr=[], arr_key="", line_break=",") => {
        let str = "";
        for(let i = 0; i < arr.length; i++){
            if(typeof arr[i] !== "object"){
                str += arr[i] + line_break;
            } else {
                str += arr[i][arr_key] + line_break ;
            }
        }
        return str.trimEnd(line_break);
    }

    const insertTableData = allCurriculumTopicData.results.map((item, i) => {
        i++;
        const data = {num:item.num, name:item.name, id:item.id, uid:item.uid, content:item.content, original_content:item.content, theme:item.theme, content_clicked:false}
        return(
            <Fragment key={i}>
                <Tr sn={true} dlt_btn={true} edit_btn handleEditClick={() => onClickEditTopic(data)} handleDeleteClick={() => onClickDeleteTopic(item.uid, item.num, item)}
                 animation={(item.uid === anim.id) ? {initial:{...anim.initial}, animate:{...anim.animate}} : {initial:{opacity:0, duration:0.3}, animate:{opacity:1, duration:0.4, background:``}}}>
                    <div className="c-data sn">{item.id === submit ? updateSuccessIcon() : (<span className="font-very-small">{i}</span>)}</div>
                    <div className="c-data"><span className="font-very-small">{item.name}</span></div>
                    <div className="c-data" style={{width:'70%'}}><span className="font-very-small" style={{whiteSpace:'pre-wrap'}}>{viewStringFromArray(item.content, "name", '\n').length > 70 ? `${viewStringFromArray(item.content, "name", '\n').slice(0, 70)}....` :  viewStringFromArray(item.content, "name", '\n')}</span></div>
                    <TdWrapper header_text={item.name.length > 22 ? item.name.slice(0, 22)+'...' : item.name} header_num={i}>
                        <Td heading="Topic Name" text={item.name} />
                        <Td heading="Topic Content" styleText={{whiteSpace:'pre-wrap'}} text={viewStringFromArray(item.content, "name", '\n')} />
                    </TdWrapper>
                </Tr>
            </Fragment>
        )
    })

    return(
        <>
            <HeadComponent title='Manage School Curriculum' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Section>
                    <Mallet>
                        <div className="flex-50-line"><label className='font-super-small'>Select Class: 
                            <select name="classes" value={state.classes} onChange={e => handleStateChange(e)} className='form-control-theme font-slightly-small' >
                                <option value={""}>Select Class</option>
                                {insertClassOption}
                            </select></label>
                        </div>
                        <div className="flex-50-line"><label className='font-super-small'>Select Subject: 
                            <select name="subject" value={state.subject} onChange={e => handleStateChange(e)} className='form-control-theme font-slightly-small' >
                                <option value={""}>Select Subject</option>
                                {insertSubjectOption}
                            </select></label>
                        </div>
                    </Mallet>
                    {/* <Note>Select subject and class before proceeding </Note> */}
                </Section>
                <Grid>
                    <GridContent header_text={`Add Curriculum For Subject Class`} header_icon="fas fa-plus">
                        {accordionLoading && <Loader />}
                        <Accordion handleSubmit={(e) => handleThemeSubmit(e)} index={1} text={`Add Theme`}  handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && (
                                <>
                                <AccordionFormData required={true} handleChange={(e) => handleThemeChange(e)} value={themeState.name} tag="input" type="text" name="name"  classname="form-control-theme font-slightly-small" placeholder="Enter Subject Theme Name/Title" />
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                </>
                            )}
                        </Accordion>
                        <Accordion index={2} text={`Add Topic For Theme`} handleSubmit={e => handleTopicSubmit(e)}  handleClick={() => handleAccordionClick(2) } currentAccordion={accordion}>
                            {accordion === 2 && (
                                <>
                                <AccordionFormData required={true} handleChange={(e) => handleTopicChange(e)} value={topicState.theme} tag="select" name="theme"  classname="form-control-theme font-slightly-small" >
                                    <option value={""}>Select Theme</option>
                                    {insertThemeOption}
                                </AccordionFormData>
                                <AccordionFormData required={true} handleChange={(e) => handleTopicChange(e)} value={topicState.name} tag="input" type="text" name="name"  classname="form-control-theme font-slightly-small" placeholder="Enter Topic Name" />
                                <SelectBox type="textarea" required={true} value={insertContentValue} placeholder="Enter Topic Content" onClick={() => handleAccordionSelectBoxClick("content_clicked", !topicState.content_clicked)}  clicked={topicState.content_clicked}>
                                    <>
                                    {topicState.content_clicked && (
                                        <>
                                            {insertContentOptions}
                                        </>
                                    )}
                                    </>
                                </SelectBox>
                                <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                </>
                            )}
                        </Accordion>
                    </GridContent> 
                    <GridContent header_text={`Manage Curriculum`} header_icon="fas fa-tasks">
                        {loading && <Loader />}
                        <Section icon="fas fa-tasks" header={`Manage School Curriculum`}>
                            <Mallet >
                                <div className="flex-100"><label className='font-super-small'>Select Theme/Title: 
                                    <select onChange={e => handleTableChange(e)} name="theme" value={tableState.theme} className='form-control-theme font-slightly-small' >
                                        <option value={""}>Select Subject Theme/Title {allCurriculumTheme.length > 0 && '...'}</option>
                                        {state.subject ? insertThemeOption2 : (<option value="">Select class and subject first</option>)}
                                    </select></label>
                                </div>
                            </Mallet>
                            {preloading ? (<ul>
                                <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                {tableState.theme && (
                                    <div style={{width:'100%'}} className='disp-flex wrap jc-sp'>
                                        <div>
                                            <span className="font-med fw-700 disp-block padd-4px app-text-color">{allCurriculumTheme.filter((item) => item.uid === tableState.theme).map((item, i) => (<Fragment key={i}>{item.name}</Fragment>))}</span>
                                        </div>
                                        <div>
                                            <button type="button" onClick={() => { 
                                                const filterItem = allCurriculumTheme.filter((item) => item.uid === tableState.theme);
                                                const data = {id:filterItem[0].id, uid:tableState.theme, name:filterItem[0].name, content:[], original_content:[], type:'theme'}
                                                 onClickEditTopic(data)}} style={{color:'#00057a', marginRight:'3px'}} className=' font-super-small btn bordered-blue hover-opacity br'>Edit <i className='fas fa-pen'></i></button>
                                            <button type="button" className=' font-super-small btn bordered-red red hover-opacity br' onClick={() => onClickDeleteTheme(tableState.theme)}>Delete <i className='fas fa-trash'></i></button>
                                        </div>
                                    </div>
                                )}
                                <Table>
                                    {spinLoading && <Spinner />}
                                    <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Topic"},  {text:"Content", style:{width:'70%'}}]} />
                                    {allCurriculumTopicData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                        (<>{insertTableData}</>)
                                    }
                                </Table>
                            </>
                            )}
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false);}} header_text={menuDropData.type==="theme" ? "Edit Theme" : "Edit Topic"} header_icon={"fas fa-pen"}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    {menuDropData.type === "theme" && (
                                        <form onSubmit={e => handleThemeUpdate(e)}>
                                            <div className='row'>
                                                <div className="fg-all">
                                                    <label className="font-super-small">Theme Name </label>
                                                    <input onChange={e => handleMenuChange(e)} type="text" name="name" className="font-very-small app-theme form-control-theme" placeholder="Enter Theme Name" value={menuDropData.name} />
                                                </div>
                                                <div className="fg-all btn-g align-center">
                                                    <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                        Update <i className="fas fa-paper-plane"></i> </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                    {!menuDropData.type && (
                                        <form onSubmit={e => handleTopicUpdate(e)}>
                                            <div className='row'>
                                                <div className="fg">
                                                    <label className="font-super-small">Theme/Title </label>
                                                    <select onChange={e => handleMenuChange(e)} className='font-very-small app-theme form-control-theme' name="theme" value={menuDropData.theme}>
                                                        <option value="">Select Subject Theme</option>
                                                        {insertThemeOption}
                                                    </select>
                                                </div>
                                                <div className="fg">
                                                    <label className="font-super-small">Topic Name </label>
                                                    <input onChange={e => handleMenuChange(e)} type="text" name="name" className="font-very-small app-theme form-control-theme" placeholder="Enter Topic Name" value={menuDropData.name} />
                                                </div>
                                                <div className='fg-all'>
                                                    <SelectBox styleInput={{height:'100px', width:'100%'}} styleWrapper={{maxHeight:'250px'}} type="textarea" required={true} value={insertMenuContentValue} placeholder="Edit Topic Content" onClick={() => handleMenuSelectBoxClick("content_clicked", !menuDropData.content_clicked)}  clicked={menuDropData.content_clicked}>
                                                    <>
                                                    {menuDropData.content_clicked && (
                                                        <>
                                                            {insertMenuContentOptions}
                                                        </>
                                                    )}
                                                    </>
                                                    </SelectBox>
                                                </div>
                                                <div className="fg-all btn-g align-center">
                                                    <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                        Update <i className="fas fa-paper-plane"></i> </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                    
                                </>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent> 
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolCurriculum;