
export const nth = (num=0, pos_only=false) => {
    let th = [4, 5, 6, 7, 8 , 9, 0]
    let nd = [2]
    let st = [1]
    let rd = [3]

    if(num.toString().length > 1){
        const last_num = num.toString()[num.toString().length - 1];
        const snd_last_num = num.toString()[num.toString().length - 2];
        const filterTh = th.filter((item) => item.toString() === last_num);
        const filterNd = nd.filter((item) => item.toString() === last_num);
        const filterSt = st.filter((item) => item.toString() === last_num);
        const filterRd = rd.filter((item) => item.toString() === last_num);
        if(filterTh.length > 0){
            return pos_only ? '<sup>th</sup>' : `${num.toString()}<sup>th</sup>`;
        }
        if(filterNd.length > 0){
            
            if(snd_last_num === "1"){
                return !pos_only ? `${num.toString()}<sup>th</sup>` : `<sup>th</sup>`;
            }
            return !pos_only ? `${num.toString()}<sup>nd</sup>` : `<sup>nd</sup>`;
        }
        if(filterRd.length > 0){
            if(snd_last_num === "1"){
                return !pos_only ? `${num.toString()}<sup>th</sup>` : `<sup>th</sup>`;
            }
            return !pos_only ? `${num.toString()}<sup>rd</sup>` : `<sup>rd</sup>`;
        }
        if(filterSt.length > 0){
            if(snd_last_num === "1"){
                return !pos_only ? `${num.toString()}<sup>th</sup>` : `<sup>th</sup>`;
            }
            return !pos_only ? `${num.toString()}<sup>st</sup>` : `<sup>st</sup>`;
        }
        return !pos_only ? `${num.toString()}<sup>th</sup>` : `<sup>th</sup>`;
    }
    if(th.filter((item) => item.toString() === num.toString()).length > 0){
        return !pos_only ?`${num.toString()}<sup>th</sup>` : `<sup>th</sup>`;
    }
    if(nd.filter((item) => item.toString() === num.toString()).length > 0){
        return !pos_only ?`${num.toString()}<sup>nd</sup>` : `<sup>nd</sup>`;
    }
    if(rd.filter((item) => item.toString() === num.toString()).length > 0){
        return !pos_only ?`${num.toString()}<sup>rd</sup>` : `<sup>rd</sup>`;
    }
    if(st.filter((item) => item.toString() === num.toString()).length > 0){
        return !pos_only ?`${num.toString()}<sup>st</sup>` : `<sup>st</sup>`;
    }
}