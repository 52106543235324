import { StyleSheet } from "@react-pdf/renderer";

export const style = StyleSheet.create({
    label:{
        fontSize:'9px',
        fontWeight:600,
        transform: 'translateY(-10px)',
        backgroundColor: '#fff',
        color:'#000',
        position: 'absolute',
    },
    wrapper:{
        borderWidth: '2px',
        borderColor: '#000',
        padding:'3px',
        borderRadius:'3px',
        position:'relative',
        marginBottom:'13px',
    },
    tarea:{
        fontSize:'11px',
    },
})