import React from "react";
import { Content } from "./style";
const BlankLoader = ({num, circle_style, text_style, cont_style, hide_text=false, hide_circle=false }) => {
    const insertLoader = (numLoader) => {
        const rows = [];
        for(let i=0; i < numLoader; i++){
            rows.push(<li key={i} style={cont_style}>{hide_circle ? "" : (<i className="circle" style={circle_style}></i>) } <span className={hide_text ? "text h-led" : "text"} style={text_style}></span></li>)
        }
        return rows
    }
    return(
        <Content>
            {insertLoader(num)}
        </Content>
    )
}

export default BlankLoader;