import React, {useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// import { Link } from "react-router-dom";
import { Wrapper, Content } from "./style";

import Chat from "components/app/chat/chat";
import Menu from "components/menu";
import MenuBackDrop from 'components/menuBackDrop';
// import emife_logo from 'images/emife.png';

const HorizontalNav = ({title="", img_src="", user_id, user, user_type, img_alt="logo", school_id, school}) => {
    const [viewLogOut, setViewLogOut] = useState(false);
    const [isInitChat, setIsInitChat] = useState(false);

    const nav = useNavigate();
    const [searchParam] = useSearchParams();

    const onLogout = () => {
        localStorage.removeItem('access');
        window.location.href = '/';
    }
    return(
        <>
        <Wrapper className="app-theme">
            <Content>
                <div className="school-logo">
                    <img src={img_src} alt={img_alt} /> 
                    {!school.is_trial ? (
                        <span className="header font-big">{title.length > 6 ? `${title.slice(0, 5).toUpperCase()}..` : title.toUpperCase()} <span className="font-small">School</span></span>
                    ) : (
                        <span className="header font-big">{`${title}`}<sup><span className="font-super-small red">Trial</span></sup></span>
                    )}
                    
                </div>
                <div className="others">
                    <Menu  bgColor="app-bg-color" i="far fa-comment-dots font-big app-bg-color-text norm"
                    tooltip="right-tooltip" position="left-menu" text="Chat Room" onClick={() => {setIsInitChat(true); nav(`?bt=chat`)}} />
                    <Menu  bgColor="app-bg-color" i="far fa-envelope font-big app-bg-color-text norm"
                    tooltip="right-tooltip" position="left-menu" text="Notifications" />
                    <Menu  bgColor="app-bg-color" i="fas fa-power-off font-big app-bg-color app-bg-text-color b-1-app-bg"
                    tooltip="right-tooltip" size="small-size" position="left-menu" text="Settings" onClick={() => setViewLogOut(!viewLogOut)}>
                        {viewLogOut && (
                        <MenuBackDrop close={true} size="small-size end" theme="app-theme" handleClose={() => setViewLogOut(false)} >
                            <div className="cont">
                                <div className="inner-cont">
                                    <div className="content">
                                        <div className="aside">
                                            <span className="fas fa-power-off icon font-small red"></span>
                                        </div>
                                        <div className="aside">
                                            <span className="text font-super-small" onClick={() => onLogout()}>Logout</span>
                                        </div>
                                    </div>
                                    {/* <div className="header">
                                        <span className="heading font-super-small">Activity Feeds</span>
                                    </div>
                                    <div className="content">
                                        <div className="aside">
                                            <span className="far fa-comment-dots icon font-small app-bg-color-text"></span>
                                        </div>
                                        <div className="aside">
                                            <span className="text font-super-small">Chat Room</span>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="aside">
                                            <span className="far fa-envelope icon font-small app-bg-color-text"></span>
                                        </div>
                                        <div className="aside">
                                            <span className="text font-super-small">Notifications</span>
                                        </div>
                                    </div>
                                    <div className="header">
                                        <span className="heading font-super-small">Sections</span>
                                    </div>
                                    <Link to="/">
                                        <div className="content">
                                            <div className="aside">
                                                <img className="icon" src={emife_logo} alt="Emife Logo" />
                                            </div>
                                            <div className="aside">
                                                <span className="text font-super-small">Emife</span>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/">
                                        <div className="content">
                                            <div className="aside">
                                                <img className="icon" src={emife_logo} alt="Emife Logo" />
                                            </div>
                                            <div className="aside">
                                                <span className="text font-super-small">Emife</span>
                                            </div>
                                        </div>
                                    </Link>     */}
                                </div>    
                            </div>
                        </MenuBackDrop>)
                        }
                    </Menu>
                </div>
            </Content>
        </Wrapper>
        {isInitChat ? (
            <AnimatePresence>
                <Chat user_id={user_id} user_type={user_type} school_id={school_id} user={user} school={school} />
            </AnimatePresence>
        ) : (
            <>
            {searchParam.get('bt') === "chat" && (
                <AnimatePresence>
                    <Chat user_id={user_id} user_type={user_type} school_id={school_id} user={user} school={school} />
                </AnimatePresence>
            )}
            </>
        )}
        </>
    )
}

export default HorizontalNav;