import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    width: 120px;
    border-radius: 5px;
    max-height: 150px;
    background: var(--appOppTheme);
    overflow-y: auto;
    color: var(--appOppTextColor);

    @media(max-width:540px){
        width: 90px;
        max-height: 100px;
    }
`;