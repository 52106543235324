import styled from "styled-components";

export const NotificationWrapper = styled.ul`
    
    padding: 0;
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99999999999999999;
    
     
    .border-blue{
        border-left: 5px solid var(--appBgColor);
    }
    .border-green{
        border-left: 5px solid #057a00;
    }
    .border-yellow{
        border-left: 5px solid #a05700;
    }
    .border-red{
        border-left: 5px solid red;
    }
    .red{
        color: red;
    }
    .green{
        color: #057a00;
    }
    .yellow{
        color: #a05700;
    }
    .blue{
        color: var(--appBgColor);
    }
    .btn-br-green{
        border: 1px solid #057a00;
    }
    .btn-br-blue{
        border: 1px solid #00057a;
    }
    .btn-br-red{
        border: 1px solid red;
    }
    .btn-br-yellow{
        border: 1px solid #a05700;
    }
    li {
    padding: 20px;
    margin: 0;
    width: 350px;
    background: #151515;
    margin: 10px;
    flex: 0 0 100px;
    position: relative;
    border-radius: 5px;
    color: #ddd;
   
    @media(max-width: 780px){
        width: 300px;
    }
    @media(max-width: 540px){
        width: 280px;
    }
    @media(max-width: 320px){
        width: 220px;
    }
        .close-cont{
            position: absolute;
            top: 2%;
            right: 3%;

            i{
                cursor: pointer;
                color: #aaa;
            }
        }
        span.text{
            display: inline-block;
            text-transform: capitalize;
            i{
                padding-right: 3px;
            }
        }
        span.heading{
            display: block;
            font-weight: 600;
            text-transform: capitalize;
            margin-top: 5px;
            color: #aaa;
            text-align: right;
        }
        .btn-cont{
            margin-top: 10px;
            text-align: right;
            
            button, a{
                padding: 5px;
                cursor: pointer;
                border-radius: 5px;
                outline: none;
                text-decoration: none;
                color: #ddd;
                background: transparent;
                transition: .4s ease;

                :hover{
                    color: #bbbbbb;
                }
            }
        }
    }
`;