import React from "react";
import { OuterWrapper } from "../header/style";
import { Wrapper, Content } from './style';

export const BtnUIWrapper = ({children, outer_classname="", is_fixed=false, img_alt="", img_src=""}) => {
    return(
        <>
        <OuterWrapper style={{position:'relative'}}>
            <Wrapper className={`${outer_classname} ${is_fixed ? 'pos-fixed' : ''}`}>
                
                {children}
            </Wrapper>
        </OuterWrapper>
        </>
    )
}

const BtnUI = ({name="msg", value="", onChange, onChangeImageFile, image_btn=true, onSubmit}) => {
    return(
        <Content>
            <div className="disp-block inp-cont">
                <textarea type="text" placeholder="Message..."  onChange={onChange} name={name} value={value} className="inp font-very-small" />
            </div>
            <div className="disp-block btn-wrapper">
                <div className="btn-cont">
                    {image_btn && (
                        <div className="disp-block padd-r-4px">
                            <input type="file" name="image" className="image-file" id="image-file" onChange={onChangeImageFile} />
                            <label for="image-file"><i  className="far fa-image font-slightly-small" style={{color:'#aaa'}}></i></label>
                        </div>
                    )}
                    
                    <div className="disp-block padd-l-4px">
                        <i className="fas fa-paper-plane font-slightly-small app-bg-text-color send_btn" onClick={onSubmit}></i>
                    </div>
                </div>
                
            </div>
        </Content>
    )
}

export default BtnUI;