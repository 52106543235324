import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import TD from 'components/app/school/SmallerComponents/table_html/td';
// import Note from 'components/app/school/SmallerComponents/NOTE';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices2, appServices3,  appServices5 } from 'api/schoolApp/services';

import { addItemToArray} from "components/app/school/actions/array-utils/Add";
import { numberDigit } from 'components/app/school/actions/numberDigit';

const ResultCheckerPin = ({user_id, user_uid, user_type, user, school, school_id, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialMalletState = useMemo(() => ({session:'', classes:'', school_branch:'', pre:false, curr_session:'', active_session:false, reload:false, student:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [malletState, setMalletState] = useState(initialMalletState);
    const [genState, setGenState] = useState({id:0, uid:''})

    const nav = useNavigate();

    // const [accessState, setAccessState] = useState([]);
    const [allSessionData, setAllSessionData] = useState([]);
    const [allKidData, setAllKidData] = useState([]);
    const [allKidKeyData, setAllKidKeyData] = useState([]);

    const [navigate, setNavigate] = useState('');

    const [preloading, setPreloading] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);

    const fetchAllSchoolSession = (sid) => {
        if(sid){
            setError({data:[], title:''});
            setPreloading(true);
            appServices5.getCurrentSession(sid).then(sess_res => {
                if(sess_res.data.id){
                    setPreloading(false);
                    setMalletState((prev) => typeof prev === "object" && {...prev, session:sess_res.data.id, curr_session:sess_res.data.id, pre:true});
                }
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", handleClick: () => setReload(true)});
            })
            appServices5.getAllSessionM(sid).then(res => {
                setAllSessionData(res.data);
                setPreloading(false);
            }).catch(e => {
                setPreloading(false);
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllKids = (id, ssid, user_type, oid) => {
        if(id && ssid && user_type === "parent"){
            setError({data:[], title:''});
            appServices3.getAllKidResult(id, ssid).then(res => {
                setAllKidData(addItemToArray(res.data));
            }).catch(e => {
                setAllKidData([]);
                setError({data:["Internal Server Error"], title:"Data Fetch Error"});
            })
            return;
        }
        if(oid && ssid && user_type === "student"){
            setError({data:[], title:''});
            appServices2.getMinorResult(oid, ssid).then(res => {
                setAllKidData(addItemToArray([res.data]));
            }).catch(e => {
                setAllKidData([]);
                setError({data:["Internal Server Error"], title:"Data Fetch Error"});
            })
            return;
        }
        setAllKidData([]);
    } 
    const fetchAllKidKeys = (id, sid, user_type, oid) => {
        if(id && sid && user_type === "parent"){
            setError({data:[], title:''});
            appServices3.getAllKidResultWithKey(id, sid).then(res => {
                setAllKidKeyData(addItemToArray(res.data));
            }).catch(e => {
                setAllKidKeyData([]);
                setError({data:["Internal Server Error"], title:"Data Fetch Error"});
            })
            return;
        }
        if(oid && sid && user_type === "student"){
            setError({data:[], title:''});
            appServices2.getAllResultKey(oid, sid).then(res => {
                setAllKidKeyData(addItemToArray(res.data));
            }).catch(e => {
                setAllKidKeyData([]);
                setError({data:["Internal Server Error"], title:"Data Fetch Error"});
            })
            return;
        }
        setAllKidKeyData([]);
    } 
    const handleKeyGenerateClick = (id, uid) => {
        setGenState({id:id, uid:uid})
    }
    const onClickKeyGenerator = () => {
        if(genState.id){
            setError(initialErrorState);
            setSuccess(initialSuccessState);
            setLoading(true);
            const data={access_exam:genState.id, created_by:user_id}
            appServices2.generateStudentResultKey(data).then(res => {
                setLoading(false);
                setGenState({id:0, uid:''});
                setAllKidKeyData(addItemToArray([...allKidKeyData, res.data]));
                setSuccess({title:'Success', text:'Your Key Has Been Generated Successfully'});
            }).catch(e => {
                setLoading(false);
                setError({title:'Data Upload Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
        }
    }

    useEffect(() => {
        if(reload){
            fetchAllSchoolSession(school_id);
            setReload(false);
        }
    }, [reload, school_id])
    useEffect(() => {
        if(dataTableLoading && malletState.session && access.uid && user_type){
            fetchAllKids(user_uid, malletState.session, user_type, access.uid);
            fetchAllKidKeys(user_uid, school_id, user_type, access.uid);
            setDataTableLoading(false);
        }
    }, [user_uid, access, user_type, school_id, dataTableLoading, malletState])
    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [navigate, nav]);


    const handleMalletChange = (e) => {
        if(!e.target.value){
            setMalletState({...malletState, [e.target.name]:e.target.value});
            setAllKidKeyData([]);
            setAllKidData([]);
            return;
        }
        setMalletState({...malletState, [e.target.name]:e.target.value});
        setDataTableLoading(true);
    }

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertSessionOption = allSessionData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.session} - ${item.term_name} term`}</option>
        )
    })
    const insertData = () => {
        return(
            <>
                <div className='disp-flex wrap'>
                <div className='flex-100'>
                <TABLE style={{width:'100%'}}>
                    <thead>
                        <TR>
                            <TH style={{width:'80%'}}><span className="font-slightly-small" >Adm No - Name</span></TH>
                            <TH style={{width:'20%'}}><span className="font-slightly-small">Pin</span></TH>
                        </TR>
                    </thead>
                    <tbody>
                    {allKidData.map((item, i) => {
                        const key_arr = allKidKeyData.filter((it) => it.access_exam === item.id);
                        const full_name = item.user_last_name ? `${item.user_last_name} ${item.user_first_name}` : `${item.last_name} ${item.first_name}`;
                        return(
                            <Fragment key={i}>
                                <TR>
                                    <TD> <span className='font-slightly-small app-text-color fw-600'>{numberDigit(item.adm_no, 4)}</span> - <span className='font-slightly-small text-capitalize app-text-color fw-500'>{full_name}</span></TD>
                                    <TD>
                                        {key_arr.map((item2, j) => {
                                            return(
                                                <Fragment key={j}><span className='font-slightly-small app-text-color fw-600'>{item2.key}</span><br /></Fragment>
                                            )
                                        })}
                                        {key_arr.length < 1 && <button type="button" className='btn br font-very-small bg-green hover-opacity' onClick={() => handleKeyGenerateClick(item.id, item.uid)}>Generate Key</button>}
                                    </TD>
                                </TR>
                            </Fragment>
                        )
                    })}
                    </tbody>
                </TABLE>
                </div>
                </div>
                <div className='disp-flex wrap'>
                    <div className='flex-100 align-center' >
                        <Link to={`/result_checker`} target={"_blank"}>Click here to check result</Link>
                    </div>
                </div>
            </>
        )
    }
    const gen_state_main = genState.id ? allKidData.filter((it) => it.id === genState.id)[0] : {id:0, uid:''}
    return(
        <>
        <HeadComponent title="Create Your Kid's Result Checker Key" />
        <BreadCrumb />
        <ComponentWrapper>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            <Grid layout='lauto'>
                <GridContent header_text={`Create Your Kid's Result Checker Key`} header_icon="fas fa-align-right">
                    {loading && <Loader />}
                    <Section>
                    {preloading ? (<ul>
                    <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                    </ul>) : 
                    (
                    <>
                        <Mallet>
                            <div className="flex-100"><label className='font-super-small'>Select Session/Term: <br />
                                <select name="session" onChange={e => handleMalletChange(e)} value={malletState.session} className='font-slightly-small text-capitalize form-control-theme'>
                                    <option value={''}>Select Session/term</option>
                                    {insertSessionOption}
                                </select>
                                </label>
                            </div>
                        </Mallet>
                    </>
                    )
                    }
                    </Section>
                    <Section>
                        
                            {genState.id ? (
                                <>
                                <div className='disp-flex wrap'>
                                    <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med text-capitalize'> {gen_state_main.user_last_name ? gen_state_main.user_last_name : gen_state_main.last_name} {gen_state_main.user_first_name ? gen_state_main.user_first_name : gen_state_main.first_name}'s Pin Generator</span></div>
                                </div>
                                <div className='disp-flex wrap'>
                                <div className="flex-100">
                                    <TABLE>
                                        <thead>
                                            <TR>
                                                <TH style={{width:'100%'}}><span className="font-slightly-small" >Type</span></TH>
                                                <TH style={{width:'10%'}}><span className="font-slightly-small" >Amount</span></TH>
                                            </TR>
                                        </thead>
                                        <tbody>
                                            <TR>
                                                <TD><span className="font-slightly-small" >Card Pin</span></TD>
                                                <TD><span className="font-slightly-small" >Free</span></TD>
                                            </TR>
                                            <TR>
                                                <TD><span className="font-slightly-small" >Tax</span></TD>
                                                <TD><span className="font-slightly-small" >0.00</span></TD>
                                            </TR>
                                        </tbody>
                                    </TABLE>
                                </div>
                                </div>
                                <div className='disp-flex wrap'>
                                    <div className='flex-100 align-center padd-4px' style={{marginTop:'4px'}}><button type="button" className='mybtn font-very-small app-bg-color app-bg-text-color' onClick={() => onClickKeyGenerator()}>Generate (FREE)</button></div>
                                </div>
                                </>
                            ) : (
                                <>
                                {allKidData.length > 0 ? insertData() : (<> <div className='disp-flex wrap'><div className='flex-100 align-center'><span className='red fw-700 font-small '>No Record Found</span></div></div></>)}
                                </>
                            )}
                    </Section>
                </GridContent>
            </Grid> 
        </ComponentWrapper>
        </>
    )
}

export default ResultCheckerPin;