import styled from "styled-components";

export const OuterWrapper = styled.div`
    width:100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Georgia, 'Times New Roman', Times, serif;
    .print{
        display: none;
    }
    @media print{
        .print{
            display: block;
        }
        .no-print{
            display: none !important;
        }
    }
`;
export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    .big{
        width: clamp(60%, 600px, 85%);
        position: relative;
        margin-top: .5rem;
    }
`;

export const InnerWrapper = styled.div`
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0,0,0,0.4);
    padding: 7px;
`;