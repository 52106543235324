import React from 'react';
import parse from 'html-react-parser';
//import { colorThemePallete } from 'components/app/school/embeddedApps/web/action/colorManager';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

import {Container, HomeContent} from "components/app/school/embeddedApps/web/component/style";

const PageByPageEditor = ({content_type1="", bg_theme="", is_add_preface_underbelly=true, img_src1="", img_alt1="", is_add_image1=false, is_delete_image1=false, img_value1="", onChangeImg1, onToggleImg1, onDeleteImg1, preface1="", preface_value1="", is_preface_change1=false, onChangePreface1, onDeletePreface1, onTogglePreface1, is_preface_delete1=false, text1="", text_value1="", is_text_change1=false, text_ph1='Write something here....', onToggleText1, onChangeEditorText1, arr1=[], is_loading=false, preface_underbelly='', is_preface_under_change2=false, is_preface_under_delete2=false, preface_under2="", preface_under_value2='', onChangePrefaceUnder2, onTogglePrefaceUnder2, onDeletePrefaceUnder2, content_type2="", preface2="", preface_value2="", is_preface_change2=false,   onChangePreface2,  onDeletePreface2, onTogglePreface2, is_preface_delete2=false, text2="", text_value2="", is_text_change2=false, text_ph2='Write something here....', onToggleText2, onChangeEditorText2,  img_src2="", img_alt2="", img_value2="", is_add_image2=false, is_delete_image2=false, onDeleteImg2, onChangeImg2, onToggleImg2, arr2=[], bg_color='', onSubmitPreface1, onSubmitPrefaceUnder, onSubmitText1, onSubmitPreface2, onSubmitPrefaceUnder2, onSubmitText2}) => {
    // const color = colorThemePallete(bg_color);
    return(
        <>
            <Container className={bg_theme}>
                <HomeContent style={{ paddingTop:'3px'}}>
                    {content_type1 === "img" ? (
                        <div className="flex-50">
                            {img_src1 ? (
                                <>
                                    <div className="align-center">
                                        <img src={img_src1} alt={img_alt1} className="img-header" />
                                    </div>
                                    {!is_add_image1 && (
                                        <div className='disp-block align-center'>
                                            <button type="button" className={`btn hover-br br app-text-color font-super-small`} onClick={onToggleImg1} style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className='disp-block'>
                                        <div className='img-header align-all-center' style={{border:'1px solid #777'}}>
                                            <span className='font-small fw-600' style={{color:'#777', cursor:'default'}} onClick={onToggleImg1}>Click here to add an image|</span>
                                        </div>
                                    </div>
                                
                                </>
                            )}
                            {is_add_image1 && (
                                <div className='disp-block'>
                                    <form>
                                    <input type="file" required className='form-control' name="img1" onChange={onChangeImg1} value={img_value1} />
                                    <div className='disp-flex wrap jc-stretch' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity'>Upload</button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' onClick={onToggleImg2} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {is_delete_image1 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' onClick={onDeleteImg1} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                    </form>
                                </div>
                            )}
                            
                        </div>
                    ) : (
                        <div className="flex-50">
                            {is_preface_change1 ? (
                                <div className='disp-block'>
                                    <form onSubmit={onSubmitPreface1}>
                                    <input type="text" placeholder='Enter Preface' className='form-control font-super-big' name="preface_value1" onChange={onChangePreface1} value={preface_value1} />
                                    <div className='disp-flex wrap jc-stretch' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' title="Cancel" onClick={onTogglePreface1} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {is_preface_delete1 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeletePreface1} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                    </form>
                                </div>
                            ) : (
                                <>
                                {preface1 ? (<><span className="disp-block font-super-big fw-700 app-text-color">{preface1}</span> <button type="button" className='btn app-text-color font-super-small hover-br br' style={{border:'1px solid var(--appTextColor)'}} onClick={onTogglePreface1}><i className='fas fa-pen'></i> Change</button> </>) : (<><span className="disp-block font-super-big fw-700 hover-opacity" onClick={onTogglePreface1} style={{color:'#888', background:'#eee'}}>Enter Preface Here.... e.g Welcome To|</span></>)}
                                </>
                            )}
                            {is_add_preface_underbelly && (
                                <>
                                    {preface_underbelly && <span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px'}}><b>({preface_underbelly})</b></span>}
                                </>
                            )}
                            
                            <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                            {is_text_change1 ? (
                                <div className='disp-block'>
                                <form onSubmit={onSubmitText1}>
                                    <div className='disp-block' style={{width:'calc(100% - 10px)'}}>
                          
                                    <CKEditor editor={ClassicEditor}
                                        data={text_value1} onChange={onChangeEditorText1} height='200px' />
                                
                                    </div>
                                    <div className='disp-flex wrap jc-stretch align-center' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' title="Cancel" onClick={onToggleText1} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {/* {is_preface_under_delete2 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeletePrefaceUnder2} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )} */}
                                    </div>
                                </form>
                            </div>
                            ) : (
                                <>
                                {text1 ? (
                                    <div className='disp-block'>
                                        <span className="disp-block app-text-color fw-500 font-small"  style={{letterSpacing:'.6px'}}>
                                            {parse(text1)}
                                        </span>
                                        <button type="button" className='btn app-text-color font-super-small hover-br br' onClick={onToggleText1} style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button> 
                                    </div>
                                ) : (
                                    <>
                                    <div className='align-all-center' onClick={onToggleText1} style={{width:'100%', height:'200px', border:'1px solid #888'}}>
                                        <span className="disp-block app-text-color fw-500 hover-opacity" style={{letterSpacing:'.6px', color:'#777'}}>
                                            {text_ph1} !
                                        </span>
                                    </div>
                                    </>
                                )}
                                </>
                            )}
                            </div>
                            {arr1.length > 0 && (
                                <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                    <ul>
                                        {arr1.map((text, i) => (
                                            <li key={i} className="font-slightly-small app-text-color fw-500">{text}</li>
                                        ))}
                                    </ul>
                                </HomeContent>
                            )}
                        </div> 
                    )}
                    {content_type2 === "img" ? (
                        <div className="flex-50">
                            {img_src2 ? (
                                <>
                                    <div className="align-center">
                                        <img src={img_src2} alt={img_alt2} className="img-header" />
                                    </div>
                                    {!is_add_image2 && (
                                        <div className='disp-block align-center'>
                                            <button type="button" className='btn hover-br br app-text-color font-super-small' onClick={onToggleImg2} style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className='disp-block'>
                                        <div className='img-header align-all-center' style={{border:'1px solid #777'}}>
                                            <span className='font-small fw-600' style={{color:'#777', cursor:'default'}} onClick={onToggleImg2}>Click here to add an image|</span>
                                        </div>
                                    </div>
                                
                                </>
                            )}
                            {is_add_image2 && (
                                <div className='disp-block'>
                                    <form>
                                    <input type="file" required className='form-control' name="img2" onChange={onChangeImg2} value={img_value2} />
                                    <div className='disp-flex wrap jc-stretch' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity'>Upload</button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' title="Cancel" onClick={onToggleImg2} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {is_delete_image2 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' title="Delete" onClick={onDeleteImg2} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex-50">
                            {is_preface_change2 ? (
                                <div className='disp-block'>
                                    <form onSubmit={onSubmitPreface2}>
                                    <input type="text" placeholder='Enter Preface' className='form-control font-super-big' name="preface_value2" onChange={onChangePreface2} value={preface_value2} />
                                    <div className='disp-flex wrap jc-stretch' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' title="Cancel" onClick={onTogglePreface2} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {is_preface_delete2 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeletePreface2} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                    </form>
                                </div>
                            ) : (
                                <>
                                {preface2 ? (<><span className="disp-block font-super-big fw-700 app-text-color">{preface2}</span> <button type="button" className='btn app-text-color font-super-small hover-br br' style={{border:'1px solid var(--appTextColor)'}} onClick={onTogglePreface2}><i className='fas fa-pen'></i> Change</button> </>) : (<><span className="disp-block font-super-big fw-700 hover-opacity" onClick={onTogglePreface2} style={{color:'#888', background:'#eee'}}>Enter Preface Here.... e.g Welcome To|</span></>)}
                                </>
                            )}
                            {is_preface_under_change2 ? (
                                <div className='disp-block'>
                                    <form onSubmit={onSubmitPrefaceUnder2}>
                                    <textarea placeholder='Enter Preface Under' className='form-control font-bigger' name="preface_under_value2" onChange={onChangePrefaceUnder2} value={preface_under_value2} />
                                    <div className='disp-flex wrap jc-stretch' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' onClick={onTogglePrefaceUnder2} title="Cancel" style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {is_preface_under_delete2 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeletePrefaceUnder2} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                    </form>
                                </div>
                            ) : (
                            <>
                            {is_add_preface_underbelly && (
                                <>
                                {preface_under2 ? (<><span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px', marginTop:'5px'}}><b>{preface_under2}</b></span> <button type="button" onClick={onTogglePrefaceUnder2} className='btn app-text-color font-super-small hover-br br' style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button> </>) : (
                                <>
                                    <span className="disp-block font-bigger fw-600 hover-opacity" onClick={onTogglePrefaceUnder2} style={{color:'#888', marginTop:'10px', background:'#eee'}}>Enter Something About Preface.... e.g ABCD school|</span>
                                </>)}
                                </>
                            )}
                            
                            </>
                            )}
                            <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                            {is_text_change2 ? (
                                <div className='disp-block'>
                                <form onSubmit={onSubmitText2}>
                                    <div className='disp-block' style={{width:'calc(100% - 10px)'}}>
                          
                                    <CKEditor editor={ClassicEditor}
                                        data={text_value2} onChange={onChangeEditorText2} height='200px' />
                                
                                    </div>
                                    <div className='disp-flex wrap jc-stretch align-center' >
                                        <div className='disp-block padd-4px'>
                                            <button className='btn hover-opacity bg-black app-bg-text-color hover-opacity' title="Send"><i className='fas fa-paper-plane'></i></button>
                                        </div>
                                        <div className='disp-block padd-4px'>
                                            <button type="button" className='btn app-text-color' title="Cancel" onClick={onToggleText2} style={{border:'1px solid rgb(245, 30, 40)'}}><i className='fas fa-times'></i></button>
                                        </div>
                                        {is_preface_under_delete2 && (
                                            <div className='disp-block padd-4px'>
                                                <button type="button" className='btn app-bg-text-color hover-opacity' title="Clear" onClick={onDeletePrefaceUnder2} style={{background:'rgb(245, 30, 40)'}}><i className='fas fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                            ) : (
                                <>
                                {text2 ? (
                                    <div className='disp-block'>
                                        <span className="disp-block app-text-color fw-500 font-small"  style={{letterSpacing:'.6px'}}>
                                            {parse(text2)}
                                        </span>
                                        <button type="button" className='btn app-text-color font-super-small hover-br br' onClick={onToggleText2} style={{border:'1px solid var(--appTextColor)'}}><i className='fas fa-pen'></i> Change</button> 
                                    </div>
                                ) : (
                                    <>
                                    <div className='align-all-center' onClick={onToggleText2} style={{width:'100%', height:'200px', border:'1px solid #888'}}>
                                        <span className="disp-block app-text-color fw-500 hover-opacity" style={{letterSpacing:'.6px', color:'#777'}}>
                                            {text_ph2} !
                                        </span>
                                    </div>
                                    </>
                                )}
                                </>
                            )}
                            
                                
                            </div>
                            {arr2.length > 0 && (
                                <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                    <ul>
                                        {arr2.map((text, i) => (
                                            <li key={i} className="font-slightly-small app-text-color fw-500">{text}</li>
                                        ))}
                                    </ul>
                                </HomeContent>
                            )}
                        </div> 
                    )}
                    {/* <div className="flex-50">
                        <span className="disp-block font-super-big fw-700 app-text-color">Welcome To,</span>
                        <span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px'}}>{state.name} <b>({state.title.toUpperCase()})</b> School</span>
                        <div  style={{paddingLeft:'12px', marginTop:'8px'}}>
                            <span className="disp-block app-text-color fw-500 font-small" style={{color:'#666'}}>
                                We are a aiaa abab bab aabpfaf papfaufafafafafafafafdaabl a balbs a bdaas a abasd a aslasasblafbalbflfabfakfbhkabfalkfbalfbaa
                                afaifg afligalfaalfgga sasgfsfgsyfs yada f;asodfhspfs789as asiuadfuh;klsahp98 sh9 g9pash8ahgghpoasghpasgs9sg sgp9shg9psha9pahgas
                            </span>
                        </div>
                    </div> */}
                </HomeContent>
            </Container>
        </>
    )
}

export default PageByPageEditor;