import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';
// import { AnimatePresence } from 'framer-motion';
// import { Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import THDrop from 'components/app/school/SmallerComponents/table_html/th/menuDrop';

import ExamTimetablePdf from './pdf';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { convertDate } from 'components/app/school/SmallerComponents/action/dateConverter';
import { addItemToArray, addItemToArrayAndInnerArray } from 'components/app/school/actions/array-utils/Add';
import { deleteItemFromInnerArray, deleteItemFromInnerSecondArray } from 'components/app/school/actions/array-utils/Delete';
import { updateArray } from 'components/app/school/actions/array-utils/Update';
import { timify } from 'components/app/school/actions/timify';
import { sortByName } from 'components/app/school/actions/array-utils/Sort';


const SchoolExamTimetable = ({school_id, school, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialTableState = useMemo(() => ({session:"", curr_session:'', is_upload_exam_timetable:false, pre:true}), []);
    const initialTtState = useMemo(() => ({exam_date:null, class_section:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [ttState, setTtState] = useState(initialTtState);
    const [tableState, setTableState] = useState(initialTableState);

    const [allSessionData, setAllSessionData] = useState([]);
    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allExamTTData, setAllExamTTData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    const [allStaffData, setAllStaffData] = useState([]);
    // const [menuDisp, setMenuDisp] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [accordionLoading, setAccordionLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(false);

    const [accordion, setAccordion] = useState(0);

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
        setTtState(initialTtState);
    }
    const handleTTChange = (e=null, type="", date=null) => {
        if(type === "date"){
            const val = date ? date : null;
            setTtState({...ttState, [e]:val})
            return;
        }
        setTtState({...ttState, [e.target.name]:e.target.value})
    }
    const handleTTSChange = (e, item={}) => {
        setAllExamTTData(updateArray(allExamTTData, item.num, {...item, [e.target.name]:e.target.value}));
    }
    const handleTableChange = e => {
        if(e.target.name === "session" && e.target.value){
            const getsess = allSessionData.filter((item) => item.id === parseInt(e.target.value))[0]
            setTableState({...tableState, [e.target.name]:getsess.id, is_upload_exam_timetable:getsess.is_upload_exam_timetable, pre:false});
            setDataTableLoading(true);
            return;
        }
        if(e.target.name === "session" && !e.target.value){
            setTableState({...tableState, [e.target.name]:e.target.value, is_upload_exam_timetable:false, pre:false});
            setDataTableLoading(true);
            return;
        }
        setTableState({...tableState, [e.target.name]:e.target.value, pre:true});
        setDataTableLoading(true);
    }
    const handleinnerTTSChange = (e, i=0, item) => {
        const filter = allExamTTData.filter((item) => item.num === i);
        if(filter.length === 1){
            const update_data = updateArray(filter[0].time, item.num, {...item, [e.target.name]:e.target.value});
            setAllExamTTData(updateArray(allExamTTData, i, {...allExamTTData[i], time:update_data}));
            return;
        }
    }
    const handlInnerTTSTDClick = (i, item={}) => {
        const filter = allExamTTData.filter((item) => item.num === i);
        if(filter.length === 1){
            const update_data = updateArray(filter[0].time, item.num, {...item, start_time_val:'', end_time_val:''});
            setAllExamTTData(updateArray(allExamTTData, i, {...allExamTTData[i], time:update_data}));
            return;
        }
    }
    const handlInnerInnerTTSTDClick = (j, k, item={}) => {
        const filter = allExamTTData.filter((item) => item.num === j);
        if(filter.length === 1){
            const filter_inner = filter[0].time.filter((item) => item.num === k);
            const update_inner_data = updateArray(filter_inner[0].subjects, item.num, {...item, subject_val:''})
            const update_data = updateArray(filter[0].time, k, {...filter_inner[0], subjects:update_inner_data});
            setAllExamTTData(updateArray(allExamTTData, j, {...allExamTTData[j], time:update_data}));
            return;
        }
    }

    const handleinnerInnerTTSChange = (e, j=-1, k=-1, item={}) => {
        const filter = allExamTTData.filter((item) => item.num === j);
        if(filter.length === 1){
            const filter_inner = filter[0].time.filter((item) => item.num === k);
            const update_inner_data = updateArray(filter_inner[0].subjects, item.num, {...item, [e.target.name]:e.target.value})
            const update_data = updateArray(filter[0].time, k, {...filter_inner[0], subjects:update_inner_data});
            setAllExamTTData(updateArray(allExamTTData, j, {...allExamTTData[j], time:update_data}));
            return;
        }
    }

    const fetchAllSchoolSession = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices.getCurrentSession(sid).then(sess_res => {
                setTableState((prev) => !prev.session ? {...prev, session:sess_res.data.id, curr_session:sess_res.data.id, pre:true} : {...prev});
                setDataTableLoading(true);
            }).catch(e => {
                setTableState({session:"", curr_session:'', is_upload_exam_timetable:false, pre:false})
                setDataTableLoading(true);
            })
            appServices.getAllSession(sid).then(res => {
                setAllSessionData(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllClassSection = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices.getAllClassSectionSchool(sid).then(res => {
                setAllClassSectionData(res.data);
            }).catch(e => {
                setAllClassSectionData([]);
                setError({data:["Internal Server Error"], title:"Class Section Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllExamTT = (pre=true, sid, ssid) => {
        if(sid && ssid){
            setError({data:[], title:''});
            setPreloading(pre);
            setSpinLoading(!pre);
            appServices.getAllSchoolExamTimetable(sid, ssid).then(res => {
                setPreloading(false);
                setSpinLoading(false);
                setAllExamTTData(addItemToArrayAndInnerArray(res.data, 'time', {is_clicked:false,  start_time_val:'', end_time_val:''}, {is_clicked:false, is_clicked_trash:false, is_subject_clicked:false, start_time_val:'', end_time_val:'', subject_val:''}, 'subjects', {is_clicked:false, is_staff_assign_clicked:false, staff_assign_val:'', subject_val:''}));
            }).catch(e => {
                setAllExamTTData([]);
                setPreloading(false);
                setSpinLoading(false);
                setError({data:["Internal Server Error"], title:"Exam Timetable Data Fetch Error"});
            })
        }
    }
    const fetchAllStaff = (sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices.getAllStaffSchoolAccessMinorData(0, sid).then(res => {
                setAllStaffData(sortByName(res.data, 'last_name'));
            }).catch(e => {
                setAllStaffData((prev) => prev.length ? [...prev] : []);
                setError({data:["Internal Server Error"], title:"Staff Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }   
    }
    const fetchAllSubject = useCallback((sid) => {
        if(sid){
            setError({data:[], title:''});
            appServices.getAllSubjectSchool(sid).then(res => {
                setAllSubjectData(sortByName(res.data, 'name'));
            }).catch(e => {
                setAllSubjectData((prev) => prev.length ? [...prev] : []);
                setError({data:["Internal Server Error"], title:"Subject Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }, [])

    const handleTTSubmit = e => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setAccordionLoading(true);
        if(tableState.curr_session && parseInt(tableState.session) === parseInt(tableState.curr_session)){
            const data = {
                school: school_id,
                session: tableState.session,
                exam_date: convertDate(ttState.exam_date, 'yyyy-mm-dd'),
                class_section: ttState.class_section,
                created_by: user_id
            }
            appServices.insertSchoolExamTimetable(data).then(res => {
                setSuccess({text:"Date Created Successfully", title:"Success"});
                setAccordionLoading(false);
                setTtState(initialTtState);
                setTableState({...tableState, pre:false})
                setDataTableLoading(true);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Date Create Error'});
                setAccordionLoading(false);
            })
            return;
        }
        setError({data:['Term/Session must be current'], title:'Date Create Error'});
        setAccordionLoading(false);
    }
    const handleSubmitTTS = (e, item={}) => {
        e.preventDefault();
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            start_time: item.start_time_val,
            end_time: item.end_time_val,
            ett: item.ett
        }
        appServices.insertSchoolExamTimetableTime(data).then(res => {
            setSuccess({text:"Data Saved Successfully", title:"Success"});
            setLoading(false);
            setAllExamTTData(updateArray(allExamTTData, item.num, {is_clicked:true, start_time_val:'', end_time_val:''}));
            setDataTableLoading(true);
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Data Error'});
            setLoading(false);
         
        })

    }
    const handleSubmitInnerTTS = (e, i=0, item={}) => {
        e.preventDefault();
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            start_time: item.start_time_val ? item.start_time_val : item.start_time,
            end_time: item.end_time_val ? item.end_time_val : item.end_time
        }
        appServices.updateSchoolExamTimetableTime(item.uid, data).then(res  => {
            setLoading(false);
            const update_data = updateArray(allExamTTData[i].time, item.num, {...item, ...res.data, start_time_val:'', end_time_val:'', is_clicked:false, is_subject_clicked:false})
            setAllExamTTData(updateArray(allExamTTData, i, {...allExamTTData[i], time:update_data}));
            setSuccess({text:"Data Updated Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Update Data Error'});
            setLoading(false);
        })
    }
    const handleUpdateInnerTTTS = (e, j, k, item={}) => {
        e.preventDefault();
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            subject:item.subject_val ? item.subject_val : item.subject
        }
        appServices.updateSchoolExamTimetableSubject(item.uid, data).then(res  => {
            const filter = allExamTTData.filter((item) => item.num === j)
            const filter_inner = filter[0].time.filter((item) => item.num === k);
            const update_inner_data = updateArray(filter_inner[0].subjects, item.num, {...res.data, is_clicked:false, subject_val:''});
            const update_data = updateArray(filter[0].time, k, {...filter_inner[0], subjects:update_inner_data});
            setAllExamTTData(updateArray(allExamTTData, j, {...allExamTTData[j], time:update_data}));
            setLoading(false);
            setSuccess({text:"Data Updated Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Update Data Error'});
            setLoading(false);
        })
    }
    const handleSubmitInnerTTTS = (e, i=0, item={}) => {
        e.preventDefault();
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            subject:item.subject_val,
            time:item.time
        }
        appServices.insertSchoolExamTimetableSubject(data).then(res  => {
            setAllExamTTData(updateArray(allExamTTData, item.num, {is_clicked:false, start_time_val:'', end_time_val:''}));
            setTableState({...tableState, pre:false})
            setTimeout(() => {
                setDataTableLoading(true);
                setLoading(false);
            }, 100)
            // const update_data = updateArray(allExamTTData[i].time, item.num, {...item, ...res.data, start_time_val:'', end_time_val:'', is_clicked:false})
            // setAllExamTTData(updateArray(allExamTTData, i, {...allExamTTData[i], time:update_data}));
            setSuccess({text:"Data Saved Successfully", title:"Success"});
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Update Data Error'});
            setLoading(false);
        })
    }
    const handleSubmit3XInnerTTTS = (e, num, item={}) => {
        e.preventDefault();
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const data = {
            staff:item.staff_assign_val,
            subject: item.subject
        }
        appServices.insertSchoolExamTimetableSubjectSupervisor(data).then(res  => {
            setTableState({...tableState, pre:false})
            setAllExamTTData(updateArray(allExamTTData, item.num, {is_clicked:false, start_time_val:'', end_time_val:''}));
            setTimeout(() => {
                setDataTableLoading(true);
                setLoading(false);
                setSuccess({text:"Data Saved Successfully", title:"Success"});
            }, )
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Update Data Error'});
            setLoading(false);
        })
    }
    const handleDeleteInnerInnerTTS = (j, k, item={}) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        setSpinLoading(true);
        appServices.deleteSchoolExamTimetableSubject(item.uid).then(() => {
            setLoading(false);
            setSpinLoading(false);
            setSuccess({text:"Data Deleted Successfully", title:"Success"});
            setAllExamTTData(deleteItemFromInnerSecondArray(allExamTTData, j, k, item.num, "time", "subjects"))
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Delete Data Error'});
            setLoading(false);
            setSpinLoading(false);
        })
    }
    const handleDeleteInnerTTS = (i=-1, item={}) => {
        const handleDeleteItem = (i=-1, item={}) => {
            setLoading(true);
            setSpinLoading(true);
            appServices.deleteSchoolExamTimetableTime(item.uid).then(() => {
                setLoading(false);
                setSpinLoading(false);
                setAllExamTTData(deleteItemFromInnerArray(allExamTTData, i, 'time', item.num));
                setSuccess({text:"Data Deleted Successfully", title:"Success"});
                setWarning({id:0, title:'', text:'', click_text:''});
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Delete Data Error'});
                setLoading(false);
                setSpinLoading(false);
                setWarning({id:0, title:'', text:'', click_text:''});
            })
        }
        setWarning({id:0, title:'', text:'', click_text:''})
        setTimeout(() => {
            console.log("no data", i)
            setWarning({id:item.id, title:'Are you sure?', text:'Once deleted, data cannot be reverted!', handleClick: () => handleDeleteItem(i, item), click_text:'DELETE'})
        }, 100)
    }
    const handleDelete3XInnerTTS = (uid) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        setSpinLoading(true);
        appServices.deleteSchoolExamTimetableSubjectSupervisor(uid).then(() => {
            setSpinLoading(false);
            setSuccess({text:"Data Deleted Successfully", title:"Success"});
            setDataTableLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Delete Data Error'});
            setLoading(false);
            setSpinLoading(false);
        })
    }
    const handleClickDeleteDate = (i=-1, uid="") => {
        const handleDeleteItem = (i=-1, uid="") => {
            setLoading(true);
            setSpinLoading(true);
            appServices.deleteSchoolExamTimetable(uid).then(() => {
                setLoading(false);
                setSpinLoading(false);
                const filterItem = addItemToArray(allExamTTData.filter((item) => item.num !== i))
                setAllExamTTData([...filterItem]);
                setSuccess({text:"All Data Deleted Successfully", title:"Success"});
                setWarning({id:0, title:'', text:'', click_text:''});
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Delete Data Error'});
                setLoading(false);
                setSpinLoading(false);
                setWarning({id:0, title:'', text:'', click_text:''});
            })
        }
        setWarning({id:0, title:'', text:'', click_text:''})
        setTimeout(() => {
            setWarning({id:uid, title:'Are you sure?', text:'All item in relation to this data will be deleted!', handleClick: () => handleDeleteItem(i, uid), click_text:'DELETE'})
        }, 100)
    }
    const handleETUpload = () => {
        const handleUpload = () => {
            setError(initialErrorState);
            setSuccess(initialSuccessState);
            setLoading(true);
            appServices.uploadSchoolExamTimetable(tableState.session).then(() => {
                setLoading(false);
                setSuccess({text:"All Data Uploaded Successfully", title:"Success"});
                setWarning({id:0, title:'', text:'', click_text:''});
                fetchAllSchoolSession(school_id);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Upload Data Error'});
                setLoading(false);
                setWarning({id:0, title:'', text:'', click_text:''});
            })
        }
        setWarning({id:0, title:'', text:'', click_text:''})
        setTimeout(() => {
            setWarning({id:tableState.session, title:'Are you sure?', text:'Once uploaded data cannot be changed or altered!', handleClick: () => handleUpload(), click_text:'UPLOAD'})
        }, 100)
    }

    useEffect(() => {
        if(reload && school_id){
            setReload(false);
            fetchAllSchoolSession(school_id);
            fetchAllClassSection(school_id);
            fetchAllSubject(school_id);
            fetchAllStaff(school_id);
        }
        
    }, [reload, school_id, fetchAllSubject]);

    useEffect(() => {
        if(dataTableLoading && tableState.session && tableState.is_upload_exam_timetable){
            setDataTableLoading(false);
            setAllExamTTData([]);
            setPreloading(false);
        }
        if(dataTableLoading && tableState.session && !tableState.is_upload_exam_timetable){
            setDataTableLoading(false);
            fetchAllExamTT(tableState.pre, school_id, tableState.session);
        }
        if(dataTableLoading && !tableState.session){
            setDataTableLoading(false);
            setAllExamTTData([]);
            setPreloading(false);
        }
    }, [school_id, dataTableLoading, tableState])
    

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertSessionOption = allSessionData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.session} - ${item.term_name} term`}</option>
        )
    })
    const insertClassSectionOption = allClassSectionData.map((item) => {
        return(
            <option className='text-capitalize' value={item.id} key={item.id}>{`${item.name} Section`}</option>
        )
    })
    const insertStaffOption = allStaffData.map((item, k) => {
        return(
            <option className='text-capitalize' value={item.id} key={k}>{item.last_name} {item.first_name}</option>
        )
    })
    const getSessionData = tableState.session ? allSessionData.filter((item) => item.id === parseInt(tableState.session))[0] : {is_upload_exam_timetable:false, id:0}
    // const spread = (arr=[]) => {
    //     let data = [];
    //     let arr_len = arr.length - 1;
    //     for(let i = arr_len; i >= 0; i--){
    //         for(let j = 0; j < arr.length; j++){
    //             if(arr[i].start_time === arr[j].start_time  &&  arr[i].end_time === arr[j].end_time && arr[i].ett === arr[j].ett){
    //                 data.push({...arr[j]});
    //             }
    //         }
    //     }
    //     console.log(data);
    //     return data;
    // }
    const insertTimeTableData = () => {
        return (
            allClassSectionData.map((class_item, i) => {
                const filterExamTimeItem = allExamTTData.filter((item) => {
                    return item.class_section === class_item.id;
                })
                const filterSubject = allSubjectData.filter((item) => {
                    return item.class_section === class_item.id;
                })
                const insertSubjectOption = filterSubject.map((item, k) => {
                    return(
                        <option value={item.id} key={k}>{item.name}</option>
                    )
                })
                return(
                    <Fragment key={i}>
                        <div className='flex-100 align-center padd-4px'><span className='app-text-color fw-700 font-small '>{class_item.name.toUpperCase() } SECTION</span></div>
                        <div className='flex-100' style={{width:'0'}}>
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH>DATE</TH>
                                    <TH><b>Time &</b> <br /> Subject</TH>
                                </TR>
                            </thead>
                            <tbody>
                            {filterExamTimeItem.map((item_j, j) => {
                                return(
                                    <Fragment key={j}>
                                        <TR>
                                            <TD>
                                                <span className="font-super-small fw-700 disp-block" >{item_j.day_name && `${item_j.day_name.slice(0, 3)},` }</span><span className="font-very-small fw-700 blue" onClick={() => setAllExamTTData(updateArray(allExamTTData, item_j.num, {...item_j, is_clicked_trash:!item_j.is_clicked_trash}))} style={{cursor:'pointer'}}>{convertDate(new Date(item_j.exam_date), 'ddMMyyyy') }</span>
                                                {item_j.is_clicked_trash && <button onClick={() => handleClickDeleteDate(item_j.num, item_j.uid)} className="font-super-small btn bg-red app-bg-text-color"><i className='fas fa-trash'></i></button>}
                                            </TD>
                                            <TD>
                                                <TABLE>
                                                    <tbody>
                                                        <TR>
                                                        {item_j.time.length > 0 && (
                                                            <>
                                                                {item_j.time.map((item_k, k) => {
                                                                    return(
                                                                        <Fragment key={k}>                               
                                                                            <TD>
                                                                            {!item_k.is_clicked && (
                                                                                <>
                                                                                    <div className='disp-block' style={{cursor:'default'}} onClick={() => handlInnerTTSTDClick(item_j.num, {...item_k, is_clicked:!item_k.is_clicked})}>
                                                                                        <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_k.start_time)}</code>
                                                                                        <span className='font-small disp-inline'>-</span>
                                                                                        <code className='font-very-small fw-700 app-text-color disp-inline'>{timify(item_k.end_time)}</code>
                                                                                    </div>
                                                                                    <div className='disp-block'>
                                                                                        <span className='font-super-small fw-500 app-text-color'>{item_k.subject_name}</span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                                {item_k.is_clicked && <button type='button' style={{padding:'0'}} className={!item_k.is_clicked ? "app-text-sec-color btn font-slightly-small bg-none fw-700" : "red btn font-slightly-small bg-none fw-700"} onClick={() => handlInnerTTSTDClick(item_j.num, {...item_k, is_clicked:!item_k.is_clicked})} ><i className={!item_k.is_clicked ? 'fas fa-ellipsis-v' : 'fas fa-times'}></i></button>}
                                                                                {item_k.is_clicked && (
                                                                                    <THDrop style={{textAlign:'center'}}>
                                                                                        <form onSubmit={e => handleSubmitInnerTTS(e, item_j.num, {...item_k})} >
                                                                                            <div className='form-group'>
                                                                                                <label className='font-very-small app-text-sec-color'>From:</label><br />
                                                                                                <input type="time" name="start_time_val" value={item_k.start_time_val ? item_k.start_time_val : item_k.start_time} onChange={e => handleinnerTTSChange(e, item_j.num, item_k)} className='font-super-small fw-500 b-1-app-bg br-10'  required/>
                                                                                            </div>
                                                                                            <div className='form-group'>
                                                                                                <label className='font-very-small app-text-sec-color'>To:</label><br />
                                                                                                <input type="time" name="end_time_val" value={item_k.end_time_val ? item_k.end_time_val : item_k.end_time} onChange={e => handleinnerTTSChange(e, item_j.num, item_k)} className='font-super-small fw-500 b-1-app-bg br-10'  required/>
                                                                                            </div>
                                                                                            {/* <div className='form-group' style={{marginTop:'3px', marginBottom:'3px'}}>
                                                                                                <label className='font-super-small app-text-sec-color'>Select Subject</label><br />
                                                                                                <select style={{width:'100%'}} value={item_k.subject_val ? item_k.subject_val : item_k.time} onChange={e => handleTTSChange(e, item_j.num, item_k)} className='font-super-small fw-600 b-1-app-bg br-10' name="subject_val"  required>
                                                                                                    <option value={""}>Select Subject</option>
                                                                                                    {filterSubject.length > 0 ? insertSubjectOption : (<option value={""}>No Record Found</option>)}
                                                                                                </select>
                                                                                            </div> */}
                                                                                            <div className='disp-flex jc-sb wrap'>
                                                                                                <button type='submit' title="Update" className='app-bg-color btn bordered-grey hover-opacity app-bg-text-color br font-very-small mr-top-2'><i className="fas fa-paper-plane"></i></button>
                                                                                                <button type='button' title="Delete" onClick={() => handleDeleteInnerTTS(item_j.num, {uid:item_k.uid, num:item_k.num, id:item_k.id})} className='btn bordered-grey hover-opacity bg-red br font-very-small mr-top-2' style={{color:'#ccc'}}><i className='fas fa-trash'></i></button>
                                                                                            </div>
                                                            
                                                                                        </form>
                                                                                    </THDrop>
                                                                                )}
                                                                                <table>
                                                                                    <tbody>
                                                                                        <TR>
                                                                                                
                                                                                            {item_k.subjects.map((item_l, l) => {
                                                                                                return(
                                                                                                    <Fragment key={l}>
                                                                                                        <TD>
                                                                                                            <div className='disp-block'>
                                                                                                                {!item_l.is_clicked &&<span className='font-super-small app-text-color' onClick={() => handlInnerInnerTTSTDClick(item_j.num, item_k.num, {...item_l, is_clicked:!item_l.is_clicked})}>{item_l.name}</span> }
                                                                                                                {item_l.is_clicked && <button type='button' style={{padding:'0'}} className={!item_l.is_clicked ? "app-text-sec-color btn font-slightly-small bg-none fw-700" : "red btn font-slightly-small bg-none fw-700"} onClick={() => handlInnerInnerTTSTDClick(item_j.num, item_k.num, {...item_l, is_clicked:!item_l.is_clicked})} ><i className={!item_l.is_clicked ? 'fas fa-ellipsis-v' : 'fas fa-times'}></i></button>}
                                                                                                                {item_l.is_clicked && (
                                                                                                                    <form onSubmit={e => handleUpdateInnerTTTS(e, item_j.num, item_k.num, {...item_l})} className='app-opp-theme' style={{borderRadius:'3px', minWidth:'80px'}}>
                                                                                                                        <div className='form-group' style={{marginTop:'3px', marginBottom:'3px'}}>
                                                                                                                            <label className='font-super-small app-opp-text-color fw-700'>Edit Subject</label><br />
                                                                                                                            <select style={{width:'100%'}} value={item_l.subject_val ? item_l.subject_val : item_l.subject} onChange={e => handleinnerInnerTTSChange(e, item_j.num, item_k.num, item_l)} className='font-super-small fw-600 b-1-app-bg br-10' name="subject_val"  required>
                                                                                                                                {filterSubject.length > 0 ? insertSubjectOption : (<option value={""}>No Record Found</option>)}
                                                                                                                            </select>
                                                                                                                        </div><br />
                                                                                                                        <div className='disp-flex jc-sb wrap'>
                                                                                                                            <button type='submit' title="Update" className='app-bg-color btn bordered-grey hover-opacity app-bg-text-color br font-super-small mr-top-2'><i className="fas fa-paper-plane"></i></button>
                                                                                                                            <button type='button' title="Delete" onClick={() => handleDeleteInnerInnerTTS(item_j.num, item_k.num, {uid:item_l.uid, num:item_l.num, id:item_l.id})} className='btn bordered-grey hover-opacity bg-red br font-super-small mr-top-2' style={{color:'#ccc'}}><i className='fas fa-trash'></i></button>
                                                                                                                        </div>
                                                                                                                    </form>
                                                                                                                )}
                                                                                        
                                                                                                            </div>
                                                                                                            <div className='disp-block' style={{marginTop:'4px'}}>                     
                                                                                                                <span className='fw-700 disp-block font-super-small app-text-sec-color'>Supervisor(s)</span>
                                                                                                                {item_l.supervisor.length > 0 && (
                                                                                                                    <>
                                                                                                                        {item_l.supervisor.map((item_m, m) => {
                                                                                                                            return(
                                                                                                                                <Fragment key={m}>
                                                                                                                                    <span className='font-super-small app-text-color'>{item_m.staff_last_name} {item_m.staff_first_name} <button className='btn hover-opacity bg-red br font-super-small mr-top-2' style={{color:'#ccc', padding:'3px'}} onClick={() => handleDelete3XInnerTTS(item_m.uid)}><i className='fas fa-trash'></i></button>, </span>    
                                                                                                                                </Fragment>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                            
                                                                                                                    </>
                                                                                                                )}
                                                                                                                <div className='disp-block'>
                                                                                                                    <button type="button"  title="Add Supervisor" style={{padding:'3px'}} onClick={(e) => handlInnerInnerTTSTDClick(item_j.num, item_k.num, {...item_l, is_staff_assign_clicked:!item_l.is_staff_assign_clicked})} className={`btn hover-opacity font-super-small ${item_l.is_staff_assign_clicked ? 'red' : 'app-text-color'}`}><i className={item_l.is_staff_assign_clicked ? 'fas fa-times' : 'fas fa-plus '}></i></button>
                                                                                                                </div>
                                                                                                                {item_l.is_staff_assign_clicked && (
                                                                                                                    <form onSubmit={e => handleSubmit3XInnerTTTS(e, item_l.num, {staff_assign_val:item_l.staff_assign_val, subject:item_l.id})} className='app-opp-theme' style={{borderRadius:'3px',minWidth:'80px'}}>
                                                                                                                        <div className='form-group' style={{marginTop:'3px', marginBottom:'3px'}}>
                                                                                                                            <label className='font-super-small app-opp-text-color fw-700'>Add Supervisor</label><br />
                                                                                                                            <select style={{width:'100%'}} value={item_l.staff_assign_val} onChange={e => handleinnerInnerTTSChange(e, item_j.num, item_k.num, item_l)} className='font-super-small fw-600 b-1-app-bg br-10' name="staff_assign_val"  required>
                                                                                                                                <option value={""}>Select Staff</option>
                                                                                                                                {allStaffData.length > 0 ? insertStaffOption : (<option value={""}>No Record Found</option>)}
                                                                                                                            </select>
                                                                                                                        </div>
                                                                                                                        <div className='disp-block align-center'>
                                                                                                                            <button type='submit' className='app-bg-color btn bordered-grey hover-opacity app-bg-text-color br font-super-small mr-top-2'>Save</button>
                                                                                                                        </div>
                                                                                                                    </form>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </TD>
                                                                                                        {item_k.subjects.length > 0 && (<>{item_k.subjects.length - 1 !== l && (<TD><span className='font-very-small fw-600'>/</span></TD>)}</>) }
                                                                                                    </Fragment>
                                                                                                )
                                                                                            })}
                                                                                                        
                                                                                            <TD>
                                                                                                <div className='disp-block'>
                                                                                                    <button type="button"  title="Add Subject" style={{padding:'3px'}} onClick={(e) => handlInnerTTSTDClick(item_j.num, {...item_k, is_subject_clicked:!item_k.is_subject_clicked})} className={`btn bordered-grey hover-opacity app-opp-text-color br-circle font-super-small ${item_k.is_subject_clicked ? 'bg-red ' : 'bg-black'}`}><i className={item_k.is_subject_clicked ? 'fas fa-times' : 'fas fa-plus '}></i></button>
                                                                                                </div>
                                                                                                {item_k.is_subject_clicked && (
                                                                                                    <form onSubmit={e => handleSubmitInnerTTTS(e, item_j.num, {subject_val:item_k.subject_val, time:item_k.id})} className='app-opp-theme' style={{borderRadius:'3px',minWidth:'80px'}}>
                                                                                                        <div className='form-group' style={{marginTop:'3px', marginBottom:'3px'}}>
                                                                                                            <label className='font-super-small app-opp-text-color fw-700'>Create Subject</label><br />
                                                                                                            <select style={{width:'100%'}} value={item_k.subject_val} onChange={e => handleinnerTTSChange(e, item_j.num, item_k)} className='font-super-small fw-600 b-1-app-bg br-10' name="subject_val"  required>
                                                                                                                <option value={""}>Select Subject</option>
                                                                                                                {filterSubject.length > 0 ? insertSubjectOption : (<option value={""}>No Record Found</option>)}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                        <div className='disp-block align-center'>
                                                                                                            <button type='submit' className='app-bg-color btn bordered-grey hover-opacity app-bg-text-color br font-super-small mr-top-2'>Save</button>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                )}
                                                                                            </TD>
                                                                                        </TR>
                                                                                    </tbody>
                                                                                </table>
                                                                            </TD>
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                      
                                                            <TD><button type="button"  title="Add Time" style={{padding:'3px'}} onClick={() => setAllExamTTData(updateArray(allExamTTData, item_j.num, {...item_j, is_clicked:!item_j.is_clicked}))} className={`btn bordered-grey hover-opacity app-opp-text-color br font-very-small ${item_j.is_clicked ? 'bg-red ' : 'bg-blue'}`}><i className={item_j.is_clicked ? 'fas fa-times' : 'fas fa-plus '}></i></button>
                                                            {item_j.is_clicked && (
                                                                <THDrop style={{textAlign:'center', backgroundColor:'#00057a'}}>
                                                                    <form onSubmit={e => handleSubmitTTS(e, {...item_j, ett:item_j.id})}>
                                                                        <div className='form-group'>
                                                                            <label className='font-very-small app-text-sec-color'>From:</label><br />
                                                                            <input type="time" name="start_time_val" value={item_j.start_time_val} onChange={e => handleTTSChange(e, item_j)} className='font-super-small fw-500 b-1-app-bg br-10'  required/>
                                                                        </div>
                                                                        <div className='form-group'>
                                                                            <label className='font-very-small app-text-sec-color'>To:</label><br />
                                                                            <input type="time" name="end_time_val" value={item_j.end_time_val} onChange={e => handleTTSChange(e, item_j)} className='font-super-small fw-500 b-1-app-bg br-10' required/>
                                                                        </div>
                                                                        {/* <div className='form-group' style={{marginTop:'3px', marginBottom:'3px'}}>
                                                                            <label className='font-super-small app-text-sec-color'>Select Subject</label><br />
                                                                            <select style={{width:'100%'}} value={item_j.subject_val} onChange={e => handleTTSChange(e, item_j)} className='font-super-small fw-600 b-1-app-bg br-10' name="subject_val"  required>
                                                                                <option value={""}>Select Subject</option>
                                                                                {filterSubject.length > 0 ? insertSubjectOption : (<option value={""}>No Record Found</option>)}
                                                                            </select>
                                                                        </div> */}
                                                                        <button type='submit' className='app-bg-color btn bordered-grey hover-opacity app-bg-text-color br font-very-small mr-top-2'>Save</button>
                                                                    </form>
                                                                </THDrop>
                                                            )}
                                                                
                                                            </TD>
                                                        </TR>
                                                    </tbody>
                                                </TABLE>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                            </tbody>
                        </TABLE>
                        </div>
                    </Fragment>
                )
            })
        )
    }

    return(
        <>
            <HeadComponent title="Manage Exam Timetable" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Section>
                    <Mallet>
                        <div className="flex-100"><label className='font-super-small'>Select Session/Term: <br />
                            <select name="session" onChange={e => handleTableChange(e)} value={tableState.session} className='font-slightly-small text-capitalize form-control-theme'>
                                <option value={''}>Select Session/term</option>
                                {insertSessionOption}
                            </select>
                            </label>
                        </div>
                    </Mallet>
                </Section>
                <Grid>
                    <GridContent header_text="Create Exam Date" header_icon="fas fa-plus">
                        {accordionLoading && <Loader />}
                        <Accordion index={1} text={`Add Exam Date`} handleClick={() => handleAccordionClick(1) } handleSubmit={e => handleTTSubmit(e)} currentAccordion={accordion}>
                            {accordion === 1 && (
                                <>
                                    <AccordionFormData required handleChange={(date) => handleTTChange("exam_date", "date", date)} selected_date={ttState.exam_date} dateFormat={'yyyy-MM-dd'} tag="input" type="date" name="exam_date"  classname="form-control-theme font-slightly-small" placeholder="Select Exam Date..." />
                                    <AccordionFormData required={true} handleChange={(e) => handleTTChange(e)} value={ttState.class_section} tag="select" name="class_section" classname="form-control-theme font-slightly-small text-capitalize">
                                        <option value="">Select Class Section</option>
                                        {insertClassSectionOption}
                                    </AccordionFormData>
                                    <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                </>
                            )}
                        </Accordion>
                    </GridContent>
                    <GridContent header_text="Manage Exam Timetable" header_icon="fas fa-align-right">
                        {loading && <Loader />}
                        <Section>
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                    {spinLoading && <Spinner />}
                                    {!getSessionData.is_upload_exam_timetable ? (
                                        <>
                                            {allExamTTData.length > 0 ? insertTimeTableData() : <div className='flex-100 align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}
                                        </>
                                    ) : (
                                        <>
                                        {!spinLoading && (
                                            
                                            <ExamTimetablePdf school={school} session={{...getSessionData}} />
                                               
                                        )}
                                        </>
                                    )}
                                    
                                </div>
                                {allExamTTData.length > 0 && (
                                    <>
                                        {!getSessionData.is_upload_exam_timetable && (
                                            <div className='disp-block align-center w-100' style={{alignItems:'center'}}>
                                                <button type="button" className='mybtn bg-blue hover-opacity br app-bg-text-color font-slightly-small' onClick={() => handleETUpload()}>Upload <i className='fas fa-upload'></i></button>
                                            </div>
                                        )}
                                    </>
                                    
                                )}
                            </>)}
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolExamTimetable;