export const loopDate = (i=20, start=2023) => {
    let data = [];
    const end_date = start + i;
    for(let j = start; j <= end_date; j++){
        data.push(j);
    }
    return data;
}

export const loopDateTime = (start=new Date(), type="day", i=20, is_desc=true) => {
    let data = [];
    let dateTime = new Date(start).getTime();
    if(type === "day"){
        let loopDate = 1000 * 60 * 60 * 24;
        let endDateTime = dateTime + ( loopDate * i);
        if(is_desc){
            while(endDateTime >= dateTime){
                data.push(endDateTime);
                endDateTime -= loopDate;
            }
            return data;
        }
        while(dateTime <= endDateTime){
            data.push(dateTime);
            dateTime += loopDate;
        }
        return data;
    }
    return data;
}