import styled from "styled-components";
import {motion} from 'framer-motion';


export const Content = styled(motion.div)`
    margin: 0 auto;
    width: 500px;
    align-items: baseline;
    height: 80%;
    width: clamp(50%, 250px, 90%);
    @media(max-width:320px){
        width: 200px;
    }
    .container{
        overflow-y: auto;
        background: var(--appTheme);
        padding: 20px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        position: relative;
        height: 100%;
        transform: translateX(-35%);

        @media(max-width:1280px){
            transform: translateX(-40%);
        }
        @media(max-width:820px){
            transform: translateX(-44%);
            overflow-y: auto;
        }
        @media(max-width:780px){
            transform: translateX(-35%);
        }
        @media(max-width:540px){
            transform: translateX(-20%);
        }
        @media(max-width:430px){
            transform: translateX(-15%);
        }
        @media(max-width:320px){
            transform: translateX(-16%);
        }
        .underlay-cont{
            position: absolute;
            top: 50%;
            bottom: 0;
            right:0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: -1;

            img{
                width:clamp(50%, 100px, 90%);
                opacity: 0.15;
            }
        }
        .header{
            display: flex;
            justify-content: space-between;
            
            span{
                font-weight: 700;
                padding-left: 5px;
                color: var(--appTextColor);

                i{
                    padding-left: 5px;
                }
            }
            button{
                font-weight: 700;
                cursor: pointer;
                border: none;
                color: #888;
                background: none;
                transition: .3s;

                :hover{
                    color: #555;
                }
            }
        }
        .row{
            display: flex;
            -moz-display: flexbox;
            -webkit-display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 10px;

            .fg{
                flex: 50%;
                max-width: 50%;
                padding: 6px 10px;
                position: relative;
                margin-bottom: 2px;
                @media(max-width:780px){
                    flex: 100%;
                    max-width: 100%;
                    padding: 4px 4px;
                }
                .inside{
                    margin-top: 10px;
                }
                label{
                    font-weight: 700;
                    color: var(--appTextColor);
                    padding: 0;
                    margin: 0;
                }
                input, select{
                    @media(max-width:780px){
                        
                    }
                }
            }
            .fg-all{
                flex: 100%;
                max-width: 100%;
                padding: 8px 10px;
                @media(max-width:780px){
                    padding: 6px;
                }
            }
            .fg-all .heading{
                text-align: center;
            }
            .fg-all .btn-g{
                margin-top: 20px;
            }
            .fg-all.profile-cont, .fg.profile-cont{
                justify-content: center;
                text-align: center;
                
                img{
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    @media(max-width: 540px){
                        width: 120px;
                        height: 120px;
                    }
                    @media(max-width: 320px){
                        width: 100px;
                        height: 100px;
                    }
                }
                .profile-file{
                    input[type="file"]{
                        display: none;
                    }
                    label, .label{
                        color: var(--appNavTextColor);
                        font-weight: 600;
                        cursor: pointer;
                        position: relative;
                    }
                }
            }
        }
    }
`;