import React from "react";

const TH = ({children, style, align="", font_size='slightly-small'}) => {
    return(
        <th style={style} className={`font-${font_size} align-${align}`}>
            {children}
        </th>
    )
}

export default TH;