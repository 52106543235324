import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import {default as SelectBox} from "components/app/school/SmallerComponents/selectBox";
import Option from "components/app/school/SmallerComponents/selectBox/MultCheckbox";
import Input, { ListOption } from 'components/app/school/SmallerComponents/input';
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";

import Pagination from '@mui/material/Pagination';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from 'components/app/school/SmallerComponents/spinner';
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';

import { addItemToArray, addItemKeyToArrayOnly } from "components/app/school/actions/array-utils/Add";
import { updateArray, updateArrayWithArray } from 'components/app/school/actions/array-utils/Update';
import { sortByName } from 'components/app/school/actions/array-utils/Sort';
import { getFormData } from 'components/app/school/actions/array-utils/Form';
import blobToFile, { getBlob } from 'components/app/school/actions/blobToFile';
import { validateAuthorForm, validateSchoolBookDataForm } from 'components/app/school/actions/validation';
import { convertNumber, convertToNumber, seperatePoint } from 'components/app/school/actions/money';
import { getPageSize } from 'components/app/school/actions/pageSize';
import { BACKEND_URL } from 'actions/url';
import { toBoolean } from '../actions/toBoolean';

import {default as success_icon} from 'images/success_icon.svg';


const SchoolBooks = ({school_id, school_title, user_id, currency}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialBookState = useMemo(() => ({name:"", book:0, class_clicked:false, school_branch_clicked:false, school_branch:[], classes:[], is_pay_student:false, author:'', price:0, price_point:0, pic:null, pic_value:'', pic_img:'', subject:'', qty_stock:undefined, is_disable_pic:false, is_disable_author:false}), []);
    const initialAuthorState = useMemo(() => ({name:"",}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const initialTableState = useMemo(() => ({q:"", classes:'', p:1, size:20}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    const [bookState, setBookState] = useState(initialBookState);
    const [authorState, setAuthorState] = useState(initialAuthorState); 
    const [tableState, setTableState] = useState(initialTableState)
    const [formError, setFormError] = useState({sb_qty_stock:[]});
    const [formEditError, setFormEditError] = useState({sb_qty_stock:[]});
    const [minorBookData, setMinorBookData] = useState({...initialBookState, id:0, num:0});
    const [formAuthorError, setFormAuthorError] = useState({});
    const [menuDropData, setMenuDropData] = useState({id:'', num:0, classes:[], school_branch:[]});
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})

    const [allClassesData, setAllClassesData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([])
    const [allAuthorData, setAllAuthorData] = useState(initialPaginationState);
    const [allBookData, setAllBookData] = useState(initialPaginationState);
    const [allMinorBookData, setAllMinorBookData] = useState(initialPaginationState);
    const [checkBoxOptionData, setCheckBoxOptionData] = useState([]);
    const [checkBoxSchoolBranchOptionData, setCheckBoxSchoolBranchOptionData] = useState([]);

    const [reloadData, setReloadData] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [tableChanged, setTableChanged] = useState(false);
    const [minorBookDataChanged, setMinorBookDataChanged] = useState(false);
    const [populateMinorBook, setPopulateMinorBook] = useState(false);
    const [accordionLoading, setAccordionLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [closeListBox, setCloseListBox] = useState(true);
    const [menuLoading, setMenuLoading] = useState(false);
    const [menuDrop, setMenuDrop] = useState(false);


    const [submit, setSubmit] = useState('');

    const [accordion, setAccordion] = useState(0);

    const handleAccordionClick = (i) => {
        if (accordion !== i){ setAccordion(i)} else {setAccordion(0)}
        setAuthorState(initialAuthorState);
        setBookState(initialBookState);
        setFormAuthorError({});
        setFormError({sb_qty_stock:[]});
        setMinorBookData({id:0, uid:'', num:0, q:''});
        setSubmit('');
    }
    const handleTableChange = (e) => {
        setTableChanged(false);
        setTableState({...tableState, [e.target.name]:e.target.value});
        setTableChanged(true)
    }
    const handleTablePagination = (e, value) => {
        setTableChanged(false);
        const name = "p";
        setTableState({...tableState, [name]:value})
        setTableChanged(true)
    }
    const handleAuthorChange = (e) => {
        setAuthorState({...authorState, [e.target.name]:e.target.value})
    }
    const handleMenuChange = (e) => {
        setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
    }
    const handleBookChange = (e) => {
        if(e.target.name === "name" && e.target.value){
            setCloseListBox(false);
            setBookState({...initialBookState, [e.target.name]:e.target.value})
            setMinorBookData({id:0, uid:'', num:0, q:e.target.value});
            setMinorBookDataChanged(true);
            return;
        }
        if(e.target.name ==="is_pay_student"){
            setBookState({...bookState, [e.target.name]:!toBoolean(e.target.value)});
            return;
        }
        setBookState({...bookState, [e.target.name]:e.target.value})
        setCloseListBox(true);
    }
    const handleBookImageChange = (e) => {
        setBookState({...bookState, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value, [e.target.name + '_img']:e.target.value ? URL.createObjectURL(e.target.files[0]) : ''});
    }
    const handleBookArrayChange = (e, i=0, name="") => {
        const value = isNaN(parseInt(e.target.value)) ? e.target.value : parseFloat(e.target.value)
        setBookState({...bookState, [name]:updateArray(bookState[name], i, {...bookState[name][i], [e.target.name]:value})})
    }
    const handleMenuBookArrayChange = (e, i=0, name="") => {
        const value = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value)
        setMenuDropData({...menuDropData, [name]:updateArray(menuDropData[name], i, {...menuDropData[name][i], [e.target.name]:value})})
    }
    const handleAccordionSelectBoxClick = (name="", value=false) => {
        const filterItem = checkBoxOptionData.filter((item) => {
            return item.checked === true;
        })
        if(filterItem.length){
            setBookState({...bookState, [name]:value, classes:filterItem})
            return;
        }
        setBookState({...bookState, [name]:value, classes:[], subject:''})
        setAllSubjectData([]);
    
    }
    const handleMenuSelectBoxClick = (name="", value=false, arr_key="") => {
        if(arr_key==="classes"){
            const filterItem = menuDropData[arr_key].filter((item) => {
                return item.checked === true;
            })
            if(filterItem.length ){
                setMenuDropData({...menuDropData, [name]:value, populate_subject:true});
                return;
            }
            setMenuDropData({...menuDropData, [name]:value, subject:'', populate_subject:false})
            setAllSubjectData([]);
            return;
        }
        setMenuDropData({...menuDropData, [name]:value})
    
    }
    const handleAccordionSelectBoxSchoolBranchClick = (name="", value=false) => {
        const filterSchoolBranchItem = checkBoxSchoolBranchOptionData.filter((item) => {
            return item.checked === true;
        })
        if(filterSchoolBranchItem.length){
            setBookState({...bookState, [name]:value, school_branch:addItemToArray(filterSchoolBranchItem, {qty_stock:undefined}),})
            return;
        }
        setBookState({...bookState, [name]:value, school_branch:[]})
    
    }
    const getDataFromArray = (arr=[], key="", grab="") => {
        var data = {}
        for(let i =0; i < arr.length; i++){
            if(arr[i][key] === grab){
                data = {...arr[i]};
            }
        }
        return data;
    }
    const handleListBoxClick = useCallback((id=0, uid='') => {
        let data = id ? getDataFromArray(allMinorBookData.results, typeof id === "number" ? "id" : 'name', id) : getDataFromArray(allMinorBookData.results, "uid", uid);
        if(data.id){
            setMinorBookData((prev) => prev.q && {...prev, ...data});
            setCloseListBox(true);
            if(data.pic){
                getBlob(`${BACKEND_URL}${data.pic}`).then(res => {
                    const pic = blobToFile(res, `${BACKEND_URL}${data.pic}`);
                    setBookState((prev) => typeof prev === "object" && {...prev, name:data.name, book:data.id, pic:`${pic}`, pic_value: '', pic_img:URL.createObjectURL(pic), is_disable_author:true, author:data.author, is_disable_pic:true})
                    return;
                })
            }
            setBookState((prev) => typeof prev === "object" && {...prev,name:data.name, book:data.id, pic:null, pic_value: '', pic_img:'', is_disable_author:true, author:data.author, is_disable_pic:false})
        }
    }, [allMinorBookData])
    const fetchAllClasses = (sid) => {
        setError({data:[], title:''});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClassesData(res.data);
        }).catch(e => {
            setAllClassesData([]);
            setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        })
    }
    const fetchAllSchoolBranch = (sid) => {
        setError({data:[], title:''});
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData(res.data); 
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        })
    }
    const fetchAllSubject = (sid, csid, ) => {
        setError({data:[], title:''});
        appServices.getAllSubjectSchool(sid, csid).then(res => {
            setAllSubjectData(res.data);    
        }).catch(e => {
            setAllSubjectData([]);
            setError({data:["Internal Server Error"], title:"Subject Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        })
    }
    const fetchAllMinorBook = (q='', initial={}) => {
        setPopulateMinorBook(false);
        appServices.getAllBook(q).then(res => {
            setAllMinorBookData(res.data);
            setPopulateMinorBook(true);
        }).catch(() => {
            setAllMinorBookData((prev) => prev.results ? {...prev, results:sortByName(prev.results)} : initial);
        })
    } 
    const fetchAllBooks = useCallback((loader=false, cid, q="", order_by_price="", p=1, size=20) => {
        if(school_id){
            setError({data:[], title:''});
            setPreloading(!loader);
            setSpinLoading(loader);
            appServices.getAllSchoolBook(school_id, p, size, q, cid, order_by_price).then(res => {
                setPreloading(false);
                setSpinLoading(false);
                setAllBookData(res.data);
            }).catch(e => {
                setPreloading(false);
                setSpinLoading(false);
                setAllBookData((prev) => prev.count ? {...prev} : initialPaginationState);
                setError({data:["Internal Server Error"], title:"Book Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }, [school_id, initialPaginationState])
    const fetchAllAuthor = useCallback(() => {
        setError(initialErrorState);
        appServices.getAllBookAuthor().then(res => {
            setAllAuthorData(res.data);
        }).catch(e => {
            setAllAuthorData(initialPaginationState);
            setError({data:["Internal Server Error"], title:"Author Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        })
    }, [initialPaginationState, initialErrorState])

    const handleSelectBoxOptionsChange = (num=0, item, checked=false) => {
        setCheckBoxOptionData(updateArray(checkBoxOptionData, num, {...item, checked:checked}))
    }
    const handleSelectBoxSchoolBranchOptionsChange = (num=0, item, checked=false) => {
        setCheckBoxSchoolBranchOptionData(updateArray(checkBoxSchoolBranchOptionData, num, {...item, checked:checked}))
    }
    const handleSelectBoxOptionsMenuChange = (num=0, item, checked=false, arr_key="") => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setMenuLoading(true);
        setSpinLoading(true);
        const filterCheckedItem = menuDropData[arr_key].filter(item => item.checked === true);
        if(arr_key ==="classes"){
            if(checked === true){
                appServices.insertSchoolClassesBook(item).then(res => {
                    const classes =  addItemToArray(updateArrayWithArray(addItemKeyToArrayOnly(allClassesData, {checked:false, added:false, book:menuDropData.id}, 'id_c','id', 'name','name', 'class_section_id','class_section_school', 'classes','id'), addItemKeyToArrayOnly(res.data.classes, {checked:true, added:true, book:menuDropData.id}, 'id_c','classes','name','class_name', 'class_section_id','class_section', 'id','id'), 'id_c'), {})
                    setSuccess({text:"Class Added Successfully", title:"Success"})
                    setSpinLoading(false);
                    setAllBookData({...allBookData,  results:sortByName(updateArray(allBookData.results, menuDropData.id, {...res.data}, 'id'), 'name')});
                    setMenuDropData({...menuDropData, [arr_key]:classes})
                    setMenuLoading(false);
                }).catch(e => {
                    setError({data:[e.response.data.detail], title:'Class Add Error'});
                    setMenuLoading(false);
                    setSpinLoading(false);
                })
                return;
            }
            if(filterCheckedItem.length > 1 && checked === false){
                appServices.deleteSchoolBookClasses(item.id).then(res => {
                    const classes =  addItemToArray(updateArrayWithArray(addItemKeyToArrayOnly(allClassesData, {checked:false, added:false, book:menuDropData.id}, 'id_c','id', 'name','name', 'class_section_id','class_section_school', 'classes','id'), addItemKeyToArrayOnly(res.data.classes, {checked:true, added:true, book:menuDropData.id}, 'id_c','classes','name','class_name', 'class_section_id','class_section', 'id', 'id'), 'id_c'), {})
                    setSuccess({text:"Class Deleted Successfully", title:"Success"});
                    setMenuDropData({...menuDropData, [arr_key]:classes});
                    setSpinLoading(false);
                    setAllBookData({...allBookData,  results:sortByName(updateArray(allBookData.results, menuDropData.id, {...res.data}, 'id'), 'name')});
                    setMenuLoading(false);
                }).catch(e => {
                    setError({data:[e.response.data.detail], title:'Class Delete Error'});
                    setMenuLoading(false);
                    setSpinLoading(false);
                })
                return
            }
            if(filterCheckedItem.length < 2 && checked === false){
                setError({data:["Class Cannot Be Empty"], title:'Class Is Required'});
            }
            setMenuLoading(false);
            setSpinLoading(false);
            return;
        }
        setMenuLoading(false);
        setSpinLoading(false);
        setMenuDropData({...menuDropData, [arr_key]:updateArray(menuDropData[arr_key], num, {...item, checked:item.added ? !checked : checked})})
        
    }
    const onClickEdit = (data) => {
        setLoading(true);
        setError(initialErrorState);
        if(allClassesData.length && data.id && !data.type){
            const classes =  addItemToArray(updateArrayWithArray(addItemKeyToArrayOnly(allClassesData, {checked:false, added:false, book:data.id}, 'id_c','id', 'name','name', 'class_section_id','class_section_school', 'classes','id'), addItemKeyToArrayOnly(data.classes, {checked:true, added:true, book:data.id}, 'id_c','classes','name','class_name', 'class_section_id','class_section', 'id','id'), 'id_c'), {})
            setMenuDropData({...data, classes:classes, populate_subject:true});
            const timeout = setTimeout(() => {
                setMenuDrop(true);
                setLoading(false);
            }, 200);
            return () => {
                clearTimeout(timeout);
            }
        }
        setError({data:["Data Not Found"], title:"Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        setLoading(false);
        setMenuDrop(false);
        
    }
    const onClickAdd = (data) => {
        setLoading(true);
        setError(initialErrorState);
        if(allSchoolBranchData.length && data.id){
            const school_branch = addItemToArray(updateArrayWithArray( addItemKeyToArrayOnly(allSchoolBranchData, {checked:false, added:false, qty_stock:'', new_qty_stock:'', disabled:true, book:data.id}, 'id_s', 'id', 'id', 'id', 'name', 'name'), addItemKeyToArrayOnly(data.school_branch, {checked:true, added:true, disabled:true, new_qty_stock:'', book:data.id}, 'id_s', 'school_branch', 'name', 'school_branch_name', 'qty_stock','qty_stock', 'id','id'), 'id_s'), {})
            setMenuDropData({...data, school_branch:school_branch});
            const timeout = setTimeout(() => {
                setMenuDrop(true);
                setLoading(false);
            }, 200);
            return () => {
                clearTimeout(timeout);
            }
        }
        setError({data:["Data Not Found"], title:"Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        setLoading(false);
        setMenuDrop(false);
    }
    const handleAuthorSubmit = (e) => {
        e.preventDefault();
        setAccordionLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setFormAuthorError(validateAuthorForm(authorState));
        setSubmit('insert_author');
        if(submit === "inserted_author"){
            const data = {
                name:authorState.name
            }
            appServices.insertBookAuthor(data).then(() => {
                fetchAllAuthor();
                setAccordionLoading(false);
                setFormAuthorError({});
                setAuthorState(initialAuthorState);
                setSuccess({text:"Data Submitted Successfully", title:"Success"})
            }).catch(e => {
                setError({data:[e.response.data.detail], title:'Author Submit Error'});
                setAccordionLoading(false);
            })
            return;
        }
        setAccordionLoading(false);
    }

    const handleBookSubmit = (e) => {
        e.preventDefault();
        setAccordionLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setFormError({sb_qty_stock:[], ...validateSchoolBookDataForm(bookState)});
        setSubmit("insert_book");
        if(submit === "inserted_book"){
            const classes = addItemKeyToArrayOnly(bookState.classes, {}, 'classes', 'id')
            const school_branch = addItemKeyToArrayOnly(bookState.school_branch, {}, "school_branch","id", "qty_stock","qty_stock")
            const price = parseFloat(`${convertToNumber(bookState.price)}.${bookState.price_point ? bookState.price_point : '0'}`); 
            var data = {
                name: bookState.name,
                is_pay_student:bookState.is_pay_student,
                subject: bookState.subject,
                price: price,
                classes:classes,
                school_branch:school_branch,
                school:school_id,
                qty_stock:0,
                created_by: user_id,
            }
            const insBookToSchool = (data) => appServices.insertSchoolBook(data).then(res => {
                setAccordionLoading(false);
                setBookState(initialBookState);
                setCheckBoxSchoolBranchOptionData(addItemToArray(allSchoolBranchData, {checked:false}));
                setCheckBoxOptionData(addItemToArray(allClassesData, {checked:false}))
                setSuccess({text:"Data Submitted Successfully", title:"Success"})
                fetchAllBooks(true, tableState.classes, tableState.q, '', tableState.p, tableState.size);
                setFormError({sb_qty_stock:[]});
                setSubmit('');
            }).catch(e => {
                setError({data:[!e.response.data.non_field_errors.length ? e.response.data.detail : 'Data Already Exists'], title:'Book Submit Error'});
                setAccordionLoading(false);
                setSubmit('');
            })
            
            if(bookState.book && bookState.pic && !minorBookData.pic){
                const formData = getFormData({name:minorBookData.name, author:minorBookData.author, pic:bookState.pic ? bookState.pic : ''})
                appServices.updateBook(bookState.book, formData).then(() => {
                    insBookToSchool({...data, book:bookState.book});
                }).catch(e => {
                    setError({data:[!e.response.data.non_field_errors.length ? e.response.data.detail : 'Data Already Exists'], title:'Book Submit Error'});
                    setAccordionLoading(false);
                })
                return;
            }
            if(bookState.book){
                insBookToSchool({...data, book:bookState.book});
                return;
            }
            const formData = getFormData({name:bookState.name, author:bookState.author, pic:bookState.pic ? bookState.pic : ''})
            appServices.insertBook(formData).then(res => {
                insBookToSchool({...data, book:res.data.id});
            }).catch(e => {
                setError({data:[!e.response.data.non_field_errors.length ? e.response.data.detail : 'Data Already Exists'], title:'Book Submit Error'});
                setAccordionLoading(false);
            })
            return;
        }
        setAccordionLoading(false);
    }
    const handleBookUpdate = (e) => {
        e.preventDefault();
        setMenuLoading(true);
        setSpinLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setFormEditError({sb_qty_stock:[], ...validateSchoolBookDataForm(menuDropData, 'update')});
        setSubmit("update_book");
        if(submit==="updated_book" && menuDropData.id){
            const price = parseFloat(`${convertToNumber(menuDropData.price)}.${menuDropData.price_point ? menuDropData.price_point : '0'}`); 
            const classes = menuDropData.classes.filter(item => item.checked===true)
            let data = {
                name: menuDropData.name,
                is_pay_student:menuDropData.is_pay_student,
                subject: menuDropData.subject,
                price: price,
                classes:classes,
                school_branch:menuDropData.school_branch,
                qty_stock:0,
            }
            appServices.updateSchoolBook(menuDropData.id, data).then(res => {
                setSuccess({text:"Data Updated Successfully", title:"Success"});
                setFormEditError({sb_qty_stock:[]});
                setSubmit(menuDropData.id);
                setMenuDrop(false);
                setSpinLoading(false);
                setMenuLoading(false);
                setAllBookData({...allBookData,  results:sortByName(updateArray(allBookData.results, menuDropData.id, {...res.data}, 'id'), 'name', false)});
            }).catch(e => {
                setError({data:[e.response.data.detail], title:'Book Update Error'});
                setMenuLoading(false);
                setSpinLoading(false);
                setSubmit('');
            })
            return;
        }
        setMenuLoading(false);
        setSpinLoading(false);
    }
    const handleBookQuantitySubmit = (e) => {
        e.preventDefault();
        var promise = Promise.resolve();
        setMenuLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        const filterItemAdded = menuDropData.school_branch.filter((item) => item.checked === true && item.added === true)
        const filterItem = menuDropData.school_branch.filter((item) => item.checked === true && item.added === false)
        if(filterItem.length){
            return;
        }
        if(filterItemAdded.length && !filterItem.length){
            filterItemAdded.forEach((item) => {
                promise = promise.then(() =>  {
                    appServices.updateSchoolSchoolBranchBook(item.id, {qty_stock:item.new_qty_stock ? item.new_qty_stock : 0}).then(res => {
                        setSuccess({title:'Data Added Successfully', text:`${item.name.toUpperCase()} Book Quantity Added Successfully`});
                    }).catch(e => {
                        setError({title:'Something Went Wrong', data:[e.response.data.detail]});
                    })
                    return new Promise((resolve) => {
                        setTimeout(resolve, 100);
                    })
                })
            })
        }
        promise.then(() => {
            setMenuLoading(false);
            setMenuDrop(false);
            fetchAllBooks(true, tableState.classes, tableState.q, '', tableState.p, tableState.size);
        })
    }

    const handleDeleteBook = (id) => {
        setWarning({id:0, title:'', text:''});
        setTimeout(() => {
            setWarning({id:id, click_text:`Delete`, 
             title:"Warning", text:"Are You Sure You Want To Delete This Book?",
             handleClick: () =>{
                setLoading(true);
                setError(initialErrorState);
                setSuccess(initialSuccessState);
                appServices.deleteSchoolBook(id).then(res => {
                    setLoading(false);
                    setWarning({id:0, title:'', text:''})
                    setAnim({initial:{opacity: 1, duration:0.4},
                        animate:{opacity:0, duration:0.4, background:"rgba(255,0,50,1)"},
                        id:id });
                    setTimeout(() => {
                        fetchAllBooks(true, tableState.classes, tableState.q, "", tableState.p, tableState.size);
                        setAnim({initial:{opacity: 0, duration:0.4},
                            animate:{opacity:1, duration:0.4, background:``},
                            id:id })
                        setSuccess({text:"Data Deleted Successfully", title:"Success"});
                    }, 500)
                }).catch(e => {
                    setLoading(false);
                    setWarning({id:0, title:'', text:''})
                    setError({data:[e.response.data.detail], title:"Delete Book Error"})
                })
             }
            })
        })
        
    }
    useEffect(() => {
        fetchAllClasses(school_id)
        fetchAllAuthor();
        fetchAllSchoolBranch(school_id);
        fetchAllBooks(false)
    }, [school_id, fetchAllAuthor, fetchAllBooks]);
    useEffect(() => {
        if(allClassesData.length){
            setCheckBoxOptionData(addItemToArray(allClassesData, {checked:false}))
        }
        if(allSchoolBranchData.length){
            setCheckBoxSchoolBranchOptionData(addItemToArray(allSchoolBranchData, {checked:false}));
        }
    }, [allClassesData, allSchoolBranchData, ])
    useEffect(() => { 
        if(reloadData){
            fetchAllClasses(school_id)
            fetchAllAuthor();
            fetchAllSchoolBranch(school_id);
            fetchAllBooks(false, tableState.classes, tableState.q, "", tableState.p, tableState.size);
            bookState.classes.length && fetchAllSubject(school_id, bookState.classes[0].class_section_id)
            setMenuDropData({id:0, num:-1, ...initialBookState})
            setReloadData(false);
            setMenuDrop(false);
            return;
        }
        if(tableChanged){
            setSpinLoading(true);
            const timeout = setTimeout(() => {
                fetchAllBooks(true, tableState.classes, tableState.q, "", tableState.p, tableState.size);
                setTableChanged(false);
            }, 350);
            return () => {
                clearTimeout(timeout);
            }
        }
        if(minorBookDataChanged){
            const timeout = setTimeout(() => { fetchAllMinorBook(minorBookData.q, initialPaginationState); setMinorBookDataChanged(false);}, 500);
            return () => {
                clearTimeout(timeout);
            }
        }
        if(populateMinorBook){
            const timeout = setTimeout(() => { setPopulateMinorBook(false); handleListBoxClick(bookState.name) }, 800);
            return () => {
                clearTimeout(timeout);
            }
        }
    }, [school_id, fetchAllAuthor, fetchAllBooks, reloadData, bookState, minorBookData, minorBookDataChanged, populateMinorBook, handleListBoxClick, tableState, tableChanged, initialPaginationState, initialBookState])
    useEffect(() => {
        if(bookState.classes.length){
            fetchAllSubject(school_id, bookState.classes[0].class_section_id)
        }
        if(menuDrop && menuDropData.populate_subject){
            const filterItem = menuDropData.classes.filter(item => item.checked === true)
            fetchAllSubject(school_id, filterItem[0].class_section_id)
            setMenuDropData((prev) => prev.populate_subject && {...prev, populate_subject:false});
        }


    }, [school_id, bookState, menuDrop, menuDropData])
    useEffect(() => {
        if(Object.keys(formError).length === 1 && submit === "insert_book"){
            Object.keys(formError).forEach((item) => {
                if(Array.isArray(formError[item]) && !formError[item].length){
                    setSubmit("inserted_book");
                }
                else if(typeof formError[item] !== "object" && !formError[item]){
                    setSubmit("inserted_book");
                }
            })
        }
        if(Object.keys(formEditError).length === 1 && submit === "update_book"){
            Object.keys(formEditError).forEach((item) => {
                if(Array.isArray(formEditError[item]) && !formEditError[item].length){
                    setSubmit("updated_book");
                }
                else if(typeof formEditError[item] !== "object" && !formEditError[item]){
                    setSubmit("updated_book");
                }
            })
        }
        if(Object.keys(formAuthorError).length === 0 && submit === "insert_author"){
            setSubmit("inserted_author")
        }
    }, [formError, formAuthorError, formEditError, submit]);

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    const getListBoxData = (data) => {
        setCloseListBox(data);
    }

    const insertClassValue = bookState.classes.map((item) => {
        return ' '+item.name.trimEnd(',');
    })
    const insertClassesMenuValue = menuDropData.classes.filter(item => item.checked === true).map(item => item.name ? ' '+item.name.trimEnd(',') : '');
    const insertSchoolBranchMenuValue = menuDropData.school_branch.filter(item => item.checked === true).map(item => item.name ? ' '+item.name.trimEnd(',') : '');
    const insertSchoolBranchValue = bookState.school_branch.map((item) => {
        if(item.name){
            return ' '+item.name.trimEnd(',');
        }
        return '';
    })


    const insertSubjectOption = allSubjectData.map((item) => {
        return(
            <option key={item.num} value={item.id}>{item.name}</option>
        )
    })
    const insertAuthorOption = allAuthorData.results.map((item, i) => {
        return(
            <option key={i} value={item.id}>{item.name}</option>
        )
    })
    const insertSchoolBookClassesOption = allClassesData.map((item, i) => {
        return(
            <option key={i} value={item.id}>{item.name}</option>
        )
    })
    const insertClassesOption = checkBoxOptionData.map((item) => {
        return(
            <Fragment key={item.num}>
                <Option classname="text-capitalize font-very-small" name="name" checked={item.checked} value={item.name} onChange={() => handleSelectBoxOptionsChange(item.num, item, !item.checked)}>{item.name}</Option>
            </Fragment>
        )
    })
    const minorBookOptions = allMinorBookData.results.map((item, i) => {
        return(
            <Fragment key={i}>
                <ListOption flex={'100'} classname='font-very-small' onClick={() => handleListBoxClick(item.id)}>{item.name.length > 34 ? item.name.slice(0, 34)+'...' : item.name}</ListOption>
            </Fragment>
        )
    })
    const insertSchoolBranchBoxOption = checkBoxSchoolBranchOptionData.map((item) => {
        return(
            <Fragment key={item.num}>
                <Option classname="text-capitalize font-very-small" name="name" checked={item.checked} value={item.name} onChange={() => handleSelectBoxSchoolBranchOptionsChange(item.num, item, !item.checked)}>{item.name}</Option>
            </Fragment>
        )
    })
    const insertSchoolBranchBoxMenuOption = menuDropData.school_branch.map((item) => {
        return(
            <Fragment key={item.num}>
                <Option classname="text-capitalize font-very-small" name="name" checked={item.checked} disabled={item.disabled} value={item.name} onChange={() => handleSelectBoxOptionsMenuChange(item.num, item, !item.checked, 'school_branch')}>{item.name}</Option>
            </Fragment>
        )
    })
    const insertClassesBoxMenuOption = menuDropData.classes.map((item) => {
        return(
            <Fragment key={item.num}>
                <Option classname="text-capitalize font-very-small" name="name" checked={item.checked} value={item.name} onChange={() => handleSelectBoxOptionsMenuChange(item.num, item, !item.checked, 'classes')}>{item.name}</Option>
            </Fragment>
        )
    })
    const updateSuccessIcon = () => {
        setTimeout(() => {
            setSubmit("none");
        }, 1000 * 15)
        
        return(
            <div className="nav-dd-anim" style={{display:submit}}><img style={{width: '30px', height:'20px'}} src={success_icon} alt="Success" /></div>
        )
    }

    const insertTableData = () => {
        var i = 0;
        if(tableState.p > 1){
            const page = parseInt(tableState.p) - 1
            const size = parseInt(tableState.size) * page;
            i = size;
        }
        
        return(allBookData.results.map((item) => {  
            i++;
            const insertClass = item.classes.map((it) => {
                if(it.class_name){
                    return ' '+it.class_name.trimEnd(',');
                }
                return '';
            })
            const insertSchoolBranch = item.school_branch.map((it) => {
                if(it.school_branch_name){
                    return ' '+it.school_branch_name.trimEnd(',');
                }
                return '';
            })
            const data_add = {id:item.id, classes:[], uid:item.uid, school_branch:item.school_branch, type:'school_branch'}
            const data = {id:item.id, uid:item.uid, school_branch:item.school_branch, book:item.book, classes:item.classes, name:item.name, pic:item.pic, subject:item.subject, is_pay_student:item.is_pay_student, price:seperatePoint(item.price), price_point:seperatePoint(item.price, true),}
            return(
                <Fragment key={i}>
                    <Tr sn={true} dlt_btn={true} add_btn={true} handleDeleteClick={() => handleDeleteBook(item.id)} handleAddClick={() => onClickAdd(data_add)} handleEditClick={() => onClickEdit(data)}
                        animation={(item.id === anim.id) && {initial:{...anim.initial}, animate:{...anim.animate}}}>
                        {/* <div className="c-data sn">{item.id === submitted ? updateSuccessIcon() : (<span className="font-very-small">{i}</span>)}</div> */}
                        <div className="c-data sn">{item.id === submit ? updateSuccessIcon() : (<span className="font-very-small">{i}</span>)}</div>
                        <div className="c-data" style={{width:'50%'}}> {item.pic && <div className="profile-cont"><img className='profile font-very-small' src={`${BACKEND_URL}${item.pic}`} alt="Book" /></div>} <span className="font-very-small">{item.name ? item.name.length > 32 ? item.name.slice(0, 32)+'...' : item.name : item.book_name.length > 32 ? item.book_name.slice(0, 32)+'...' : item.book_name}</span></div>
                        <div className="c-data"><span className="font-very-small">{currency.symbol}{convertNumber(item.price)}</span></div>
                        <div className="c-data"><span className="font-very-small">{item.qty_stock}</span></div>

                        <TdWrapper header_text={item.name ? item.name.length > 22 ? item.name.slice(0, 22)+'...' : item.name : item.book_name.length > 22 ? item.book_name.slice(0, 22)+'...' : item.book_name} header_num={i}>
                            {item.pic && <Td profile_pic={`${BACKEND_URL}${item.pic}`} styleImg={{borderRadius:'5px'}} /> }
                            <Td heading="Book Name" text={item.name ? item.name : item.book_name} />
                            <Td heading="Price" text={`${currency.symbol}${convertNumber(item.price)}`} />
                            <Td heading="Subject" text={item.subject_name} />
                            <Td heading="Classes" text={insertClass} />
                            <Td heading="School Branch(s)" text={insertSchoolBranch} />
                            <Td heading="Quantity In Stock" text={item.qty_stock} />
                            <Td heading="Quantity Remain" text={item.qty_remain} />
                            <Td heading="Quantity Used" text={item.qty_used} />
                            <Td heading="Author's Name" text={item.author} />
                        </TdWrapper>
                    </Tr>
                </Fragment>
            )
        }))
    }

    return(
        <>
            <HeadComponent title='Manage School Books' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>  
                <Grid>
                    <GridContent header_text={`Add Books`} header_icon="fas fa-plus">
                        {accordionLoading && <Loader />}
                        <Accordion index={2} text={`Add Author`} handleSubmit={e => handleAuthorSubmit(e)} handleClick={() => handleAccordionClick(2) } currentAccordion={accordion}>
                            {accordion === 2 && (
                                <>
                                    <AccordionFormData error={formAuthorError.name} handleChange={(e) => handleAuthorChange(e)} value={authorState.name} tag="input" type="text" name="name"  classname="form-control-theme font-slightly-small" placeholder="Enter Author's Name" />
                                    <AccordionFormData contentClass="align-center" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                </>
                            )}
                        </Accordion>
                        <Accordion index={1} text={`Add Book`} handleSubmit={e => handleBookSubmit(e)} handleClick={() => handleAccordionClick(1) } currentAccordion={accordion}>
                            {accordion === 1 && (
                                <>
                                    <Input error={formError.name} styleContent={{paddingLeft:'5px',paddingRight:'5px', marginTop:'7px'}} handleChange={(e) => handleBookChange(e)} value={bookState.name} name="name" type="list" classname="form-control-theme font-slightly-small" placeholder='Enter Book Name' setCloseListBox={getListBoxData} closeListBox={closeListBox} listItemLength={allMinorBookData.results.length} >
                                        {allMinorBookData.results.length > 0  &&  minorBookOptions}
                                    </Input>
                                    <AccordionFormData handleChange={e => handleBookImageChange(e, true)} error={formError.pic}  tag="input" type="file" name="pic" disabled={bookState.is_disable_pic} value={bookState.pic_value} fileImg={bookState.pic_img} styleFileImg={{width:'8rem', height:'8rem', borderRadius:'10px'}} classname="form-control-theme font-slightly-small" placeholder="Choose Book Image (if any)" />
                                    <AccordionFormData error={formError.author} handleChange={(e) => handleBookChange(e)} value={bookState.author} disabled={bookState.is_disable_author} tag="select" name="author"  classname="form-control-theme font-slightly-small" >
                                        <option value={""}>Select Book Author</option>
                                        {insertAuthorOption}
                                    </AccordionFormData>
                                    <SelectBox error={formError.classes} value={insertClassValue} placeholder="Select Class For Book..." onClick={() => handleAccordionSelectBoxClick("class_clicked", !bookState.class_clicked)}  clicked={bookState.class_clicked}>
                                        {insertClassesOption}
                                    </SelectBox>
                                    <SelectBox error={formError.school_branch} value={insertSchoolBranchValue} placeholder="Select School Branch With Book..." onClick={() => handleAccordionSelectBoxSchoolBranchClick("school_branch_clicked", !bookState.school_branch_clicked)}  clicked={bookState.school_branch_clicked}>
                                        {insertSchoolBranchBoxOption}
                                    </SelectBox>
                                    <AccordionFormData error={formError.subject} handleChange={(e) => handleBookChange(e)} value={bookState.subject} tag="select" name="subject"  classname="form-control-theme font-slightly-small" >
                                        <option value={""}>Select Book Subject</option>
                                        {insertSubjectOption}
                                    </AccordionFormData>
                                    <AccordionFormData error={formError.price} handleChange={(e) => handleBookChange(e)} value={bookState.price} currency={currency.symbol} currency_value={currency.id} point_display={bookState.price ? true : false} point_name='price_point' point_value={bookState.price_point} handlePointChange={e => handleBookChange(e)} tag="input" type="money" name="price"  classname="form-control-theme font-slightly-small" placeholder="Enter Book Price" />
                                    {bookState.school_branch.map((item, i) => {
                                        return(
                                            <Fragment key={i}>
                                                <AccordionFormData error={formError.sb_qty_stock.length > 0 ? formError.sb_qty_stock[i] : ''} handleChange={(e) => handleBookArrayChange(e, item.num, "school_branch")} value={item.qty_stock} tag="input" type="number" name="qty_stock"  classname="form-control-theme font-slightly-small" placeholder={`Enter Quantity In Stock ${item.name}`} />
                                            </Fragment>
                                        )
                                    })}
                                    <AccordionFormData>
                                        <span className='font-super-small fw-600'>Do You Want To Add Book To List Of Book Payment For Student ?</span> <input type="checkbox" name="is_pay_student" value={bookState.is_pay_student} checked={bookState.is_pay_student} onChange={(e) => handleBookChange(e)} /> 
                                    </AccordionFormData>
                                    <br />
                                    <AccordionFormData styleContent={{zIndex:0}} contentClass="align-center z-9" btn_text="Save" btn_icon="fas fa-paper-plane" tag="btn" type="submit" 
                                    classname="font-slightly-small app-bg-text-color mybtn app-bg-color" />
                                </>
                            )}
                        </Accordion>
                    </GridContent>
                    <GridContent header_text={`Manage Books`} header_icon="fas fa-tasks">
                        {loading && <Loader />}
                        <Section icon="fas fa-tasks" header={`Manage School Books`}>
                        <Note>To Add Books To List Of Payment For Student School Fees Click <Link to={`/${school_title}/school_fee`}>HERE</Link></Note>
                            <Mallet >
                                <div className="flex-100"><label className='font-super-small'>Select Book Class: 
                                    <select onChange={e => handleTableChange(e)} name="classes" value={tableState.classes} className='form-control-theme font-slightly-small' >
                                        <option value={""}>All</option>
                                        {insertSchoolBookClassesOption}
                                    </select></label>
                                </div>
                                <div className="flex-70-line"><label className='font-super-small'>Filter By: 
                                    <input onChange={e => handleTableChange(e)} name="q" value={tableState.q} className='form-control-theme font-slightly-small' type="text" placeholder='Search...' /></label>
                                </div>
                                <div className="flex-30-line align-right"><label className='font-super-small'>No. Of Books: <br />
                                    <select onChange={e => handleTableChange(e)} name="size" value={tableState.size} className='font-slightly-small form-control-theme'>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                    </label>
                                </div>
                            </Mallet>
                            {preloading ? (<ul>
                                <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                <Table>
                                    {spinLoading && <Spinner />}
                                    <Tr btn={false} header={true} sn={true} text={[{text:"S/N"},  {text:"Book Name", style:{width:'50%'}}, {text:"Price"}, {text:'Qty Stock'}]} />
                                    {allBookData.results.length === 0 ? (<Tr btn={false} text={[{text:"No Record Found", style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                        (<>
                                            {insertTableData()}
                                        </>)                         
                                    }
                                    <div  style={{marginTop:'40px', display:'flex', justifyContent:'center'}}>
                                        <Pagination count={getPageSize(allBookData.count, tableState.size)} page={tableState.p} onChange={handleTablePagination} size="small" color="primary" variant="outlined" shape="rounded" />
                                    </div>
                                </Table>
                            </>)
                            }
                            
                        </Section>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                            { menuDrop && 
                            <MenuDrop handleClose={() => {setMenuDrop(false); setAllSubjectData([]);}} header_text={menuDropData.type==="school_branch" ? "Add New Qty" : "Edit Book"} header_icon={menuDropData.type==="school_branch" ? "fas fa-plus" : "fas fa-pen"}>
                                {menuLoading && <Loader />}
                                {menuDropData.id && (
                                <>
                                    {menuDropData.type === "school_branch" && (
                                        <form onSubmit={e => handleBookQuantitySubmit(e)}>
                                            <div className="fg">
                                                <label className="font-super-small">Edit School Branch </label>
                                                <SelectBox error={formEditError.school_branch} value={''} placeholder={insertSchoolBranchMenuValue.length ? insertSchoolBranchMenuValue : "Select School Branch..."} onClick={() => handleMenuSelectBoxClick("school_branch_clicked", !menuDropData.school_branch_clicked, "school_branch")}  clicked={menuDropData.school_branch_clicked}>
                                                    {insertSchoolBranchBoxMenuOption}
                                                </SelectBox>
                                                {formEditError.school_branch && <span className='font-slightly-small red fw-600'>{formEditError.school_branch}</span>}
                                            </div>
                                            {menuDropData.school_branch.filter((item) => item.checked === true && item.added === false).length > 0 ? (
                                                <>
                                                    <div className='fg-all align-center' style={{marginTop:'7px', marginBottom:'5px'}}>
                                                        <span className='font-small app-text-sec-color fw-600'>Editted School Branch Book Quantity</span>
                                                    </div>
                                                    {menuDropData.school_branch.filter((item) => item.checked === true && item.added === false).map((item => {
                                                        return(
                                                            <div className='fg fw-600'>
                                                                <label className="font-super-small">Editted {item.name} Quantity</label>
                                                                <input onChange={e => handleMenuChange(e)} type="text" name="qty_stock" className="font-very-small app-theme form-control-theme" placeholder={`Quantity In Stock ${item.name}`} value={item.qty_stock} />
                                                            </div>
                                                        )
                                                    }))}
                                                </>
                                            ) : (
                                                <>
                                                    <div className='fg-all align-center' style={{marginTop:'7px', marginBottom:'7px'}}>
                                                        <span className='font-small app-text-sec-color fw-600'>Book Quantity In Stock</span>
                                                    </div>
                                                    {menuDropData.school_branch.filter((item) => item.checked === true).map((item => {
                                                        return(
                                                            <div className='fg fw-600'>
                                                                <label className="font-super-small">Quantity In Stock For {item.name} </label>
                                                                <input disabled={true} type="text" className="font-very-small app-theme form-control disabled" placeholder={`Quantity In Stock ${item.name}`} defaultValue={item.qty_stock} />
                                                            </div>
                                                        )
                                                    }))}
                                                    <div className='fg-all align-center' style={{marginTop:'7px', marginBottom:'7px'}}>
                                                        <span className='font-small app-text-sec-color fw-600'>Add New Quantity</span>
                                                    </div>
                                                    {menuDropData.school_branch.filter((item) => item.checked === true).map((item => {
                                                        return(
                                                            <div className='fg'>
                                                                <label className="font-super-small fw-600">New Quantity {item.name} </label>
                                                                <input onChange={e => handleMenuBookArrayChange(e, item.num, 'school_branch')} type="number" name="new_qty_stock" className="font-very-small app-theme form-control-theme" placeholder={`New Quantity ${item.name}`} value={item.new_qty_stock} />
                                                                {formEditError.name && <span className='font-slightly-small red fw-600'>{formEditError.name}</span>}
                                                            </div>
                                                        )
                                                    }))}
                                                    <div className="fg-all btn-g align-center">
                                                        <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                            Update <i className="fas fa-paper-plane"></i> </button>
                                                    </div>
                                                </>
                                            )}
                                            
                                        </form>
                                    )}
                                {!menuDropData.type && (
                                    <form onSubmit={e => handleBookUpdate(e)}>
                                        <div className='row'>
                                            {menuDropData.pic && (
                                                <div className="fg profile-cont">
                                                    <img style={{borderRadius:'5px'}} src={`${BACKEND_URL}${menuDropData.pic}`} className="" alt="Book" />
                                                </div>
                                            )}
                                            <div className="fg">
                                                <label className="font-super-small">Edit Class </label>
                                                <SelectBox error={formError.classes} value={''} placeholder={insertClassesMenuValue.length ? insertClassesMenuValue : 'Select Class For Book'} onClick={() => handleMenuSelectBoxClick("class_clicked", !menuDropData.class_clicked, "classes")}  clicked={menuDropData.class_clicked}>
                                                    {insertClassesBoxMenuOption}
                                                </SelectBox>
                                                {formEditError.classes && <span className='font-slightly-small red fw-600'>{formEditError.classes}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Book Name </label>
                                                <input onChange={e => handleMenuChange(e)} type="text" name="name" className="font-very-small app-theme form-control-theme" placeholder="Enter Book Name" value={menuDropData.name} />
                                                {formEditError.name && <span className='font-slightly-small red fw-600'>{formEditError.name}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Book Subject </label>
                                                <select onChange={e => handleMenuChange(e)} className='font-very-small app-theme form-control-theme' name="subject" value={menuDropData.subject}>
                                                    <option value="">Select Subject For Book</option>
                                                    {insertSubjectOption}
                                                </select>
                                                {formEditError.subject && <span className='font-slightly-small red fw-600'>{formEditError.subject}</span>}
                                            </div>
                                            <div className="fg">
                                                <label className="font-super-small">Book Price </label>
                                                <Input type="money" required currency={currency.symbol} currency_value={currency.id} name={"price"} value={menuDropData.price} point_name='price_point' point_value={menuDropData.price_point}
                                                point_display={menuDropData.price ? true : false} handleChange={e => handleMenuChange(e)} handlePointChange={e => handleMenuChange(e)} classname='font-very-small app-theme form-control-theme app-text-color' placeholder='Price..' />
                                                {formEditError.price && <span className='font-slightly-small red fw-600'>{formEditError.price}</span>}
                                            </div>
                                            <div className="fg">
                                                <span className='font-super-small fw-600'>Do You Want To Add Book To List Of Book Payment For Student ?</span> <input type="checkbox" name="is_pay_student" value={menuDropData.is_pay_student} checked={menuDropData.is_pay_student} onChange={(e) => handleMenuChange(e)} /> 
                                            </div>
                                            <div className="fg-all btn-g align-center">
                                                <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                    Update <i className="fas fa-paper-plane"></i> </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                                </>
                                )}
                            </MenuDrop>
                            }
                        </AnimatePresence>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default SchoolBooks;