import React from "react";
import { Wrapper, Content } from "../style";

const HeaderEditor = ({ name="", value='', placeholder='',  is_edit=false, onToggle, onChange, onSubmit, is_required=false}) => {
    return(
        <Wrapper>
            <Content>
                <form onSubmit={onSubmit}>
                    <div className="disp-block">
                        <input type="text" className="font-bigger form-control text-capitalize" required={is_required} name={name} value={value} placeholder={placeholder} onChange={onChange} />
                    </div>
                    <div className="disp-flex wrap">
                        <div className="disp-block padd-4px">
                            <button className="btn hover-opacity padd-4px bg-black app-bg-text-color" title="Send"><i className="fas fa-paper-plane"></i></button>
                        </div>
                        <div className="disp-block padd-4px">
                            <button type="button" className="btn padd-4px app-text-color" title="Cancel" onClick={onToggle} style={{border:'1px solid rgb(245, 50, 45)'}}><i className="fas fa-times"></i></button>
                        </div>
                    
                    </div>
                    <span></span>
                </form>
            </Content>
        </Wrapper>
    )
}

export default HeaderEditor;