import styled from 'styled-components';

export const Content = styled.div`
    width: 100%;
    position: relative;
    margin: 10px 0 0 0;
    padding-left : 12px;
    background: var(--appTheme);
    border-radius: 5px;
    div{
        display: inline-block;
        padding: 5px;
        @media(max-width: 400px){
            padding: 3px;
        }
        a{
            font-weight: 600;
            letter-spacing: .6px;
            color: var(--appBgColorText);
            text-decoration: none;
        }
        span{
            font-weight: 600;
            letter-spacing: .6px;
            color: var(--appTextColor);
            text-decoration: none;
        }
        i{
            margin:0 5px;
            padding: 3px 5px;
            background: var(--appBgTextColor);
            color: var(--appTextColor);
            border-radius: 50%;

            @media(max-width: 540px){
                margin:0;
            }
        }
    }
`;