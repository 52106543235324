import styled from "styled-components";

export const OuterWrapper = styled.div`
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    justify-content: center;

    
`;

export const Content = styled.div`
    display: flex;
    padding: 5px 5px 5px 5px;
    margin: 5px 10px;
    transform: translateY(10px);
    justify-content: space-between;
    flex-wrap: wrap;
    background: rgba(0,0,0,0.4);
    border-radius: 10px;
    width: 100%;

    .inp-cont{
        width: 85%;
        
        @media (max-width: 375px){
            width: 80%;
        }
        @media (max-width: 290px){
            width: 75%;
        }
        textarea.inp, .inp{
            background: transparent;
            border: none;
            outline: none;
            color: #bbb;
            min-height: 33px;
            width: 100% !important;
        }
    }
    .btn-wrapper{
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding: 0 10px;

        .btn-cont{
            display: flex;
            justify-content: stretch;

            .image-file{
                display: none;
            }
            .send_btn:active{
                transform: scale(0.9);
            }
            .padd-r-4px{
                padding-right: 7px;
            }
            .padd-l-4px{
                padding-left: 7px;
            }
        }
    }
`;