import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { numberDigit } from 'components/app/school/actions/numberDigit';
import { HeadComponent } from "components/head";
import { appServices2, appServices5 } from 'api/schoolApp/services';

import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { updateArray, updateArrayWithArray } from 'components/app/school/actions/array-utils/Update';
import { convertNumber } from 'components/app/school/actions/money';

const AccountAppSetup = ({school={}, user_id=0, user_type="", access={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [accessState, setAccessState] = useState(access);
    const [paymentState, setPaymentState] = useState({id:0, uid:'', cart:[]});

    const [allSportHouse, setAllSportHouse] = useState([]);
    const [allSubject, setAllSubject] = useState([]);

    const [navigate, setNavigate] = useState('');
    const [genreType, setGenreType] = useState('');

    const [step, setStep] = useState(0);
    const [totStep] = useState(2);
    const [currentSportHouse, setCurrentSporthHouse] = useState(0);

    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [isGenreCompulsory, setIsGenreCompulsory] = useState(false);

    const nav = useNavigate();

    const fetchAccessPayment = (id, sess_id) => {
        setPreloading2(true);
        appServices2.getAccessPayment(id, sess_id).then(res => {
            setPreloading2(false);
            setPaymentState(res.data);
        }).catch(e => {
            setPreloading2(false);
            setPaymentState({id:0, uid:'', cart:[]})
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Internal Server Error'], title:'Payment Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
        })
    }

    const fetchAllSportHouse = (sid) => {
        if(sid){
            setError({data:[], title:''});
            setPreloading(true);
            appServices5.getAllSchoolSportHouse(sid).then(res => {
                setAllSportHouse(res.data);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Internal Server Error'], title:'Sport House Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
                setAllSportHouse([]);
            })   
        }
    }

    const fetchAllSubject = (sid=0, csid="") => {
        if(sid){
            setError({data:[], title:''});
            setPreloading(true);
            appServices5.getAllSchoolSubject(sid, csid).then(res => {
                setAllSubject(addItemToArray(res.data, {is_checked:false}));
                const filter = res.data.filter((it) => it.genre_name === "science" || it.genre_name === "art");
                setPreloading(false);
                if(filter.length > 0){
                    setIsGenreCompulsory(true);
                }
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Internal Server Error'], title:'Subject Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
                setAllSubject([]);
                setPreloading(false);
            })
        }
    }

    

    const onUpdateSportHouse = (id=0) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        appServices2.updateAccess(accessState.uid, {house:id}).then(res => {
            // setSuccess({title:'Success', text:'Data Added Successfully'});
            setStep(2);
            setLoading(false);
            setAccessState(res.data);
        }).catch(e => {
            setError({title:'Sport House Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
            setLoading(false);
        })
    }

    const onClickSportHouse = (id=0) => {
        if(id === currentSportHouse){
            setCurrentSporthHouse(0);
            return;
        }
        setCurrentSporthHouse(id);
    }
    const onClickBack = () => {
        setLoading(true);
        if(step > 0){
            setStep((prev) => prev - 1);
            setLoading(false);
            if(accessState.house){
                setCurrentSporthHouse(accessState.house)
            }
            return;
        }
        setLoading(false);
        return;
    }
    const onClickNext = () => {
        setError(initialErrorState);
        setLoading(true);
        if(!step){
            if(allSportHouse.length > 0){
                setStep(1);
                setLoading(false);
                if(accessState.house){
                    setCurrentSporthHouse(accessState.house)
                }
                return;
            }
            setLoading(false);
            // setError({data:['Sport House Data Not Found'], title:'Subject Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
            setStep(2);
            return;
        }
        if(step === 1){
            if(currentSportHouse > 0){
                onUpdateSportHouse(currentSportHouse);
                return;
            }
            setError({title:'Error', data:['No House Selected']});
            setLoading(false);
            return;
        }
    }

    const onClickFinish = () => {
        const filter = allSubject.filter((item) => item.genre_name === "general" || item.is_checked);
        const filterReligionalItem = allSubject.filter((item) => item.genre_name === "religion" && item.is_checked);
        var promise = Promise.resolve()
        setSuccess(initialSuccessState);
        setError(initialErrorState);
        if(isGenreCompulsory && !genreType){
            setError({data:['Genre Type Required'], title:'Error'});
            return;
        }
        if(filter.length > 0){
            if(filterReligionalItem.length === 1){
                const lastItemNum = filter.length - 1;
                const upd = (id='') => {
                    appServices2.updateAccess(id, {is_assigned_subjects:true, is_setup_app_account:true}).then(res => {
                        setLoading(false);
                        setSuccess({text:'All Subjects Added Successfully', title:'Reloading....'});
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                        
                    }).catch(e => {
                        setError({title:'Subject Insert Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']})
                        setLoading(false);
                    })
                }
                filter.forEach((item, i) => {
                    setLoading(true);
                    promise = promise.then(() => {
                        const data = {
                            access: accessState.id,
                            subject: item.id
                        }
                        appServices2.insertAccessSubject(data).then(res => {
                            if(i === lastItemNum){
                                upd(accessState.uid);
                                return;
                            }
                            return new Promise((resolve) => setTimeout(resolve, 50));
                        }).catch(e => {
                            setError({title:'Subject Insert Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']})
                            if(i === lastItemNum){
                                if(e.response.data.detail.toString().toLowerCase() === "data already exist"){
                                    upd(accessState.uid);
                                }
                                return;
                            }
                            return new Promise((resolve) => setTimeout(resolve, 50));
                        })
                        
                    })
                })
                return;
            }
            setError({data:['One religious subject is required'], title:'Error'});
            return;
        }
        setError({data:['Data Error'], title:'Error'});
    }

    const onClickSubjectBox = (checked=false, num=-1, item={}) => {
        setError(initialErrorState);
        if(item.genre_name === "religion" && checked){
            const filterReligionalItem = allSubject.filter((item) => item.genre_name === "religion" && item.is_checked);
            if(!filterReligionalItem.length){
                setAllSubject(updateArray(allSubject, num, {...item, is_checked:checked}));
                return;
            }
            setError({data:['Only one religious subject is required'], title:'Error'});
            return;
        }
        setAllSubject(updateArray(allSubject, num, {...item, is_checked:checked}));
    }
    const onChangeGenre = (e) => {
        setGenreType(e.target.value);
        const val = e.target.value;
        if(val === "all"){
            const filter = allSubject.filter((it) => it.genre_name === "science" || it.genre_name === "art");
            if(filter.length > 0){
                let filter_arr = [];
                filter.forEach((it) => filter_arr.push({...it, is_checked:true}));
                setAllSubject(updateArrayWithArray(allSubject, filter_arr, 'num', 'num'));
                
            }
            return;
        }
        if(val){
            const filter = allSubject.filter((it) => it.genre_name === val);
            const oppGenre = val === "science" ? "art" : 'science';
            const filterOpp = allSubject.filter((it) => it.genre_name === oppGenre);
            if(filter.length > 0){
                let filter_arr = [];
                let filter_opp_arr = [];
                filter.forEach((it) => filter_arr.push({...it, is_checked:true}));
                filterOpp.forEach((it) => filter_opp_arr.push({...it, is_checked:false}));
                const combine = filter_arr.concat(filter_opp_arr)
                setAllSubject(updateArrayWithArray(allSubject, combine, 'num', 'num'));
                filterOpp.forEach((it) => setAllSubject(updateArray(allSubject, it.num, {...it, is_checked:false})));
            }
            return;
        }
        const filter = allSubject.filter((it) => it.genre_name === "science" || it.genre_name === "art");
        if(filter.length > 0){
            let filter_arr = [];
            filter.forEach((it) => filter_arr.push({...it, is_checked:false}));
            setAllSubject(updateArrayWithArray(allSubject, filter_arr, 'num', 'num'));
            return;
        }
    }

    useEffect(() => {
        if(school.id && access.id && !access.is_setup_app_account){
            fetchAllSportHouse(school.id);
            fetchAllSubject(school.id, access.class_section);
            setAccessState(access);
            fetchAccessPayment(access.uid, school.curr_sess);
            return;
        }
        setNavigate(`/${school.title}/app/dashboard`);
    }, [school, access]);

    // console.log(allSubject)

    useEffect(() => {
        if(reload){
            fetchAllSportHouse(school.id);
            fetchAllSubject(school.id, access.class_section);
            setReload(false);
        }
    }, [reload, school, access]);

    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav]);

    // useEffect(() => {
    //     if(accessState.id && !accessState.house){
    //         setStep(1);
    //         return;
    //     }
    // }, [accessState]);
    const btns = () => {
        if(!step){
            return(
                <>
                <div className='flex align-center'>
                    <button type="button" className='btn hover-opacity br-10 app-bg-color app-bg-text-color' onClick={() => onClickNext()}>Next <i className='fas fa-arrow-right'></i></button>
                </div>
                </>
            )
        }
        if(step < totStep){
            return(
                <>
                <div className='flex-50-line align-center'>
                    <button type="button" className='btn hover-opacity br-10 app-bg-color app-bg-text-color' onClick={() => onClickBack()}><i className='fas fa-arrow-left'></i> Back</button>
                </div>
                <div className='flex-50-line align-center'>
                    <button type="button" className='btn hover-opacity br-10 app-bg-color app-bg-text-color' onClick={() => onClickNext()}>Next <i className='fas fa-arrow-right'></i></button>
                </div>
                </>
            )
        }
        if(step === totStep){
            return(
                <>
                <div className='flex-50-line align-center'>
                    <button type="button" className='btn hover-opacity br-10 app-bg-color app-bg-text-color' onClick={() => onClickBack()}><i className='fas fa-arrow-left'></i> Back</button>
                </div>
                <div className='flex-50-line align-center'>
                    <button type="button" className='btn hover-opacity br-10 app-bg-color app-bg-text-color' onClick={() => onClickFinish()}>Finish <i className='fas fa-paper-plane'></i></button>
                </div>
                </>
            )
        }
    }
    const artScienceSelect = (sci=[], art=[]) => {
        if(sci.length > 0 || art.length > 0){
            return(
                <>
                <div className='flex-100'>
                    <label className='font-very-small form-group '>Are you a/an .... </label>
                    <select className='flex-100 form-control-theme font-very-small app-text-color' name="genre" onChange={e => onChangeGenre(e)} value={genreType}>
                        <option value={''}>???</option>
                        {sci.length > 0 && <option value={'science'}>Science Student</option>}
                        {art.length > 0 && <option value={'art'}>Art Student</option>}
                        <option value={'all'}>All</option>
                    </select>
                </div>
                </>
            )
        }
    }
    const insertData = () => {
        if(step === 0){
            return(
                <>{user_type === "parent" && (
                    <Mallet>
                        <div className='flex align-center'>
                            <span className="font-med fw-700 app-text-color"><b className='green'>Congratulations</b>, {accessState.is_self_sponsored ? `${accessState.user_last_name} ${accessState.user_first_name},` : `${accessState.last_name} ${accessState.first_name},`} your admission to {school.name}  ({school.title.toString().toUpperCase()}) as a/an {accessState.class_name.toString().toUpperCase()} student has been <b className="green">APPROVED</b> , </span><br /> <br />
                            <span className="font-med fw-700 app-text-color">Your ADMISSION NUMBER is {numberDigit(accessState.adm_no, 4)}</span><br /> <br />
                            <span className="font-small fw-700 app-text-color"> click on next to setup your school app account</span>
                        </div>
                    </Mallet>
                )}
                {user_type === "student" && (
                    <Mallet>
                        <div className='flex align-center'>
                            <span className="font-med fw-700 app-text-color"><b className='green'>Congratulations</b>, {accessState.is_self_sponsored ? `${accessState.user_last_name} ${accessState.user_first_name},` : `${accessState.last_name} ${accessState.first_name},`} your admission to {school.name}  ({school.title.toString().toUpperCase()}) as a/an {accessState.class_name.toString().toUpperCase()} student has been <b className="green">APPROVED</b> , </span><br /> <br />
                            <span className="font-med fw-700 app-text-color">Your ADMISSION NUMBER is {numberDigit(accessState.adm_no, 4)}</span><br /> <br />
                            <span className="font-small fw-700 app-text-color"> click on next to setup your school app account</span>
                        </div>
                    </Mallet>
                )}
                
                </>
            )
        }
        if(step === 1){
            return(
                <>
                    <span className="font-med fw-700 disp-block padd-4px app-text-color">Select a sport house, you would like to join!...</span>
                    <Mallet >
                        {allSportHouse.map((item, i) => {
                            return(
                                <Fragment key={i}>
                                    <div  className='flex-50-line'>
                                        <div className={`br-10 ${currentSportHouse === item.id ? 'app-opp-theme app-opp-text-color hover-opacity' : 'app-body-color hover-opacity'}`} onClick={() => onClickSportHouse(item.id)} style={{border:`1px solid ${item.color_name}`, paddingRight:'5px'}}>
                                            <span className='fw-500 font-small text-capitalize'>{item.color_name} {item.name && ` / ${item.name}`} House</span>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })}
                        
                    </Mallet>
                </>
            )
        }
        if(step === 2){
            const filterGeneralItem = allSubject.filter((item) => item.genre_name === "general");
            const filterReligionalItem = allSubject.filter((item) => item.genre_name === "religion");
            const filterOptionalItem = allSubject.filter((item) => item.genre_name === "optional");
            const filterScienceItem = allSubject.filter((item) => item.genre_name === "science");
            const filterArtItem = allSubject.filter((item) => item.genre_name === "art");
            const filterBothItem = allSubject.filter((item) => item.genre_name === "science" ||  item.genre_name === "art");
            return(
                <>
                    <span className="font-med fw-700 disp-block padd-4px app-text-color">Choose preferred subject to offer in school...</span>
                    <Mallet >
                        {filterGeneralItem.length > 0 && (
                            <>
                            <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">General Subjects</span>
                            </div>
                            {filterGeneralItem.map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <div  className='flex-33'>
                                            <div className={``} onClick={() => onClickSportHouse(item.id)} style={{paddingRight:'5px'}}>
                                                <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })}
                            </>
                        )}
                        {artScienceSelect(filterScienceItem, filterArtItem)}
                        {genreType === "science" && (
                            <>
                            {filterScienceItem.length > 0 && (
                                <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Science Subjects</span>
                                </div>
                                {filterScienceItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``}  style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        )}
                        {genreType === "art" && (
                            <>
                            {filterArtItem.length > 0 && (
                                <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Art Subjects</span>
                                </div>
                                {filterArtItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``}  style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        )}
                        {genreType === "all" && (
                            <>
                            {filterBothItem.length > 0 && (
                                <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">All</span>
                                </div>
                                {filterBothItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``} style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        )}
                        {filterReligionalItem.length > 0 && (
                            <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Religious Subjects</span>
                                </div>
                                {filterReligionalItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``}  style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} onChange={(e) => onClickSubjectBox(!item.is_checked, item.num, {...item})} checked={item.is_checked} /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </>
                        )}
                        {filterOptionalItem.length > 0 && (
                            <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Optional Subjects</span>
                                </div>
                                {filterOptionalItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``} style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} name="optional" onChange={(e) => onClickSubjectBox(!item.is_checked, item.num, {...item})} checked={item.is_checked} /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </>
                        )}
                        
                    </Mallet>
                </>
            )
        }
    }

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    return(
        <>
        {!accessState.is_setup_app_account && (
            <>
            <HeadComponent title='App Account Setup' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>  
                <Grid layout='l31'>
                    <GridContent header_text={`Setup App School Account....`} header_icon="fas fa-align-right">
                        {loading && <Loader />}
                        <Section>
                        {preloading ? 
                        (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                            <div className='align-center'>
                                <span className='app-text-sec-color fw-600 disp-block align-center'>Step ({step}/{totStep})</span>
                            </div>
                            {insertData()}
                            <Mallet>
                                {btns()}
                            </Mallet>
                        </>
                        )}
                        </Section>
                    </GridContent>
                    <GridContent header_text={`Payment Reciept`} header_icon="fas fa-money-bill">
                    {preloading2 ? 
                        (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section>
                            <Mallet>
                                {paymentState.is_paid && (
                                    <div className='flex align-center'>
                                        <span className='app-text-color font-small fw-600 disp-block green'>Transaction Approved</span>
                                    </div>
                                )}
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>Invoice: </span> <span className='app-text-color fw-500 font-slightly-small'>{paymentState.invoice}</span>
                                </div>
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>Amount: </span> <span className='app-text-color fw-600 font-slightly-small'>{school.curr_symbol}{convertNumber(paymentState.tot_amt_paid)}</span>
                                </div>
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>No. Item: </span> <span className='app-text-color fw-500 font-slightly-small'>{paymentState.cart.length}</span>
                                </div>
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>Payer's Name: </span> <span className='app-text-color fw-500 font-very-small'>{paymentState.paid_by_last_name} {paymentState.paid_by_first_name}</span>
                                </div>
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>Payee's Name: </span> <span className='app-text-color fw-500 font-very-small'>{accessState.is_self_sponsored ? `${accessState.user_last_name} ${accessState.user_first_name},` : `${accessState.last_name} ${accessState.first_name},`}</span>
                                </div>
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>Paid By: </span> <span className='app-text-color fw-500 font-slightly-small'>{paymentState.paid_by_user_type === "student" ? 'Self' : 'Parent / Guardian'}</span>
                                </div>
                                <div className='flex'>
                                    <span className='app-text-sec-color fw-600 font-very-small' style={{paddingRight:'3px'}}>Date Paid: </span> <span className='app-text-color fw-500 font-very-small'>{ paymentState.pay_date}</span>
                                </div>
                                <div className='flex align-center'>
                                    <button type="button" className='btn hover-opacity br-10 app-opp-theme app-opp-text-color'>Generate Reciept</button>
                                </div>
                            </Mallet>
                        </Section>
                        </>
                        )
                    }
                    </GridContent>
                </Grid>
            </ComponentWrapper>
            </>
        )}
            
        </>
    )
}


export default AccountAppSetup;