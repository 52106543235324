import React, { Fragment } from "react";
import { Wrapper, Content } from "./style";

const DropDownBox = (props) => {
   
    return(
        <Fragment>
            <Wrapper onClick={e => e.stopPropagation()}>
                <Content className={`${props.theme}`}>
                    <div className="container">
                        {props.children}
                    </div>
                </Content>
            </Wrapper>
        </Fragment>
    )
}

export default DropDownBox;