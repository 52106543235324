import React, { useState, useEffect, useMemo, Fragment, useCallback} from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom'

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import View from 'components/app/school/SmallerComponents/mallet/view';
import Mash from 'components/app/school/SmallerComponents/mash';
import Btn from 'components/app/school/SmallerComponents/mash/btn';
import ImgViewer from 'components/app/school/SmallerComponents/imgViewer';
import Img from 'components/app/school/SmallerComponents/imgViewer/img';
import Note from 'components/app/school/SmallerComponents/NOTE';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "../../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';


const ViewEnrolledStudent = ({school_id, user_id, user_type, school_title}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const initialEnrolledState = useMemo(() => ({class_enrolled:{id:null}, interviewer:{id:null}, interviewer_end:{id:null}, accepted_by:{id:null}, class_accepted:{id:null}, date_start_interview:'', date_finish_interview:'', interviewer_action:{id:null}, school:{id:null, country:{id:null}}, school_branch:{id:null}, student:{id:null, guardian_type:{id:null}, fath_country_origin:{id:null}, fath_occupation:{id:null}, moth_country_origin:{id:null}, moth_occupation:{id:null}, emergency_country_res:{id:null}, emergency_occupation:{id:null}, emergency_type:{id:null}, loc_heard:{id:null},  country_origin:{id:null}, disabilty:[], guardian:{country_origin:{id:null}, spouse_country_origin:{id:null}, emergency_country_origin:{id:null}, emergency_occupation:{id:null}, emergency_relate:{id:null}, occupation:{id:null}, spouse_occupation:{id:null}, state_res:{id:null}, user:{id:null, first_name:'', profile_pic:''}, guardian_type:{id:null},  id:null}, state_res:{id:null}, med_challenge:{id:null}, user:{id:null, first_name:'', profile_pic:''}}, profile_pic:'',  id:null, uid:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    const [notify, setNotify] = useState(initialSuccessState);

    const [imgViewer, setImgViewer] = useState({src:'', alt:"", title:''});

    const [state, setState] = useState(initialEnrolledState);
    const [notifyState, setNotifyState] = useState({start_date:'', end_date:''})

    const [allClassesData, setAllClassesData] = useState([]);
    const [allData, setAllData] = useState(initialPaginationState);

    const [preloading, setPreloading] = useState(false);
    const [preloading2, setPreloading2] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [exist, setExist] = useState(true);
    const [openImgViewer, setOpenImgViewer] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [navigate, setNavigate] = useState('');

    const nav = useNavigate();

    const { id } = useParams();

    const fetchApplicant = useCallback(() => {
        setPreloading(true);
        setExist(false);
        setLoading(true);
        setError(initialErrorState);
        if(id){
            appServices.getStudentEnrolled_Admin(id).then(res => {
                setPreloading(false);
                setExist(true);
                setLoading(false);
                setState({...initialEnrolledState, ...res.data});
                res.data.is_accepted ? setDisableBtn(true) : setDisableBtn(false);
            }).catch(e => {
                setPreloading(false);
                setLoading(false);
                setExist(false);
                setState(initialEnrolledState);
                setError({title:'Data Error', data:['Something Went Wrong']})
            })
            return;
        }
    }, [initialEnrolledState, initialErrorState, id]
    );

    const fetchAllEnrolledStudent = (sid) => {
        setPreloading2(true);
        appServices.getAllStudentEnrolled_Admin(sid, 1, 20).then(res => {
            setPreloading2(false);
            setAllData({...res.data});
        }).catch(e => {
            setPreloading2(false);
            setAllData({count:0, previous:undefined, next:undefined, results:[]});
            setError({data:['Internal Server Error'], title:'All Enrolled Data Fetch Error'});
        })
    }

    const fetchAllClasses = (school_id) => {
        if(school_id){
            setError({data:[], title:''});
            appServices.getAllClassSchoolItem(school_id).then(res => {
                setAllClassesData(res.data);
            }).catch(e => {
                setAllClassesData([]);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }

    const handleNotifyChange = e => {
        setNotifyState({...notifyState, [e.target.name]:e.target.value});
    }

    const getClassName = (id=0) => {
        const filterItem = allClassesData.filter((item) => item.classes === id);
        if(filterItem.length > 0){
            return filterItem[0].name
        }
        return '';
    }

    const handleImgClick = (imgSrc, alt="blank", title="") => {
        if(imgSrc){
            setOpenImgViewer(true);
            setImgViewer({src:imgSrc, alt:alt, title:title});
            setError(initialErrorState);
            return;
        }
        setError({data:['Image Not Found'], title:'Error'})
    }
    const onReject = () => {
        let data = {interviewer_action:user_id, is_enrolled:false};
        setLoading2(true);
        appServices.proccessStudentEnrolled_Admin(state.uid, data).then((res) => {
            setSuccess({text:'Child Rejected Successfully', title:'Redirecting...'});
            setTimeout(() => {
                setNavigate(`/${school_title}/app/student/enrolled`);
                setLoading2(false);
            }, 500)
        }).catch(e => {
            setLoading2(false);
            if(e.response.data.code === "INTERVIEW_DATE_ERROR"){
                user_type === "school_owner" ? setError({data:[e.response.data.detail], title:'Start Interview Error'}) : setError({data:[e.response.data.detail, "Contact Administrator to begin enrollment"], title:'Start Interview Error'});
                user_type === "school_owner" && setNotify({text:'Fill In The Form Below To Start Enrollment', type:'error', timer:300000, id:id});
            }
            else{
                setError({data:[e.response.data.detail], title:'Reject Child Error'});
            }
        })
    }
    const onClickMashBtn = (type, e) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setNotify({text:'', type:'', timer:0, id:0});
        setWarning(initialSuccessState);
        setLoading2(true);
        if(type === "add_interview_date"){
            e.preventDefault();
            const data = {add_interview_date:true, interview_start:notifyState.start_date, interview_end:notifyState.end_date}
            appServices.proccessStudentEnrolled_Admin(state.uid, data).then((res) => {
                setSuccess({text:'Interview Started Successfully', title:''});
                setState({...state, ...res.data});
                setLoading2(false);
            }).catch(e => {
                setLoading2(false);
                if(e.response.data.code === "INTERVIEW_DATE_ERROR"){
                    user_type === "school_owner" ? setError({data:[e.response.data.detail], title:'Start Interview Error'}) : setError({data:[e.response.data.detail, "Contact Administrator to begin enrollment"], title:'Start Interview Error'});
                    user_type === "school_owner" && setNotify({text:'Fill In The Form Below To Start Enrollment', type:'error', timer:300000, id:state.id});
                }
                else{
                    setError({data:[e.response.data.detail], title:'Start Interview Error'});
                }
            })
            return;
        }
        if(type === "start_interview"){
            const data = {is_start_interview:true, interviewer:user_id, date_start_interview:new Date()}
            appServices.proccessStudentEnrolled_Admin(state.uid, data).then((res) => {
                setSuccess({text:'Interview Started Successfully', title:''});
                setState({...state, ...res.data});
                setLoading2(false);
            }).catch(e => {
                setLoading2(false);
                if(e.response.data.code === "INTERVIEW_DATE_ERROR"){
                    user_type === "school_owner" ? setError({data:[e.response.data.detail], title:'Start Interview Error'}) : setError({data:[e.response.data.detail, "Contact Administrator to begin enrollment"], title:'Start Interview Error'});
                    user_type === "school_owner" && setNotify({text:'Fill In The Form Below To Start Enrollment', type:'error', timer:300000, id:id});
                }
                else{
                    setError({data:[e.response.data.detail], title:'Start Interview Error'});
                }
            })
            return;
        }
        if(type === "end_interview"){
            const data = {is_finish_interview:true, interviewer_end:user_id, date_finish_interview:new Date()}
            appServices.proccessStudentEnrolled_Admin(state.uid, data).then((res) => {
                setSuccess({text:'Interview Ended Successfully', title:''});
                setState({...state, ...res.data});
                setLoading2(false);
            }).catch(e => {
                setLoading2(false);
                if(e.response.data.code === "INTERVIEW_DATE_ERROR"){
                    user_type === "school_owner" ? setError({data:[e.response.data.detail], title:'Start Interview Error'}) : setError({data:[e.response.data.detail, "Contact Administrator to begin enrollment"], title:'Start Interview Error'});
                    user_type === "school_owner" && setNotify({text:'Fill In The Form Below To Start Enrollment', type:'error', timer:300000, id:id});
                }
                else{
                    setError({data:[e.response.data.detail], title:'Start Interview Error'});
                }
            })
            return;
        }
        if(type === "pre_accept"){
            setTimeout(() => {
                setLoading2(false);
                setNotify({id:state.uid, text:'Once accepted, it cannot be reverted', type:'warning', timer:300000});
                const filterItem = allClassesData.filter((item) => item.classes === state.class_enrolled.id);
                filterItem.length > 0 ? setNotifyState({...notifyState, class_accepted:filterItem[0].id}) : fetchAllClasses(school_id);
            })
            return;
        }
        if(type === "accept"){
            e.preventDefault();
            const data = {accepted_by:user_id, is_accepted:true, class_accepted:notifyState.class_accepted}
            appServices.proccessStudentEnrolled_Admin(state.uid, data).then((res) => {
                setDisableBtn(true);
                setSuccess({text:'Child Accepted Successfully, Payment Portal Sent To Parent', title:''});
                setState({...state, ...res.data});
                setLoading2(false);
            }).catch(e => {
                setLoading2(false);
                if(e.response.data.code === "INTERVIEW_DATE_ERROR"){
                    user_type === "school_owner" ? setError({data:[e.response.data.detail], title:'Start Interview Error'}) : setError({data:[e.response.data.detail, "Contact Administrator to begin enrollment"], title:'Start Interview Error'});
                    user_type === "school_owner" && setNotify({text:'Fill In The Form Below To Start Enrollment', type:'error', timer:300000, id:id});
                }
                else{
                    setError({data:[e.response.data.detail], title:'Accept Child Error'});
                }
            })
            return;
        }
        if(type === "reject"){
            setLoading2(false);
            setTimeout(() => {
                setWarning({id:state.uid, title:'Warning', text:'Once rejected, it cannot be reverted', click_text:'Reject', handleClick:() => onReject()})
            })          
            return;
        }
        setLoading2(false);
    }

    useEffect(() => {
        fetchApplicant();
        fetchAllClasses(school_id);
        fetchAllEnrolledStudent(school_id);
    }, [fetchApplicant, school_id]);
    useEffect(() => {
        if(reloadData){
            fetchAllClasses(school_id);
            fetchApplicant();
            setReloadData(false);
        }
    }, [reloadData, school_id, fetchApplicant]);
    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    const stat = (data={}) => {
        if(data.is_accepted){
            return '(Undergoing payment)'
        }
        if(data.is_interviewer_accepted){
            return '(Interviewer accepted this student)'
        }
        if(data.is_finish_interview){
            return '(Interview finished)'
        }
        if(data.is_start_interview){
            return '(Interview startted)'
        }
        return 'PENDING';
    }
    const btnn = () => {
        if(state.is_accepted && !state.is_done){
            return (
                <>
                <Btn  color='blue' flex="33" onClick={() => setNavigate(`/${school_title}/student/enrolled`)}><i className='fas fa-arrow-left'></i> Back </Btn>
                <Btn disabled={disableBtn} color='green' align='center' >Undergoing Payment</Btn>
                </>
            )
        }
        else if(state.enroll_type === "returning" && state.is_enrolled && !state.is_accepted){
            return(
                <>
                    <Btn disabled={disableBtn} onClick={() => onClickMashBtn("pre_accept")} color='green' align="left" flex={50} >Accept <i className='fas fa-user-plus'></i></Btn>
                    <Btn disabled={disableBtn} onClick={() => onClickMashBtn("reject")} flex={33} color='red'>Reject <i className='fas fa-running'></i></Btn>
                </>
            )
        }
        else if(state.enroll_type === "new" && state.is_enrolled && !state.is_start_interview){
            return(
                <>
                    <Btn disabled={disableBtn} color='blue' flex={50} align='left' onClick={() => onClickMashBtn("start_interview")}>Start Interview <i className='fas fa-question-circle'></i></Btn>
                    <Btn color='red' flex="33" disabled>Reject <i className='fas fa-running'></i></Btn>
                </>
            )
        }
        else if(state.enroll_type === "new" && state.is_enrolled && state.is_start_interview && !state.is_finish_interview){
            return(
                <>
                    <Btn disabled={disableBtn} color='pink' flex={50} align='left' onClick={() => onClickMashBtn("end_interview")}>End Interview <i className='fas fa-hourglass-end'></i></Btn>
                    <Btn  color='red' flex="33" disabled>Reject <i className='fas fa-running'></i></Btn>
                </>
            )
        }
        else if(state.enroll_type === "new" && state.is_enrolled && state.is_start_interview && state.is_finish_interview && !state.is_accepted){
            return(
                <>
                    <Btn disabled={disableBtn} onClick={() => onClickMashBtn("pre_accept")} color='green' align="left" flex={50} >Accept <i className='fas fa-user-plus'></i></Btn>
                    <Btn disabled={disableBtn} onClick={() => onClickMashBtn("reject")} flex={33} color='red'>Reject <i className='fas fa-running'></i></Btn>
                </>
            )
        }
    }


    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertClassesOption = allClassesData.map((item, i) => {
        return(
            <option value={item.id} key={i}>{`${item.name}`}</option>
        )
    })

    return(
        <>
        {loading && (<Loader />)}
        {!preloading && (
        <>
            {exist ? (
                <>
                <HeadComponent title='Manage Enrolled Child' />
                <BreadCrumb arr={['localhost:3000', school_title, 'student', 'enrolled', 'View Enrolled Child']} />
                <ComponentWrapper>
                    <NotificationWrapper>
                        {error.title && (errorHandler)}
                        {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                        {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                        {notify.id && (<Notification timer={notify.timer} type={notify.type} text={notify.text}>
                        <>
                        {notify.type === "error" && (
                            <form onSubmit={e => onClickMashBtn("add_interview_date", e)}>
                                <div className='disp-flex wrap padd-4px'>
                                    <div className='flex-100'>
                                        <label className='font-super-small'>Enrollment Start Date </label>
                                        <input type="date" required name="start_date" onChange={e => handleNotifyChange(e)} className='app-theme form-control-theme font-slightly-small' />
                                    </div>
                                </div>
                                <div className='disp-flex wrap padd-4px'>
                                    <div className='flex-100'>
                                        <label className='font-super-small'>Enrollment End Date </label>
                                        <input type="date" required name="end_date" onChange={e => handleNotifyChange(e)} className='app-theme form-control-theme font-slightly-small' />
                                    </div>
                                </div>
                                <div className='disp-flex wrap padd-4px'>
                                    <div className='flex-100 align-center'>
                                    <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                        Save <i className={"fas fa-paper-plane"}></i> </button>
                                    </div>
                                </div>
                            </form>
                        )}
                        {notify.type === "warning" && (
                            <form onSubmit={e => onClickMashBtn("accept", e)}>
                                <div className='disp-flex wrap padd-4px'>
                                    <div className='flex-100'>
                                        <label className='font-super-small'>Enroll child to: </label>
                                        <select required name="class_accepted" value={notifyState.class_accepted} onChange={e => handleNotifyChange(e)} className='app-theme form-control-theme font-slightly-small'>
                                            <option value="">Select class to enroll child</option>
                                            {insertClassesOption}
                                        </select>
                                    </div>
                                </div>
                                <div className='disp-flex wrap padd-4px'>
                                    <div className='flex-100 align-center'>
                                    <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                        Accept <i className={"fas fa-user-plus"}></i> </button>
                                    </div>
                                </div>
                            </form>
                        )}
                        
                        </>
                    </Notification>)}
                    </NotificationWrapper>
                    <Grid layout='l31'>
                        <GridContent header_text={`View Enrolled Child's Data`} header_icon="fas fa-eye">
                            <Note>Once the child has been accepted or rejected, it cannot be reverted and payment portal will be sent, if accepted</Note>
                        {preloading ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (
                        <>
                        {loading2 && (<Loader />)}
                        {user_type === "school_owner" && (
                            <Section icon="fas fa-info-circle" header="Interviewers Review">
                                <Mallet>
                                    <View type='text' label="Date Interview Starts" classname='text-capitalize'>{ `${state.date_start_interview}` }</View>
                                    <View type='text' label="Interview Started By" classname='text-capitalize'>{ `${state.interviewer && state.interviewer.last_name + ' ' + state.interviewer.first_name}` }</View>
                                    <View type='text' label="Date Interview End" classname='text-capitalize'>{ `${state.date_finish_interview}` }</View>
                                    <View type='text' label="Interview Ended By" classname='text-capitalize'>{ `${state.interviewer_end && state.interviewer_end.last_name + ' ' + state.interviewer_end.first_name}` }</View>
                                    {state.is_accepted  && <View type='text' label="Class Enrolled Child To" classname='text-capitalize'>{ `${state.class_accepted && state.class_accepted.name}` }</View>}
                                    {state.is_accepted && <>{state.accepted_by.id !== user_id && <View type='text' label="Child Accepted By" classname='text-capitalize'>{ `${state.accepted_by && state.accepted_by.last_name + ' ' + state.accepted_by.first_name}` }</View> } </>}
                                </Mallet>
                            </Section>
                        )}
                        <Section icon="fas fa-info-circle" header="Child Information">
                            <Mallet>
                                <View type='pp' label='Profile Picture' src={`${BACKEND_URL}${state.student.is_self_sponsored ? state.student.user.profile_pic : state.student.profile_pic}` } alt="Profile" />
                                <View type="arr" arr={[{label:'Fullname', text:`${state.student.is_self_sponsored ? state.student.user.last_name : state.student.sur_name} ${state.student.is_self_sponsored ? state.student.user.first_name : state.student.other_names}`},
                                                        {label:'Email', text:`${state.student.is_self_sponsored ? state.student.user.email : state.student.guardian.user.email}`}, {label:'Phone Number', text:`+${state.student.is_self_sponsored ? state.student.user.phone_number : state.student.guardian.user.phone_number}`},
                                                        {label:'Enrolled As', text:`${state.enroll_type} Student`, classname:'text-capitalize'},
                                                        {label:'Class Enrolled', text:getClassName(state.class_enrolled.id)},
                                                        {label:'Status', text:`${stat({is_accepted:state.is_accepted, is_finish_interview:state.is_finish_interview, is_interviewer_accepted:state.is_interviewer_accepted, is_start_interview:state.is_start_interview})}`, classname:'text-capitalize fw-700'}]} />
                                <View type="arr" arr={[{label:'Date Of Birth', text:`${state.student.dob}`}, 
                                                        {label:'School Branch Applied', text:`${state.school_branch.name}`},
                                                    {label:'Sponsored By', text:`${state.student.is_self_sponsored ? 'Self' : state.student.is_guardian_parent ? 'Parent' : 'Guardian'}`, classname:'text-capitalize'},
                                                    {label:'Religion', text:`${state.student.rel}`, classname:'text-capitalize'},
                                                    {label:'Gender', text:state.student.gender === "F" ? 'Female' : 'Male'}, 
                                                    {label:'Language(s) Spoken', text:state.student.lang_spoken}, 
                                                    ]} />
                                <View type="arr" arr={[{label:'Nationality', text:`${state.student.country_origin ? state.student.country_origin.name :''}`}, 
                                                    {label:'State Of Residence', text:`${state.student.state_res ? state.student.state_res.name : ''}`, classname:'text-capitalize'},
                                                    {label:'Address', text:`${state.student.address}`, classname:'text-capitalize'},
                                                    {label:"Child's Custody", text:`${state.student.is_father_custody ? 'Father' : state.student.is_mother_custody ? 'Mother' : state.student.is_both_custody ? 'Both Mother and Father' : state.student.is_guardian_custody ? 'Guardian' : 'Self'}`, classname:'text-capitalize'},
                                                    ]} />
                                <View type="arr" arr={[ 
                                                    {label:'Birth Certificate', btn_text:`Click to view birth cert`, onClickImg:() => handleImgClick(`${BACKEND_URL}${state.student.birth_cert}`, 'Birth Certificate', 'Birth Certificate')},
                                                    {label:'Previous School Attended', text:`${state.student.details}`},
                                                    {label:'Previous School Report Card', btn_text:`Click to view report card`, onClickImg:() => handleImgClick(`${BACKEND_URL}${state.student.report_card}`, 'Report Card', 'Report Card')},
                                                    {label:"Child's Custody", text:`${state.student.is_father_custody ? 'Father' : state.student.is_mother_custody ? 'Mother' : state.student.is_both_custody ? 'Both Mother and Father' : state.student.is_guardian_custody ? 'Guardian' : 'Self'}`, classname:'text-capitalize'},
                                                    ]} />
                                <View type="arr" arr={[
                                                    {label:'Sponsored By', text:`${state.student.is_self_sponsored ? 'Self' : state.student.is_guardian_parent ? 'Parent' : 'Guardian'}`, classname:'text-capitalize'},
                                                    {label:'Medical Challenge', text:`${state.student.is_medical_challenged ? state.student.med_challenge && state.student.med_challenge.name : 'None'}`}, 
                                                    {label:'Disability', text:state.student.disabilty > 0 ? state.student.disabilty.map((item) => item.name) : 'None'}, 
                                                    {label:'Mother Deceased?', text:`${state.student.is_mother_deceased ? 'Yes' : 'No'}`}, 
                                                    {label:'Father Deceased?', text:`${state.student.is_father_deceased ? 'Yes' : 'No'}`, classname:'text-capitalize'},
                                                    ]} />
                                
                            </Mallet>
                        </Section>
                        {state.student.guardian_type && state.student.guardian && !state.student.is_self_sponsored && (
                            <Section icon="fas fa-info-circle" header="Guardian Information">
                                <Mallet>
                                    <View type='text' label="Guardian Type" classname='text-capitalize'>{ `${state.student.guardian_type && state.student.guardian_type.name}` }</View>
                                    <View type='text' label="Guardian's FullName" classname='text-capitalize'>{ `${state.student.guardian.title}. ${state.student.guardian.user.last_name} ${state.student.guardian.user.first_name}` }</View>
                                    <View type='text' label="Guardian's Email" >{`${state.student.guardian.user.email}` }</View>
                                    <View type='text' label="Guardian's Marital Status" >{`${state.student.guardian.marital_status}` }</View>
                                    <View type='text' label="Guardian's Phone Number" >{`+${state.student.guardian.user.phone_number}` }</View>
                                    <View type='text' label="Guardian's Work Phone Number" >{`${state.student.guardian.work_phone}` }</View>
                                    <View type='text' label="Guardian's Occupation" >{`${state.student.guardian.occupation ? state.student.guardian.occupation.name : ''}`}</View>
                                    <View type='text' label="Guardian's Nationality" >{`${state.student.guardian.country_origin ? state.student.guardian.country_origin.name : ''}`}</View>
                                    <View type='text' label="Guardian's Address" >{`${state.student.guardian.address}`}</View>
                                    <View type='text' label="Guardian's Religion" classname='text-capitalize'>{`${state.student.guardian.rel}` }</View>
                                    <View type="text"></View>
                                    <View type="text"></View>

                                    {state.student.guardian.has_spouse && (
                                        <>
                                            <View type='text' label="Spouse Type" >{`${state.student.guardian.spouse_type}` }</View>
                                            <View type='text' label="Spouse's FullName" classname='text-capitalize'>{`${state.student.guardian.spouse_title}. ${state.student.guardian.spouse_fullname}` }</View>
                                            <View type='text' label="Spouse's Email" >{`${state.student.guardian.spouse_email}` }</View>
                                            <View type='text' label="Spouse's Phone Number" >{`${state.student.guardian.spouse_phone}` }</View>
                                            <View type='text' label="Spouse's Work Phone Number" >{`${state.student.guardian.spouse_work_phone}` }</View>
                                            <View type='text' label="Spouse's Occupation" >{`${state.student.guardian.spouse_occupation.name}` }</View>
                                            <View type='text' label="Spouse's Nationality" >{`${state.student.guardian.spouse_country_origin && state.student.guardian.spouse_country_origin.name}` }</View>
                                            <View type='text' label="Spouse's Address" >{`${state.student.guardian.address}` }</View>
                                        </>
                                    )}
                                </Mallet>
                            </Section>
                        )}
                        {state.student.is_guardian_parent && state.student.guardian && !state.student.guardian_type && (
                            <Section icon="fas fa-info-circle" header="Parent Information">
                                <Mallet>
                                    <View type='text' label="Father's FullName" classname='text-capitalize'>{state.student.fath_fullname ?  state.student.fath_fullname : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? `${state.student.guardian.title}. ${state.student.guardian.user.last_name} ${state.student.guardian.user.first_name}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_title}. ${state.student.guardian.spouse_fullname}` }</View>
                                    <View type='text' label="Father's Email" >{state.student.fath_email ?  state.student.fath_email : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? `${state.student.guardian.user.email}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_email}` }</View>
                                    <View type='text' label="Father's Phone Number" >{state.student.fath_phone ?  "+"+state.student.fath_phone : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? `+${state.student.guardian.user.phone_number}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_phone}` }</View>
                                    <View type='text' label="Father's Work Phone Number" >{state.student.fath_work_phone ?  state.student.fath_work_phone : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? `` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_work_phone}` }</View>
                                    <View type='text' label="Father's Occupation" >{state.student.fath_occupation ?  state.student.fath_occupation.name : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? `${state.student.guardian.occupation.name}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_occupation.name}` }</View>
                                    {/* <View type='text' label="Father's Nationality" >{state.student.fath_country_origin ?  state.student.fath_country_origin : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? state.student.guardian.country_origin ? `${state.student.guardian.country_origin.name}` : '' : state.student.is_guardian_parent_spouse && state.student.guardian.has_spouse ? state.student.guardian.spouse_country_origin && `${state.student.guardian.spouse_country_origin.name}` : '' }</View> */}
                                    <View type='text' label="Father's Address" >{state.student.fath_addr ?  state.student.fath_addr : state.student.guardian.gender === "M" & state.student.is_guardian_parent ? `${state.student.guardian.address}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.address}` }</View>
                                    <View type='text' label="Father's Religion" classname='text-capitalize'>{state.student.fath_rel ?  state.student.fath_rel : state.student.guardian.gender === "M" & state.student.is_guardian_parent && `${state.student.guardian.rel}` }</View>
                                    <View type='img' label="Father's Signature" onClickImg={() => handleImgClick(`${BACKEND_URL}${state.student.first_relationship === "father" ? state.student.first_sign : state.student.second_sign}`, 'Father Signature', 'Signature')}>Click to view sign</View>
                                    
                                    <View type='text' label="Mother's FullName" classname='text-capitalize'>{state.student.moth_fullname ?  state.student.moth_fullname : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `${state.student.guardian.title}. ${state.student.guardian.user.last_name} ${state.student.guardian.user.first_name}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_title}. ${state.student.guardian.spouse_fullname}` }</View>
                                    <View type='text' label="Mother's Phone Number" >{state.student.moth_phone ?  "+"+state.student.moth_phone : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `+${state.student.guardian.user.phone_number}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_phone}` }</View>
                                    <View type='text' label="Mother's Email" >{state.student.moth_email ?  state.student.moth_email : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `${state.student.guardian.user.email}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_email}` }</View>
                                    <View type='text' label="Mother's Work Phone Number" >{state.student.moth_work_phone ?  state.student.moth_work_phone : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_work_phone}` }</View>
                                    <View type='text' label="Mother's Occupation" >{state.student.moth_occupation ?  state.student.moth_occupation.name : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `${state.student.guardian.occupation.name}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_occupation.name}` }</View>
                                    <View type='text' label="Mother's Nationality" >{state.student.moth_country_origin ?  state.student.moth_country_origin.name : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `${state.student.guardian.country_origin.name}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.spouse_country_origin && state.student.guardian.spouse_country_origin.name}` }</View>
                                    <View type='text' label="Mother's Address" >{state.student.moth_addr ?  state.student.moth_addr : state.student.guardian.gender === "F" & state.student.is_guardian_parent ? `${state.student.guardian.address}` : state.student.is_guardian_parent_spouse & state.student.guardian.has_spouse && `${state.student.guardian.address}` }</View>
                                    <View type='img' label="Mother's Signature" onClickImg={() => handleImgClick(`${state.student.second_relationship === "mother" ? state.student.second_sign ? `${BACKEND_URL}${state.student.second_sign}` :'' : state.student.first_sign}`, 'Mother Signature', 'Signature')}>Click to view sign</View>
                                </Mallet>
                            </Section>
                        )}
                        {state.student.is_self_sponsored && !state.student.guardian_type  && (
                            <Section icon="fas fa-info-circle" header="Parent Information">
                                <Mallet>
                                    <View type='text' label="Father's FullName" classname='text-capitalize'>{state.student.fath_fullname}</View>
                                    <View type='text' label="Father's Email" >{state.student.fath_email  }</View>
                                    <View type='text' label="Father's Phone Number" >{state.student.fath_phone }</View>
                                    <View type='text' label="Father's Work Phone Number" >{state.student.fath_work_phone}</View>
                                    <View type='text' label="Father's Occupation" >{state.student.fath_occupation && state.student.fath_occupation.name}</View>
                                    <View type='text' label="Father's Nationality" >{state.student.fath_country_origin && state.student.fath_country_origin.name}</View>
                                    <View type='text' label="Father's Address" >{state.student.fath_addr }</View>
                                    <View type='text' label="Father's Religion" classname='text-capitalize'>{state.student.fath_rel}</View>
                                    <View type='img' label="Father's Signature" onClickImg={() => handleImgClick(`${state.student.first_sign ? `${BACKEND_URL}${state.student.first_sign}` :''}`, 'Father Signature', 'Signature')}>Click to view sign</View>
                                    
                                    <View type='text' label="Mother's FullName" classname='text-capitalize'>{state.student.moth_fullname}</View>
                                    <View type='text' label="Mother's Phone Number" >{state.student.moth_phone}</View>
                                    <View type='text' label="Mother's Email" >{state.student.moth_email }</View>
                                    <View type='text' label="Mother's Work Phone Number" >{state.student.moth_work_phone}</View>
                                    <View type='text' label="Mother's Occupation" >{state.student.moth_occupation && state.student.moth_occupation.name}</View>
                                    <View type='text' label="Mother's Nationality" >{state.student.moth_country_origin &&  state.student.moth_country_origin.name}</View>
                                    <View type='text' label="Mother's Address" >{state.student.moth_addr}</View>
                                    <View type='img' label="Mother's Signature" onClickImg={() => handleImgClick(`${state.student.second_sign ? `${BACKEND_URL}${state.student.second_sign}` :''}`, 'Mother Signature', 'Signature')}>Click to view sign</View>
                                </Mallet>
                            </Section>
                        )}
                        <Section icon="fas fa-info-circle" header="Emergency Contact Information">
                            <Mallet>
                                <View type='text' label="FullName" classname='text-capitalize'>{state.student.emergency_fullname ?  state.student.emergency_fullname : state.student.guardian ? `${state.student.guardian.emergency_fullname}` : '' }</View>
                                <View type='text' label="Email" >{state.student.emergency_email ?  state.student.emergency_email : state.student.guardian ? `${state.student.guardian.emergency_email}` : '' }</View>
                                <View type='text' label=" Phone Number" >{state.student.emergency_phone ? state.student.emergency_phone : state.student.guardian ? `${state.student.guardian.emergency_phone}` : '' }</View>
                                <View type='text' label="Work Phone Number" >{state.student.emergency_work_phone ?  state.student.emergency_work_phone : state.student.guardian ? `${state.student.guardian.emergency_work_phone}` : ''}</View>
                                <View type='text' label="Occupation" >{state.student.emergency_occupation ?  state.student.emergency_occupation.name : state.student.guardian ? `${state.student.guardian.emergency_occupation && state.student.guardian.emergency_occupation.name }` : '' }</View>
                                <View type='text' label="Country Of Residence" >{state.student.emergency_country_res ?  state.student.emergency_country_res.name : state.student.guardian ? `${state.student.guardian.emergency_country_origin && state.student.guardian.emergency_country_origin.name}` : ''}</View>
                                <View type='text' label="Address" >{state.student.emergency_addr ?  state.student.emergency_addr : state.student.guardian ? `${state.student.guardian.emergency_addr}` : ''}</View>
                                <View type='text' label="Relation to child" classname='text-capitalize'>{state.student.emergency_type ?  state.student.emergency_type.name : state.student.guardian ? `${state.student.guardian.emergency_relate && state.student.guardian.emergency_relate.name}` : ''}</View>
                            </Mallet>
                        </Section>
                    </> 
                        ) }
                            
                        </GridContent>
                        {preloading2 ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                <GridContent style={{maxHeight:'80vh'}}>
                                    <Section>
                                        <span className="font-med fw-700 disp-block app-text-color">Other Enrolled Children</span><br />
                                        <Mallet>
                                        {allData.results.map((item, i) => {
                                            const full_name = item.last_name ? item.last_name + ' ' + item.first_name : item.enroll_sur_name + ' ' + item.enroll_other_name;
                                                return(
                                                    <Fragment key={i}>
                                                        <View flex='30-line' type=""><img src={`${BACKEND_URL}${item.is_self_sponsored ? item.profile_pic : item.enroll_profile_pic}`} alt={full_name} style={{width:'90%', height:'40px', borderRadius:'5px'}} /></View>
                                                        <View flex='70-line' type="text"><Link to={`/${school_title}/student/enrolled/${item.uid}`} className="link font-very-small">{full_name} </Link></View>
                                                    </Fragment>
                                                )
                                            })}
                                        </Mallet>  
                                        <div className="content align-center">
                                            <Link to={`/${school_title}/student/enrolled`} className="link font-very-small">View More</Link>
                                        </div> 
                                    </Section>
                                </GridContent>
                            </>
                            )
                        }
                    </Grid>
                    <Mash>
                        <>
                        {/* {!state.is_start_interview && (
                            <>
                                <Btn flex="50" align="left" color='blue' >Start Interview <i className='fas fa-hourglass-end'></i></Btn>
                                <Btn  flex="33" align="left" disabled={disableBtn} color='pink'>Reject <i className='fas fa-running'></i></Btn>
                            </>
                        )} */}
                        {btnn()}
                        </>
                        
                    </Mash>
                    {openImgViewer && (
                        <ImgViewer src={imgViewer.src} alt={imgViewer.alt} title={imgViewer.title} handleClose={() => setOpenImgViewer(false)}>
                            
                            <Img src={imgViewer.src} alt={imgViewer.alt} selected />
                                
                        </ImgViewer>
                    )}
                </ComponentWrapper>
            </>
            ) : (<><HeadComponent title='Error 404 - Page Not Found' /> <h1>Data Not Found</h1></>)}
        </>
        )}
    </>
    )
}

export default ViewEnrolledStudent;