import { View, Text } from "@react-pdf/renderer";
import { style } from "./style";

const TextInput = ({label="", borderColor="", color="", children}) => {
    return(
        <View style={{...style.wrapper, borderColor:borderColor ? borderColor : '#000' }}>
            <Text style={{...style.label, color:borderColor ? borderColor : '#000'}}>{label}</Text>
            <Text style={{...style.tarea, color:color ? color : '#000'}}>{children}</Text>
        </View>
    )
}

export default TextInput;