import React from "react";
import { Wrapper, Content } from "./style";

export const ChatUserUI = ({img_src="", img_alt="", is_typing=false, is_online=false, btn_icon="", btn_icon2="", is_btn_text=false, is_active=false, title="", desc="", number_message=0, is_init_status=true, is_disable_btn_click=false, onClick, onClickBtn, onClickBtn2}) => {
    return(
        <Content onClick={onClick} className={is_active ? 'app-bg-color active' : ''}>
            <div className="disp-block img-cont">
                <img className="img" src={img_src} alt={img_alt} />
                {is_init_status && (
                    <div className="stat-cont">
                        <span className={`${is_online ? 'online':'offline'}`}></span>
                    </div>
                )}
                
            </div>
            <div className="disp-block text-cont">
                <span className={`${is_btn_text ? 'font-very-small' : 'font-slightly-small'} fw-600`} style={{color:is_active ? '#eee' : 'var(--appBgColor)', letterSpacing:'.4px'}}>{title}</span> {is_typing && <> - <span className="font-super-small green" style={{paddingLeft:'3px'}}>Typing ...</span></>}
                <div className="disp-block">
                    <span className="font-super-small fw-500" style={{color:is_active ? '#999' : '#666', letterSpacing:'.4px'}}>{desc}</span>
                </div>
                {/* {is_typing && (
                    <div className="disp-block align-right" style={{paddingLeft:'6px'}}>
                        <span className="font-super-small green">Typing ...</span>
                    </div>
                )} */}
            </div>
            <div className="disp-block align-center num-cont">
                {btn_icon && (
                    <>
                    {!is_disable_btn_click ? <span className={`font-small hover-opacity ${btn_icon}`} onClick={onClickBtn}></span> : <span className={`font-slightly-small ${btn_icon} disabled`}></span>}
                    </>
                )}
                {btn_icon2 && (
                    <div className="disp-block" style={{paddingTop:'4px'}}>
                        <span className={`font-small hover-opacity ${btn_icon2}`}  onClick={onClickBtn2}></span>
                    </div>
                )}
                {number_message > 0 && (
                    <span className="font-very-super-small num fw-600">{number_message}</span>
                )}
                
            </div>
        </Content>
    )
}

const ChatUI = ({children, classname="app-theme"}) => {
    return(
        <Wrapper className={classname}>
            {children}
        </Wrapper>
    )
}

export default ChatUI;