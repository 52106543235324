import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    /* align-items: center;
    justify-content: center; */
    /* position: relative; */
    /* padding: 10px; */
    .button{
        width: 50px;
        height: 18px;
        background-color: #d2d2d2;
        border-radius: 200px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;

        &::before{
        position: absolute;
        content: "";
        width: 18px;
        height: 18px;
        background-color: #111;
        border-radius: 200px;
        /* margin-left: -50px; */
        /* margin-right: 40px; */
        transition: 0.2s;
        }
    }
    input:checked + .button{
    background-color: blue;
    }
    input:checked + .button::before{
        transform: translateX(35px);
        animation-name: toggle;
        animation-duration: 0.8s;
        transition-delay: 0.2s;
        
    }
    input{
        display: none;
    }

    @keyframes toggle{
        0%{
            width: 30px;
            height: 15px;
        }
        25%{
            width: 23px;
            height: 18px;
        }
        50%{
            margin: 1px;
            width: 48px;
            height: 15px;
        }
        75%{
            margin: 0px;
            width: 18px;
            height: 15px;
        }
        100%{
            width: 18px;
            height: 18px;
        }
    }



    /* .button{
        width: 80px;
        height: 30px;
        background-color: #d2d2d2;
        border-radius: 200px;
        cursor: pointer;
        position: relative;
        transition: 0.2s;

        &::before{
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background-color: #000;
        border-radius: 200px;
   
        transition: 0.2s;
        }
    }
    input:checked + .button{
    background-color: blue;
    }
    input:checked + .button::before{
        transform: translateX(50px);
        animation-name: toggle;
        animation-duration: 0.8s;
        transition-delay: 0.2s;
        
    }
    input{
        display: none;
    }

    @keyframes toggle{
        0%{
            width: 25px;
            height: 30px;
        }
        25%{
      
            width: 40px;
            height: 20px;
        }
        50%{
            margin: 2px;
            width: 30px;
            height: 20px;
        }
        75%{
            margin: 4px;
            width: 20px;
            height: 25px;
        }
        100%{
            width: 30px;
            height: 30px;
        }
    } */

`;


// import styled from "styled-components";

// export const Wrapper = styled.div`
//     background-color: #f1eaff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     .button{
//         width: 200px;
//         height: 100px;
//         background-color: #d2d2d2;
//         border-radius: 200px;
//         cursor: pointer;
//         position: relative;
//         transition: 0.2s;

//         &::before{
//         position: absolute;
//         content: "";
//         width: 90px;
//         height: 90px;
//         background-color: #fff;
//         border-radius: 200px;
//         margin: 5px;
//         transition: 0.2s;
//         }
//     }
//     input:checked + .button{
//     background-color: blue;
//     }
//     input:checked + .button::before{
//         transform: translateX(100px);
//         animation-name: toggle;
//         animation-duration: 0.8s;
//         transition-delay: 0.2s;
        
//     }
//     input{
//         display: none;
//     }

//     @keyframes toggle{
//         0%{
//             width: 90px;
//             height: 90px;
//         }
//         25%{
//             margin: 10px;
//             width: 30px;
//             height: 80px;
//         }
//         50%{
//             margin: 20px;
//             width: 100px;
//             height: 60px;
//         }
//         75%{
//             margin: 5px;
//             width: 70px;
//             height: 90px;
//         }
//         100%{
//             width: 90px;
//             height: 90px;
//         }
//     }

// `;