import {StyleSheet} from "@react-pdf/renderer";

export const pdfStyle = StyleSheet.create({
    page:{
        display: 'block',
        width: '100%',
        fontFamily: 'Raleway'
    },
    cont_voskool_logo:{
        position: 'absolute',
        top:'50%',
        left:'35%',
        transform: 'rotate(315deg)',
        zIndex:0,
    },
    voskool_logo:{
        width:'170px',
        height: '50px',
        opacity: .22,
    },
    heading_container: {
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'space-between',
        marginTop: '2px',
        marginBottom: '2px',
    },
    heading_logo:{
        width:'60px',

        height: '50px'
    },
    heading_text:{
        width:'90%',
        fontWeight:700,
        fontSize:'19px',
        paddingRight:'10px',
        textTransform:'capitalize'
    },
    pagination:{
        position:'absolute',
        bottom:0,
        left: 0,
        right:0,
        textAlign:'center',
        fontSize:'8px',
        fontWeight:600
    }, 
})