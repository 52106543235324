import styled from "styled-components";

export const Wrapper = styled.div`
    position: absolute;
    right: 50px;
    bottom: 100px;
    z-index: 1000000000000;
`;

export const Content = styled.div`
    position: relative;
    .num-cont{
        position: absolute;
        bottom: -10px;
        .num{
            padding: 2px 6px;
            background: rgb(245, 23, 43);
            color: #eee;
            border-radius: 50%;
        }
    }
    i{
        padding: 3px 7px;
        cursor: pointer;
        border-radius: 50px;
    }
`;