import React from "react";
import { Wrapper } from "./style";

const PanelDBWrapper = ({children, classname=""}) => {
    return(
        <Wrapper className={classname}>
            {children}
        </Wrapper>
    )
}

export default PanelDBWrapper;