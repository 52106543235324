import styled from 'styled-components';

export const HomeWrapper = styled.div`
    margin-top: 70px;
    width: 100%;
`;
export const SchoolWebHomeWrapper = styled.div`
    margin-top: 30px;
    width: 100%;
`;
export const Container = styled.div`
    margin: 5px 15px;
    @media (max-width: 375px) {
        margin: 5px 10px;
    }
`;
export const GridWrapper = styled.div`
    background: var(--appTheme);
    border-radius: 15px;
    transition: .5s ease;
    width: 100%;
    /* padding: 10px; */
    box-shadow: 0 0 3px 5px rgba(0,0,0,0.2);
    .cont-school-web {

        .img-cont{
            position: relative;
            transition: .5s ease;
            img{
                width: 100%;
                height: 200px;
                object-fit: cover;
                transition: .5s ease;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
            div.img-cont{
                width: 100%;
                height: 200px;
                background: #333;
            }
            .overlay{
                position: absolute;
                width: 100%;
                bottom: 26px;
                height: 10px;
                .overlay-inner-cont{
                    height: 35px;
                    background: #000000;
                    opacity: .23;
                    display: block;
                    position: relative;
                }
                .text-cont{
                    position: absolute;
                    top:5px;
                    left: 8px;
                    span{
                        color: #ccc;
                    }
                }
            }
        }
        .img-cont:hover img{
            object-fit: none;
        }
        .anim-arrow{
            animation: anim_enroll 3s ease infinite;
            animation-delay: 1s;

            @keyframes anim_enroll{
                0%{
                    transform: rotate(0deg);
                }
                50%{
                    transform: rotate(180deg);
                }
                100%{
                    transform: rotate(360deg);
                }
            }
        }
        .content{
            padding: 8px;
            .class-link-cont .dis-none{
                display: none;
            }
            .class-link-cont .link-cont a{
                color: #eee;
            }
            .class-link-cont .link-cont a:hover{
                background: none;
                color: var(--appTextColor);
                border: 1px solid #ff2400;
            }
        }
    }
`;
export const HomeContent = styled.div`    
    display: flex;
    -webkit-display: flex;
    -moz-display: flexbox;
    -ms-display: flex;
    flex-wrap : wrap;
    padding: 30px 10px;
    border-radius: 10px;
    @media (max-width: 375px) {
        padding: 20px 10px;
    }
    .align-all-center{
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;
        justify-self: center;
    }
    .flex-33-p{
        flex: 0 0 33%;
        max-width: 33%;

        @media(max-width: 780px){
            flex: 0 0 50%;
            max-width: 50%;
        }
    } 
    .mission{
        ul{
            list-style-type: square;
        }
        li{
            padding: 5px;
        }
        
    }
    .img-header{
        width: 90%;
        height: 300px;
        border-radius: 5px;
        
        @media (max-width: 780px){
            width: 80%;
            height: 250px;
        }
        @media (max-width: 420px){
            width: 100%;
            height: 220px;
        }

    }
    .flex-70{
        flex: 0 0 70%;
        max-width: 70%;
        padding: 10px 20px;
        
        @media (max-width: 780px){
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media (max-width: 375px) {
            padding: 5px;
        }

        .home-img-cont{
            position: relative;
            .overlay-cont{
                background-color: rgba(0,0,0,0.2);
                position: absolute;
                width: 100%;
                height: 70vh;
                border-radius: 20px;
                z-index: 1;
                animation: anim_img 1s ease;
                --webkit-animation: anim_img 1s ease;
                @keyframes anim_img {
                    from{
                        opacity: 0;
                    }
                    to{
                        opacity: 1;
                    }
                }
                @media (max-width: 540px){
                    height: 58vh;
                }
                @media (max-width: 280px){
                    height: 70vh;
                }
                .overlay{
                    margin-top: 20vh;
                    padding: 20px 45px;
                    animation: anim_text 2.5s ease;
                    --webkit-animation: anim_text 2.5s ease;
                    @keyframes anim_text {
                        from{
                            transform: translateX(-40px);
                        }
                        to{
                            transform: translateX(0px);
                        }
                    }

                    .heading{
                        padding-left: 3px;
                        position: relative;

                        .block{
                            position: absolute;
                            margin-top: 1px;
                            width: 14px;
                            height: 44px;
                            background-color: var(--voskoolBagColor);
                            border: 3px solid var(--assignedColor);
                            border-radius: 5px;
                            z-index: 1;
                        }
                        h1{
                            padding-left: 25px;
                            font-size: var(--fontSuperBig);
                            color: var(--assignedColor);
                            text-transform: capitalize;
                        }
                    }
                    .text-container{
                        padding-left: 30px;

                        p{
                            letter-spacing: .5px;
                            color: var(--assignedColor);
                            font-size: .9rem;
                        }
                        .link-cont{
                            text-align: right;
                            padding: 12px;
                            a{
                                text-decoration: none;
                                text-align: right;
                                font-size: .9rem;
                                text-transform: uppercase;
                                color: var(--lightGrey);
                                border: 1px solid #fff;
                                border-radius: 10px;
                                font-weight: 500;
                                padding: 10px;
                                background: var(--voskoolBagColor);
                                box-shadow: 5px 3px 3px 3px rgba(0,0,0,0.4);
                                transition: .4s ease;
                                -webkit-transition: .4s ease;
                                -moz-transition: .4s ease;
                                -ms-transition: .4s ease;
                            }
                            a:hover{
                                background: transparent;
                            }
                        }
                    }
                }
                @media (max-width: 1280px) {
                    .overlay{
                    margin-top: 18%;
                    @media (max-width: 780px){
                        
                    }
                    @media (max-width: 540px){
                        
                        padding: 10px 25px;
                    }

                    @media (max-width: 375px) {
                       
                        padding: 10px 25px;
                    }
                    .heading{
                        padding-left: 3px;
                        

                        .block{
                            width: 14px;
                            height: 28px;
                        }
                        @media (max-width: 540px){
                            .block{
                                width: 12px;
                                height: 22px;
                            }
                        }
                        
                        h1{
                            padding-left: 25px;
                            font-size: var(--fontBig);
                        }
                        @media (max-width: 540px){
                            h1{
                                font-size: var(--fontMed);
                            }
                        }
                    }
                    .text-container{
                        padding-left: 30px;

                        p{
                            font-size: .8rem;
                        }
                        @media (max-width: 540px){
                            p{
                            font-size: .7rem;
                             }
                            .link-cont{
                                padding: 10px;
                                a{
                                    font-size: .7rem;
                                }
                            }
                        }
                        @media (max-width: 375px) {
                            p{
                            font-size: .6rem;
                             }
                            .link-cont{
                                padding: 10px;
                                a{
                                    font-size: .6rem;
                                }
                            }
                        }
                    }
                }
                }
            }
            img{
                width: 100%;
                height: 70vh;
                border-radius: 20px;
                object-position: unset;
                transition: .4s ease;
                -webkit-transition: .4s ease;
                -moz-transition: .4s ease;
                -ms-transition: .4s ease;
                animation: anim_img 1s ease;
                --webkit-animation: anim_img 1s ease;
                @keyframes anim_img {
                    from{
                        opacity: 0;
                    }
                    to{
                        opacity: 1;
                    }
                }

                @media (max-width: 540px){
                    height: 58vh;
                }
                @media (max-width: 280px){
                    height:70vh;
                }
            }
        }

        .rounded-icon-cont{
            position: relative;
            margin-top: 5px;
            .rounded-icon{
                text-align: center;
                .circle{
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    display: inline-block;
                    background-color: #aaa;
                    border: 2px solid #aaa;
                    margin: 0 2px;
                }
                .circle.active{
                    border: 2px solid var(--voskoolBagColor);
                }
            }
        }
    }
    .flex-30{
        flex: 0 0 30%;
        max-width: 30%;
        @media (max-width: 780px) {
            flex: 0 0 100%;
             max-width: 100%;
        }

        .rounded-cont{
            padding: 10px 20px;
            margin-top: 25px;
            box-sizing: border-box;
            box-shadow: 0px 0px 3px 4px rgba(0,0,0,0.1);
            border-radius: 20px;
            .heading{
                padding-left: 1px;
                position: relative;
                .icon{
                    position: absolute;
                    z-index: 1;

                    i{
                        font-size: var(--fontBig);
                        color: #eda800;
                    }
                }
                h3{
                    padding-left: 25px;
                    font-size: 1.3rem;
                    color: var(--unAssignedColor);

                    @media (max-width: 1280px){
                        font-size: 1rem;
                        font-weight: 800;
                    }
                    @media (max-width: 280px){
                        font-size: .9rem;
                        font-weight: 800;
                    }
                }
                
            }
        }
    }

`;