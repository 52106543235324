import styled from "styled-components";

export const Wrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    -webkit-display: flex;
    -ms-display: flexbox;
    justify-content: space-between;
    align-items: center;
    

    .school-logo{
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 60px;
            height: 60px;
            display: inline-block;

            @media(max-width: 780px){
                width: 40px;
                height: 40px;
            }
            @media(max-width: 280px){
                width: 30px;
                height: 30px;
            }
        }
        .header{
            display: inline-block;
            padding-left: 9px;
            font-weight: 700;
            font-style: italic;
            color: var(--appBgColor);
            text-transform: uppercase;
        }
    }
    .others{
        i{
            display: inline-block;
            position: relative;
            margin-left: 40px;
            padding: 18px;
            cursor: pointer;
            @media(max-width: 780px){
                margin-left: 20px;
            }
            @media(max-width: 540px){
                margin-left: 10px;
                padding: 12px;
            }
            @media(max-width: 400px){
                margin-left: 3px;  
            }
            @media(max-width: 375px){
                margin-left: 0px;  
            }
            @media(max-width: 320px){
                padding: 10px; 
                margin-left: 2px; 
            }
        }
        .norm{
            transition: .3s ease;
            border: 1px solid transparent;
            border-radius: 5px;
            :hover{
                background: var(--appBgTextColor);
                border: 1px solid var(--appBgColor);
            }
        }

        .cont{
            margin-top: 25px;
            padding: 0 5px;

            .inner-cont{
                a{
                    text-decoration: none;
                }
                .content{
                    display: flex;
                    justify-content: stretch;
                    width: 100%;
                    padding: 5px;
                    border-radius: 5px;
                    cursor: default;
                    transition: .3s ease;
                    border-bottom: 1px solid var(--appBgSecTextColor);
                    &:hover{
                        transform: scale(0.9);
                        border-bottom: 1px solid var(--appBgColor);
                    }
                    .aside{
                        margin: 0 5px;

                        span.icon{

                        }
                        span.text{
                            font-weight: 500;
                            text-transform: capitalize;
                            color: var(--appNavTextColor);
                        }
                        img.icon{
                            width: 20px;
                            height: 20px;
                            @media(max-width: 780px){
                                margin-top: 3px;
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
                .header{
                    margin:6px 0;

                    .heading{
                        font-weight: 700;
                        color: var(--appTextColor);
                    }
                }
            }
        }
    }
`;