import React, { useState, useEffect, useMemo, Fragment } from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import CountDownTimer from 'components/app/school/SmallerComponents/countDownTimer';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import { HeadComponent } from "components/head";
import { appServices5, appServices4 } from 'api/schoolApp/services';

import { addItemToArray, addItemToArrayAndInnerArray, addItemToArrayAndInnerArrays} from 'components/app/school/actions/array-utils/Add';
import { updateArray, updateArrayWithArray, updateinnerArray } from 'components/app/school/actions/array-utils/Update';
import { moveKeyLocInObject } from 'components/app/school/actions/array-utils/Move';
import { groupArray } from 'components/app/school/actions/array-utils/Group';
import { numberDigit } from 'components/app/school/actions/numberDigit';
import Note from 'components/app/school/SmallerComponents/NOTE';

const StaffScoresEntry = ({school_id, school, school_branch, user_id, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialMalletState = useMemo(() => ({curr_sess_id:0, curr_sess:'', scores_entry_end:'', curr_term:'', session:'', is_upload_exam_timetable:false, term:'', classes:'', class_thought:'', subject:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);
    
    const [malletState, setMalletState] = useState(initialMalletState);

    const [allScoresEtable, setAllScoresEtable] = useState([]);
    const [allClassesThought, setAllClassesThought] = useState([]);
    const [allSubjectThought, setAllSubjectThought] = useState([]);
    const [allStudent, setAllStudent] = useState([]);

    const [preloading, setPreloading] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(false);

    const [assignNum, setAssignNum] = useState(-1);
    const [assignNumId, setAssignNumId] = useState('');

    
    
    const fetchSchoolSession = (sid) => {
        if(sid){
            setError({data:[], title:""});
            appServices5.getCurrentSession(sid).then(res => {
                setMalletState({curr_sess:res.data.session, curr_sess_id:res.data.id, curr_term:res.data.term_name, scores_entry_end:res.data.scores_entry_end, is_upload_exam_timetable:res.data.is_upload_exam_timetable, classes:'', class_thought:'', subject:''});
                setDataTableLoading(true);
            }).catch(e => {
                setMalletState({id:0, session:'', term:''});
                setError({title:'School Session Data Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }

    const fetchAllScores = (sid) => {
        if(sid){
            setError({data:[], title:""});
            appServices5.getAllSchoolScores(sid).then(res => {
                setAllScoresEtable(addItemToArray(res.data, {is_clicked:false}))
            }).catch(e => {
                setError({title:'School Scores Data Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllClassesThought = (stid, sid) => {
        if(stid && sid){
            setError({data:[], title:""});
            appServices4.getAllClassesThought(stid, sid).then(res => {
                setAllClassesThought(groupArray(res.data, 'classes'));
                setPreloading(false);
            }).catch(e => {
                setAllClassesThought([]);
                setError({title:'Class Data Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllSubjectThought = (stid, csid) => {
        if(stid){
            setError({data:[], title:""});
            appServices4.getAllSubjectThought(stid, csid).then(res => {
                setAllSubjectThought(res.data);
            }).catch(e => {
                setAllSubjectThought([]);
                setError({title:'Subject Data Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
   
    const fetchAllStudScoresTemp = (subid, sid, sbid, cid) => {
        if(subid && cid && sid && sbid){
            setError({data:[], title:""});
            setSpinLoading(true);
            appServices4.getAllSchoolMainStudentSubjectScoresEntry(subid, sid, sbid, cid).then(res => {
                setSpinLoading(false);
                let arr = addItemToArrayAndInnerArrays(moveKeyLocInObject(res.data, 'subject_offering/scores_temp', 'scores_temp', true), ['scores_temp', 'scores'], [{is_main:true, is_clicked:false, mark_value:'', is_absent_value:false}, {is_main:true, is_clicked:false, mark_value:''}], {is_iter:false, is_exam_score_iter:false, is_main:true, is_temp:false})
                setAllStudent(arr);
            }).catch(e => {
                appServices4.getAllSchoolTempStudentSubjectScoresEntry(subid, sid, sbid, cid).then(res => {
                    setSpinLoading(false);
                    setAllStudent(addItemToArrayAndInnerArray(res.data, 'scores_temp', {is_main:true, is_clicked:false, mark_value:'', is_absent_value:false}, {is_iter:false, is_main:false, is_temp:true}));
                }).catch(e => {
                    setAllStudent([]);
                    setSpinLoading(false);
                    setError({title:'Student Scores Data Fetch Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
                })
            })
            return;
        }
        setAllStudent([]);
    }
    const fetchStudScoresMain = (subid, pk, sid, sbid, num=-1) => {
        if(subid && pk && sid && sbid){
            setLoading(true);
            appServices4.getSchoolMainStudentSubjectScoresEntry(subid, pk, sid, sbid).then(res => {
                setAllStudent((prev) => prev.length > 0 ? updateArray(prev, num, {...prev[num], ...res.data, scores:addItemToArray(res.data.scores, {is_main:true, is_clicked:false, is_exam_score_iter:true, mark_value:res.data.mark}), num:num}) : [...prev]);
                setLoading(false);
            }).catch(e => {
                setAllStudent((prev) => [...prev]);
                setLoading(false);
            })
        }
    }

    const handleMalletChange = (e) => {
        if(e.target.name === "class_thought" && e.target.value){
            const class_item = allClassesThought.filter((item) => item.id===parseInt(e.target.value))[0]
            fetchAllSubjectThought(access.uid, class_item.classes__class_section_school);
            setMalletState({...malletState, [e.target.name]:e.target.value, classes:class_item.classes});
            setDataTableLoading(true);
            return;
        }
        if(e.target.name === "class_thought" && !e.target.value){
            setAllSubjectThought([]);
            setMalletState({...malletState, [e.target.name]:e.target.value, classes:'', subject:''});
            setDataTableLoading(true);
            return;
        }
        setMalletState({...malletState, [e.target.name]:e.target.value});
        setDataTableLoading(true);
    }
    const handleAddScoresChange = (num=0, num_j=0, item={}, is_iter=false, iter_arr=[]) => {
        if(!is_iter && iter_arr.length > 0){
            const upd_arr = updateArray(allStudent, num, {...allStudent[num], is_iter:true, scores_temp:iter_arr});
            const upd_inner_arr = updateinnerArray(upd_arr, num, 'scores_temp', num_j, {...item});
            setAllStudent(upd_inner_arr);
            return;
        }
        setAllStudent(updateinnerArray(allStudent, num, 'scores_temp', num_j, {...item}))
    }
    const handleAddExamScoresChange = (num=0, num_j=0, item={}, is_iter=false, iter_arr=[]) => {
        if(!is_iter && iter_arr.length > 0){
            const upd_arr = updateArray(allStudent, num, {...allStudent[num], is_exam_score_iter:true, scores:iter_arr});
            const upd_inner_arr = updateinnerArray(upd_arr, num, 'scores', num_j, {...item});
            setAllStudent(upd_inner_arr);
            return;
        }
        setAllStudent(updateinnerArray(allStudent, num, 'scores', num_j, {...item}))
    }
    const handleSubmitScore = (e,  item={}, num, inner_num, type="") => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        if(type === "temp"){
            appServices4.insertSchoolStudentScoreEntryTemp({...item, sid:school_id}, malletState.subject).then(res => {
                setLoading(false);
                setAllStudent(updateinnerArray(allStudent, num, 'scores_temp', inner_num, {...res.data, num:inner_num, is_main:true, is_clicked:false, mark_value:res.data.mark, is_absent_value:res.data.is_absent}))
                setSuccess({title:'Success', text:'Data Saved Successfully'});
                if(allStudent[num].is_main){
                    setAssignNum(num);
                    setAssignNumId(allStudent[num].uid);
                }
            }).catch(e => {
                setLoading(false);
                setError({title:'Insert Data Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            return;
        }
        if(type === "temp_update"){
            appServices4.updateSchoolTempStudentScoresEntry(malletState.subject, item.uid, school_id, school_branch.id, {...item}).then(res => {
                setLoading(false);
                setAllStudent(updateinnerArray(allStudent, num, 'scores_temp', inner_num, {...res.data, num:inner_num, is_main:true, is_clicked:false, mark_value:res.data.mark, is_absent_value:res.data.is_absent}))
                setSuccess({title:'Success', text:'Data Updated Successfully'});
                if(allStudent[num].is_main){
                    setAssignNum(num);
                    setAssignNumId(allStudent[num].uid);
                }
            }).catch(e => {
                setLoading(false);
                setError({title:'Update Data Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            return;
        }
        if(type === "main"){
            appServices4.insertSchoolStudentScoreEntryMain({...item, sid:school_id}, malletState.subject).then(res => {
                setLoading(false);
                setAllStudent(updateArray(allStudent, num, {...allStudent[num], ...res.data, scores:addItemToArray(res.data.scores, {is_main:true, is_clicked:false, is_exam_score_iter:true, mark_value:res.data.mark}), num:num}))
                setSuccess({title:'Success', text:'Data Saved Successfully'});
            }).catch(e => {
                setLoading(false);
                setError({title:'Insert Data Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            return;
        }
        if(type === "main_update"){
            appServices4.updateSchoolMainStudentScoresEntry(malletState.subject, item.uid, school_id, school_branch.id, {...item}).then(res => {
                setLoading(false);
                setAllStudent(updateArray(allStudent, num, {...allStudent[num], ...res.data, scores:addItemToArray(res.data.scores, {is_main:true, is_clicked:false, is_exam_score_iter:true, mark_value:res.data.mark}), num:num}))
                setSuccess({title:'Success', text:'Data Updated Successfully'});
            }).catch(e => {
                setLoading(false);
                setError({title:'Update Data Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            return;
        }
    }

    useEffect(() => {
        if(reload && access.uid){
            fetchSchoolSession(school_id);
            fetchAllScores(school_id);
            fetchAllClassesThought(access.uid, school_id);
            setReload(false);
        }
    }, [school_id, access.uid, reload]);

    useEffect(() => {
        if(dataTableLoading){
            setDataTableLoading(false);
            fetchAllStudScoresTemp(malletState.subject, school_id, school_branch.id, malletState.classes)
        }
        if(assignNum > -1 && assignNumId){
            fetchStudScoresMain(malletState.subject, assignNumId, school_id, school_branch.id, assignNum);
            setAssignNum(-1);
            setAssignNumId('');
        }
    }, [school_id, school_branch.id, dataTableLoading, malletState, assignNum, assignNumId])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const allClasssThoughtOption = allClassesThought.map((item, i) => (
        <option key={i} value={item.id}>{item.classes__name}</option>
    ))
    const allSubjectThoughtOption = allSubjectThought.map((item, i) => (
        <option key={i} value={item.subject}>{item.subject_name}</option>
    ))
    const unattendedStudents = allStudent.filter((item) =>  item.is_main === true).filter((item) =>  (item.is_present === true  && item.scores.length === 0) ||(item.is_present === true  && item.scores.length === 1 && !item.scores[0].score_type) || (item.is_present === false && item.scores_temp.length === 0) || (item.is_present === false && item.scores_temp.length > 0 && !item.scores_temp[0].score_type))
    const insertScoreEntryData = (type="temp") => {
        const all_scores = !allStudent[0].is_main ? allScoresEtable.filter((it) => it.short_name.toLowerCase() !== "exam") : allScoresEtable;
        const stud_name_width = !allStudent[0].is_main ? (100 - (all_scores.length * 10) + 10) : (100 - ((all_scores.length + 2) * 5) + 10);
        const class_item = malletState.class_thought ? allClassesThought.filter((item) => item.id === parseInt(malletState.class_thought))[0] : {classes__name:''};
        const subject_item = malletState.subject ? allSubjectThought.filter((item) => item.subject === parseInt(malletState.subject))[0] : {subject_name:''};
        return (
            <>
            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med text-upper'> {subject_item.subject_name} ({class_item.classes__name}) Scores Entry</span></div>
            {unattendedStudents.length > 0 && <div className='flex-100 padd-4px align-right' style={{marginBottom:'4px'}}><span className='red fw-700 font-slightly-small'>{unattendedStudents.length} Student{unattendedStudents.length > 1 ? 's' : ''} Remaining</span> </div>}
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH style={{padding:'5px', width:`${stud_name_width}%`}}><span className="font-slightly-small">Student's Adm No - Name</span></TH>
                            {all_scores.map((item_j, j) => (
                                <Fragment key={j}>
                                    <TH style={{padding:'5px', width:!allStudent[0].is_main ? '10%' : '5%'}}><span className="font-slightly-small">{item_j.short_name}</span></TH>
                                </Fragment>
                            ))}
                            {allStudent[0].is_main && (
                                <>
                                    <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">TOT</span></TH>
                                    <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">GRD</span></TH>
                                </>
                            )}
                        </TR>
                    </thead>
                    <tbody>
                    {allStudent.map((item, i) => {
                        const all_scores = allScoresEtable.filter((it) => it.short_name.toLowerCase() !== "exam");
                        const full_name = item.is_main ? (item.subject_offering.user_last_name ? `${item.subject_offering.user_last_name} ${item.subject_offering.user_first_name}` : `${item.subject_offering.last_name} ${item.subject_offering.first_name}`) : (item.user_last_name ? `${item.user_last_name} ${item.user_first_name}` : `${item.last_name} ${item.first_name}`);
                        const scores = item.is_iter ? item.scores_temp : addItemToArray(updateArrayWithArray(all_scores, item.scores_temp, 'id', 'score_type'))
                        const exam_score = item.is_main && (item.scores.length === 1 ? item.scores : (item.is_exam_score_iter ? item.scores : addItemToArray(allScoresEtable.filter((it) => it.short_name.toLowerCase() === "exam"), {is_clicked:false, is_main:false, mark_value:''})));
                        const insertScores = (t="") => {
                            if(t === "temp"){
                                return allScoresEtable.filter((it) => it.short_name.toLowerCase() !== "exam").map((item_k, j) => {
                                    const arr_j = scores;
                                    let item_j = scores.filter((it) => it.score_type === item_k.id || it.short_name === item_k.short_name)[0]
                                    const form_data ={score_type:item_j.id, mark:!item_j.is_absent_value ? parseFloat(item_j.mark_value) : 0, is_absent:item_j.is_absent_value, upload_date: new Date((new Date().getTime()) * 1000 * 60 * 60 * 12), created_by:access.id, subject_offering:item.is_main ? item.subject_offering.id : item.id}
                                    const form_data_upd ={uid:item_j.uid, mark:!item_j.is_absent_value ? parseFloat(item_j.mark_value) : 0, is_absent:item_j.is_absent_value, updated_by:access.id}
                                    const addScoresPHolder = (t="", submit_t="temp") => {
                                        return(
                                            <form onSubmit={e => handleSubmitScore(e, t ==="edit" ? form_data_upd : form_data, item.num, item_j.num, submit_t)} className="disp-block align-center app-opp-theme br-10 flex-90">
                                                <input type="number" name="mark_value" step={'0.01'} min={0} onChange={(e) => handleAddScoresChange(item.num, item_j.num, {...item_j, [e.target.name]:e.target.value}, item.is_iter, arr_j)} placeholder='Mark...' disabled={item_j.is_absent_value} required={!item_j.is_absent_value} className='app-text-color font-very-small padd-3px' style={{outline:'none', maxWidth:'70px', margin:'2px 4px', padding:'2px'}} value={item_j.mark_value} /><br />
                                                <input type="checkbox" name="is_absent_value" onChange={() => handleAddScoresChange(item.num, item_j.num, {...item_j, is_absent_value:!item_j.is_absent_value, mark_value: !item_j.is_absent_value ? '' : item_j.mark_value}, item.is_iter, arr_j)} className='font-super-small'  value={item_j.is_absent_value} checked={item_j.is_absent_value} /> <span className="font-super-small app-text-sec-color">Is Absent?</span><br />
                                                
                                                <button type="submit" className='font-very-small btn app-bg-color br hover-opacity app-bg-text-color' title={t === "edit" ? 'Update' : 'Save'}>{t === "edit" ? <i className="fas fa-paper-plane"></i> : 'Save'}</button>
                                            </form>
                                        )
                                    }
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                {!item_j.is_main ? (<><div className='disp-flex wrap'><div className="flex-10 "><button type="button" onClick={() => handleAddScoresChange(item.num, item_j.num, {...item_j, is_clicked:!item_j.is_clicked}, item.is_iter, arr_j)} className=' btn br-circle app-bg-color hover-opacity' style={{padding:'1px 3px'}}><i className={`fas fa-angle-${item_j.is_clicked ? 'left' : 'right'} app-bg-text-color font-very-small`}></i></button></div>{item_j.is_clicked && addScoresPHolder()}</div> </>) : (
                                                    <>
                                                        <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{item_j.is_absent ? 'ABS' : parseFloat(item_j.mark)}</span>
                                                        {new Date(item_j.upload_date).getTime() > new Date().getTime() && <span className={`disp-block align-center blue font-very-small fas fa-angle-${item_j.is_clicked ? 'up' : 'down'}`} onClick={() => handleAddScoresChange(item.num, item_j.num, {...item_j, is_clicked:!item_j.is_clicked, mark_value:item_j.mark, is_absent_value:item_j.is_absent}, item.is_iter, arr_j)}></span>}
                                                        {new Date(item_j.upload_date).getTime() > new Date().getTime() && item_j.is_clicked && addScoresPHolder('edit', 'temp_update')}
                                                    </>
                                                )}
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                        }
                        const insertExamScore = () => {
                            return exam_score.map((item_j, j) => {
                                const arr_j = exam_score;
                                const form_data ={score_type:item_j.id, mark:parseInt(item_j.mark_value), created_by:access.id, scores_rank:item.id}
                                const form_data_upd ={uid:item_j.uid, mark:parseInt(item_j.mark_value), updated_by:access.id}
                                const addScoresPHolder = (t="", submit_t="temp") => {
                                    return(
                                        <form onSubmit={e => handleSubmitScore(e, t ==="edit" ? form_data_upd : form_data, item.num, item_j.num, submit_t)} className="disp-block align-center app-opp-theme br-10 flex-90">
                                            <input type="number" name="mark_value" step={'0.01'} min={0} onChange={(e) => handleAddExamScoresChange(item.num, item_j.num, {...item_j, [e.target.name]:e.target.value}, item.is_exam_score_iter, arr_j)} placeholder='Mark...' disabled={item_j.is_present} required={!item_j.is_present} className='app-text-color font-very-small padd-3px' style={{outline:'none', maxWidth:'70px', margin:'2px 4px', padding:'2px'}} value={item_j.mark_value} /><br />
                                            <input type="checkbox" name="is_absent_value" disabled  className='font-super-small'  defaultChecked={item_j.is_present} /> <span className="font-super-small app-text-sec-color">Is Absent?</span><br />
                                            {!item_j.is_present && <button type="submit" className='font-very-small btn app-bg-color br hover-opacity app-bg-text-color' title={t === "edit" ? 'Update' : 'Save'}>{t === "edit" ? <i className="fas fa-paper-plane"></i> : 'Save'}</button>}
                                        </form>
                                    )
                                }
                                return(
                                    <Fragment key={j}>
                                        <TD>
                                            {!item_j.is_main ? (<><div className='disp-flex wrap'><div className="flex-10 "><button type="button" onClick={() => handleAddExamScoresChange(item.num, item_j.num, {...item_j, is_clicked:!item_j.is_clicked}, item.is_exam_score_iter, arr_j)} className=' btn br-circle app-bg-color hover-opacity' style={{padding:'1px 3px'}}><i className={`fas fa-angle-${item_j.is_clicked ? 'left' : 'right'} app-bg-text-color font-very-small`}></i></button></div>{item_j.is_clicked && addScoresPHolder('', 'main')}</div> </>) : (
                                                <>
                                                    <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{parseFloat(item_j.mark)}</span>
                                                    {new Date(item_j.upload_date).getTime() > new Date().getTime() && <span className={`disp-block align-center blue font-very-small fas fa-angle-${item_j.is_clicked ? 'up' : 'down'}`} onClick={() => handleAddExamScoresChange(item.num, item_j.num, {...item_j, is_clicked:!item_j.is_clicked, mark_value:item_j.mark, is_absent_value:item_j.is_absent}, item.is_exam_score_iter, arr_j)}></span>}
                                                    {item.is_misconducted && <span className={`disp-block font-super-small fw-600  red app-text-color align-right`}>-{parseFloat(item.mark_pun)}</span>}
                                                    {new Date(item_j.upload_date).getTime() > new Date().getTime() && item_j.is_clicked && addScoresPHolder('edit', 'main_update')}
                                                </>
                                            )}
                                        </TD>
                                    </Fragment>
                                )
                            })
                        }
                        return(
                            <Fragment key={i}>
                                <TR>
                                    <TD align='center'><span className="font-slightly-small fw-600 app-text-color text-capitalize">{numberDigit(item.is_main ? item.subject_offering.adm_no : item.adm_no, 4)} - {full_name}</span></TD>
                                    {insertScores(type)}
                                    {item.is_main && (
                                        <>
                                        {item.is_present ? insertExamScore() : (<TD><span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>ABS</span></TD>)}
                                        <TD>{<span className={`disp-block font-slightly-small fw-700 app-text-color align-center`}>{parseFloat(item.tot_mark)}</span>}</TD>
                                        <TD>{<span className={`disp-block font-slightly-small fw-700 app-text-color align-center`}>{item.curr_grade}</span>}</TD>
                                        </>
                                    )}
                                </TR>    
                            </Fragment>
                        )
                    })}
                    </tbody>
                </TABLE>
            </div>
            </>
        )
    }
    const scores_entry_end_time = malletState.scores_entry_end === null ? (new Date().getTime()  + 100000) : (malletState.scores_entry_end ?  new Date(malletState.scores_entry_end).getTime() : 0)
    return(
        <>
            <HeadComponent title="Scores Entry For Student" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Section>
                    <Mallet>
                        <div className='flex-100'>
                            <label className='font-slightly-small'>Current Session: 
                            <select style={{cursor:'not-allowed'}} name="session" value={malletState.curr_sess_id} disabled className='form-control font-slightly-small' >
                                <option value={malletState.curr_sess_id}>{malletState.curr_sess} - {malletState.curr_term.toUpperCase()} TERM</option>
                            </select></label>
                        </div>
                    </Mallet>
                </Section>
                <Grid layout='lauto'> 
                    
                    <GridContent header_text={`Student Scores Entry`} header_icon="fas fa-align-left">
                        <Section>
                            {loading && (<Loader />)}
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                                </ul>) : 
                                (
                                <>
                                {scores_entry_end_time > new Date().getTime() ? (
                                    <>
                                    {malletState.scores_entry_end && <CountDownTimer type="date" end={malletState.scores_entry_end} title={`Scores Entry Countdown Timer`} />}
                                    <Note> Editing for scores added today ends by 12am</Note>
                                    
                                     <Mallet>
                                        <div className='flex-50-line'>
                                            <label className='font-slightly-small'>Select Class: 
                                            <select name="class_thought" value={malletState.class_thought} className='form-control-theme font-slightly-small' onChange={e => handleMalletChange(e)} >
                                                <option value={''}>Select Class</option>
                                                {allClasssThoughtOption}
                                            </select></label>
                                        </div>
                                        <div className='flex-50-line'>
                                            <label className='font-slightly-small'>Select Subject: 
                                            <select name="subject" value={malletState.subject} onChange={e => handleMalletChange(e)} className='form-control-theme font-slightly-small' >
                                                <option value={''}>Select Subject {allSubjectThought.length > 0 && '...'}</option>
                                                {allSubjectThoughtOption}
                                            </select></label>
                                        </div>
                                    </Mallet>
                                    
                                    <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                        {spinLoading && <Spinner />} 
                                        {allStudent.length > 0 ? insertScoreEntryData() : <div className='flex-100 align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}
                                    </div>  
                                    </>  
                                    ) : (
                                        <div className='flex-100 padd-4px align-center' style={{marginBottom:'4px'}}><span className='red fw-700 font-big'>Scores Entry Session Has Been Ended </span> </div>
                                    )}               
                                </>
                                )
                            }
                        </Section>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default StaffScoresEntry;