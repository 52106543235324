import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {Link} from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from 'components/app/school/SmallerComponents/Section';
import Note from 'components/app/school/SmallerComponents/NOTE';
import HeaderEditor from './component/header/editor';
import OnePageEditor from './component/onePage/editor';
//import PageByPage from './component/pageByPage';
import { SchoolWebHomeWrapper, HomeContent } from "components/app/school/embeddedApps/web/component/style";
import WebComponentGrid from 'components/app/school/embeddedApps/web/component/grid';
import ClassPlugin from 'components/app/school/embeddedApps/web/component/classPlugin';
import ClassPluginEditor from 'components/app/school/embeddedApps/web/component/classPlugin/editor';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { updateArray } from 'components/app/school/actions/array-utils/Update';
import { deleteItemFromArrayByNumKey } from 'components/app/school/actions/array-utils/Delete';
import { previewer } from "components/app/school/embeddedApps/web/action/previewer";
import { HeadComponent } from "components/head";
import { webAppServices, appServices } from 'api/schoolApp/services';
import { BACKEND_URL } from 'actions/url';
import Header, { HeaderDesc } from './component/header';
import { colorThemePallete } from "components/app/school/embeddedApps/web/action/colorManager";
import PageByPageEditor from './component/pageByPage/editor';
// import OnePage from './component/onePage';




const WebApp = ({school_id, user_id, user_type, school_title=''}) => {
    const initialArr = useMemo(() => ([{title:'home', type:'home', theme:'app-theme', is_general:true, is_plugin:false, is_original:true, is_checked:true,  desc:'Home page', plugin_type:''}, {title:'Begin your journey now!', type:'class', theme:'app-theme', is_checked:true, is_plugin:true, is_general:true, is_original:true, desc:'Choose a class to begin your journey with us now', plugin_type:'class'}, {type:'school location', is_checked:true, theme:'app-opp-theme', is_plugin:false, title:'Where you can find us', is_general:true, is_original:true, desc:'Check out our school locations', plugin_type:''}, {title:'Mission & Vision', theme:'', is_checked:true, is_plugin:false, type:'mission and vision', is_original:true, is_general:false, desc:'The mission and vision of our school', plugin_type:''}, 
    {title:'Health Care', theme:'', is_plugin:false, is_checked:false, type:'health', is_original:true, desc:'Your child is safe with us with our health care parastatal', is_general:false, plugin_type:''}, {title:'Commandments', theme:'', is_plugin:false, is_checked:false, type:'commandments', is_original:true, is_general:false, desc:'What our students must abide by to enable peaceful and lively coexistence', plugin_type:''}]), [])
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initConfig = useMemo(() => ({header_title:'', is_preface_under_change1:false, is_preface_change1:false, is_text_change1:false, is_preface_under_delete1:false, is_preface_delete1:false, is_text_delete1:false,
        is_preface_under_change2:false, is_preface_change2:false, is_text_change2:false, is_preface_under_delete2:false, is_preface_delete2:false, is_text_delete2:false,
        preface_value1:'', text_value1:'', preface_value2:'', text_value2:'', is_page_title_change:false, page_title:'', page_title_value:''
    }), []);
    // const initialState = useMemo(() => ({p:1, size:50, session:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [schoolPic, setSchoolPic] = useState({file:null, value:''});
    const [school, setSchool] = useState({});

    const [allClassSection, setAllClassSection] = useState([]);
    const [allClass, setAllClass] = useState([]);
    const [allWeb, setAllWeb] = useState([]);
    const [webConfig, setWebConfig] = useState(addItemToArray(initialArr, {school:school_id}));

    const [isAuto, setIsAuto] = useState(true);
    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [isPreview, setIsPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);


    const fetchSchool = (title="") => {
        setPreloading2(true);
        setError({data:[], title:''});
        appServices.getSchool(title).then(res => {
            setSchool(res.data);
            setPreloading2(false);
        }).catch(e => {
            setSchool({});
            setPreloading2(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
        })
    }

    const fetchAllClassSection = (sid) => {
        setError({data:[], title:''});
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSection(addItemToArray(res.data, {from_age_value:'', to_age_value:'', desc_value:'', img_value:null, img_value_value:'', is_add_image:false, is_age_change:false}));
            setPreloading2(false);
        }).catch(e => {
            setPreloading2(false);
            setAllClassSection([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Class Section Error'});
        })
    }
    const fetchAllClass = (sid) => {
        setError({data:[], title:''});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClass(addItemToArray(res.data));
            setPreloading2(false);
            setPreloading(false);
        }).catch(e => {
            setPreloading2(false);
            setPreloading(false);
            setAllClass([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Class Section Error'});
        })
    }
    const fetchAllWeb = (sid) => {
        webAppServices.getAllWeb(sid).then(res => {
            setAllWeb(addItemToArray(res.data));
        }).catch(e => {
            setAllWeb([]);
        })
    }
    const fetchAllWebLink = (sid, initConfig={}) => {
        setError({data:[], title:''});
        webAppServices.getAllWebLink(sid).then(res => {
            setWebConfig((prev) => res.data.length > 0 ? addItemToArray(res.data, {layout:'', ...initConfig}) : prev);
            setPreloading(false);
        }).catch(e => {
            setWebConfig([]);
            setPreloading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Fetch Error'});
        })
    }
    const validateImg = (pic=null, size=0) => {
        const ext = ['.jpg', 'jpeg', '.png']
        const end_name = pic.name.slice(-4);
        let stats = {success:true, detail:''};
        if(!ext.includes(end_name) && size > 0 && pic.size > size ){
            stats.success = false;
            stats.detail = "Invalid File Format";
        }
        else if(!ext.includes(end_name)){
            stats.success = false;
            stats.detail = "Invalid File Format";
        }
        else if(size > 0 && pic.size > size ){
            stats.success = false;
            stats.detail = `File size must be less than ${size}`;
        }
        return stats;
    }
    const onSubmitSchoolWeb = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        let picFormData = new FormData();
        picFormData.append('pic', schoolPic.file, schoolPic.file.name);
        let data = isAuto ? webConfig.filter((it) => it.is_general === true) : webConfig.filter((it) => it.is_checked === true);
            webAppServices.startAppService({arr:JSON.stringify(addItemToArray(data, {school:school_id}))}).then(res => {
                appServices.updateSchoolPic(school_id, picFormData).then(res => {
                    // setLoading(false);
                    window.location.reload();
                }).catch(e => {
                    setLoading(false);
                    setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
                })
            }).catch(e => {
                setLoading(false);
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
            })
        
    }
    const onSubmitText = (e, num=-1, web_item={}, name='', value='', toggler_name="", item={}) => {
        e.preventDefault();
        setError(initialErrorState);
        setLoading(true);
        const data = !name ? item : {[name]:value};
        webAppServices.updateWeb(web_item.uid, data).then(res => {
            setLoading(false);
            setAllWeb((prev) => {return updateArray(prev, web_item.num, {...prev[web_item.num],  ...res.data})});
            setWebConfig((prev) => {return updateArray(prev, num, {...prev[num], [toggler_name]:false})});
        }).catch(e => {
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
        })
    }
    const onSubmitClassPlugin = (e, num=-1, item={}, toggler_name="", type="") => {
        e.preventDefault();
        setError(initialErrorState);
        const it = allClassSection[num];
        setLoading(true);
        if(type === "file"){
            appServices.updateClassSectionPicSchool(it.id, item).then(res => {
                setLoading(false);
                setAllClassSection((prev) => {return updateArray(prev, num, {...it,  ...res.data, [toggler_name]:false})})
            }).catch(e => {
                setLoading(false);
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
            })
            return;
        }
        appServices.updateClassSectionSchool(it.id, item).then(res => {
            setLoading(false);
            setAllClassSection((prev) => {return updateArray(prev, num, {...it,  ...res.data, [toggler_name]:false})})
        }).catch(e => {
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:' Data Insert Error'});
        })
    }

    const onChangeConfig = (num=-1, is_checked=false) => {
        setWebConfig((prev) => {return updateArray(prev, num, {...prev[num], is_checked:is_checked})});
    }
    const onChangeLayout = (e, num=-1) => {
        setLoading(true);
        let name = e.target.name;
        let value = e.target.value;
        const web_link_id = webConfig[num].id;
        const check_web = allWeb.filter((it) => it.school === parseInt(web_link_id)) 
        if (check_web.length > 0) {
            const web = check_web[0];  
            let data = {is_split:parseInt(e.target.value) === 2 ? true : false, school:web_link_id, updated_by:user_id} 
            webAppServices.updateWeb(web.uid, data).then(res => {
                setLoading(false);
                setWebConfig(updateArray(webConfig, num, {...webConfig[num], [name]:value}));
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Insert Error'});
                setLoading(false);
            })
            return;
        }
        let data = {is_split:parseInt(value) === 2 ? true : false, school:web_link_id,  order:allWeb.length * 500, created_by:user_id} 
        webAppServices.insertWeb(data).then(res => {
            setLoading(false);
            setAllWeb((prev) => {return addItemToArray([...prev, res.data])});
            setWebConfig(updateArray(webConfig, num, {...webConfig[num], [name]:value}));
        }).catch(e => {
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Insert Error'});
            setLoading(false);
        })
    }
    const onChangeFile = (e) => {
        if(!e.target.value){
            setSchoolPic({file:null, value:''});
            return;
        }
        setError(initialErrorState);
        const file = e.target.files[0];
        const vald = validateImg(file);
        if(vald.success === true){
            setSchoolPic({file:file, value:e.target.value});
            return;
        }
        setSchoolPic({file:null, value:''});
        setError({title:'Form Error', data:[vald.detail]});
    }
    const onToggleConfig = (e, num=-1, name="", init_value="", value_name="") => {
        const name_split = name.split('_');
        const name_change = value_name ? value_name : `${deleteItemFromArrayByNumKey(name_split, [0, name_split.length-1]).join('_')}_value`;
        setWebConfig((prev) => prev.length > 0 ? updateArray(prev, num, !prev[num][name] ? {...prev[num], [name]:!prev[num][name], [name_change]:init_value} :  {...prev[num], [name]:!prev[num][name]}) : []);
    }
    const onToggleClass = (e, num=-1, name="", init_value="", value_name="", init={}) => {
        const name_split = name.split('_');
        const name_change = value_name ? value_name : `${deleteItemFromArrayByNumKey(name_split, [0, name_split.length-1]).join('_')}_value`;
        setAllClassSection((prev) => prev.length > 0 ? updateArray(prev, num, !prev[num][name] ? {...prev[num], [name]:!prev[num][name], [name_change]:init_value, ...init} :  {...prev[num], [name]:!prev[num][name]}) : []);
    }
    
    const onClickNext = (num=-1, id=0, plugin="") => {
        setError(initialErrorState);
        if(plugin === "class"){
            const filter = allClassSection.filter((it) => !it.from_age || !it.to_age);
            if(filter.length > 0){
                setError({data:["All Class Age Differences Are Required"], title:'Web Link Data Insert Error'});
                return;
            }
        }
        setLoading(true);
        const next_data = webConfig[num+1] ?  webConfig[num+1] : {id:0, num:num+1, uid:''}
        // const item = webConfig[num];
        if(next_data.id && webConfig.length !== next_data.num){
            webAppServices.updateWebLink(id, {is_done:true, type:webConfig[num].type, order:num*500}).then(res => {
                setWebConfig((prev) => {return updateArray(prev, num, {...prev[num],  ...res.data, layout:''})});
                if(next_data.id){
                    const dt = {school:next_data.id,  order:allWeb.length * 500, is_plugin:next_data.is_plugin, created_by:user_id, header_title:next_data.title, header_title_desc:next_data.desc}
                    webAppServices.insertWeb(dt).then(result => {
                        setLoading(false);
                        setAllWeb((prev) => {return addItemToArray([...prev, result.data])});
                    }).catch(e => {
                        setLoading(false);
                    })
                }
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Insert Error'});
                setLoading(false);
            })
            return;
        }
        if(webConfig.length === next_data.num) {
            webAppServices.updateWebLink(id, {is_done:true, type:webConfig[num].type, order:num*500, is_finished:1}).then(res => {
                setWebConfig((prev) => {return updateArray(prev, num, {...prev[num],  ...res.data, layout:''})});
                window.location.reload();
            }).catch(e => {
                setLoading(false);
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Insert Error'});
            })
            return;
        }
        setLoading(false);
        setError({data:['Something Went Wrong'], title:'Web Link Data Insert Error'});
    }
    const onPushToLive = () => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        webAppServices.pushWebLive(school_id, {pushed_by:user_id}).then(() => {
            setSuccess({title:'Success', text:'School Website is now live'});
            setTimeout(() => {
                window.location.reload();
            }, 1000)
            
        }).catch(e => {
            setLoading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Error'});
        })
    }
    const onChangeConfigEditor = (e, num=-1, name="", value="") => {
        setWebConfig((prev) => prev.length > 0 ? updateArray(prev, num, {...prev[num], [name ? name : e.target.name]:name ? value : e.target.value}) : []);
    }
    const onChangeClassEditor = (e, num=-1, type="", name="", value="") => {
        if(type === "file"){
            if(!e.target.value){
                setAllClassSection((prev) => prev.length > 0 ? updateArray(prev, num, {...prev[num], [name ? name : e.target.name]:null, [name ? name+'_value' : e.target.name+'_value']: ''}) : [])
                return;
            }
            setError(initialErrorState);
            const file = e.target.files[0];
            const vald = validateImg(file);
            if(vald.success === true){
                setAllClassSection((prev) => prev.length > 0 ? updateArray(prev, num, {...prev[num], [name ? name : e.target.name]:file, [name ? name+'_value' : e.target.name+'_value']: e.target.value}) : [])
                return;
            }
            setAllClassSection((prev) => prev.length > 0 ? updateArray(prev, num, {...prev[num], [name ? name : e.target.name]:null, [name ? name+'_value' : e.target.name+'_value']: ''}) : [])
            setError({title:'Form Error', data:[vald.detail]});
            return;
        }
        setAllClassSection((prev) => prev.length > 0 ? updateArray(prev, num, {...prev[num], [name ? name : e.target.name]:name ? value : e.target.value}) : []);
    }
    
   

    useEffect(() => {
        if(reload && school_id){
            fetchAllWebLink(school_id, initConfig);
            fetchAllWeb(school_id);
            fetchAllClassSection(school_id);
            fetchSchool(school_title);
            fetchAllClass(school_id);
            setReload(false);
            return;
        }
    }, [reload, school_id, school_title, initConfig]);
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    
    
    return(
        <>
            <HeadComponent title='All Embedded Applications' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                <Grid layout='lauto'>
                    <GridContent>
                        {loading && <Loader />}
                        {preloading ? (<ul>
                            <BlankLoader num={20} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        {!school.is_start_setup_web ? (
                            <Section header="Setup School Website" >
                                <div className='disp-block align-center padd-4px padd-4px'>
                                    <span className='font-small app-text-color fw-600'>Setup School Picture...</span>
                                </div>
                                <form onSubmit={e => onSubmitSchoolWeb(e)}>
                                    <div className='disp-flex row wrap jc-sb'>
                                        <div className='' style={{width:'100%'}}>
                                            <div className='disp-block padd-4px'>
                                                <label className='font-slightly-small fw-500 disp-block padd-4px app-text-color' >Choose an image showcasing your school<b className='red'>*</b></label>
                                                <input type="file" required name="pic" className='font-very-small' value={schoolPic.value} onChange={e => onChangeFile(e)}  />
                                            </div>
                                            {/* <div className='disp-block'>
                                                <span className='disp-block padd-4px align-center font-small app-text-sec-color fw-600'>Initialize Class Plugin</span>
                                            </div> */}
                                    
                                            {/* {allClassSection.map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <div className='disp-block'>
                                                            <div style={{marginTop:'5px'}}>
                                                                <span className='font-slightly-small padd-4px fw-600 disp-block app-text-color text-capitalize' >{item.name} Section</span>
                                                                <div className='disp-block' style={{padding:'3px'}}>
                                                                    <label className='font-slightly-small fw-500 disp-block padd-4px app-text-color' >Enter age gap for this class<b className='red'>*</b></label>
                                                                    <div className='disp-flex wrap jc-stretch'>
                                                                        <div className='disp-block'>
                                                                            <label className='font-very-small fw-500 app-text-color'>From Age: </label>
                                                                            <input type="number" name="from_age_value" className='font-very-small app-text-color form-control' style={{width:'50px'}} value={item.age_from} />
                                                                        </div>
                                                                        <div className='disp-block'>
                                                                            <label className='font-very-small fw-500 app-text-color'>To Age: </label>
                                                                            <input type="number" name="to_age_value" className='font-very-small app-text-color form-control'  style={{width:'50px'}} value={item.age_to} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='disp-block'>
                                                                    <label className='font-very-small fw-500 app-text-color'>Class Description </label>
                                                                    <textarea className='form-control font-very-small app-text-color' name="desc_value" placeholder='Enter description about the class for parents....' style={{minHeight:'50px'}}></textarea>
                                                                </div>
                                                                <div className='disp-block padd-4px'>
                                                                    <label className='font-slightly-small fw-500 disp-block padd-4px app-text-color' >Choose an image showcasing this class <b className='red'>*</b></label>
                                                                    <input type="file" name="pic" className='font-very-small'  />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })} */}
            
                                        </div>
                                        <div className='' style={{border:'1px solid #666', width:'100%'}}>
                                            <div className='disp-block'>
                                                <div className='disp-inline padd-4px'>
                                                    <label className='font-very-small fw-500 padd-4px app-text-color'>Auto</label>
                                                    <input type="radio" onChange={() => setIsAuto(!isAuto)} checked={isAuto} color='var(--appBgColor)' />
                                                </div>
                                            </div>
                                            <div className='disp-block'>
                                                <div className='disp-inline padd-4px'>
                                                    <label className='font-very-small fw-500 padd-4px app-text-color'>Custom</label>
                                                    <input type="radio" onChange={() => setIsAuto(!isAuto)} checked={!isAuto}  color='var(--appBgColor)' />
                                                </div>
                                                {!isAuto && (
                                                    <div className='disp-block'>
                                                        <div style={{paddingLeft:'12px'}}>
                                                            {webConfig.filter((it) => it.is_original === true).map((item, i) => {
                                                                return(
                                                                    <Fragment key={i}>
                                                                        <div className='disp-block'>
                                                                            <label className='fw-500 font-super-small app-text-color'>{item.type}</label>
                                                                            <input type="checkbox" onChange={() => onChangeConfig(item.num, !item.is_checked)} checked={item.is_checked} disabled={item.is_general} />
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='disp-block align-center mr-t-12px'>
                                        <button type="submit" className='btn hover-opacity app-bg-color app-bg-text-color br'>Next <i className='fas fa-arrow-right'></i></button>
                                    </div>
                                </form>
                            </Section>
                        ) : (
                            <>
                            {!school.is_setup_eapp_web ? (
                                <Section header="Installing School Website...." >
                                    {webConfig.filter((it) => !it.is_done).length > 0 && (
                                        <>
                                        {isPreview ? (
                                            <>
                                            {[webConfig.filter((it) => !it.is_done)[0]].map((item, i, web_arr) => {
                                                const all_web = allWeb.filter((it) => it.school === item.id);
                                                
                                                return(
                                                    <Fragment key={i}>
                                                        <div className={'app-body-color'} style={{marginBottom:'5px'}}>
                                                        {all_web.map((item_web, j) => (
                                                            <Fragment key={j}>
                                                                {previewer(item_web.is_split ? 2 : 1, item.type, item_web.header_title, {content_type:item.type === "home" ? 'img' : (item_web.pic.length > 0 ? 'img' : 'text'), preface:item_web.preface, title:item_web.title, desc:item_web.desc, text:item_web.text, img:item.type === "home" ? school.pic : ''}, {content_type:'text', preface:item_web.preface2, title:item_web.title2, desc:item_web.desc2, text:item_web.text2}, item.theme)}
                                                            </Fragment>
                                                        ))}
                                                        <div className='disp-flex wrap mr-t-5px'>
                                                            <div className='flex-50-line align-center'>
                                                                <button type='button' className='btn br app-bg-text-color bg-black font-slightly-small hover-opacity' onClick={() => setIsPreview(false)}><i className='fas fa-arrow-left'></i> Back</button>
                                                            </div>
                                                            <div className='flex-50-line align-center'>
                                                                <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid)}>{webConfig.length-1 === item.num ? 'Finish' : 'Next Page'}  <i className='fas fa-arrow-right'></i></button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                            </>
                                        ) : (
                                            <>
                                            {[webConfig.filter((it) => !it.is_done)[0]].map((item, i, web_arr) => {
                                            const all_web = allWeb.filter((it) => it.school === item.id);
                                      
                                            return(
                                                <Fragment key={i}>
                                                    <div className={'app-body-color'} style={{marginBottom:'5px'}}>
                                                    <div className='disp-block align-center'>
                                                        <h3 className='app-text-color text-capitalize'>{item.type} Page Setup</h3>
                                                    </div>
                                                    {item.type === "home" ? (
                                                        <>
                                                        {!item.layout ? (
                                                            <>
                                                            <div className='disp-block align-center'>
                                                                <label className='app-text-color fw-500 '>Select Page Layout</label>
                                                                <select name="layout" onChange={e => onChangeLayout(e, item.num)} value={item.layout}>
                                                                    <option value={''}>Select Layout</option>
                                                                    <option value={'2'}>Dual-Split Layout</option>
                                                                </select>
                                                            </div>
                                                            {/* <div className='disp-block mr-t-5px' style={{marginTop:'10px'}}>
                                                                <div className='align-center'>
                                                                    <button className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity'> Next Page <i className='fas fa-arrow-right'></i></button>
                                                                </div>
                                                            </div> */}
                                                            </>
                                                        ) : (
                                                            <>
                                                            {all_web.map((item_web, j) => {
                                                                return (
                                                                    <Fragment key={j}>
                                                                        <div className={item.theme}>
                                                                            <SchoolWebHomeWrapper>
                                                                                {!item.is_page_title_change ? <Header title={item_web.header_title ? item_web.header_title : "Create page title e.g Home ....|"} onClick={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.header_title)} is_placeholder={item_web.header_title ? false : true}/> : <HeaderEditor is_required={false} name={'page_title_value'} value={item.page_title_value} title={item_web.header_title} onToggle={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.title)} placeholder='Enter Page Title' onChange={e => onChangeConfigEditor(e, item.num)} onSubmit={e => onSubmitText(e, item.num, item_web, 'header_title', item.page_title_value, 'is_page_title_change', item_web.header_title)}  />}
                                                                                <PageByPageEditor is_add_image1={false} is_preface_change2={item.is_preface_change2}  content_type1='img' img_src1={`${BACKEND_URL}${school.pic}`} img_alt1='home' title={item_web.title} is_preface_under_change2={item.is_preface_under_change2} 
                                                                                    preface2={item_web.title2} preface_under2={item_web.preface2} text2={item_web.text2}
                                                                                    onTogglePreface2={(e) => onToggleConfig(e, item.num, 'is_preface_change2', item_web.title2, 'preface_value2')} onChangePreface2={e => onChangeConfigEditor(e, item.num)} preface_value2={item.preface_value2}
                                                                                    onTogglePrefaceUnder2={(e) => onToggleConfig(e, item.num, 'is_preface_under_change2', item_web.preface2, 'preface_under_value2')} onChangePrefaceUnder2={e => onChangeConfigEditor(e, item.num)} preface_under_value2={item.preface_under_value2}
                                                                                    onToggleText2={(e) => onToggleConfig(e, item.num, 'is_text_change2', item_web.text2, 'text_value2')} text_value2={item.text_value2} is_text_change2={item.is_text_change2} onChangeEditorText2={(e, editor) => {
                                                                                        const data = editor.getData();
                                                                                        onChangeConfigEditor(e, item.num, 'text_value2', data);
                                                                                    } } 
                                                                                    onSubmitPreface2={e => onSubmitText(e, item.num, item_web, 'title2', `${item.preface_value2}`, 'is_preface_change2')}
                                                                                    onSubmitPrefaceUnder2={e => onSubmitText(e, item.num, item_web, 'preface2', `${item.preface_under_value2}`, 'is_preface_under_change2')}
                                                                                    onSubmitText2={e => onSubmitText(e, item.num, item_web, 'text2', `${item.text_value2}`, 'is_text_change2')}
                                                                                />
                                                                                <div className='disp-block mr-t-4px mr-b-4px'>
                                                                                    <div className='align-center'>
                                                                                        <button className='btn br app-text-color font-slightly-small hover-opacity' style={{border:'3px solid var(--appBgColor)'}} onClick={() => setIsPreview(true)}><i className='fas fa-play'></i> Preview</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='disp-flex wrap mr-t-4px'>
                                                                                    <div className='flex-50-line align-center'>
                                                                                        <button type='button' className='btn br app-bg-text-color bg-black font-slightly-small hover-opacity' onClick={() => setWebConfig(updateArray(webConfig, item.num,  {...webConfig[item.num], layout:''}))}><i className='fas fa-arrow-left'></i> Back</button>
                                                                                    </div>
                                                                                    <div className='flex-50-line align-center'>
                                                                                        <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid)}> Next Page <i className='fas fa-arrow-right'></i></button>
                                                                                    </div>
                                                                                </div>
                                                                            </SchoolWebHomeWrapper>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            } )}
                                                            
                                                            </>
                                                        )}
                                                        
                                                        </>
                                                    ) : (
                                                        <>
                                                        {item.is_plugin && (
                                                        <>
                                                        {item.plugin_type === "class" && (
                                                            <>
                                                            {all_web.map((item_web, j) => {
                                                                return (
                                                                    <Fragment key={j}>
                                                                        <div className={item.theme}>
                                                                            <SchoolWebHomeWrapper>
                                                                                {!item.is_page_title_change ? <Header title={item_web.header_title ? item_web.header_title : "Create page title e.g Home ....|"} onClick={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.header_title)} is_placeholder={item_web.header_title ? false : true}/> : <HeaderEditor is_required={false} name={'page_title_value'} value={item.page_title_value} title={item_web.header_title} onToggle={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.title)} placeholder='Enter Page Title' onChange={e => onChangeConfigEditor(e, item.num)} onSubmit={e => onSubmitText(e, item.num, item_web, 'header_title', item.page_title_value, 'is_page_title_change', item_web.header_title)}  />}
                                                                                <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                                                                    <HeaderDesc text={item_web.header_title_desc} />
                                                                                    <WebComponentGrid grid_width={"big-w"}>
                                                                                        {allClassSection.map((item_class, i) => {
                                                                                            const cls = allClass.filter((it) => it.class_section_school === item_class.id)
                                                                                            return(
                                                                                                <Fragment key={i}>
                                                                                                    <ClassPluginEditor title={`${item_class.name} section`} class_arr={cls} from_age={item_class.from_age} to_age={item_class.to_age} text={item_class.desc ? item_class.desc : ''} img_src={item_class.pic ? `${BACKEND_URL}${item_class.pic.pic}` : ''} 
                                                                                                        onToggleImg={(e) => onToggleClass(e, item_class.num, 'is_add_image', null, 'img_value', {img_value_value:''})} is_add_image={item_class.is_add_image} onChangeImg={e => onChangeClassEditor(e, item_class.num, 'file')} img_value={item_class.img_value_value} onSubmitImg={e => {
                                                                                                            e.preventDefault();
                                                                                                            let formData = new FormData();
                                                                                                            formData.append('pic', item_class.img_value, item_class.img_value.name);
                                                                                                            formData.append('created_by', user_id);
                                                                                                            formData.append('school', school_id);
                                                                                                            formData.append('class_section_school', item_class.id);
                                                                                                            formData.append('section', 'class')
                                                                                                            formData.append('type', 'class_section')
                                                                                                            formData.append('main_type', item_class.name)
                                                                                                            onSubmitClassPlugin(e, item_class.num, formData, 'is_add_image', 'file')}}
                                                                                                        onToggleAge={(e) => onToggleClass(e, item_class.num, 'is_age_change', item_class.from_age === 0 ? '' : item_class.from_age, 'from_age_value', {to_age_value:item_class.to_age === 0 ? '' : item_class.to_age})} onChangeAge={e => onChangeClassEditor(e, item_class.num)} is_age_change={item_class.is_age_change} from_age_value={item_class.from_age_value} to_age_value={item_class.to_age_value} onSubmitAge={e => onSubmitClassPlugin(e, item_class.num, {from_age:item_class.from_age_value, to_age:item_class.to_age_value}, 'is_age_change')}
                                                                                                        onToggleText={(e) => onToggleClass(e, item_class.num, 'is_text_change', item_class.desc, 'text_value')} onChangeText={e => onChangeClassEditor(e, item_class.num)} is_text_change={item_class.is_text_change} text_value={item_class.text_value} onSubmitText={e => onSubmitClassPlugin(e, item_class.num, {desc:item_class.text_value}, 'is_text_change')} /> 
                                                                                                </Fragment>
                                                                                            )
                                                                                        })}
                                                                                        
                                                                                    </WebComponentGrid>
                                                                                    <div className='disp-block' style={{width:'100%'}}>
                                                                                        <div className='align-center'>
                                                                                            <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid, item.plugin_type)}> Next Page <i className='fas fa-arrow-right'></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </HomeContent>
                               
                                                                            </SchoolWebHomeWrapper>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            } )}
                                                            </>
                                                        )}
                                                        </>
                                                    )}
                                                    {!item.layout ? (
                                                        <>
                                                        <div className='disp-block align-center'>
                                                            <label className='app-text-color fw-500 '>Select Page Layout</label>
                                                            <select name="layout" onChange={e => onChangeLayout(e, item.num)} value={item.layout}>
                                                                <option value={''}>Select Layout</option>
                                                                {item.type.split('and').length < 2 && <option value={'1'}>One Layout</option>}
                                                                {item.type !== "school location" && <option value={'2'}>Dual-Split Layout</option>}
                                                            </select>
                                                        </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                        {parseInt(item.layout) === 1 && (
                                                            <>
                                                            {all_web.map((item_web, j) => {
                                                                const color = colorThemePallete(item.theme);
                                                                return (
                                                                    <Fragment key={j}>
                                                                        <div className={item.theme}>
                                                                            <SchoolWebHomeWrapper>
                                                                                {!item.is_page_title_change ? <Header title={item_web.header_title ? item_web.header_title : "Create page title e.g Home ....|"} classname={`${color.app_text_color}`} onClick={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.header_title)} is_placeholder={item_web.header_title ? false : true}/> : <HeaderEditor is_required={false} name={'page_title_value'} value={item.page_title_value} title={item_web.header_title} onToggle={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.title)} placeholder='Enter Page Title' onChange={e => onChangeConfigEditor(e, item.num)} onSubmit={e => onSubmitText(e, item.num, item_web, 'header_title', item.page_title_value, 'is_page_title_change', item_web.header_title)}  />}
                                                                                <OnePageEditor bg_theme={item.theme} is_preface_change={item.is_preface_change}   title={item_web.title} is_preface_under_change={item.is_preface_under_change}
                                                                                    preface={item_web.title} preface_under={item_web.preface} text={item_web.text}
                                                                                    onTogglePreface={(e) => onToggleConfig(e, item.num, 'is_preface_change', item_web.title)} onChangePreface={e => onChangeConfigEditor(e, item.num)} preface_value={item.preface_value}
                                                                                    onToggleText={(e) => onToggleConfig(e, item.num, 'is_text_change', item_web.text)} text_value={item.text_value} is_text_change={item.is_text_change} onChangeEditorText={(e, editor) => {
                                                                                        const data = editor.getData();
                                                                                        onChangeConfigEditor(e, item.num, 'text_value', data);
                                                                                    } } 
                                                                                    onSubmitPreface={e => onSubmitText(e, item.num, item_web, 'title', `${item.preface_value}`, 'is_preface_change')}
                                                                                    onSubmitText={e => onSubmitText(e, item.num, item_web, 'text', `${item.text_value}`, 'is_text_change')}
                                                                                />
                                                                            </SchoolWebHomeWrapper>
                                                                        </div>
                                                                        <div className='disp-block mr-t-4px mr-b-4px'>
                                                                                <div className='align-center'>
                                                                                    <button className='btn br app-text-color font-slightly-small hover-opacity' style={{border:'3px solid var(--appBgColor)'}} onClick={() => setIsPreview(true)}><i className='fas fa-play'></i> Preview</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='disp-flex wrap mr-t-4px'>
                                                                                <div className='flex-50-line align-center'>
                                                                                    <button type='button' className='btn br app-bg-text-color bg-black font-slightly-small hover-opacity' onClick={() => setWebConfig(updateArray(webConfig, item.num,  {...webConfig[item.num], layout:''}))}><i className='fas fa-arrow-left'></i> Back</button>
                                                                                </div>
                                                                                <div className='flex-50-line align-center'>
                                                                                    <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid)}>{webConfig.length-1 === item.num ? 'Finish' : 'Next Page'}  <i className='fas fa-arrow-right'></i></button>
                                                                                </div>
                                                                            </div>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                            </>
                                                        )}
                                                        {parseInt(item.layout) === 2 && (
                                                            <>
                                                            {all_web.map((item_web, j, web_arr) => {
                                                                return (
                                                                    <Fragment key={j}>
                                                                        <div className={item.theme}>
                                                                            <SchoolWebHomeWrapper>
                                                                                {!item.is_page_title_change ? <Header title={item_web.header_title ? item_web.header_title : "Create page title e.g Home ....|"} onClick={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.header_title)} is_placeholder={item_web.header_title ? false : true}/> : <HeaderEditor is_required={false} name={'page_title_value'} value={item.page_title_value} title={item_web.header_title} onToggle={(e) => onToggleConfig(e, item.num, 'is_page_title_change', item_web.title)} placeholder='Enter Page Title' onChange={e => onChangeConfigEditor(e, item.num)} onSubmit={e => onSubmitText(e, item.num, item_web, 'header_title', item.page_title_value, 'is_page_title_change', item_web.header_title)}  />}
                                                                                <PageByPageEditor bg_theme={item.theme} is_add_preface_underbelly={false} is_add_image1={false} is_preface_change1={item.is_preface_change1} is_preface_change2={item.is_preface_change2} title={item_web.title} is_preface_under_change1={item.is_preface_under_change} is_preface_under_change2={item.is_preface_under_change2} 
                                                                                    preface1={item_web.title} text1={item_web.text} preface2={item_web.title2} preface_under2={item_web.preface2} text2={item_web.text2}
                                                                                    onTogglePreface1={(e) => onToggleConfig(e, item.num, 'is_preface_change1', item_web.title, 'preface_value1')} onChangePreface1={e => onChangeConfigEditor(e, item.num)} preface_value1={item.preface_value1}
                                                                                    onToggleText1={(e) => onToggleConfig(e, item.num, 'is_text_change1', item_web.text, 'text_value1')} text_value1={item.text_value1} is_text_change1={item.is_text_change1} onChangeEditorText1={(e, editor) => {
                                                                                        const data = editor.getData();
                                                                                        onChangeConfigEditor(e, item.num, 'text_value1', data);
                                                                                    } } 
                                                                                    onTogglePreface2={(e) => onToggleConfig(e, item.num, 'is_preface_change2', item_web.title2, 'preface_value2')} onChangePreface2={e => onChangeConfigEditor(e, item.num)} preface_value2={item.preface_value2}
                                                                                    onToggleText2={(e) => onToggleConfig(e, item.num, 'is_text_change2', item_web.text2, 'text_value2')} text_value2={item.text_value2} is_text_change2={item.is_text_change2} onChangeEditorText2={(e, editor) => {
                                                                                        const data = editor.getData();
                                                                                        onChangeConfigEditor(e, item.num, 'text_value2', data);
                                                                                    } } 
                                                                                    onSubmitPreface1={e => onSubmitText(e, item.num, item_web, 'title', `${item.preface_value1}`, 'is_preface_change1')}
                                                                                    onSubmitText1={e => onSubmitText(e, item.num, item_web, 'text', `${item.text_value1}`, 'is_text_change1')}
                                                                                    onSubmitPreface2={e => onSubmitText(e, item.num, item_web, 'title2', `${item.preface_value2}`, 'is_preface_change2')}
                                                                                    //onSubmitPrefaceUnder2={e => onSubmitText(e, item.num, item_web, 'preface2', `${item.preface_under_value2}`, 'is_preface_under_change2')}
                                                                                    onSubmitText2={e => onSubmitText(e, item.num, item_web, 'text2', `${item.text_value2}`, 'is_text_change2')}
                                                                                />
                                                                                <div className='disp-block mr-t-4px mr-b-4px'>
                                                                                    <div className='align-center'>
                                                                                        <button className='btn br app-text-color font-slightly-small hover-opacity' style={{border:'3px solid var(--appBgColor)'}} onClick={() => setIsPreview(true)}><i className='fas fa-play'></i> Preview</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='disp-flex wrap mr-t-4px'>
                                                                                    <div className='flex-50-line align-center'>
                                                                                        <button type='button' className='btn br app-bg-text-color bg-black font-slightly-small hover-opacity' onClick={() => setWebConfig(updateArray(webConfig, item.num,  {...webConfig[item.num], layout:''}))}><i className='fas fa-arrow-left'></i> Back</button>
                                                                                    </div>
                                                                                    <div className='flex-50-line align-center'>
                                                                                        <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid)}>{webConfig.length-1 === item.num ? 'Finish' : 'Next Page'}  <i className='fas fa-arrow-right'></i></button>
                                                                                    </div>
                                                                                </div>
                                                                            </SchoolWebHomeWrapper>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            } )}
                                                            </>
                                                        )}
                                                        </>
                                                    )}
                                                        </>
                                                    )}
                                                    
                                                </div>
                                                </Fragment>
                                            )
                                        })}
                                            </>
                                        )}
                                        
                                        
                                        </>
                                    )}
                                </Section> 
                            ) : (
                                <>
                                {webConfig.length > 0 && (
                                    <>
                                  
                                    <div className='disp-block align-center' style={{marginTop:'20px'}}>
                                        <span className='font-small fw-600' style={{letterSpacing:'.6px'}}>Current Status: {school.is_eapp_web_live ? <b style={{color:'green'}}>LIVE</b> : <b style={{color:'red'}}>TEST</b>}</span>
                                    </div>
                                    {!school.is_eapp_web_live && (
                                        <div className='disp-block align-center' style={{marginTop:'20px'}}>
                                            <button type="button" className='mybtn app-bg-color app-bg-text-color fw-600 font-slightly-small' onClick={() => onPushToLive()}>Go Live!</button>
                                        </div>
                                    )}
                                    {!school.is_eapp_web_live ? <Note>This website is currently not working live, please GO LIVE to integrate the functionality</Note> : (
                                        <div className='disp-block align-center' style={{marginTop:'10px'}}>
                                            <span className='font-very-small fw-600'>Visit Your School Website at <Link to={`https://voskool.com/${school.title}`} target="_blank">www.voskool.com/{school.title}</Link> </span>
                                        </div>
                                    )}
                                    {webConfig.map((item, i) => {
                                        const all_web = allWeb.filter((it) => it.school === item.id);
                                        const color = colorThemePallete(item.theme);
                                        return(
                                            <Fragment key={i}>
                                                {item.is_plugin ? (
                                                    <>
                                                    {item.plugin_type === "class" && (
                                                        <>
                                                        {all_web.map((item_web, j) => {
                                                            return (
                                                                <Fragment key={j}>
                                                                    <div className={item.theme}>
                                                                        <SchoolWebHomeWrapper>
                                                                            {item_web.header_title && <Header classname={color.app_text_color} title={item_web.header_title} is_placeholder={false}/> }
                                                                            <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                                                                <HeaderDesc text={item_web.header_title_desc} />
                                                                                <WebComponentGrid grid_width={"big-w"}>
                                                                                    {allClassSection.map((item_class, i) => {
                                                                                        const cls = allClass.filter((it) => it.class_section_school === item_class.id)
                                                                                        return(
                                                                                            <Fragment key={i}>
                                                                                                <ClassPlugin title={`${item_class.name} section`} class_arr={cls} from_age={item_class.from_age} to_age={item_class.to_age} text={item_class.desc ? item_class.desc : ''} img_src={item_class.pic ? `${BACKEND_URL}${item_class.pic.pic}` : ''} />                                                                                                      
                                                                                            </Fragment>
                                                                                        )
                                                                                    })}
                                                                                    
                                                                                </WebComponentGrid>
                                                                            </HomeContent>
                            
                                                                        </SchoolWebHomeWrapper>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        } )}
                                                        </>
                                                    )}
                                                    </>
                                                ) : (
                                                    <>
                                                    <div className={'app-body-color'} style={{marginBottom:'5px'}}>
                                                        {all_web.map((item_web, j) => (
                                                            <Fragment key={j}>
                                                                {previewer(item_web.is_split ? 2 : 1, item.type, item_web.header_title, {content_type:item.type === "home" ? 'img' : (item_web.pic.length > 0 ? 'img' : 'text'), preface:item_web.preface, title:item_web.title, desc:item_web.desc, text:item_web.text, img:item.type === "home" ? school.pic : ''}, {content_type:'text', preface:item_web.preface2, title:item_web.title2, desc:item_web.desc2, text:item_web.text2}, item.theme)}
                                                            </Fragment>
                                                        ))}
                                                        {/* <div className='disp-flex wrap mr-t-5px'>
                                                            <div className='flex-50-line align-center'>
                                                                <button type='button' className='btn br app-bg-text-color bg-black font-slightly-small hover-opacity' onClick={() => setIsPreview(false)}><i className='fas fa-arrow-left'></i> Back</button>
                                                            </div>
                                                            <div className='flex-50-line align-center'>
                                                                <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid)}>{webConfig.length-1 === item.num ? 'Finish' : 'Next Page'}  <i className='fas fa-arrow-right'></i></button>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </>
                                                )}
                                            </Fragment>
                                        )
                                    })} 
                                    
                                    <div className='disp-block align-center' style={{marginTop:'20px'}}>
                                        <span className='font-small fw-600' style={{letterSpacing:'.6px'}}>Current Status: {school.is_eapp_web_live ? <b style={{color:'green'}}>LIVE</b> : <b style={{color:'red'}}>TEST</b>}</span>
                                    </div>
                                    {!school.is_eapp_web_live && (
                                        <div className='disp-block align-center' style={{marginTop:'20px'}}>
                                            <button type="button" className='mybtn app-bg-color app-bg-text-color fw-600 font-slightly-small' onClick={() => onPushToLive()}>Go Live!</button>
                                        </div>
                                    )}
                                </>
                                )}    
                                </>
                            )}
                            </>
                        )}
                        </>
                        )}
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default WebApp;