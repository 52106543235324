import { StyleSheet } from "@react-pdf/renderer";

export const pdfStyle = (schoolColor) => { return StyleSheet.create({
    page:{
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Raleway',
    },
    section_half:{
        width:'45%',
        padding: '4px'
    },
    section_6:{
        width:'6%',
    },
    cont_obj_bg:{
        position: 'absolute',
        top:'0',   
        zIndex:0,
    },
    obj_bg:{
        display: 'flex',
        width:'110%',
        flexDirection:'row',
    },
    obj:{
        fontSize:'5px',
        color:schoolColor,
        opacity:.25
    },
    cont_voskool_logo:{
        position: 'absolute',
        top:'50%',
        left:'20%',
        zIndex:0,
    },
    voskool_logo:{
        width:'170px',
        height: '50px',
        opacity: .25,
    },
    heading_container: {
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'space-between',
        marginTop: '2px',
        marginBottom: '8px',
    },
    heading_text:{
        width: '80%',
        fontWeight:700,
        fontSize:'15px',
        paddingRight:'10px',
    }, 
    heading_text_:{
        width:'100%',
    } ,
    heading_logo:{
        width:'50px',
        height: '40px'
    },
    cont:{
        marginTop:'8px',
        display: 'flex',
        flexDirection:'row',
    },
    inner_cont_sb:{
        display: 'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },  
    inner_cont_25:{
        width:'25%',
        padding:'2px',
    },
    inner_cont_form:{
        width:'65%'
    },
    inner_cont_other:{
        width:'35%',
        
        
    },
    cont_header_center:{
        fontSize:'10px',
        textAlign:'center',
        color:'#555',
        fontWeight:600,
    },
    box_cont:{
        display:'flex', flexDirection:'row', justifyContent:'flex-start', padding:'3px', borderWidth:'2px', borderColor:schoolColor,
        position:'relative', borderRadius:'3px',
    },
    box_label:{
        fontSize:'9px',
        width:'70%',
        marginBottom:'5px',
        fontWeight:600,
        transform: 'translateY(-10px)',
        backgroundColor: '#fff',
        color:schoolColor,
        position: 'absolute',
    },
    box_letter_header:{
        fontWeight: 600,
        fontSize:'7px',
        marginRight:'5px',
    },
    box_letter_text:{
        fontSize:'6px',
        marginRight:'5px',
        letterSpacing:'0.6px',
        color:schoolColor,
        fontWeight:600,
    },

    
    text:{
       
    }
})
}