import { StyleSheet } from "@react-pdf/renderer";

export const style = StyleSheet.create({
    wrapper:{

    },
    container:{
        position: 'relative',
    },
    num:{
        position: 'absolute',
        transform: 'translate(1.5px, -1px)',
        color:'#000',
        fontWeight:'600',
        fontSize:'8px',
    },
    mark:{
        position: 'absolute',
        transform: 'translate(-0.2px, 2px)',
        width:'7px',
        borderWidth:'2px',
        borderColor:'#111'
    },
    ilis:{
        fontSize:'7px',
        color:'#000',
    },
    ilis_box:{
        width:'100%',
        borderWidth:'1px',
        padding: '3px',
        // width:'50%',
        borderColor:'#000'
    },
})