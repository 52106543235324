import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, Content } from "./style";

const UnderConstruction = ({link="../dashboard"}) => {
    return(
        <Wrapper>
            <Content>
                <div className="disp-block padd-4px align-left" style={{marginRight:'5px'}}>
                    <span className="fas fa-hard-hat font-big" style={{color:'#ccc'}}></span>
                </div>
                <div className="disp-block padd-4px align-center">
                    <span className="font-slightly-small fw-600" style={{color:'#ccc'}}>Yello, This technology is under construction and will be released soon</span>
                </div>
                <div className="disp-block padd-4px align-right">
                    <Link to={link} className="font-very-small"> <i className="fas fa-arrow-left"></i> Go Back</Link>
                </div>
            </Content>
        </Wrapper>
    )
}

export default UnderConstruction;