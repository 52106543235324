import styled from 'styled-components';

export const Spinner = styled.div`
    border: 4px solid #ddd;
    border-top: 4px solid var(--voskoolBagColor);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin .9s infinite ease;
    margin: 15px auto;
    @keyframes spin {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    @media(max-width: 780px){
        width: 35px;
        height: 35px;
        border: 3px solid #ddd;
        border-top: 3px solid var(--voskoolBagColor);
    }
`;