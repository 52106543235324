import React from "react";
import { OuterWrapper, Wrapper } from "./style";

const Background = ({children, bg_color="#fff"}) => {
    return(
        <OuterWrapper >
            <Wrapper style={{background:bg_color}}>
                {children}
            </Wrapper>
        </OuterWrapper>
    )
}

export default Background;