import React from "react";
import { Wrapper, Content, InnerContent } from "./style";

export const PanelDBInner = ({icon="", text="", desc="", is_click_text=false, is_bordered=false, onClickText, is_img=false, children, is_children=true}) => {
    const text_split = text.split(':');
    return(
        <>
        <InnerContent className={is_bordered ? 'bordered' : ''}>
            <div className="main-cont">
                <div className="cont-icon">
                    {!is_img ? <span className={`${icon} font-small app-text-color`}></span> : <img className="user-icon br-circle" src={icon} alt="Ig" />}
                </div>
                <div className="cont-text">
                    {text_split.length > 1 ? (<><span className={`font-very-small fw-600 app-text-color text ${is_click_text ? 'hover-text' : ''}`} >{text_split[0]}</span>:<span className="font-very-small app-text-color fw-500">{text_split[1]}</span></>)  :  <span onClick={onClickText} className={`font-slightly-small fw-600 app-text-color text ${is_click_text ? 'hover-text' : ''}`} >{text}</span>}
                    {desc && (
                        <div className="padd-4px">
                            <span className="font-super-small fw-600" style={{color:'#666'}}>{desc}</span>
                        </div>
                    )}
                    
                </div>
            </div>
            {is_children &&(
                <div>
                    {children}
                </div>
            )}
            
        </InnerContent>
        </>
    )
}

const PanelDB = ({title="", children, icon="", is_img=false, is_view=false, btn_icon="fas fa-eye", btn_title="View", onClickView}) => {
    return(
        <Wrapper>
            <Content>
                {icon && (
                    <div className="icon-placeholder-cont">
                        {!is_img ? <i className={`${icon}`}></i> : <img src={icon} alt="My" />}
                    </div>
                )}
                {is_view && (
                    <div className="icon-btn-cont">
                        <button onClick={onClickView} className="font-super-small">{btn_title} <i className={btn_icon}></i></button>
                    </div>
                )}   
                <div className="header-cont" style={{marginTop:'2px'}}>
                    <div className="header">
                        <span className="app-text-sec-color fw-700 font-slightly-small">{title}</span>
                    </div>
                    <div className="icon">
                        <span className="fas fa-plus font-slightly-small"></span>
                    </div>
                </div>
                {children}
            </Content>
        </Wrapper>
    )
}

export default PanelDB;