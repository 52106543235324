import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.ul)`
    margin: 0;
    padding: 0;
    list-style: none;
    display: table;
    width: 100%;
    

    li.striped:nth-child(odd) div.c-data{
        background: var(--appBgTextColor);
    }
    li.th{
        span{
            font-weight: 700;
            text-transform: capitalize;
        }
    }
    

`;