import React, { useEffect, useState, useMemo, Fragment, useCallback } from "react";

import Loader from 'components/app/school/SmallerComponents/loader';
import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from "components/preloaderLine";

import Notification from "components/app/school/SmallerComponents/notification";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import View from 'components/app/school/SmallerComponents/mallet/view';

import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { appServices } from "api/schoolApp/services";


const AssignRoles = ({data, school_id}) => {
    const initialState = useMemo(() => ({id:0, uid:'', email:'', school_uid:'', staff:{staff:{id:0, gender:''}, staff_cert_type:{id:0}, staff_type:{id:0, name:''}, school:{id:0}, school_branch:{name:'', id:0},}, session_added:{id:0, term:{id:0}}}), []);
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPermState = useMemo(() => ({is_form_master:false, is_add_score:true, is_exam_question_setter:true, is_examiner:false, is_library_attendant:false, is_student_data:true, is_subject_curriculum:false}), []);


    const [allClassItem, setAllClassItem] = useState([]);

    const [state, setState] = useState(initialState);
    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [staffPermState, setStaffPermState] = useState(initialPermState);

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(true);
    
    const [fmaster, setFMaster] = useState(false);
    const [fmasterDisp, setFMasterDisp] = useState(false);

    const handleFMaster = (type="", value=false, class_val=0, class_checked=false) => {
        setLoading(true);
        setFMaster(false);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(type==="class" && class_val){
            appServices.updateStaffSchoolFormMaster(class_val, {is_checked:class_checked}).then(res => {
                setFMaster(true);
                setLoading(false);
                fetchStaffPerm(state.uid, initialPermState);
                setSuccess({title:'Success', text:'Class Successful'})
            }).catch(e => {
                setError({title:'Form Master Class Data Error', data:[e.response.data.detail]});
                setLoading(false);
                setFMaster(false);
                fetchStaffPerm(state.uid, initialPermState);
            })
        }
        if(type==="fm"){
            appServices.manageStaffSchoolFormMaster(state.uid, school_id).then(res => {
                setFMaster(true);
                setLoading(false);
                setStaffPermState({...staffPermState, is_form_master:!value});
                // setSuccess({title:'Assigned Successfully', text:'Role Assigned To Staff Successfully'})
            }).catch(e => {
                setError({title:'Form Master Data Error', data:[e.response.data.detail]});
                setLoading(false);
                setFMaster(false);
                setStaffPermState({...staffPermState, is_form_master:!value});
            })
        } else {
            appServices.manageStaffSchoolFormMaster(state.uid, school_id).then(res => {
                setFMaster(true);
                setLoading(false);
                setStaffPermState({...staffPermState, is_form_master:!value});
                // setSuccess({title:'Unassigned Successfully', text:'Role Unassigned Successfully'})
            }).catch(e => {
                setFMaster(false);
                setError({title:'Form Master Data Error', data:[e.response.data.detail]});
                setLoading(false);
                setStaffPermState({...staffPermState, is_form_master:!value});
            })
        }
    }

    const handleChanges = (e, value) => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(e.target.name !== "is_form_master"){
            setStaffPermState({...staffPermState, [e.target.name]:value});
            appServices.updateStaffSchoolAccessPerm(state.uid, {[e.target.name]:value}).then(res => {
                setLoading(false);
                setSuccess({title:value ? 'Assigned Successfully' : 'Unassigned Successfully', text:value ? 'Role Assigned To Staff Successfully' : 'Role Unassigned Successfully'})
            }).catch(e => {
                setLoading(false);
                setStaffPermState({...staffPermState, [e.target.name]:!value});
                setError({title:'Error', data:[e.response.data.detail]});
            })
        }
    }

    const fetchStaffPerm = (uid="", initialPermState) => {
        if(uid){
            setLoading(true);
            setPreloading(true);
            appServices.getStaffSchoolAccessPerm(uid).then(res => {
                setLoading(false);
                setPreloading(false);
                setStaffPermState({...initialPermState, ...res.data})
            }).catch(e => {
                setStaffPermState(initialPermState);
                setPreloading(false);
                setLoading(false);
                setError({title:'Error', data:['Internal Server Error']});
            })        
        }
    }

    const fetchAllClasses = useCallback(() => {
        setFMasterDisp(true);
        appServices.getAllStaffSchoolFormMaster(state.uid, school_id).then(res => {
            setFMasterDisp(false);
            setAllClassItem([...res.data]);
        }).catch(e => {
            setFMasterDisp(false);
            setAllClassItem([]);
        })
    }, [state.uid, school_id])

    console.log(staffPermState)

    useEffect(() => {
        setState(data);
    }, [data, school_id]);

    useEffect(() => {
        fetchStaffPerm(state.uid, initialPermState);
    }, [state.uid, initialPermState])
    useEffect(() => {
        if(fmaster) {fetchAllClasses();}
    }, [fmaster, fetchAllClasses]);


    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })


    return(
        <Fragment>
            {preloading && <PreloaderLine />}
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {success.text && (<Notification type="success" timer={1000 * 15} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            {state.staff.staff_type.name === "teacher" && (

            <Section>
                {loading && <Loader />}
                <span className="font-med fw-700 disp-block app-text-color">Assign Roles</span>

                <Mallet>
                    <View type="text" classname="font-slightly-small fw-600" flex={20}><span style={{cursor:'pointer'}} onClick={() => {setFMaster(!fmaster);}} className="app-bg-color-text">Form Master</span></View>
                    <View type="text" flex={10}><input type={"checkbox"} onChange={() => handleFMaster("fm", !staffPermState.is_form_master)} value={staffPermState.is_form_master} checked={staffPermState.is_form_master} /></View>
                    <View type="text" classname="font-very-small" flex={'70-line'}><i>This allows the staff to be able to take attendance of student, access student's current term exam result and view student's performance scale</i></View>
                    {fmaster && (
                    <div className="flex">
                        {fmasterDisp && <Spinner />}
                        {allClassItem.length > 0 && (
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH style={{width:'100%'}}>Subject</TH>
                                    <TH>Action</TH>
                                </TR>
                            </thead>
                            <tbody>
                                {allClassItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <TR>
                                                <TD>{item.class_name}</TD>
                                                <TD><input type={"checkbox"} onChange={() => handleFMaster("class", false, item.id, !item.is_checked)} checked={item.is_checked} value={item.id} /></TD>
                                            </TR>
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        </TABLE>
                        )}
                    </div>
                                 
                    )}

                    <View type="text" classname="font-slightly-small fw-600" flex={20}>Examiner</View>
                    <View type="text" flex={10}><input type={"checkbox"} onChange={(e) => handleChanges(e, !staffPermState.is_examiner)} name="is_examiner" value={staffPermState.is_examiner} checked={staffPermState.is_examiner}  /></View>
                    <View type="text" classname="font-very-small fw-500" flex={'70-line'}><i>Staff can supervise ongoing exams</i></View>

                    <View type="text" classname="font-slightly-small fw-600" flex={20}>Library Attendant</View>
                    <View type="text" flex={10}><input type={"checkbox"} onChange={(e) => handleChanges(e, !staffPermState.is_library_attendant)} name="is_library_attendant" value={staffPermState.is_library_attendant} checked={staffPermState.is_library_attendant} /></View>
                    <View type="text" classname="font-very-small fw-500" flex={'70-line'}><i>Staff gives access of library to students throught the voskool web app and also take care of the books in the library</i></View>

                    <View type="text" classname="font-slightly-small fw-600" flex={20}>Subject Curriculum</View>
                    <View type="text" flex={10}><input type={"checkbox"} onChange={(e) => handleChanges(e, !staffPermState.is_subject_curriculum)} name="is_subject_curriculum" value={staffPermState.is_subject_curriculum} checked={staffPermState.is_subject_curriculum} /></View>
                    <View type="text" classname="font-very-small fw-500" flex={'70-line'}><i>Staff will input and manage the termly subject curriculum offered by the school through the voskool web app from the assigned subjects</i></View>

                    <View type="text" classname="font-slightly-small fw-600" flex={20}>Students Data</View>
                    <View type="text" flex={10}><input type={"checkbox"} defaultChecked={staffPermState.is_student_data} disabled  /></View>
                    <View type="text" classname="font-very-small fw-500" flex={'70-line'}><i>Staff has access to student's data from the assigned subject</i></View>

                    <View type="text" classname="font-slightly-small fw-600" flex={20}>Exam, Test Question Set</View>
                    <View type="text" flex={10}><input type={"checkbox"} defaultChecked={staffPermState.is_exam_question_setter} disabled  /></View>
                    <View type="text" classname="font-very-small fw-500" flex={'70-line'}><i>Staff can set exam questions from the assigned subject</i></View>

                    <View type="text" classname="font-slightly-small fw-600" flex={20}>Adding Scores</View>
                    <View type="text" flex={10}><input type={"checkbox"} defaultChecked={staffPermState.is_add_score} disabled  /></View>
                    <View type="text" classname="font-very-small fw-500" flex={'70-line'}><i>Staff can add scores of student from the assigned subject</i></View>
                </Mallet>
            </Section>
            )}
        </Fragment>
    )
}

export default AssignRoles;