import React, { useState, useEffect, useMemo, useCallback, Fragment} from 'react';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';
import THDrop from 'components/app/school/SmallerComponents/table_html/th/menuDrop';
import Note from 'components/app/school/SmallerComponents/NOTE';

import { ComponentWrapper} from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import BlankLoader from "components/blankLoader";
import Loader from 'components/app/school/SmallerComponents/loader';
import Spinner from 'components/app/school/SmallerComponents/spinner';

import { BACKEND_URL } from 'actions/url';
import { HeadComponent } from "components/head";
import { appServices, appServices4, appServices5 } from 'api/schoolApp/services';
import { timify } from 'components/app/school/actions/timify';
import { Link } from 'react-router-dom';

import { addItemToArray, addItemToArrayAndInnerArray, addItemToArrayOnly, addArrayWithArray } from 'components/app/school/actions/array-utils/Add';
import { updateArray, updateinnerArray } from 'components/app/school/actions/array-utils/Update';
import { deleteItemFromInnerArray } from 'components/app/school/actions/array-utils/Delete';

const StaffSchoolLiveTimetable = ({school_id, user_id, user_type, access}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialTTTState = useMemo(() => ({start_time:'', end_time:'', uid:'', id:0, start_time_disp:false, end_time_disp:false, num:-1, class_section:''}), []);
    const initialSessionState = useMemo(() => ({id:0, curr_sess_id:0, curr_sess:'', curr_term:'', session:'', term:''}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const [allDays, setAllDays] = useState([]);
    const [allClassSectionData, setAllClassSectionData] = useState([]);
    const [allTimeTTData, setAllTimeTTData] = useState([]);
    const [allSubjectData, setAllSubjectData] = useState([]);
    const [allTimeTableData, setAllTimeTableData] = useState([{subject:[]}]);

    const [TTTState, setTTTState] = useState([]);
    const [TimeTableState, setTimeTableState] = useState([]);
    const [sessionState, setSessionState] = useState(initialSessionState);

    const [preloading, setPreloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);

    const fetchAllWeekday = useCallback(() => {
        if(school_id && sessionState.curr_sess_id){
            setPreloading(true);
            appServices.getAllSchoolWeekDayData(school_id).then(day_res => {
                appServices5.getAllClassSectionSchool(school_id).then(res => {
                    setAllDays(addArrayWithArray(res.data, day_res.data,  "class_num"));
                    setTimeout(() => {
                        setAllClassSectionData(res.data);
                    }, 200)
                }).catch(e => {
                    setAllClassSectionData([]);
                    setError({data:["Internal Server Error"], title:"Class Section Fetch Error"});
                    setPreloading(false);
                })
                // 
            }).catch(e => {
                setAllDays([]);
                setError({title:'Day Fetch Error', data:['Internal Server Error']});
            })
        }
    }, [school_id, sessionState.curr_sess_id]);

    const fetchAllTimeTableTime = useCallback(() => {
        if(school_id && sessionState.curr_sess_id){
            appServices.getAllSchoolTimeTableTimeData(school_id, sessionState.curr_sess_id).then(res => {
                setAllTimeTTData(addItemToArray(res.data));
                setTTTState(addItemToArray(res.data))
            }).catch(e => {
                setPreloading(false);
                setAllTimeTTData([]);
                setError({title:'Timetable Time Fetch Error', data:['Internal Server Error']});
            })
        }
    }, [school_id, sessionState.curr_sess_id])

    const fetchAllTimeTable = useCallback(() => {
        if(school_id && sessionState.curr_sess_id){
            appServices.getAllSchoolTimeTableData(school_id, sessionState.curr_sess_id).then(res => {
                setAllTimeTableData(addItemToArray(res.data));
                setTimeout(() => {
                    setPreloading(false)
                }, 300);
            }).catch(e => {
                setPreloading(false);
                setAllTimeTableData([]);
                setError({title:'Timetable Fetch Error', data:['Internal Server Error']});
            })
        }
    }, [school_id, sessionState.curr_sess_id]);

    const fetchAllSubject = (sid) => {
        if(sid){
            appServices.getAllSubjectSchool(sid, "", "", "name").then(res => {
                setAllSubjectData([...res.data]);
            }).catch(e => {
                setAllSubjectData([]);
                setError({title:'Subject Fetch Error', data:['Internal Server Error']});
            })
        }
    }

    const fetchSchoolSession = (sid) => {
        if(sid){
            appServices5.getCurrentSession(sid).then(res => {
                setSessionState({...res.data, curr_sess:res.data.session, curr_sess_id:res.data.id, curr_term:res.data.term_name});
            }).catch(e => {
                setSessionState({id:0, session:'', term:''});
                setError({title:'School Session Fetch Error', data:['Internal Server Error']});
            })
        }
    }

    console.log(allClassSectionData)

    useEffect(() => {
     
            fetchAllWeekday();
            fetchSchoolSession(school_id);
            fetchAllTimeTableTime();
            fetchAllTimeTable();
            // fetchAllSchoolSession();
            fetchAllSubject(school_id);
            setReload(false);
        
    }, [school_id, reload, fetchAllWeekday, fetchAllTimeTableTime, fetchAllTimeTable]);

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const insertTableTDData = (class_num=0, class_id=0) => {
        if(!allDays.length){
            return(<TR><TD classname={"align-center red"}>No Record Found</TD></TR>)   
        } else {
            const filterDay = allDays.filter((item) => {
                return item.class_num === class_num;
            })
            const filterSubject = allSubjectData.filter((item) => {
                return item.class_section === class_id;
            })
            const currentDay = new Date().getDay()
            return (filterDay.map((item, i) => {
                const filterTimeTable = allTimeTableData.filter((item2) => {
                    return item2.week_day===item.id && item2.class_section_id===class_id;
                })
                return(
                    <Fragment key={i}>
                        <TR>
                            <TD><span className='fw-700'>{item.name.slice(0, 4)}</span></TD>
                            {filterTimeTable.map((item_tt, ii) => {
                                const tot_sub_len_min = item_tt.subject.length - 1;
                                const styleTT = () => {
                                    var return_value;
                                    const currentTime = new Date().getTime();
                                    const start_time = new Date(new Date().setHours(parseInt(item_tt.start_time.slice(0,2)), parseInt(item_tt.start_time.slice(3,5)), parseInt(item_tt.start_time.slice(6,8)))).getTime();
                                    const end_time = new Date(new Date().setHours(parseInt(item_tt.end_time.slice(0,2)), parseInt(item_tt.end_time.slice(3,5)), parseInt(item_tt.end_time.slice(6,8)))).getTime();
                                    if(currentDay === item_tt.day_order ){
                                        return_value = {background:'red', color:'#eee'};
                                        if(start_time > currentTime){
                                            return_value = undefined;
                                        }
                                        else if(start_time < currentTime && end_time > currentTime){
                                            return_value = {background:'green', color:'#eee'}
                                        }
                                    }
                                    return return_value;
                                    
                                }  
                                
                                return(
                                    <Fragment key={ii}>
                                        <TD style={styleTT()}>
                                            <div className='form-group'>
                                                {item_tt.subject.map((item_sub, iii) => { 
                                                    return(
                                                        <Fragment key={iii}>
                                                            <span className='font-super-small app-text-color' style={styleTT()}>{item_sub.sub_name}</span>   
                                                            <span className='font-slightly-small fw-700'>{tot_sub_len_min === iii ? '' : '/'}</span>
                                                        </Fragment>
                                                    )
                                                })}
                                            </div>
                                            
                                        </TD>
                                    </Fragment>
                                )
                            })}
                        </TR>
                    </Fragment>
                )
            }))
        }
        
    }
    const insertTimeTableData = () => {
        return (allClassSectionData.map((class_item, i) => {
            const filterTimeItem = allTimeTTData.filter((item) => {
                return item.class_section === class_item.id;
            })
            const filterTimeItemState = TTTState.filter((item) => {
                return item.class_section === class_item.id;
            })
            return(
                <Fragment key={i}>
                    <div className='flex-100 align-center padd-4px'><span className='app-text-color fw-700 font-small '>{class_item.name.toUpperCase() } SECTION</span></div>
                    <div className='flex-100' style={{width:'0'}}>
                        <TABLE>
                            <thead>
                                <TR>
                                    <TH>Day / Time</TH>      
                                    {filterTimeItem.map((item, j) => {
                                        return(
                                            <Fragment key={j}>
                                                <TH>
                                                    <div className='form-group'>
                                                        <code className='font-very-small fw-700 app-bg-color-text'>{timify(item.start_time)}</code>
                                                        - 
                                                        <code className='font-very-small fw-700 app-bg-color-text' >{timify(item.end_time)}</code>
                                                    </div>
                                                </TH>
                                            </Fragment>
                                        )
                                    })}
                                        
                                </TR>
                            </thead>
                            <tbody>
                                {insertTableTDData(i, class_item.id)}
                            </tbody>
                        </TABLE>
                    </div>
                </Fragment>
            )
        }))
    }

    return(
        <>
            <HeadComponent title='Live Timetable' />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {/* {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)} */}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> 
                <Grid layout='lauto'>
                    
                        <GridContent header_text={`Manage School Timetable`} header_icon="fas fa-align-right">
                        <Section>
                            {/* <Mallet> */}
                            {loading && <Loader />}
                            {preloading ? (<ul>
                            <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                            <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                {allClassSectionData.length > 0 ? insertTimeTableData() : <div className='flex-100 align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}
                                
                            </div>
                            </>)}
                        </Section>
                        </GridContent>
                </Grid>
            </ComponentWrapper>
        </>
    )
}


export default StaffSchoolLiveTimetable;