import styled from "styled-components";

export const Wrapper = styled.div`
    margin: 5% auto;
    padding: 0;
    width: 70%;

    @media(max-width: 500px){
        width: 90%;
    }
    /* background: #fff; */
`;

export const Content = styled.div`
    text-align: center;
    padding: 10px 15px;

    .logo-container{
        padding:10px 0;
        
        .logo{
            width: 150px;
            height: 50px;
        }
    }

    .h-t.err{
        border: 2px solid #db0000;
    }
    .h-t.suc{
        border: 2px solid #00db00;
    }
    .h-t{
        padding: 4px;
        img.success{
            width: 50px;
            height: 50px;
        }
        /* span.err.h{
            border: 2px solid #db0000;
            border-radius: 50%;
            padding: 10px;
        } */
        span.h{
            font-size: 2.5rem;
            font-weight: 500;
        }
        span.err{
            color: #db0000;
        }
    }
    .i-404-container{
        .i-404{
            width: 400px;
            height: 200px;
            opacity: 0.7;

            @media(max-width: 780px){
                width: 90%;
            }
        }
    }
    .title{
        position: relative;

        h2.success{
            color: #00db00;
        }
        h2{
            font-size: 1.8rem;
            font-weight: 600;
            color: #777;
        }
    }
    .text{
        h4{
            font-size: 1.2rem;
            color: #011;
            font-weight: 500;
        }
    }
    .link-container{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        a.bg, button.bg{
            background: var(--voskoolBagColor);
            color: #eee;
        }
        a.br, button.br{
            border: 1px solid var(--voskoolBagColor); 
            background  : #fff;
        }
        a, button{
            color: #000;
            text-decoration: none;
            padding: 10px;
            font-weight: 600;
            border-radius: 2px;
            font-size: 1rem;
            transition: .3s ease;
            cursor: pointer;
            :hover{
                opacity: .9;
            }
        }
    }
`;