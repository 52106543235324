import React from "react";
import { Wrapper } from "./style";

const Section = ({header, icon, headerBgColor="app-bg-color", headerColor="app-bg-text-color", children, id=""}) => {
    return(
        <Wrapper initial={{opacity:0}} animate={{duration:0.4, opacity: 1}} id={id}>
            {header && <div className={`header ${headerBgColor}`}>
                <span className={`${headerColor} font-slightly-small`}>{icon && <i className={icon}></i>} {header}</span></div>}
            <div className="content">
                {children}
            </div>
        </Wrapper>
    )
}

export default Section;