import React, { useEffect, useMemo, useState, Fragment } from "react";
import { AnimatePresence } from 'framer-motion';
import { appServices } from "api/schoolApp/services";
import BlankLoader from "components/blankLoader";
import { HeadComponent } from "components/head";
import Section from "components/app/school/SmallerComponents/Section";
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import MenuDrop from "components/app/school/SmallerComponents/menuDrop";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Accordion from "components/app/school/SmallerComponents/accordion";
import AccordionFormData from "components/app/school/SmallerComponents/accordionFormData";
import Notification from "components/app/school/SmallerComponents/notification";
import { ComponentWrapper } from "../wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";
import Loader from "components/app/school/SmallerComponents/loader";
import BreadCrumb from "components/breadCrumb";
import Note from "components/app/school/SmallerComponents/NOTE";

import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Session = ({school_id, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialSessionFormState = useMemo(() => ({session:null, year:null, term_select:null, res_date:null, interview_start:null, interview_end:null, vac_date:null }), []);
    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [accordion, setAccordion] = useState(-2);
    const [formSession, setFormSession] = useState(initialSessionFormState);
    // const [formTerm, setFormTerm] = useState({ term:"", term_select:""});
    const [allTermData, setAllTermData] = useState([{id:0, term:""}])
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState("");
    const [menuDropData, setMenuDropData] = useState({id:''});
    const [menuDrop, setMenuDrop] = useState(false)
    const [allSessionData, setAllSessionData] = useState([]);
    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [anim, setAnim] = useState({initial:null, animate:null, exit:null, display:null, id:0})
    const [accordionState, setAccordionState] = useState([{text:"", result:[{
        contentClass:"", type:"", name:"", options:[], selected:"none", tag:null, btn_text:"", placeholder:"", classname:"", required:false, disabled:false
    }]}])

    
    const handleAccordionClick = (num) => {
        if(accordion !== num){
            setAccordion(num);
        }
        else{
            setAccordion(-2);
        }
    } 
    
    const getAllTerm = (school_id) => {
        appServices.getAllTerm(school_id).then(res => {
            setAllTermData(res.data);
        }).catch(e => {
            setError({data:[e.response.data], title:"Data Error"});
        })
    }
    const getAllSession = (school_id) => {
        setPreloading2(true);
        appServices.getAllSession(school_id).then(res => {
            const curr_sess = res.data.length >0 ? res.data.filter((it) => it.is_active && it.active_session)[0] : {id:0, term_name:''};
            const curr_only_session = curr_sess.term_name !== res.data[res.data.length-1] ? curr_sess.session : '';
            setFormSession((prev) => typeof prev === "object" ? {...prev, session:curr_only_session} : prev);
            setAllSessionData(res.data);
            setPreloading2(false);
        }).catch(e => {
            setAllSessionData([]);
            setError({data:[e.response.data], title:"Data Error"});
            setPreloading2(false);
        })
    }
    // const getTerm = (id, school_id) => {
    //     appServices.getTerm(id, school_id).then(res => {
    //         setFormTerm({term:res.data.term});
    //     }).catch(e => {
    //         setError({data:[e.response.data], title:"Data Error"});
    //     })
    // }
    // const delayPreloader = (type) => {
    //     setLoading(type);
    //     setTimeout(() => {
    //         setLoading("");
    //     }, 1000);
    // }
    
    useEffect(() => {
        getAllTerm(school_id)
        getAllSession(school_id);
    }, [school_id])

    // useEffect(() => {

    // }, [])
    
    const arr = ['first', 'second', 'third']
    const curr_sess = allSessionData.length >0 ? allSessionData.filter((it) => it.is_active && it.active_session)[0] : {id:0, term_name:''};
    const check_session = menuDropData.session ? allSessionData.filter((it) => it.session === menuDropData.session) : [];
    const check_session_exist = check_session.length > 1 ? true : false;
    const all_term = curr_sess.term_name !== arr[arr.length-1] ? allTermData.filter((it) => it.term !== curr_sess.term_name) : allTermData;
    const curr_only_session = curr_sess.term_name !== arr[arr.length-1] ? curr_sess.session : '';
    useEffect(() => {
        // var disableTag = false;
        
        // const handleFormChange = (e) => {
        //     setFormTerm({...formTerm, [e.target.name]:e.target.value})
        // }
        // const vac_month = formSession.vac_date.getMonth() + 1
        // const vac_date = formSession.vac_date.getFullYear() + "-0" + vac_month + "-0" + formSession.vac_date.getDate()
        const handleFormSessionChange = (e=null, name=null, date=Date) => {
            if(name){
                
                const value = date ? new Date(date) : null;
                const n = name;
                setFormSession({...formSession, [n]:value}) 
            }
            else{            
                setFormSession({...formSession, [e.target.name]:e.target.value})    
                   
            }
        }
        // if(formTerm.term_select){
        //     disableTag =false;
        // }
        // else{
        //     disableTag = true;    
        // }
        // const handleTermUpdate = (e) => {
        //     setSubmitted(false);
        //     setLoading("formTerm");
        //     setError({...initialErrorState});
        //     setSuccess({...initialSuccessState});
        //     e.preventDefault();
        //     const data = {
        //         term: formTerm.term
        //     }
        //     appServices.updateTerm(formTerm.term_select, data).then(() => {
        //         setFormTerm({...formTerm, term:""});
        //         setSuccess({text:"Term Updated Successfully", title:"Success"});
        //         setSubmitted(true);
        //         getAllTerm(school_id);
        //         setLoading("");
        //     }).catch(e => {
        //         setSuccess({...initialSuccessState});
        //         setError({data:[e.response.data.term], title:"Term Error"})
        //         setLoading("");
                
        //     })
        // }
        // const handleTermDelete = (e) => {
        //     setSubmitted(false);
        //     setLoading("formTerm");
        //     setError({...initialErrorState});
        //     setSuccess({...initialSuccessState});
        //     e.preventDefault();
        //     if(formTerm.term_select){
        //         appServices.deleteTerm(formTerm.term_select).then(() => {
        //             setSuccess({text:"Term Deleted Successfully", title:"Success"});
        //             setSubmitted(true);
        //             setLoading("");
        //             getAllTerm(school_id);
        //         }).catch(e => {
        //             setSuccess({...initialSuccessState});
        //             console.log(e.response.data);
        //             setError({data:[], title:"Delete Term Error"})
        //             setLoading("");
                    
        //         })
        //     }
        //     else{
        //         setError({data:["Term Must Be Selected"], title:"Delete Term Error"})
        //         setLoading("");
        //     }
            
        // }
        // const handleTermSubmit = (e) => {
        //     setSubmitted(false);
        //     setLoading("formTerm");
        //     setError({...initialErrorState});
        //     setSuccess({...initialSuccessState});
        //     e.preventDefault();
        //     const data = {
        //         school: school_id,
        //         term: formTerm.term
        //     }
        //     appServices.insertTerm(data).then(response => {
        //         setFormTerm({term:""});
        //         setSuccess({text:"Term Added Successfully", title:"Success"});
        //         setSubmitted(true);
        //         setLoading("");
        //         getAllTerm(school_id);
        //     }).catch(e => {
        //         if(e.response.data.term){
        //             setSuccess({...initialSuccessState});
        //             setError({data:[e.response.data.term], title:"Term Error"})
        //             setLoading("");
        //         }
        //     })
        // }
        const handleSessionSubmit = (e) => {
            setSubmitted(false);
            setLoading("formTerm");
            setError({...initialErrorState});
            setSuccess({...initialSuccessState});
            
            e.preventDefault();
            const data = {
                session: curr_only_session ? curr_only_session : formSession.session,
                school:school_id,
                year: formSession.year.getFullYear(),
                term: formSession.term_select,
                res_date: convertDate(formSession.res_date, 'yyyy-mm-dd') ,
                vac_date: convertDate(formSession.vac_date, 'yyyy-mm-dd'),
                interview_start: convertDate(formSession.interview_start_date, 'yyyy-mm-dd'),
                interview_end: convertDate(formSession.interview_end_date, 'yyyy-mm-dd'),
                created_by:user_id
            }
            appServices.insertSession(data).then(response => {
                setFormSession({session:"", year:"", term_select:"", res_date:"", vac_date:"", interview_start_date:'', interview_end_date:''});
                setSuccess({text:"Session Submitted Successfully", title:"Success"});
                setSubmitted(true);
                setLoading("");
                // getAllTerm(school_id);
                getAllSession(school_id);
            }).catch(e => {
                setSuccess({...initialSuccessState});
                setLoading("");
                setError({data:[e.response.data.detail], title:"Session Error"})
                
            })
        }
        const data = [{text: "Create Session", handleSubmit: (e) => handleSessionSubmit(e), result:[
                      {handleChange: (e)=> handleFormSessionChange(e), value:curr_only_session ? curr_only_session : formSession.session, name:"session", classname:"form-control font-slightly-small app-text-color", type:"text", tag:"input", placeholder:"Enter Session e.g 2022/2023", readOnly:curr_only_session ? true :false, required:curr_only_session ? false :true}, 
                      {handleChange: (date)=> handleFormSessionChange("", "year", date), showYearPicker:true, dateFormat:"yyyy", name:"year", selected_date: formSession.year, classname:"form-control font-slightly-small app-text-color", type:"date", tag:"input", required:true, placeholder:"Enter Current Year"}, 
                      {handleChange: (e)=> handleFormSessionChange(e), selected:formSession.term_select, value:formSession.term_select, tag:"select", name:"term_select", classname:"text-capitalize form-control font-slightly-small app-text-color", required:true, options:[{text:"Select Term", value:""}]},
                      {handleChange: (date)=> handleFormSessionChange("", "interview_start_date", date), dateFormat:"yyyy-MM-dd",  name:"interview_start_date",  selected_date: formSession.interview_start_date, classname:"form-control font-slightly-small app-text-color", type:"date", tag:"input", placeholder:"Student Enrollment Start Date*"},
                      {handleChange: (date)=> handleFormSessionChange("", "interview_end_date", date), dateFormat:"yyyy-MM-dd",  name:"interview_end_date",  selected_date: formSession.interview_end_date, classname:"form-control font-slightly-small app-text-color", type:"date", tag:"input", placeholder:"Student Enrollment End Date*"},
                      {handleChange: (date)=> handleFormSessionChange("", "res_date", date), dateFormat:"yyyy-MM-dd",  name:"res_date",  selected_date: formSession.res_date, required:true, classname:"form-control font-slightly-small app-text-color", type:"date", tag:"input", placeholder:"Enter Resumption Date"},
                      {handleChange: (date)=> handleFormSessionChange("", "vac_date", date), dateFormat:"yyyy-MM-dd",  name:"vac_date",  selected_date: formSession.vac_date, classname:"form-control font-slightly-small app-text-color", type:"date", tag:"input", placeholder:"Enter Vacation Date*"}, 
                      {handleChange: (date)=> handleFormSessionChange("", "scores_entry_end", date), disabled:true, dateFormat:"yyyy-MM-dd",  name:"scores_entry_end",  selected_date: formSession.scores_entry_end, classname:"form-control font-slightly-small app-text-color", type:"date", tag:"input", placeholder:"Enter Staff Scores Entry End Date*"}, 
                      { contentClass:"align-center", classname:"font-slightly-small app-bg-text-color mybtn app-bg-color", type:"submit", btn_text:"Submit", btn_icon:"fas fa-paper-plane", tag:"btn"}
                    ]}, 
        //                 {handleSubmit: (e) => handleTermSubmit(e),  text: "Create Term", 
        //         result:[{handleChange: (e) => handleFormChange(e), name:"term", value:formTerm.term, classname:"form-control font-slightly-small app-text-color", type:"text", tag:"input", placeholder:"Enter Term", required:true},
        //         { contentClass:"align-center", classname:"font-slightly-small app-bg-text-color mybtn app-bg-color", type:"submit", btn_text:"Save", btn_icon:"fas fa-paper-plane", tag:"btn"}
        //         ]},
        //         {text:"Edit Term", handleSubmit: (e) => handleTermUpdate(e), result:[{handleChange: (e) => handleFormChange(e), name:"term_select", tag:"select", classname:"form-control font-slightly-small app-text-color  text-capitalize", required:true, options:[{text:"Select Term", value:""}] },
        //          {handleChange: (e) => handleFormChange(e), name:"term", value:formTerm.term, classname:"form-control font-slightly-small app-text-color", type:"text", tag: "input", disabled:disableTag,  placeholder:"Edit Term", required:true},
        //          { contentClass:"align-center", classname:"font-slightly-small app-bg-text-color mybtn app-bg-color", type:"submit", btn_text:"Update ", btn_icon:"fas fa-paper-plane", tag:"btn"}
        //         ]},
        //         {handleSubmit: (e) => handleTermDelete(e), text: "Delete Term", result:[{handleChange: (e) => handleFormChange(e), tag:"select", name:"term_select", classname:"text-capitalize form-control font-slightly-small app-text-color", required:true, options:[{text:"Select Term", value:""}]
        //         }, { contentClass:"align-center", classname:"font-slightly-small bg-red app-bg-text-color mybtn app-bg-color", type:"submit", btn_text:"Delete", btn_icon:"fas fa-trash", tag:"btn"}]
        // }
        ]
        setAccordionState([...data]);
        setPreloading(false);
    }, [formSession, curr_only_session, initialSessionFormState, initialErrorState, initialSuccessState, school_id, user_id])
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const termDataOption = all_term.map((item, index) => {
        return(
            <option className="text-capitalize" value={item.id} key={item.id}>{item.term}</option>
        )
    })
    const accordionData = accordionState.map((item, index) => {
        const accordionInnerData = item.result.map((res, i) => {
            return(
                <Fragment key={i}>
                <AccordionFormData key={i} readonly={res.readOnly} showYearDropdown={res.showYearDropdown} showYearPicker={res.showYearPicker} 
                selected={res.selected} selected_date={res.selected_date} 
                dateFormat={res.dateFormat} required={res.required} disabled={res.disabled} 
                contentClass={res.contentClass} btn_text={res.btn_text} btn_icon={res.btn_icon} 
                classname={res.classname} type={res.type} tag={res.tag} placeholder={res.placeholder}
                options={res.options} name={res.name} value={res.value} handleChange={res.handleChange}
                >
                    {i === 2 && index === 0 &&  termDataOption}
                    {i === 0 && index === 3 &&  termDataOption}
                    {i === 0 && index === 2 &&  termDataOption}
                </AccordionFormData>
                </Fragment>
            )
        }) 
        return(
        <Fragment key={index}>
            <Accordion handleSubmit={item.handleSubmit} key={index} text={item.text} handleClick={() => handleAccordionClick(index)}
             index={index} currentAccordion = {accordion}>
            {accordion === index && accordionInnerData 
            }
            
            </Accordion>
        </Fragment>    
    )})

    const handleEditSession = (e, data) => {
        setMenuDrop(true);
        setMenuDropData(data);
    }
    // const handleUndoDeleteSession = (id) => {
    //     setSubmitted(false);
    //     setLoading("right");
    //     setError({...initialErrorState});
    //     setSuccess(initialSuccessState);
    //     appServices.deleteSession(id, "undo").then(res => {
    //         setAnim({initial:{opacity: 0, duration:0.4},
    //             animate:{opacity:1, duration:0.4, background:""},
    //             id:id })
    //         setSuccess({text:"Undo Successfull", title:"Success"}) 
    //         setLoading("");
    //         getAllSession(school_id);
    //     })
    //     .catch(e => {
    //         console.log(e.response);
    //         setSuccess({...initialSuccessState});
    //         setLoading("");
    //         setError({data:[e.response.data.detail], title:"Undo Delete Session Error"})
    //     })
    // }
    const handleDeleteSession = (e, id) => {
        e.preventDefault();
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess(initialSuccessState);
        appServices.deleteSession(id).then(res => {
            setAnim({initial:{opacity: 1, duration:0.4},
            animate:{opacity:0, duration:0.4, background:"#ff0000"},
            id:id })
            setSuccess({text:"Data Deleted Successfully", title:"Success"});
            setLoading("");
            setTimeout(() => {
                getAllSession(school_id);
            }, 500)
        }).catch(e => {
            setSuccess({...initialSuccessState});
            setLoading("");
            setError({data:[e.response.data.detail], title:"Delete Session Error"})
        })
    }
    const sessionData = allSessionData.map((item, i) => {
        i++;
        const data = {i:i, id:item.id, term:item.term, session:item.session, year:new Date(item.year), interview_start:item.interview_start, interview_end:item.interview_end, 
            res_date:item.res_date ? new Date(item.res_date) : '', vac_date:item.vac_date, scores_entry_end:item.scores_entry_end, status:item.active_session, type:"session_form"}
        return(
            <Fragment key={i}>
                <Tr style={item.id === anim.id && {display:anim.display}} sn={true} edit_btn={item.active_session  ? true : false} dlt_btn={item.active_session  ? true : false} animation={item.id === anim.id && {initial:{...anim.initial}, animate:{...anim.animate}}}
                handleDeleteClick={e => handleDeleteSession(e, item.id)}
                handleEditClick={e => handleEditSession(e, data)}>
                    <div className="c-data sn"><span className="font-very-small">{i}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.session}</span></div>
                    <div className="c-data"><span className="font-very-small text-capitalize">{item.term_name}</span></div>
                    <div className="c-data"><span className="font-very-small">
                        {item.active_session ? (<i className="fas fa-check green"> Active</i>) : (<i className="fas fa-times red"> Inactive</i>)}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.year}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.res_date}</span></div>
                    <div className="c-data"><span className="font-very-small">{item.vac_date}</span></div>

                    <TdWrapper header_text={item.session} header_num={i}>
                        <Td heading="Session" text={item.session} />
                        <Td heading="Term" text_class="app-text-color text-capitalize" text={item.term_name} />
                        <Td heading="Year" text={item.year} />
                        <Td heading="Enrollment Start Date"  text={convertDate(item.interview_start, "ddMMyyyy")} />
                        <Td heading="Enrollment End Date"  text={convertDate(item.interview_end, "ddMMyyyy")} />
                        <Td heading="Resumption Date"  text={convertDate(item.res_date, "ddMMyyyy")} />
                        <Td heading="Vacation Date"  text={convertDate(item.vac_date, "ddMMyyyy")} />
                        <Td heading="Staff Scores Entry End Date"  text={convertDate(item.scores_entry_end, "ddMMyyyy")} />
                        <Td heading="Created By" text_class="app-text-color text-capitalize"  text={item.created_by_last_name + ' ' + item.created_by_first_name} />
                        <Td heading="Session Status" 
                            text_class={item.active_session ? 'green text-upper' : 'red text-upper'}  text={item.active_session ? "Active" : "Inactive"}
                            text_icon={item.active_session ? "fas fa-check" : "fas fa-times red"} />
                    </TdWrapper>
                </Tr>
            </Fragment>
        )
    })
    const termDataOptionFunc = (data, sel) => { 
        return(data.map((item, index) => {
            return(
                <option className="text-capitalize" value={item.id} key={item.id}>{item.term}</option>
            )
        }))
    }
    const handleFormEditSessionChange = (e=null, name="") => {
        if (name){   
            const n = name;
            const value = e ? new Date(e) : null;
            setMenuDropData({...menuDropData, [n]:value});    
        }
        else{
            setMenuDropData({...menuDropData, [e.target.name]:e.target.value})
        }
        
    }
    const handleSessionUpdate = (e) => {
        setSubmitted(false);
        setLoading("right");
        setError({...initialErrorState});
        setSuccess({...initialSuccessState});
        e.preventDefault();

        const data = {
            active_session: menuDropData.status,
            session: menuDropData.session,
            res_date: convertDate(menuDropData.res_date, 'yyyy-mm-dd'),
            vac_date: convertDate(menuDropData.vac_date, 'yyyy-mm-dd'),
            interview_start: convertDate(menuDropData.interview_start, 'yyyy-mm-dd'),
            interview_end: convertDate(menuDropData.interview_end, 'yyyy-mm-dd'),
            scores_entry_end: convertDate(menuDropData.scores_entry_end, 'yyyy-mm-dd'),
            term: menuDropData.term,
            year: menuDropData.year.getFullYear(),
        }

        appServices.updateSession(school_id, menuDropData.id, data).then(res => {
            setLoading("");
            setError({...initialErrorState});
            setMenuDrop(false)
            setSuccess({text: "Session Updated Successfully", title:"success"})
            submitted && setSubmitted(false)// delayPreloader("view_session");
            setSubmitted(true);
            getAllSession(school_id);
        }).catch(e => {
            setLoading("");
            setSuccess({...initialSuccessState});
            setError({data:[e.response.data.detail], title:"Session Update Error"})
        })
    }
    
    return(
        <>
            <HeadComponent title="Session" />
            <BreadCrumb />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> 
                <Note>The enrollment start and end date is required for enrolling student to the created session </Note> 
                <Note>To end scores entry for staff you can click on the pen icon on the current session and edit the scores entry end date </Note>     
                <Grid>
                    <GridContent header_text="Set Session" header_icon="fas fa-align-left">
                        
                        {/* {loading==="formTerm" && (<Loader />)}
                        {preloading ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : (
                            <>
                                {accordionData}
                            </>
                            )}
                         */}
                        
                        
                    </GridContent>
                    <GridContent header_text="Manage Session" header_icon="fas fa-tasks">
                        
                        <Note>New session created will automatically be marked as active session and there will be no changes until after a month's time </Note>
                        {preloading2 ? (<ul>
                            <BlankLoader num={5} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section icon="fas fa-eye" header="View Session">
                            {loading === "view_session" && <Loader />}
                            <Table>
                                <Tr btn={false} header={true} sn={true} text={[{text:"S/N"}, {text:"Session"}, {text:"Term"}, {text:"Status"}, {text:"Year"}, {text:"res date"}, {text:"vac date"}]}> </Tr>
                                    {sessionData} 
                            </Table>
                            
                        </Section>
                        </>
                        )}
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter={true}
                            onExitComplete={() => null}
                        >
                        { menuDrop && <MenuDrop handleClose={() => setMenuDrop(false)} header_text="Edit" header_icon="fas fa-pen">
                           {menuDropData.type === "session_form" && 
                           (<>
                                {loading === "right" && <Loader />}
                                <form  onSubmit={(e) => handleSessionUpdate(e)}>
                                    <div className='row'>
                                        {!menuDropData.active_session ? (
                                            <div className="fg">
                                                <label className="font-super-small">Session </label><input name="session" disabled={check_session_exist} onChange={(e) => handleFormEditSessionChange(e)} 
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Session" value={menuDropData.session} />
                                            </div>
                                        ) : (
                                            <div className="fg">
                                                <label className="font-super-small">Session </label><input name="session" disabled={true}
                                                type="text" className="font-very-small app-theme form-control-theme" placeholder="Enter Session" defaultValue={menuDropData.session} />
                                            </div>
                                        )}
                                        {!menuDropData.active_session ? (
                                            <div className="fg">
                                                <label className="font-super-small">Term </label>
                                                <select required name="term" disabled={check_session_exist} className="app-theme form-control-theme font-very-small text-capitalize" value={menuDropData.term} onChange={(e) => handleFormEditSessionChange(e)} >
                                                    <option value="">Select Term</option>
                                                    {(termDataOptionFunc(all_term, menuDropData.term))}
                                                </select>
                                                
                                            </div>
                                        ) : (
                                            <div className="fg">
                                                <label className="font-super-small">Term </label>
                                                <select name="term" disabled={true} className="app-theme form-control-theme font-very-small text-capitalize" defaultValue={menuDropData.term} >
                                                    <option value="">Select Term</option>
                                                    {(termDataOptionFunc(allTermData, menuDropData.term))}
                                                </select>
                                                
                                            </div>
                                        )}
                                        
                                        <div className="fg">
                                            <label className="font-super-small">Year </label>
                                            <DatePicker  dateFormat="yyyy" showYearPicker
                                            className="app-theme form-control-theme font-very-small text-capitalize" placeholderText="Enter Year"
                                            onChange={(date) => handleFormEditSessionChange(date, "year")} selected={menuDropData.year}
                                            required={true} />     
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Resumption Date </label>
                                            <DatePicker  dateFormat="yyyy-MM-dd"
                                            className="app-theme form-control-theme font-very-small text-capitalize" placeholderText="Enter Date"
                                            onChange={(date) =>handleFormEditSessionChange(date, "res_date")} required={menuDropData.vac_date ? false : true} disabled={menuDropData.vac_date ? true : false} selected={menuDropData.res_date ? new Date(menuDropData.res_date) : ""} />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Vacation Date </label>
                                            <DatePicker  dateFormat="yyyy-MM-dd"
                                            className="app-theme form-control-theme font-very-small text-capitalize" placeholderText="Enter Date"
                                            onChange={(date) =>handleFormEditSessionChange(date, "vac_date")} selected={menuDropData.vac_date ? new Date(menuDropData.vac_date) : ""} />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Student Enrollment Start Date </label>
                                            <DatePicker  dateFormat="yyyy-MM-dd"
                                            className="app-theme form-control-theme font-very-small text-capitalize" placeholderText="Enter Enrollment Start Date"
                                            onChange={(date) =>handleFormEditSessionChange(date, "interview_start")} selected={menuDropData.interview_start ? new Date(menuDropData.interview_start) : ""} />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Student Enrollment End Date </label>
                                            <DatePicker  dateFormat="yyyy-MM-dd"
                                            className="app-theme form-control-theme font-very-small text-capitalize" placeholderText="Enter Enrollment Start Date"
                                            onChange={(date) =>handleFormEditSessionChange(date, "interview_end")} selected={menuDropData.interview_end ? new Date(menuDropData.interview_end) : ""} />
                                        </div>
                                        <div className="fg">
                                            <label className="font-super-small">Staff Scores Entry End Date </label>
                                            <DatePicker  dateFormat="yyyy-MM-dd"
                                            className="app-theme form-control-theme font-very-small text-capitalize" placeholderText="Enter Staff Scores Entry End Date"
                                            onChange={(date) =>handleFormEditSessionChange(date, "scores_entry_end")} selected={menuDropData.scores_entry_end ? new Date(menuDropData.scores_entry_end) : ""} />
                                        </div>
                                        {!menuDropData.status && (
                                            <div className="fg">
                                                <label className="font-super-small">Session Status </label>
                                                <select required name="status" className={`form-control-theme font-very-small text-capitalize`} value={menuDropData.status}  onChange={(e) => handleFormEditSessionChange(e)} >
                                                    <option value="">Select Status</option>
                                                    <option value={true} className="green">Active</option>
                                                    <option value={false} className="red" >Inactive</option>
                                                </select>     
                                            </div>
                                        )}
                                        <div className="fg-all btn-g align-center">
                                            <button type="submit" className="mybtn app-bg-color app-bg-text-color font-slightly-small">
                                                Update <i className="fas fa-paper-plane"></i> </button>
                                        </div>
                                    </div>
                                </form>
                                
                            </>)}
                        </MenuDrop>}
                        </AnimatePresence>
                    </GridContent>
                </Grid>
            </ComponentWrapper>
        </>    
    )
}

export default Session;