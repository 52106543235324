import { nth } from "./nth";
import parse from 'html-react-parser';
export const convertDate = (d=null, type) => {
    if (d){
        Date.prototype.getMonthName = function() {
            var monthNames = [ "Jan", "Feb", "March", "April", "May", "June", 
                               "July", "Aug", "Sept", "Oct", "Nov", "Dec" ];
            return monthNames[this.getMonth()];
        }
        const date = new Date(d);
        var new_month;
        var new_day;
        var new_hour;
        var new_minute;
        var new_second;
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();

        if(month > 9){
            new_month = month;
        }
        else{
            new_month = '0'+month;
        }
        if(day > 9){
            new_day = day;
        }
        else{
            new_day = '0'+day;
        }
        if(hour > 9){
            new_hour = hour;
        }
        else{
            new_hour = '0'+hour;
        }
        if(minute > 9){
            new_minute = minute;
        }
        else{
            new_minute = '0'+minute;
        }
        if(second > 9){
            new_second = second;
        }
        else{
            new_second = '0'+second;
        }
        if(type === "yyyy-mm-dd"){
            return[ year, new_month, new_day ].join('-');
        }
        if(type === "yyyy-mm-dd hh:mm:ss"){
            return `${year}-${new_month}-${new_day} ${new_hour}:${new_minute}:${new_second}`;
        }
        if(type === "hh:mm"){
            return `${new_hour}:${new_minute}`;
        }
        if(type === "hh:mm:ss"){
            return `${new_hour}:${new_minute}:${new_second}`;
        }
        else if(type==="yyyy"){
            return year;
        }
        else if(type==="ddMMyyyy"){
            return parse(`${nth(new_day)} ${[date.getMonthName(), year].join(', ').trimEnd(', ')}`);
        }
        else if(type === "ddMM"){
            return parse(`${nth(new_day)} ${date.getMonthName()}`);
        }
    }
    else{
        return null;
    }
}

