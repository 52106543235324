/* NotFound.css */
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: #f7f7f7;

    .error-code {
    font-size: 4rem;
    margin-bottom: 10px;
    color: red;
  }
  
  .error-message {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #777;
    font-weight: 600;
  }
  
  .home-link {
    text-decoration: none;
    transition: color 0.3s ease;
    color: #0000ff;
    font-weight: 600;
  }
  
  .home-link:hover {
    color: #0056b3;
  }
`;
  
  
  