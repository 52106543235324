import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import {Wrapper} from 'components/notFound/style.js';
import PreloaderLine from 'components/preloaderLine';

import { appServices, appServices2, appServices3, appServices4 } from 'api/schoolApp/services';
import { groupArray } from 'components/app/school/actions/array-utils/Group';

const CheckSchool = ({user={}}) => {
    // const initialState = useMemo(() => ({id:0, title:""}), []);
    
    const [state, setState] = useReducer((prev, curr) => [...prev, ...curr], []);
    
    const [navigate, setNavigate]  = useState('');

    const [navigateReload, setNavigateReload] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [redirecting, setRedirecting] = useState(false);

    const nav = useNavigate();

    const fetchSchool = (user_id, user_type, user_uid) => {
        setPreloading(true);
        if(user_type === "school_owner" || user_type === "super_admin" || user_type === "admin"){
            appServices.getUserA(user_id).then(res => {
                setPreloading(false);
                setState(res.data);
            }).catch(e => {
                setPreloading(false);
                setState([]);
            })
            return;
        }
        if(user_type === "student"){
            appServices2.getAccessB(user_uid).then(res => {
                setPreloading(false);
                setState(res.data);
            }).catch(e => {
                setPreloading(false);
                setState([]);
            })
            return;
        }
        if(user_type === "parent"){
            appServices3.getAccessB(user_uid).then(res => {
                setPreloading(false);
                setState(groupArray(res.data, 'school_id'));
            }).catch(e => {
                setPreloading(false);
                setState([]);
            })
            return;
        }
        if(user_type === "staff"){
            appServices4.getAccessB(user_uid).then(res => {
                setPreloading(false);
                setState(res.data);
            }).catch(e => {
                setPreloading(false);
                setState([]);
            })
            return;
        }
        setPreloading(false);
        setState([]);
        return;
    }

    useEffect(() => {
        fetchSchool(user.id, user.user_type, user.uid);
    }, [user]);

    useEffect(() => {
        if(!preloading && !user.id){
            setNavigate('login');
        }
    }, [preloading, user])

    useEffect(() => {
        if(state.length === 1){
            setRedirecting(true);
            setNavigateReload(true);
            setTimeout(() => {
                setNavigate(`/${state[0].school_title}/${user.user_type === "parent" ? 'account/all_kids' : 'dashboard'}`);
                setRedirecting(false);
            }, 500)
        }
    }, [state, user])

    useEffect(() => {
        if(navigate && navigateReload){
            window.location.href = navigate;
            setNavigate('');
            return;
        }
        if(navigate){
            nav(navigate);
            setNavigate('');
            return;
        }
    }, [nav, navigate, navigateReload])
    return(
        <>
            {preloading && <PreloaderLine />}
            <Wrapper>
                {redirecting ? (
                    <h1 className='app-text-color'>Redirecting....</h1>
                 ) :<></> }
                {/* {state.map((item, i) => {
                    return(
                        <Fragment key={i}>
                            <h1 className="error-code">{item.school_title}</h1>
                        </Fragment>
                    )
                }) */}
                

            </Wrapper>
        </>
    )
    
}

export default CheckSchool;