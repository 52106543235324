export const sortArrayByNumber = (arr=[]) => {
    return arr.sort((a,b) => a.num - b.num);
}

export const sortByName = (arr=[], itemKey="", order_by=false) => {
    return arr.sort((a, b) => {
        if(!order_by){
            if(typeof a === "object"){
                const a_key = a[itemKey].toLowerCase();
                const b_key = b[itemKey].toLowerCase();
                if(a_key < b_key){
                    return -1;
                }
                if(a_key > b_key){
                    return 1;
                }
                return 0
            }
            if(typeof a === "string"){
                if(a < b){
                    return -1;
                }
                if(a > b){
                    return 1;
                }
                return 0
            }
            return 0;
        }
        if(typeof a === "object"){
            const a_key = a[itemKey].toLowerCase();
            const b_key = b[itemKey].toLowerCase();
            if(a_key > b_key){
                return -1;
            }
            if(a_key < b_key){
                return 1;
            }
            return 0
        }
        if(typeof a === "string"){
            if(a > b){
                return -1;
            }
            if(a < b){
                return 1;
            }
            return 0
        }
        return 0;
        
    })
}